/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import ZIndexManager from "./base/components/ZIndexManager";
import WorkshopIndex from "./pages/Workshop/_Workshop.index";
import ModuleAuth from "./pages/Auth/Auth.module";
import LogoutPage from "./pages/Auth/Logout.page";
import { useControllers } from "./base/hooks/rootContext.hooks";
import { DialogStack } from "./base/components/Dialog/DialogStack";
import PortalLayer from "./base/components/PortalLayer";
import HelmetColorThemeUpdater from "./base/components/HelmetColorThemeUpdater";
import CommandPalette from "./components/shared/CommandPalette";
import AccountIndex from "./pages/Account/_Account.index";
import { IS_EMBEDDED } from "./env";
import DevEnvLabel from "./base/components/DevEnvLabel";
import { useOnMount } from "./base/hooks/lifecycle.hooks";
import { addRootClass } from "./base/utils/dom.utils";
import { ShowGlobalProgressBar } from "./base/utils/globalProgressBar.utils";
import { getUrlParams } from "./base/utils/urlParams.utils";
import { ComposerAppUIMode } from "./components/composer/useCreateComposerInstance";
import LoadingIndicator from "./base/components/LoadingIndicator";
import styled from "@emotion/styled";

const ComposerAppManager = React.lazy(
  () => import("./components/composer/ComposerAppManager")
);
const AdminIndex = React.lazy(() => import("./pages/Admin/_Admin.index"));

interface AppContainerProps {}

const PreAuthLoadingScreen = styled.div`
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AppContainer: React.FC<AppContainerProps> = observer(props => {
  const { NAVIGATOR, AUTH } = useControllers();
  useOnMount(() => {
    if (IS_EMBEDDED) addRootClass("EMBEDDED");
  });
  const urlParams = getUrlParams<{ m?: string }>();
  return (
    <Router>
      <div className="AppContainer">
        <HelmetColorThemeUpdater />
        {AUTH.beforeInitChecksComplete ? (
          <Routes>
            <Route
              path="play/:id"
              element={
                <React.Suspense fallback={<ShowGlobalProgressBar />}>
                  <ComposerAppManager mode="player" />
                </React.Suspense>
              }
            />
            <Route
              path="compose/:id"
              element={
                <React.Suspense fallback={<ShowGlobalProgressBar />}>
                  <ComposerAppManager mode="editor" />
                </React.Suspense>
              }
            />
            {(!urlParams.m ||
              urlParams.m === "player" ||
              urlParams.m === "editor") && (
              <Route
                path="c/:id"
                element={
                  <React.Suspense fallback={<ShowGlobalProgressBar />}>
                    <ComposerAppManager
                      mode={(urlParams.m || "editor") as ComposerAppUIMode}
                      loadPackage
                    />
                  </React.Suspense>
                }
              />
            )}
            <Route
              path="render/:id"
              element={
                <React.Suspense fallback={<ShowGlobalProgressBar />}>
                  <ComposerAppManager mode="renderer" loadRenderJob />
                </React.Suspense>
              }
            />
            {AUTH.isAuthenticated && (
              <>
                <Route path="workshop/*" element={<WorkshopIndex />} />
                <Route path="account/*" element={<AccountIndex />} />
              </>
            )}
            {AUTH.isAdmin && (
              <Route
                path="admin/*"
                element={
                  <React.Suspense fallback={null}>
                    <AdminIndex />
                  </React.Suspense>
                }
              />
            )}
            {AUTH.isAuthenticated && (
              <Route path="*" element={<Navigate to="workshop" />} />
            )}
            <Route path="logout" element={<LogoutPage />} />
            <Route path="auth/*" element={<ModuleAuth />} />
            <Route
              path="/"
              element={
                <Navigate
                  to={AUTH.isAuthenticated ? "workshop" : "auth/login"}
                />
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        ) : (
          <PreAuthLoadingScreen>
            <LoadingIndicator delay="1s" />
          </PreAuthLoadingScreen>
        )}
        {NAVIGATOR.shouldNavigateTo && (
          <Navigate to={NAVIGATOR.shouldNavigateTo} />
        )}
        <PortalLayer name="default" />
        <PortalLayer name="invisible" />
        <CommandPalette />
        <DialogStack />
        <DevEnvLabel />
        <ZIndexManager
          phone={[
            ".DevEnvLabel",
            ".DebugConsole",
            ".DropdownLikeUI__dropdown",
            ".Dialog",
            ".CommandPalette",
            ".ContextMenu",
            `.AppNavigation`,
            `.FullScreenPanelLayer`,
            ".VoiceQuickManager",
            `.PortalLayer[data-name="default"]`,
            ".StatusBar",
            ".WorkspaceDock",
            ".InstrumentUI",
            ".ComposerAppTitleBar",
            "[data-name='canvas-top-svg-portal']",
            `.PortalLayer[data-name^="composer-canvas"]`,
          ]}
          tablet={[
            ".DevEnvLabel",
            ".DebugConsole",
            ".DropdownLikeUI__dropdown",
            ".Dialog",
            ".CommandPalette",
            ".ContextMenu",
            `.FullScreenPanelLayer`,
            ".VoiceQuickManager",
            `.PortalLayer[data-name="default"]`,
            ".StatusBar",
            ".WorkspaceDock",
            ".InstrumentUI",
            ".ComposerAppTitleBar",
            "[data-name='canvas-top-svg-portal']",
            `.PortalLayer[data-name^="composer-canvas"]`,
          ]}
          desktop={[
            ".DevEnvLabel",
            ".DebugConsole",
            ".DropdownLikeUI__dropdown",
            ".Dialog",
            ".CommandPalette",
            ".ContextMenu",
            `.FullScreenPanelLayer`,
            ".VoiceQuickManager",
            `.PortalLayer[data-name="default"]`,
            ".StatusBar",
            ".WorkspaceDock",
            ".InstrumentUI",
            ".ComposerAppTitleBar",
            "[data-name='canvas-top-svg-portal']",
            `.PortalLayer[data-name^="composer-canvas"]`,
          ]}
        />
      </div>
    </Router>
  );
});

export default AppContainer;
