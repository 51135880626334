import styled from "@emotion/styled";
import {
  varFontMono,
  varPrimary,
  varPrimary10,
} from "../../constants/cssCustomProperties.constants";
import { UNITS } from "../constants/units.constant";

export const Code = styled.code`
  font-family: ${varFontMono};
  font-weight: 500;
  background-color: ${varPrimary10};
  border: ${UNITS.lineWidth}px solid ${varPrimary10};
  color: ${varPrimary};
  border-radius: 0.2em;
  padding: 0 0.4em;
  margin: 0.1em 0.2em 0.1em 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: baseline;
  text-align: center;
  min-width: 2em;
  white-space: nowrap;
  line-height: 1.4;
  text-transform: uppercase;
  font-size: 80%;
`;
