import { AtomType, GroupLikeAtom, Note, Ornament } from "../../@types";
import { OrnamentationType } from "../../constants/ornaments.constants";
import { createAtomFactory } from "../../logic/Atom.factory";
import { getOrnamentDefByIdentifier } from "../../logic/ornaments/index.ornamentDefs";
import { syncableGroupLikeAtomInitFunction } from "../../utils/syncableGroupLikeAtoms.utils";
import {
  GroupSnapshotFactory,
  makeGroupExtendedMembersFactory,
} from "./Group.model";

export const OrnamentSnapshotFactory = () => ({
  ...GroupSnapshotFactory(AtomType.ornament),
  ornamentationType: "" as OrnamentationType,
  noteId: "",
  ruleId: "",
});

export const makeOrnamentExtendedMembersFactory = (O: Ornament) => ({
  get displayName(): string {
    return O.name || `Ornament ${O._id}`;
  },
  set displayName(value: string) {
    O.name = value;
  },
  get def() {
    return getOrnamentDefByIdentifier(O.ornamentationType);
  },
  get symbol() {
    return O.def?.symbolFactory(O) ?? "";
  },
  get note() {
    return O.context?.getAtomById<Note>(O.noteId) ?? null;
  },
  get rule() {
    return O.note?.rules.find(r => r._id === O.$.ruleId) ?? null;
  },
  get voice() {
    return O.note?.voice ?? null;
  },
  set voice(v) {
    if (O.note) O.note.$.voiceId = v?.voiceId ?? null;
  },
  get voiceId() {
    return O.note?.$.voiceId ?? null;
  },
  set voiceId(v) {
    if (O.note) O.note.$.voiceId = v ?? null;
  },
  get parents() {
    return O.note?.parents ?? [];
  },
  get parentIds() {
    return O.note?.parentIds ?? [];
  },
  setParents: (parents: GroupLikeAtom[], mode: ArrayOperationMode) => {
    O.note?.setParents(parents, mode);
  },
  clearParents: () => {
    O.note?.clearParents();
  },
});

export const makeOrnament = createAtomFactory<Ornament>({
  type: AtomType.ornament,
  snapshotFactory: OrnamentSnapshotFactory,
  extendedPropertiesFactories: [
    makeGroupExtendedMembersFactory,
    makeOrnamentExtendedMembersFactory,
  ],
  init: syncableGroupLikeAtomInitFunction,
});
