import { action } from "mobx";
import { AtomContext, Keyframe, KeyframeSnapshot } from "../@types";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { KeyframeSnapshotFactory } from "../models/atoms/Keyframe.model";
import { insertAtomInOrder } from "../logic/atomFactoryMethods";

export const createKeyframe = action(
  (context: AtomContext, template?: Keyframe | Partial<KeyframeSnapshot>) => {
    const snapshot: KeyframeSnapshot = makeSnapshot(
      KeyframeSnapshotFactory,
      template ?? {}
    );
    if (!snapshot._id || context.getAtomById(snapshot._id))
      snapshot._id = context.getNextNewAtomId();
    if (context.composer) {
      if (context.composer.writeKeyframesTo === "interpretation") {
        if (!snapshot._itpId) {
          snapshot._itpId =
            context.composer.interpreter?.interpretation._id ?? "";
        }
      }
    }
    context.addAtomSnapshots([snapshot]);
    const newKeyframe = context.getAtomById<Keyframe>(snapshot._id)!;
    insertAtomInOrder(context.keyframes, newKeyframe);
    insertAtomInOrder(context.notesAndKeyframes, newKeyframe);
    context.lastEditedKeyframe = newKeyframe;
    context.canvas?.setPrimaryCursorPosition(newKeyframe.endX);
    return newKeyframe;
  }
);
