import dayjs from "dayjs";
import { action, observable } from "mobx";
import packageJson from "../../package.json";
import { APP_DISPLAY_NAME, APP_NAME } from "../constants/app.constants";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";

export type CommonController = ReturnType<typeof makeCommonController>;

export const APP_INFO = Object.freeze({
  name: APP_NAME,
  version: packageJson.version ?? "unknown",
  displayName: APP_DISPLAY_NAME,
});

export const makeCommonController = () => {
  const c = observable({
    ...makeControllerBase("COMMON"),
    get app() {
      return APP_INFO;
    },
    now: dayjs(),
  });

  document.documentElement.setAttribute("data-app-version", c.app.version);

  c.init = makeRootControllerChildInitFn(c, () => {
    setInterval(
      action(() => (c.now = dayjs())),
      1000
    );
  });

  return c;
};
