import { AtomType, TextNode } from "../../@types";
import { add } from "../../base/utils/math.utils";
import {
  createAtomFactory,
  makeAtomBaseSnapshot,
} from "../../logic/Atom.factory";
import {
  getTransformedValueOfProp,
  setTransformedValueOfProp,
} from "../../transformers/transform.controller";
import { ValidRect } from "../../base/@types";
import { action, observable, reaction } from "mobx";
import { makeDisposerController } from "../../base/utils/disposer.utils";

export const TextNodeSnapshotFactory = () => ({
  ...makeAtomBaseSnapshot(AtomType.textNode),
  fontFamily: null as null | string,
  fontSize: null as null | number,
  fontWeight: null as null | number,
  italic: null as null | boolean,
  markdown: null as null | string,
});

export const makeTextNodeExtendedProperties = (T: TextNode) => {
  const _ = observable({
    hitBox: null as null | ValidRect,
  });
  return {
    get displayName() {
      return `Text ${T.$._id}`;
    },
    get _x() {
      return (
        T.$.x ??
        (T.refAtom
          ? add(T.refAtom.x, T.replica?._anchorDiffFromSource.x ?? null)
          : null)
      );
    },
    set _x(newValue) {
      T.$.x = newValue;
    },
    get x() {
      return getTransformedValueOfProp(T, "x") || 0;
    },
    set x(newValue: number) {
      setTransformedValueOfProp(T, "x", newValue);
    },
    setSourceX: (newValue: number) => {
      T.$.x = newValue;
    },
    get _y() {
      if (T.$.y !== null) return T.$.y;
      if (!T.refAtom || !T.replica || !T.replica.pattern) return 0;
      return add(T.refAtom.y, T.replica._anchorDiffFromSource.y);
    },
    set _y(v) {
      T.$.y = v;
    },
    get y() {
      return getTransformedValueOfProp(T, "y") ?? 0;
    },
    set y(v) {
      setTransformedValueOfProp(T, "y", v);
    },
    setSourceY: (newValue: number) => {
      T.$.y = newValue;
    },
    get _width() {
      return T.$.width || T.refAtom?.width || 0;
    },
    set _width(v) {
      T.$.width = v;
    },
    get width() {
      return getTransformedValueOfProp(T, "width");
    },
    set width(v) {
      setTransformedValueOfProp(T, "width", v);
    },
    get _height() {
      return T.$.height || T.refAtom?.height || 0;
    },
    set _height(v) {
      T.$.height = v;
    },
    get height() {
      return getTransformedValueOfProp(T, "height");
    },
    set height(v) {
      setTransformedValueOfProp(T, "height", v);
    },
    get markdown() {
      return T.$.markdown || (T.refAtom?.markdown ?? "");
    },
    set markdown(v) {
      T.$.markdown = v;
    },
    get fontFamily() {
      return T.$.fontFamily || (T.refAtom?.fontFamily ?? "sans");
    },
    set fontFamily(v) {
      T.$.fontFamily = v;
    },
    get fontSize() {
      return T.$.fontSize || (T.refAtom?.fontSize ?? 12);
    },
    set fontSize(v) {
      T.$.fontSize = v;
    },
    get fontWeight() {
      return T.$.fontWeight || (T.refAtom?.fontWeight ?? 400);
    },
    set fontWeight(v) {
      T.$.fontWeight = v;
    },
    get italic() {
      return T.$.italic || (T.refAtom?.italic ?? false);
    },
    set italic(v) {
      T.$.italic = v;
    },
    get hitBox() {
      return _.hitBox;
    },
    _updateHitBox: action((rect: ValidRect) => {
      _.hitBox = rect;
    }),
  };
};

export const makeTextNode = createAtomFactory<TextNode>({
  type: AtomType.textNode,
  snapshotFactory: TextNodeSnapshotFactory,
  extendedPropertiesFactories: [makeTextNodeExtendedProperties],
  init: T => {
    const d = makeDisposerController();
    d.add(
      reaction(
        () => T.x,
        () => {
          const deltaX = T.x - T.hitBox[0].x;
          T._updateHitBox([
            {
              x: T.hitBox[0].x + deltaX,
              y: T.hitBox[0].y,
            },
            {
              x: T.hitBox[1].x + deltaX,
              y: T.hitBox[1].y,
            },
          ]);
        }
      )
    );
    d.add(
      reaction(
        () => T.y,
        () => {
          const deltaY = T.y - T.hitBox[0].y;
          T._updateHitBox([
            {
              x: T.hitBox[0].x,
              y: T.hitBox[0].y + deltaY,
            },
            {
              x: T.hitBox[1].x,
              y: T.hitBox[1].y + deltaY,
            },
          ]);
        }
      )
    );
    return d.dispose;
  },
});
