/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { CSSProperties } from "react";
import { CSSPartial } from "../../base/@types/css.types";
import { useProps } from "../../base/utils/mobx.utils";
import { Collection } from "../../models/Collection.model";
import VinylDisc from "../directory/VinylDisc";
import ImageFromFileRecord from "../../base/components/ImageFromFileRecord";

type CollectionCoverImageProps = {
  collection: Collection | null;
  className?: string;
  size?: string | number;
};

const style = {
  container: {
    position: "relative",
    display: "block",
    width: "var(--size, 100%)",
    height: "var(--size, auto)",
    aspectRatio: "1/1",
    objectPosition: "center",
    overflow: "hidden",
    borderRadius: 2,
  } as CSSPartial,
  disc: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  } as CSSPartial,
  image: {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    opacity: 0,
    transition: ".1s opacity",
    "&.loaded": {
      opacity: 1,
    },
  } as CSSPartial,
};

const CollectionCoverImage: React.FC<CollectionCoverImageProps> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <div
          className={p.className}
          style={
            {
              "--size": p.size,
            } as CSSProperties
          }
          css={style.container}
        >
          <VinylDisc css={style.disc} size={p.size} />
          {p.collection?.$.coverImageId && (
            <ImageFromFileRecord
              imageId={p.collection.$.coverImageId}
              css={style.image}
              fadeInOnLoad
              includeDimensionAttributes
            />
          )}
        </div>
      )}
    />
  );
};

export default CollectionCoverImage;
