import { computed, makeObservable } from "mobx";
import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { has_id } from "../traits/hasId.trait";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import {
  StandardModel,
  addChangeDetectionToModel,
  appendMissingKeys,
} from "./StandardModel";
import { User } from "./User.model";

export type FileRecordAccessType = "public" | "private";

export const FileRecordSnapshotFactory = () => ({
  ...has_id(),
  url: "",
  name: "",
  description: "",
  key: "",
  type: "",
  width: null as null | number,
  height: null as null | number,
  access: "private" as FileRecordAccessType,
  ownerId: "",
});

const defaultSnapshot = FileRecordSnapshotFactory();

export type FileRecordSnapshot = ReturnType<typeof FileRecordSnapshotFactory>;

const changeDetectionSnapshotMaker = ($: FileRecordSnapshot) => {
  const { _id, name, url, description, type, ownerId } = $;
  return JSON.stringify({
    _id,
    name,
    url,
    description,
    type,
    ownerId,
    ...hasTimestamps(),
  });
};

export class FileRecord extends StandardModel<
  ModelName.fileRecords,
  FileRecordSnapshot
> {
  constructor(LOCALDB: LocalDBController, $ = FileRecordSnapshotFactory()) {
    appendMissingKeys($, defaultSnapshot);
    super(ModelName.fileRecords, LOCALDB, $);
    makeObservable(this, {
      owner: computed,
    });
    addChangeDetectionToModel(
      this,
      LOCALDB,
      changeDetectionSnapshotMaker,
      this.d
    );
  }
  get url() {
    return this.$.url;
  }
  get owner() {
    return this.LOCALDB.get<User>(ModelName.users, this.$.ownerId);
  }
}
