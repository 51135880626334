import {
  OrnamentationDef,
  OrnamentationType,
} from "../../constants/ornaments.constants";

export const Ornamentation_NullDef: OrnamentationDef = {
  identifier: OrnamentationType.null,
  displayName: "No ornamentation",
  defaultSymbol: "",
  symbolFactory: orn => "",
  generator: (note, options) => {
    return [];
  },
};
