import { makeIconDef } from "./makeIconDef";

export const LibraryIconDef = {
  library: makeIconDef("library", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5H14V20H11V5ZM10 4H11H14H15V5V6.53926L15.0929 6.51437L17.0247 5.99674L17.9907 5.73792L18.2495 6.70384L21.6141 19.2609L21.8729 20.2268L20.907 20.4856L18.9752 21.0033L18.0092 21.2621L17.7504 20.2962L15 10.0314V20V21H14H11H10V20V5V4ZM5 8H8V17H5V8ZM4 18V17V8V7H5H8H9V8V20V21H8H5H4V20V18ZM5 18H8V20H5V18ZM17.2835 6.96266L15.3517 7.4803L18.7163 20.0373L20.6482 19.5197L17.2835 6.96266Z"
        fill="currentColor"
      />
    ),
  }),
};
