import {
  PolySynthMeta,
  PolySynthName,
  makeDefaultPolySynthOptions,
  makePolySynth,
} from "../../instruments/PolySynth/PolySynth.instrument";
import {
  SalamanderGrandPianoMeta,
  SalamanderGrandPianoName,
  makeSalamanderGrandPiano,
  makeSalamanderGrandPianoDefaultOptions,
} from "../../instruments/SalamanderGrandPiano/SalamanderGrandPiano.instrument";
import { observable } from "mobx";
import { InstrumentDef } from "../../@types/instruments.types";
import {
  FluteMeta,
  FluteName,
  makeDefaultFluteOptions,
  makeFlute,
} from "../../instruments/Flute/Flute.instrument";
import {
  AltoRecorderMeta,
  AltoRecorderName,
  makeAltoRecorder,
  makeDefaultAltoRecorderOptions,
} from "../../instruments/AltoRecorder/AltoRecorder.instrument";
import {
  RenaissanceOrganMeta,
  RenaissanceOrganName,
  makeDefaultRenaissanceOrganOptions,
  makeRenaissanceOrgan,
} from "../../instruments/RenaissanceOrgan/RenaissanceOrgan.instrument";
import {
  BlanchetHarpsichordMeta,
  BlanchetHarpsichordName,
  makeBlanchetHarpsichord,
  makeDefaultBlanchetHarpsichordOptions,
} from "../../instruments/BlanchetHarpsichord/BlanchetHarpsichord.instrument";
import {
  FlemishHarpsichordMeta,
  FlemishHarpsichordName,
  makeDefaultFlemishHarpsichordOptions,
  makeFlemishHarpsichord,
} from "../../instruments/FlemishHarpsichord/FlemishHarpsichord.instrument";
import {
  ItalianHarpsichordMeta,
  ItalianHarpsichordName,
  makeDefaultItalianHarpsichordOptions,
  makeItalianHarpsichord,
} from "../../instruments/ItalianHarpsichord/ItalianHarpsichord.instrument";
import {
  EnglishHarpsichordMeta,
  EnglishHarpsichordName,
  makeDefaultEnglishHarpsichordOptions,
  makeEnglishHarpsichord,
} from "../../instruments/EnglishHarpsichord/EnglishHarpsichord.instrument";
import {
  MarimbaMeta,
  MarimbaName,
  makeDefaultMarimbaOptions,
  makeMarimba,
} from "../../instruments/Marimba/Marimba.instrument";
import {
  HarpMeta,
  HarpName,
  makeDefaultHarpOptions,
  makeHarp,
} from "../../instruments/Harp/Harp.instrument";

export const InstrumentDefIndex = observable(
  new Map([
    [
      BlanchetHarpsichordName,
      {
        meta: BlanchetHarpsichordMeta,
        factory: makeBlanchetHarpsichord,
        defaultOptionsFactory: makeDefaultBlanchetHarpsichordOptions,
      },
    ],
    [
      FlemishHarpsichordName,
      {
        meta: FlemishHarpsichordMeta,
        factory: makeFlemishHarpsichord,
        defaultOptionsFactory: makeDefaultFlemishHarpsichordOptions,
      },
    ],
    [
      ItalianHarpsichordName,
      {
        meta: ItalianHarpsichordMeta,
        factory: makeItalianHarpsichord,
        defaultOptionsFactory: makeDefaultItalianHarpsichordOptions,
      },
    ],
    [
      EnglishHarpsichordName,
      {
        meta: EnglishHarpsichordMeta,
        factory: makeEnglishHarpsichord,
        defaultOptionsFactory: makeDefaultEnglishHarpsichordOptions,
      },
    ],
    [
      SalamanderGrandPianoName,
      {
        meta: SalamanderGrandPianoMeta,
        factory: makeSalamanderGrandPiano,
        defaultOptionsFactory: makeSalamanderGrandPianoDefaultOptions,
      },
    ],
    [
      RenaissanceOrganName,
      {
        meta: RenaissanceOrganMeta,
        factory: makeRenaissanceOrgan,
        defaultOptionsFactory: makeDefaultRenaissanceOrganOptions,
      },
    ],
    [
      AltoRecorderName,
      {
        meta: AltoRecorderMeta,
        factory: makeAltoRecorder,
        defaultOptionsFactory: makeDefaultAltoRecorderOptions,
      },
    ],
    [
      FluteName,
      {
        meta: FluteMeta,
        factory: makeFlute,
        defaultOptionsFactory: makeDefaultFluteOptions,
      },
    ],
    [
      HarpName,
      {
        meta: HarpMeta,
        factory: makeHarp,
        defaultOptionsFactory: makeDefaultHarpOptions,
      },
    ],
    [
      MarimbaName,
      {
        meta: MarimbaMeta,
        factory: makeMarimba,
        defaultOptionsFactory: makeDefaultMarimbaOptions,
      },
    ],
    [
      PolySynthName,
      {
        meta: PolySynthMeta,
        factory: makePolySynth,
        defaultOptionsFactory: makeDefaultPolySynthOptions,
      },
    ],
  ] as unknown as [string, InstrumentDef][])
);

export const KeyboardInstrumentDefinitions = Array.from(
  InstrumentDefIndex.values()
).filter(i => i.meta.type === "keyboards");
