/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import Button, { ButtonGroup } from "../../base/components/Button";
import Panel, {
  PanelCloseButton,
  PanelFooter,
  PanelHeader,
  PanelInner,
} from "../../base/components/Panel";
import Serif from "../../base/components/Serif";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { getSnapshot } from "../../base/utils/snapshot.utils";
import {
  Composition,
  CompositionSnapshot,
} from "../../models/Composition.model";
import CompositionSetupEditor from "../shared/CompositionSetupEditor";

type CompositionEditorPanelProps = {
  composition: Composition;
  onShouldClose?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
};

const CompositionEditorPanel: React.FC<CompositionEditorPanelProps> = props => {
  const { THEME } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    preEditCopy: getSnapshot<CompositionSnapshot>(p.composition),
    cancel: () => {
      p.composition.$patch(s.preEditCopy);
      p.onShouldClose?.();
      p.onCancel?.();
    },
    save: () => {
      p.onShouldClose?.();
      p.onSave?.();
    },
  }));
  return (
    <Observer
      children={() => (
        <Panel
          id="composition-editor"
          title="Edit Composition"
          fullscreen
          canEscape
          minWidth="30em"
        >
          <PanelHeader End={<PanelCloseButton onClick={s.cancel} />}>
            <h2>
              <Serif>Edit Composition</Serif>
            </h2>
          </PanelHeader>
          <PanelInner>
            <CompositionSetupEditor
              composition={p.composition}
              autoFocus
              metaOnly
            />
          </PanelInner>
          <PanelFooter>
            <ButtonGroup>
              <Button onClick={s.cancel} minHeight="3em" fullWidth>
                Discard changes
              </Button>
              <Button
                onClick={s.save}
                minHeight="3em"
                fullWidth
                color={THEME.primary}
              >
                Save changes
              </Button>
            </ButtonGroup>
          </PanelFooter>
        </Panel>
      )}
    />
  );
};

export default CompositionEditorPanel;
