import { makeIconDef } from "./makeIconDef";

export const DeleteIconDef = {
  delete: makeIconDef("delete", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.97702 4.70231C8.96113 4.42662 9.17173 4.19025 9.44741 4.17435L15.4375 3.82897C15.7131 3.81307 15.9495 4.02367 15.9654 4.29936C15.9813 4.57504 15.7707 4.81141 15.495 4.82731L9.50497 5.17269C9.22929 5.18859 8.99292 4.97799 8.97702 4.70231ZM20.9857 7.00211C21.0017 7.27778 20.7913 7.51427 20.5156 7.53031L18.9035 7.6241C18.9053 7.64888 18.9053 7.67411 18.9034 7.69963L17.9744 19.7763C17.9143 20.5578 17.2626 21.1613 16.4788 21.1613H8.33086C7.54706 21.1613 6.8954 20.5578 6.83528 19.7763L5.95843 8.37729L4.54261 8.45966C4.26693 8.4757 4.03045 8.26523 4.01441 7.98955C3.99837 7.71387 4.20885 7.47739 4.48452 7.46135L20.4575 6.53199C20.7332 6.51596 20.9697 6.72643 20.9857 7.00211ZM6.95692 8.31919L7.83234 19.6996C7.85237 19.9601 8.06959 20.1613 8.33086 20.1613H16.4788C16.7401 20.1613 16.9573 19.9601 16.9773 19.6996L17.9017 7.68239L6.95692 8.31919ZM10.5 10C10.7762 10 11 10.2239 11 10.5V14.5C11 14.7761 10.7762 15 10.5 15C10.2239 15 10 14.7761 10 14.5V10.5C10 10.2239 10.2239 10 10.5 10ZM15 10.5C15 10.2239 14.7762 10 14.5 10C14.2239 10 14 10.2239 14 10.5V14.5C14 14.7761 14.2239 15 14.5 15C14.7762 15 15 14.7761 15 14.5V10.5Z"
        fill="currentColor"
      />
    ),
  }),
};
