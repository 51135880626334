import { toJS } from "mobx";
import {
  OrnamentationDef,
  OrnamentationType,
} from "../../constants/ornaments.constants";
import { getNoteSnapshotAfterAddingOrSubtractingStepsInScale } from "../../utils/musicScale.utils";

export const Ornamentation_ShortTrillDef: OrnamentationDef = {
  identifier: OrnamentationType.shortTrill,
  displayName: "Short Trill",
  defaultSymbol: "",
  symbolFactory: orn => "",
  generator: (note, options) => {
    const $ = {
      ...toJS(note.$),
      x: note.x,
      y: note.y,
    };
    const higherNoteBase = getNoteSnapshotAfterAddingOrSubtractingStepsInScale(
      note,
      1
    );
    if (!note.width) return [];
    const ornamentNoteDuration =
      options?.ornamentNoteWidth ??
      Math.min(note.width * (note.width <= 0.5 ? 1 / 4 : 1 / 8), 0.125);
    return [higherNoteBase, $, higherNoteBase, $].map((snapshot, i) => ({
      ...snapshot,
      x: note.x! + i * ornamentNoteDuration,
      width:
        i === 3 ? note.width! - ornamentNoteDuration * 3 : ornamentNoteDuration,
      velocity: note.velocity,
      appearance: note.appearance,
    }));
  },
};
