import {
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  StandardInstrumentOptions,
} from "../../@types";
import { IconName } from "../../base/components/Symbols/iconDefs/_index.iconDefs";
import { STATIC_HOST } from "../../env";
import { createCompositeSamplerInstrumentFactory } from "../_factory/compositeSamplerInstrumentFactory";
import BlanchetHarpsichordControlPanel from "./ui/BlanchetHarpsichordControlPanel";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";

export type BlanchetHarpsichordSamplerName = "jeu1" | "jeu2" | "lute";

export type BlanchetHarpsichordOptions = StandardInstrumentOptions & {
  activatedSamplers: BlanchetHarpsichordSamplerName[];
};

export const makeDefaultBlanchetHarpsichordOptions =
  (): BlanchetHarpsichordOptions => ({
    ...makeDefaultInstrumentOptions(),
    activatedSamplers: ["jeu1"],
  });

export type BlanchetHarpsichordType = Instrument<BlanchetHarpsichordOptions>;

export const BlanchetHarpsichordName = "blanchet-harpsichord";

export const BlanchetColors = {
  red: "#C9311B",
  // black: "#261E1F",
  // gold: "#E7C4AB",
};

export const BlanchetHarpsichordMeta: InstrumentMeta<BlanchetHarpsichordOptions> =
  {
    name: BlanchetHarpsichordName,
    displayName: "Blanchet 1720 Harpsichord",
    source: "Soni Musicae",
    sourceWebsite: "http://sonimusicae.free.fr/blanchet1-en.html",
    icon: "harpsichord" as IconName,
    range: [[29, 88]] as InstrumentRange,
    ControlPanel: BlanchetHarpsichordControlPanel,
    defaultColor: BlanchetColors.red,
    type: "keyboards",
  };

const lowerRegisters = () => ({
  G1: `09-Gsharp0.mp3`,
  "G#1": `10-A0.mp3`,
  A1: `11-Asharp0.mp3`,
  "A#1": `12-B0.mp3`,
  B1: `13-C1.mp3`,
  C2: `14-Csharp1.mp3`,
  "C#2": `15-D1.mp3`,
  D2: `16-Dsharp1.mp3`,
  "D#2": `17-E1.mp3`,
  E2: `18-F1.mp3`,
  F2: `19-Fsharp1.mp3`,
  "F#2": `20-G1.mp3`,
  G2: `21-Gsharp1.mp3`,
  "G#2": `22-A1.mp3`,
  A2: `23-Asharp1.mp3`,
  "A#2": `24-B1.mp3`,
  B2: `25-C2.mp3`,
});

const midRangeOctaves = () => ({
  C3: `26-Csharp2.mp3`,
  "C#3": `27-D2.mp3`,
  D3: `28-Dsharp2.mp3`,
  "D#3": `29-E2.mp3`,
  E3: `30-F2.mp3`,
  F3: `31-Fsharp2.mp3`,
  "F#3": `32-G2.mp3`,
  G3: `33-Gsharp2.mp3`,
  "G#3": `34-A2.mp3`,
  A3: `35-Asharp2.mp3`,
  "A#3": `36-B2.mp3`,
  B3: `37-C3.mp3`,
  C4: `38-Csharp3.mp3`,
  "C#4": `39-D3.mp3`,
  D4: `40-Dsharp3.mp3`,
  "D#4": `41-E3.mp3`,
  E4: `42-F3.mp3`,
  F4: `43-Fsharp3.mp3`,
  "F#4": `44-G3.mp3`,
  G4: `45-Gsharp3.mp3`,
  "G#4": `46-A3.mp3`,
  A4: `47-Asharp3.mp3`,
  "A#4": `48-B3.mp3`,
  B4: `49-C4.mp3`,
});

const higherRegisters = () => ({
  C5: `50-Csharp4.mp3`,
  "C#5": `51-D4.mp3`,
  D5: `52-Dsharp4.mp3`,
  "D#5": `53-E4.mp3`,
  E5: `54-F4.mp3`,
  F5: `55-Fsharp4.mp3`,
  "F#5": `56-G4.mp3`,
  G5: `57-Gsharp4.mp3`,
  "G#5": `58-A4.mp3`,
  A5: `59-Asharp4.mp3`,
  "A#5": `60-B4.mp3`,
  B5: `61-C5.mp3`,
  C6: `62-Csharp5.mp3`,
  "C#6": `63-D5.mp3`,
  D6: `64-Dsharp5.mp3`,
  "D#6": `65-E5.mp3`,
  E6: `66-F5.mp3`,
});

const urls = {
  ...higherRegisters(),
  ...midRangeOctaves(),
  ...lowerRegisters(),
};

export const makeBlanchetHarpsichord = createCompositeSamplerInstrumentFactory({
  defaultOptionsBagBuilder: makeDefaultBlanchetHarpsichordOptions,
  instrumentMeta: BlanchetHarpsichordMeta,
  getters: {
    samplers: (n, o) => o?.activatedSamplers ?? ["full"],
    velocity: {
      attack: 0.7,
      release: 0.5,
    },
  },
  compositeSamplerConfigArray: [
    {
      name: "jeu1",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/blanchet-1720/mp3/jeu1/`,
          urls,
        },
        release: {
          baseUrl: `${STATIC_HOST}/instruments/blanchet-1720/mp3/jeu1-release/`,
          urls,
        },
      },
    },
    {
      name: "jeu2",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/blanchet-1720/mp3/jeu2/`,
          urls,
        },
        release: {
          baseUrl: `${STATIC_HOST}/instruments/blanchet-1720/mp3/jeu2-release/`,
          urls,
        },
      },
    },
    {
      name: "lute",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/blanchet-1720/mp3/lute/`,
          urls,
        },
        release: {
          baseUrl: `${STATIC_HOST}/instruments/blanchet-1720/mp3/lute-release/`,
          urls,
        },
      },
    },
  ],
});
