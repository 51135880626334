/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { generateUUID } from "../../base/utils/uuid.utils";

type VinylDiscProps = {
  className?: string;
  color?: string;
  size?: string | number;
  borderRadius?: number;
};

const VinylDisc: React.FC<VinylDiscProps> = props => {
  const p = useProps(props);
  const { THEME } = useControllers();
  const s = useStore(() => ({
    get color() {
      return p.color ?? THEME.fg;
    },
  }));
  const [id] = useState(generateUUID());
  return (
    <Observer
      children={() => (
        <div
          className={props.className}
          css={{
            display: "block",
            "&:before": {
              content: "''",
              display: "block",
              paddingTop: "100%",
              position: "absolute",
            },
          }}
        >
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            css={{
              width: p.size ?? "100%",
              height: p.size ?? "100%",
              display: "block",
            }}
          >
            <rect
              opacity="0.1"
              width="100"
              height="100"
              fill={`url(#${id}_linear_0_1)`}
            />
            <path
              opacity="0.2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50 96C75.4051 96 96 75.4051 96 50C96 24.5949 75.4051 4 50 4C24.5949 4 4 24.5949 4 50C4 75.4051 24.5949 96 50 96ZM50 55C52.7614 55 55 52.7614 55 50C55 47.2386 52.7614 45 50 45C47.2386 45 45 47.2386 45 50C45 52.7614 47.2386 55 50 55Z"
              fill={`url(#${id}_linear_0_1)`}
            />
            <path
              opacity="0.1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M92.4986 32.3966C94.8103 37.9775 96.0001 43.9592 96.0001 50H55.0001C55.0001 48.6193 54.4405 47.3693 53.5356 46.4645L82.527 17.4731C86.7985 21.7446 90.1869 26.8156 92.4986 32.3966Z"
              fill={`url(#${id}_linear_0_1)`}
            />
            <path
              opacity="0.1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M45 50L4 50C4 56.0408 5.18983 62.0225 7.50154 67.6034C9.81326 73.1844 13.2016 78.2554 17.4731 82.5269L46.4645 53.5355C45.5596 52.6307 45 51.3807 45 50Z"
              fill={`url(#${id}_linear_0_1)`}
            />
            <path
              opacity="0.1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50 63C57.1797 63 63 57.1797 63 50C63 42.8203 57.1797 37 50 37C42.8203 37 37 42.8203 37 50C37 57.1797 42.8203 63 50 63ZM50 55C52.7614 55 55 52.7614 55 50C55 47.2386 52.7614 45 50 45C47.2386 45 45 47.2386 45 50C45 52.7614 47.2386 55 50 55Z"
              fill={`url(#${id}_linear_0_1)`}
            />
            <defs>
              <linearGradient
                id={`${id}_linear_0_1`}
                x1="50"
                y1="100"
                x2="50"
                y2="-1.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor={s.color} />
                <stop offset="1" stopColor={s.color} stopOpacity="0.3" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      )}
    />
  );
};

export default VinylDisc;
