import {
  OrnamentationDef,
  OrnamentationType,
} from "../../constants/ornaments.constants";

export const Ornamentation_CustomDef: OrnamentationDef = {
  identifier: OrnamentationType.custom,
  displayName: "Custom",
  defaultSymbol: "",
  symbolFactory: orn => "",
  generator: (note, options) => {
    return [];
  },
};
