import {
  configure,
  isComputed,
  isComputedProp,
  isObservable,
  isObservableProp,
  makeAutoObservable,
  makeObservable,
  observable,
} from "mobx";
import { isDevelopment } from "./base/env";

/** enable MobX strict mode to prevent accidental non-action changes */
export const configureMobx = () =>
  configure({
    enforceActions: "observed",
    computedRequiresReaction: true,
    // reactionRequiresObservable: true,
    // observableRequiresReaction: true,
    // disableErrorBoundaries: true
  });

if (isDevelopment) {
  Reflect.set(window, "observable", observable);
  Reflect.set(window, "makeObservable", makeObservable);
  Reflect.set(window, "makeAutoObservable", makeAutoObservable);
  Reflect.set(window, "isObservable", isObservable);
  Reflect.set(window, "isObservableProp", isObservableProp);
  Reflect.set(window, "isComputed", isComputed);
  Reflect.set(window, "isComputedProp", isComputedProp);
}
