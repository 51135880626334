import {
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  StandardInstrumentOptions,
} from "../../@types";
import { STATIC_HOST } from "../../env";
import { ColorPalette } from "../../theming/colorPalette";
import { createCompositeSamplerInstrumentFactory } from "../_factory/compositeSamplerInstrumentFactory";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";
import RenaissanceOrganControlPanel from "./RenaissanceOrganControlPanel";

export type RenaissanceOrganSamplerName = "8'" | "4'" | "full";

export type RenaissanceOrganOptions = StandardInstrumentOptions & {
  activatedSamplers: RenaissanceOrganSamplerName[];
};

export const makeDefaultRenaissanceOrganOptions =
  (): RenaissanceOrganOptions => ({
    ...makeDefaultInstrumentOptions(),
    activatedSamplers: ["full"],
  });

export type RenaissanceOrganType = Instrument<RenaissanceOrganOptions>;

export const RenaissanceOrganName = "renaissance-organ";

export const RenaissanceOrganMeta: InstrumentMeta<RenaissanceOrganOptions> = {
  name: RenaissanceOrganName,
  displayName: "Renaissance Organ",
  source: "VS Chamber Orchestra: Community Edition",
  sourceWebsite: "https://github.com/sgossner/VSCO-2-CE",
  icon: "organ",
  range: [[31, 89]] as InstrumentRange,
  defaultColor: ColorPalette.orange,
  ControlPanel: RenaissanceOrganControlPanel,
  type: "keyboards",
};

export const makeRenaissanceOrgan = createCompositeSamplerInstrumentFactory({
  defaultOptionsBagBuilder: makeDefaultRenaissanceOrganOptions,
  instrumentMeta: RenaissanceOrganMeta,
  getters: {
    samplers: (n, o) => o?.activatedSamplers ?? ["full"],
    velocity: {
      attack: () => 0.7,
    },
  },
  compositeSamplerConfigArray: [
    {
      name: "4'",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/renaissance-organ/4/`,
          extendable: true,
          urls: {
            "A#1": "RenOrgan_4foot_Room_Asharp1_rr1.mp3",
            "A#2": "RenOrgan_4foot_Room_Asharp2_rr1.mp3",
            "A#3": "RenOrgan_4foot_Room_Asharp3_rr1.mp3",
            "A#4": "RenOrgan_4foot_Room_Asharp4_rr1.mp3",
            C1: "RenOrgan_4foot_Room_C1_rr1.mp3",
            C2: "RenOrgan_4foot_Room_C2_rr1.mp3",
            C3: "RenOrgan_4foot_Room_C3_rr1.mp3",
            C4: "RenOrgan_4foot_Room_C4_rr1.mp3",
            C5: "RenOrgan_4foot_Room_C5_rr1.mp3",
            D1: "RenOrgan_4foot_Room_D1_rr1.mp3",
            D2: "RenOrgan_4foot_Room_D2_rr1.mp3",
            D3: "RenOrgan_4foot_Room_D3_rr1.mp3",
            D4: "RenOrgan_4foot_Room_D4_rr1.mp3",
            D5: "RenOrgan_4foot_Room_D5_rr1.mp3",
            E1: "RenOrgan_4foot_Room_E1_rr1.mp3",
            E2: "RenOrgan_4foot_Room_E2_rr1.mp3",
            E3: "RenOrgan_4foot_Room_E3_rr1.mp3",
            E4: "RenOrgan_4foot_Room_E4_rr1.mp3",
            E5: "RenOrgan_4foot_Room_E5_rr1.mp3",
            "F#1": "RenOrgan_4foot_Room_Fsharp1_rr1.mp3",
            "F#2": "RenOrgan_4foot_Room_Fsharp2_rr1.mp3",
            "F#3": "RenOrgan_4foot_Room_Fsharp3_rr1.mp3",
            "F#4": "RenOrgan_4foot_Room_Fsharp4_rr1.mp3",
            "G#1": "RenOrgan_4foot_Room_Gsharp1_rr1.mp3",
            "G#2": "RenOrgan_4foot_Room_Gsharp2_rr1.mp3",
            "G#3": "RenOrgan_4foot_Room_Gsharp3_rr1.mp3",
            "G#4": "RenOrgan_4foot_Room_Gsharp4_rr1.mp3",
          },
          volume: 12,
          octaveOffset: 1,
        },
      },
    },
    {
      name: "8'",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/renaissance-organ/8/`,
          extendable: true,
          urls: {
            C4: "RenOrgan_8foot_Room_C4_rr1.mp3",
            "A#1": "RenOrgan_8foot_Room_Asharp1_rr1.mp3",
            "A#2": "RenOrgan_8foot_Room_Asharp2_rr1.mp3",
            "A#3": "RenOrgan_8foot_Room_Asharp3_rr1.mp3",
            "A#4": "RenOrgan_8foot_Room_Asharp4_rr1.mp3",
            C1: "RenOrgan_8foot_Room_C1_rr1.mp3",
            C2: "RenOrgan_8foot_Room_C2_rr1.mp3",
            C3: "RenOrgan_8foot_Room_C3_rr1.mp3",
            C5: "RenOrgan_8foot_Room_C5_rr1.mp3",
            D1: "RenOrgan_8foot_Room_D1_rr1.mp3",
            D2: "RenOrgan_8foot_Room_D2_rr1.mp3",
            D3: "RenOrgan_8foot_Room_D3_rr1.mp3",
            D4: "RenOrgan_8foot_Room_D4_rr1.mp3",
            D5: "RenOrgan_8foot_Room_D5_rr1.mp3",
            E1: "RenOrgan_8foot_Room_E1_rr1.mp3",
            E2: "RenOrgan_8foot_Room_E2_rr1.mp3",
            E3: "RenOrgan_8foot_Room_E3_rr1.mp3",
            E4: "RenOrgan_8foot_Room_E4_rr1.mp3",
            E5: "RenOrgan_8foot_Room_E5_rr1.mp3",
            "F#1": "RenOrgan_8foot_Room_Fsharp1_rr1.mp3",
            "F#2": "RenOrgan_8foot_Room_Fsharp2_rr1.mp3",
            "F#3": "RenOrgan_8foot_Room_Fsharp3_rr1.mp3",
            "F#4": "RenOrgan_8foot_Room_Fsharp4_rr1.mp3",
            "G#1": "RenOrgan_8foot_Room_Gsharp1_rr1.mp3",
            "G#2": "RenOrgan_8foot_Room_Gsharp2_rr1.mp3",
            "G#3": "RenOrgan_8foot_Room_Gsharp3_rr1.mp3",
            "G#4": "RenOrgan_8foot_Room_Gsharp4_rr1.mp3",
          },
          volume: 12,
          octaveOffset: 1,
        },
      },
    },
    {
      name: "full",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/renaissance-organ/full/`,
          extendable: true,
          urls: {
            C1: "RenOrgan_Full_Room_C1_rr1.mp3",
            "A#1": "RenOrgan_Full_Room_Asharp1_rr1.mp3",
            "A#2": "RenOrgan_Full_Room_Asharp2_rr1.mp3",
            "A#3": "RenOrgan_Full_Room_Asharp3_rr1.mp3",
            "A#4": "RenOrgan_Full_Room_Asharp4_rr1.mp3",
            C2: "RenOrgan_Full_Room_C2_rr1.mp3",
            C3: "RenOrgan_Full_Room_C3_rr1.mp3",
            C4: "RenOrgan_Full_Room_C4_rr1.mp3",
            C5: "RenOrgan_Full_Room_C5_rr1.mp3",
            D1: "RenOrgan_Full_Room_D1_rr1.mp3",
            D2: "RenOrgan_Full_Room_D2_rr1.mp3",
            D3: "RenOrgan_Full_Room_D3_rr1.mp3",
            D4: "RenOrgan_Full_Room_D4_rr1.mp3",
            D5: "RenOrgan_Full_Room_D5_rr1.mp3",
            E1: "RenOrgan_Full_Room_E1_rr1.mp3",
            E2: "RenOrgan_Full_Room_E2_rr1.mp3",
            E3: "RenOrgan_Full_Room_E3_rr1.mp3",
            E4: "RenOrgan_Full_Room_E4_rr1.mp3",
            E5: "RenOrgan_Full_Room_E5_rr1.mp3",
            "F#1": "RenOrgan_Full_Room_Fsharp1_rr1.mp3",
            "F#2": "RenOrgan_Full_Room_Fsharp2_rr1.mp3",
            "F#3": "RenOrgan_Full_Room_Fsharp3_rr1.mp3",
            "F#4": "RenOrgan_Full_Room_Fsharp4_rr1.mp3",
            "G#1": "RenOrgan_Full_Room_Gsharp1_rr1.mp3",
            "G#2": "RenOrgan_Full_Room_Gsharp2_rr1.mp3",
            "G#3": "RenOrgan_Full_Room_Gsharp3_rr1.mp3",
            "G#4": "RenOrgan_Full_Room_Gsharp4_rr1.mp3",
          },
          volume: 12,
          octaveOffset: 1,
        },
      },
    },
  ],
});
