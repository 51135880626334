/** @jsxImportSource @emotion/react */
import { action, flow, observable } from "mobx";
import { Observer, useLocalObservable } from "mobx-react-lite";
import React, { ReactNode, RefObject } from "react";
import {
  VAR_PanelBackdropFilter,
  VAR_PanelBackground,
  VAR_PanelBorder,
  VAR_ShadowMedium,
} from "../../../constants/cssCustomProperties.constants";
import { ValidPoint } from "../../@types";
import { CSSPartial } from "../../@types/css.types";
import { useControllers } from "../../hooks/rootContext.hooks";
import { useCreateResizeQueryWithRef } from "../../hooks/useCreateResizeQueryWithRef.hook";
import cx from "../../utils/className.utils";
import { cVar } from "../../utils/customProperties.utils";
import {
  makeObservableStore,
  useProps,
  useStore,
} from "../../utils/mobx.utils";
import { getRandomNumericString } from "../../utils/random.utils";
import { isNumber, isString } from "../../utils/typeChecks.utils";
import resolveAfter from "../../utils/waiters.utils";
import ClickOutside from "../ClickOutside";
import LineSeparator from "../LineSeparator";
import { IconName } from "../Symbols/iconDefs/_index.iconDefs";
import ContextMenuItem from "./ContextMenuItem";
import {
  getClientXFromMouseOrTouchEvent,
  getClientYFromMouseOrTouchEvent,
} from "../../utils/events.utils";

export type ContextMenuItemConfigObject = {
  identifier: string;
  Label: ReactNode;
  icon?: IconName;
  action?: () => void;
  disabled?: boolean;
  color?: string;
  children?: ContextMenuItemConfig[];
};

export enum ContextMenuDecoration {
  "separator" = "separator",
}

export type ContextMenuItemConfig =
  | ContextMenuDecoration.separator
  | ContextMenuItemConfigObject;

type ContextMenuProps = {
  controller?: ContextMenuController;
  configSet?: ContextMenuItemConfig[];
  origin?: ValidPoint;
  className?: string;
  onExecuteItem?: () => void;
  onShouldClose?: () => void;
  minWidth?: number;
  color?: string | null;
};

const OPENED_CONTEXT_MENUS = observable(new Set<ContextMenuController>());
export const clearAllContextMenus = action(() => OPENED_CONTEXT_MENUS.clear());
export const hasAnyOpenedContextMenus = () => OPENED_CONTEXT_MENUS.size > 0;

export const makeContextMenuController = (o: {
  name?: string;
  configSet: ContextMenuItemConfig[];
  minWidth?: number | string;
  offsetX?: number;
  offsetY?: number;
}) => {
  const s = makeObservableStore({
    get name() {
      return o.name ?? getRandomNumericString();
    },
    get configSet() {
      return o.configSet;
    },
    get shouldRender(): boolean {
      return OPENED_CONTEXT_MENUS.has(s);
    },
    get isOpen(): boolean {
      return s.shouldRender;
    },
    get minWidth() {
      return o.minWidth || "12.5em";
    },
    origin: { x: 0, y: 0 },
    toggle: (
      eventOrRef:
        | MouseEvent
        | React.MouseEvent
        | RefObject<Element | null>
        | React.PointerEvent
        | React.TouchEvent,
      newValue?: boolean
    ) =>
      flow(function* () {
        if (newValue) {
          s.close();
        } else if (newValue === false || s.configSet.length === 0) {
          s.close();
          return;
        }
        const e = "target" in eventOrRef ? eventOrRef : undefined;
        const ref = "current" in eventOrRef ? eventOrRef : undefined;
        const wasOpen = s.isOpen;
        if (OPENED_CONTEXT_MENUS.size > 0) {
          OPENED_CONTEXT_MENUS.clear();
          if (wasOpen && !newValue) return;
          yield resolveAfter(300);
        }
        if (e) {
          s.origin.x =
            getClientXFromMouseOrTouchEvent(e) + 1 + (o.offsetX ?? 0);
          s.origin.y =
            getClientYFromMouseOrTouchEvent(e) + 1 + (o.offsetY ?? 0);
        }
        if (ref?.current) {
          const bb = ref.current.getBoundingClientRect();
          s.origin.x = bb.x + (o.offsetX ?? 0);
          s.origin.y = bb.y + bb.height + (o.offsetY ?? 0);
        }
        OPENED_CONTEXT_MENUS.add(s);
      })(),

    open: (
      eventOrRef:
        | MouseEvent
        | React.MouseEvent
        | RefObject<Element | null>
        | React.PointerEvent
        | React.TouchEvent
    ) => {
      return s.toggle(eventOrRef, true);
    },
    close() {
      OPENED_CONTEXT_MENUS.clear();
    },
    openedSubmenu: null as ContextMenuItemConfig | null,
    execute: (item: ContextMenuItemConfigObject) => {
      item.action?.();
      s.close();
    },
  });
  return s;
};

export const useContextMenuController = (o: {
  name?: string;
  configSet: ContextMenuItemConfig[];
  minWidth?: number | string;
  offsetX?: number;
  offsetY?: number;
}) => useLocalObservable(() => makeContextMenuController(o));

export type ContextMenuController = ReturnType<
  typeof makeContextMenuController
>;

const ContextMenu: React.FC<ContextMenuProps> = props => {
  const { UI, PORTALS } = useControllers();

  const p = useProps(props);

  const { ref, query } = useCreateResizeQueryWithRef<HTMLDivElement>({
    defaultWidth: action(() =>
      isNumber(p.controller?.minWidth) ? p.controller?.minWidth : 192
    )(),
  });

  const s = useStore(() => ({
    get shouldRender() {
      return s.configSet.length > 0 && (p.controller?.shouldRender ?? true);
    },
    get origin() {
      return p.controller?.origin ?? p.origin ?? { x: 0, y: 0 };
    },
    get configSet() {
      return p.controller?.configSet ?? p.configSet ?? [];
    },
    get width() {
      return query.width;
    },
    get availableSpace(): Record<"left" | "right" | "top" | "bottom", number> {
      return {
        left: s.origin.x,
        right: UI.appWidth - s.origin.x,
        top: s.origin.y,
        bottom: UI.appHeight - s.origin.y,
      };
    },
    get height() {
      return query.height;
    },
    get maxHeight(): number {
      return Math.max(s.availableSpace.top, s.availableSpace.bottom);
    },
    get maxWidth(): number {
      return Math.max(s.availableSpace.left, s.availableSpace.right);
    },
    get xDirection(): string {
      return s.width < s.availableSpace.right
        ? "right"
        : s.width < s.availableSpace.left
        ? "left"
        : "right";
    },
    get yDirection(): string {
      return s.height < s.availableSpace.bottom
        ? "bottom"
        : s.height < s.availableSpace.top
        ? "top"
        : "bottom";
    },
    get hasNoIcons(): boolean {
      return s.configSet
        .filter(i => !isString(i))
        .every(c => !(c as ContextMenuItemConfigObject).icon);
    },
    handleClickOutside: async () => {
      await resolveAfter(100);
      p.controller?.close();
      p.onShouldClose?.();
    },
    executeItem: (item: ContextMenuItemConfigObject) => {
      // console.info("Executing menu command", item);
      if (p.controller) {
        p.controller.execute(item);
        p.controller.close();
      } else {
        item.action?.();
        p.onShouldClose?.();
      }
      p.onExecuteItem?.();
    },
    get inner() {
      return (
        <div css={style.listInnerFrame}>
          {s.configSet.map((config, i) =>
            config === ContextMenuDecoration.separator ? (
              <LineSeparator opacity={0.5} key={i} margin=".25em 0" />
            ) : (
              <ContextMenuItem
                controller={p.controller}
                config={config}
                key={config.identifier}
                onClick={s.executeItem}
                noIcon={s.hasNoIcons}
                color={p.color}
              />
            )
          )}
        </div>
      );
    },
  }));

  const style = useStore(() => ({
    get wrapper(): CSSPartial {
      return {
        // padding: '.25em',
      };
    },
    get menu(): CSSPartial {
      return {
        position: "fixed",
        top: s.yDirection === "bottom" ? s.origin.y : s.origin.y - s.height,
        left: s.xDirection === "right" ? s.origin.x : s.origin.x - s.width,
        transform: "translate(-1px, -1px)",
        minWidth: p.controller?.minWidth ?? p.minWidth ?? 192,
        maxWidth: s.maxWidth,
        maxHeight: s.maxHeight,
        backgroundColor: cVar(VAR_PanelBackground),
        backdropFilter: cVar(VAR_PanelBackdropFilter),
        border: cVar(VAR_PanelBorder),
        boxShadow: cVar(VAR_ShadowMedium),
        fontSize: "1.4rem",
        borderRadius: ".25em",
        padding: ".25em",
      };
    },
    get listInnerFrame(): CSSPartial {
      return {};
    },
  }));

  return (
    <Observer
      children={() =>
        s.shouldRender
          ? PORTALS.render(
              // p.controller ? (
              <ClickOutside
                className={cx("ContextMenu", p.className)}
                onClickOutside={s.handleClickOutside}
                innerRef={ref}
                css={style.menu}
              >
                {s.inner}
              </ClickOutside>
              // ) : (
              // s.inner
              // )
            )
          : null
      }
    />
  );
};

export default ContextMenu;
