import { observable } from "mobx";
import type { ComposerInstance } from "../components/composer/useCreateComposerInstance";
import { ControllerBase } from "./_controller.types";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";

export const makeComposerController = () => {
  const c: ComposerController = observable({
    ...makeControllerBase("COMPOSER"),
    instance: null as ComposerInstance | null,
  });

  c.init = makeRootControllerChildInitFn(c, () => {});

  return c;
};

export type ComposerController = ControllerBase & {
  instance: ComposerInstance | null;
};
