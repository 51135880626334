/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { CSSPartial } from "../../base/@types/css.types";
import { clampLines } from "../../base/styles/helpers/clampLines";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopMl,
  fromTablet,
  mediaFromDesktop,
  mediaFromDesktopMd,
  mediaFromDesktopMl,
  uptoDesktop,
} from "../../base/styles/helpers/mediaQueries.styling";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import {
  fg70,
  varFontSerif,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";
import { Collection } from "../../models/Collection.model";
import ArtistEntry from "../directory/ArtistEntry";
import { AppPageHeaderPreHeading } from "../layout/AppPage";
import CollectionCoverImage from "./CollectionCoverImage";

type CollectionPageHeaderProps = {
  collection: Collection;
  End?: ReactNode;
  linkTo?: "discover" | "workshop";
};

const style = {
  content: {} as CSSPartial,
  header: {
    display: "grid",
    gridGap: "2em",
    [fromTablet]: {
      gridTemplateColumns: "auto minmax(0, 1fr)",
      gridTemplateRows: "auto auto",
      gridTemplateAreas: `"cover text" "controls controls"`,
    },
    [fromDesktop]: {
      gridTemplateColumns: "auto minmax(0, 1fr) auto",
      gridTemplateAreas: `"cover text controls"`,
      alignItems: "end",
    },
  } as CSSPartial,
  coverDisc: {
    width: "12rem",
    [fromTablet]: {
      gridArea: "cover",
      width: "16rem",
    },
    [fromDesktop]: {
      width: "18rem",
    },
    [fromDesktopMd]: {
      width: "22.5rem",
    },
    [fromDesktopMl]: {
      width: "25rem",
      ".hasSubheading &": {
        width: "28.5rem",
      },
    },
  } as CSSPartial,
  headerText: {
    [fromTablet]: {
      gridArea: "text",
    },
  } as CSSPartial,
  title: {
    fontSize: "3.8rem",
    lineHeight: "1.15",
    fontWeight: 200,
    fontFamily: varFontSerif,
    marginTop: ".125em",
    ...mediaFromDesktopMd({
      fontWeight: 100,
      fontSize: "4.8rem",
    }),
    ...mediaFromDesktopMl({
      fontSize: "5.2rem",
      letterSpacing: "-0.01em",
    }),
  } as CSSPartial,
  subheading: {
    fontSize: "1.4rem",
    fontWeight: 300,
    fontFamily: varFontSerif,
    color: fg70,
    fontStyle: "italic",
    margin: ".5em 0",
    ...mediaFromDesktop({
      fontSize: "1.6rem",
    }),
    [fromDesktopMd]: {
      fontSize: "1.8rem",
    },
  } as CSSPartial,
  artistsLine: {
    fontSize: "1.6rem",
    fontFamily: varFontSerif,
    fontWeight: 300,
    marginTop: ".75em",
    ...mediaFromDesktopMd({
      fontSize: "1.8rem",
    }),
    a: {
      padding: 0,
      textDecoration: "none",
      "&:hover": {
        color: varPrimary,
      },
      "+ a": {
        marginTop: ".5em",
      },
    },
  } as CSSPartial,
  description: {
    maxWidth: "48em",
    lineHeight: "1.4",
    marginTop: "1em",
    ...clampLines(),
    opacity: 0.8,
  } as CSSPartial,
  headerEndSlot: {
    [fromTablet]: {
      gridArea: "controls",
    },
    [uptoDesktop]: {
      paddingTop: "1em",
    },
    [fromDesktop]: {
      paddingLeft: "1em",
    },
  } as CSSPartial,
};

const CollectionPageHeader: React.FC<CollectionPageHeaderProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get hasDescription(): boolean {
      return !!p.collection.$.description?.trim();
    },
  }));
  return (
    <Observer
      children={() => (
        <header
          css={style.header}
          className={cx(p.collection.$.subheading && "hasSubheading")}
        >
          <CollectionCoverImage
            collection={p.collection}
            css={style.coverDisc}
          />
          <div css={style.headerText}>
            <AppPageHeaderPreHeading>
              <Link to={`/${p.linkTo ?? "discover"}/collections`}>
                Collection
              </Link>
            </AppPageHeaderPreHeading>
            <h1 css={style.title}>{p.collection.$.name}</h1>
            {p.collection.$.subheading && (
              <p css={style.subheading}>{p.collection.$.subheading}</p>
            )}
            {p.collection.artists.length > 0 && (
              <div css={style.artistsLine}>
                {p.collection.artists.map(a => (
                  <ArtistEntry
                    asLink
                    key={a._id}
                    artist={a}
                    linkTo={p.linkTo}
                    appearance="entry"
                    photoSize="2em"
                  />
                ))}
              </div>
            )}
            {s.hasDescription && (
              <p css={style.description}>{p.collection.$.description}</p>
            )}
          </div>
          {p.End && <div css={style.headerEndSlot}>{p.End}</div>}
        </header>
      )}
    />
  );
};

export default CollectionPageHeader;
