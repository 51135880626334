/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { action, when } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { useObservableRef } from "../hooks/useObservableRef.hook";
import { clearAnimatedProps, createEase } from "../utils/gsap.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import cx from "../utils/className.utils";
import { fg05, fg07 } from "../../constants/cssCustomProperties.constants";

type AccordionProps = {
  id?: string;
  className?: string;
  style?: CSSProperties;
  Summary: ReactNode;
  openOnMount?: boolean;
  children: () => React.ReactElement | null;
};

const accordionEase = createEase("M0,0,C0.03,0.916,0.198,1,1,1");

const AccordionWrap = styled.div``;
const SummaryWrap = styled.div``;
const Content = styled.div`
  overflow: hidden;
`;

const Accordion = (props: AccordionProps) => {
  const p = useProps(props);
  const contentRef = useObservableRef();
  const s = useStore(() => ({
    isOpen: p.openOnMount ?? false,
    willClose: false,
    open: () => {
      s.isOpen = true;
      when(
        () => !!contentRef.current,
        () => {
          gsap.fromTo(
            contentRef.current,
            {
              height: 0,
              opacity: 0,
            },
            {
              height: contentRef.current?.clientHeight ?? "auto",
              duration: 0.3,
              opacity: 1,
              ease: accordionEase,
              onComplete: action(() => {
                clearAnimatedProps(contentRef.current, ["height", "opacity"]);
              }),
            }
          );
        }
      );
    },
    close: () => {
      const heightBeforeClose = s.isOpen
        ? contentRef.current?.clientHeight ?? 0
        : 0;
      s.willClose = true;
      gsap.fromTo(
        contentRef.current,
        {
          height: heightBeforeClose,
          opacity: 1,
        },
        {
          height: 0,
          opacity: 0,
          duration: 0.2,
          ease: accordionEase,
          onComplete: action(() => {
            s.isOpen = false;
            s.willClose = false;
            clearAnimatedProps(contentRef.current, ["height", "opacity"]);
          }),
        }
      );
    },
    toggleOpenState: () => {
      if (s.isOpen) s.close();
      else s.open();
    },
  }));
  return (
    <Observer
      children={() => {
        const { Summary, ...attr } = p;
        return (
          <AccordionWrap
            id={p.id}
            data-open={s.isOpen && !s.willClose}
            {...attr}
          >
            <SummaryWrap onClick={s.toggleOpenState}>{Summary}</SummaryWrap>
            {s.isOpen ? (
              <Content ref={contentRef}>
                <Observer children={p.children} />
              </Content>
            ) : null}
          </AccordionWrap>
        );
      }}
    />
  );
};

const AccordionSummaryWithChevronWrap = styled.header`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-gap: 1em;
  align-items: center;
  padding: 1em 0;
  [data-open="false"] > div > & {
    svg.chevron {
      transform: rotate(180deg);
    }
  }
  &.withBackdrop {
    padding: 1em;
    background-color: ${fg05};
    [data-open="false"] > & {
      border-radius: 2px;
    }
    [data-open="true"] > & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:hover {
      background-color: ${fg07};
    }
  }
`;

export const AccordionSummaryWithChevron = (
  p: PropsWithChildren<{
    withBackdrop?: boolean;
  }>
) => {
  return (
    <AccordionSummaryWithChevronWrap
      className={cx(p.withBackdrop && "withBackdrop")}
    >
      <div>{p.children}</div>
      <svg className="chevron" width="9" height="6" viewBox="0 0 9 6">
        <path d="M1 1L4.5 4.5L8 1" stroke="currentColor" fill="none" />
      </svg>
    </AccordionSummaryWithChevronWrap>
  );
};

export default Accordion;
