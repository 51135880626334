/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Chord } from "../../@types";
import styled from "@emotion/styled";
import {
  VAR_InputBorder,
  VAR_LineWidth,
  fg05,
  fg10,
} from "../../constants/cssCustomProperties.constants";
import { cVar } from "../../base/utils/customProperties.utils";
import { useProps } from "../../base/utils/mobx.utils";

type ChordPropertiesDisplayProps = { chord: Chord };

const Wrap = styled.div`
  background-color: ${fg05};
  border-radius: 0.5em;
  border: ${cVar(VAR_InputBorder)};
  padding: 1em;
  user-select: text;
  line-height: 1.5;
  h3 {
    border-bottom: ${cVar(VAR_InputBorder)};
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  > div {
    + div {
      margin-top: 0.6rem;
      border-top: ${cVar(VAR_LineWidth)} dashed ${fg10};
      padding-top: 0.6rem;
    }
  }
  code {
    padding: 0 0.2em;
    background-color: ${fg05};
    border: ${cVar(VAR_InputBorder)};
    border-radius: 2px;
    margin-right: 0.2em;
    font-size: 85%;
  }
`;

const ChordPropertiesDisplay: React.FC<ChordPropertiesDisplayProps> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <Wrap>
          {p.chord.matchedChords.length > 1 && <h3>Possible chords</h3>}
          {p.chord.matchedChords.map(c => (
            <div key={c.symbol}>
              <h4>{c.symbol}</h4>
              <p>{c.name}</p>
            </div>
          ))}
        </Wrap>
      )}
    />
  );
};

export default ChordPropertiesDisplay;
