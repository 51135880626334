/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Pattern } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import { UNITS } from "../../base/constants/units.constant";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { autoPluralizeWithNumber } from "../../base/utils/string.utils";
import {
  fg15,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";
import AtomThumbnailCanvas from "./AtomThumbnailCanvas";

type PatternEntryProps = {
  pattern: Pattern;
  withBorder?: boolean;
  selectPatternOnClick?: boolean;
};

const entryStyle: CSSPartial = {
  "> div": {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    minHeight: "4em",
  },
  figure: {
    margin: 0,
    padding: "1em 0",
    height: "5em",
    canvas: {
      maxWidth: "100%",
      width: "auto !important",
      height: "3em !important",
      aspectRatio: "none",
      imageRendering: "pixelated",
    },
  },
  header: {
    "> *": {
      display: "inline-block",
    },
    span: {
      marginLeft: ".4em",
      marginRight: ".4em",
    },
  },
  h3: {
    fontSize: "inherit",
  },
  "&.interactable": {
    cursor: "pointer",
  },
  "&.withBorder": {
    border: `${UNITS.lineWidth}px solid ${fg15}`,
    padding: "1em",
    "&.interactable": {
      "&:hover": {
        border: `${UNITS.lineWidth}px solid ${varPrimary}`,
      },
    },
  },
};

const PatternEntry: React.FC<PatternEntryProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    handleClick: () => {
      if (p.selectPatternOnClick) {
        p.pattern.select();
      }
    },
  }));
  return (
    <Observer
      children={() => (
        <div
          css={entryStyle}
          className={cx(
            p.withBorder && "withBorder",
            p.selectPatternOnClick && "interactable"
          )}
          onClick={s.handleClick}
        >
          <figure>
            <AtomThumbnailCanvas target={p.pattern} />
          </figure>
          <header>
            <h3>{p.pattern.displayName}</h3>
            <span>•</span>
            <p>{autoPluralizeWithNumber(p.pattern.replicas, "replica")}</p>
          </header>
        </div>
      )}
    />
  );
};

export default PatternEntry;
