import axios from "axios";
import { action } from "mobx";

export const readFileAsText = (file: File) =>
  new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.onload = action(event => {
      const content = `${event.target?.result ?? ""}`;
      resolve(content);
    });
    reader.readAsText(file);
  });
export const readFileAsArrayBuffer = (file: File) =>
  new Promise<ArrayBuffer | null>(resolve => {
    const reader = new FileReader();
    reader.onload = action(event => {
      const content = (event.target?.result ?? null) as ArrayBuffer | null;
      resolve(content);
    });
    reader.readAsArrayBuffer(file);
  });

export const getRemoteFileAsBase64 = async (url: string) => {
  const response = await axios.get(url, {
    responseType: "arraybuffer",
  });
  return `data:${response.headers["content-type"]};base64,${Buffer.from(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    response.data,
    "base64"
  ).toString("base64")}`;
};
