import { action, observable } from "mobx";
import { isMac } from "../base/utils/browsersAndPlatforms.utils";
import {
  macKeyboardShortcuts,
  windowsKeyboardShortcuts,
} from "../constants/keyboardShortcuts.constants";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";

const makeObjectOfKeysToWatchPressStateFor = () => {
  const s = observable({
    enter: false,
    meta: false,
    ctrl: false,
    alt: false,
    shift: false,
    get metaOrCtrl() {
      return isMac ? s.meta : s.ctrl;
    },
    get controlOrAlt() {
      return isMac ? s.ctrl : s.alt;
    },
    get optionOrAlt() {
      return isMac ? s.alt : s.alt;
    },
    get optionOrCtrl() {
      return isMac ? s.alt : s.ctrl;
    },
  });
  return s;
};

export type KeysToWatchPressStateFor = Exclude<
  keyof ReturnType<typeof makeObjectOfKeysToWatchPressStateFor>,
  "metaOrCtrl" | "controlOrAlt" | "optionOrAlt" | "optionOrCtrl"
>;

export const makeKeyboardController = () => {
  const c = observable({
    ...makeControllerBase("KEYBOARD"),
    pressed: makeObjectOfKeysToWatchPressStateFor(),
    registerPressStart: (key: KeysToWatchPressStateFor) => {
      c.pressed[key] = true;
    },
    registerPressEnd: (key: KeysToWatchPressStateFor) => {
      c.pressed[key] = false;
    },
    releaseAll: () => {
      c.registerPressEnd("enter");
      c.registerPressEnd("meta");
      c.registerPressEnd("ctrl");
      c.registerPressEnd("alt");
      c.registerPressEnd("shift");
    },
    get shortcuts() {
      if (isMac) return macKeyboardShortcuts;
      return windowsKeyboardShortcuts;
    },
  });

  c.init = makeRootControllerChildInitFn(
    c,
    action(() => {
      c.ready = true;
      window.addEventListener("keydown", e => {
        switch (e.key) {
          case "Enter":
            c.registerPressStart("enter");
            break;
          case "Control":
            c.registerPressStart("ctrl");
            break;
          case "Alt":
            c.registerPressStart("alt");
            break;
          case "Meta":
            c.registerPressStart("meta");
            break;
          case "Shift":
            c.registerPressStart("shift");
            break;
        }
      });
      window.addEventListener("keyup", e => {
        switch (e.key) {
          case "Enter":
            c.registerPressEnd("enter");
            break;
          case "Control":
            c.registerPressEnd("ctrl");
            break;
          case "Alt":
            c.registerPressEnd("alt");
            break;
          case "Meta":
            c.registerPressEnd("meta");
            break;
          case "Shift":
            c.registerPressEnd("shift");
            break;
        }
      });
      window.addEventListener("blur", c.releaseAll);
    })
  );

  return c;
};

export type KeyboardController = ReturnType<typeof makeKeyboardController>;
