import { makeIconDef } from "./makeIconDef";

export const VoiceIconDef = {
  voice: makeIconDef("voice", {
    regular: () => (
      <path
        d="M2.5 12.5H7.13962C7.35484 12.5 7.54591 12.3623 7.61396 12.1581L9.92916 5.21252C10.0895 4.73143 10.7816 4.76714 10.8916 5.26217L14.1084 19.7378C14.2184 20.2329 14.9105 20.2686 15.0708 19.7875L17.386 12.8419C17.4541 12.6377 17.6452 12.5 17.8604 12.5H22.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
    ),
  }),
};
