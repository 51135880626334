/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import Button from "../../base/components/Button";
import Panel, {
  PanelCloseButton,
  PanelFooter,
  PanelHeader,
  PanelInner,
} from "../../base/components/Panel";
import SelectableItem from "../../base/components/SelectableItem";
import Serif from "../../base/components/Serif";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { formatAsSearchString } from "../../base/utils/search.utils";
import { varFontSerif } from "../../constants/cssCustomProperties.constants";
import { Collection } from "../../models/Collection.model";
import NewCollectionButton from "../controls/NewCollectionButton";
import CollectionEntry from "./CollectionEntry";

type CollectionFinderProps = {
  onShouldClose: () => void;
  onSelect: (collections: Collection[]) => void;
  title?: string;
  initialSelection?: Collection[];
};

const EmptyWarning = styled.div`
  margin-top: 0.5em;
  opacity: 0.6;
  font-size: 1.8rem;
  font-weight: 200;
  font-family: ${varFontSerif};
`;
const SearchAndAddNewButton = styled.div`
  display: grid;
  align-items: stretch;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-gap: 0.5em;
`;
const Scrollable = styled.div`
  overflow: auto;
  height: 400px;
  margin: 0.5em -0.5em;
  > * + * {
    margin-top: 0.5em;
  }
`;

const CollectionFinder: React.FC<CollectionFinderProps> = props => {
  const { COLLECTIONS } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    selectionSet: new Set<Collection>(p.initialSelection),
    selectionToggler: (collection: Collection) =>
      action(() => {
        if (s.selectionSet.has(collection)) s.selectionSet.delete(collection);
        else s.selectionSet.add(collection);
      }),
    confirmSelection: () => {
      p.onSelect(Array.from(s.selectionSet));
      p.onShouldClose();
    },
    query: "",
    get queryFormatted() {
      return formatAsSearchString(s.query);
    },
    get collections() {
      if (!s.queryFormatted) return COLLECTIONS.ownNonArchived;
      return COLLECTIONS.ownNonArchived.filter(c =>
        formatAsSearchString(c.$.name).includes(s.queryFormatted)
      );
    },
    handlePanelClose: (newCollection?: Collection) => {
      if (newCollection) s.selectionToggler(newCollection)();
    },
  }));
  return (
    <Observer
      children={() => (
        <Panel
          id="collection-finder"
          className="CollectionFinder"
          title={p.title ?? "Collections"}
          canEscape
          onShouldClose={p.onShouldClose}
          width={500}
          moveable
          fullscreen
        >
          <PanelHeader End={<PanelCloseButton onClick={p.onShouldClose} />}>
            <h2>
              <Serif>{p.title ?? "Collections"}</Serif>
            </h2>
          </PanelHeader>
          <PanelInner>
            <SearchAndAddNewButton>
              <TextInput
                form={s}
                field="query"
                placeholder="Search..."
                icon="search"
                autoComplete="off"
                type="search"
                autoFocus
              />
              <NewCollectionButton onShouldClose={s.handlePanelClose} />
            </SearchAndAddNewButton>
            {!s.collections.length && (
              <EmptyWarning>No collections found.</EmptyWarning>
            )}
            <Scrollable className="Scrollable">
              {s.collections.map(a => (
                <SelectableItem
                  key={a._id}
                  selectStateChecker={() => s.selectionSet.has(a)}
                  onClick={s.selectionToggler(a)}
                  padding=".5em"
                >
                  <CollectionEntry
                    collection={a}
                    appearance="entry"
                    coverImageSize="4em"
                  />
                </SelectableItem>
              ))}
            </Scrollable>
          </PanelInner>
          <PanelFooter sticky noPaddingTop>
            <Button onClick={s.confirmSelection} fullWidth>
              Confirm
            </Button>
          </PanelFooter>
        </Panel>
      )}
    />
  );
};

export default CollectionFinder;
