/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import ArtistGallery from "./ArtistGallery";
import { mediaFromDesktop } from "../../base/styles/helpers/mediaQueries.styling";
import { Artist } from "../../models/Artist.model";

type ArtistIndexProps = {
  className?: string;
  artists: Artist[];
  linkTo?: "discover" | "workshop";
};

const ArtistIndex: React.FC<ArtistIndexProps> = props => {
  const p = useProps(props);

  const s = useStore(() => ({
    get all() {
      return [...p.artists].sort((a, b) =>
        a.$.surname > b.$.surname ? 1 : a.$.surname < b.$.surname ? -1 : 0
      );
    },
  }));

  return (
    <Observer
      children={() => (
        <div
          className={cx("ArtistIndex", p.className)}
          css={{
            a: {
              textDecoration: "none",
              fontSize: "1.8rem",
              ...mediaFromDesktop({
                fontSize: "2rem",
                lineHeight: "1.15",
              }),
            },
          }}
        >
          <ArtistGallery artists={s.all} linkTo={p.linkTo} />
        </div>
      )}
    />
  );
};

export default ArtistIndex;
