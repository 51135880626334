/** @jsxImportSource @emotion/react */
import { Observer, observer } from "mobx-react-lite";
import * as React from "react";
import { Navigate } from "react-router-dom";
import LoginForm from "../../base/components/LoginDialog";
import SpaceChildren from "../../base/components/SpaceChildren";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { externalLinkAttrs } from "../../base/utils/links.utils";
import AuthPage from "./components/AuthPage";

interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = observer(props => {
  const { AUTH } = useControllers();
  return (
    <Observer
      children={() => (
        <AuthPage
          className="LoginPage"
          title="Log in"
          displayTitle="Welcome back!"
        >
          {AUTH.isAuthenticated && <Navigate to="/app" />}
          <SpaceChildren>
            <LoginForm includeSubmitButton autoFocus />
            <p>
              ©{" "}
              <a href="https://yile.art" title="Yile Ou" {...externalLinkAttrs}>
                Yile Ou
              </a>
              , 2022
            </p>
          </SpaceChildren>
        </AuthPage>
      )}
    />
  );
});

export default LoginPage;
