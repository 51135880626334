/** @jsxImportSource @emotion/react */
import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import AuthForm from "../../pages/Auth/components/AuthForm";
import { ColorPalette } from "../../theming/colorPalette";
import { useControllers } from "../hooks/rootContext.hooks";
import { spaceChildren } from "../styles/partials/spaceChildren.stylePartials";
import { readAxiosErrorMessage } from "../utils/errors.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import Button from "./Button";
import InfoBox from "./InfoBox";
import TextInput from "./TextInput";

type LoginDialogProps = {
  form?: {
    usernameOrEmail: string;
    password: string;
  };
  includeSubmitButton?: boolean;
  autoFocus?: boolean;
  onSuccess?: () => void;
};

const LoginForm: React.FC<LoginDialogProps> = props => {
  const { AUTH } = useControllers();
  const p = useProps(props);
  const _ = useStore(() => ({
    form: {
      usernameOrEmail: "",
      password: "",
    },
  }));
  const s = useStore(() => ({
    get form() {
      return props.form ?? _.form;
    },
    get style() {
      return {
        padding: "1em",
        ...spaceChildren(".5em"),
      };
    },
    error: null as string | null,
    login: async () =>
      await flow(function* () {
        if (!s.form.usernameOrEmail || !s.form.password) return;
        s.error = null;
        try {
          yield AUTH.login(s.form);
          p.onSuccess?.();
        } catch (e) {
          reportError(e);
          s.error = readAxiosErrorMessage(e);
        }
      })(),
  }));
  return (
    <Observer
      children={() => (
        <AuthForm>
          <TextInput
            form={s.form}
            field="usernameOrEmail"
            type={s.form.usernameOrEmail.includes("@") ? "email" : "text"}
            Label="Username / Email"
            autoCapitalize="off"
            autoFocus={p.autoFocus}
          />
          <TextInput
            form={s.form}
            field="password"
            type="password"
            Label="Password"
            onEnter={s.login}
          />
          {p.includeSubmitButton && (
            <Button
              onClick={s.login}
              color={s.error ? ColorPalette.red : undefined}
              fullWidth
            >
              {s.error ? "Retry" : "Log in"}
            </Button>
          )}

          {s.error && <InfoBox>{s.error}</InfoBox>}

          <p>
            <Link to="/auth/signup" title="Create account">
              Create account
            </Link>
            <span>&emsp;•&emsp;</span>
            <Link to="/auth/forgot-password" title="Forgot password">
              Forgot password
            </Link>
          </p>
        </AuthForm>
      )}
    />
  );
};

export default LoginForm;
