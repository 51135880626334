/** @jsxImportSource @emotion/react */
import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSSPartial } from "../../base/@types/css.types";
import ContextMenu, {
  useContextMenuController,
} from "../../base/components/ContextMenu/ContextMenu";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { mediaFromDesktop } from "../../base/styles/helpers/mediaQueries.styling";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import {
  fg70,
  varFontSerif,
} from "../../constants/cssCustomProperties.constants";
import { Collection } from "../../models/Collection.model";
import CollectionCoverImage from "../shared/CollectionCoverImage";
import ArtistEntry from "./ArtistEntry";
import { makeSlug } from "../../base/utils/slug.utils";

type CollectionEntryProps = {
  collection: Collection;
  appearance?: "disc" | "entry";
  linkTo?: "discover" | "workshop";
  coverImageSize?: string | number;
  enableContextMenu?: boolean;
  showSubheading?: boolean;
  showMeta?: boolean;
  showArtists?: boolean;
};

const discStyle: CSSPartial = {
  display: "grid",
  gridTemplateRows: "auto minmax(0, 1fr)",
  gridGap: ".75em",
  textDecoration: "none",
  "&:hover": {
    filter: "brightness(1.1)",
  },
  "&:active": {
    filter: "brightness(.9)",
  },
};

const entryStyle: CSSPartial = {
  display: "grid",
  gridTemplateColumns: "auto minmax(0, 1fr)",
  gridGap: ".75em",
  alignItems: "center",
  textDecoration: "none",
  "&:hover": {
    filter: "brightness(1.1)",
  },
  "&:active": {
    filter: "brightness(.9)",
  },
};

const titleStyle: CSSPartial = {
  fontSize: "1.8rem",
  fontWeight: 300,
  fontFamily: varFontSerif,
  ...mediaFromDesktop({
    fontSize: "2.4rem",
  }),
};

const subheadingStyle: CSSPartial = {
  fontSize: "1.2rem",
  fontWeight: 400,
  fontFamily: varFontSerif,
  color: fg70,
  fontStyle: "italic",
  margin: ".38em 0",
  ...mediaFromDesktop({
    fontSize: "1.4rem",
  }),
};

const artistsLineStyle: CSSPartial = {
  marginTop: ".25em",
  ".ArtistEntry": {
    textDecoration: "none",
    opacity: 0.5,
    "&:hover": {
      opacity: 1,
    },
  },
};

const CollectionEntry: React.FC<CollectionEntryProps> = props => {
  const { DIALOGS, COLLECTIONS } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get link() {
      return `/${p.linkTo ?? "discover"}/collections/${
        p.collection._id
      }?i=${makeSlug(p.collection.$.name)}`;
    },
    get componentStyle() {
      switch (p.appearance) {
        case "entry":
          return entryStyle;
        case "disc":
        default:
          return discStyle;
      }
    },
    get coverImageSize() {
      if (p.coverImageSize !== undefined) return p.coverImageSize;
      if (p.appearance == "entry") return "8em";
    },
    get sharedAttributes() {
      return {
        css: s.componentStyle,
        onContextMenu: contextMenu.toggle,
      };
    },
    archiveCollection: () =>
      flow(function* () {
        const confirm: boolean = yield DIALOGS.attention({
          Heading: "Confirm",
          Body: `Are you sure you want to delete the collection "${p.collection.$.name}"? You can recover it from your bin if you change your mind.`,
        });
        if (!confirm) return;
        yield COLLECTIONS.archive(p.collection);
      })(),
  }));

  const navigate = useNavigate();

  const contextMenu = useContextMenuController({
    configSet: [
      {
        identifier: "open",
        Label: "Open",
        action: () => navigate(s.link),
      },
      {
        identifier: "archive",
        Label: "Delete",
        action: s.archiveCollection,
      },
    ],
  });

  return (
    <Observer
      children={() => {
        const inner = (
          <>
            <CollectionCoverImage
              size={s.coverImageSize}
              collection={p.collection}
            />
            <div>
              <h4 css={titleStyle}>{p.collection.$.name}</h4>
              {p.showSubheading && (
                <p css={subheadingStyle}>{p.collection.$.subheading}</p>
              )}
              {p.showArtists && p.collection.artists.length > 0 && (
                <div css={artistsLineStyle}>
                  {p.collection.artists.map(a => (
                    <ArtistEntry
                      asLink
                      key={a._id}
                      artist={a}
                      linkTo={p.linkTo}
                      appearance="text"
                      pseudoLink
                    />
                  ))}
                </div>
              )}
              {p.showMeta && (
                <p css={{ opacity: 0.5, marginTop: ".25em" }}>
                  {p.collection.compositions.length === 1
                    ? "1 Composition"
                    : `${p.collection.compositions.length} Compositions`}
                </p>
              )}
            </div>
            {contextMenu.isOpen && <ContextMenu controller={contextMenu} />}
          </>
        );
        return p.linkTo ? (
          <Link to={s.link} {...s.sharedAttributes}>
            {inner}
          </Link>
        ) : (
          <div {...s.sharedAttributes}>{inner}</div>
        );
      }}
    />
  );
};

export default CollectionEntry;
