import { action, observable, reaction, when } from "mobx";
import {
  Atom,
  AtomType,
  Chord,
  Group,
  Note,
  Ornament,
  Replica,
} from "../@types";
import { keepTruthy } from "../base/utils/array.utils";
import {
  DisposerController,
  makeDisposerController,
} from "../base/utils/disposer.utils";
import { getRandomNumericString } from "../base/utils/random.utils";
import { isNoteAtom, isOrnamentAtom } from "./atoms.utils";

const debug = false;

export const createSyncableGroupLikeAtomSynchronizer = (
  G: Replica | Group | Chord | Ornament,
  d: DisposerController
) => {
  const _ = observable({
    get source() {
      return G.refAtom;
    },
    get canSync() {
      if (isOrnamentAtom(G) && G.note?.$.disableOrnamentReplicaSync)
        return false;
      return true;
    },
    get idsOfAtomsToAdd() {
      return (
        _.source?.children
          .filter(
            srcChild => !G.children.find(c => c.refAtomId === srcChild._id)
          )
          .map(a => a._id)
          .join(",") ?? ""
      );
    },
    get idsOfAtomsToRemove() {
      return G.children.filter(atom => !atom.refAtom).join(",");
    },
  });
  const sync = action(() => {
    if (!_.canSync) return;
    if (!G.context || !_.source) return;
    const runId = getRandomNumericString();
    const toAdd = _.source.children.filter(
      atom =>
        !G.children.find(
          c => c.refAtomId === atom._id
          // || c.parents.some(p => p.refAtomId === atom._id)
        )
    );
    const toRemove = G.children.filter(
      atom => !_.source!.children.find(d => d._id === atom.refAtomId)
    );
    if (toAdd.length === 0 && toRemove.length === 0) return;
    if (debug) {
      console.info(
        `%c❖ Syncing ${G.type}#${G._id}, Run ID ${runId}`,
        "color: yellow"
      );
      console.info(
        "currentChildren",
        G.children.map(
          a => `${a.type}#${a._id}<-Ref_${a.refAtom?.type}#${a.refAtomId}`
        )
      );
      console.info(
        "toAdd",
        toAdd.map(a => `${a.type}#${a._id}`)
      );
      console.info(
        "toRemove",
        toRemove.map(a => `${a.type}#${a._id}`)
      );
    }
    const processAdditions = (atoms: Atom[]) => {
      const notes = atoms.filter(isNoteAtom);
      const notesAdded: Note[] = [];
      notes.forEach(a => {
        const newNote = G.context!.createNote({
          refAtomId: a._id,
          parentIds: [G._id],
          voiceId: G.voiceId ?? a.voiceId,
        });
        notesAdded.push(newNote);
      });
      if (debug) {
        console.info(
          "childrenAfterAddingNotes",
          G.children
            .map(
              a => `${a.type}#${a._id}<-Ref_${a.refAtom?.type}#${a.refAtomId}`
            )
            .join(", ")
        );
      }
      const addedAtoms = [
        ...notesAdded,
        ...(keepTruthy(
          atoms.map(a => {
            switch (a.type) {
              case AtomType.group: {
                return G.context!.createGroup([], {
                  refAtomId: a._id,
                  parentIds: [G._id],
                });
              }
              case AtomType.chord: {
                return G.context!.createChord([], {
                  refAtomId: a._id,
                  parentIds: [G._id],
                });
              }
              case AtomType.ornament: {
                const forNote = G.descendantNotes.find(
                  n => n.refAtom?.interpreted.ornament === a
                );
                if (!forNote) return null;
                const rule =
                  G.context!.interpreter!.findOrCreateRuleForAtom(forNote);
                return G.context!.createOrnament({
                  forNote,
                  rule,
                  def: (a as Ornament).def,
                  template: {
                    refAtomId: a._id,
                    parentIds: [G._id],
                  },
                });
              }
              case AtomType.textNode: {
                return G.context!.createTextNode({
                  refAtomId: a._id,
                  parentIds: [G._id],
                });
              }
              case AtomType.keyframe: {
                return G.context!.createKeyframe({
                  refAtomId: a._id,
                  parentIds: [G._id],
                });
              }
            }
          })
        ) as Atom[]),
      ];
      G.children.push(...addedAtoms);
      if (debug) {
        console.info(
          "addedAtoms",
          addedAtoms,
          addedAtoms.map(a => `${a.type}#${a._id}`)
        );
      }
      return addedAtoms;
    };
    processAdditions(toAdd);
    G.context?.removeAtomsAndDescendants(toRemove);
    if (debug) console.info(`-- Sync finished, Run ID ${runId} --`);
    // debugger;
  });
  d.add(
    when(
      () => !!G.context?.ready && !!G.refAtom,
      () => {
        d.add(
          reaction(
            () => `${_.idsOfAtomsToAdd}|${_.idsOfAtomsToRemove}`,
            () => {
              // console.info(
              //   `Triggering resync for ${G.type}#${G._id}, curr: ${curr}, prev: ${prev}`
              // );
              sync();
            },
            {
              fireImmediately: true,
            }
          )
        );
      }
    )
  );
};

export const syncableGroupLikeAtomInitFunction = (
  G: Replica | Group | Chord | Ornament
) => {
  const d = makeDisposerController();
  createSyncableGroupLikeAtomSynchronizer(G, d);
  return d.dispose;
};
