import { makeIconDef } from "./makeIconDef";

export const FastForwardIconDef = {
  "fast-forward": makeIconDef("fast-forward", {
    regular: () => (
      <path
        d="M20 7H21V18H20V7ZM4 8L11 12.5L4 17V8ZM19 12.5L12 8V17L19 12.5Z"
        fill="currentColor"
      />
    ),
  }),
};
