export const octaveNumberInSnippetRegex = /(-)?\d*$/;

export const parseOctaveNumberFromShorthand = (shorthand: string) => {
  const firstSnippet = shorthand.split("'")[0];
  if (!firstSnippet) return undefined;
  const octaveNumberMatch = octaveNumberInSnippetRegex.exec(firstSnippet);
  if (!octaveNumberMatch) return undefined;
  const parsedOctaveNumber = parseInt(octaveNumberMatch[0]);
  return isNaN(parsedOctaveNumber) ? undefined : parsedOctaveNumber;
};
