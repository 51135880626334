import { action } from "mobx";
import { Atom, AtomBaseSnapshot, AtomContext } from "../@types";
import { removeOneFromArray } from "../base/utils/array.utils";
import { SELECTION_GROUP_ID } from "../constants/selection.constants";

export const repairAtomSnapshot = action(
  (snapshot: Partial<AtomBaseSnapshot>, context: AtomContext) => {
    if (snapshot.parentIds?.includes(SELECTION_GROUP_ID)) {
      removeOneFromArray(snapshot.parentIds, SELECTION_GROUP_ID);
    }
    return snapshot;
  }
);

export const repairAtom = action((n: Atom, context?: AtomContext) => {
  // if (context) {}
  if (n.parentIds.includes(SELECTION_GROUP_ID)) {
    removeOneFromArray(n.parentIds, SELECTION_GROUP_ID);
  }
  return n;
});
