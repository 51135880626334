/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useRef,
} from "react";
import { useMaybeComposer } from "../../components/composer/ComposerApp.context";
import {
  VAR_InputBackground,
  VAR_InputBorder,
  VAR_InputFocusOutline,
  VAR_InputForeground,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";
import { FormControlProps } from "../@types";
import { CSSPartial } from "../@types/css.types";
import { mediaOnlyPhones } from "../styles/helpers/mediaQueries.styling";
import cx from "../utils/className.utils";
import { cVar } from "../utils/customProperties.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import { isNotNil } from "../utils/ramdaEquivalents.utils";
import FormLabel from "./FormLabel";

export type DropdownOptionDef = {
  label?: string;
  value: string;
  disabled?: boolean;
};
export type DropdownOptionDefSet = DropdownOptionDef[];
export type DropdownAppearance = "input" | "text-only";
type DropdownProps<
  T extends UnknownObject = UnknownObject,
  V extends string = string
> = FormControlProps<T, V> &
  PropsWithChildren<{
    Label?: ReactNode;
    options: DropdownOptionDefSet;
    required?: boolean;
    optional?: boolean;
    allowEmpty?: boolean;
    emptyOption?: DropdownOptionDef;
    inline?: boolean;
    appearance?: DropdownAppearance;
    minWidth?: string;
    blurOnChange?: boolean;
  }>;

const style = {
  wrapper: {
    "> div": {
      position: "relative",
    },
    "&.inline": {
      display: "inline-block",
      "> div": {
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
      },
    },
    label: {
      marginBottom: ".5em",
    },
    select: {
      display: "block",
      appearance: "none",
      fontFeatureSettings: "'tnum' 1",
      minWidth: `var(--minWidth)`,
    },
    '&[data-appearance="input"]': {
      select: {
        width: "100%",
        backgroundColor: cVar(VAR_InputBackground),
        color: cVar(VAR_InputForeground),
        border: cVar(VAR_InputBorder),
        fontSize: "inherit",
        minHeight: "2.5em",
        padding: ".5em 2em .5em .5em",
        lineHeight: "1.2em",
        borderRadius: 2,
        ...mediaOnlyPhones({
          fontSize: 16,
        }),
        "&:focus": {
          outline: cVar(VAR_InputFocusOutline),
          outlineColor: `var(--color, ${varPrimary})`,
        },
      },
    },
    '&[data-appearance="text-only"]': {
      select: {
        font: "inherit",
        color: "inherit",
        padding: "0 1em 0 0",
        border: 0,
        backgroundColor: "transparent",
      },
    },
    svg: {
      position: "absolute",
      top: "50%",
      transform: `translateY(-50%)`,
      right: 0,
      pointerEvents: "none",
    },
  } as CSSPartial,
};

const Dropdown = <
  V extends string = string,
  T extends UnknownObject = UnknownObject
>(
  props: React.PropsWithChildren<DropdownProps<T, V>>
) => {
  const p = useProps(props);
  const I = useMaybeComposer();
  const ref = useRef<HTMLSelectElement>(null);
  const s = useStore(() => ({
    get value() {
      try {
        if (p.getter) return p.getter();
        if (p.form && isNotNil(p.field)) return (p.form[p.field] ?? "") as V;
        return "" as V;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(
          `Dropdown for field ${p.field} is not supplied with a form.`
        );
        return "" as V;
      }
    },
    set value(v: V) {
      if (p.setter) p.setter(v);
      if (p.form && isNotNil(p.field)) Reflect.set(p.form, p.field, v);
    },
    handleChange: (e: React.FormEvent<HTMLSelectElement>) => {
      const { value } = e.currentTarget;
      const fn = () => {
        const prev = s.value;
        if (p.onChange) p.onChange(value as V, prev);
        else Reflect.set(s, "value", value);
      };
      const taskName = p.mergeableTaskName ?? p.taskName;
      if (taskName && I)
        I.runInHistory(taskName, fn, {
          mergeableId: p.mergeableTaskName ?? p.mergeableId,
        });
      else fn();
      if (p.blurOnChange) ref.current?.blur();
    },
    handleOnFocus: () => p.onFocus?.(s.value),
    handleOnBlur: () => p.onBlur?.(s.value),
    get emptyOption() {
      return (
        p.emptyOption ?? {
          label: "– Please select –",
          value: "",
          disabled: !p.allowEmpty,
        }
      );
    },
  }));

  return (
    <Observer
      children={() => (
        <div
          className={cx("Dropdown", p.className, p.inline && "inline")}
          css={style.wrapper}
          style={
            {
              "--color": p.color,
              "--minWidth": p.minWidth,
            } as CSSProperties
          }
          data-appearance={p.appearance ?? "input"}
        >
          {p.Label && <FormLabel children={p.Label} bold />}
          <div>
            <select
              name={`${p.field}`}
              value={s.value}
              onChange={s.handleChange}
              onFocus={s.handleOnFocus}
              onBlur={s.handleOnBlur}
              required={p.required}
              disabled={p.disabled}
              ref={ref}
            >
              {p.allowEmpty && (
                <option value={s.emptyOption.value} disabled={!p.allowEmpty}>
                  {s.emptyOption.label ?? "– Please select –"}
                </option>
              )}
              {p.options.map((o, i) => (
                <option
                  value={o.value}
                  key={`${o.value}_${i}`}
                  disabled={o.disabled}
                >
                  {o.label ?? o.value}
                </option>
              ))}
              {p.children}
            </select>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
              <path d="M5.3999 6.84L8.9999 10.44L12.5999 6.84" />
            </svg>
          </div>
        </div>
      )}
    />
  );
};

export default Dropdown;
