import { observable, reaction, runInAction } from "mobx";
import { Atom, AtomContext } from "../@types";
import { RuleProperties, RuleSnapshot } from "../@types/interpretations.types";
import {
  addOneToArrayIfNew,
  removeOneFromArray,
} from "../base/utils/array.utils";
import { makeDisposerController } from "../base/utils/disposer.utils";
import { recursiveMergeWithTypeCast } from "../base/utils/object.utils";
import { uniq } from "../base/utils/ramdaEquivalents.utils";
import { isNumericString } from "../base/utils/string.utils";
import { makeAppearanceBase } from "../traits/hasAppearance.trait";
import { isGroupLikeAtom, isVoiceAtom } from "../utils/atoms.utils";
import { Interpreter } from "./interpreter.controller";

const debug = false;

export const makeDefaultRulePropertiesObject = (): RuleProperties => ({
  start: "",
  width: "",
  pitch: "",
  disabled: false,
  instrumentIds: [],
  velocity: null,
  ornamentId: "",
  articulation: "",
  phrase: false,
  emotion: "",
  appearance: makeAppearanceBase(),
});

export const defaultRulePropertiesObject = Object.freeze(
  makeDefaultRulePropertiesObject()
);

export const makeRuleSnapshot = (): RuleSnapshot => ({
  _id: "",
  selector: "",
  properties: makeDefaultRulePropertiesObject(),
});

export const makeRuleController = (
  snapshot: RuleSnapshot,
  interpreter: Interpreter
) => {
  const d = makeDisposerController();
  if (!snapshot.properties) snapshot.properties = {};
  if (!snapshot.properties.appearance)
    snapshot.properties.appearance = makeAppearanceBase();
  const R: RuleController = observable({
    get _id() {
      return R.$._id;
    },
    get $() {
      return snapshot;
    },
    get alias() {
      return R.$.alias ?? "";
    },
    get selector() {
      return R.$.selector ?? "";
    },
    set selector(v) {
      if (R.$) R.$.selector = v;
    },
    get properties() {
      if (!R.$.properties) R.$.properties = {};
      return R.$.properties;
    },
    get interpreter() {
      return interpreter;
    },
    get atomContext() {
      return R.interpreter.atomContext;
    },
    atoms: [],
    get atomsDescendants() {
      return uniq(
        R.atoms
          .map(a => (isGroupLikeAtom(a) ? [a, ...a.descendants] : a))
          .flat()
      );
    },
    get isForSingleVoice() {
      return R.atoms.length === 1 && isVoiceAtom(R.atoms[0]);
    },
    get compactSnapshot() {
      const { _id: id, alias, selector } = R.$;
      const result = {
        _id: id,
        alias,
        selector,
        properties: {},
      } as Required<RuleSnapshot>;
      if (!R.properties) return result;
      Object.entries(R.properties).forEach(entry => {
        const key = entry[0] as keyof RuleProperties;
        const value = entry[1];
        if (value !== defaultRulePropertiesObject[key]) {
          Reflect.set(result.properties, key, value);
        }
      });
      return result;
    },
    patch: (snapshot: Partial<RuleSnapshot>) => {
      recursiveMergeWithTypeCast(R.$, snapshot);
      return R;
    },
    remove: () => {
      R.interpreter.deleteRule(R);
    },
    reevaluate: () => {
      const prevAtoms = [...R.atoms];
      // changes to the atoms array from new or deleted atoms are managed by each individual atom
      const currAtoms = getRuleAtomsBySelector(R.atomContext, R.selector, R);
      runInAction(() => {
        prevAtoms.forEach(a => {
          if (currAtoms.includes(a)) return;
          else {
            removeOneFromArray(R.atoms, a);
            removeOneFromArray(a.rules, R);
          }
        });
        currAtoms.forEach(a => {
          if (prevAtoms.includes(a)) return;
          else {
            addOneToArrayIfNew(R.atoms, a);
            addOneToArrayIfNew(a.rules, R);
          }
        });
      });
    },
    dispose: d.dispose,
  });
  d.add(
    reaction(
      () => R.atomContext.interpreter,
      () => {
        d.add(
          reaction(() => R.selector, R.reevaluate, { fireImmediately: true })
        );
      },
      { fireImmediately: true }
    )
  );
  d.add(
    reaction(
      () => JSON.stringify(R.$),
      (curr, prev) => {
        R.interpreter.atomContext.composer?.queue.recordRuleChangeBuffer(
          "changed",
          R.interpreter.interpretation._id,
          JSON.parse(curr) as RuleSnapshot,
          JSON.parse(prev) as RuleSnapshot
        );
      }
    )
  );
  d.add(() => {
    R.atoms.forEach(a => removeOneFromArray(a.rules, R));
  });
  return R;
};

export type RuleController = Required<RuleSnapshot> & {
  _id: string;
  $: RuleSnapshot;
  atomContext: AtomContext;
  interpreter: Interpreter;
  atoms: Atom[];
  atomsDescendants: Atom[];
  isForSingleVoice: boolean;
  compactSnapshot: RuleSnapshot;
  patch: (snapshot: Partial<RuleSnapshot>) => RuleController;
  reevaluate: () => void;
  remove: () => void;
  dispose: () => void;
};

const splitSelector = (s: string) => s.split(/\s*,\s*/);

export const getRuleAtomsBySelector = (
  _context?: AtomContext,
  _selector?: string | null,
  R?: RuleController
) => {
  const { atomContext = _context, selector = _selector } = R ?? {};
  const result = [] as Atom[];
  if (!selector || !atomContext || atomContext.interpreter !== R?.interpreter)
    return result;
  if (!selector.length) return result;
  splitSelector(selector).forEach(snippet => {
    switch (snippet) {
      case "*":
        return atomContext.atomsSortedByX;
      default: {
        if (
          isNumericString(snippet) /** atom IDs are always strings of numbers */
        ) {
          const atom = atomContext.getAtomById(snippet);
          // console.info(atom);
          if (atom) addOneToArrayIfNew(result, atom);
          else if (R) {
            // the atom that the ID is pointing to no longer exists.
            // remove the ID from the selector.
            if (debug)
              console.info(
                `[Rule#${R._id}]: the atom for ID #${snippet} in selector "${selector}" no longer exists, removing it from the selector.`
              );
            const updatedSelector = splitSelector(selector)
              .filter(i => i !== snippet)
              .join(",");
            if (!updatedSelector) {
              if (debug)
                console.info(
                  `[Rule#${R._id}] selector is blank after cleanup; removing this rule.`
                );
              R.remove();
            } else {
              runInAction(() => {
                R.$.selector = updatedSelector;
              });
              if (debug)
                console.info(
                  `[Rule#${R._id}] selector after cleanup: "${R.selector}"`
                );
            }
          }
        }
      }
    }
  });
  return result;
};

export const addAtomToRuleIfSelectedByTheRule = (
  atom: Atom,
  rule: RuleController
) => {
  if (!rule.selector) return;
  splitSelector(rule.selector).forEach(snippet => {
    switch (snippet) {
      case "*":
        addOneToArrayIfNew(rule.atoms, atom);
        addOneToArrayIfNew(atom.rules, rule);
        break;
      default:
        if (snippet === atom._id) {
          addOneToArrayIfNew(rule.atoms, atom);
          addOneToArrayIfNew(atom.rules, rule);
          break;
        }
    }
  });
};
