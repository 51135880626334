import { addRootClass } from "./dom.utils";

const ua = navigator.userAgent ?? "";

export const isIE = ua.indexOf("MSIE ") >= 0 || !!/Trident.*rv:11\./.exec(ua);
export const isFirefox = ua.indexOf("Firefox") >= 0;
export const isWebKit = ua.indexOf("AppleWebKit") >= 0;
export const isChrome = ua.indexOf("Chrome") >= 0;
export const isSafari = !isChrome && ua.indexOf("Safari") >= 0;
export const isDesktopSafari = isSafari && ua.includes("Macintosh");
export const isWebkitWebView = !isChrome && !isSafari && isWebKit;
export const isIPad =
  ua.indexOf("iPad") >= 0 || (isSafari && navigator.maxTouchPoints > 0);
export const isIPhone =
  ua.indexOf("iPhone") >= 0 || (isSafari && navigator.maxTouchPoints > 0);
export const isIPod =
  ua.indexOf("iPod") >= 0 || (isSafari && navigator.maxTouchPoints > 0);
export const isIOS = isIPad || isIPhone || isIPod;
export const isAppleBrowser = isIOS || isSafari;
export const isEdgeLegacyWebView =
  ua.indexOf("Edge/") >= 0 && ua.indexOf("WebView/") >= 0;
export const isAndroid = ua.indexOf("Android") >= 0;
export const isStandalone =
  window.matchMedia && window.matchMedia("(display-mode: standalone)").matches;
export const isChromiumEdge = isChrome && /\sEdg\//.test(ua);
export const isEdgeLegacy = !isChromiumEdge && ua.includes("Edge");
export const isOculus = ua.indexOf("OculusBrowser") >= 0;
export const isMobileOS = isIOS || isAndroid || isOculus;
export const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
export const isApple = isMac || isIOS;
export const isWindows = navigator.platform.toUpperCase().indexOf("WIN") >= 0;

export function detectBrowsers() {
  if (isIE) addRootClass("ie");
  if (isFirefox) addRootClass("firefox");
  if (isWebKit) addRootClass("webkit");
  if (isChrome) addRootClass("chrome");
  if (isSafari) addRootClass("safari");
  if (isDesktopSafari) addRootClass("desktop-safari");
  if (isIPad) addRootClass("iPad");
  if (isIPhone) addRootClass("iPhone");
  if (isIOS) addRootClass("iOS");
  if (isAppleBrowser) addRootClass("apple");
  if (isAndroid) addRootClass("android");
  if (isChromiumEdge) addRootClass("edge");
  if (isEdgeLegacy) addRootClass("edge-legacy");
  if (isOculus) addRootClass("oculus");
}
