import { flow, observable } from "mobx";
import { convertRecordMapToArray } from "../../base/utils/map.utils";
import { makeSnapshot } from "../../base/utils/snapshot.utils";
import { ModelName } from "../../constants/modelName.constants";
import {
  User,
  UserSnapshot,
  UserSnapshotFactory,
} from "../../models/User.model";
import { ApiController } from "../api.controller";
import { LocalDBController } from "../localDB.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "../_root.controller";

export const makeUsersController = () => {
  const s = observable({
    ...makeControllerBase("USERS"),

    get API(): ApiController {
      return s.ROOT!.API;
    },
    get LOCALDB(): LocalDBController {
      return s.ROOT!.LOCALDB;
    },
    get all(): User[] {
      return convertRecordMapToArray(s.LOCALDB.data.users);
    },
    get allActive(): User[] {
      return s.all.filter(c => !c.$.timeArchived);
    },
    get allDeactivated(): User[] {
      return s.all.filter(c => !!c.$.timeArchived);
    },

    create: async (template?: UserSnapshot) => {
      return await s.API.post<User>(
        "/users",
        ModelName.users,
        makeSnapshot(UserSnapshotFactory, {
          givenName: "New user",
          ...template,
        })
      );
    },

    get: (id: string) => s.API.get<User>("/users/" + id, ModelName.users),

    getAll: (): Promise<User[]> =>
      flow(function* () {
        const users: User[] = yield s.API.getMany<User>(
          "/users",
          ModelName.users
        );
        return users;
      })(),

    save: (user: User | UserSnapshot): Promise<User> =>
      flow(function* () {
        const savedUser: User = yield s.API.patch<User>(
          "/users/" + user._id,
          ModelName.users,
          user
        );
        return savedUser;
      })(),

    reset: () => {
      // c.composerAppState = null;
    },
  });

  s.init = makeRootControllerChildInitFn(s, () => {});

  return s;
};

export type UsersController = ReturnType<typeof makeUsersController>;
