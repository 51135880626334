/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, Chord } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import PillTag from "../../base/components/PillTag";
import Spacing from "../../base/components/Spacing";
import TextInput from "../../base/components/TextInput";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import AtomBarRangeDisplay from "../composer/AtomBarRangeDisplay";
import AtomRulesetEditor from "../composer/AtomRulesetEditor";
import AtomVoiceSelector from "../composer/AtomVoiceSelector";
import ReferrerCounter from "../composer/ReferrerCounter";
import ChordPropertiesDisplay from "../composer/ChordPropertiesDisplay";
import { useComposer } from "../composer/ComposerApp.context";

interface ChordPropertyPanelProps {
  atom: Atom;
}

const style = {
  grid: {
    display: "grid",
    gridGap: "1em",
    gridTemplateColumns: "1fr 1fr 1fr",
    fontSize: "1.2rem",
  } as CSSPartial,
  header: {
    display: "flex",
    justifyContent: "space-between",
  } as CSSPartial,
  headerInner: {
    paddingRight: "1em",
  } as CSSPartial,
  title: {
    fontSize: "1.6rem",
  } as CSSPartial,
};

const ChordPropertyPanel: React.FC<ChordPropertyPanelProps> = props => {
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    get chord() {
      return p.atom as Chord;
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="ChordPropertyPanel">
          <header css={style.header}>
            <div css={style.headerInner}>
              <h3 css={style.title}>{s.chord.displayName}</h3>
              <p>
                <strong>
                  <AtomBarRangeDisplay atom={s.chord} />
                  {s.chord.referrers.length > 0 && (
                    <>
                      , <ReferrerCounter atom={s.chord} />
                    </>
                  )}
                </strong>
              </p>
            </div>
            <div>
              <PillTag color={s.chord.appearance?.colorInContext}>
                #{s.chord._id}
              </PillTag>
            </div>
          </header>
          <Spacing />
          {s.chord.matchedChords.length > 0 && (
            <>
              <ChordPropertiesDisplay chord={s.chord} />
              <Spacing />
            </>
          )}
          <TextInput
            form={s.chord}
            field="name"
            Label="Name"
            autoComplete="off"
          />
          <Spacing />
          <AtomVoiceSelector
            atom={s.chord}
            fullWidth
            disabled={I.editDisabled}
          />
          <Spacing />
          <AtomRulesetEditor atom={s.chord} />
        </div>
      )}
    />
  );
};

export default ChordPropertyPanel;
