/** @jsxImportSource @emotion/react */
import { Global } from "@emotion/react";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../@types/css.types";
import { DisplayMode } from "../constants/DisplayMode.enum";
import { useControllers } from "../hooks/rootContext.hooks";
import { useProps, useStore } from "../utils/mobx.utils";

interface ZIndexManagerProps {
  phone?: string[];
  tablet?: string[];
  desktop?: string[];
}

const ZIndexManager: React.FC<ZIndexManagerProps> = observer(props => {
  const { UI } = useControllers();

  const p = useProps(props);
  const s = useStore(() => ({
    zIndexRegistry: {
      [DisplayMode.phone]: p.phone || [],
      [DisplayMode.tablet]: p.tablet || [],
      [DisplayMode.desktop]: p.desktop || [],
    } as Record<DisplayMode, string[]>,
    get registryToApply(): string[] {
      return s.zIndexRegistry[UI.displayMode];
    },
    get styles() {
      const styles: CSSPartial = {};
      s.registryToApply.forEach((selector, i) => {
        styles[selector] = {
          zIndex: s.registryToApply.length - i + 9999,
        };
      });
      return styles;
    },
  }));

  return (
    <div className="ZIndexManager">
      <Global styles={s.styles} />
    </div>
  );
});

export default ZIndexManager;
