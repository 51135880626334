/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import { action, runInAction } from "mobx";
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { VAR_PanelBackdropFilter } from "../../../constants/cssCustomProperties.constants";
import { DialogAction, IDialog } from "../../@types";
import { CSSPartial } from "../../@types/css.types";
import { UNITS } from "../../constants/units.constant";
import { useOnMount } from "../../hooks/lifecycle.hooks";
import { useControllers } from "../../hooks/rootContext.hooks";
import { useGetColorFromString } from "../../hooks/theme.hooks";
import { useObservableRef } from "../../hooks/useObservableRef.hook";
import { mediaFromTablet } from "../../styles/helpers/mediaQueries.styling";
import { border } from "../../styles/helpers/shorthands.styleHelpers";
import { withOpacity } from "../../utils/colors.utils";
import { cVar } from "../../utils/customProperties.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import Button from "../Button";
import { makeDisposerController } from "../../utils/disposer.utils";

interface P {
  dialog: IDialog;
}

const dialogBackdropEnter = keyframes`
  from { opacity: 0; }
    to { opacity: 1; }
`;
const dialogBackdropExit = keyframes`
  from { opacity: 1; }
    to { opacity: 0; }
`;
export const dialogEnter = keyframes`
  from { transform: scale(.95); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
`;
export const dialogExit = keyframes`
  from { transform: scale(1); opacity: 1; }
    to { transform: scale(1.05); opacity: 0; }
`;

export const VAR_DialogBackdrop = "--DialogBackdrop";
export const VAR_DialogBackground = "--DialogBackground";
export const VAR_DialogShadow = "--DialogShadow";

const Dialog: React.FC<P> = props => {
  const { KEYBOARD } = useControllers();

  const p = useProps(props);

  const c = useGetColorFromString(() => p.dialog.config.color);

  const s = useStore(() => ({
    ready: false,
    dialog: p.dialog,
    config: p.dialog.config,
    get heading(): React.ReactNode {
      const { Heading } = s.dialog.config;
      if (typeof Heading === "function") return <Heading />;
      else return Heading;
    },
    get body(): React.ReactNode {
      const { Body } = s.dialog.config;
      if (typeof Body === "function") return <Body />;
      else return Body;
    },
    get actions(): DialogAction[] {
      return s.dialog.actions;
    },
    get someWaiting(): boolean {
      return s.actions.some(a => a.waiting);
    },
    get isClosing(): boolean {
      return s.dialog.status === "closing";
    },
  }));

  const style = useStore(() => ({
    get layer(): CSSPartial {
      return {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1.5em",
        textAlign: "center",
        "@media print": {
          display: "none",
        },
      };
    },
    get backdrop(): CSSPartial {
      return {
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: cVar(VAR_DialogBackdrop),
        animation: `${
          s.isClosing ? dialogBackdropExit : dialogBackdropEnter
        } .38s forwards`,
      };
    },
    get dialog(): CSSPartial {
      return {
        position: "relative",
        backgroundColor: cVar(VAR_DialogBackground),
        backdropFilter: cVar(VAR_PanelBackdropFilter),
        border: border(UNITS.lineWidth, withOpacity(c.color, 0.3)),
        borderTop: border(2, c.color),
        boxShadow: cVar(VAR_DialogShadow),
        animation: `${s.isClosing ? dialogExit : dialogEnter} ${
          s.isClosing ? ".38s" : ".1s"
        } cubic-bezier(.38,0,0,2) forwards`,
        maxWidth: s.dialog.config.maxWidth ?? "38rem",
        borderRadius: ".3rem",
        marginBottom: "1.5em",
        overflow: ["hidden", "clip"],
        width: "90vw",
        ...mediaFromTablet({
          width: "62vw",
        }),
        code: {
          overflowWrap: "break-word",
        },
      };
    },
    get dialogHeader(): CSSPartial {
      return {
        padding: "2em 2em .5em",
      };
    },
    get dialogHeading(): CSSPartial {
      return {
        fontSize: "1.6rem",
        color: c.color,
        margin: 0,
      };
    },
    get dialogBody(): CSSPartial {
      return {
        padding: ".5em 1.5em 1.5em",
        lineHeight: 1.38,
        fontSize: "1.4rem",
        a: {
          color: c.color,
          fontWeight: 700,
        },
      };
    },
    get dialogFooter(): CSSPartial {
      return {
        display: "flex",
        marginTop: s.body ? 0 : "1.5em",
      };
    },
    get actionButton(): CSSPartial {
      return {
        minWidth: "6em",
        fontWeight: 700,
        flex: "1 1 50%",
        padding: "1.38em 1em",
        borderRadius: 0,
        borderWidth: 0,
        "&:first-of-type": {
          borderBottomLeftRadius: ".3rem",
        },
        "&:last-of-type": {
          borderBottomRightRadius: ".3rem",
        },
      };
    },
  }));

  const dialogRef = useObservableRef();

  useHotkeys("escape", e => {
    e.stopPropagation();
    dialogRef.current
      ?.querySelector<HTMLButtonElement>('[data-escape="true"]')
      ?.click();
  });

  useOnMount(() => {
    const d = makeDisposerController();
    runInAction(() => {
      const prevFocus = document.activeElement as
        | HTMLButtonElement
        | HTMLInputElement;
      dialogRef.current
        ?.querySelector<HTMLButtonElement>("footer > button:last-child")
        ?.focus();
      d.add(() => {
        if (prevFocus && "focus" in prevFocus) prevFocus.focus();
      });
    });
    if (KEYBOARD.pressed.enter) {
      window.addEventListener(
        "keyup",
        () => {
          setTimeout(
            action(() => {
              s.ready = true;
            }),
            100
          );
        },
        { once: true }
      );
    } else {
      runInAction(() => {
        s.ready = true;
      });
    }
    if (s.actions.find(a => a.isActionForEnter)) {
      const handleKeyUp = action((e: KeyboardEvent) => {
        if (e.key === "Enter") {
          if (!s.ready) return;
          else {
            dialogRef.current
              ?.querySelector<HTMLButtonElement>('[data-enter="true"]')
              ?.click();
            window.removeEventListener("keyup", handleKeyUp);
          }
        }
      });
      window.addEventListener("keyup", handleKeyUp);
      d.add(() => {
        window.removeEventListener("keyup", handleKeyUp);
      });
    }
    return d.dispose;
  });

  return (
    <Observer
      children={() => (
        <section
          className="Dialog"
          data-dialog-name={s.dialog.config.name}
          css={style.layer}
        >
          <span css={style.backdrop} />
          <div css={style.dialog} ref={dialogRef}>
            {s.heading && (
              <header css={style.dialogHeader}>
                <h1 css={style.dialogHeading}>{s.heading}</h1>
              </header>
            )}
            {s.body && <div css={style.dialogBody}>{s.body}</div>}
            <footer css={style.dialogFooter}>
              {s.actions.map(a => (
                <Button
                  onClick={a.action}
                  key={a.id}
                  name={a.name}
                  appearance={a.buttonAppearance}
                  colorMode={a.buttonColorMode}
                  className={a.buttonClass}
                  color={a.color || c.color}
                  css={style.actionButton}
                  data-escape={a.isActionForEscape || a.name === "negative"}
                  data-enter={a.isActionForEnter || a.name === "positive"}
                >
                  {a.label}
                </Button>
              ))}
            </footer>
          </div>
        </section>
      )}
    />
  );
};

export default Dialog;
