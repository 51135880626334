/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { Bar } from "../../@types";
import FormLabel from "../../base/components/FormLabel";
import TextInput from "../../base/components/TextInput";
import { useProps, useStore } from "../../base/utils/mobx.utils";

type BarNumberEditorProps = {
  bar: Bar;
  Label?: ReactNode;
  disabled?: boolean;
};

const BarNumberEditor: React.FC<BarNumberEditorProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get barNumber() {
      return `${p.bar.$.barNumber ?? ""}` || "Auto";
    },
    set barNumber(v: string) {
      if (!v || v.toLowerCase() === "auto") p.bar.$.barNumber = null;
      p.bar.$.barNumber = parseInt(v);
    },
  }));
  return (
    <Observer
      children={() => (
        <div>
          {p.Label && (
            <FormLabel
              css={{
                display: "block",
                fontWeight: 700,
                marginBottom: ".5em",
              }}
            >
              {p.Label}
            </FormLabel>
          )}
          <TextInput form={s} field="barNumber" disabled={p.disabled} />
        </div>
      )}
    />
  );
};

export default BarNumberEditor;
