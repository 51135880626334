export const snapToFloor = <T extends number | null = number | null>(
  value: T,
  snapUnit: number | null
): T => {
  if (value === null || !snapUnit) return value;
  return (Math.floor(value / snapUnit) * snapUnit) as T;
};
export const snapToCeil = <T extends number | null = number | null>(
  value: T,
  snapUnit: number | null
): T => {
  if (value === null || !snapUnit) return value;
  return (Math.ceil(value / snapUnit) * snapUnit) as T;
};
export const snapByRounding = <T extends number | null = number | null>(
  value: T,
  snapUnit: number | null
): T => {
  if (value === null || !snapUnit) return value;
  return (Math.round(value / snapUnit) * snapUnit) as T;
};
export const snapBy = <T extends number | null = number | null>(
  value: T,
  snapUnit: number | null,
  method: "floor" | "ceil" | "round"
): T => {
  if (value === null || !snapUnit) return value;
  return (Math[method](value / snapUnit) * snapUnit) as T;
};
