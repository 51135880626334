/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Observer, observer } from "mobx-react-lite";
import * as React from "react";
import { useComposerCanvasState } from "../../components/composerCanvas/ComposerCanvas.context";
import { CSSPartial } from "../@types/css.types";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useControllers } from "../hooks/rootContext.hooks";
import { useObservableRef } from "../hooks/useObservableRef.hook";
import { hideScrollbars } from "../styles/partials/scrollable.stylePartials";
import cx from "../utils/className.utils";
import { getDataAttributes } from "../utils/dataAttributes.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import { disableWheel } from "../utils/dom.utils";
import { action } from "mobx";

interface PortalLayerProps {
  className?: string;
  name: string;
  cssPassThrough?: boolean;
  width?: string | number;
  height?: string | number;
  scrollable?: boolean;
  disableWheel?: boolean;
}

const Stretcher = styled.div`
  pointer-events: none;
`;

const PortalLayer: React.FC<PortalLayerProps> = observer(props => {
  const { PORTALS } = useControllers();

  const portalRef = useObservableRef();
  const canvas = useComposerCanvasState();

  useOnMount(() => {
    return PORTALS.registerRef(p.name, portalRef);
  });

  const p = useProps(props);

  const s = useStore(() => ({
    get style(): CSSPartial {
      return {
        display: p.cssPassThrough ? "contents" : "block",
        position: p.name === "default" ? "fixed" : "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        overflow: p.scrollable ? "auto" : "visible",
        ...hideScrollbars(),
        pointerEvents: "none",
        "> *": {
          pointerEvents: "auto",
        },
        "> .CCanvasOctaveSet": {
          pointerEvents: "none",
        },
        "&:empty": {
          display: "none",
        },
      };
    },
  }));

  useOnMount(
    action(() => {
      if (p.disableWheel) return disableWheel(portalRef.current);
    })
  );

  return (
    <Observer
      children={() => (
        <div
          className={cx("PortalLayer", p.className)}
          css={s.style}
          ref={portalRef}
          data-name={p.name}
          style={{
            width: p.width,
            height: p.height,
          }}
          {...getDataAttributes(p as unknown as AnyObject)}
        >
          {p.scrollable && (
            <Stretcher
              style={{
                width: canvas.canvas.outerWidthPt,
                height: canvas.canvas.outerHeightPt,
              }}
            />
          )}
        </div>
      )}
    />
  );
});

export default PortalLayer;
