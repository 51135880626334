/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import FileUploader from "../../base/components/FileUploader";
import Spacing from "../../base/components/Spacing";
import TextInput from "../../base/components/TextInput";
import { grid6col } from "../../base/styles/partials/grids.stylePartials";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { User } from "../../models/User.model";
import { FileRecord } from "../../models/FileRecord.model";

type UserEditorProps = {
  user: User;
};

const UserEditor: React.FC<UserEditorProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    handlePhotoUpload: (file: FileRecord) => {
      p.user.$.profileImageId = file._id;
    },
  }));
  return (
    <Observer
      children={() => (
        <div css={grid6col.grid}>
          <TextInput
            css={grid6col.third}
            Label="Username"
            form={p.user.$}
            field="username"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
          />
          <TextInput
            css={grid6col.twoThirds}
            Label="Email"
            type="email"
            form={p.user.$}
            field="email"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
          />
          <TextInput
            css={grid6col.third}
            Label="Given Name"
            form={p.user.$}
            field="givenName"
            autoComplete="off"
            autoCorrect="off"
          />
          <TextInput
            css={grid6col.third}
            Label="Middle Name"
            form={p.user.$}
            field="middleName"
            autoComplete="off"
            autoCorrect="off"
          />
          <TextInput
            css={grid6col.third}
            Label="Surname"
            form={p.user.$}
            field="surname"
            autoComplete="off"
            autoCorrect="off"
          />
          <div css={grid6col.full}>
            <h4>Photo</h4>
            <Spacing size=".5em" />
            <FileUploader
              previewImage={p.user.profileImage}
              acceptOnlyImages
              onUpload={s.handlePhotoUpload}
              purpose="avatar"
              sizeLimitInMB={5}
            />
          </div>
        </div>
      )}
    />
  );
};

export default UserEditor;
