export const KEYBOARD_SHORTCUTS = {
  toggleCommandPalette: ["command+shift+p", "ctrl+shift+p"],
  toggleColorTheme: ["command+shift+k", "ctrl+shift+k"],
  toggleFullScreen: ["command+shift+f", "ctrl+shift+f"],
  syncChanges: ["command+s", "ctrl+s"],
  undo: ["command+z", "ctrl+z"],
  redo: ["command+shift+z", "ctrl+shift+z"],
  hideUI: ["command+shift+h", "ctrl+shift+h"],
  group: ["command+g", "ctrl+g"],
  ungroup: ["command+shift+g", "ctrl+shift+g"],
  createChord: ["command+k", "ctrl+k"],
  createPattern: ["command+p", "ctrl+p"],
  duplicate: ["command+d", "ctrl+d"],
  toggleMute: ["command+m", "ctrl+m"],
  detachAtomsFromParents: ["command+option+d", "ctrl+alt+d"],
  selectAll: ["command+a", "ctrl+a"],
  deleteSelection: ["backspace, delete"],
  joinNotes: ["command+j", "ctrl+j"],
  splitNotes: ["command+option+s", "ctrl+alt+s"],
  transformWithDialog: ["return", "enter"],
  moveAtomBackwardByOneBar: ["alt+left"],
  moveAtomForwardByOneBar: ["alt+right"],
  moveAtomUpByOneOctave: ["alt+up"],
  moveAtomDownByOneOctave: ["alt+down"],
  incrementDuration: ["="],
  decrementDuration: ["-"],
  incrementVelocityOrSpeedScalar: ["."],
  decrementVelocityOrSpeedScalar: [","],
  doubleSnapXValue: ["'"],
  halfSnapXValue: [";"],
  insertFastSpeedScalar: ["cmd+.", "ctrl+."],
  insertSlowSpeedScalar: ["cmd+,", "ctrl+,"],
  copy: ["command+c", "ctrl+c"],
  cut: ["command+x", "ctrl+x"],
  paste: ["command+v", "ctrl+v"],
  setVelocity1: ["option+1", "alt+1"],
  setVelocity2: ["option+2", "alt+2"],
  setVelocity3: ["option+3", "alt+3"],
  setVelocity4: ["option+4", "alt+4"],
  setVelocity5: ["option+5", "alt+5"],
  setVelocity6: ["option+6", "alt+6"],
  setVelocity7: ["option+7", "alt+7"],
  setVelocity8: ["option+8", "alt+8"],
  setVelocity9: ["option+9", "alt+9"],
  setVelocity0: ["option+0", "alt+0"],
  assignToVoice1: ["command+1", "ctrl+1"],
  assignToVoice2: ["command+2", "ctrl+2"],
  assignToVoice3: ["command+3", "ctrl+3"],
  assignToVoice4: ["command+4", "ctrl+4"],
  assignToVoice5: ["command+5", "ctrl+5"],
  assignToVoice6: ["command+6", "ctrl+6"],
  assignToVoice7: ["command+7", "ctrl+7"],
  assignToVoice8: ["command+8", "ctrl+8"],
  assignToVoice9: ["command+9", "ctrl+9"],
  moveCursorBackwardAndSelect: ["shift+left"],
  moveCursorForwardAndSelect: ["shift+right"],
  moveCursorToStartOfBar: ["alt+left"],
  moveCursorToEndOfBar: ["alt+right"],
  moveCursorToStartOfBarAndSelect: ["alt+shift+left"],
  moveCursorToEndOfBarAndSelect: ["alt+shift+right"],
  moveCursorToStartOfComposition: ["command+left", "ctrl+left"],
  moveCursorToEndOfComposition: ["command+right", "ctrl+right"],
  moveCursorToStartOfCompositionAndSelect: [
    "command+shift+left",
    "ctrl+shift+left",
  ],
  moveCursorToEndOfCompositionAndSelect: [
    "command+shift+right",
    "ctrl+shift+right",
  ],
  zoomIn: ["command+=", "ctrl+="],
  zoomOut: ["command+-", "ctrl+-"],
  resetZoom: ["command+0", "ctrl+0"],
  logSelectionToConsole: ["command+l", "ctrl+l"],
} as const;

export type KeyboardShortcutName = keyof typeof KEYBOARD_SHORTCUTS;

export const macKeyboardShortcuts = Object.entries(KEYBOARD_SHORTCUTS).reduce(
  (map, next) => {
    return {
      ...map,
      [next[0]]: next[1][0],
    };
  },
  {}
) as Record<KeyboardShortcutName, string>;

export const windowsKeyboardShortcuts = Object.entries(
  KEYBOARD_SHORTCUTS
).reduce((map, next) => {
  return {
    ...map,
    [next[0]]: next[1][1] ?? next[1][0],
  };
}, {}) as Record<KeyboardShortcutName, string>;
