/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Observer } from "mobx-react-lite";
import React from "react";
import PageSectionHeader from "../../base/components/PageSectionHeader";
import TextInput from "../../base/components/TextInput";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { formatAsSearchString } from "../../base/utils/search.utils";
import CompositionList from "../../components/directory/CompositionList";
import { Composition } from "../../models/Composition.model";
import { useControllers } from "../../base/hooks/rootContext.hooks";

type RecentCompsGridProps = {
  comps: Composition[];
};

const headerInner = css`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-gap: 1em;
  align-items: center;
`;
const RecentCompsGrid: React.FC<RecentCompsGridProps> = props => {
  const { UI } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    query: "",
    get queryFormatted() {
      return formatAsSearchString(s.query);
    },
    get comps() {
      if (!s.queryFormatted) return p.comps;
      return p.comps.filter(c =>
        formatAsSearchString(c.titlePlaintext).includes(s.queryFormatted)
      );
    },
  }));
  return (
    <Observer
      children={() => (
        <section>
          <PageSectionHeader>
            <div css={headerInner}>
              <h2>Recents</h2>
              <TextInput
                form={s}
                field="query"
                placeholder="Search..."
                icon="search"
                autoComplete="off"
                type="search"
              />
            </div>
          </PageSectionHeader>
          <CompositionList
            compositions={s.comps}
            mode="editor"
            enableContextMenu
            columns={UI.appWidth >= 1680 ? 3 : 2}
          />
        </section>
      )}
    />
  );
};

export default RecentCompsGrid;
