import { AxiosError } from "axios";
import { observable } from "mobx";
import { ModelName } from "../constants/modelName.constants";
import { Artist } from "../models/Artist.model";
import { Collection } from "../models/Collection.model";
import { Composition } from "../models/Composition.model";
import { User, UserSnapshot } from "../models/User.model";
import { ApiController, GetRequestOptions } from "./api.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { saveAs } from "file-saver";
import {
  LibraryEntry,
  LibraryEntrySnapshot,
} from "../models/LibraryEntry.model";
import { AuthController } from "./auth.controller";

export const makeAdminController = () => {
  const c = observable({
    ...makeControllerBase("ADMIN"),
    get API(): ApiController | undefined {
      return c.ROOT?.API;
    },
    get AUTH(): AuthController | undefined {
      return c.ROOT?.AUTH;
    },
    users: {
      get: async (id: string) => {
        const user = await c.API!.get<User>(
          `/admin/users/${id}`,
          ModelName.users
        );
        return user;
      },
      getAll: () => c.API!.getMany<User>("/admin/users", ModelName.users),
      create: async (details: {
        username: string;
        email: string;
        givenName: string;
        surname: string;
        password: string;
        asAdmin: boolean;
      }) => {
        if (!c.ROOT?.AUTH.isAdmin) return;
        const response = await c.API!.postRaw<{
          data: UserSnapshot;
        }>("/admin/users", {
          ...details,
          roles: details.asAdmin ? ["admin", "user"] : undefined,
        });
        c.ROOT.LOCALDB.setOrMerge(ModelName.users, response.data);
        c.ROOT?.DIALOGS.success({
          Heading: `User "${details.username}" created`,
        });
        c.ROOT?.NAVIGATOR.navigateTo(`/admin/users/${response.data._id}`);
        return response;
      },
      update: async (user: User) => {
        const payload = {
          username: user.$.username,
          email: user.$.email,
          givenName: user.$.givenName,
          middleName: user.$.middleName,
          surname: user.$.surname,
          profileImageId: user.$.profileImageId,
        };
        try {
          const savedUser = await c.API!.patchRaw<UserSnapshot>(
            `/admin/users/${user.$._id}`,
            payload
          );
          if (savedUser) user.$patch(savedUser);
        } catch (e) {
          c.ROOT?.DIALOGS.error({
            Heading:
              (e as AxiosError<{ message: string }>).response?.data?.message ??
              "Failed to update user.",
          });
        }
      },
      listCompositions: async (userId: string) => {
        await c.API!.getMany<Composition>(
          `/admin/users/${userId}/compositions/list`,
          ModelName.compositions,
          {
            params: {
              select: "-atomSnapshots",
            },
          }
        );
      },
      updatePassword: async (
        user: User,
        form: {
          password: string;
          confirm: string;
        }
      ) => {
        if (form.password !== form.confirm) {
          throw Error("Passwords do not match.");
        }
        return await c.API!.postRaw<UserSnapshot>(
          `/admin/users/${user.$._id}/update-password`,
          form
        );
      },
      deactivate: async (user: User) => {
        return await c.API!.postRaw<UserSnapshot>(
          `/admin/users/${user.$._id}/deactivate`
        );
      },
      reactivate: async (user: User) => {
        return await c.API!.postRaw<UserSnapshot>(
          `/admin/users/${user.$._id}/reactivate`
        );
      },
      delete: async (user: User) => {
        const result = await c.API!.deleteRaw(`/admin/users/${user.$._id}`);
        c.ROOT?.LOCALDB.remove(user);
        return result;
      },
    },
    artists: {
      getAll: (options?: GetRequestOptions) =>
        c.API!.getMany<Artist>("/admin/artists", ModelName.artists, options),
    },
    collections: {
      getAll: (options?: GetRequestOptions) =>
        c.API!.getMany<Collection>(
          "/admin/collections",
          ModelName.collections,
          options
        ),
    },
    compositions: {
      getAll: () =>
        c.API!.getMany<Composition>(
          "/admin/compositions",
          ModelName.compositions,
          { select: "-atomSnapshots" }
        ),
    },
    library: {
      listEntries: () =>
        c.API!.getMany<LibraryEntry>(
          `/admin/library-entries`,
          ModelName.libraryEntries
        ),
      createEntry: async (compositionId: string) => {
        if (!c.AUTH?.isAdmin) return;
        return await c.API!.post<LibraryEntry, Partial<LibraryEntrySnapshot>>(
          `/admin/library-entries/`,
          ModelName.libraryEntries,
          {
            compositionId,
          }
        );
      },
    },
    backup: async () => {
      const contents = await c.API!.postRaw<{
        database: string;
        time: string;
      }>("/admin/backup");
      if (contents)
        saveAs(
          new Blob([JSON.stringify(contents)], {
            type: "application/javascript;charset=utf-8",
          }),
          `${c.API!.info.world}-backup-${contents.time}.json`
        );
    },
  });
  c.init = makeRootControllerChildInitFn(c, () => {});
  return c;
};

export type AdminController = ReturnType<typeof makeAdminController>;
