/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Observer } from "mobx-react-lite";
import React from "react";
import {
  bg,
  varFontMono,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";
import { isDevelopment } from "../env";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useControllers } from "../hooks/rootContext.hooks";
import DevStripes from "./DevStrips";
import { useStore } from "../utils/mobx.utils";
import { action } from "mobx";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage.utils";
import { getJsHeapSizeInMb } from "../utils/memory.utils";

type DevEnvLabelProps = {};

const DevEnvLabelTag = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${varPrimary};
  color: ${bg};
  font-family: ${varFontMono};
  font-size: 0.9rem;
  font-weight: 700;
  pointer-events: none;
  text-transform: uppercase;
  padding-right: 0.25em;
  > * + * {
    margin-left: 0.25em;
  }
  span {
    line-height: 1;
  }
  svg {
    display: block;
  }
`;

declare global {
  interface Window {
    performance: {
      memory?: {
        usedJSHeapSize?: number;
      };
    };
  }
}

const DevEnvLabel: React.FC<DevEnvLabelProps> = props => {
  const { API } = useControllers();
  const s = useStore(() => ({
    shouldEnable: false,
    supportsMemory: false,
    usedJSHeapSizeInMb: 0,
    updateMemoryUsage: () => {
      s.usedJSHeapSizeInMb = getJsHeapSizeInMb();
    },
  }));
  useOnMount(() => {
    let interval: ReturnType<typeof setInterval>;
    const enableDevTag = action(() => {
      setLocalStorageItem("enableDevTag", "true");
      s.shouldEnable = true;
      if (isDevelopment) {
        Array.from(
          document.querySelectorAll<HTMLLinkElement>('link[rel="icon"]')
        ).forEach(icon => {
          icon.href = icon.href.replace(".png", "-dev.png");
        });
      }
      if ("memory" in window.performance) {
        s.supportsMemory = true;
        interval = setInterval(s.updateMemoryUsage, 1000);
      } else {
        console.info(
          "Performance memory API is not supported in this browser."
        );
      }
    });
    if (isDevelopment || getLocalStorageItem("enableDevTag") === "true") {
      enableDevTag();
    }
    Reflect.set(window, "enableDevTag", enableDevTag);
    return () => {
      clearInterval(interval);
    };
  });
  return (
    <Observer
      children={() =>
        s.shouldEnable ? (
          <DevEnvLabelTag className="DevEnvLabel">
            <DevStripes />
            <span>
              {isDevelopment ? <span>Development</span> : <span>Live</span>}
              {API.info.world && <span> » World: {API.info.world}</span>}
            </span>
            {s.supportsMemory && (
              <span> • JS Heap: {s.usedJSHeapSizeInMb}MB</span>
            )}
          </DevEnvLabelTag>
        ) : null
      }
    />
  );
};

export default DevEnvLabel;
