import {
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  StandardInstrumentOptions,
} from "../../@types";
import { STATIC_HOST } from "../../env";
import { ColorPalette } from "../../theming/colorPalette";
import { createCompositeSamplerInstrumentFactory } from "../_factory/compositeSamplerInstrumentFactory";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";

export type ItalianHarpsichordOptions = StandardInstrumentOptions;

export const makeDefaultItalianHarpsichordOptions =
  (): ItalianHarpsichordOptions => ({
    ...makeDefaultInstrumentOptions(),
  });

export type ItalianHarpsichordType = Instrument<ItalianHarpsichordOptions>;

export const ItalianHarpsichordName = "italian-harpsichord";

export const ItalianHarpsichordMeta: InstrumentMeta<ItalianHarpsichordOptions> =
  {
    name: ItalianHarpsichordName,
    displayName: "Italian Harpsichord",
    source: "VS Chamber Orchestra: Community Edition",
    sourceWebsite: "https://github.com/sgossner/VSCO-2-CE",
    icon: "harpsichord",
    range: [[29, 88]] as InstrumentRange,
    defaultColor: ColorPalette.green,
    type: "keyboards",
  };

export const makeItalianHarpsichord = createCompositeSamplerInstrumentFactory({
  defaultOptionsBagBuilder: makeDefaultItalianHarpsichordOptions,
  instrumentMeta: ItalianHarpsichordMeta,
  getters: {
    samplers: (n, o) => o?.activatedSamplers ?? ["stop-1"],
    velocity: {
      attack: 0.7,
      release: 0.375,
    },
  },
  compositeSamplerConfigArray: [
    {
      name: "stop-1",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/italian-harpsichord/stop-1/`,
          volume: 8,
          urls: {
            "A#0": "Harpsichord_stop1_Asharp0_1.mp3",
            "A#1": "Harpsichord_stop1_Asharp1_1.mp3",
            "A#2": "Harpsichord_stop1_Asharp2_1.mp3",
            "A#3": "Harpsichord_stop1_Asharp3_1.mp3",
            "A#4": "Harpsichord_stop1_Asharp4_1.mp3",
            B0: "Harpsichord_stop1_B0_1.mp3",
            B1: "Harpsichord_stop1_B1_1.mp3",
            B2: "Harpsichord_stop1_B2_1.mp3",
            B3: "Harpsichord_stop1_B3_1.mp3",
            B4: "Harpsichord_stop1_B4_1.mp3",
            "C#1": "Harpsichord_stop1_Csharp1_1.mp3",
            "C#2": "Harpsichord_stop1_Csharp2_1.mp3",
            "C#3": "Harpsichord_stop1_Csharp3_1.mp3",
            "C#4": "Harpsichord_stop1_Csharp4_1.mp3",
            "D#1": "Harpsichord_stop1_Dsharp1_1.mp3",
            "D#2": "Harpsichord_stop1_Dsharp2_1.mp3",
            "D#3": "Harpsichord_stop1_Dsharp3_1.mp3",
            "D#4": "Harpsichord_stop1_Dsharp4_1.mp3",
            E1: "Harpsichord_stop1_E1_1.mp3",
            E2: "Harpsichord_stop1_E2_1.mp3",
            E3: "Harpsichord_stop1_E3_1.mp3",
            E4: "Harpsichord_stop1_E4_1.mp3",
            "F#0": "Harpsichord_stop1_Fsharp0_1.mp3",
            "F#1": "Harpsichord_stop1_Fsharp1_1.mp3",
            "F#2": "Harpsichord_stop1_Fsharp2_1.mp3",
            "F#3": "Harpsichord_stop1_Fsharp3_1.mp3",
            "F#4": "Harpsichord_stop1_Fsharp4_1.mp3",
            "G#0": "Harpsichord_stop1_Gsharp0_1.mp3",
            "G#1": "Harpsichord_stop1_Gsharp1_1.mp3",
            "G#2": "Harpsichord_stop1_Gsharp2_1.mp3",
            "G#3": "Harpsichord_stop1_Gsharp3_1.mp3",
            "G#4": "Harpsichord_stop1_Gsharp4_1.mp3",
          },
          octaveOffset: 1,
        },
        release: {
          baseUrl: `${STATIC_HOST}/instruments/italian-harpsichord/stop-1-release/`,
          urls: {
            B3: "Harpsichord_stop1-rel_B3_1.mp3",
            "A#0": "Harpsichord_stop1-rel_Asharp0_1.mp3",
            "A#1": "Harpsichord_stop1-rel_Asharp1_1.mp3",
            "A#2": "Harpsichord_stop1-rel_Asharp2_1.mp3",
            "A#3": "Harpsichord_stop1-rel_Asharp3_1.mp3",
            B0: "Harpsichord_stop1-rel_B0_1.mp3",
            B1: "Harpsichord_stop1-rel_B1_1.mp3",
            B2: "Harpsichord_stop1-rel_B2_1.mp3",
            B4: "Harpsichord_stop1-rel_B4_1.mp3",
            "C#1": "Harpsichord_stop1-rel_Csharp1_1.mp3",
            "C#2": "Harpsichord_stop1-rel_Csharp2_1.mp3",
            "C#3": "Harpsichord_stop1-rel_Csharp3_1.mp3",
            "C#4": "Harpsichord_stop1-rel_Csharp4_1.mp3",
            "D#1": "Harpsichord_stop1-rel_Dsharp1_1.mp3",
            "D#2": "Harpsichord_stop1-rel_Dsharp2_1.mp3",
            "D#3": "Harpsichord_stop1-rel_Dsharp3_1.mp3",
            "D#4": "Harpsichord_stop1-rel_Dsharp4_1.mp3",
            E1: "Harpsichord_stop1-rel_E1_1.mp3",
            E2: "Harpsichord_stop1-rel_E2_1.mp3",
            E3: "Harpsichord_stop1-rel_E3_1.mp3",
            E4: "Harpsichord_stop1-rel_E4_1.mp3",
            "F#0": "Harpsichord_stop1-rel_Fsharp0_1.mp3",
            "F#1": "Harpsichord_stop1-rel_Fsharp1_1.mp3",
            "F#2": "Harpsichord_stop1-rel_Fsharp2_1.mp3",
            "F#3": "Harpsichord_stop1-rel_Fsharp3_1.mp3",
            "F#4": "Harpsichord_stop1-rel_Fsharp4_1.mp3",
            "G#0": "Harpsichord_stop1-rel_Gsharp0_1.mp3",
            "G#1": "Harpsichord_stop1-rel_Gsharp1_1.mp3",
            "G#2": "Harpsichord_stop1-rel_Gsharp2_1.mp3",
            "G#3": "Harpsichord_stop1-rel_Gsharp3_1.mp3",
            "G#4": "Harpsichord_stop1-rel_Gsharp4_1.mp3",
          },
          octaveOffset: 1,
        },
      },
    },
  ],
});
