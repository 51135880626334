/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { runInAction } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Atom, AtomContext } from "../../@types";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useObservableRef } from "../../base/hooks/useObservableRef.hook";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import {
  AtomOrAtomContextThumbnailRenderer,
  createAtomOrAtomContextThumbnailRenderer,
} from "../../utils/thumbnails.utils";

type P = {
  className?: string;
  target: Atom | AtomContext;
  opacity?: number;
  maintainRatio?: boolean;
  cover?: boolean;
};

const AtomThumbnailCanvasContainer = styled.div`
  display: flex;
  align-items: center;
  &.cover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  canvas {
    max-width: 100%;
    max-height: 100%;
  }
`;

const AtomThumbnailCanvas: React.FC<P> = props => {
  const p = useProps(props);
  const { THEME } = useControllers();
  const containerRef = useObservableRef();
  const s = useStore(() => ({
    thumbnailRenderer: null as AtomOrAtomContextThumbnailRenderer | null,
  }));
  useOnMount(() => {
    runInAction(() => {
      s.thumbnailRenderer = createAtomOrAtomContextThumbnailRenderer({
        THEME,
        canvasContainerRef: containerRef,
        params: p,
      });
    });
    return () => {
      s.thumbnailRenderer?.dispose();
    };
  });
  return (
    <Observer
      children={() => (
        <AtomThumbnailCanvasContainer
          className={cx("AtomThumbnailCanvas", p.className, p.cover && "cover")}
          ref={containerRef}
          css={{
            opacity: p.opacity,
            canvas: {
              height: p.maintainRatio ? "auto !important" : undefined,
            },
          }}
        />
      )}
    />
  );
};

export default AtomThumbnailCanvas;
