import { action, observable } from "mobx";
import { AtomType, Instrument, Keyframe } from "../../@types";
import { add, applyFormula } from "../../base/utils/math.utils";
import {
  createAtomFactory,
  makeAtomBaseSnapshot,
} from "../../logic/Atom.factory";
import { getInterpretedInstrumentsOfAtom } from "../../logic/atomFactoryMethods";
import {
  getTransformedValueOfProp,
  setTransformedValueOfProp,
} from "../../transformers/transform.controller";
import {
  getInterpretedBpmOfAtom,
  getInterpretedBpxOfAtom,
  getInterpretedXpmOfAtom,
} from "../../utils/atoms.utils";
import { setupKeyframeScheduler } from "../../utils/playback.utils";
import { ValidRect } from "../../base/@types";

export const KeyframeSnapshotFactory = <
  T extends unknown | null = unknown | null
>() => ({
  ...makeAtomBaseSnapshot(AtomType.keyframe),
  controlPath: "",
  value: null as T,
});

export const makeKeyframeExtendedMembersFactory = (K: Keyframe) => {
  const _ = observable({
    hitBox: null as null | ValidRect,
  });
  return {
    _on: false,
    get displayName() {
      return `Keyframe #${K._id}`;
    },
    get height() {
      return 1;
    },
    get _x() {
      return (
        K.$.x ??
        (K.refAtom
          ? add(K.refAtom.x, K.replica?._anchorDiffFromSource.x ?? null)
          : null)
      );
    },
    set _x(newValue) {
      K.setSourceX(newValue);
    },
    get x() {
      return getTransformedValueOfProp(K, "x");
    },
    set x(newValue) {
      setTransformedValueOfProp(K, "x", newValue);
    },
    setSourceX: (newValue: number | null) => {
      K.$.x = newValue;
    },
    get _width() {
      return K.$.width ?? (K.refAtom ? K.refAtom.width : null);
    },
    set _width(v) {
      K.$.width = v;
    },
    get width() {
      return getTransformedValueOfProp(K, "width");
    },
    set width(v) {
      setTransformedValueOfProp(K, "width", v);
    },
    get _y() {
      return (
        K.$.y ??
        (K.refAtom
          ? add(K.refAtom.y, K.replica?._anchorDiffFromSource.y ?? null)
          : null)
      );
    },
    set _y(v) {
      K.setSourceY(v);
    },
    get y() {
      return getTransformedValueOfProp(K, "y");
    },
    set y(v) {
      setTransformedValueOfProp(K, "y", v);
    },
    setSourceY: (newValue: number | null) => {
      K.$.y = newValue;
    },
    get controlPath() {
      return K.$.controlPath || K.refAtom?.controlPath || null;
    },
    set controlPath(v) {
      K.$.controlPath = v;
    },
    get value() {
      return K.$.value ?? K.refAtom?.value;
    },
    set value(v) {
      K.$.value = v;
    },
    get isDisabled() {
      return K.rulePropertiesFlattened.disabled;
    },
    interpreted: observable({
      get instruments(): Instrument[] {
        return getInterpretedInstrumentsOfAtom(K);
      },
      get startX() {
        return (
          applyFormula(K.startX, K.rulePropertiesFlattened.start ?? "") ?? 0
        );
      },
      get endX() {
        return add(K.interpreted.startX, K.interpreted.width)!;
      },
      get width() {
        return (
          applyFormula(K.width, K.rulePropertiesFlattened.width ?? "") ?? 0
        );
      },
      get timeStartInSeconds() {
        const startX = K.interpreted.startX;
        if (!K.context) return startX;
        return K.context.getScaledTime(K.interpreted.startX);
      },
      get timeEndInSeconds() {
        const endX = K.interpreted.endX;
        if (!K.context) return endX;
        return K.context.getScaledTime(K.interpreted.endX);
      },
      get durationInSeconds() {
        if (
          K.interpreted.timeStartInSeconds === null ||
          K.interpreted.timeEndInSeconds === null
        )
          return 0;
        return (
          K.interpreted.timeEndInSeconds - K.interpreted.timeStartInSeconds
        );
      },
      get bpm() {
        return getInterpretedBpmOfAtom(K);
      },
      get bpx() {
        return getInterpretedBpxOfAtom(K);
      },
      get xpm() {
        return getInterpretedXpmOfAtom(K);
      },
    }),
    get hitBox() {
      return _.hitBox;
    },
    _updateHitBox: action((rect: ValidRect) => {
      _.hitBox = rect;
    }),
  };
};

export const makeKeyframe = createAtomFactory<Keyframe>({
  type: AtomType.keyframe,
  snapshotFactory: KeyframeSnapshotFactory,
  extendedPropertiesFactories: [makeKeyframeExtendedMembersFactory],
  init: k => {
    return setupKeyframeScheduler(k);
  },
});
