import { action, observable } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { CSSProperties, PropsWithChildren, RefObject } from "react";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useObservableRef } from "../hooks/useObservableRef.hook";
import { addOneToArrayIfNew, removeOneFromArray } from "../utils/array.utils";
import { useStore } from "../utils/mobx.utils";
import { getRandomNumericString } from "../utils/random.utils";

interface ClickOutsideProps {
  id?: string;
  className?: string;
  onClickOutside?: (e: MouseEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  onPointerDown?: (e: React.PointerEvent) => void;
  onDoubleClick?: (e: React.MouseEvent) => void;
  disableOtherClickOutsides?: boolean;
  style?: CSSProperties;
  innerRef?: RefObject<HTMLDivElement>;
}

const CLICK_OUTSIDE_MANAGER = observable({
  disablerIds: [] as string[],
  clear: () => {
    CLICK_OUTSIDE_MANAGER.disablerIds.splice(0);
  },
  add: (id: string) =>
    addOneToArrayIfNew(CLICK_OUTSIDE_MANAGER.disablerIds, id),
  remove: (id: string) =>
    removeOneFromArray(CLICK_OUTSIDE_MANAGER.disablerIds, id),
});

// @ts-ignore
// if (isLocalhost) window.CLICK_OUTSIDE_MANAGER = CLICK_OUTSIDE_MANAGER;

const ClickOutside: React.FC<PropsWithChildren<ClickOutsideProps>> = p => {
  const s = useStore(() => ({
    timestampOnMount: performance.now(),
    id: getRandomNumericString(),
  }));

  const ownRef = useObservableRef();
  const ref = p.innerRef ?? ownRef;

  const handleClickOutside = action((e: PointerEvent | MouseEvent) => {
    if (performance.now() - s.timestampOnMount < 300) return;
    const { target } = e;
    if (
      CLICK_OUTSIDE_MANAGER.disablerIds.length > 0 &&
      !CLICK_OUTSIDE_MANAGER.disablerIds.includes(s.id)
    )
      return;
    if (target && !ref.current?.contains(target as Node)) {
      p.onClickOutside?.(e);
    }
  });
  const createListener = () => {
    document.addEventListener("pointerdown", handleClickOutside);
    document.addEventListener("contextmenu", handleClickOutside);
    return () => {
      document.removeEventListener("pointerdown", handleClickOutside);
      document.removeEventListener("contextmenu", handleClickOutside);
    };
  };
  useOnMount(() => {
    if (p.disableOtherClickOutsides) CLICK_OUTSIDE_MANAGER.add(s.id);
    const disposer = createListener();
    return () => {
      if (p.disableOtherClickOutsides) CLICK_OUTSIDE_MANAGER.remove(s.id);
      disposer();
    };
  });
  return (
    <Observer
      children={() => (
        <div
          className={p.className}
          ref={ref}
          style={p.style}
          onClick={p.onClick}
          onPointerDown={p.onPointerDown}
          onDoubleClick={p.onDoubleClick}
        >
          {p.children}
        </div>
      )}
    />
  );
};

export default ClickOutside;
