import { makeIconDef } from "./makeIconDef";

export const CommentIconDef = {
  comment: makeIconDef("comment", {
    regular: () => (
      <path
        d="M8.91896 16.5117L9.06434 16.371L9.26667 16.371L20.5 16.371L20.5 7C20.5 6.17157 19.8284 5.5 19 5.5L5.5 5.5L5.5 19.8203L8.91896 16.5117Z"
        stroke="currentColor"
      />
    ),
  }),
};
