/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Atom } from "../../@types";

interface AtomPropertyPanelProps {
  atom: Atom;
}

const AtomPropertyPanel: React.FC<AtomPropertyPanelProps> = observer(p => {
  return (
    <div className="AtomPropertyPanel">
      <p>
        Element of unknown type <strong>{p.atom.type}</strong> has been selected
      </p>
    </div>
  );
});

export default AtomPropertyPanel;
