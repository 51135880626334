/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { Atom } from "../../@types";
import Spacing from "../../base/components/Spacing";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { uniq } from "../../base/utils/ramdaEquivalents.utils";
import { autoPluralizeWithNumber } from "../../base/utils/string.utils";
import {
  isBarAtom,
  isBarOrSectionAtom,
  isChordAtom,
  isGroupAtom,
  isKeyframeAtom,
  isMusicalAtom,
  isNoteAtom,
  isOrnamentAtom,
  isPatternAtom,
  isReplicaAtom,
  isSectionAtom,
  isTextNodeAtom,
  isVoiceAtom,
} from "../../utils/atoms.utils";
import AtomVoiceSelector from "../composer/AtomVoiceSelector";
import GroupPropertyPanel from "./GroupPropertyPanel";
import KeyframePropertyPanel from "./KeyframePropertyPanel";
import NotePropertyPanel from "./NotePropertyPanel";
import ReplicaPropertyPanel from "./ReplicaPropertyPanel";
import TextNodePropertyPanel from "./TextNodePropertyPanel";

type MultipleAtomPropertyPanelProps = {
  atoms: Atom[];
};

const MultipleAtomPropertyPanel: React.FC<
  MultipleAtomPropertyPanelProps
> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get isAllNotes() {
      return p.atoms.every(isNoteAtom);
    },
    get isAllGroups() {
      return p.atoms.every(isGroupAtom);
    },
    get isAllBars() {
      return p.atoms.every(isBarAtom);
    },
    get isAllKeyframes() {
      return p.atoms.every(isKeyframeAtom);
    },
    get isAllTextNodes() {
      return p.atoms.every(isTextNodeAtom);
    },
    get isAllReplicas() {
      return p.atoms.every(isReplicaAtom);
    },
    get isAllMusicalAtoms() {
      return p.atoms.every(isMusicalAtom);
    },
    get includesBarsOrSections() {
      return p.atoms.some(isBarOrSectionAtom);
    },
    get allTypes() {
      return uniq(p.atoms.map(n => n.type));
    },
    get isMixed(): boolean {
      return s.allTypes.length > 1;
    },
    get onlyPattern() {
      return s.patterns.length === 1 ? s.patterns[0] : null;
    },
    get isLoop() {
      return (
        p.atoms.length > 1 &&
        p.atoms.every(
          a =>
            (isReplicaAtom(a) && a.pattern && a.pattern === s.onlyPattern) ||
            a === s.onlyPattern
        )
      );
    },
    get title(): string {
      if (s.allTypes.length === 1) {
        return `${autoPluralizeWithNumber(p.atoms, s.allTypes[0])} selected`;
      }
      return `${autoPluralizeWithNumber(p.atoms, "element")} selected`;
    },
    get notes() {
      return p.atoms.filter(isNoteAtom);
    },
    get keyframes() {
      return p.atoms.filter(isKeyframeAtom);
    },
    get chords() {
      return p.atoms.filter(isChordAtom);
    },
    get ornaments() {
      return p.atoms.filter(isOrnamentAtom);
    },
    get textNodes() {
      return p.atoms.filter(isTextNodeAtom);
    },
    get groups() {
      return p.atoms.filter(isGroupAtom);
    },
    get patterns() {
      return p.atoms.filter(isPatternAtom);
    },
    get replicas() {
      return p.atoms.filter(isReplicaAtom);
    },
    get sections() {
      return p.atoms.filter(isSectionAtom);
    },
    get bars() {
      return p.atoms.filter(isBarAtom);
    },
    get voices() {
      return p.atoms.filter(isVoiceAtom);
    },
    get summary() {
      return `${[
        s.notes.length ? autoPluralizeWithNumber(s.notes, "note") : "",
        s.keyframes.length
          ? autoPluralizeWithNumber(s.keyframes, "keyframes")
          : "",
        s.chords.length ? autoPluralizeWithNumber(s.ornaments, "chords") : "",
        s.ornaments.length
          ? autoPluralizeWithNumber(s.ornaments, "ornament")
          : "",
        s.textNodes.length
          ? autoPluralizeWithNumber(s.textNodes, "text node")
          : "",
        s.groups.length ? autoPluralizeWithNumber(s.groups, "group") : "",
        s.patterns.length ? autoPluralizeWithNumber(s.patterns, "pattern") : "",
        s.replicas.length ? autoPluralizeWithNumber(s.replicas, "replica") : "",
        s.bars.length ? autoPluralizeWithNumber(s.bars, "bar") : "",
        s.sections.length ? autoPluralizeWithNumber(s.sections, "section") : "",
        s.voices.length ? autoPluralizeWithNumber(s.voices, "voice") : "",
      ]
        .filter(w => w)
        .reduce((result, next, i, arr) => {
          return `${result}${
            i === arr.length - 1 ? " and " : i === 0 ? "" : ", "
          }${next}`;
        }, "")}`;
    },
    get defaultPanelContent(): ReactNode {
      return (
        <>
          <h3>{s.title}</h3>
          {/* {s.isMixed && <p>{s.summary}</p>} */}
          <Spacing />
          {s.isAllMusicalAtoms && (
            <AtomVoiceSelector atoms={p.atoms} fullWidth />
          )}
        </>
      );
    },
    get panelContent(): ReactNode {
      if (s.isAllKeyframes) return <KeyframePropertyPanel atoms={p.atoms} />;
      if (s.isAllNotes) return <NotePropertyPanel atoms={p.atoms} />;
      if (s.isAllGroups) return <GroupPropertyPanel atoms={p.atoms} />;
      if (s.isAllReplicas) return <ReplicaPropertyPanel atoms={p.atoms} />;
      if (s.isAllTextNodes) return <TextNodePropertyPanel atoms={p.atoms} />;
      // if (s.isLoop)
      //   return <LoopPropertyPanel atoms={p.atoms as (Pattern | Replica)[]} />;
      return s.defaultPanelContent;
    },
  }));
  return (
    <Observer
      children={() => (
        <div className="MultipleAtomPropertyPanel">{s.panelContent}</div>
      )}
    />
  );
};

export default MultipleAtomPropertyPanel;
