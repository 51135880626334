/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import { autorun, computed, runInAction } from "mobx";
import React from "react";
import { Instrument, Note, Voice } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore, useStyle } from "../../base/utils/mobx.utils";
import { withOpacity } from "../../base/utils/colors.utils";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { tint } from "polished";
import { useObservableRef } from "../../base/hooks/useObservableRef.hook";
import { makeDisposerController } from "../../base/utils/disposer.utils";
import { createResponsiveCanvas } from "../../base/utils/canvas.utils";
import chroma from "chroma-js";

type PlayStateHighlighterProps = {
  highlightNotesAtCurrentCursor?: boolean;
  highlightQuillNote?: boolean;
  respondToMidiNumber?: number;
  respondToVoiceId?: string;
  respondToInstrument?: Instrument;
  brightenColor?: number;
  color?: string;
  top?: string | number;
  right?: string | number;
  bottom?: string | number;
  left?: string | number;
  borderRadius?: string | number;
  baseOpacityWhenOn?: number;
  baseOpacityWhenOff?: number;
  gradientOpacityMap?: [max: number, min: number];
};

/**
 * highlights notes when they play.
 * TODO: update to support highlighting multiple simultaneous notes in a single voice
 */
const PlayStateHighlighter: React.FC<PlayStateHighlighterProps> = React.memo(
  function PlayStateHighlighter(props) {
    const { ENSEMBLE, THEME, COMPOSER } = useControllers();
    const ref = useObservableRef();
    const p = useProps(props);
    const s = useStore(
      () => ({
        get context() {
          return ENSEMBLE.composition?.atomContext;
        },
        get canvas() {
          return COMPOSER.instance?.focusedCanvas;
        },
        get primaryCursor() {
          return s.canvas?.primaryCursor;
        },
        get notesAtCurrentCursor() {
          if (
            s.primaryCursor &&
            (s.primaryCursor.x === 0 || s.primaryCursor.x === s.context?.endX)
          )
            return [];
          return s.primaryCursor?.notesAtCursorPositionUsingInterpretedX ?? [];
        },
        get notesToCheck() {
          if (ENSEMBLE.isPlaying) return ENSEMBLE.notesOn;
          else {
            const notes = [];
            if (
              p.highlightQuillNote &&
              COMPOSER.instance?.tools.quill.isActivated
            ) {
              const ghost = s.canvas?.ghostNote;
              if (ghost) notes.push(ghost as Note);
            }
            if (p.highlightNotesAtCurrentCursor)
              notes.push(...s.notesAtCurrentCursor);
            return notes;
          }
        },
        get notesOn(): Note[] {
          return s.notesToCheck.filter(
            n =>
              (!p.respondToMidiNumber ||
                (n.midiNumber &&
                  Math.round(n.midiNumber) === p.respondToMidiNumber)) &&
              (!p.respondToVoiceId || n.voiceId === p.respondToVoiceId) &&
              (!p.respondToInstrument ||
                n.interpreted.instruments.includes(s.respondToInstrument!))
          );
        },
        get voices() {
          return ENSEMBLE.composition?.atomContext?.voices ?? [];
        },
        get nonEmptyVoices(): Voice[] {
          return s.voices.filter(v => !v.isEmpty) ?? [];
        },
        get respondToVoice(): Voice | null {
          return (
            s.nonEmptyVoices.find(v => v._id === p.respondToVoiceId) ??
            s.voices.find(v => v._id === p.respondToVoiceId) ??
            null
          );
        },
        get respondToVoice_color() {
          return s.getColorOfVoice(s.respondToVoice);
        },
        get respondToInstrument(): Instrument | null {
          return p.respondToInstrument ?? null;
        },
        get voicesOn(): (Voice | null)[] {
          return s.notesOn.map(n => n.voice ?? null);
        },
        get voicesOnColors() {
          return s.voicesOn.map(v => s.getColorOfVoice(v));
        },
        getColorOfVoice: (voice: Voice | null) => {
          return tint(
            p.brightenColor ?? 0,
            p.color ?? voice?.appearance?.colorInContext ?? THEME.primary
          );
        },
        get shouldRespondToNoteOrNotes() {
          return !!(p.respondToMidiNumber || !p.respondToVoiceId);
        },
        get mixedColor() {
          if (s.shouldRespondToNoteOrNotes) {
            if (s.voicesOnColors.length === 0) return null;
            const colorMixed = s.voicesOnColors.reduce((result, hex) =>
              chroma.mix(hex, result).hex()
            );
            if (THEME.isDarkTheme)
              return chroma.mix(colorMixed, THEME.bg).hex();
            return colorMixed;
          } else if (p.respondToVoiceId) {
            return s.respondToVoice_color;
          }
        },
        get gradientStopsWhenOn() {
          if (!p.gradientOpacityMap || !s.mixedColor)
            return [withOpacity(s.mixedColor, p.baseOpacityWhenOn ?? 0.85)];
          return [
            withOpacity(
              s.mixedColor,
              (p.baseOpacityWhenOn ?? 0.85) * p.gradientOpacityMap[0]
            ),
            withOpacity(
              s.mixedColor,
              (p.baseOpacityWhenOn ?? 0.85) * p.gradientOpacityMap[1]
            ),
          ];
        },
        get gradientStopsWhenOff() {
          if (!p.gradientOpacityMap || !s.mixedColor)
            return [withOpacity(s.mixedColor, p.baseOpacityWhenOff ?? 0.25)];
          return [
            withOpacity(
              s.mixedColor,
              (p.baseOpacityWhenOff ?? 0.25) * p.gradientOpacityMap[0]
            ),
            withOpacity(
              s.mixedColor,
              (p.baseOpacityWhenOff ?? 0.25) * p.gradientOpacityMap[1]
            ),
          ];
        },
        get gradientStops() {
          if (s.shouldRespondToNoteOrNotes) {
            if (s.voicesOnColors.length === 0) return null;
            return s.gradientStopsWhenOn;
          } else if (p.respondToVoiceId) {
            if (s.notesOn.length === 0) return s.gradientStopsWhenOff;
            return s.gradientStopsWhenOn;
          }
        },
      }),
      {
        nonEmptyVoices: computed.struct,
        voicesOn: computed.struct,
      }
    );

    const style = useStyle(() => ({
      get component(): CSSPartial {
        return {
          position: "absolute",
          top: p.top ?? 0,
          left: p.left ?? 0,
          bottom: p.bottom ?? 0,
          right: p.right ?? 0,
          pointerEvents: "none",
          borderRadius: p.borderRadius,
          overflow: "hidden",
        };
      },
    }));

    useOnMount(() => {
      const d = makeDisposerController();
      const C = createResponsiveCanvas(ref);
      const ctx = C.context;
      const paintFrame = () => {
        ctx.clearRect(0, 0, C.width, C.height);
        if (s.gradientStops) {
          if (s.gradientStops.length === 1) {
            if (s.gradientStops[0]) {
              ctx.fillStyle = s.gradientStops[0];
            }
          } else {
            if (s.gradientStops[0] && s.gradientStops[1]) {
              const gradient = ctx.createLinearGradient(0, 0, 0, C.height);
              gradient.addColorStop(0, s.gradientStops[0]);
              gradient.addColorStop(1, s.gradientStops[1]);
              ctx.fillStyle = gradient;
            }
          }
          if (ctx.fillStyle) ctx.fillRect(0, 0, C.width, C.height);
        }
        // paintRect({
        //   ctx,
        //   x: 0,
        //   y: 0,
        //   w: C.width,
        //   h: C.height,
        //   fill: s.mixedColor,
        // });
      };
      C.onReady(() => runInAction(paintFrame));
      d.add(autorun(paintFrame));

      const handleWindowFocus = () => {
        paintFrame();
      };
      window.addEventListener("focus", handleWindowFocus);
      d.add(() => {
        window.removeEventListener("focus", handleWindowFocus);
      });
      return d.dispose;
    });
    return (
      <Observer
        children={() => (
          <div
            className="PlayStateHighlighter"
            css={style.component}
            ref={ref}
            data-gradient-map={p.gradientOpacityMap?.join("-")}
            data-gradient-stops={s.gradientStops?.join("-")}
          />
        )}
      />
    );
  }
);

export default PlayStateHighlighter;
