import { computed, makeObservable, observable } from "mobx";
import { AtomBaseSnapshot, AtomContext, VoiceOrderType } from "../@types";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { makeAtomContextOptions } from "../logic/AtomContext.maker";
import { has_id } from "../traits/hasId.trait";
import { hasOwnerAndAuthors } from "../traits/hasOwnerAndAuthors.trait";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { Interpretation } from "./Interpretation.model";
import {
  StandardModel,
  addChangeDetectionToModel,
  appendMissingKeys,
} from "./StandardModel";
import { Artist } from "./Artist.model";
import { Score } from "./Score.model";
import { User } from "./User.model";
import { FlemishHarpsichordName } from "../instruments/FlemishHarpsichord/FlemishHarpsichord.instrument";
import { first } from "lodash-es";
import { Package } from "./Package.model";
import dayjs from "dayjs";
import { stripMarkdown } from "../base/components/Markdown";
import { AccessType } from "./_index.model";
import { makeDefaultVideoExportConfig } from "./RenderJob.model";

export const makeCompositionOptions = () => ({
  ...makeAtomContextOptions(),
  voiceDisplayName: "",
  renderTitleAsMarkdown: false,
  defaultInstrumentName: FlemishHarpsichordName,
  automaticallyManageBars: true,
  voiceOrder: "by-pitch" as VoiceOrderType,
  videoExportConfig: makeDefaultVideoExportConfig(),
});

export type CompositionOptions = Partial<
  ReturnType<typeof makeCompositionOptions>
>;

export const CompositionSnapshotFactory = () => ({
  ...has_id(),
  title: "",
  subheading: "",
  description: "",
  atomSnapshots: [] as AtomBaseSnapshot[],
  options: makeCompositionOptions(),
  ...hasOwnerAndAuthors(),
  interpretationIds: [] as string[],
  scoreIds: [] as string[],
  packageIds: [] as string[],
  _duration: 0,
  artistIds: [] as string[],
  arrangerIds: [] as string[],
  initialSource: "",
  thumbnailDarkId: null as string | null,
  thumbnailLightId: null as string | null,
  readAccess: "private" as AccessType,
  ...hasTimestamps(),
});

const defaultSnapshot = CompositionSnapshotFactory();

export type CompositionSnapshot = ReturnType<typeof CompositionSnapshotFactory>;

const changeDetectionSnapshotMaker = ($: CompositionSnapshot) => {
  const {
    _id,
    title,
    description,
    options,
    interpretationIds,
    scoreIds,
    artistIds,
    arrangerIds,
  } = $;
  return JSON.stringify({
    _id,
    title,
    description,
    options,
    interpretationIds,
    scoreIds,
    artistIds,
    arrangerIds,
  });
};

export class Composition extends StandardModel<
  ModelName.compositions,
  CompositionSnapshot
> {
  constructor(LOCALDB: LocalDBController, $ = CompositionSnapshotFactory()) {
    appendMissingKeys($, defaultSnapshot);
    super(ModelName.compositions, LOCALDB, $);
    makeObservable(this, {
      atomContext: observable,
      title: computed,
      titlePlaintext: computed,
      owner: computed,
      authors: computed,
      artists: computed,
      arrangers: computed,
      collections: computed,
      primaryCollection: computed,
      interpretations: computed,
      defaultInterpretation: computed,
      scores: computed,
      packages: computed,
      voiceDisplayName: computed,
      isArchived: computed,
      thumbnailDark: computed,
      thumbnailLight: computed,
      isPublic: computed,
      isPrivate: computed,
    });
    addChangeDetectionToModel(
      this,
      LOCALDB,
      changeDetectionSnapshotMaker,
      this.d
    );
  }
  atomContext: AtomContext | null = null;
  get options() {
    return this.$.options;
  }
  get title() {
    return this.$.title || "Untitled Composition";
  }
  set title(value) {
    this.$.title = value;
  }
  get titlePlaintext() {
    if (this.options.renderTitleAsMarkdown) return stripMarkdown(this.title);
    return this.title;
  }
  get owner() {
    return this.LOCALDB.get<User>(ModelName.users, this.$.ownerId);
  }
  get authors() {
    return this.LOCALDB.getMany<User>(ModelName.users, this.$.authorIds);
  }
  get artists() {
    return this.LOCALDB.getMany<Artist>(ModelName.artists, this.$.artistIds);
  }
  get arrangers() {
    return this.LOCALDB.getMany<Artist>(ModelName.artists, this.$.arrangerIds);
  }
  get collections() {
    return (
      this.ROOT?.COLLECTIONS.all.filter(c =>
        c.$.compositionIds.includes(this.$._id)
      ) ?? []
    );
  }
  get primaryCollection() {
    return this.collections[0];
  }
  get interpretations() {
    return this.LOCALDB.getMany<Interpretation>(
      ModelName.interpretations,
      this.$.interpretationIds
    );
  }
  get defaultInterpretation() {
    return first(this.interpretations);
  }
  get scores() {
    return this.LOCALDB.getMany<Score>(ModelName.scores, this.$.scoreIds);
  }
  get packages() {
    if (this.ROOT?.COMPOSER.instance?.package) {
      if (this.$.packageIds.includes(this.ROOT.COMPOSER.instance.package._id))
        return [this.ROOT.COMPOSER.instance.package];
      return [];
    }
    return [
      ...this.LOCALDB.getMany<Package>(
        ModelName.packages,
        this.$.packageIds ?? []
      ),
    ].sort((a, b) => dayjs(b.$.timeCreated).diff(a.$.timeCreated));
  }
  get voiceDisplayName() {
    return this.$.options.voiceDisplayName ?? "Voice";
  }
  get isArchived() {
    return !!this.$.timeArchived;
  }
  get thumbnailDark() {
    return this.defaultInterpretation?.thumbnailDark;
  }
  get thumbnailLight() {
    return this.defaultInterpretation?.thumbnailLight;
  }
  get isPublic() {
    return this.$.readAccess === "public";
  }
  get isPrivate() {
    return this.$.readAccess !== "public";
  }
}

export const makeCompositionSnapshot = (
  partial: Partial<CompositionSnapshot>
) => makeSnapshot(CompositionSnapshotFactory, partial);
