/** @jsxImportSource @emotion/react */
import { sort } from "fast-sort";
import { Observer } from "mobx-react-lite";
import React from "react";
import { ButtonGroup } from "../../base/components/Button";
import LargeButton from "../../base/components/LargeButton";
import PageSectionHeader from "../../base/components/PageSectionHeader";
import Spacing from "../../base/components/Spacing";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { makeBooleanState } from "../../base/hooks/useBooleanState.hooks";
import IconNewComposition27 from "../../base/icons/27/NewComposition.icon.27";
import { useStore } from "../../base/utils/mobx.utils";
import { first } from "../../base/utils/ramdaEquivalents.utils";
import CompositionEntry from "../../components/directory/CompositionEntry";
import CompositionImporter from "../../components/controls/CompositionImporter";
import MidiImporter from "../../components/controls/MidiImporter";
import NewCompositionPanel from "../../components/panels/NewCompositionPanel";
import { Composition } from "../../models/Composition.model";
import WorkshopAreaPageTemplate from "./_WorkshopArea.pageTemplate";
import WorkshopAreaPageHeader from "./_WorkshopAreaPageHeader";
import LineSeparator from "../../base/components/LineSeparator";
import WithRequest from "../../base/components/ContainerWithRequest";
import NoCompPlaceholder from "../../components/shared/NoCompPlaceholder";
import RecentCompsGrid from "./RecentCompsGrid";

type WorkshopHomePageProps = {};

const WorkshopHomePage: React.FC<WorkshopHomePageProps> = props => {
  const { COMPOSITIONS, AUTH } = useControllers();

  const s = useStore(() => ({
    get allOwnNonArchivedCompsSortedByLatestFirst() {
      return sort(COMPOSITIONS.ownNonArchived).desc(c => c.$.timeUpdated);
    },
    get mostRecentComp(): Composition | null {
      return first(s.allOwnNonArchivedCompsSortedByLatestFirst) ?? null;
    },
    get recentComps(): Composition[] {
      return s.allOwnNonArchivedCompsSortedByLatestFirst.slice(1);
    },
    shouldRenderNewCompPanel: makeBooleanState(false),
    handleDataFetch: () => {
      // if (s.allOwnNonArchivedCompsSortedByLatestFirst.length === 0) {
      //   s.shouldRenderNewCompPanel.toggle(true);
      // }
    },
  }));

  return (
    <Observer
      children={() => (
        <WorkshopAreaPageTemplate title="Workshop">
          <WorkshopAreaPageHeader
            EndSlot={
              <ButtonGroup>
                <CompositionImporter />
                <MidiImporter />
              </ButtonGroup>
            }
          >
            <h1>Workshop</h1>
          </WorkshopAreaPageHeader>

          <WithRequest
            id={["WorkshopHome", AUTH.user?._id]}
            request={COMPOSITIONS.getAllOwnMeta}
            options={{
              onFirstFetchComplete: s.handleDataFetch,
            }}
            children={() => (
              <>
                <PageSectionHeader>
                  <h2>
                    {s.recentComps.length > 0 ? "Most recent" : "Get started"}
                  </h2>
                </PageSectionHeader>
                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "minmax(0,1fr) auto",
                    gridGap: "2em",
                  }}
                >
                  {s.mostRecentComp ? (
                    <CompositionEntry
                      composition={s.mostRecentComp}
                      mode="editor"
                      showPrimaryCollection
                      enableContextMenu
                    />
                  ) : (
                    <NoCompPlaceholder />
                  )}
                  <LargeButton
                    Icon={<IconNewComposition27 />}
                    Label="New composition"
                    onClick={s.shouldRenderNewCompPanel.toggle}
                    width="18rem"
                    height="18rem"
                  />
                </div>

                <Spacing size="2.5em" />
                <LineSeparator />
                <Spacing size="2.5em" />

                {s.recentComps.length > 0 ? (
                  <RecentCompsGrid comps={s.recentComps} />
                ) : s.mostRecentComp ? (
                  <NoCompPlaceholder text="No more compositions found." />
                ) : null}

                {s.shouldRenderNewCompPanel.value && (
                  <NewCompositionPanel
                    onShouldClose={s.shouldRenderNewCompPanel.toggle}
                  />
                )}
              </>
            )}
          />
        </WorkshopAreaPageTemplate>
      )}
    />
  );
};

export default WorkshopHomePage;
