/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { InstrumentDef } from "../../@types";
import SelectableItem from "../../base/components/SelectableItem";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { setValueOfKey } from "../../base/utils/object.utils";
import { KeyboardInstrumentDefinitions } from "../../controllers/ensemble/instrument.index";
import { BlanchetHarpsichordName } from "../../instruments/BlanchetHarpsichord/BlanchetHarpsichord.instrument";
import InstrumentMetaEntry from "../shared/InstrumentMetaEntry";

type Props<T extends {} = {}> = {
  className?: string;
  form: T;
  field: StringKeyOf<T>;
};

const selectorStyle = css``;
const gridStyle = css`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.25em;
`;

const itemStyle = css`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  cursor: default;
`;

const KeyboardInstrumentSelector = <T extends {} = {}>(props: Props<T>) => {
  const p = useProps(props);
  const { AUTH } = useControllers();
  const s = useStore(() => ({
    get selection() {
      return (p.form[p.field] ?? null) as string | null;
    },
    selectHandlerFactory: (def: InstrumentDef) =>
      action(() => {
        setValueOfKey(p.form, p.field, def.meta.name);
      }),
    get usableDefs() {
      if (AUTH.user?.$.username === "yile")
        return KeyboardInstrumentDefinitions;
      return KeyboardInstrumentDefinitions.filter(
        def => def.meta.name !== BlanchetHarpsichordName && !def.meta.hidden
      );
    },
  }));
  return (
    <Observer
      children={() => (
        <div
          className={cx("KeyboardInstrumentSelector", p.className)}
          css={selectorStyle}
        >
          <div css={gridStyle}>
            {s.usableDefs.map((def: InstrumentDef) => (
              <SelectableItem
                key={def.meta.name}
                selectStateChecker={() => s.selection === def.meta.name}
                onClick={s.selectHandlerFactory(def)}
                css={itemStyle}
              >
                <InstrumentMetaEntry instrumentMeta={def.meta} />
              </SelectableItem>
            ))}
          </div>
        </div>
      )}
    />
  );
};

export default KeyboardInstrumentSelector;
