import { capitalize, pluralize, singularize } from "../base/utils/string.utils";

export enum ModelName {
  "users" = "users",
  "compositions" = "compositions",
  "interpretations" = "interpretations",
  "scores" = "scores",
  "collections" = "collections",
  "artists" = "artists",
  "fileRecords" = "fileRecords",
  "packages" = "packages",
  "renderJobs" = "renderJobs",
  "libraryEntries" = "libraryEntries",
}
export enum ModelAPIName {
  "User" = "User",
  "Composition" = "Composition",
  "Interpretation" = "Interpretation",
  "Score" = "Score",
  "Collection" = "Collection",
  "Artist" = "Artist",
  "FileRecord" = "FileRecord",
  "Package" = "Package",
  "RenderJob" = "RenderJob",
  "LibraryEntry" = "LibraryEntry",
}

export const getModelNameFromAPIName = (constructorName: ModelAPIName) => {
  return `${pluralize(constructorName)[0].toLowerCase()}${pluralize(
    constructorName
  ).slice(1)}` as ModelName;
};
export const getModelAPINameFromModelName = (modelName: ModelName) => {
  return capitalize(singularize(modelName)) as ModelAPIName;
};

export const isModelName = (string: string): string is ModelName => {
  return Object.values(ModelName).includes(string as ModelName);
};
