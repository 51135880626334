import { computed, makeObservable } from "mobx";
import { AtomBaseSnapshot, InstrumentSnapshot } from "../@types";
import { RuleSnapshot } from "../@types/interpretations.types";
import { recursiveMergeWithTypeCast } from "../base/utils/object.utils";
import { first } from "../base/utils/ramdaEquivalents.utils";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { makeImageExportOptionsBag } from "../components/composer/AtomMapSvg";
import {
  defaultVisualizerCameraFocalLength,
  defaultVisualizerCameraZDistance,
} from "../components/visualizer/visualizerConstants";
import {
  DefaultCanvasYCenterNote,
  DefaultCenterOctave,
} from "../constants/composer.constants";
import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { makeClavieristColorTheme } from "../theming/themes/clavierist.colorTheme";
import { has_id } from "../traits/hasId.trait";
import { hasOwnerAndAuthors } from "../traits/hasOwnerAndAuthors.trait";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { makeMidiExportOptionsBag } from "../utils/midi/midiExport.utils";
import { Artist } from "./Artist.model";
import { Composition } from "./Composition.model";
import { FileRecord } from "./FileRecord.model";
import {
  StandardModel,
  addChangeDetectionToModel,
  appendMissingKeys,
} from "./StandardModel";
import { User } from "./User.model";
import {
  DefaultVignetteEffectDarkness,
  DefaultVignetteEffectOffset,
} from "../components/visualizer/shaders/VignetteShader";

export type InterpretationSnapshot = ReturnType<
  typeof InterpretationSnapshotFactory
>;

export const makeInterpretationOptionsObject = () => ({
  bpm: 96,
  a4: 440,
  loop: false,
  leadingBeatsBeforeStart: 1,
  canvasYCenterNote: DefaultCanvasYCenterNote,
  centerOctave: DefaultCenterOctave,
  ptPerX: 48,
  ptPerY: 7,
  visualizer: makeVisualizationOptionsObject(),
  reverb: {
    enabled: true,
    decay: 5,
    wet: 1,
  },
});

export const DefaultBloomMaxStrength = 1;
export const DefaultBloomThreshold = 0.85;
export const DefaultBloomRadius = 0.5;

export const makeDefaultBloomEffectOptions = (enable?: boolean) => ({
  enable: enable ?? false,
  maxStrength: DefaultBloomMaxStrength,
  threshold: DefaultBloomThreshold,
  radius: DefaultBloomRadius,
});

export const DefaultFilmNoiseIntensity = 0.1;
export const DefaultFilmScanlineIntensity = 0;
export const DefaultFilmScanlineCount = 720;
export const DefaultFilmGrayScale = 0;

export const makeDefaultFilmEffectOptions = () => ({
  enable: false,
  noiseIntensity: DefaultFilmNoiseIntensity,
  scanlinesCount: DefaultFilmScanlineCount,
  scanlinesIntensity: DefaultFilmScanlineIntensity,
});

export type FilmEffectOptions = ReturnType<typeof makeDefaultFilmEffectOptions>;
export type FilmPassUniformsObject = {
  nIntensity: { value: number };
  sIntensity: { value: number };
  sCount: { value: number };
};

export const makeDefaultVignetteEffectOptions = () => ({
  enable: false,
  offset: DefaultVignetteEffectOffset,
  darkness: DefaultVignetteEffectDarkness,
});

export const makeDefaultCameraOptions = () => ({
  focalLength: defaultVisualizerCameraFocalLength,
  positionOffsetX: 0,
  positionOffsetY: 0,
  z: defaultVisualizerCameraZDistance,
});

export const makeVisualizerEffectsOptionsObject = (defaults?: {
  enableBloom?: boolean;
}) => ({
  bloom: makeDefaultBloomEffectOptions(defaults?.enableBloom),
  film: makeDefaultFilmEffectOptions(),
  vignette: makeDefaultVignetteEffectOptions(),
});

// remember to update the API counterpart
export const makeVisualizationOptionsObject = () => ({
  effects: {
    dark: makeVisualizerEffectsOptionsObject({ enableBloom: true }),
    light: makeVisualizerEffectsOptionsObject(),
  },
  camera: makeDefaultCameraOptions(),
});

export type VisualizerOptionsObject = ReturnType<
  typeof makeVisualizationOptionsObject
>;

export type InterpretationOptions = ReturnType<
  typeof makeInterpretationOptionsObject
>;

export const InterpretationSnapshotFactory = () => ({
  ...has_id(),
  name: "",
  description: "",
  color: "",
  ruleSnapshots: [] as RuleSnapshot[],
  atomSnapshots: [] as AtomBaseSnapshot[],
  instrumentSnapshots: [] as InstrumentSnapshot[],
  compositionId: "",
  thumbnailDarkId: null as string | null,
  thumbnailLightId: null as string | null,
  ...hasOwnerAndAuthors(),
  artistIds: [] as string[],
  arrangerIds: [] as string[],
  options: makeInterpretationOptionsObject(),
  ...hasTimestamps(),
  theme: makeClavieristColorTheme(),
  midiExportOptions: makeMidiExportOptionsBag(),
  imageExportOptions: makeImageExportOptionsBag(),
  _duration: 0,
});

const defaultSnapshot = InterpretationSnapshotFactory();

const changeDetectionSnapshotMaker = ($: InterpretationSnapshot) => {
  const {
    _id,
    name,
    description,
    color,
    thumbnailDarkId,
    thumbnailLightId,
    compositionId,
    artistIds,
    arrangerIds,
    options,
    theme,
    midiExportOptions,
  } = $;
  return JSON.stringify({
    _id,
    name,
    description,
    color,
    thumbnailDarkId,
    thumbnailLightId,
    compositionId,
    artistIds,
    arrangerIds,
    options,
    theme,
    midiExportOptions,
  });
};

export class Interpretation extends StandardModel<
  ModelName.interpretations,
  InterpretationSnapshot
> {
  constructor(LOCALDB: LocalDBController, $ = InterpretationSnapshotFactory()) {
    appendMissingKeys($, defaultSnapshot);
    super(ModelName.interpretations, LOCALDB, $);
    makeObservable(this, {
      owner: computed,
      authors: computed,
      artists: computed,
      arrangers: computed,
      composition: computed,
      thumbnailDark: computed,
      thumbnailLight: computed,
      isDefault: computed,
    });
    this.$.theme = recursiveMergeWithTypeCast(
      makeClavieristColorTheme(),
      this.$.theme ?? {}
    );
    addChangeDetectionToModel(
      this,
      LOCALDB,
      changeDetectionSnapshotMaker,
      this.d
    );
  }
  get options() {
    return this.$.options;
  }
  get owner() {
    return this.LOCALDB.get<User>(ModelName.users, this.$.ownerId);
  }
  get authors() {
    return this.LOCALDB.getMany<User>(ModelName.users, this.$.authorIds);
  }
  get artists() {
    return this.LOCALDB.getMany<Artist>(ModelName.artists, this.$.artistIds);
  }
  get arrangers() {
    return this.LOCALDB.getMany<Artist>(ModelName.artists, this.$.arrangerIds);
  }
  get composition() {
    return this.LOCALDB.get<Composition>(
      ModelName.compositions,
      this.$.compositionId
    );
  }
  get isDefault() {
    return first(this.composition?.$.interpretationIds) === this._id;
  }
  getRuleByRuleId(id: string) {
    return this.$.ruleSnapshots.find(rule => rule._id === id);
  }
  get thumbnailDark() {
    return this.$.thumbnailDarkId
      ? this.LOCALDB.get<FileRecord>(
          ModelName.fileRecords,
          this.$.thumbnailDarkId
        )
      : null;
  }
  get thumbnailLight() {
    return this.$.thumbnailLightId
      ? this.LOCALDB.get<FileRecord>(
          ModelName.fileRecords,
          this.$.thumbnailLightId
        )
      : null;
  }
}

export const makeInterpretationSnapshot = (
  partial: Partial<InterpretationSnapshot>
) => makeSnapshot(InterpretationSnapshotFactory, partial);
