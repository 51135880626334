import { CSSPartial } from "../../@types/css.types";

export const grid6col = {
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridGap: ".5em",
  } as CSSPartial,
  gridAlignEnd: {
    display: "grid",
    alignItems: "end",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridGap: ".5em",
  } as CSSPartial,
  gridGapLg: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridGap: "2em",
  } as CSSPartial,
  full: {
    gridColumnEnd: "span 6",
  } as CSSPartial,
  half: {
    gridColumnEnd: "span 3",
  } as CSSPartial,
  third: {
    gridColumnEnd: "span 2",
  } as CSSPartial,
  twoThirds: {
    gridColumnEnd: "span 4",
  } as CSSPartial,
};

export const grid3col = {
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: ".5em",
  } as CSSPartial,
  gridAlignEnd: {
    display: "grid",
    alignItems: "end",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: ".5em",
  } as CSSPartial,
  gridGapLg: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "2em",
  } as CSSPartial,
  full: {
    gridColumnEnd: "span 3",
  } as CSSPartial,
  twoThirds: {
    gridColumnEnd: "span 2",
  } as CSSPartial,
};
