/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Atom } from "../../@types";

type AtomBarRangeDisplayProps = {
  atom: Atom;
};

const AtomBarRangeDisplay: React.FC<AtomBarRangeDisplayProps> = props => {
  return (
    <Observer
      children={() => (
        <span className="AtomBarRangeDisplay">
          {props.atom.startsInBar === props.atom.endsInBar
            ? props.atom.startsInBar?.displayName
            : [
                props.atom.startsInBar?.displayName,
                props.atom.endsInBar?.barNumber,
              ]
                .filter(i => i)
                .join("–")}
        </span>
      )}
    />
  );
};

export default AtomBarRangeDisplay;
