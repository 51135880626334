import { action } from "mobx";
import { Ornament } from "../@types";
import { OrnamentationType } from "../constants/ornaments.constants";
import { generateOrnamentNoteSnapshots } from "../logic/ornaments/index.ornamentDefs";

export const changeOrnamentType = action(
  (ornament: Ornament, newType: OrnamentationType) => {
    const ac = ornament.context;
    if (!ac || !ornament.note) return;
    if (ornament.note.refAtom) {
      ornament.note.disableOrnamentReplicaSync = true;
    }
    ac.removeAtoms([...ornament.children]);
    ornament.ornamentationType = newType;
    generateOrnamentNoteSnapshots(ornament.note, newType).forEach($ =>
      ac.createNote({
        ...$,
        parentIds: [ornament._id],
        _itpId: ornament._itpId,
      })
    );
  }
);
