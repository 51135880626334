/** @jsxImportSource @emotion/react */
import { autorun, runInAction } from "mobx";
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { SyntheticEvent } from "react";
import { FormControlProps } from "../@types";
import { useOnMount } from "../hooks/lifecycle.hooks";
import cx from "../utils/className.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import resolveAfter from "../utils/waiters.utils";
import FormLabel from "./FormLabel";
import { CSSPartial } from "../@types/css.types";
import { mediaOnlyPhones } from "../styles/helpers/mediaQueries.styling";
import { border } from "../styles/helpers/shorthands.styleHelpers";
import { cVar } from "../utils/customProperties.utils";
import {
  VAR_InputBackground,
  VAR_InputBackgroundHover,
  VAR_InputBorder,
  VAR_InputFocusOutline,
  VAR_InputForeground,
  fg50,
  varPrimary,
  varPrimary10,
} from "../../constants/cssCustomProperties.constants";
import { ColorPalette } from "../../theming/colorPalette";
import { useMaybeComposer } from "../../components/composer/ComposerApp.context";
import ResetButton from "./ResetButton";
import SpaceBetween from "./SpaceBetween";
import { isNotNil } from "../utils/ramdaEquivalents.utils";
import { withOpacity } from "../utils/colors.utils";
import { IconName } from "./Symbols/iconDefs/_index.iconDefs";
import SymbolIcon from "./SymbolIcon";
import { isNil, round } from "lodash-es";
import { UNITS } from "../constants/units.constant";
import { useObservableRef } from "../hooks/useObservableRef.hook";

export type TextInputAppearance = "default" | "transparent" | "underlined";

export type TextInputProps<T extends {} = {}> = FormControlProps<T> & {
  type?:
    | "text"
    | "number"
    | "email"
    | "password"
    | "time"
    | "date"
    | "search"
    | "url"
    | "tel";
  Label?: React.ReactNode;
  step?: string | number;
  min?: string | number;
  max?: string | number;
  placeholder?: string;
  onEnter?: (value?: string) => void;
  onEscape?: (value?: string) => void;
  onKeyUp?: (e: TextInputKeyboardEvent) => void;
  onKeyDown?: (e: TextInputKeyboardEvent) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  autoFocus?: boolean;
  autoCapitalize?: string;
  autoCorrect?: string;
  appearance?: TextInputAppearance;
  innerRef?: React.MutableRefObject<HTMLInputElement | null>;
  selectOnFocus?: boolean;
  inline?: boolean;
  precision?: number;
  width?: string | number;
  minWidth?: string | number;
  adaptToContentWidth?: boolean;
  height?: string | number;
  minHeight?: string | number;
  title?: string;
  resettable?: boolean;
  defaultValue?: unknown;
  icon?: IconName;
  transformer?: (value: string) => string;
};

export type TextInputEvent = SyntheticEvent<HTMLInputElement>;
export type TextInputKeyboardEvent = React.KeyboardEvent<HTMLInputElement>;

const TextInput = <T extends {} = {}>(
  props: React.PropsWithChildren<TextInputProps<T>>
) => {
  const p = useProps(props);
  const I = useMaybeComposer();
  const ref = useObservableRef<HTMLInputElement>();

  const s = useStore(() => ({
    get appearance() {
      return p.appearance ?? "default";
    },
    hasFocus: false,
    get precision() {
      return p.precision ?? 4;
    },
    get outerValue() {
      if (p.getter) return `${p.getter()}`;
      return p.form && isNotNil(p.field) && p.field in p.form
        ? p.form[p.field] === null
          ? ""
          : p.form[p.field]
        : "";
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set outerValue(v: any) {
      if (p.setter) p.setter(v as unknown);
      else if (p.form && isNotNil(p.field)) p.form[p.field] = v;
    },
    _innerValue: "" as string | number,
    get innerValue(): string | number {
      return s._innerValue;
    },
    set innerValue(v: string | number) {
      switch (p.type) {
        case "number": {
          s._innerValue = isNaN(+v)
            ? isNotNil(p.defaultValue)
              ? `${p.defaultValue}`
              : ""
            : round(+v, s.precision);
          break;
        }
        default: {
          s._innerValue = v;
        }
      }
    },
    get charLength(): number {
      return `${s._innerValue}`.length;
    },
    applyValueToOuter: () => {
      if (s.outerValue !== s.innerValue) {
        const fn = () => {
          const prev = s.outerValue;
          s.outerValue = s.innerValue;
          p.onChange && p.onChange(s.innerValue, prev);
        };
        const taskName = p.mergeableTaskName ?? p.taskName;
        if (taskName && I)
          I.runInHistory(taskName, fn, {
            mergeableId: p.mergeableTaskName ?? p.mergeableId,
          });
        else fn();
      }
    },
    handleChange: (e: TextInputEvent) => {
      if (p.transformer) {
        s.innerValue = p.transformer(e.currentTarget.value);
      } else {
        s.innerValue = e.currentTarget.value;
      }
      s.applyValueToOuter();
    },
    handleKeyDown: (e: TextInputKeyboardEvent) => {
      switch (e.key) {
        case "d":
          // command+d is duplicate.
          // if the user presses cmd+d, they probably mean to duplicate an atom instead of adding this page to favorites, or whatever the browser shortcut for command+d is set to.
          if (e.metaKey || e.ctrlKey) {
            e.preventDefault();
            ref.current?.blur();
          }
      }
      p.onKeyDown?.(e);
    },
    handleKeyUp: (e: TextInputKeyboardEvent) => {
      switch (e.key) {
        case "Enter":
          p.onEnter?.(`${s.innerValue}`);
          break;
        case "Escape":
          p.onEscape?.(`${s.innerValue}`);
          if (s.hasFocus) ref.current?.blur();
          break;
      }
      p.onKeyUp?.(e);
    },
    handleFocus: (e: TextInputEvent) => {
      s.hasFocus = true;
      p.onFocus?.(s.innerValue);
      if (p.selectOnFocus) s.select();
    },
    handleBlur: (e: TextInputEvent) => {
      s.hasFocus = false;
      p.onBlur?.(s.innerValue);
    },
    get ref() {
      return p.innerRef || ref;
    },
    select: async () => {
      await resolveAfter();
      runInAction(() => s.ref.current?.select());
    },
    get label() {
      return (
        (p.Label || p.resettable) && (
          <FormLabel bold>
            {!p.disabled && p.resettable && p.form && isNotNil(p.field) ? (
              <SpaceBetween>
                {p.Label}
                <ResetButton
                  form={p.form}
                  field={p.field}
                  default={p.defaultValue}
                />
              </SpaceBetween>
            ) : (
              <>{p.Label}</>
            )}
          </FormLabel>
        )
      );
    },
  }));

  useOnMount(() => {
    runInAction(() => {
      if (
        (isNil(s.outerValue) || s.outerValue === "") &&
        isNotNil(p.defaultValue)
      ) {
        s.outerValue = s.innerValue = p.defaultValue as string | number;
      }
    });
    return autorun(() => {
      s.innerValue = s.outerValue;
    });
  });

  const style = useStore(() => ({
    get isDefault(): boolean {
      return s.appearance === "default";
    },
    get isTransparent(): boolean {
      return s.appearance === "transparent";
    },
    get isUnderlined(): boolean {
      return s.appearance === "underlined";
    },
    get focusStyle(): CSSPartial {
      return style.isTransparent
        ? {}
        : {
            outline: cVar(VAR_InputFocusOutline),
            outlineColor: p.color ?? varPrimary,
            backgroundColor: p.color ? withOpacity(p.color, 0.2) : varPrimary10,
            outlineOffset: "-1px",
            ...(style.isUnderlined && {
              outline: "none",
              borderBottomColor: p.color ?? varPrimary,
            }),
          };
    },
    get component(): CSSPartial {
      return {
        display: p.inline ? "inline-flex" : "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "stretch",
        minWidth: p.minWidth,
        width: p.width,
        maxWidth: "100%",
        lineHeight: "1.2",
        label: {
          marginBottom: ".5em",
          color: p.resettable ? varPrimary : "currentcolor",
        },
        "&.hasIcon": {
          position: "relative",
          input: {
            paddingLeft: "2.5em",
          },
          ".SymbolIcon": {
            position: "absolute",
            top: "50%",
            left: ".5em",
            transform: "translateY(-50%)",
            width: "1.5em",
            height: "1.5em",
          },
        },
      };
    },
    get input(): CSSPartial {
      return {
        display: "block",
        flex: "1 1 100%",
        minWidth: "inherit",
        maxWidth: "100%",
        border: 0,
        fontSize: "inherit",
        fontWeight: "inherit",
        fontStyle: "inherit",
        width: "100%",
        height: p.height,
        minHeight: p.minHeight ?? (p.height ? undefined : "2.5em"),
        textAlign: "inherit",
        boxSizing: "border-box",
        color: cVar(VAR_InputForeground),
        borderRadius: 2,
        fontFeatureSettings: "'tnum' 1",
        ...(style.isTransparent && {
          border: 0,
          padding: 0,
          backgroundColor: "transparent",
          color: "inherit",
        }),
        ...(style.isUnderlined && {
          backgroundColor: "transparent",
          borderWidth: "0 0 .1em 0",
          borderColor: varPrimary,
          borderStyle: "solid",
          padding: ".62em 0",
        }),
        ...(style.isDefault && {
          backgroundColor: cVar(VAR_InputBackground),
          border: cVar(VAR_InputBorder),
          padding: ".5em",
          "&:not([disabled])": {
            "@media (hover: hover)": {
              "&:hover": {
                backgroundColor: cVar(VAR_InputBackgroundHover),
              },
            },
          },
        }),
        ...mediaOnlyPhones({
          fontSize: 16,
        }),
        "&:focus": style.focusStyle,
        "&::placeholder": {
          color: "inherit",
          opacity: 0.35,
        },
        "&.invalid": {
          outline: border(UNITS.lineWidth, ColorPalette.red),
        },
        "&[disabled]": {
          color: fg50,
        },
      };
    },
  }));
  return (
    <Observer
      children={() => (
        <div
          className={cx("TextInput", p.className, p.icon && "hasIcon")}
          css={style.component}
        >
          {s.label}
          <input
            id={p.id}
            type={p.type}
            name={`${p.field}`}
            value={s.innerValue}
            placeholder={p.placeholder}
            css={style.input}
            min={p.min}
            max={p.max}
            step={p.step}
            onChange={s.handleChange}
            onKeyDown={s.handleKeyDown}
            onKeyUp={s.handleKeyUp}
            onClick={p.onClick}
            onFocus={s.handleFocus}
            onBlur={s.handleBlur}
            disabled={p.disabled}
            autoFocus={p.autoFocus}
            autoCapitalize={p.autoCapitalize}
            autoComplete={p.autoComplete}
            autoCorrect={p.autoCorrect}
            ref={s.ref}
            title={p.title}
            style={{
              width: p.adaptToContentWidth
                ? `${s.charLength + 2}ch`
                : undefined,
            }}
          />
          {p.icon && <SymbolIcon icon={p.icon} />}
        </div>
      )}
    />
  );
};

export default TextInput;
