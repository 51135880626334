import {
  action,
  autorun,
  flow,
  observable,
  reaction,
  runInAction,
  when,
} from "mobx";
import * as Tone from "tone";
import {
  AtomContext,
  Bar,
  Instrument,
  InstrumentRange,
  Keyframe,
  Note,
} from "../@types";
import {
  addOneToArrayIfNew,
  removeOneFromArray,
} from "../base/utils/array.utils";
import { snapByRounding } from "../base/utils/snap.utils";
import { isNumber } from "../base/utils/typeChecks.utils";
import { generateUUID } from "../base/utils/uuid.utils";
import { runAfter } from "../base/utils/waiters.utils";
import { PianoRange } from "../constants/instruments.constants";
import { KnownKeyframeControlPath } from "../constants/keyframe.constants";
import { IS_EMBEDDED } from "../env";
import { Interpreter } from "../logic/interpreter.controller";
import { Composition } from "../models/Composition.model";
import { Interpretation } from "../models/Interpretation.model";
import {
  changeMusicKeyDOMElBrightness,
  resetKeyDOMElBrightness,
} from "../utils/musicKey.utils";
import { getAbstractXFromScaledTime } from "../utils/valueAtXMap.utils";
import type { Metronome } from "./composer/metronome.controller";
import {
  PlaybackController,
  createPlaybackController,
} from "./ensemble/playback.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import {
  ClavieristWindowEvent,
  postMessageToParentWindow,
} from "../utils/messages.utils";
import { ComposerInstance } from "../components/composer/useCreateComposerInstance";
import { last } from "lodash-es";

export type EnsembleController = ReturnType<typeof makeEnsembleController>;
export type PlayPauseState = "playing" | "paused";
export type PlaybackBeatHandler = (beatIndex: number, bar: Bar) => void;
export type PlaybackEndHandler = () => void;
type EnsembleNoteAttackOptions = {
  overrides?: {
    durationInSeconds?: number;
    beatCount?: number;
    velocity?: number;
    time?: number;
  };
};

export const DEFAULT_MASTER_VOLUME = -6;

export const makeEnsembleController = () => {
  const __internal = observable({
    defaultInstrument: null as string | null,
    beatHandlerMap: new Map<string, PlaybackBeatHandler>(),
    endHandlerMap: new Map<string, PlaybackEndHandler>(),
  });

  const c = observable({
    ...makeControllerBase("ENSEMBLE"),

    get composer(): ComposerInstance | null {
      return c.ROOT?.COMPOSER.instance ?? null;
    },
    get supportsTuning(): boolean {
      return c._instrumentArray.every(ins => ins.supportsMasterTuning);
    },
    get masterTuning(): number {
      return c.supportsTuning ? c.interpretation?.$.options.a4 ?? 440 : 440;
    },
    set masterTuning(a4: number) {
      if (!c.interpretation) return;
      const newA4 = a4 > 0 ? a4 : 440;
      if (c.interpretation.$.options.a4 !== newA4) {
        c.releaseAll();
      }
      c.interpretation.$.options.a4 = newA4;
    },

    get composition(): Composition | null {
      return c.composer?.composition ?? null;
    },
    get atomContext(): AtomContext | null {
      return c.composition?.atomContext ?? null;
    },
    get interpreter(): Interpreter | null {
      return c.atomContext?.interpreter ?? null;
    },
    get interpretation(): Interpretation | null {
      return c.interpreter?.interpretation ?? null;
    },

    get instrumentMap(): Map<string, Instrument> {
      return c.interpreter?.instrumentMap ?? new Map();
    },
    get _instrumentArray(): Instrument[] {
      return Array.from(c.instrumentMap.values());
    },

    get focusedInstruments(): Instrument[] {
      return c.interpreter?.focusedInstruments ?? [];
    },
    get totalRangeOfFocusedInstruments(): InstrumentRange {
      const ranges = c._instrumentArray.map(ins => ins.range).flat();
      if (ranges.length === 0) return PianoRange;
      return ranges;
    },

    get timeScale(): number {
      return c.ROOT?.SETTINGS.playback.timeScale ?? 1;
    },
    set timeScale(v) {
      if (!c.ROOT) return;
      c.ROOT.SETTINGS.playback.timeScale = 1;
    },

    reverb: null as Tone.Reverb | null,
    reverbOptions: {
      get enabled() {
        return c.interpretation?.options.reverb.enabled ?? false;
      },
      set enabled(v) {
        if (!c.interpretation || !c.masterVolumeController) return;
        c.interpretation.options.reverb.enabled = v;
        if (v) c.reverb?.connect(c.masterVolumeController);
        else c.reverb?.disconnect(c.masterVolumeController);
      },
      get decay() {
        return c.interpretation?.options.reverb.decay ?? 5;
      },
      set decay(v) {
        if (c.interpretation) c.interpretation.options.reverb.decay = v;
      },
      get wet() {
        return c.interpretation?.options.reverb.wet ?? 1;
      },
      set wet(v) {
        if (c.interpretation) c.interpretation.options.reverb.wet = v;
      },
    },

    get masterVolume(): number {
      return c.ROOT?.SETTINGS.playback.masterVolume ?? DEFAULT_MASTER_VOLUME;
    },
    set masterVolume(newValue: number) {
      if (!c.ROOT?.AUTH.user?.$.preferences) return;
      c.ROOT.SETTINGS.playback.masterVolume = newValue;
    },
    masterVolumeController: null as Tone.Volume | null,

    activatedMidiKeys: new Set<number>(),
    activatedInstruments: new Set<Instrument>(),
    // TODO: refactor so that the activatedKeys always record ongoing notes regardless of where the attack was called
    activateMidiKeysImmediately: (
      n: number | null,
      options?: {
        instruments?: Instrument[];
        velocity?: number;
        duration?: number;
      }
    ) => {
      if (n === null) return;
      if (c.activatedMidiKeys.has(n)) c.deactivateMidiKeyImmediately(n);
      c.activatedMidiKeys.add(n);
      (options?.instruments ?? c.focusedInstruments).forEach(ins => {
        ins.attack(n, undefined, {
          ...options,
          inputType: "midi",
          velocity: options?.velocity ?? 0.5,
          immediate: true,
        });
        c.activatedInstruments.add(ins);
      });
      if (isNumber(options?.duration)) {
        runAfter(() => {
          c.deactivateMidiKeyImmediately(n, {
            instruments: options?.instruments,
          });
        }, options!.duration * 1000);
      }
      changeMusicKeyDOMElBrightness(n, c.ROOT!.THEME.isDarkTheme ? 0.4 : 0.9);
    },
    deactivateMidiKeyImmediately: (
      n: number | null,
      options?: {
        instruments?: Instrument[];
        velocity?: number;
      }
    ) => {
      if (n === null) return;
      const deleted = c.activatedMidiKeys.delete(n);
      if (!deleted) return;
      (
        options?.instruments ?? [
          ...c.focusedInstruments,
          ...c.activatedInstruments,
        ]
      ).forEach(ins => {
        ins.release(n, undefined, {
          ...options,
          inputType: "midi",
          velocity: options?.velocity ?? 0.5,
          immediate: true,
        });
        c.activatedInstruments.delete(ins);
      });
      resetKeyDOMElBrightness(n);
    },
    deactivateAll: () => {
      c.activatedMidiKeys.forEach(k => {
        c.deactivateMidiKeyImmediately(k);
      });
    },
    isKeyActivated: (i: number) => c.activatedMidiKeys.has(i),

    notesOn: [] as Note[],

    attackNote: (n: Note, options?: EnsembleNoteAttackOptions) => {
      if (n._isDeleted || n.muted) return;
      if (n.interpreted?.ornament) return;
      const markNoteOff = n._markNoteOn?.() ?? (() => {});
      addOneToArrayIfNew(c.notesOn, n);
      n.interpreted?.instruments.forEach(ins => {
        ins?.attackNote(n, {
          time: options?.overrides?.time,
        });
      });
      return { markNoteOff };
    },

    releaseNoteVisual: (
      n: Note,
      options?: {
        markNoteOff?: () => void;
      }
    ) => {
      options?.markNoteOff?.();
      removeOneFromArray(c.notesOn, n);
    },

    releaseNoteAudio: (n: Note) => {
      n.interpreted?.instruments.forEach(ins => ins?.releaseNote?.(n));
      const selectTool = c.ROOT?.COMPOSER.instance?.tools.select;
      const isOnlyNoteInSelection =
        selectTool?.allNotesInSelectionSortedByEndX.length === 1;
      const completedLastNoteInSelection =
        selectTool?.hasNotesInSelection &&
        last(selectTool.allNotesInSelectionSortedByEndX)?._id === n._id;
      if (completedLastNoteInSelection) {
        const shouldLoop =
          c.isPlaying &&
          (c.ROOT?.SETTINGS?.playback.loop ||
            selectTool?.hasNotesInSelection) &&
          !isOnlyNoteInSelection;
        if (shouldLoop && c.playback) {
          c.playback.seekTime(
            selectTool?.selectionPseudoGroup.interpreted.timeStartInSeconds ??
              n.context?.notes[0]?.interpreted.timeStartInSeconds ??
              0,
            true
          );
          c.playback.play();
        } else if (isOnlyNoteInSelection) {
          c.pause();
        }
      }
    },

    releaseNote: (
      n: Note,
      options?: {
        markNoteOff?: () => void;
      }
    ) => {
      c.releaseNoteVisual(n, { markNoteOff: options?.markNoteOff });
      c.releaseNoteAudio(n);
    },

    playNote: (n: Note, options?: EnsembleNoteAttackOptions) => {
      const attackResult = c.attackNote(n, options);
      runAfter(() => {
        c.releaseNoteAudio(n);
      }, n.interpreted.adjustedDuration);
      runAfter(() => {
        c.releaseNoteVisual(n, attackResult);
      }, n.interpreted.timeEndInSeconds ?? 0);
    },

    applyKeyframe: (k: Keyframe) => {
      if (k.disabled) return;
      k._on = true;
      const interpretedDurationInSeconds =
        (k.interpreted?.durationInSeconds ?? k.durationInSeconds ?? 0) *
        (1 / (c.ROOT?.SETTINGS.playback.timeScale ?? 1));
      switch (k.controlPath) {
        case KnownKeyframeControlPath.sustainPedal:
          c.instrumentMap.forEach(ins => ins.sustainPedalDown?.());
          runAfter(() => {
            c.instrumentMap.forEach(ins => ins.sustainPedalUp?.());
          }, interpretedDurationInSeconds * 1000 - 5);
          break;
        default:
          break;
      }
      runAfter(
        action(() => {
          k._on = false;
        }),
        Math.max(
          interpretedDurationInSeconds
            ? interpretedDurationInSeconds - 0.05
            : 0.05,
          0.05
        ) * 1000
      );
    },

    playback: null as PlaybackController | null,
    pause: () => {
      if (c.isPaused) return;
      c.releaseAll();
      c.setPrimaryCursorToCurrentProgress();
      c.playback?.pause();
      c.updatePlayPauseState();
    },
    hasPendingPlayCommandInWaitOfInstrumentLoad: false,
    get hasFocusedVisualizer(): boolean {
      return c.atomContext?.visualizers.some(v => v.hasFocus) ?? false;
    },
    get currentCursorPosition(): number {
      return c.composer?.focusedCanvas?.primaryCursor?.x ?? 0;
    },
    get metronome(): Metronome | null {
      return c.composer?.metronome ?? null;
    },
    play: async (from?: number | string | null) =>
      await flow(function* () {
        if (
          !c.composer ||
          !c.playback?.contentDuration ||
          !c.interpreter ||
          c.hasPendingPlayCommandInWaitOfInstrumentLoad
        )
          return;
        if (c.interpreter.instruments.some(ins => ins.isLoading)) {
          c.hasPendingPlayCommandInWaitOfInstrumentLoad = true;
          yield c.ROOT?.STATUS.registerProgress(
            "Waiting for instruments to finish loading...",
            () => c.interpreter!.noInstrumentsAreLoading
          ).waitForComplete();
          c.hasPendingPlayCommandInWaitOfInstrumentLoad = false;
        }
        const defaultPosition = c.hasFocusedVisualizer
          ? c.playback.playheadPositionInSeconds
          : c.currentCursorPosition;
        c.playback?.play(from ?? defaultPosition);
        c.updatePlayPauseState();
      })(),
    setPrimaryCursorToCurrentProgress: () => {
      if (!c.playback) return;
      let newPosition: number;
      if (c.atomContext) {
        newPosition = getAbstractXFromScaledTime(
          c.playback.playheadPositionInSeconds,
          c.atomContext.valueAtXMaps.scaledTime,
          c.atomContext
        );
      } else {
        newPosition =
          (c.playback?.progress("totalDuration") ?? 0) *
          (c.composition?.atomContext?.lastAtomByEndX?.endX ?? 0);
      }
      c.atomContext?.canvas?.setPrimaryCursorPosition(newPosition);
    },
    togglePlayState: () => {
      if (IS_EMBEDDED && c.playback?.neverPlayed && c.composer?.withPlayerUI) {
        c.playback.seekProgress(0);
      }
      if (c.isPlaying) {
        c.pause();
        return;
      } else {
        const selectTool = c.composer?.tools.select;
        let startFrom = 0 as string | number;
        if (c.composer?.withEditorUI) {
          if (selectTool?.hasSelection) {
            const { selectionPseudoGroup: selection } = selectTool;
            const fallbackStartX =
              selection.x ?? c.atomContext?.canvas?.primaryCursor?.x ?? null;
            startFrom =
              (selection.descendantNotes.length > 0
                ? selection.interpreted.timeStartInSeconds
                : fallbackStartX !== null &&
                  c.atomContext?.valueAtXMaps.scaledTime
                ? c.atomContext.getScaledTime(fallbackStartX)
                : null) ?? 0;
          } else if (c.atomContext) {
            if (c.hasFocusedVisualizer && c.playback) {
              startFrom = c.playback.playheadPositionInSeconds;
            } else {
              if (c.atomContext.canvas?.primaryCursor) {
                const seekX = c.atomContext.canvas.primaryCursor.x;
                if (c.atomContext.canvas.primaryCursor.x === 0) {
                  startFrom = 0;
                } else {
                  startFrom = c.atomContext.getScaledTime(seekX);
                  // console.info(
                  //   `seek for current cursor X (${seekForX}) on scaled time map:`,
                  //   startFrom
                  // );
                }
              }
            }
          }
        } else {
          startFrom = c.playback?.playheadPositionInSeconds ?? 0;
        }
        c.play(startFrom);
      }
    },
    playPauseState: "paused" as PlayPauseState,
    get isRecording(): boolean {
      return c.composer?.tools.record.isRecording ?? false;
    },
    get isRecordingMidi(): boolean {
      return c.composer?.recorders.midi.isRecording ?? false;
    },
    updatePlayPauseState: () => {
      const isPlaying = c.playback ? !c.playback.isPaused() : false;
      c.playPauseState = isPlaying ? "playing" : "paused";
      if (isPlaying) {
        postMessageToParentWindow(
          ClavieristWindowEvent.PlaybackStarted,
          {},
          true
        );
        if (!c.isRecording) c.metronome?.startScheduled();
      } else {
        postMessageToParentWindow(
          ClavieristWindowEvent.PlaybackPaused,
          {},
          true
        );
        if (!c.isRecording) c.metronome?.stopScheduled();
      }
    },
    moveFocusedCanvasPrimaryCursorToPlayheadPosition: () => {
      const canvas = c.composer?.focusedCanvas;
      if (!canvas) return;
      const progressPercentage = c.playback?.progress("totalDuration") ?? 0;
      const x =
        progressPercentage * (c.composition?.atomContext?.playbackWidth ?? 0) +
        (c.composition?.atomContext?.playbackStartX ?? 0);
      const snapX = c.composer?.units.snapX;
      const snappedX = snapX ? snapByRounding(x, snapX) : x;
      const cursor = canvas.primaryCursor;
      if (cursor) cursor.moveTo(snappedX);
    },
    get isPaused(): boolean {
      return c.playPauseState !== "playing";
    },
    get isPlaying(): boolean {
      return c.playPauseState === "playing";
    },
    releaseAll: () => {
      c.notesOn.forEach(n => {
        n.interpreted.instruments.forEach(i => i.releaseNote?.(n));
        n._hardMarkNoteOff();
      });
      c.notesOn.length = 0;
      c._instrumentArray.forEach(ins => ins.releaseAll());
    },
    addBeatHandler: (handler: PlaybackBeatHandler) => {
      const id = generateUUID();
      __internal.beatHandlerMap.set(id, handler);
      return () => __internal.beatHandlerMap.delete(id);
    },
    runOnBeat: (beatIndex: number, bar: Bar) => {
      if (__internal.beatHandlerMap.size === 0) return;
      __internal.beatHandlerMap.forEach(fn => fn(beatIndex, bar));
    },
    addPlaybackEndHandler: (handler: PlaybackEndHandler) => {
      const id = generateUUID();
      __internal.endHandlerMap.set(id, handler);
      return () => __internal.endHandlerMap.delete(id);
    },
    executePlaybackEndHandlers: () => {
      if (__internal.endHandlerMap.size === 0) return;
      __internal.endHandlerMap.forEach(fn => fn());
    },
    stop: () => {
      c.releaseAll();
      c.updatePlayPauseState();
    },
    cleanup: () => {
      c.playback?.dispose();
      c.playback = null;
      c.stop();
    },
  });

  reaction(
    () => c.atomContext,
    () => {
      if (c.atomContext) {
        // console.info("[ENSEMBLE] atom context change");
        when(
          () => !!c.atomContext?.ready,
          () => {
            c.playback = createPlaybackController(
              c,
              action(() => c.atomContext ?? null)
            );
          }
        );
      }
    },
    { fireImmediately: true }
  );

  c.init = makeRootControllerChildInitFn(c, () => {
    window.addEventListener("beforeunload", c.releaseAll);

    runInAction(() => {
      c.reverb = new Tone.Reverb(c.reverbOptions);
      c.masterVolumeController = new Tone.Volume(
        DEFAULT_MASTER_VOLUME
      ).toDestination();
      reaction(
        () => c.interpretation?._id,
        () => {
          if (c.reverbOptions.enabled) {
            c.reverb!.connect(c.masterVolumeController!);
            c.reverb!.generate();
          }
        },
        { fireImmediately: true }
      );
      autorun(() => {
        // console.info(`setting master volume: ${c.masterVolume}dB`);
        c.masterVolumeController!.set({
          volume: c.masterVolume,
        });
      });
      autorun(() => {
        if (c.reverb) {
          // console.info(
          //   `setting reverb options: decay ${c.reverbOptions.decay}, wet ${c.reverbOptions.wet}`
          // );
          c.reverb.set({
            decay: c.reverbOptions.decay,
            wet: c.reverbOptions.wet,
          });
        }
      });
      c.ready = true;
    });
  });

  return c;
};
