/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { varFontSerif } from "../../constants/cssCustomProperties.constants";

const PageSectionHeader: React.FC<PropsWithChildren> = props => {
  return (
    <Observer
      children={() => (
        <header
          css={{
            paddingBottom: "2em",
            "h2, h3, h4": {
              fontFamily: varFontSerif,
              fontWeight: 300,
            },
            h2: {
              fontSize: "2.4rem",
              opacity: 0.38,
            },
          }}
        >
          <div>{props.children}</div>
        </header>
      )}
    />
  );
};

export default PageSectionHeader;
