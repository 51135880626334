/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { CSSPartial } from "../../base/@types/css.types";
import Spacing from "../../base/components/Spacing";
import { clampLines } from "../../base/styles/helpers/clampLines";
import {
  fromDesktopLg,
  fromDesktopMd,
  fromDesktopMl,
  fromTablet,
} from "../../base/styles/helpers/mediaQueries.styling";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { varFontSerif } from "../../constants/cssCustomProperties.constants";
import { Artist } from "../../models/Artist.model";
import { AppPageHeaderPreHeading } from "../layout/AppPage";
import PersonIcon from "./PersonIcon";

type ArtistPageHeaderProps = {
  artist: Artist;
  linkTo?: "discover" | "workshop";
  After?: ReactNode;
};

const style = {
  header: {
    display: "grid",
    gridTemplateColumns: "auto minmax(0, 1fr)",
    gridGap: "2em",
    alignItems: "end",
  } as CSSPartial,
  coverPersonIcon: {
    [fromTablet]: {
      width: "15rem",
    },
    [fromDesktopMd]: {
      width: "20rem",
    },
  } as CSSPartial,
  headerText: {} as CSSPartial,
  title: {
    fontSize: "3.8rem",
    lineHeight: "1.15",
    fontWeight: 200,
    fontFamily: varFontSerif,
    [fromDesktopMd]: {
      fontWeight: 100,
      fontSize: "4.8rem",
    },
    [fromDesktopMl]: {
      fontSize: "5.2rem",
      letterSpacing: "-0.01em",
    },
    [fromDesktopLg]: {
      fontSize: "6.4rem",
      letterSpacing: "-0.015em",
    },
  } as CSSPartial,
  description: {
    maxWidth: "48em",
    lineHeight: "1.4",
    marginTop: "1em",
    ...clampLines(),
    opacity: 0.8,
  } as CSSPartial,
};

const ArtistPageHeader: React.FC<ArtistPageHeaderProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get hasDescription(): boolean {
      return !!p.artist?.$.description?.trim();
    },
  }));
  return (
    <Observer
      children={() => (
        <header css={style.header}>
          <PersonIcon
            css={style.coverPersonIcon}
            imageId={p.artist.$.imageId}
          />
          <div css={style.headerText}>
            <AppPageHeaderPreHeading>
              <Link to={`/${p.linkTo ?? "discover"}/artists/`}>
                {p.artist.$.title ?? "Artist"}
              </Link>
            </AppPageHeaderPreHeading>
            <h1 css={style.title}>{p.artist.fullName}</h1>
            {s.hasDescription && (
              <p css={style.description}>{p.artist.$.description}</p>
            )}
            {p.After && (
              <>
                <Spacing />
                {p.After}
              </>
            )}
          </div>
        </header>
      )}
    />
  );
};

export default ArtistPageHeader;
