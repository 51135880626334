/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Has_Id } from "../../traits/hasId.trait";
import { removeFromArrayById, replaceContents } from "./array.utils";
import { equalByJsonSnapshot } from "./equality.utils";
import { reportError } from "./errors.utils";
import { uniq } from "./ramdaEquivalents.utils";
import { isArray, isBoolean, isObject } from "./typeChecks.utils";

export const isEmptyObject = (o: unknown): o is {} =>
  isObject(o) && !isArray(o) && !Object.keys(o).length;

export const isEmptyObjectOrUndefined = (o: unknown): o is {} | undefined =>
  o === undefined || (isObject(o) && !isArray(o) && !Object.keys(o).length);

export function getValueOfKey<T>(key: keyof T | string, object?: T) {
  return object?.[key as keyof T];
}
export const setValueOfKey = <T, V = unknown>(
  object: T,
  key: keyof T,
  newValue: V
) => {
  object[key] = newValue as any;
};
export const setValueOfKeyFactory =
  <T extends UnknownObject, V>(object: T) =>
  (key: keyof T, newValue: V) => {
    Reflect.set(object, key, newValue);
  };

export function mergeIntoObjectByDescriptors<
  A extends UnknownObject,
  B extends UnknownObject
>(a: A, b: B, options?: { configurable?: boolean }) {
  const propertyDescriptorMap = Object.getOwnPropertyDescriptors(b);
  if (options && isBoolean(options.configurable === true)) {
    Object.values(propertyDescriptorMap).forEach(
      desc => (desc.configurable = options.configurable)
    );
  }
  Object.defineProperties(a, propertyDescriptorMap);
  return a;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function composeObject<T extends UnknownObject = {}>(
  ...arr: UnknownObject[]
) {
  return arr.reduce((a, b) => {
    return Object.defineProperties(a, Object.getOwnPropertyDescriptors(b));
  }, {}) as T;
}

export function copyWithJSON<T>(obj?: T) {
  if (obj === undefined || obj === null) return obj!;
  return JSON.parse(JSON.stringify(obj)) as T;
}

export type TypeCastSchema<T> = Partial<
  Record<keyof T, "string" | "boolean" | "number" | "array" | "object">
>;

export function recursiveMerge<T extends UnknownObject = UnknownObject>(
  obj: T,
  src?: Partial<T> | null,
  schema: TypeCastSchema<T> = {}
) {
  if (!src) return obj;
  const keys = uniq([...Object.keys(obj), ...Object.keys(src)]);
  keys.forEach((key: any) => {
    const setter = setValueOfKeyFactory(obj as any);
    if (obj[key] === src[key]) return;
    if (obj[key] === null || src[key] === null) setter(key, null);
    if (src[key] !== undefined) {
      const typeofKey = schema[key] || typeof obj[key];
      switch (typeofKey) {
        case "boolean":
        case "string":
        case "number":
          setter(key, src[key]);
          break;
        default: {
          if (JSON.stringify(obj[key]) === JSON.stringify(src[key])) {
            return;
          }
          if (isArray(obj[key])) {
            if (
              ((obj[key] as any[]).length > 0 && (obj[key] as any[])[0]._id) ||
              ((src[key] as any[]).length > 0 && (src[key] as any[])[0]._id)
            ) {
              updateSnapshotArrayInPlace(
                obj[key] as Has_Id[],
                src[key] as Has_Id[]
              );
            } else {
              replaceContents(obj[key] as any[], src[key] as any[]);
            }
          } else if (isObject(obj[key])) {
            recursiveMerge(obj[key] as any, src[key] as any);
          } else {
            setter(key, src[key]);
          }
        }
      }
    }
  });
  return obj;
}

export function recursiveMergeWithTypeCast<
  T extends UnknownObject = UnknownObject
>(obj: T, src?: Partial<T> | null, schema: TypeCastSchema<T> = {}) {
  if (!src) return obj;
  const keys = uniq([...Object.keys(obj), ...Object.keys(src)]);
  keys.forEach((key: any) => {
    const setter = setValueOfKeyFactory(obj as any);
    if (obj[key] === src[key]) return;
    if (obj[key] === null || src[key] === null) setter(key, null);
    if (src[key] !== undefined) {
      const typeofKey = schema[key] || typeof obj[key];
      switch (typeofKey) {
        case "boolean":
          setter(key, src[key] === null ? src[key] : !!src[key]);
          break;
        case "string":
          setter(key, src[key] === null ? src[key] : `${src[key]}`);
          break;
        case "number":
          setter(
            key,
            src[key] === null ? src[key] : parseFloat(src && (src[key] as any))
          );
          break;
        default: {
          if (JSON.stringify(obj[key]) === JSON.stringify(src[key])) {
            return;
          }
          if (isArray(obj[key])) {
            if (
              ((obj[key] as any[]).length > 0 && (obj[key] as any[])[0]._id) ||
              ((src[key] as any[]).length > 0 && (src[key] as any[])[0]._id)
            ) {
              updateSnapshotArrayInPlace(
                obj[key] as Has_Id[],
                src[key] as Has_Id[]
              );
            } else {
              replaceContents(obj[key] as any[], src[key] as any[]);
            }
          } else if (isObject(obj[key])) {
            recursiveMergeWithTypeCast(obj[key] as any, src[key] as any);
          } else {
            setter(key, src[key]);
          }
        }
      }
    }
  });
  return obj;
}

export const updateSnapshotArrayInPlace = <T extends { _id: string }>(
  arr: T[],
  newSrc: T[]
) => {
  const newSrcCopy = [...newSrc];
  const toRemove: T[] = [];
  arr.forEach(p => {
    const indexInNewSrc = newSrcCopy.findIndex(n => n._id === p._id);
    if (indexInNewSrc >= 0) {
      const inNewSrc = newSrcCopy[indexInNewSrc];
      recursiveMergeWithTypeCast(p, inNewSrc);
      newSrcCopy.splice(indexInNewSrc, 1);
    } else {
      toRemove.push(p);
    }
  });
  removeFromArrayById(arr, toRemove);
  const toAdd = newSrcCopy.filter(p => !arr.find(n => n._id === p._id));
  arr.push(...toAdd);
  return arr;
};

export function convertObjectToArray(object: UnknownObject) {
  const arr = [];
  for (const key in object) {
    arr.push({ key: key, value: object[key] });
  }
  return arr;
}

export const mergeIntoObject = <T extends UnknownObject = UnknownObject>(
  obj: T,
  src: Partial<T> | null = {},
  options?: { mergeInPlace?: boolean }
) => {
  if (!obj)
    throw Error(
      "mergeIntoObject requires there to be an object to merge into!"
    );
  const { mergeInPlace = true } = options || {};
  const o = mergeInPlace ? obj : copyWithJSON(obj);
  for (const key in src) {
    try {
      const descriptor = Reflect.getOwnPropertyDescriptor(o, key);
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const isWritable = descriptor?.writable || descriptor?.set;
      const hasChanged = !equalByJsonSnapshot(o[key], src[key]);
      // @ts-ignore
      if (isWritable && hasChanged) o[key] = src[key];
    } catch (e) {
      reportError(e);
      console.error("An error occurred while merging objects.", e);
      console.error("Error key: ", key);
    }
  }
  return o;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const keys = <T extends UnknownObject = {}>(obj: T) => Object.keys(obj);

export const convertKeyValuePairsToObject = <T extends {}>(
  pairs: [string, unknown][]
) => pairs.reduce((obj, next) => ({ ...obj, [next[0]]: next[1] }), {}) as T;
