/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import * as React from "react";
import { RefObject } from "react";
import { CSSPartial } from "../@types/css.types";
import { ResizeQuery } from "../hooks/useResizeQuery";
import cx from "../utils/className.utils";
import { useDataAttributes } from "../utils/dataAttributes.utils";

interface ResizeQueryContainerProps {
  className?: string;
  query: ResizeQuery;
  containerRef: RefObject<HTMLDivElement>;
  shouldHideBeforeReady?: boolean;
  fillHeight?: boolean;
}

const ResizeQueryContainer: React.FC<
  React.PropsWithChildren<ResizeQueryContainerProps>
> = observer(p => {
  const styles: CSSPartial = {
    ...((p.shouldHideBeforeReady ?? true) &&
      !p.query?.ready && {
        visibility: "hidden",
      }),
    ...(p.fillHeight && {
      height: "100%",
    }),
  };
  const dataAttrs = useDataAttributes(p as unknown as AnyObject);

  return (
    <div
      className={cx(p.className, "ResizeQueryContainer")}
      css={styles}
      ref={p.containerRef}
      {...dataAttrs}
    >
      {p.children}
    </div>
  );
});

export default ResizeQueryContainer;
