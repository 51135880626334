/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useCompleteProgressBar } from "../../base/utils/globalProgressBar.utils";
import ForgotPasswordPage from "./ForgotPassword.page";
import LoginPage from "./Login.page";
import LogoutPage from "./Logout.page";
import SignUpPage from "./SignUp.page";
import ResetPasswordPage from "./ResetPassword.page";

interface ModuleAuthProps {}

const ModuleAuth: React.FC<ModuleAuthProps> = observer(props => {
  useCompleteProgressBar();
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="signup" element={<SignUpPage />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="logout" element={<LogoutPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
});

export default ModuleAuth;
