/* eslint-disable prefer-const */
import { MusicKey } from "../constants/musicKeys.constants";
import { MusicScaleName } from "../constants/musicScales.constants";
import {
  getIndexInMusicScale,
  getScaleStepDiffFromYDiff,
  getYDiffFromScaleStepDiff,
} from "./musicScale.utils";

export const transpose = (options: {
  y: number;
  patternAnchorY: number;
  // replicaYDiff: number;
  replicaScaleStepDiffFromSource: number;
  source: {
    key: MusicKey;
    scale: MusicScaleName;
  };
  target?: {
    key: MusicKey;
    scale: MusicScaleName;
  };
}) => {
  const {
    y,
    patternAnchorY,
    replicaScaleStepDiffFromSource,
    source,
    target = source,
  } = options;

  // find how many scaleSteps Y is from patternAnchorY (scaleStepDiffFromPatternAnchor)

  const anchorYIndexInScale = getIndexInMusicScale(
    patternAnchorY,
    source.key,
    source.scale
  );

  const scaleStepDiffFromPatternAnchor = getScaleStepDiffFromYDiff(
    y - patternAnchorY,
    anchorYIndexInScale,
    source.scale
  );

  // find patternAnchorY's corresponding Y in target key (replicaAnchorYInTargetKey)

  const replicaYDiffInScale = getYDiffFromScaleStepDiff(
    replicaScaleStepDiffFromSource,
    anchorYIndexInScale,
    target.scale
  );

  const replicaAnchorYInTargetKey = patternAnchorY + replicaYDiffInScale;

  const replicaAnchorYIndexInScale = getIndexInMusicScale(
    replicaAnchorYInTargetKey,
    target.key,
    target.scale
  );

  if (scaleStepDiffFromPatternAnchor === 0) return replicaAnchorYInTargetKey;

  // find the intervals represented by the scaleStepDiffFromPatternAnchor in the target key (intervalDiffFromReplicaAnchorYInTargetKey)

  const intervalDiffFromReplicaAnchorYInTargetKey = getYDiffFromScaleStepDiff(
    scaleStepDiffFromPatternAnchor,
    replicaAnchorYIndexInScale,
    target.scale
  );

  // console.log({
  //   key: `${uniq([source.key, target.key]).join(" -> ")}`,
  //   y,
  //   patternAnchorY,
  //   anchorYIndexInScale,
  //   scaleStepDiffFromPatternAnchor,
  //   replicaYDiffInScale,
  //   replicaAnchorYInTargetKey,
  //   replicaAnchorYIndexInScale,
  //   intervalDiffFromReplicaAnchorYInTargetKey,
  // });

  return replicaAnchorYInTargetKey + intervalDiffFromReplicaAnchorYInTargetKey;
};
