type Props = {
  strokeWidth?: number;
};

const IconImport18 = (props: Props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M11 4L14 4L14 14L11 14"
        stroke="currentColor"
        strokeWidth={props.strokeWidth ?? 2}
      />
      <path
        d="M2 9L9 9M9 9L5 13M9 9L5 5"
        stroke="currentColor"
        strokeWidth={props.strokeWidth ?? 2}
      />
    </svg>
  );
};

export default IconImport18;
