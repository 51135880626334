/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Artist } from "../../models/Artist.model";
import AppPage from "../../components/layout/AppPage";
import CompositionList from "../../components/directory/CompositionList";
import Button, { ButtonGroup } from "../../base/components/Button";
import PageSectionHeader from "../../base/components/PageSectionHeader";
import Spacing from "../../base/components/Spacing";
import { makeBooleanState } from "../../base/hooks/useBooleanState.hooks";
import ArtistAddContentPanel from "../../components/panels/ArtistAddItemPanel";
import ArtistEditorPanel from "../../components/panels/ArtistEditorPanel";
import LineSeparator from "../../base/components/LineSeparator";
import ArtistPageHeader from "../../components/shared/ArtistPageHeader";
import { ModelName } from "../../constants/modelName.constants";
import WithRequest from "../../base/components/ContainerWithRequest";

type WorkshopArtistSinglePageProps = {};

const WorkshopArtistSinglePage: React.FC<
  WorkshopArtistSinglePageProps
> = props => {
  const { ARTISTS, NAVIGATOR, LOCALDB } = useControllers();
  const params = useParams<{ id: string }>();
  const p = useProps({ ...props, params });
  const s = useStore(() => ({
    get id(): string {
      return p.params.id ?? "";
    },
    get artist() {
      return LOCALDB.get<Artist>(ModelName.artists, s.id);
    },
    get artistName(): string {
      return s.artist?.fullName ?? "Untitled Artist";
    },
    shouldShowArtistEditorPanel: makeBooleanState(false),
    shouldShowArtistAddContentPanel: makeBooleanState(false),
  }));

  return (
    <Observer
      children={() => (
        <AppPage
          className="LibraryArtistSinglePage"
          title={s.artist ? s.artistName : "Artist"}
        >
          <WithRequest
            id={["WorkshopArtistSingle", s.id]}
            request={async () =>
              await ARTISTS.get(s.id, {
                includes: ["compositions"],
              })
            }
            children={() => {
              return (
                s.artist && (
                  <div>
                    <ArtistPageHeader
                      artist={s.artist}
                      linkTo="workshop"
                      After={
                        <ButtonGroup>
                          {/* <Button
                        minWidth="9em"
                        icon="play"
                        iconVariant="filled"
                        disabled
                      >
                        Play
                      </Button> */}
                          <Button
                            minWidth="9em"
                            icon="pencil"
                            onClick={s.shouldShowArtistEditorPanel.toggle}
                          >
                            Edit details
                          </Button>
                          <Button
                            minWidth="9em"
                            icon="plus"
                            onClick={s.shouldShowArtistAddContentPanel.toggle}
                          >
                            Add content
                          </Button>
                        </ButtonGroup>
                      }
                    />
                    <Spacing size="2.5em" />
                    <LineSeparator />
                    <Spacing size="2.5em" />
                    <PageSectionHeader>
                      <h2>Compositions</h2>
                    </PageSectionHeader>
                    <CompositionList
                      compositions={s.artist.compositions}
                      EmptyMessage={
                        <p>
                          This {s.artist.title.toLocaleLowerCase()} has no
                          compositions yet.
                        </p>
                      }
                      mode="editor"
                      enableContextMenu
                    />
                    {s.shouldShowArtistAddContentPanel.value && (
                      <ArtistAddContentPanel
                        artist={s.artist}
                        onShouldClose={s.shouldShowArtistAddContentPanel.toggle}
                      />
                    )}
                    {s.shouldShowArtistEditorPanel.value && (
                      <ArtistEditorPanel
                        artist={s.artist}
                        onShouldClose={s.shouldShowArtistEditorPanel.toggle}
                      />
                    )}
                  </div>
                )
              );
            }}
          />
        </AppPage>
      )}
    />
  );
};

export default WorkshopArtistSinglePage;
