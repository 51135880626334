import { observable } from "mobx";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "../_root.controller";
import { convertRecordMapToArray } from "../../base/utils/map.utils";
import { ApiController } from "../api.controller";
import { LocalDBController } from "../localDB.controller";
import { ModelName } from "../../constants/modelName.constants";
import { RenderJob } from "../../models/RenderJob.model";

export const makeRenderJobsController = () => {
  const s = observable({
    ...makeControllerBase("RENDER_JOBS"),
    get API(): ApiController {
      return s.ROOT!.API;
    },
    get LOCALDB(): LocalDBController {
      return s.ROOT!.LOCALDB;
    },
    get all(): RenderJob[] {
      return convertRecordMapToArray(s.LOCALDB.data.renderJobs);
    },

    get: (id: string) =>
      s.API.get<RenderJob>("/render-jobs/" + id, ModelName.renderJobs),
  });

  s.init = makeRootControllerChildInitFn(s, () => {});

  return s;
};

export type RenderJobsController = ReturnType<typeof makeRenderJobsController>;
