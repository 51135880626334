/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import {
  VAR_InputBackground,
  VAR_InputBorder,
  VAR_InputFocusOutline,
  VAR_InputForeground,
  fg50,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore, useStyle } from "../../base/utils/mobx.utils";
import { Artist } from "../../models/Artist.model";
import { Composition } from "../../models/Composition.model";
import ArtistFinder from "../directory/ArtistFinder";
import { cVar } from "../../base/utils/customProperties.utils";
import { replaceContents } from "../../base/utils/array.utils";

export type CompositionArtistsSelectorProps = {
  composition: Composition;
  forType: "arrangers" | "artists";
  disabled?: boolean;
};

const CompositionArtistsSelector = (
  props: React.PropsWithChildren<CompositionArtistsSelectorProps>
) => {
  const p = useProps(props);
  const { ARTISTS, PORTALS } = useControllers();
  const s = useStore(() => ({
    get allArtists() {
      return ARTISTS.all;
    },
    get selected() {
      return p.composition[p.forType];
    },
    get textLabel(): string {
      return s.selected.map(a => a.fullName).join(", ");
    },
    get placeholder(): string {
      return "Please select...";
    },
    shouldRenderSelector: false,
    toggleSelector: () => {
      s.shouldRenderSelector = !s.shouldRenderSelector;
    },
    handleSelect: (artists: Artist[]) => {
      replaceContents(
        p.composition.$[p.forType === "artists" ? "artistIds" : "arrangerIds"],
        artists.map(a => a.$._id)
      );
    },
  }));
  const style = useStyle(() => ({
    get select(): CSSPartial {
      return {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        width: "100%",
        appearance: "none",
        paddingRight: "2em",
        fontFeatureSettings: "'tnum' 1",
        backgroundColor: cVar(VAR_InputBackground),
        color: cVar(VAR_InputForeground),
        border: cVar(VAR_InputBorder),
        minHeight: "2.5em",
        paddingLeft: ".5em",
        paddingTop: ".5em",
        paddingBottom: ".5em",
        lineHeight: "1.5em",
        borderRadius: 2,
        "&:hover": {
          filter: "brightness(1.1)",
        },
        "&:focus": {
          outline: cVar(VAR_InputFocusOutline),
          outlineColor: varPrimary,
        },
        "&[disabled]": {
          color: fg50,
        },
      };
    },
  }));
  return (
    <Observer
      children={() => (
        <div className="CompositionArtistSelector">
          <button
            type="button"
            css={style.select}
            onClick={s.toggleSelector}
            disabled={p.disabled}
          >
            {s.textLabel || s.placeholder}
          </button>
          {s.shouldRenderSelector &&
            PORTALS.render(
              <ArtistFinder
                onShouldClose={s.toggleSelector}
                onSelect={s.handleSelect}
                title="Select artists"
                initialSelection={p.composition[p.forType]}
              />
            )}
        </div>
      )}
    />
  );
};

export default CompositionArtistsSelector;
