/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import * as React from "react";
import { varFontMono } from "../../../constants/cssCustomProperties.constants";
import BrokenRecord from "../../../illustrations/BrokenRecord";
import { ColorPalette } from "../../../theming/colorPalette";
import { UNITS } from "../../constants/units.constant";
import cx from "../../utils/className.utils";
import { withOpacity } from "../../utils/colors.utils";
import { reportError } from "../../utils/errors.utils";
import ErrorRenderer from "../ErrorRenderer";
import Spacing from "../Spacing";

interface ErrorBoundaryProps {
  className?: string;
  fallback?: (error?: unknown) => string | React.ReactElement;
}
interface ErrorBoundaryState {
  error: unknown;
}

const ErrorBoundaryContainer = styled.div`
  user-select: text;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--bg, ${ColorPalette.shade});
  color: var(--fg, ${ColorPalette.light});
  overflow: auto;
  z-index: 1000000000;
`;

const Inner = styled.div`
  min-height: var(--vh, 100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  text-align: center;
  > div {
    max-width: 50rem;
    padding-bottom: 4em;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.6rem;
  }
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  padding: 0.75em 2em;
  border-radius: 0.2em;
  background-color: var(--fg10, ${withOpacity(ColorPalette.light, 0.1)});
  color: inherit;
  border: ${UNITS.lineWidth}px solid
    var(--fg10, ${withOpacity(ColorPalette.light, 0.1)});
  transform: translateZ(0);
  cursor: pointer;
  &:hover {
    background-color: var(--fg10, ${withOpacity(ColorPalette.light, 0.15)});
    border: ${UNITS.lineWidth}px solid
      var(--fg10, ${withOpacity(ColorPalette.light, 0.2)});
  }
`;

const ErrorContent = styled.div`
  font-family: ${varFontMono};
  padding: 1em;
  background-color: var(--fg05, ${withOpacity(ColorPalette.light, 0.05)});
  min-width: 27.5rem;
`;

function reload() {
  window.location.reload();
}

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  constructor(props: React.PropsWithChildren<ErrorBoundaryProps>) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidMount(): void {
    window.ProgressBar?.complete();
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    reportError(error as Error);
  }

  render() {
    if (!!this.state.error) {
      const { fallback: Fallback } = this.props;
      // You can render any custom fallback UI
      return (
        <ErrorBoundaryContainer
          className={cx("ErrorBoundary", this.props.className)}
        >
          <Inner>
            <div>
              {Fallback ? (
                typeof Fallback === "string" ? (
                  Fallback
                ) : (
                  Fallback(this.state.error)
                )
              ) : (
                <>
                  <BrokenRecord />
                  <Spacing size="1.6em" />
                  <h1>An error occurred.</h1>
                  <Spacing size="1em" />
                  <ErrorContent>
                    <ErrorRenderer error={this.state.error} />
                  </ErrorContent>
                  <Spacing size="1.6em" />
                  <Button onClick={reload}>Reload</Button>
                </>
              )}
            </div>
          </Inner>
        </ErrorBoundaryContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
