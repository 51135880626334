export type MIDIDeviceDescriptor = {
  _name: string;
  _manufacturer: string;
  displayName: string;
  displayManufacturer: string;
};
export const knownMidiInputDevices: MIDIDeviceDescriptor[] = [
  {
    _name: "nanoKEY Studio KEYBOARD/CTRL",
    _manufacturer: "KORG INC.",
    displayName: "KORG nanoKEY Studio Keyboard",
    displayManufacturer: "KORG",
  },
  {
    _name: "Digital Piano",
    _manufacturer: "Yamaha Corporation",
    displayName: "Digital Piano",
    displayManufacturer: "Yamaha",
  },
];

export const knownMidiOutputDevices: MIDIDeviceDescriptor[] = [
  {
    _name: "Kontakt Virtual Input",
    _manufacturer: "", // this is indeed empty
    displayName: "Kontakt Virtual Input",
    displayManufacturer: "Kontakt",
  },
];
