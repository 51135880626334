/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { useProps } from "../../base/utils/mobx.utils";
import AppPage from "../../components/layout/AppPage";

import { APP_DISPLAY_NAME } from "../../constants/app.constants";

type WorkshopAreaPageTemplateProps = {
  title?: string;
};

const WorkshopAreaPageTemplate: React.FC<
  PropsWithChildren<WorkshopAreaPageTemplateProps>
> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <AppPage
          className="WorkshopAreaPageTemplate"
          title={p.title ?? APP_DISPLAY_NAME}
        >
          {props.children}
        </AppPage>
      )}
    />
  );
};

export default WorkshopAreaPageTemplate;
