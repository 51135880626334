/** @jsxImportSource @emotion/react */
import { reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { TimeSignature } from "../../@types";
import { FormControlProps } from "../../base/@types";
import FormLabel from "../../base/components/FormLabel";
import TextInput from "../../base/components/TextInput";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { isNotNil } from "../../base/utils/ramdaEquivalents.utils";
import { makeTimeSignature44 } from "../../constants/timeSignatures.constants";

type TimeSignatureEditorProps<T extends UnknownObject = UnknownObject> =
  FormControlProps<T> & {
    Label?: ReactNode;
  };

const TimeSignatureEditor: React.FC<TimeSignatureEditorProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get value() {
      return (
        p.getter
          ? p.getter()
          : p.form && isNotNil(p.field)
          ? p.form[p.field]
          : makeTimeSignature44()
      ) as TimeSignature;
    },
    timeSignature: makeTimeSignature44(),
    handleChange: () => {
      const newTimeSignature = [s.timeSignature[0], s.timeSignature[1]];
      if (p.setter) p.setter(newTimeSignature);
      else if (p.form && isNotNil(p.field)) p.form[p.field] = newTimeSignature;
    },
  }));
  useOnMount(() => {
    return reaction(
      () => s.value,
      v => {
        s.timeSignature = v;
      },
      { fireImmediately: true }
    );
  });
  return (
    <Observer
      children={() => (
        <div className={cx("TimeSignatureEditor", p.className)}>
          {p.Label && (
            <FormLabel
              css={{
                display: "block",
                fontWeight: 700,
                marginBottom: ".5em",
              }}
            >
              {p.Label}
            </FormLabel>
          )}
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              gridGap: ".5em",
              alignItems: "baseline",
            }}
          >
            {p.form ? (
              <>
                <TextInput
                  form={s.timeSignature}
                  type="number"
                  field="0"
                  min={1}
                  onChange={s.handleChange}
                  taskName={p.taskName}
                  mergeableId={p.mergeableId}
                  mergeableTaskName={p.mergeableTaskName}
                  disabled={p.disabled}
                />
                <span>/</span>
                <TextInput
                  form={s.timeSignature}
                  type="number"
                  field="1"
                  min={1}
                  onChange={s.handleChange}
                  taskName={p.taskName}
                  mergeableId={p.mergeableId}
                  mergeableTaskName={p.mergeableTaskName}
                  disabled={p.disabled}
                />
              </>
            ) : null}
          </div>
        </div>
      )}
    />
  );
};

export default TimeSignatureEditor;
