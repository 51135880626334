import { makeIconDef } from "./makeIconDef";

export const PauseIconDef = {
  pause: makeIconDef("pause", {
    regular: () => (
      <>
        <rect x="6" y="6" width="5" height="13" fill="currentColor" />
        <rect x="14" y="6" width="5" height="13" fill="currentColor" />
      </>
    ),
  }),
  "pause-larger": makeIconDef("pause-larger", {
    regular: () => (
      <>
        <rect x="5" y="5" width="6" height="15" fill="currentColor" />
        <rect x="14" y="5" width="6" height="15" fill="currentColor" />
      </>
    ),
  }),
};
