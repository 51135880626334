/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import { cssVar } from "polished";
import React, { ReactNode } from "react";
import { useMaybeComposer } from "../../components/composer/ComposerApp.context";
import {
  VAR_InputBorder,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";
import { FormControlProps, HexOrContextColorName, Renderable } from "../@types";
import { CSSPartial } from "../@types/css.types";
import { useGetColorFromString } from "../hooks/theme.hooks";
import cx from "../utils/className.utils";
import { renderRenderable } from "../utils/components.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import { T, cond, isNotNil } from "../utils/ramdaEquivalents.utils";
import { getRandomNumericString } from "../utils/random.utils";
import ResetButton from "./ResetButton";
import styled from "@emotion/styled";
import { useControllers } from "../hooks/rootContext.hooks";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CheckboxStateGetter = (value: any) => boolean | number;

export type CheckboxProps<
  T extends AnyObject = AnyObject,
  V extends boolean | number = boolean | number
> = FormControlProps<T, V> & {
  name?: string;
  ActiveIcon?: Renderable;
  SemiActiveIcon?: Renderable;
  InactiveIcon?: Renderable;
  MultiActiveIcon?: Renderable;
  stateGetter?: CheckboxStateGetter;
  Label?: Renderable;
  color?: HexOrContextColorName | null;
  onChange?: (value: boolean | number) => void;
  resettable?: boolean;
  defaultValue?: boolean | null;
  fullWidth?: boolean;
};

const CheckboxContainer = styled.div`
  position: relative;
  &.fullWidth {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 1fr) auto;
  }
  &.resettable {
    color: ${varPrimary};
  }
  .ResetButton {
    transform: translateY(-5%);
    margin-left: 0.5em;
  }
`;

const Checkbox = <T extends AnyObject = AnyObject>(
  props: React.PropsWithChildren<CheckboxProps<T>>
) => {
  const { THEME } = useControllers();
  const p = useProps(props);
  const I = useMaybeComposer();
  const s = useStore(() => ({
    _id: getRandomNumericString(),
    get id(): string {
      return p.id ?? (p.name ? `checkbox-${p.name}` : s._id);
    },
    get value() {
      if (p.getter) return p.getter();
      if (p.form && isNotNil(p.field)) return p.form[p.field];
      return false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set value(v: any) {
      if (p.setter) p.setter(v);
      if (p.form && isNotNil(p.field)) p.form[p.field] = v;
    },
    get stateGetter(): CheckboxStateGetter {
      return p.stateGetter || Boolean;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get innerState(): any {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return s.stateGetter(s.value);
    },
    get defaultIcons() {
      return {
        active: (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            css={style.defaultIcon}
          >
            <path
              d="M2 5.5L5.5 9L11 3.5"
              stroke="currentColor"
              strokeWidth="1.5"
            />
          </svg>
        ),
        semiActive: (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            css={style.defaultIcon}
          >
            <line
              x1="2"
              y1="6"
              x2="11"
              y2="6"
              stroke="currentColor"
              strokeWidth="1.5"
            />
          </svg>
        ),
        inactive: (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            css={style.defaultIcon}
          ></svg>
        ),
        multiActive: (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            css={style.defaultIcon}
          >
            <path
              opacity="0.7"
              d="M6.5 10.5L11 6"
              stroke="currentColor"
              strokeWidth="1.5"
            />
            <path
              d="M2 6L4.5 8.5L10 3"
              stroke="currentColor"
              strokeWidth="1.5"
            />
          </svg>
        ),
      };
    },
    get activeIcon(): ReactNode {
      return p.ActiveIcon
        ? renderRenderable(p.ActiveIcon)
        : s.defaultIcons.active;
    },
    get semiActiveIcon(): ReactNode {
      return p.SemiActiveIcon
        ? renderRenderable(p.SemiActiveIcon)
        : s.defaultIcons.semiActive;
    },
    get inactiveIcon(): ReactNode {
      return p.InactiveIcon
        ? renderRenderable(p.InactiveIcon)
        : s.defaultIcons.inactive;
    },
    get multiActiveIcon(): ReactNode {
      return p.MultiActiveIcon
        ? renderRenderable(p.MultiActiveIcon)
        : s.defaultIcons.multiActive;
    },
    get icon(): ReactNode {
      return cond<ReactNode>([
        [
          () => s.innerState === 0 || s.innerState === false,
          () => s.inactiveIcon,
        ],
        [() => s.innerState === 1 || s.innerState === true, () => s.activeIcon],
        [() => s.innerState > 0 && s.innerState < 1, () => s.semiActiveIcon],
        [() => s.innerState > 1, () => s.multiActiveIcon],
        [T, () => s.inactiveIcon],
      ])();
    },
    handleInputValueChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.checked;
      const fn = () => {
        const prev = s.value;
        s.value = value;
        p.onChange && p.onChange(s.value, prev);
      };
      const taskName = p.mergeableTaskName ?? p.taskName;
      if (taskName && I)
        I.runInHistory(taskName, fn, {
          mergeableId: p.mergeableTaskName ?? p.mergeableId,
        });
      else fn();
    },
    get label() {
      return p.Label ?? p.name;
    },
  }));

  const color = useGetColorFromString(() => (p.disabled ? THEME.fg : p.color));

  const style = useStore(() => ({
    get component(): CSSPartial {
      return {};
    },
    get inner(): CSSPartial {
      return {
        display: "inline-grid",
        maxWidth: "100%",
        gridGap: ".5em",
        gridTemplateColumns: "auto minmax(auto, 1fr)",
        gridAutoFlow: "column",
        alignItems: "center",
      };
    },
    get input(): CSSPartial {
      return {
        opacity: 0,
        position: "absolute",
        "&:focus ~ label": {
          boxShadow: `0 0 2px 2px ${color.color}`,
        },
        "&[disabled] ~ label": {
          opacity: 0.5,
        },
      };
    },
    get icon(): CSSPartial {
      return {
        svg: {
          display: "block",
        },
      };
    },
    get label(): CSSPartial {
      return {
        fontWeight: 500,
        a: {
          textDecoration: "underline",
          "&:hover": {
            color: color.color,
          },
        },
      };
    },
    get defaultIcon(): CSSPartial {
      return {
        backgroundColor: !s.innerState ? "transparent" : color.color,
        color: color.contrastColor,
        border: cssVar(VAR_InputBorder),
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <CheckboxContainer
          className={cx(
            "Checkbox",
            p.className,
            p.resettable && "resettable",
            p.fullWidth && "fullWidth"
          )}
          data-value={s.value}
        >
          <input
            css={style.input}
            type="checkbox"
            checked={s.value}
            onChange={s.handleInputValueChange}
            id={s.id}
            disabled={p.disabled}
          />
          <label htmlFor={s.id} css={style.inner}>
            <div css={style.icon}>{renderRenderable(s.icon as any)}</div>
            <div css={style.label}>
              {renderRenderable(s.label)}
              {props.children}
            </div>
          </label>
          {p.resettable && p.form && p.field && (
            <span>
              {" "}
              <ResetButton
                form={p.form}
                field={p.field}
                default={p.defaultValue ?? null}
              >
                Reset
              </ResetButton>
            </span>
          )}
        </CheckboxContainer>
      )}
    />
  );
};

export const CheckboxGroup = (props: React.PropsWithChildren<{}>) => {
  return (
    <div
      css={{
        "> * + *": {
          marginTop: "1em",
        },
      }}
    >
      {props.children}
    </div>
  );
};

export default Checkbox;
