import { isObservable, observable, toJS } from "mobx";
import {
  Atom,
  AtomBaseExtendedMembers,
  AtomBaseSnapshot,
  AtomContext,
  AtomExtendedPropertiesFactory,
  AtomPath,
  AtomType,
  Bar,
  GroupLikeAtom,
  Pattern,
  Replica,
  Section,
  SnapshotOfAtom,
  TypeOfAtom,
  Voice,
} from "../@types";
import { clearArray } from "../base/utils/array.utils";
import { createObservable } from "../base/utils/mobx.utils";
import {
  TypeCastSchema,
  mergeIntoObjectByDescriptors,
  recursiveMerge,
  recursiveMergeWithTypeCast,
  setValueOfKey,
} from "../base/utils/object.utils";
import { first, last, uniq } from "../base/utils/ramdaEquivalents.utils";
import { LocalDBController } from "../controllers/localDB.controller";
import { AppearanceSnapshot } from "../traits/hasAppearance.trait";
import { has_id } from "../traits/hasId.trait";
import { hasOptionalXYZ } from "../traits/hasXYZ";
import {
  ScaleTransformParams,
  TransformController,
  TransformStepType,
  sortAtomTransforms,
} from "../transformers/transform.controller";
import {
  findNearestInAtomPath,
  getAtomSnapshot,
  isBarAtom,
  isGroupLikeAtom,
  isNoteAtom,
  isPatternAtom,
  isReplicaAtom,
  isSectionAtom,
  setupAtomReactions,
  updateAtomId,
} from "../utils/atoms.utils";
import { setupAccessorsToObservableSnapshot } from "../base/utils/snapshot.utils";
import {
  AtomAppearanceController,
  makeAtomAppearanceController,
} from "./makeAtomAppearanceController.factory";
import {
  getAncestorsOfAtom,
  getAtomBoundingBox,
  getAtomEndX,
  getAtomEndY,
  getAtomPath,
  getAtomStartX,
  getAtomStartY,
  getAtomVoice,
  getAtomX2,
  getAtomXRange,
  getAtomY2,
  setAtomParents,
} from "./atomFactoryMethods";
import { add, subtract } from "../base/utils/math.utils";
import { Interpreter } from "./interpreter.controller";
import { MusicKey } from "../constants/musicKeys.constants";
import { MusicScaleName } from "../constants/musicScales.constants";
import { getValueAtXFromMap } from "../utils/valueAtXMap.utils";
import {
  getAtomIndexInMusicScale,
  getIndexInOctaveFromRoot,
} from "../utils/musicScale.utils";
import {
  getClosestTonicMidiNumber,
  getMidiNumberFromY,
} from "../utils/musicKey.utils";
import { makeTimeSignature44 } from "../constants/timeSignatures.constants";
import { getBpxFromTimeSignature } from "../utils/beats.utils";
import { getNoteSetClosestTonicMidiNumber } from "../utils/note.utils";
import { SELECTION_GROUP_ID } from "../constants/selection.constants";
import { Interpretation } from "../models/Interpretation.model";

const debugIdList = [] as string[];

/**
 * createAtomFactory
 */
export const createAtomFactory = <T extends Atom<AtomType>>(o: {
  type: TypeOfAtom<T>;
  snapshotFactory: () => SnapshotOfAtom<T>;
  snapshotTypeCastSchema?: TypeCastSchema<SnapshotOfAtom<T>>;
  accessorOverridesFactory?: (
    $: SnapshotOfAtom<T>,
    m: T,
    context?: AtomContext,
    localDB?: LocalDBController
  ) => Partial<SnapshotOfAtom<T>>;
  extendedPropertiesFactories?: AtomExtendedPropertiesFactory<T>[];
  init?: (m: T, context?: AtomContext, localDB?: LocalDBController) => Disposer;
  options?: { debug?: boolean };
}) => {
  return (
    source?: Partial<SnapshotOfAtom<T>> | T,
    context?: AtomContext,
    localDB?: LocalDBController
  ) => {
    const { debug } = o.options || {};

    if (debug) {
      console.info(
        `🏭 createAtomFactory: ${o.type}#${source?._id}`,
        toJS(source),
        context
      );
      debugger;
    }

    const mutableBase = o.snapshotFactory();

    const snapshot = source ? getAtomSnapshot<AtomBaseSnapshot>(source) : {};

    if (debug) {
      console.info(source, snapshot);
      debugger;
    }

    const { _id } = snapshot;

    if (debug) {
      if (debugIdList.indexOf(_id as string) >= 0) {
        console.info(
          `⚠️ Atom[${o.type}]#${_id} total init count: ${
            debugIdList.filter(i => i === _id).length
          }`,
          "source supplied:",
          source
        );
      }
      debugIdList.push(_id as string);
    }

    // *** LINKED MODELS: linking to actual models such as users or media files, to be designed *** ///
    // const linkedModelSchema = o.linkedModelSchemaFactory ? o.linkedModelSchemaFactory(snapshot as Partial<SnapshotOf<Model>>) : o.linkedModelSchema;

    const snapshotWithDefaultValues = recursiveMergeWithTypeCast(
      mutableBase,
      snapshot,
      o.snapshotTypeCastSchema
    );
    // create private observable snapshot as the 'state' of the model
    // this makes it easy to patch the snapshot when it is updated
    // if the input snapshot is observable, we presume it came from within a atomContext.
    // otherwise, it's a static object – a "loner" atom that does not belong to a atomContext.
    if (!isObservable(snapshot)) {
      console.info(
        `%c🤨Snapshot used to create ${o.type} atom #${snapshot._id} was not observable. Make sure this is intentional; if it was, it might be worth refactoring. Change this message to console.warn to see the call stack.`,
        "color: orange"
      );
    }
    const $ = isObservable(snapshot)
      ? Object.assign(snapshot, snapshotWithDefaultValues)
      : observable(snapshotWithDefaultValues);

    const internal = observable({
      appearance: null as AtomAppearanceController | null,
    });

    const A = {
      $,
      get type() {
        return A.$.type;
      },
      context,
      get interpreter(): Interpreter | null {
        return A.context?.interpreter ?? null;
      },
      get interpretation(): Interpretation | null {
        return A._itpId
          ? A.context?.composer?.initiatedInterpreters.get(A._itpId)
              ?.interpretation ?? null
          : null;
      },
      setParents: (
        parents: GroupLikeAtom[] = [],
        mode: ArrayOperationMode = "add"
      ) => setAtomParents(A, $, parents, mode),
      addParents: (...newParents: GroupLikeAtom[]) =>
        A.setParents(newParents, "add"),
      subtractParents: (...parentsToSubtract: GroupLikeAtom[]) =>
        A.setParents(parentsToSubtract, "subtract"),
      clearParents: () => clearArray(A.parentIds),
      get isAtom() {
        return true;
      },
      select: () => {
        const KEYBOARD = A.context?.ROOT?.KEYBOARD;
        const selectTool = A.context?.composer?.tools.select;
        if (selectTool) {
          selectTool.updateSelection({
            debugCaller: "atom.select",
            name: "Select atom",
            atoms: [A],
            updateCursorPosition: true,
            mode: KEYBOARD?.pressed.shift
              ? A._isSelected
                ? "subtract"
                : "add"
              : "replace",
          });
        }
      },
      get path(): AtomPath {
        return getAtomPath(A);
      },
      transforms: [] as TransformController[],
      get allTransforms() {
        return sortAtomTransforms(
          uniq([A.transforms, ...A.parents.map(p => p.allTransforms)].flat())
        );
      },
      get allTransformSteps() {
        return uniq(A.allTransforms.map(t => t.steps).flat());
      },
      get hasActiveTransforms() {
        return A.allTransforms.some(t => t.isActive);
      },
      get isXInverted() {
        return A.allTransformSteps.find(
          sig =>
            sig.type === TransformStepType.scale &&
            (sig.params as ScaleTransformParams).x < 0
        );
      },
      get isYInverted() {
        return A.allTransformSteps.find(
          sig =>
            sig.type === TransformStepType.scale &&
            (sig.params as ScaleTransformParams).y < 0
        );
      },
      rules: [],
      get ruleParents() {
        return uniq(
          [
            ...(A.voice?.voicePath ?? []),
            ...(!isSectionAtom(A) ? [A.section] : []),
            ...(!isBarAtom(A) && !isSectionAtom(A) ? A.bars : []),
            ...A.parents,
          ].filter(i => i)
        ) as (GroupLikeAtom | Bar | Section)[];
      },
      get ownRules() {
        return A.rules.filter(r => r.selector === A._id);
      },
      get lastOwnRule() {
        return last(A.ownRules) ?? null;
      },
      get rulesRecursive() {
        return uniq(
          A.ruleParents
            .map(p => p.rulesRecursive)
            .concat(A.rules)
            .flat()
        );
      },
      get rulePropertiesFlattened() {
        return A.rulesRecursive.reduce((flattened, r) => {
          return recursiveMerge(flattened, toJS(r.$.properties ?? {}));
        }, {});
      },
      setAppearance: (appearanceSnapshot: AppearanceSnapshot) => {
        if (!internal.appearance) return;
        Object.entries(appearanceSnapshot).forEach(e => {
          setValueOfKey(
            internal.appearance!,
            e[0] as keyof AtomAppearanceController,
            e[1]
          );
        });
      },
      _updateId: () => {
        return updateAtomId(A) ?? A;
      },
    } as unknown as T;

    // apply standard getters and setters of all attributes in the snapshot directly on the model
    setupAccessorsToObservableSnapshot(A, $, mutableBase, {
      ...(o.accessorOverridesFactory
        ? o.accessorOverridesFactory(
            $ as SnapshotOfAtom<T>,
            A,
            context,
            localDB
          )
        : undefined),
      get z() {
        return (A.$.z || A.voice?.z) ?? 0;
      },
      set z(v) {
        A.$.z = v;
      },
      get voiceId(): string | null {
        if (A.type === "voice") return null;
        return A.voice?._id ?? null;
      },
      set voiceId(v) {
        // console.log(`setting ${atom.type}#${atom._id} voiceId to ${v}`);
        if (A.type === "voice") return;
        $.voiceId = v ?? null;
      },
      get parentIds() {
        return $.parentIds;
      },
      set parentIds(v: string[]) {
        console.warn(
          "It is not allowed to set parentIds directly. The only way to assign a parent is to use the parent setter with a atom."
        );
      },
      get appearance() {
        return internal.appearance;
      },
    });

    // apply extended properties (e.g. getters, methods, special attributes)
    mergeIntoObjectByDescriptors(A, makeAtomBaseExtendedMembers(A, $), {
      configurable: true,
    });
    if (o.extendedPropertiesFactories) {
      o.extendedPropertiesFactories.forEach(factory => {
        mergeIntoObjectByDescriptors(
          A,
          factory(A, $ as SnapshotOfAtom<T>, localDB),
          {
            configurable: true,
          }
        );
      });
    }

    // make the model observable
    createObservable(A, undefined, {
      name: `atom_${source?._id}`,
      inPlace: true,
    });

    internal.appearance = makeAtomAppearanceController(A);

    const disposer = setupAtomReactions({ init: o.init, A, context, localDB });

    A.dispose = disposer;

    if (debug) {
      console.info(A);
    }

    return A;
  };
};

export const makeAtomBaseSnapshot = (type?: AtomType) => ({
  ...has_id(),
  name: "" as string | null,
  type: type as AtomType,
  parentIds: [] as string[],
  voiceId: null as string | null,
  refAtomId: null as string | null,
  width: null as number | null,
  height: null as number | null,
  ...hasOptionalXYZ(),
  _isHidden: false,
  _isLocked: false,
  /**
   * managed by atomContext
   */
  _itpId: "",
});

export const makeAtomBaseExtendedMembers = (
  A: Atom,
  observableModel: Partial<AtomBaseSnapshot>
): AtomBaseExtendedMembers => ({
  _isSelected: false,
  _isDeleted: false,
  get _isLocked() {
    return (
      A.$._isLocked ||
      A.voice?._isLocked ||
      (A as Voice).ancestorVoices?.some(v => v._isLocked) ||
      A.parents.some(p => p.$._isLocked)
    );
  },
  set _isLocked(v) {
    A.$._isLocked = v;
  },
  get _isHidden() {
    return !!(
      A.$._isHidden ||
      A.voice?._isHidden ||
      (A as Voice).ancestorVoices?.some(v => v._isHidden) ||
      A.parents.some(p => p.$._isHidden)
    );
  },
  set _isHidden(v) {
    A.$._isHidden = v;
  },
  markAsDeleted: () => {
    A._isDeleted = true;
    A.dispose();
  },
  get displayName(): string {
    return A.name?.trim() || `Atom ${A._id}`;
  },
  set displayName(v) {
    A.name = `${v}`;
  },
  get x2(): number | null {
    return getAtomX2(A);
  },
  get y2(): number | null {
    return getAtomY2(A);
  },
  get startX(): number | null {
    return getAtomStartX(A);
  },
  set startX(v) {
    if (v === null) A.x = null;
    else {
      if (A.x2 !== null && A.x !== null) {
        if (A.x2 >= A.x) A.x = v;
        else A.x = v - (A.width ?? 0);
      } else {
        A.x = v;
      }
    }
  },
  get startY(): number | null {
    return getAtomStartY(A);
  },
  get centerY(): number | null {
    if (A.startY === null || A.endY === null) return null;
    return (A.startY + A.endY) / 2;
  },
  set centerY(v) {
    A.y = v === null ? null : v + (A.height || 0) / 2;
  },
  get endX(): number | null {
    return getAtomEndX(A);
  },
  set endX(n) {
    if (A.x === null) return;
    if (n === null) A.x = null;
    else A.width = n - A.x;
  },
  get endY(): number | null {
    return getAtomEndY(A);
  },
  get timeSignature() {
    return (
      A.section?.timeSignature ??
      (A.context?.timeSignature
        ? [...A.context?.timeSignature]
        : makeTimeSignature44())
    );
  },
  get bpm() {
    if (!A.x || !A.context) return 60;
    return getValueAtXFromMap(A.x, A.context.valueAtXMaps.bpm);
  },
  get bpx() {
    return getBpxFromTimeSignature(A.timeSignature);
  },
  get xpm() {
    return A.bpm / A.bpx;
  },
  get beatCount() {
    return (A.width ?? 0) * A.bpx;
  },
  set beatCount(d: number) {
    A.width = Math.abs(d) / A.bpx;
  },
  get boundingBox() {
    return getAtomBoundingBox(A);
  },
  get hitBox() {
    return A.boundingBox;
  },
  get section() {
    if (A.x === null) return null;
    return (
      A.context?.sections.find(
        s => s.startX <= A.startX! && s.endX > A.startX!
      ) ?? null
    );
  },
  get parents(): GroupLikeAtom[] {
    if (A._id === SELECTION_GROUP_ID) return [];
    return A.context?.getAtomsByIds(A.$.parentIds) ?? [];
  },
  set parents(newParents: GroupLikeAtom[]) {
    setAtomParents(A, observableModel, newParents);
  },
  get patternParents() {
    return A.parents.filter(isPatternAtom);
  },
  get patternAncestors() {
    return A.ancestors.filter(isPatternAtom);
  },
  get primaryPatternAncestor() {
    return A.patternAncestors.length > 0
      ? (first(A.patternAncestors) as Pattern)
      : null;
  },
  get replica() {
    return findNearestInAtomPath<Replica>(A.path, isReplicaAtom);
  },
  get voice() {
    return getAtomVoice(A);
  },
  set voice(v) {
    A.voiceId = v?._id ?? null;
  },
  get xRange() {
    return getAtomXRange(A);
  },
  get bars() {
    if (A.x === null) return [];
    const bars: Bar[] = [];
    let nextBar = A.context?.bars.find(b => b.x > A.x!) ?? null;
    const lastBarInAc = last(A.context?.bars);
    const firstBar = nextBar
      ? nextBar.prevBar
      : lastBarInAc && lastBarInAc.x < A.x
      ? lastBarInAc
      : null;
    if (!firstBar || A.endX === null) return bars;
    bars.push(firstBar);
    while (nextBar && A.endX > nextBar.endX) {
      bars.push(nextBar);
      nextBar = nextBar?.nextBar ?? null;
    }
    return bars;
  },
  get startsInBar() {
    return first(A.bars) ?? null;
  },
  get endsInBar() {
    return last(A.bars) ?? null;
  },
  get ancestors() {
    return getAncestorsOfAtom(A);
  },
  get musicKey() {
    return A.context
      ? getValueAtXFromMap(A.x ?? 0, A.context.valueAtXMaps.musicKey) ||
          MusicKey.C
      : MusicKey.C;
  },
  get tonic() {
    return A.musicKey;
  },
  get closestTonicMidiNumber() {
    if (isGroupLikeAtom(A))
      return getNoteSetClosestTonicMidiNumber(A.descendantNotes, A.tonic);
    return getClosestTonicMidiNumber(
      getMidiNumberFromY(A.centerY) ?? 60,
      A.tonic
    );
  },
  get musicScaleName() {
    return A.context
      ? getValueAtXFromMap(A.x ?? 0, A.context.valueAtXMaps.musicScaleName)
      : MusicScaleName.Ionian;
  },
  get indexInOctaveFromRoot() {
    if (!isNoteAtom(A) || A.indexInOctaveFromC === null || A.y === null)
      return null;
    return getIndexInOctaveFromRoot(A.y, A.musicKey);
  },
  get indexInMusicScale() {
    return getAtomIndexInMusicScale(A);
  },
  get refAtom() {
    const atom = A.context?.getAtomById(A.$.refAtomId) ?? null;
    if (atom?._isDeleted || !atom?.patternAncestors.length) return null;
    return atom;
  },
  referrers: [],
  get anchor() {
    return A.replica?.anchor ?? { x: 0, y: 0 };
  },
  get xyRelToAnchor() {
    if (A.primaryPatternAncestor) {
      return {
        x: subtract(A.x, A.primaryPatternAncestor.anchor.x),
        y: subtract(A.y, A.primaryPatternAncestor.anchor.y),
      };
    }
    if (!A.refAtom || !A.replica) return null;
    return {
      x: subtract(
        A.$.x ?? add(A.refAtom.x, A.replica.anchorDiffFromSource.x),
        A.anchor.x
      ),
      y: subtract(
        A.$.y ?? add(A.refAtom.y, A.replica.anchorDiffFromSource.y),
        A.anchor.y
      ),
    };
  },
});
