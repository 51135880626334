import { action, reaction } from "mobx";
import {
  BreakpointName,
  breakpoint,
} from "../styles/helpers/breakpoints.styleHelper";
import { debounce } from "../utils/debounce.utils";
import { makeDisposerController } from "../utils/disposer.utils";
import { useStore } from "../utils/mobx.utils";
import { useOnMount } from "./lifecycle.hooks";
import { useControllers } from "./rootContext.hooks";
import { useResizeObserver } from "./useResizeObserver";

export const useResizeQuery = (
  ref: React.MutableRefObject<HTMLElement | SVGElement | null>,
  options?: {
    defaultWidth?: number;
    delay?: number;
  }
) => {
  const { UI } = useControllers();

  const s = useStore(() => ({
    entry: null as ResizeObserverEntry | null,
    boundingBox: null as DOMRect | null,

    get ready(): boolean {
      return !!s.entry;
    },

    get width(): number {
      return (
        s.entry?.target?.clientWidth ||
        ref.current?.clientWidth ||
        options?.defaultWidth ||
        0
      );
    },
    get height(): number {
      return s.entry?.target?.clientHeight ?? ref.current?.clientHeight ?? 0;
    },

    get contentBottom(): number {
      return s.entry?.contentRect.bottom ?? 0;
    },
    get contentHeight(): number {
      return s.entry?.contentRect.height ?? 0;
    },
    get contentLeft(): number {
      return s.entry?.contentRect.left ?? 0;
    },
    get contentRight(): number {
      return s.entry?.contentRect.right ?? 0;
    },
    get contentTop(): number {
      return s.entry?.contentRect.top ?? 0;
    },
    get contentWidth(): number {
      return s.entry?.contentRect.width ?? 0;
    },

    get scrollWidth(): number {
      return s.entry?.target?.scrollWidth ?? s.width;
    },
    get scrollHeight(): number {
      return s.entry?.target?.scrollHeight ?? s.height;
    },

    get x(): number {
      return s.entry?.contentRect.x ?? 0;
    },
    get y(): number {
      return s.entry?.contentRect.y ?? 0;
    },

    get top(): number {
      return s.boundingBox?.top ?? 0;
    },
    get rightFromViewportLeft(): number | null {
      return s.boundingBox?.right ?? null;
    },
    get rightFromViewportRight(): number | null {
      if (s.rightFromViewportLeft === null) return null;
      return UI.appWidth - s.rightFromViewportLeft;
    },
    get bottomFromViewportTop(): number | null {
      return s.boundingBox?.bottom ?? null;
    },
    get bottomFromViewportBottom(): number | null {
      if (s.bottomFromViewportTop === null) return null;
      return UI.appHeight - s.bottomFromViewportTop;
    },
    get left(): number | null {
      return s.boundingBox?.left ?? s.contentLeft;
    },

    fromBreakpoint(n: BreakpointName) {
      return s.width >= breakpoint(n);
    },
    get onlyPhones(): boolean {
      return s.width < breakpoint("tablet");
    },
    get fromPhoneLg(): boolean {
      return s.width >= breakpoint("phone-lg");
    },
    get onlyTablets(): boolean {
      return s.width >= breakpoint("tablet") && s.width < breakpoint("desktop");
    },
    get fromTablet(): boolean {
      return s.width >= breakpoint("tablet");
    },
    get fromTabletLg(): boolean {
      return s.width >= breakpoint("tablet-lg");
    },
    get fromDesktop(): boolean {
      return s.width >= breakpoint("desktop");
    },
    get fromDesktopLg(): boolean {
      return s.width >= breakpoint("desktop-lg");
    },

    callbacks: [] as VoidFunction[],
    onResize: (fn: VoidFunction) => {
      s.callbacks.push(fn);
    },
    removeHandler: (fn: VoidFunction) => {
      s.callbacks.splice(s.callbacks.indexOf(fn), 1);
    },
    updateEntries: (entries: ResizeObserverEntry[]) => {
      setTimeout(
        action(() => {
          s.entry = entries[0];
          s.updateBoundingBox();
        })
      );
    },
    updateBoundingBox: () => {
      if (s.entry) s.boundingBox = s.entry.target.getBoundingClientRect();
    },
    recalc: () => {
      s.updateBoundingBox();
    },
  }));

  useResizeObserver(
    options?.delay
      ? debounce(s.updateEntries, { duration: options.delay })
      : s.updateEntries,
    ref
  );

  useOnMount(() => {
    const d = makeDisposerController();
    const handler = s.updateBoundingBox;
    window.addEventListener("resize", handler);
    d.add(
      reaction(
        () => `${s.width},${s.height}`,
        () => {
          s.callbacks.forEach(c => c());
        }
      )
    );
    d.add(
      action(() => {
        s.entry = null;
        window.removeEventListener("resize", handler);
      })
    );
    return d.dispose;
  });

  return s;
};

export type ResizeQuery = ReturnType<typeof useResizeQuery>;
