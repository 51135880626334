import { makeIconDef } from "./makeIconDef";

export const ShareIconDef = {
  share: makeIconDef("share", {
    regular: () => (
      <>
        <path d="M13 14.5V4H12V14.5H13Z" fill="currentColor" />
        <path
          d="M9 12H6.5C5.94772 12 5.5 12.4477 5.5 13V19C5.5 19.5523 5.94772 20 6.5 20H18.5C19.0523 20 19.5 19.5523 19.5 19V13C19.5 12.4477 19.0523 12 18.5 12H16"
          stroke="currentColor"
        />
        <path d="M8.5 8L12.5 4L16.5 8" stroke="currentColor" />
      </>
    ),
  }),
};
