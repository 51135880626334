/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import { ContextMenuItemConfig } from "../../base/components/ContextMenu/ContextMenu";
import { Composition } from "../../models/Composition.model";
import { ComposerAppUIMode } from "../composer/useCreateComposerInstance";
import CompositionEntry from "./CompositionEntry";
import DraggableList, {
  DraggableListDragEndHandler,
} from "../../base/components/DraggableList";

interface P {
  compositions: Composition[];
  EmptyMessage?: React.ReactNode;
  mode?: ComposerAppUIMode;
  enableContextMenu?: boolean;
  contextMenuConfigSetFactory?: (c: Composition) => ContextMenuItemConfig[];
  onDragEnd: DraggableListDragEndHandler;
}

const style = {
  gallery: {} as CSSPartial,
  list: {
    "> * + *": {
      marginTop: "3em",
    },
  } as CSSPartial,
};

const CompositionListDraggable: React.FC<P> = p => {
  return (
    <Observer
      children={() => (
        <div css={style.gallery}>
          {p.compositions.length === 0 ? (
            p.EmptyMessage ?? <p>No compositions found.</p>
          ) : (
            <DraggableList
              css={style.list}
              items={p.compositions}
              itemDragOverBoundingBoxOffset="-14px"
              onDragEnd={p.onDragEnd}
              renderItem={(comp, i) => (
                <CompositionEntry
                  key={i}
                  composition={comp}
                  mode={p.mode ?? "player"}
                  showPrimaryCollection
                  enableContextMenu={
                    !!(p.contextMenuConfigSetFactory || p.enableContextMenu)
                  }
                  contextMenuConfigSetFactory={p.contextMenuConfigSetFactory}
                />
              )}
            />
          )}
        </div>
      )}
    />
  );
};

export default CompositionListDraggable;
