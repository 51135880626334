/** @jsxImportSource @emotion/react */
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import {
  RequestId,
  UseRequestOptions,
  useRequest,
} from "../../utils/request.utils";
import ErrorScreen from "./ErrorScreen";
import LoadingIndicatorBlock from "./LoadingIndicatorBlock";

type ContainerWithRequestProps<T> = {
  id: RequestId;
  request: () => Promise<T>;
  options?: UseRequestOptions<T>;
  fallback?: ReactNode;
  children: (data: T) => EmotionJSX.Element | null;
};

const WithRequest = function <T>(props: ContainerWithRequestProps<T>) {
  const R = useRequest<T>(props.id, props.request, props.options);
  return (
    <Observer
      children={() => (
        <div>
          {R.error ? (
            <ErrorScreen error={R.error} addBackdrop />
          ) : R.loading ? (
            <LoadingIndicatorBlock />
          ) : R.loaded ? (
            <Observer children={() => props.children(R.data as T)} />
          ) : (
            props.fallback
          )}
        </div>
      )}
    />
  );
};

export default WithRequest;
