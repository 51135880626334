import { makeIconDef } from "./makeIconDef";

export const TriangleIconDef = {
  triangle: makeIconDef("triangle", {
    regular: () => (
      <path
        d="M4.27276 19.25L12.5 5L20.7272 19.25H4.27276Z"
        stroke="currentColor"
      />
    ),
  }),
};
