/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import {
  VAR_InputBorder,
  fg02,
  fg10,
} from "../../constants/cssCustomProperties.constants";
import { useProps } from "../utils/mobx.utils";
import styled from "@emotion/styled";
import cx from "../utils/className.utils";
import { cVar } from "../utils/customProperties.utils";

type InfoBoxProps = {
  className?: string;
  outlined?: boolean;
};

const InfoBoxContainer = styled.div`
  background-color: ${fg10};
  padding: 1em;
  border-radius: 3px;
  > * + * {
    margin-top: 0.5em;
  }
  &.outlined {
    background-color: ${fg02};
    border: ${cVar(VAR_InputBorder)};
  }
`;

const InfoBox: React.FC<PropsWithChildren<InfoBoxProps>> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <InfoBoxContainer
          className={cx(p.className, props.outlined && "outlined")}
        >
          {props.children}
        </InfoBoxContainer>
      )}
    />
  );
};

export default InfoBox;
