/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { action, flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { InstrumentDef, InstrumentSnapshot } from "../../@types";
import Button from "../../base/components/Button";
import Panel, {
  PanelCloseButton,
  PanelFooter,
  PanelHeader,
  PanelInner,
} from "../../base/components/Panel";
import SelectableItem from "../../base/components/SelectableItem";
import Serif from "../../base/components/Serif";
import Spacing from "../../base/components/Spacing";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { fromTabletMd } from "../../base/styles/helpers/mediaQueries.styling";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { getRandomNumericString } from "../../base/utils/random.utils";
import { autoPluralize } from "../../base/utils/string.utils";
import { InstrumentDefIndex } from "../../controllers/ensemble/instrument.index";
import { BlanchetHarpsichordName } from "../../instruments/BlanchetHarpsichord/BlanchetHarpsichord.instrument";
import { Interpretation } from "../../models/Interpretation.model";
import { useComposer } from "../composer/ComposerApp.context";
import InstrumentMetaEntry from "../shared/InstrumentMetaEntry";

type InterpretationAddInstrumentPanelProps = {
  interpretation: Interpretation;
  onShouldClose?: () => void;
};

const H3 = styled.h3`
  margin-bottom: 0.5em;
`;

const InterpretationAddInstrumentPanel: React.FC<
  InterpretationAddInstrumentPanelProps
> = props => {
  const p = useProps(props);
  const { AUTH, INTERPRETATIONS, UI } = useControllers();
  const I = useComposer();
  const s = useStore(() => ({
    get width() {
      return Math.min(UI.appWidth * 0.9, 600);
    },
    selection: new Set<InstrumentDef>(),
    addInstrumentsToInterpretation: async () =>
      await flow(function* () {
        I.runInHistory(
          `Add ${autoPluralize(s.selection.size, "instrument")}`,
          async () => {
            await I.interpreter?.addNewInstrumentsToInterpretation(
              Array.from(s.selection).map(
                def =>
                  ({
                    _id: getRandomNumericString(),
                    name: def.meta.name,
                  } as InstrumentSnapshot)
              )
            );
          }
        );
        yield INTERPRETATIONS.save(p.interpretation);
        p.onShouldClose?.();
      })(),
    selectHandlerFactory: (def: InstrumentDef) =>
      action(() => {
        s.selection.has(def) ? s.selection.delete(def) : s.selection.add(def);
      }),
    get availableInstruments() {
      const all = Array.from(InstrumentDefIndex.values());
      if (AUTH.user?.$.username === "yile") return all;
      return all.filter(
        def => def.meta.name !== BlanchetHarpsichordName && !def.meta.hidden
      );
    },
  }));

  return (
    <Observer
      children={() => (
        <Panel
          id="add-instrument-to-interpretation"
          title="Add instruments"
          fullscreen
          canEscape
          onShouldClose={p.onShouldClose}
          width={s.width}
        >
          <PanelHeader End={<PanelCloseButton onClick={p.onShouldClose} />}>
            <h2>
              <Serif>Add instruments</Serif>
            </h2>
          </PanelHeader>
          <PanelInner>
            <H3>Add built-in instruments</H3>
            <p>You can select multiple instruments at the same time.</p>
            <Spacing />
            <div
              css={css`
                display: grid;
                grid-gap: 0.25em;
                grid-template-columns: 1fr;
                align-items: stretch;
                ${fromTabletMd} {
                  grid-template-columns: repeat(2, 1fr);
                }
              `}
            >
              {s.availableInstruments.map((def: InstrumentDef) => (
                <SelectableItem
                  key={def.meta.name}
                  selectStateChecker={() => s.selection.has(def)}
                  onClick={s.selectHandlerFactory(def)}
                >
                  <InstrumentMetaEntry instrumentMeta={def.meta} />
                </SelectableItem>
              ))}
            </div>
          </PanelInner>
          <PanelFooter sticky css={{ textAlign: "right" }}>
            <Button
              onClick={s.addInstrumentsToInterpretation}
              disabled={s.selection.size === 0}
            >
              Add {s.selection.size > 1 ? "instruments" : "instrument"}
            </Button>
          </PanelFooter>
        </Panel>
      )}
    />
  );
};

export default InterpretationAddInstrumentPanel;
