/** @jsxImportSource @emotion/react */
import { action, runInAction } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import {
  bg50,
  fg05,
  varPrimary,
  varPrimary30,
} from "../../constants/cssCustomProperties.constants";
import { CSSPartial } from "../@types/css.types";
import { useControllers } from "../hooks/rootContext.hooks";
import { useObservableRef } from "../hooks/useObservableRef.hook";
import { clampLines } from "../styles/helpers/clampLines";
import cx from "../utils/className.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import Button, { ButtonGroup } from "./Button";
import { FileRecord } from "../../models/FileRecord.model";
import ErrorRenderer from "./ErrorRenderer";
import ImageFromFileRecord from "./ImageFromFileRecord";

type FileUploaderProps = {
  previewImage?: FileRecord | null;
  className?: string;
  acceptOnlyImages: boolean;
  onUpload?: (file: FileRecord) => void;
  sizeLimitInMB?: number;
  purpose?: "general" | "avatar";
};

const style = {
  wrapper: {
    position: "relative",
    width: "100%",
    backgroundColor: fg05,
    display: "grid",
    gridTemplateColumns: "auto minmax(0, 1fr)",
    alignContent: "stretch",
    input: {
      position: "absolute",
      opacity: 0,
      width: "100%",
      height: "100%",
    },
    figure: {
      position: "relative",
      width: "10rem",
      height: "10rem",
      padding: 0,
      margin: 0,
      objectPosition: "center",
      backgroundColor: fg05,
      pointerEvents: "none",
      ".dragOver &": {
        pointerEvents: "none",
      },
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    figcaption: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      padding: ".25em",
      backgroundColor: bg50,
      textAlign: "center",
      wordWrap: "break-word",
      backdropFilter: "blur(1em)",
      ".dragOver &": {
        pointerEvents: "none",
      },
      ...clampLines(2),
    },
  } as CSSPartial,
  rightSlot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1em",
    ".dragOver &": {
      pointerEvents: "none",
    },
    div: {
      textAlign: "right",
    },
    p: {
      opacity: 0.6,
    },
  } as CSSPartial,
  droppableOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: varPrimary30,
    border: `1px solid ${varPrimary}`,
    pointerEvents: "none",
  } as CSSPartial,
};

const FileUploader: React.FC<FileUploaderProps> = props => {
  const p = useProps(props);
  const { BUCKET, AUTH } = useControllers();
  const ref = useObservableRef<HTMLInputElement>();
  const s = useStore(() => ({
    fileToUpload: {
      fileName: "",
      contentBase64: null as string | null,
    },
    uploadedFile: null as FileRecord | null,
    isUploading: false,
    uploaded: false,
    error: null as null | unknown,
    selectFile: () => {
      ref.current?.click();
      s.error = null;
    },
    get sizeLimitInMB() {
      return p.sizeLimitInMB ?? (p.acceptOnlyImages ? 2 : 7);
    },
    get sizeLimitInBytes() {
      return s.sizeLimitInMB * 1024 * 1024;
    },
    processFile: action((e: React.FormEvent) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) return;
      if (file.size > s.sizeLimitInBytes) {
        s.error = new Error("File too big.");
        return;
      }
      s.uploaded = false;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = action(e => {
        if (!e.target?.result) return;
        s.fileToUpload.fileName = file.name;
        s.fileToUpload.contentBase64 = e.target.result as string;
        s.uploadFile();
      });
    }),
    uploadFile: async () => {
      if (!s.fileToUpload.contentBase64) return;
      runInAction(() => {
        s.isUploading = true;
      });
      try {
        const uploadedFile = await BUCKET.upload({
          ...s.fileToUpload,
          purpose: p.purpose,
        });
        if (uploadedFile) {
          p.onUpload?.(uploadedFile);
          s.uploadedFile = uploadedFile;
        }
        runInAction(() => {
          s.fileToUpload.fileName = "";
          s.fileToUpload.contentBase64 = null;
          s.uploaded = true;
        });
      } catch (e) {
        runInAction(() => {
          s.error = e;
        });
      } finally {
        runInAction(() => {
          s.isUploading = false;
        });
      }
    },
    get previewImageTitle(): string | null {
      return p.previewImage ? null : s.fileToUpload.fileName;
    },
    handleFigureClick: () => {
      if (!s.fileToUpload.contentBase64) {
        s.selectFile();
      }
    },
    dragOver: false,
    handleDragOver: () => {
      s.dragOver = true;
    },
    handleDragLeave: () => {
      s.dragOver = false;
    },
    handleDragEnd: () => {
      s.dragOver = false;
    },
  }));
  return (
    <Observer
      children={() => (
        <div
          className={cx(p.className, s.dragOver && "dragOver")}
          css={style.wrapper}
          onDragOver={s.handleDragOver}
          onDragLeave={s.handleDragLeave}
          onDragEnd={s.handleDragEnd}
          onDragExit={s.handleDragEnd}
          onDrop={s.handleDragEnd}
        >
          <input
            ref={ref}
            type="file"
            onChange={s.processFile}
            accept={
              p.acceptOnlyImages
                ? `image/png, image/jpeg${
                    AUTH.isAdmin ? ", image/svg+xml" : ""
                  }`
                : ""
            }
          />
          <figure>
            {s.fileToUpload.contentBase64 ? (
              <img src={s.fileToUpload.contentBase64} />
            ) : s.uploadedFile ? (
              <ImageFromFileRecord imageId={s.uploadedFile._id} />
            ) : p.previewImage ? (
              <ImageFromFileRecord imageId={p.previewImage._id} />
            ) : null}
            {s.previewImageTitle && (
              <figcaption>{s.previewImageTitle}</figcaption>
            )}
          </figure>
          <div css={style.rightSlot}>
            <ButtonGroup>
              <Button onClick={s.selectFile}>Select file</Button>
              <Button
                onClick={s.uploadFile}
                loading={s.isUploading}
                disabled={!s.fileToUpload.contentBase64}
              >
                {s.uploaded ? "✓ Uploaded" : "Upload"}
              </Button>
            </ButtonGroup>
            <div>
              <p>Max file size: {s.sizeLimitInMB} MB</p>
              {s.error ? <ErrorRenderer error={s.error} /> : null}
            </div>
          </div>
          {s.dragOver && <div css={style.droppableOverlay} />}
        </div>
      )}
    />
  );
};

export default FileUploader;
