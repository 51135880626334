import { IconDef } from "./_index.iconDefs";

export const makeIconDef = (name: string, iconDef: IconDef) => ({
  regular: () => (
    <symbol
      key={`icon-${name}`}
      id={`icon-${name}`}
      viewBox="0 0 25 25"
      fill="none"
    >
      {iconDef.regular()}
    </symbol>
  ),
  colored: iconDef.colored
    ? () => (
        <symbol
          key={`icon-${name}-colored`}
          id={`icon-${name}-colored`}
          viewBox="0 0 25 25"
          fill="none"
        >
          {iconDef.colored && iconDef.colored()}
        </symbol>
      )
    : null,
});
