import { useOnMount } from "../hooks/lifecycle.hooks";

export const useCompleteProgressBar = () => {
  useOnMount(() => {
    window.ProgressBar?.complete();
  });
};
export const useResetProgressBar = () => {
  useOnMount(() => {
    window.ProgressBar?.reset();
  });
};
export const useShowProgressBar = () => {
  useOnMount(() => {
    window.ProgressBar?.show();
  });
};

export const ShowGlobalProgressBar = () => {
  useOnMount(() => {
    window.ProgressBar?.show();
  });
  return null;
};
