/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { useThemeController } from "../base/hooks/theme.hooks";

type BrokenRecordProps = {};

const BrokenRecord: React.FC<BrokenRecordProps> = props => {
  const THEME = useThemeController();
  return (
    <Observer
      children={() => (
        <svg width="126" height="109" viewBox="0 0 126 109" fill="none">
          <path
            opacity="0.2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.979 14.9785C47.6178 15.0333 47.2563 15.0926 46.8946 15.1564C22.2011 19.5106 5.71282 43.0583 10.0669 67.7518C14.4211 92.4452 37.9688 108.934 62.6623 104.579C70.2913 103.234 77.1372 100.057 82.8272 95.5799L65.7678 76.7216L57.378 73.1899L57.0855 64.2319C56.6423 64.4661 56.1556 64.6361 55.6354 64.7279C52.9514 65.2011 50.3918 63.4089 49.9186 60.7249C49.6053 58.9486 50.2843 57.2268 51.5574 56.1282L48.204 55.2645L50.192 46.6457L55.7815 41.4007L45.0491 26.005L47.979 14.9785Z"
            fill="url(#paint0_linear_30_13119)"
          />
          <path
            opacity="0.2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.9184 60.7249L10.0668 67.7518C11.1022 73.6234 13.2838 79.2336 16.4873 84.262C19.6908 89.2904 23.8533 93.6387 28.7372 97.0585L51.9478 63.9104C50.9133 63.186 50.1551 62.0669 49.9184 60.7249Z"
            fill="url(#paint1_linear_30_13119)"
          />
          <path
            opacity="0.3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.878 48.0068C44.4285 50.2521 41.0769 56.0526 42.1425 62.096C43.373 69.0746 50.0278 73.7343 57.0064 72.5038C57.1227 72.4833 57.2384 72.4613 57.3534 72.4378L57.0855 64.2318C56.6423 64.466 56.1555 64.6361 55.6353 64.7279C52.9512 65.2012 50.3917 63.4089 49.9184 60.7249C49.6052 58.9486 50.2842 57.2268 51.5573 56.1282L48.204 55.2645L49.878 48.0068Z"
            fill="url(#paint2_linear_30_13119)"
          />
          <path
            d="M32.989 45.4831L25.6886 55.9092M24.1257 47.046L34.5518 54.3464"
            stroke="currentColor"
          />
          <path
            opacity="0.2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.9936 101.465C103.879 95.0859 114.862 80.9288 116.372 63.667C118.525 39.054 100.616 17.3128 76.194 14.5903L70.51 24.4829L76.892 42.1318L70.1354 45.7513L65.9845 53.5619L69 55.2641C69.7737 54.8902 70.6552 54.7136 71.5735 54.7939C74.2886 55.0315 76.2971 57.4251 76.0595 60.1402C75.868 62.329 74.2753 64.0586 72.2424 64.5224L70.2064 73.251L77.3963 78.8338L88.9936 101.465Z"
            fill="url(#paint3_linear_30_13119)"
          />
          <path
            opacity="0.2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M114.443 46.0577C116.236 51.7439 116.891 57.7276 116.372 63.6671L76.0595 60.1402C76.1783 58.7826 75.7355 57.5054 74.9237 56.538L105.923 30.5267C109.755 35.094 112.65 40.3715 114.443 46.0577Z"
            fill="url(#paint4_linear_30_13119)"
          />
          <path
            opacity="0.3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70.3771 72.5181C77.29 72.9351 83.3178 67.77 83.9251 60.8284C84.5427 53.7691 79.3207 47.5458 72.2614 46.9281C71.3153 46.8454 70.3842 46.8675 69.4796 46.9849L65.9842 53.5619L68.9997 55.2641C69.7734 54.8902 70.6549 54.7136 71.5732 54.794C74.2883 55.0315 76.2968 57.4251 76.0592 60.1402C75.8677 62.3291 74.275 64.0587 72.2421 64.5225L70.3771 72.5181Z"
            fill="url(#paint5_linear_30_13119)"
          />
          <path
            d="M100.328 42.6463L90.5777 50.8276M91.3621 41.8619L99.5434 51.612"
            stroke="currentColor"
          />
          <path
            d="M98.6034 68.0928C92.452 69.5623 86.9916 74.6055 85.0616 79.4558"
            stroke="currentColor"
          />
          <path
            d="M93.4046 70.1475C98.6101 75.0251 92.905 81.1534 87.4026 75.6453"
            stroke="currentColor"
          />
          <path
            opacity="0.1"
            d="M70.9999 1.77778L61.9999 0V16L70.9999 1.77778Z"
            fill="currentColor"
          />
          <path
            opacity="0.1"
            d="M0.000120999 98.2989L2.00024 103.799L13.5546 100.182L0.000120999 98.2989Z"
            fill="currentColor"
          />
          <defs>
            <linearGradient
              id="paint0_linear_30_13119"
              x1="2.18311"
              y1="23.0403"
              x2="98.6391"
              y2="70.8162"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={THEME.fg} stopOpacity="0.29" />
              <stop offset="1" stopColor={THEME.fg} stopOpacity="0.62" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_30_13119"
              x1="36.2791"
              y1="95.7286"
              x2="30.6208"
              y2="63.6386"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={THEME.fg} />
              <stop offset="1" stopColor={THEME.fg} stopOpacity="0.3" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_30_13119"
              x1="53.7025"
              y1="77.6107"
              x2="38.243"
              y2="60.4701"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F3C8A7" />
              <stop offset="1" stopColor={THEME.fg} stopOpacity="0.31" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_30_13119"
              x1="69.4457"
              y1="13.9999"
              x2="140.49"
              y2="65.3798"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={THEME.fg} stopOpacity="0.29" />
              <stop offset="1" stopColor={THEME.fg} stopOpacity="0.62" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_30_13119"
              x1="95.4957"
              y1="61.8406"
              x2="98.3357"
              y2="29.3796"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={THEME.fg} />
              <stop offset="1" stopColor={THEME.fg} stopOpacity="0.3" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_30_13119"
              x1="75.1471"
              y1="77.4599"
              x2="66.2258"
              y2="54.3959"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F3C8A7" />
              <stop offset="1" stopColor={THEME.fg} stopOpacity="0.31" />
            </linearGradient>
          </defs>
        </svg>
      )}
    />
  );
};

export default BrokenRecord;
