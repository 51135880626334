/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { useProps } from "../utils/mobx.utils";

type CenteredMessageBoxProps = {
  className?: string;
};

const CenteredMessageBox: React.FC<
  PropsWithChildren<CenteredMessageBoxProps>
> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <div
          className={p.className}
          css={{
            boxSizing: "border-box",
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "3em 1em 6em",
            textAlign: "center",
          }}
        >
          <div>{p.children}</div>
        </div>
      )}
    />
  );
};

export default CenteredMessageBox;
