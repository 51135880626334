import { Midi } from "@tonejs/midi";
import { NoteSnapshot } from "../../@types";
import { makeSnapshot } from "../../base/utils/snapshot.utils";
import { isString } from "../../base/utils/typeChecks.utils";
import { TimeSignature } from "../../constants/timeSignatures.constants";
import { LocalDBController } from "../../controllers/localDB.controller";
import { FlemishHarpsichordName } from "../../instruments/FlemishHarpsichord/FlemishHarpsichord.instrument";
import { NoteSnapshotFactory } from "../../models/atoms/Note.model";
import { VoiceSnapshotFactory } from "../../models/atoms/Voice.model";
import {
  Composition,
  CompositionSnapshotFactory,
  makeCompositionOptions,
} from "../../models/Composition.model";
import { ColorPalette } from "../../theming/colorPalette";
import { getYFromMidiNumber } from "../musicKey.utils";

export const convertMidiFileToComposition = (
  LOCALDB: LocalDBController,
  source: string | ArrayBuffer,
  fileName?: string,
  isTest?: boolean
) =>
  new Promise<Composition>(async resolve => {
    const midi = await (isString(source)
      ? Midi.fromUrl(source)
      : new Midi(source));
    const compSnapshot = makeSnapshot(CompositionSnapshotFactory, {
      _id: isTest ? "TEST" : undefined,
      title:
        (midi.header.name && midi.header.name.toLowerCase() !== "untitled"
          ? midi.header.name
          : fileName) ??
        ((isString(source)
          ? source.match(/\/([^/]*).midi?$/)?.[1]
          : "Untitled") ||
          "Untitled"),
      description: "",
      atomSnapshots: [],
      initialSource: "midi",
      options: makeSnapshot(makeCompositionOptions, {
        defaultInstrumentName: FlemishHarpsichordName,
        automaticallyManageBars: false,
        timeSignature: (midi.header.timeSignatures[0]?.timeSignature ?? [
          4, 4,
        ]) as TimeSignature,
      }),
    });
    const nonEmptyTracks = midi.tracks.filter(t => t.notes.length > 0);
    const voices = await Promise.all(
      nonEmptyTracks.map((track, i) => {
        return makeSnapshot(VoiceSnapshotFactory, {
          _id: `${i + 1}`,
          name: track.name || `${i + 1}`,
        });
      })
    );
    compSnapshot.atomSnapshots.push(...voices);
    let id = voices.length + 1;
    const allNotes: NoteSnapshot[] = [];
    nonEmptyTracks.forEach((track, i) => {
      const voice = voices[i];
      const notesInVoice = track.notes.map(note => {
        const noteId = id++;
        return makeSnapshot(NoteSnapshotFactory, {
          _id: `${noteId}`,
          x: note.time,
          width: note.duration,
          y: getYFromMidiNumber(note.midi),
          voiceId: voice._id,
          velocity: note.velocity,
        });
      });
      Reflect.set(
        voice,
        "childrenIds",
        notesInVoice.map(n => n._id)
      );
      allNotes.push(...notesInVoice);
      compSnapshot.atomSnapshots.push(...notesInVoice);
    });
    const firstNoteVelocity = allNotes[0]?.velocity ?? 1;
    const noVelocityInput =
      allNotes.length <= 1 ||
      allNotes.every(n => n.velocity === firstNoteVelocity);
    if (noVelocityInput) {
      (
        compSnapshot.atomSnapshots.filter(
          atom => atom.type === "note"
        ) as NoteSnapshot[]
      ).forEach(n => (n.velocity = 0.5));
    }
    const composition = new Composition(LOCALDB, compSnapshot);
    resolve(composition);
  });

export const DefaultVoiceColorsFromDefaultPalette = [
  ColorPalette.pistachio,
  ColorPalette.yellow,
  ColorPalette.red,
  ColorPalette.eucalyptus,
  ColorPalette.gray,
  ColorPalette.teal,
  ColorPalette.apricot,
  ColorPalette.orange,
  ColorPalette.neonGreen,
  ColorPalette.purple,
  ColorPalette.blue,
  ColorPalette.violet,
  ColorPalette.green,
  ColorPalette.lightGray,
];
