export enum SupportedNoteNamingConvention {
  "English" = "en",
  // 'German' = 'de',
}
export enum SupportedNoteValueNamingConvention {
  "AmericanEnglish" = "en-US",
  "BritishEnglish" = "en-GB",
}
export type NotationNoteSymbolDescriptor = {
  type?: "note" | "rest";
  beats: number;
  midiNumber: number | null;
  beatsPerWholeNote: number;
  lang?: SupportedNoteValueNamingConvention;
};
