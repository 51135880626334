/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { FormControlProps } from "../../base/@types";
import Dropdown from "../../base/components/Dropdown";
import { joinTruthy } from "../../base/utils/array.utils";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import {
  MusicScaleDefMap,
  MusicScaleName,
} from "../../constants/musicScales.constants";

export type MusicScaleSelectorProps<T extends UnknownObject = UnknownObject> =
  FormControlProps<T> & {
    Label?: ReactNode;
  };

const MusicScaleSelector = <T extends UnknownObject = UnknownObject>(
  props: React.PropsWithChildren<MusicScaleSelectorProps<T>>
) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get options() {
      return s.showExtraOptions
        ? Object.entries(MusicScaleDefMap).map(k => ({
            label: s.showExtraOptions
              ? joinTruthy(" / ", k[1].commonName, k[1].displayName)
              : k[1].commonName,
            value: k[0],
          }))
        : [
            {
              label: MusicScaleDefMap.Ionian.commonName,
              value: MusicScaleDefMap.Ionian.name,
            },
            {
              label: MusicScaleDefMap.Aeolian.commonName,
              value: MusicScaleDefMap.Aeolian.name,
            },
          ];
    },
    get value() {
      return p.form?.[p.field as keyof T];
    },
    get showExtraOptions() {
      return (
        s.value &&
        s.value !== MusicScaleName.Ionian &&
        s.value !== MusicScaleName.Aeolian
      );
    },
  }));
  return (
    <Observer
      children={() => (
        <div className={cx("MusicScaleSelector", p.className)}>
          <Dropdown
            form={p.form}
            field={p.field}
            options={s.options}
            Label={p.Label}
            taskName={p.taskName}
            mergeableId={p.mergeableId}
            mergeableTaskName={p.mergeableTaskName}
            disabled={p.disabled}
          />
        </div>
      )}
    />
  );
};

export default MusicScaleSelector;
