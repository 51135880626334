/** @jsxImportSource @emotion/react */
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Atom, Instrument } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import Button from "../../base/components/Button";
import Panel, {
  PanelCloseButton,
  PanelFooter,
  PanelHeader,
  PanelInner,
} from "../../base/components/Panel";
import SelectableItem from "../../base/components/SelectableItem";
import Serif from "../../base/components/Serif";
import { UNITS } from "../../base/constants/units.constant";
import { replaceContents } from "../../base/utils/array.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import {
  fg00,
  fg02,
  fg05,
} from "../../constants/cssCustomProperties.constants";
import { isVoiceAtom } from "../../utils/atoms.utils";
import AddInstrumentButton from "../composer/AddInstrumentButton";
import { useComposer } from "../composer/ComposerApp.context";
import InstrumentEntry from "../shared/InstrumentEntry";

type AtomSelectInstrumentsPanelProps = {
  atom: Atom;
  onShouldClose?: () => void;
};

const style = {
  emptyOption: {
    padding: "1em",
    backgroundColor: fg05,
    border: `${UNITS.lineWidth}px solid ${fg02}`,
    backgroundImage: `linear-gradient(to top, ${fg02} 0, ${fg00} 4em)`,
  } as CSSPartial,
  footerFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
};

const AtomSelectInstrumentsPanel: React.FC<
  AtomSelectInstrumentsPanelProps
> = props => {
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    get width() {
      return 400;
    },
    selection: new Set<string>(p.atom.rulePropertiesFlattened.instrumentIds),
    get isChildVoice() {
      return isVoiceAtom(p.atom) && !!p.atom.parentVoice;
    },
    clearSelection: () => {
      s.selection.clear();
    },
    setAtomInstruments: () => {
      I.runInHistory(
        "Update instruments",
        action(() => {
          if (!I.interpreter) return;
          let rule = p.atom.lastOwnRule;
          if (!rule) {
            rule = I.interpreter.findOrCreateRuleForAtom(p.atom) ?? null;
          }
          if (rule) {
            if (!rule.$.properties.instrumentIds)
              rule.$.properties.instrumentIds = [];
            replaceContents(
              rule.$.properties.instrumentIds,
              Array.from(s.selection)
            );
          }
        })
      );
      p.onShouldClose?.();
    },
    selectHandlerFactory: (id: string) =>
      action(() => {
        s.selection.has(id) ? s.selection.delete(id) : s.selection.add(id);
      }),
  }));

  return (
    <Observer
      children={() => (
        <Panel
          id="atom-select-instruments"
          title="Configure instruments"
          fullscreen
          canEscape
          onShouldClose={p.onShouldClose}
          width={s.width}
        >
          <PanelHeader End={<PanelCloseButton onClick={p.onShouldClose} />}>
            <h2>
              <Serif>Configure instruments</Serif>
            </h2>
            <p>For atom #{p.atom._id}</p>
          </PanelHeader>
          <PanelInner>
            <div css={{ "> * + *": { marginTop: ".5em" } }}>
              {s.isChildVoice && (
                <SelectableItem
                  selectStateChecker={() => s.selection.size === 0}
                  onClick={s.clearSelection}
                >
                  <div css={style.emptyOption}>Inherit form parent voice</div>
                </SelectableItem>
              )}
              {(I.interpreter?.instruments ?? []).map((ins: Instrument) => (
                <SelectableItem
                  key={ins._id}
                  selectStateChecker={() => s.selection.has(ins._id)}
                  onClick={s.selectHandlerFactory(ins._id)}
                >
                  <InstrumentEntry instrument={ins} />
                </SelectableItem>
              ))}
            </div>
          </PanelInner>
          <PanelFooter sticky>
            <div css={style.footerFlex}>
              <AddInstrumentButton />
              <Button
                onClick={s.setAtomInstruments}
                disabled={!s.isChildVoice && s.selection.size === 0}
              >
                Confirm
              </Button>
            </div>
          </PanelFooter>
        </Panel>
      )}
    />
  );
};

export default AtomSelectInstrumentsPanel;
