import chroma from "chroma-js";
import { action, observable, reaction } from "mobx";
import {
  ColorTheme,
  ColorThemeVariant,
  ContextColorName,
  HexColorCode,
  SystemColorSchemeName,
} from "../base/@types";
import { isCSSColorKeyword, isColorHexCode } from "../base/utils/colors.utils";
import { makeDisposerController } from "../base/utils/disposer.utils";
import {
  mapObjectByKey,
  mapObjectByValue,
} from "../base/utils/ramdaEquivalents.utils";
import { ClavieristColorTheme } from "../theming/themes/clavierist.colorTheme";
import { AuthController } from "./auth.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import {
  ClavieristWindowEvent,
  postMessageToParentWindow,
} from "../utils/messages.utils";
import { getUrlParam } from "../base/utils/urlParams.utils";

export type ContextColorWithContrastColor = {
  name: ContextColorName;
  color: HexColorCode;
  contrast: HexColorCode;
};

export type ThemeController = ReturnType<typeof makeThemeController>;

const DEFAULT_WHITE = "#FFFFFF";
const DEFAULT_BLACK = "#000000";

export const checkIfSystemPreferredDarkTheme = () => {
  try {
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  } catch (e) {
    return true;
  }
};

export const isRecognizedColorScheme = (
  value: string | null
): value is SystemColorSchemeName => value === "light" || value === "dark";

export const makeThemeController = () => {
  const systemPreferredDarkThemeValue = checkIfSystemPreferredDarkTheme();
  const c = observable({
    ...makeControllerBase("THEME"),
    get AUTH(): AuthController {
      return c.ROOT!.AUTH;
    },
    get preferredColorScheme() {
      return isRecognizedColorScheme(c.colorSchemeOverride)
        ? c.colorSchemeOverride
        : c.ROOT?.SETTINGS.theming.colorScheme ?? "dark";
    },
    set preferredColorScheme(type: SystemColorSchemeName) {
      if (!c.ROOT) return;
      if (c.colorSchemeOverride) {
        c.colorSchemeOverride = null;
      }
      c.ROOT.SETTINGS.theming.colorScheme = type;
    },
    systemPreferredDarkTheme: systemPreferredDarkThemeValue,
    get theme(): ColorTheme {
      return (
        c.ROOT?.COMPOSER.instance?.selectedInterpretation?.$.theme ??
        ClavieristColorTheme
      );
    },
    colorSchemeOverride: getUrlParam("color-scheme"),
    get themeVariant(): ColorThemeVariant {
      return c.preferredColorScheme === "dark"
        ? c.theme.variants.dark
        : c.theme.variants.light;
    },
    switchColorScheme: () => {
      if (!c.ROOT) return;
      switch (c.preferredColorScheme) {
        case "dark":
          c.ROOT.SETTINGS.theming.colorScheme = "light";
          break;
        case "light":
        default:
          c.ROOT.SETTINGS.theming.colorScheme = "dark";
      }
      if (c.colorSchemeOverride) {
        c.colorSchemeOverride = null;
      }
    },
    get whitePoint(): HexColorCode {
      return DEFAULT_WHITE;
    },
    get blackPoint(): HexColorCode {
      return DEFAULT_BLACK;
    },
    getContrastColor: (clr?: ContextColorName | null | string): string => {
      if (!clr) return c.themeVariant.colors.foreground;
      const color = isColorHexCode(clr)
        ? clr
        : c.themeVariant.colors[clr as unknown as ContextColorName] ||
          DEFAULT_BLACK;
      const luminosity = c.getLuminosity(color);
      return luminosity > 0.5 ? c.shade : c.light;
    },
    get contrastContextColors(): ColorThemeVariant["colors"] {
      return mapObjectByValue(c.getContrastColor, c.themeVariant.colors);
    },
    get fg(): HexColorCode {
      return c.themeVariant.colors.foreground;
    },
    get bg(): HexColorCode {
      return c.themeVariant.colors.background;
    },
    get bgLuminance(): number {
      return chroma(c.bg).luminance();
    },
    get primary(): HexColorCode {
      return c.themeVariant.colors.primary;
    },
    getColor: (n: ContextColorName) => {
      return c.themeVariant.colors[n];
    },
    getContextColorWithContrastColor: (
      name: ContextColorName
    ): { name: string; color: string; contrast: string } =>
      observable({
        name,
        get color() {
          return c.themeVariant.colors[name];
        },
        get contrast() {
          return c.contrastContextColors[name];
        },
      }),
    get contextColorsWithContrastColors(): Record<
      ContextColorName,
      { name: string; color: string; contrast: string }
    > {
      return mapObjectByKey(
        c.getContextColorWithContrastColor,
        c.themeVariant.colors
      );
    },
    get light(): HexColorCode {
      return c.isDarkTheme
        ? c.themeVariant.colors.foreground
        : c.themeVariant.colors.background;
    },
    get shade(): HexColorCode {
      return c.isDarkTheme
        ? c.themeVariant.colors.background
        : c.themeVariant.colors.foreground;
    },
    get isDarkTheme(): boolean {
      const fgLuminosity = chroma(c.themeVariant.colors.foreground).luminance();
      const bgLuminosity = chroma(c.themeVariant.colors.background).luminance();
      return fgLuminosity > bgLuminosity;
    },
    get isLightTheme(): boolean {
      return !c.isDarkTheme;
    },
    blendWithContextColor: <T extends string>(
      color: T,
      contextColorName: ContextColorName,
      contextColorIntensity = 0.5
    ): string => {
      if (!color) return color;
      return chroma
        .mix(color, c.getColor(contextColorName), contextColorIntensity)
        .hex();
    },
    blendWithForegroundColor: <T extends string>(
      color: T,
      intensity?: number
    ): string => {
      return c.blendWithContextColor(color, "foreground", intensity);
    },
    blendWithBackgroundColor: <T extends string>(
      color: T,
      intensity?: number
    ): string => {
      return c.blendWithContextColor(color, "background", intensity);
    },
    increaseContrastFromColor: <T extends string>(
      color: T,
      targetColor: T,
      intensity = 0.19
    ): string => {
      if (!color) return color;
      if (isCSSColorKeyword(color)) return color;
      const contrast = chroma.contrast(color, targetColor);
      if (contrast > 2.5) return color;
      const i = ((21 - contrast) / 21) * intensity;
      return chroma(targetColor).luminance() < 0.5
        ? chroma(color).brighten(i).hex()
        : chroma(targetColor).darken(i).hex();
    },
    increaseContrastFromBackground: <T extends HexColorCode>(
      color: T,
      intensity?: number
    ): HexColorCode =>
      c.increaseContrastFromColor(
        color,
        c.themeVariant.colors.background,
        intensity
      ),
    getLuminosity(hexCode: HexColorCode): number {
      return chroma(hexCode).luminance();
    },
  });

  const listenToColorSchemeChanges = () => {
    const darkColorSchemeQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    const listener = action((e: MediaQueryListEvent) => {
      // console.info(`changed to ${e.matches ? "dark" : "light"} mode.`);
      c.systemPreferredDarkTheme = e.matches;
    });
    darkColorSchemeQuery.addEventListener("change", listener);
    return () => darkColorSchemeQuery.addEventListener("change", listener);
  };

  c.init = makeRootControllerChildInitFn(c, () => {
    const d = makeDisposerController();
    d.add(
      reaction(
        () => c.isDarkTheme,
        () => {
          document.documentElement.setAttribute(
            "data-theme",
            c.isDarkTheme ? "dark" : "light"
          );
        },
        { fireImmediately: true }
      )
    );
    d.add(
      reaction(
        () => [c.bg, c.fg, c.primary].join(","),
        () => {
          postMessageToParentWindow(
            ClavieristWindowEvent.ThemeChange,
            {
              background: c.bg,
              foreground: c.fg,
              primary: c.primary,
            },
            true
          );
        }
      )
    );
    d.add(listenToColorSchemeChanges());
    return d.dispose;
  });

  return c;
};
