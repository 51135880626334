/** @jsxImportSource @emotion/react */
import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React, {
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
  forwardRef,
} from "react";
import { Link } from "react-router-dom";
import { HexOrContextColorName } from "../@types";
import cx from "../utils/className.utils";
import { reportError } from "../utils/errors.utils";
import { useProps, useStore, useStyle } from "../utils/mobx.utils";
import SymbolIcon from "./SymbolIcon";
import LoadingIndicator from "./LoadingIndicator";
import { IconName, IconVariant } from "./Symbols/iconDefs/_index.iconDefs";
import { CSSPartial } from "../@types/css.types";

import { flex } from "../styles/helpers/flex.styleHelper";
import { border } from "../styles/helpers/shorthands.styleHelpers";
import { withOpacity } from "../utils/colors.utils";
import { isNotNil } from "../utils/ramdaEquivalents.utils";
import {
  useGetColorFromString,
  useThemeController,
} from "../hooks/theme.hooks";
import { lighten } from "polished";
import {
  fg10,
  varPrimary,
  varPrimary10,
  varPrimary30,
} from "../../constants/cssCustomProperties.constants";
import { UNITS } from "../constants/units.constant";
import { convertKeyValuePairsToObject } from "../utils/object.utils";

export type ButtonAppearance = "normal" | "icon" | "icon-circular" | "text";
export type ButtonColorMode =
  | "solid"
  | "translucent"
  | "primary-text"
  | "transparent";

export type ButtonProps = React.PropsWithChildren<
  {
    className?: string;
    name?: string;
    appearance?: ButtonAppearance;
    colorMode?: ButtonColorMode;
    onClick?: (
      e?: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => Promise<unknown> | unknown;
    icon?: IconName;
    iconVariant?: IconVariant;
    iconSize?: string | number;
    Icon?: ReactNode;
    color?: HexOrContextColorName | null;
    foregroundColor?: HexOrContextColorName | null;
    allCaps?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    nowrap?: boolean;
    loading?: boolean;
    padding?: string | number;
    minWidth?: string | number;
    minHeight?: string | number;
    gap?: string | number;
    align?: "start" | "end" | "center";
    to?: string;
    href?: string;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
    Label?: ReactNode;
    onMouseEnter?: (e: React.MouseEvent) => void;
    onMouseLeave?: (e: React.MouseEvent) => void;
  } & Record<`data-${string}`, string>
>;

const appearanceStyleDef: Record<
  ButtonAppearance,
  (color?: string) => CSSPartial
> = {
  normal: (color?: string) => ({
    padding: "0 .75em",
    borderRadius: 2,
    fontWeight: 500,
    border: `${UNITS.lineWidth}px solid ${
      color ? withOpacity(color, 0.1) : fg10
    }`,
  }),
  icon: () => ({
    padding: 0,
  }),
  "icon-circular": () => ({
    padding: ".5em",
    borderRadius: "50%",
  }),
  text: () => ({
    fontWeight: 700,
    padding: "0",
  }),
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  function Button(props, ref) {
    const p = useProps(props);

    const s = useStore(() => ({
      get appearance() {
        return p.appearance ?? "normal";
      },
      get colorMode() {
        if (p.colorMode) return p.colorMode;
        switch (p.appearance) {
          case "text":
          case "icon":
            return "transparent";
          default:
            return "translucent";
        }
      },
      awaitingAction: false,
      get isLoading() {
        return p.loading || s.awaitingAction;
      },
      handleClick: flow(function* (
        e: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>
      ) {
        if (p.disabled) return;
        if (s.isLoading) return;
        try {
          const onClickPromise = p.onClick && p.onClick(e);
          if (onClickPromise instanceof Promise) {
            s.awaitingAction = true;
            yield onClickPromise;
          }
        } catch (e) {
          reportError(e);
        } finally {
          s.awaitingAction = false;
        }
      }),
      get dataAttrSet() {
        return convertKeyValuePairsToObject(
          Object.entries(p).filter(([key]) => /^data-/.test(key))
        );
      },
      get commonAttr() {
        return {
          className: cx("Button", p.className, p.nowrap && "nowrap"),
          css: style.button,
          onClick: s.handleClick,
          disabled: p.disabled,
          children: s.inner,
          onMouseEnter: p.onMouseEnter,
          onMouseLeave: p.onMouseLeave,
          "data-name": p.name,
          ...s.dataAttrSet,
        };
      },
      get inner() {
        return (
          <>
            {s.isLoading && (
              <div css={style.loadingIndicatorWrapper}>
                <LoadingIndicator color={style.contrastColor} />
              </div>
            )}
            {(p.icon || p.Icon) && (
              <div css={style.iconWrapper}>
                {p.icon && (
                  <SymbolIcon
                    icon={p.icon}
                    variant={p.iconVariant}
                    size={p.iconSize}
                    css={style.icon}
                  />
                )}
                {p.Icon}
              </div>
            )}
            {(p.Label || p.children) && (
              <div css={style.label}>
                {p.Label}
                {p.children}
              </div>
            )}
          </>
        );
      },
    }));

    const THEME = useThemeController();
    const c = useGetColorFromString(() => p.color ?? "foreground");
    const authoredForeground = useGetColorFromString(() => p.foregroundColor);

    const style = useStyle(() => ({
      get contrastColor(): string {
        return (
          (p.foregroundColor
            ? authoredForeground.color
            : s.colorMode === "solid"
            ? c.contrastColor
            : THEME.increaseContrastFromBackground(c.color)) ?? "inherit"
        );
      },
      get colorModeStyleDef(): Record<ButtonColorMode, CSSPartial> {
        return {
          solid: {
            backgroundColor: c.color,
            color: style.contrastColor,
          },
          "primary-text": {
            backgroundColor: "transparent",
            color: c.color,
          },
          translucent: {
            backgroundColor: withOpacity(
              c.color,
              THEME.isDarkTheme ? 0.1 : 0.05
            ),
            color: c.color,
            "&:hover:not([disabled]):not(.disabled)": {
              filter: "none",
              backgroundColor: c.color
                ? withOpacity(c.color, 0.2)
                : varPrimary10,
              color: c.color ?? varPrimary,
              borderColor: c.color ? withOpacity(c.color, 0.3) : varPrimary30,
            },
          },
          transparent: {
            backgroundColor: "transparent",
            color: c.color,
            "&:hover": {
              filter: "brightness(1.1)",
            },
          },
        };
      },
      get button(): CSSPartial {
        return {
          position: "relative",
          ...flex({
            inline: p.fullWidth ? false : true,
            align: "center",
            justify: p.align ?? "center",
          }),
          ...(p.fullWidth && { flex: "1 1 100%", width: "100%" }),
          appearance: "none",
          fontSize: p.allCaps ? "80%" : "inherit",
          border: border(2, "transparent"),
          textTransform: p.allCaps ? "uppercase" : "inherit",
          userSelect: "none",
          textDecoration: "none",
          minWidth: p.minWidth,
          minHeight:
            p.minHeight ?? (s.appearance === "normal" ? "2.5em" : undefined),
          lineHeight: "1.2em",
          "&.nowrap": {
            whiteSpace: "nowrap",
          },
          "&:hover": {
            filter: "brightness(1.2)",
          },
          "&:active": {
            filter: "brightness(.9)",
          },
          "&:focus": {
            outline: "none",
            borderColor: lighten(0.05, c.color),
          },
          ...style.colorModeStyleDef[s.colorMode],
          ...appearanceStyleDef[s.appearance](c.color),
          ...(isNotNil(p.padding) && {
            padding: p.padding,
          }),
          "&:not([disabled]):not(.disabled)": {
            cursor: "pointer",
          },
          "&[disabled], &.disabled": {
            opacity: 0.5,
            filter: "grayscale(.38)",
          },
        };
      },
      get iconWrapper() {
        return {
          ...(s.isLoading && {
            opacity: 0,
          }),
          svg: {
            display: "block",
          },
        };
      },
      get icon() {
        return {};
      },
      get label() {
        return {
          display: "block",
          ...(s.isLoading && {
            opacity: 0,
          }),
          "* + &, & + *": {
            marginLeft: p.gap ?? ".5em",
            marginInlineStart: p.gap ?? ".5em",
          },
        };
      },
      get loadingIndicatorWrapper(): CSSPartial {
        return {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        };
      },
    }));

    return (
      <Observer
        children={() =>
          p.href ? (
            <a
              href={p.href}
              {...s.commonAttr}
              ref={ref as MutableRefObject<HTMLAnchorElement>}
            />
          ) : p.to ? (
            <Link
              to={p.to}
              {...s.commonAttr}
              ref={ref as MutableRefObject<HTMLAnchorElement>}
            />
          ) : (
            <button
              {...s.commonAttr}
              ref={ref as MutableRefObject<HTMLButtonElement>}
            />
          )
        }
      />
    );
  }
);

export const ButtonGroup = (
  props: PropsWithChildren<{
    className?: string;
    wrap?: boolean;
  }>
) => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <div
          className={p.className}
          css={{
            display: "flex",
            flexWrap: p.wrap ? "wrap" : "nowrap",
            marginLeft: "-.15em",
            marginRight: "-.15em",
            "> *": {
              margin: ".15em",
            },
          }}
        >
          {p.children}
        </div>
      )}
    />
  );
};

export default Button;
