/** @jsxImportSource @emotion/react */
import { first } from "lodash-es";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, Replica } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import Button from "../../base/components/Button";
import Checkbox from "../../base/components/Checkbox";
import PillTag from "../../base/components/PillTag";
import SpaceChildren from "../../base/components/SpaceChildren";
import Spacing from "../../base/components/Spacing";
import TextInput from "../../base/components/TextInput";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { grid6col } from "../../base/styles/partials/grids.stylePartials";
import { useMixedValueFormState } from "../../base/utils/form.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { autoPluralizeWithNumber } from "../../base/utils/string.utils";
import AtomBarRangeDisplay from "../composer/AtomBarRangeDisplay";
import AtomRulesetEditor from "../composer/AtomRulesetEditor";
import AtomVoiceSelector from "../composer/AtomVoiceSelector";
import { useComposer } from "../composer/ComposerApp.context";
import PatternEntry from "../composer/PatternEntry";

interface ReplicaPropertyPanelProps {
  atom?: Atom;
  atoms?: Atom[];
}

const style = {
  header: {
    display: "flex",
    justifyContent: "space-between",
  } as CSSPartial,
  headerInner: {
    paddingRight: "1em",
    "> * + *": {
      marginTop: ".5em",
    },
  } as CSSPartial,
  title: {
    fontSize: "1.6rem",
  } as CSSPartial,
};

const ReplicaPropertyPanel: React.FC<ReplicaPropertyPanelProps> = observer(
  props => {
    const p = useProps(props);
    const I = useComposer();
    const _ = useStore(() => ({
      get replicas(): Replica[] {
        return [p.atom, ...(p.atoms ?? [])].filter(i => i) as Replica[];
      },
    }));
    const form = useMixedValueFormState(
      () => _.replicas.map(r => r.$),
      ["x", "y", "snapToScale", "useClickThroughBoundingBox"]
    );
    const scaleForm = useMixedValueFormState(
      () => _.replicas.map(r => r.scale),
      ["x", "y"]
    );

    const s = useStore(() => ({
      get ids() {
        return _.replicas.map(n => n._id).join(",");
      },
      get firstReplica() {
        return first(_.replicas) as Replica;
      },
      get hasExactlyOneReplica() {
        return _.replicas.length === 1;
      },
      get title(): string {
        return _.replicas.length > 1
          ? `${autoPluralizeWithNumber(_.replicas, "replica")}`
          : s.firstReplica.displayName;
      },
      resetY: () => {
        I.runInHistory("Reset replica Y", () => {
          _.replicas.forEach(action(r => (r.$.y = null)));
        });
      },
      resetPosition: () => {
        I.runInHistory("Reset replica position", () => {
          _.replicas.forEach(
            action(r => {
              r.$.x = null;
              r.$.y = null;
            })
          );
        });
      },
      resetScale: () => {
        I.runInHistory("Reset replica scaling", () => {
          _.replicas.forEach(
            action(r => {
              r.scale.x = 1;
              r.scale.y = 1;
            })
          );
        });
      },
      get allReplicasAreOfTheSamePattern() {
        return (
          s.firstReplica?.pattern &&
          _.replicas.every(r => r.pattern === s.firstReplica.pattern)
        );
      },
      get canResetUseClickThroughBoundingBox() {
        return _.replicas.some(r => r.$.useClickThroughBoundingBox !== null);
      },
    }));
    useOnMount(action(() => {}));
    return (
      <div className="ReplicaPropertyPanel">
        <header css={style.header}>
          <div css={style.headerInner}>
            <h3 css={style.title}>{s.title}</h3>
            {s.hasExactlyOneReplica && (
              <p>
                <strong>
                  <AtomBarRangeDisplay atom={s.firstReplica} />
                </strong>
              </p>
            )}
          </div>
          {s.hasExactlyOneReplica && (
            <div>
              <PillTag color={s.firstReplica.appearance?.colorInContext}>
                #{s.firstReplica._id}
              </PillTag>
            </div>
          )}
        </header>
        <Spacing size="0.5em" />
        <SpaceChildren size="0.5em">
          {s.allReplicasAreOfTheSamePattern && s.firstReplica.pattern && (
            <>
              <h4>Source pattern:</h4>
              <PatternEntry
                pattern={s.firstReplica.pattern}
                withBorder
                selectPatternOnClick
              />
            </>
          )}

          {s.hasExactlyOneReplica && (
            <>
              <h4>Replica name</h4>
              <TextInput
                form={s.firstReplica.$}
                field="name"
                taskName={`Update replica name`}
                autoComplete="off"
                disabled={I.editDisabled}
              />
            </>
          )}

          <h4>Position</h4>

          <div css={grid6col.gridAlignEnd}>
            <TextInput
              form={form}
              field="x"
              Label="X"
              type="number"
              css={grid6col.third}
              taskName={
                s.hasExactlyOneReplica
                  ? `Changing "${s.firstReplica.displayName}" anchor X`
                  : `Change anchor X of ${autoPluralizeWithNumber(
                      _.replicas,
                      "replica"
                    )}`
              }
              mergeableId={`update-replica-anchorX-${s.ids}`}
              step={I.tools.quill.snapUnitX}
              disabled={I.editDisabled}
            />
            <TextInput
              form={form}
              field="y"
              Label="Y"
              type="number"
              css={grid6col.third}
              taskName={
                s.hasExactlyOneReplica
                  ? `Changing "${s.firstReplica.displayName}" anchor Y`
                  : `Change anchor Y of ${autoPluralizeWithNumber(
                      _.replicas,
                      "replica"
                    )}`
              }
              mergeableId={`update-replica-anchorY-${s.ids}`}
              disabled={I.editDisabled}
            />
            <div css={grid6col.third}>
              <Button
                onClick={form.y !== null ? s.resetY : s.resetPosition}
                fullWidth
                disabled={I.editDisabled || (form.x === form.y) === null}
              >
                Reset {form.y !== null ? "Y" : "XY"}
              </Button>
            </div>

            <div css={grid6col.full}>
              <Spacing size=".5em" />
              <Checkbox
                form={form}
                field="snapToScale"
                taskName={
                  form.snapToScale
                    ? "Disable replica scale snapping"
                    : "Enable replica scale snapping"
                }
                mergeableId={`toggle-replica-snapToScale-${s.ids}`}
                disabled={I.editDisabled}
              >
                Snap notes to current scale where possible
              </Checkbox>
              <Spacing size=".5em" />
            </div>
          </div>

          <div css={grid6col.gridAlignEnd}>
            <TextInput
              form={scaleForm}
              field="x"
              Label="X scalar"
              type="number"
              css={grid6col.third}
              taskName={
                s.hasExactlyOneReplica
                  ? `Changing "${s.firstReplica.displayName}" scaleX`
                  : `Change scaleX of ${autoPluralizeWithNumber(
                      _.replicas,
                      "replica"
                    )}`
              }
              step={0.1}
              disabled={I.editDisabled}
            />
            <div css={grid6col.third}>
              <Button
                onClick={s.resetScale}
                fullWidth
                disabled={I.editDisabled}
              >
                Reset
              </Button>
            </div>
          </div>

          {/* {s.hasExactlyOneReplica && (
            <>
              <Spacing size=".5em" />
              <GroupLikeAtomChildrenSpacingEditor atom={s.firstReplica} />
            </>
          )} */}

          <Spacing size=".5em" />

          <AtomVoiceSelector
            css={grid6col.full}
            atoms={_.replicas}
            fullWidth
            disabled={I.editDisabled}
          />

          <Spacing />

          <AtomRulesetEditor atoms={_.replicas} />

          {I.editable && (
            <>
              <Spacing size=".5em" />

              <h4>Options</h4>

              <Spacing size=".5em" />

              <Checkbox
                form={form}
                field="useClickThroughBoundingBox"
                resettable={s.canResetUseClickThroughBoundingBox}
                defaultValue={null}
              >
                <span>Select only with ◇ label</span>
              </Checkbox>
            </>
          )}
        </SpaceChildren>
      </div>
    );
  }
);

export default ReplicaPropertyPanel;
