/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { autorun } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useThemeController } from "../../base/hooks/theme.hooks";
import { useObservableRef } from "../../base/hooks/useObservableRef.hook";
import { createResponsiveCanvas } from "../../base/utils/canvas.utils";
import { makeDisposerController } from "../../base/utils/disposer.utils";
import { useProps } from "../../base/utils/mobx.utils";
import { EnvelopeOptions } from "../../instruments/PolySynth/PolySynth.instrument";
import { drawEnvelopeCurve } from "../../utils/envelope.utils";

type EnvelopeVisualizerProps = {
  envelope: EnvelopeOptions;
  height?: number;
  color?: string;
};

const EnvelopeVisualizerContainer = styled.div`
  width: 100%;
  height: 6.4rem;
  canvas {
    display: block;
  }
`;

const EnvelopeVisualizer: React.FC<EnvelopeVisualizerProps> = props => {
  const p = useProps(props);
  const THEME = useThemeController();
  const ref = useObservableRef();
  useOnMount(() => {
    const d = makeDisposerController();
    const C = createResponsiveCanvas(ref);
    const paintFrame = () => {
      drawEnvelopeCurve({
        c: C,
        envelope: p.envelope,
        color: p.color || THEME.primary,
      });
    };
    d.add(autorun(paintFrame));
    C.onResize(paintFrame);
    return d.dispose;
  });
  return (
    <Observer
      children={() => (
        <EnvelopeVisualizerContainer
          ref={ref}
          style={{
            height: p.height,
          }}
        />
      )}
    />
  );
};

export default EnvelopeVisualizer;
