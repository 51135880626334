/** @jsxImportSource @emotion/react */
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import { PanelInner } from "../../../base/components/Panel";
import { useOnMount } from "../../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../../base/hooks/rootContext.hooks";
import { inRangeInclusive } from "../../../base/utils/math.utils";
import { useStore } from "../../../base/utils/mobx.utils";
import { first } from "../../../base/utils/ramdaEquivalents.utils";
import { useComposer } from "../ComposerApp.context";
import SelectionPropertiesPanel from "../SelectionPropertiesPanel";
import { WorkspacePanelMeta } from "./WorkspacePanelIndex";
import WSPanel from "./WSPanel";
import { Atom } from "../../../@types";

const WSPanelSelection = () => {
  const { UI } = useControllers();
  const I = useComposer();
  const s = useStore(() => ({
    ready: false,
    get ws() {
      return I.workspace;
    },
    get panel() {
      return I.workspace.panelMap.get(WSPanelSelectionMeta.name);
    },
  }));
  useOnMount(() => {
    const pointerXY = I.focusedCanvas?.pointerPosition.onViewport.pt;
    if (!pointerXY || !s.panel) return;
    const { x, width } = s.panel.params;
    const determineX = action(() => {
      if (!s.panel) return;
      if (inRangeInclusive(pointerXY.x, x, x + width)) {
        let newX = pointerXY.x + 24;
        if (newX + width > UI.appWidth) {
          newX = pointerXY.x - width - 24;
        }
        if (newX < 0) return;
        s.panel.params.x = newX;
      }
    });
    determineX();
  });
  return (
    <Observer
      children={() => (
        <WSPanel panel={s.panel}>
          <PanelInner padding={0}>
            <SelectionPropertiesPanel />
          </PanelInner>
        </WSPanel>
      )}
    />
  );
};

export const WSPanelSelectionMeta: WorkspacePanelMeta<Atom> = {
  name: "selection",
  idFactory: () => `selection`,
  titleFactory: t => {
    const firstAtom = first(t);
    if (t?.length === 1 && firstAtom) {
      return firstAtom.displayName;
    }
    return `Selection`;
  },
  iconFactory: () => "bounding-box",
  Component: WSPanelSelection,
  isSingleton: true,
};

export default WSPanelSelection;
