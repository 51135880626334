/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import SymbolIcon from "../../base/components/SymbolIcon";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { withOpacity } from "../../base/utils/colors.utils";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { InstrumentMeta } from "../../@types/instruments.types";
import { UNITS } from "../../base/constants/units.constant";

type InstrumentMetaEntryProps = {
  instrumentMeta: InstrumentMeta;
  onClick?: (instrumentMeta: InstrumentMeta) => void;
  flexDirection?: "row" | "column";
};

const InstrumentMetaEntry: React.FC<InstrumentMetaEntryProps> = props => {
  const { THEME } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    handleComponentClick: () => {
      p.onClick && p.onClick(p.instrumentMeta);
    },
    get isInteractable() {
      return Boolean(p.onClick);
    },
    get flexDirection() {
      return p.flexDirection === "column" ? "column" : "row";
    },
  }));

  const style = useStore(() => ({
    get color() {
      return p.instrumentMeta.defaultColor ?? THEME.fg;
    },
    get entry(): CSSPartial {
      return {
        backgroundColor: withOpacity(style.color, 0.1),
        border: `${UNITS.lineWidth}px solid ${withOpacity(style.color, 0.2)}`,
        position: "relative",
        display: "grid",
        gridTemplateColumns: "minmax(0, 1fr)",
        gridTemplateRows: "minmax(0, 1fr)",
        "&:hover": {
          filter: s.isInteractable ? "brightness(1.1)" : undefined,
          borderColor: style.color,
        },
        "&:active": {
          filter: s.isInteractable ? "brightness(.9)" : undefined,
        },
      };
    },
    get inner(): CSSPartial {
      return {
        display: "flex",
        position: "relative",
        flexDirection: s.flexDirection ?? "row",
        // backgroundColor: withOpacity(style.color, 0.4),
        // backgroundImage: `linear-gradient(to top, ${withOpacity(
        //   style.color,
        //   0.2
        // )} 0, ${withOpacity(style.color, 0)} 4em)`,
        zIndex: 1,
      };
    },
    get iconWrapper(): CSSPartial {
      return {
        flex: "0 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1em",
      };
    },
    get label(): CSSPartial {
      return {
        flex: "1 1 auto",
        display: "grid",
        gridGap: ".5em",
        gridTemplateColumns: "minmax(0,1fr)",
        alignItems: "center",
        padding: "1em",
        paddingTop: s.flexDirection === "row" ? "1em" : "0",
        paddingLeft: s.flexDirection === "row" ? "0" : "1em",
        textAlign: s.flexDirection === "row" ? "left" : "center",
      };
    },
    get title(): CSSPartial {
      return {
        fontSize: "1.4rem",
        fontWeight: 500,
      };
    },
    get controls(): CSSPartial {
      return {
        textAlign: "right",
      };
    },
  }));

  return (
    <Observer>
      {() => (
        <div
          className="InstrumentMetaEntry"
          data-name={p.instrumentMeta.name}
          css={style.entry}
          onClick={s.handleComponentClick}
        >
          <div css={style.inner}>
            <div css={style.iconWrapper}>
              <SymbolIcon icon={p.instrumentMeta.icon ?? "piano-keyboard"} />
            </div>
            <div css={style.label}>
              <p css={style.title}>
                {p.instrumentMeta.displayName ??
                  `Instrument ${p.instrumentMeta.name}`}
              </p>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default InstrumentMetaEntry;
