/** @jsxImportSource @emotion/react */
import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import Button from "../../base/components/Button";
import Panel, { PanelHeader, PanelInner } from "../../base/components/Panel";
import Serif from "../../base/components/Serif";
import Spacing from "../../base/components/Spacing";
import Textarea from "../../base/components/Textarea";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { makeSnapshot } from "../../base/utils/snapshot.utils";
import {
  Collection,
  CollectionSnapshotFactory,
} from "../../models/Collection.model";

type NewCollectionPanelProps = {
  onShouldClose?: (newCollection?: Collection) => void;
  shouldNavigateToNewCollectionAfterCreation?: boolean;
};

const NewCollectionPanel: React.FC<NewCollectionPanelProps> = props => {
  const { COLLECTIONS, THEME, PORTALS } = useControllers();
  const navigate = useNavigate();
  const p = useProps(props);
  const s = useStore(() => ({
    snapshot: makeSnapshot(CollectionSnapshotFactory, {}),
    createCollection: async () =>
      await flow(function* () {
        try {
          const newCollection: Collection = yield COLLECTIONS.create(
            s.snapshot
          );
          p.onShouldClose?.(newCollection);
          if (p.shouldNavigateToNewCollectionAfterCreation)
            navigate(`/workshop/collections/${newCollection._id}`);
        } catch (e) {
          console.error(e);
        }
      })(),
  }));
  return (
    <Observer
      children={() =>
        PORTALS.render(
          <Panel
            id="new-collection"
            title="New collection"
            fullscreen
            canEscape
          >
            <PanelHeader>
              <h2>
                <Serif>New collection</Serif>
              </h2>
              <Spacing />
              <p>
                Set up your new collection. You can always change those settings
                later.
              </p>
            </PanelHeader>
            <PanelInner>
              <div
                css={{
                  display: "grid",
                  gridGap: ".5em",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <TextInput
                  form={s.snapshot}
                  field="name"
                  Label="Name"
                  autoComplete="off"
                  placeholder="Enter a name"
                  css={{ gridColumnEnd: "span 2" }}
                  autoFocus
                  onEnter={s.createCollection}
                />
                <Textarea
                  form={s.snapshot}
                  field="description"
                  Label="Description (Optional)"
                  css={{ gridColumnEnd: "span 2" }}
                  rows={3}
                />
              </div>
              <Spacing />
              <Button
                fullWidth
                onClick={s.createCollection}
                icon="check"
                color={THEME.primary}
              >
                Confirm
              </Button>
            </PanelInner>
          </Panel>
        )
      }
    />
  );
};

export default NewCollectionPanel;
