import { CSSPartial } from "../../@types/css.types";

export const clampLines = (lines = 3): CSSPartial => ({
  display: "-webkit-box",
  WebkitLineClamp: lines,
  WebkitBoxOrient: "vertical",
  lineClamp: lines,
  textOverflow: "ellipsis",
  overflow: ["hidden", "clip"],
});
