/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { ReactNode, RefObject } from "react";
import { Link } from "react-router-dom";
import { CSSPartial } from "../@types/css.types";
import cx from "../utils/className.utils";
import { cVar } from "../utils/customProperties.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import SymbolIcon from "./SymbolIcon";
import { IconName, IconVariant } from "./Symbols/iconDefs/_index.iconDefs";

type IconButtonProps = React.PropsWithChildren<{
  className?: string;
  icon?: IconName;
  iconVariant?: IconVariant;
  onClick?: (e?: React.MouseEvent) => void;
  onPointerDown?: () => void;
  color?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  foregroundColorVar?: string;
  backgroundColorVar?: string;
  padding?: string | number;
  to?: string;
  disabled?: boolean;
  innerRef?: RefObject<HTMLButtonElement | HTMLAnchorElement>;
}>;

const IconButton: React.FC<IconButtonProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get button(): CSSPartial {
      return {
        appearance: "none",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        background: p.backgroundColorVar
          ? cVar(p.backgroundColorVar)
          : "transparent",
        color: p.foregroundColorVar
          ? cVar(p.foregroundColorVar)
          : p.color ?? "inherit",
        border: "none",
        padding: p.padding ?? ".3em",
        '&[disabled], &[data-disabled="true"]': {
          opacity: 0.5,
          cursor: "not-allowed",
        },
      };
    },
    get icon(): ReactNode {
      return p.icon ? (
        <SymbolIcon icon={p.icon} variant={p.iconVariant} />
      ) : (
        p.children
      );
    },
  }));
  return (
    <Observer
      children={() =>
        p.to && !p.disabled ? (
          <Link
            className={cx("IconButton", p.className)}
            to={p.to}
            css={s.button}
            onPointerDown={p.disabled ? undefined : p.onPointerDown}
            onClick={p.disabled ? undefined : p.onClick}
            data-disabled={p.disabled}
            ref={p.innerRef as RefObject<HTMLAnchorElement>}
          >
            {s.icon}
          </Link>
        ) : (
          <button
            className={cx("IconButton", p.className)}
            type={p.type ?? "button"}
            css={s.button}
            onPointerDown={p.disabled ? undefined : p.onPointerDown}
            onClick={p.disabled ? undefined : p.onClick}
            disabled={p.disabled}
            ref={p.innerRef as RefObject<HTMLButtonElement>}
          >
            {s.icon}
          </button>
        )
      }
    />
  );
};

export default IconButton;
