import { action } from "mobx";
import { AtomContext, TextNode, TextNodeSnapshot } from "../@types";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { TextNodeSnapshotFactory } from "../models/atoms/TextNode.model";
import { insertAtomInOrder } from "../logic/atomFactoryMethods";

export const createTextNode = action(
  (context: AtomContext, template?: TextNode | Partial<TextNodeSnapshot>) => {
    const snapshot: TextNodeSnapshot = makeSnapshot(
      TextNodeSnapshotFactory,
      template ?? {}
    );
    if (!snapshot._id || context.getAtomById(snapshot._id))
      snapshot._id = context.getNextNewAtomId();
    context.addAtomSnapshots([snapshot]);
    const newTextNode = context.getAtomById<TextNode>(snapshot._id)!;
    insertAtomInOrder(context.textNodes, newTextNode);
    context.lastEditedTextNode = newTextNode;
    return newTextNode;
  }
);
