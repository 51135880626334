/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import WithRequest from "../../base/components/ContainerWithRequest";
import LineSeparator from "../../base/components/LineSeparator";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import AppPage, {
  AppPageBody,
  AppPageHeader,
} from "../../components/layout/AppPage";
import CompositionTable from "../../components/shared/CompositionTable";
import NoCompPlaceholder from "../../components/shared/NoCompPlaceholder";
import CompositionTitle from "../../components/composer/CompositionTitle";

type PageWorkshopArchivedProps = {};

const PageWorkshopArchived: React.FC<PageWorkshopArchivedProps> = props => {
  const { COMPOSITIONS, DIALOGS } = useControllers();
  return (
    <Observer
      children={() => (
        <AppPage title="Archived">
          <AppPageHeader>
            <h1>Archived</h1>
          </AppPageHeader>
          <LineSeparator />
          <AppPageBody>
            <h2>
              Archived Compositions{" "}
              {COMPOSITIONS.ownArchived.length > 0 &&
                `(${COMPOSITIONS.ownArchived.length + 1})`}
            </h2>
            <WithRequest
              id={["PageWorkshopArchived"]}
              request={COMPOSITIONS.getAllArchivedMeta}
              children={() =>
                COMPOSITIONS.ownArchived.length > 0 ? (
                  <CompositionTable
                    compositions={COMPOSITIONS.ownArchived}
                    actions={[
                      {
                        label: "Recover",
                        action: COMPOSITIONS.unarchive,
                      },
                      {
                        label: "Delete permanently",
                        action: async c => {
                          const confirm = await DIALOGS.attention({
                            Heading: "Confirm",
                            Body: (
                              <>
                                Are you sure you want to permanently delete the
                                composition “
                                <CompositionTitle composition={c} />
                                ”?
                              </>
                            ),
                          });
                          if (!confirm) return;
                          await COMPOSITIONS.delete(c);
                        },
                      },
                    ]}
                  />
                ) : (
                  <NoCompPlaceholder />
                )
              }
            />
          </AppPageBody>
        </AppPage>
      )}
    />
  );
};

export default PageWorkshopArchived;
