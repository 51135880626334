import {
  Point,
  Point3D,
  ValidPoint,
  ValidPoint3D,
} from "../../base/@types/geometry.types";
import { wrapWithSilentReadonlyProxy } from "../../base/utils/proxy.utils";

export const makePoint = (x?: number | null, y?: number | null) =>
  ({
    x: x ?? (null as number | null),
    y: y ?? (null as number | null),
  } as Point);

export const makePoint3D = (
  x?: number | null,
  y?: number | null,
  z?: number | null
) =>
  ({
    x: x ?? (null as number | null),
    y: y ?? (null as number | null),
    z: z ?? (null as number | null),
  } as Point3D);

export const NULL_POINT = wrapWithSilentReadonlyProxy(makePoint());
export const NULL_POINT_3D = wrapWithSilentReadonlyProxy(makePoint3D());

export const ZERO_POINT = wrapWithSilentReadonlyProxy(
  makePoint(0, 0)
) as ValidPoint;
export const ZERO_POINT_3D = wrapWithSilentReadonlyProxy(
  makePoint3D(0, 0, 0)
) as ValidPoint3D;
