import { makeIconDef } from "./makeIconDef";

export const AtomTreeIconDef = {
  "atom-tree": makeIconDef("atom-tree", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7C8 7.93192 7.36261 8.71497 6.5 8.93699V11V12.5H10C10.021 12.5 10.0417 12.5013 10.062 12.5038C10.2827 11.6393 11.0667 11 12 11C13.1046 11 14 11.8954 14 13C14 14.1046 13.1046 15 12 15C11.0667 15 10.2827 14.3607 10.062 13.4962C10.0417 13.4987 10.021 13.5 10 13.5H6.5V17C6.5 17.8284 7.17157 18.5 8 18.5H10C10.021 18.5 10.0417 18.5013 10.062 18.5038C10.2827 17.6393 11.0667 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C11.0667 21 10.2827 20.3607 10.062 19.4962C10.0417 19.4987 10.021 19.5 10 19.5H8C6.61929 19.5 5.5 18.3807 5.5 17V13.5V13V11V8.93699C4.63739 8.71497 4 7.93192 4 7C4 5.89543 4.89543 5 6 5C7.10457 5 8 5.89543 8 7ZM11 6C10.4477 6 10 6.44771 10 7C10 7.55228 10.4477 8 11 8L20 8C20.5523 8 21 7.55228 21 7C21 6.44771 20.5523 6 20 6L11 6ZM17 12C16.4477 12 16 12.4477 16 13C16 13.5523 16.4477 14 17 14H20C20.5523 14 21 13.5523 21 13C21 12.4477 20.5523 12 20 12L17 12ZM16 19C16 18.4477 16.4477 18 17 18H20C20.5523 18 21 18.4477 21 19C21 19.5523 20.5523 20 20 20H17C16.4477 20 16 19.5523 16 19ZM12 14C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14ZM13 19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19C11 18.4477 11.4477 18 12 18C12.5523 18 13 18.4477 13 19Z"
        fill="currentColor"
      />
    ),
  }),
};
