/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, Keyframe } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import TextInput from "../../base/components/TextInput";
import Spacing from "../../base/components/Spacing";
import PillTag from "../../base/components/PillTag";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import AtomBarRangeDisplay from "../composer/AtomBarRangeDisplay";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import KeyframePathEditor from "../composer/KeyframePathEditor";
import FormLabel from "../../base/components/FormLabel";
import { KnownKeyframeControlPath } from "../../constants/keyframe.constants";
import { first } from "../../base/utils/ramdaEquivalents.utils";
import {
  autoPluralize,
  autoPluralizeWithNumber,
} from "../../base/utils/string.utils";
import {
  setMixedValueOfField,
  useMixedValueFormState,
} from "../../base/utils/form.utils";
import AtomVoiceSelector from "../composer/AtomVoiceSelector";
import MusicKeySelector from "../composer/MusicKeySelector";
import MusicScaleSelector from "../composer/MusicScaleSelector";
import { getKeyframeControlPathDefaultValue } from "../../utils/keyframe.utils";
import { useAtomContext, useComposer } from "../composer/ComposerApp.context";
import ReferrerCounter from "../composer/ReferrerCounter";

interface KeyframePropertyPanelProps {
  atom?: Atom;
  atoms?: Atom[];
}

const style = {
  grid: {
    display: "grid",
    gridGap: ".5em",
    gridTemplateColumns: "1fr 1fr 1fr",
    fontSize: "1.2rem",
  } as CSSPartial,
  header: {
    display: "flex",
    justifyContent: "space-between",
  } as CSSPartial,
  headerInner: {
    paddingRight: "1em",
  } as CSSPartial,
  title: {
    fontSize: "1.6rem",
  } as CSSPartial,
};

const KeyframePropertyPanel: React.FC<KeyframePropertyPanelProps> = props => {
  const { SETTINGS } = useControllers();
  const p = useProps(props);
  const context = useAtomContext();
  const I = useComposer();
  const _ = useStore(() => ({
    get keyframes(): Keyframe[] {
      return [p.atom, ...(p.atoms ?? [])].filter(i => i) as Keyframe[];
    },
  }));

  const form = useMixedValueFormState(
    () => _.keyframes,
    ["startX", "y", "width", "controlPath", "value"]
  );

  const s = useStore(() => ({
    get ids() {
      return _.keyframes.map(k => k._id).join(",");
    },
    get firstKeyframe(): Keyframe {
      return first(_.keyframes) as Keyframe;
    },
    get hasExactlyOneKeyframe(): boolean {
      return _.keyframes.length === 1;
    },
    get title(): string {
      return _.keyframes.length > 1
        ? `${autoPluralizeWithNumber(_.keyframes, "keyframe")}`
        : s.firstKeyframe.displayName;
    },
    get canAdjustValueAsNumber(): boolean {
      return [
        KnownKeyframeControlPath.speedScalar,
        KnownKeyframeControlPath.sustainPedal,
        KnownKeyframeControlPath.bpmChange,
      ].includes(form.controlPath as KnownKeyframeControlPath);
    },
    handlePathChange: (path?: KnownKeyframeControlPath | string) => {
      setMixedValueOfField(_.keyframes, "controlPath", path);
      if (path)
        setMixedValueOfField(
          _.keyframes,
          "value",
          getKeyframeControlPathDefaultValue(path, context)
        );
    },
    get minValue() {
      switch (s.firstKeyframe.controlPath) {
        case KnownKeyframeControlPath.bpmChange:
          return 1;
        case KnownKeyframeControlPath.speedScalar:
          return 0.001;
        case KnownKeyframeControlPath.sustainPedal:
          return 0;
      }
    },
    get valueInputStep() {
      switch (s.firstKeyframe.controlPath) {
        case KnownKeyframeControlPath.speedScalar:
          return 0.05;
        case KnownKeyframeControlPath.bpmChange:
          return 1;
        default:
          return 0.1;
      }
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="KeyframePropertyPanel">
          <form>
            <header css={style.header}>
              <div css={style.headerInner}>
                <h3 css={style.title}>{s.title}</h3>
                {s.hasExactlyOneKeyframe && (
                  <p>
                    <strong>
                      <AtomBarRangeDisplay atom={s.firstKeyframe} />
                      {s.firstKeyframe.referrers.length > 0 && (
                        <>
                          , <ReferrerCounter atom={s.firstKeyframe} />
                        </>
                      )}
                    </strong>
                  </p>
                )}
              </div>
              {s.hasExactlyOneKeyframe && (
                <div>
                  <PillTag
                    color={s.firstKeyframe.voice?.appearance?.colorInContext}
                  >
                    #{s.firstKeyframe._id}
                  </PillTag>
                </div>
              )}
            </header>
            <Spacing />
            <div css={style.grid}>
              <TextInput
                form={form}
                type="number"
                field="startX"
                Label="X"
                step={SETTINGS.composer.snapUnitX}
                taskName={`Adjust ${autoPluralize(
                  _.keyframes,
                  "keyframe X",
                  undefined,
                  "X value of keyframes"
                )}`}
                mergeableId={`adjust-keyframe-x-${s.ids}`}
                disabled={I.editDisabled}
              />
              <TextInput
                form={form}
                type="number"
                field="y"
                Label="Y"
                step={1}
                taskName={`Adjust ${autoPluralize(
                  _.keyframes,
                  "keyframe Y",
                  undefined,
                  "Y value of keyframes"
                )}`}
                mergeableId={`adjust-keyframe-y-${s.ids}`}
                disabled={I.editDisabled}
              />
              <TextInput
                form={form}
                type="number"
                field="width"
                Label="Width"
                step={SETTINGS.composer.snapUnitX}
                taskName={`Adjust ${autoPluralize(
                  _.keyframes,
                  "keyframe width",
                  undefined,
                  "width of keyframes"
                )}`}
                mergeableId={`adjust-keyframe-width-${s.ids}`}
                disabled={I.editDisabled}
              />
              <div css={{ gridColumnEnd: "span 3" }}>
                <FormLabel bold>Control path</FormLabel>
                <Spacing size=".5em" />
                <KeyframePathEditor
                  form={form}
                  keyframe={s.firstKeyframe}
                  onChange={s.handlePathChange}
                  taskName={`Adjust ${autoPluralize(
                    _.keyframes,
                    "keyframe control path",
                    undefined,
                    "control paths of keyframes"
                  )}`}
                  mergeableId={`adjust-keyframe-control-path-${s.ids}`}
                  disabled={I.editDisabled}
                />
              </div>
              {s.canAdjustValueAsNumber && (
                <div css={{ gridColumnEnd: "span 3" }}>
                  <FormLabel bold>Control value</FormLabel>
                  <Spacing size=".5em" />
                  <TextInput
                    type="number"
                    form={form}
                    field="value"
                    step={s.valueInputStep}
                    min={s.minValue}
                    taskName={`Adjust ${autoPluralize(
                      _.keyframes,
                      "keyframe control value",
                      undefined,
                      "control value value of keyframes"
                    )}`}
                    mergeableId={`adjust-keyframe-control-value-${s.ids}`}
                    disabled={I.editDisabled}
                  />
                </div>
              )}
              {form.controlPath === KnownKeyframeControlPath.musicKeyChange && (
                <div css={{ gridColumnEnd: "span 3" }}>
                  <MusicKeySelector
                    form={form}
                    field="value"
                    taskName={`Adjust key change`}
                    mergeableId={`adjust-keyframe-key-change-${s.ids}`}
                    disabled={I.editDisabled}
                  />
                </div>
              )}
              {form.controlPath ===
                KnownKeyframeControlPath.musicScaleChange && (
                <div css={{ gridColumnEnd: "span 3" }}>
                  <MusicScaleSelector
                    form={form}
                    field="value"
                    taskName={`Adjust music scale change`}
                    mergeableId={`adjust-keyframe-music-scale-change-${s.ids}`}
                    disabled={I.editDisabled}
                  />
                </div>
              )}
            </div>
            <Spacing />
            <AtomVoiceSelector
              atoms={_.keyframes}
              nullable
              fullWidth
              disabled={I.editDisabled}
            />
          </form>
          <Spacing />
          {/* <AtomRulesEditor atom={s.keyframe} /> */}
        </div>
      )}
    />
  );
};

export default KeyframePropertyPanel;
