import {
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  StandardInstrumentOptions,
} from "../../@types";
import { STATIC_HOST } from "../../env";
import { ColorPalette } from "../../theming/colorPalette";
import { createCompositeSamplerInstrumentFactory } from "../_factory/compositeSamplerInstrumentFactory";
import FlemishHarpsichordControlPanel from "./FlemishHarpsichordControlPanel";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";

export type FlemishHarpsichordSamplerName = "lower" | "upper";

export type FlemishHarpsichordOptions = StandardInstrumentOptions & {
  activatedSamplers: FlemishHarpsichordSamplerName[];
};

export const makeDefaultFlemishHarpsichordOptions =
  (): FlemishHarpsichordOptions => ({
    ...makeDefaultInstrumentOptions(),
    activatedSamplers: ["lower"],
  });

export type FlemishHarpsichordType = Instrument<FlemishHarpsichordOptions>;

export const FlemishHarpsichordName = "flemish-harpsichord";

export const FlemishHarpsichordMeta: InstrumentMeta<FlemishHarpsichordOptions> =
  {
    name: FlemishHarpsichordName,
    displayName: "Flemish Harpsichord",
    source: "VS Chamber Orchestra: Community Edition",
    sourceWebsite: "https://github.com/sgossner/VSCO-2-CE",
    icon: "harpsichord",
    range: [[29, 88]] as InstrumentRange,
    defaultColor: ColorPalette.pistachio,
    ControlPanel: FlemishHarpsichordControlPanel,
    type: "keyboards",
  };

export const makeFlemishHarpsichord = createCompositeSamplerInstrumentFactory({
  defaultOptionsBagBuilder: makeDefaultFlemishHarpsichordOptions,
  instrumentMeta: FlemishHarpsichordMeta,
  getters: {
    samplers: (n, o) => o?.activatedSamplers ?? ["lower"],
    velocity: {
      attack: 1,
      release: 0.4,
    },
  },
  compositeSamplerConfigArray: [
    {
      name: "lower",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/flemish-harpsichord/low/`,
          volume: 8,
          urls: {
            "F#0": "HarpsiRH_Low_Far_Fsharp0_rr1.mp3",
            "A#0": "HarpsiRH_Low_Far_Asharp0_rr1.mp3",
            "A#1": "HarpsiRH_Low_Far_Asharp1_rr1.mp3",
            "A#2": "HarpsiRH_Low_Far_Asharp2_rr1.mp3",
            "A#3": "HarpsiRH_Low_Far_Asharp3_rr1.mp3",
            "A#4": "HarpsiRH_Low_Far_Asharp4_rr1.mp3",
            C1: "HarpsiRH_Low_Far_C1_rr1.mp3",
            C2: "HarpsiRH_Low_Far_C2_rr1.mp3",
            C3: "HarpsiRH_Low_Far_C3_rr1.mp3",
            C4: "HarpsiRH_Low_Far_C4_rr1.mp3",
            C5: "HarpsiRH_Low_Far_C5_rr1.mp3",
            D1: "HarpsiRH_Low_Far_D1_rr1.mp3",
            D2: "HarpsiRH_Low_Far_D2_rr1.mp3",
            D3: "HarpsiRH_Low_Far_D3_rr1.mp3",
            D4: "HarpsiRH_Low_Far_D4_rr1.mp3",
            E1: "HarpsiRH_Low_Far_E1_rr1.mp3",
            E2: "HarpsiRH_Low_Far_E2_rr1.mp3",
            E3: "HarpsiRH_Low_Far_E3_rr1.mp3",
            E4: "HarpsiRH_Low_Far_E4_rr1.mp3",
            "F#1": "HarpsiRH_Low_Far_Fsharp1_rr1.mp3",
            "F#2": "HarpsiRH_Low_Far_Fsharp2_rr1.mp3",
            "F#3": "HarpsiRH_Low_Far_Fsharp3_rr1.mp3",
            "F#4": "HarpsiRH_Low_Far_Fsharp4_rr1.mp3",
            "G#0": "HarpsiRH_Low_Far_Gsharp0_rr1.mp3",
            "G#1": "HarpsiRH_Low_Far_Gsharp1_rr1.mp3",
            "G#2": "HarpsiRH_Low_Far_Gsharp2_rr1.mp3",
            "G#3": "HarpsiRH_Low_Far_Gsharp3_rr1.mp3",
            "G#4": "HarpsiRH_Low_Far_Gsharp4_rr1.mp3",
          },
          octaveOffset: 1,
        },
        release: {
          baseUrl: `${STATIC_HOST}/instruments/flemish-harpsichord/low-release/`,
          urls: {
            "A#3": "HarpsiRH_LowRel_Far_Asharp3_rr1.mp3",
            "A#0": "HarpsiRH_LowRel_Far_Asharp0_rr1.mp3",
            "A#1": "HarpsiRH_LowRel_Far_Asharp1_rr1.mp3",
            "A#2": "HarpsiRH_LowRel_Far_Asharp2_rr1.mp3",
            "A#4": "HarpsiRH_LowRel_Far_Asharp4_rr1.mp3",
            C1: "HarpsiRH_LowRel_Far_C1_rr1.mp3",
            C2: "HarpsiRH_LowRel_Far_C2_rr1.mp3",
            C3: "HarpsiRH_LowRel_Far_C3_rr1.mp3",
            C4: "HarpsiRH_LowRel_Far_C4_rr1.mp3",
            C5: "HarpsiRH_LowRel_Far_C5_rr1.mp3",
            D1: "HarpsiRH_LowRel_Far_D1_rr1.mp3",
            D2: "HarpsiRH_LowRel_Far_D2_rr1.mp3",
            D3: "HarpsiRH_LowRel_Far_D3_rr1.mp3",
            D4: "HarpsiRH_LowRel_Far_D4_rr1.mp3",
            E1: "HarpsiRH_LowRel_Far_E1_rr1.mp3",
            E2: "HarpsiRH_LowRel_Far_E2_rr1.mp3",
            E3: "HarpsiRH_LowRel_Far_E3_rr1.mp3",
            E4: "HarpsiRH_LowRel_Far_E4_rr1.mp3",
            "F#0": "HarpsiRH_LowRel_Far_Fsharp0_rr1.mp3",
            "F#1": "HarpsiRH_LowRel_Far_Fsharp1_rr1.mp3",
            "F#2": "HarpsiRH_LowRel_Far_Fsharp2_rr1.mp3",
            "F#3": "HarpsiRH_LowRel_Far_Fsharp3_rr1.mp3",
            "F#4": "HarpsiRH_LowRel_Far_Fsharp4_rr1.mp3",
            "G#0": "HarpsiRH_LowRel_Far_Gsharp0_rr1.mp3",
            "G#1": "HarpsiRH_LowRel_Far_Gsharp1_rr1.mp3",
            "G#2": "HarpsiRH_LowRel_Far_Gsharp2_rr1.mp3",
            "G#3": "HarpsiRH_LowRel_Far_Gsharp3_rr1.mp3",
            "G#4": "HarpsiRH_LowRel_Far_Gsharp4_rr1.mp3",
          },
          octaveOffset: 1,
        },
      },
    },
    {
      name: "upper",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/flemish-harpsichord/high/`,
          volume: 6,
          urls: {
            "C#4": "HarpsiRH_High_Far_Csharp4_rr1.mp3",
            "A#1": "HarpsiRH_High_Far_Asharp1_rr1.mp3",
            "A#2": "HarpsiRH_High_Far_Asharp2_rr1.mp3",
            "A#4": "HarpsiRH_High_Far_Asharp4_rr1.mp3",
            "A#5": "HarpsiRH_High_Far_Asharp5_rr1.mp3",
            A3: "HarpsiRH_High_Far_A3_rr2.mp3",
            "C#2": "HarpsiRH_High_Far_Csharp2_rr1.mp3",
            "C#3": "HarpsiRH_High_Far_Csharp3_rr1.mp3",
            C5: "HarpsiRH_High_Far_C5_rr1.mp3",
            C6: "HarpsiRH_High_Far_C6_rr1.mp3",
            D4: "HarpsiRH_High_Far_D4_rr1.mp3",
            D5: "HarpsiRH_High_Far_D5_rr1.mp3",
            E2: "HarpsiRH_High_Far_E2_rr1.mp3",
            E3: "HarpsiRH_High_Far_E3_rr1.mp3",
            E4: "HarpsiRH_High_Far_E4_rr1.mp3",
            E5: "HarpsiRH_High_Far_E5_rr1.mp3",
            "F#1": "HarpsiRH_High_Far_Fsharp1_rr1.mp3",
            "F#2": "HarpsiRH_High_Far_Fsharp2_rr1.mp3",
            "F#3": "HarpsiRH_High_Far_Fsharp3_rr1.mp3",
            "F#4": "HarpsiRH_High_Far_Fsharp4_rr1.mp3",
            "F#5": "HarpsiRH_High_Far_Fsharp5_rr1.mp3",
            "G#1": "HarpsiRH_High_Far_Gsharp1_rr1.mp3",
            "G#2": "HarpsiRH_High_Far_Gsharp2_rr1.mp3",
            "G#3": "HarpsiRH_High_Far_Gsharp3_rr1.mp3",
            "G#4": "HarpsiRH_High_Far_Gsharp4_rr1.mp3",
            "G#5": "HarpsiRH_High_Far_Gsharp5_rr1.mp3",
          },
          octaveOffset: 1,
        },
        release: {
          baseUrl: `${STATIC_HOST}/instruments/flemish-harpsichord/high-release/`,
          urls: {
            "A#4": "HarpsiRH_HighRel_Far_Asharp4_rr1.mp3",
            "A#1": "HarpsiRH_HighRel_Far_Asharp1_rr1.mp3",
            "A#2": "HarpsiRH_HighRel_Far_Asharp2_rr1.mp3",
            "A#5": "HarpsiRH_HighRel_Far_Asharp5_rr1.mp3",
            A3: "HarpsiRH_HighRel_Far_A3_rr1.mp3",
            "C#2": "HarpsiRH_HighRel_Far_Csharp2_rr1.mp3",
            "C#3": "HarpsiRH_HighRel_Far_Csharp3_rr1.mp3",
            "C#4": "HarpsiRH_HighRel_Far_Csharp4_rr1.mp3",
            C5: "HarpsiRH_HighRel_Far_C5_rr1.mp3",
            C6: "HarpsiRH_HighRel_Far_C6_rr1.mp3",
            D4: "HarpsiRH_HighRel_Far_D4_rr1.mp3",
            D5: "HarpsiRH_HighRel_Far_D5_rr1.mp3",
            E2: "HarpsiRH_HighRel_Far_E2_rr1.mp3",
            E3: "HarpsiRH_HighRel_Far_E3_rr1.mp3",
            E4: "HarpsiRH_HighRel_Far_E4_rr1.mp3",
            E5: "HarpsiRH_HighRel_Far_E5_rr1.mp3",
            "F#1": "HarpsiRH_HighRel_Far_Fsharp1_rr1.mp3",
            "F#2": "HarpsiRH_HighRel_Far_Fsharp2_rr1.mp3",
            "F#3": "HarpsiRH_HighRel_Far_Fsharp3_rr1.mp3",
            "F#4": "HarpsiRH_HighRel_Far_Fsharp4_rr1.mp3",
            "F#5": "HarpsiRH_HighRel_Far_Fsharp5_rr1.mp3",
            "G#1": "HarpsiRH_HighRel_Far_Gsharp1_rr1.mp3",
            "G#2": "HarpsiRH_HighRel_Far_Gsharp2_rr1.mp3",
            "G#3": "HarpsiRH_HighRel_Far_Gsharp3_rr1.mp3",
            "G#4": "HarpsiRH_HighRel_Far_Gsharp4_rr1.mp3",
            "G#5": "HarpsiRH_HighRel_Far_Gsharp5_rr1.mp3",
          },
          octaveOffset: 1,
        },
      },
    },
  ],
});
