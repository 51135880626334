import { toJS } from "mobx";
import { approxEq } from "../../base/utils/math.utils";
import { first, last } from "../../base/utils/ramdaEquivalents.utils";
import {
  OrnamentationDef,
  OrnamentationType,
} from "../../constants/ornaments.constants";
import { getNoteSnapshotAfterAddingOrSubtractingStepsInScale } from "../../utils/musicScale.utils";

export const Ornamentation_TurnDef: OrnamentationDef = {
  identifier: OrnamentationType.turn,
  displayName: "Turn",
  defaultSymbol: "",
  symbolFactory: orn =>
    !orn || !orn.children.length
      ? ""
      : first(orn.children)!.y! < last(orn.children)!.y!
      ? ""
      : "",
  generator: (note, options) => {
    const $ = toJS(note.$);
    const higherNoteBase = getNoteSnapshotAfterAddingOrSubtractingStepsInScale(
      note,
      1
    );
    const lowerNoteBase = getNoteSnapshotAfterAddingOrSubtractingStepsInScale(
      note,
      -1
    );
    const nextNote = note.nextNote;
    if (!note.width) return [];
    if (
      nextNote &&
      nextNote.width &&
      approxEq(nextNote.width, note.width / 3)
    ) {
      //   
      const ornamentNoteWidth =
        options?.ornamentNoteWidth ?? nextNote.width / 3;
      const firstNoteDuration =
        note.width - nextNote.width - ornamentNoteWidth * 3;
      const fromLower =
        options?.startFromLower ??
        (nextNote.y !== null && nextNote.y > note.y!);
      return (
        fromLower
          ? [$, lowerNoteBase, $, higherNoteBase, $]
          : [$, higherNoteBase, $, lowerNoteBase, $]
      ).map((snapshot, i) => ({
        ...snapshot,
        x: (() => {
          if (i === 0) return note.x!;
          if (i === 4)
            return note.x! + firstNoteDuration + ornamentNoteWidth * 3;
          return note.x! + firstNoteDuration + (i - 1) * ornamentNoteWidth;
        })(),
        width: (() => {
          if (i === 0) return firstNoteDuration;
          if (i === 4) return nextNote.width;
          return ornamentNoteWidth;
        })(),
      }));
    } else {
      const ornamentNoteDuration =
        options?.ornamentNoteWidth ??
        Math.min(note.width * (note.width <= 0.5 ? 1 / 4 : 1 / 8), 0.25);
      return (
        options?.startFromLower
          ? [lowerNoteBase, $, higherNoteBase, $]
          : [higherNoteBase, $, lowerNoteBase, $]
      ).map((snapshot, i) => ({
        ...snapshot,
        x: note.x! + i * ornamentNoteDuration,
        width:
          i === 3
            ? note.width! - ornamentNoteDuration * 3
            : ornamentNoteDuration,
      }));
    }
  },
};
