/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Instrument, InstrumentRange, Note } from "../../@types";
import { HexOrContextColorName } from "../../base/@types";
import { CSSPartial } from "../../base/@types/css.types";
import {
  VAR_ForegroundDarken,
  fg10,
  fg30,
} from "../../constants/cssCustomProperties.constants";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useCreateResizeQueryWithRef } from "../../base/hooks/useCreateResizeQueryWithRef.hook";
import { flex } from "../../base/styles/helpers/flex.styleHelper";
import { resetListStyle } from "../../base/styles/helpers/resetListStyle.styleHelper";
import {
  hideScrollbars,
  scrollable,
} from "../../base/styles/partials/scrollable.stylePartials";
import cx from "../../base/utils/className.utils";
import { useProps, useStore, useStyle } from "../../base/utils/mobx.utils";
import { mathMod } from "../../base/utils/ramdaEquivalents.utils";
import PlayStateHighlighter from "./PlayStateHighlighter";
import { cVar } from "../../base/utils/customProperties.utils";
import { PianoRange } from "../../constants/instruments.constants";
import { jitter } from "../../base/utils/math.utils";
import {
  DisguisedNoteOffEvent,
  DisguisedNoteOnEvent,
} from "../../controllers/composer/midiRecorder.controller";
import { useAtomContext, useComposer } from "../composer/ComposerApp.context";
import { getYFromMidiNumber } from "../../utils/musicKey.utils";
import { action } from "mobx";
import { saturate } from "polished";
import { IS_EMBEDDED } from "../../env";

interface PianoKeyboardProps {
  className?: string;
  instruments: Instrument[];
  overrideDisplayRange?: InstrumentRange;
  overrideStyles?: {
    container?: CSSPartial;
    inner?: CSSPartial;
    keys?: PianoKeyStyleOverride;
  };
  keyColors?: PianoKeyColorOptions;
}

export type PianoKeyStyleOverride = {
  natural?: CSSPartial;
  halfStep?: CSSPartial;
};
export type PianoKeyColorOptions = {
  naturals?: HexOrContextColorName;
  halfSteps?: HexOrContextColorName;
  naturalsWhenPlaying?: HexOrContextColorName;
  halfStepsWhenPlaying?: HexOrContextColorName;
};

const getKeyIndices = (min: number, length: number) => {
  return Array(length)
    .fill(null)
    .map((n, i) => i + min);
};

const isHalfStep = (i: number) => [1, 3, 6, 8, 10].includes(mathMod(i, 12));

export const VAR_PianoKeyboardBackground = "--PianoKeyboardBackground";
export const VAR_PianoKeyboardTopShaderOpacity =
  "--PianoKeyboardTopShaderOpacity";
export const VAR_PianoKeyboardHalfStepKeyShadow =
  "--PianoKeyboardHalfStepKeyShadow";
export const VAR_PianoKeyHoverFilter = `--PianoKeyHoverFilter`;
export const VAR_PianoKeyHalfStepColor = "--PianoKeyHalfStepColor";
export const VAR_PianoKeyHalfStepBorder = "--PianoKeyHalfStepBorder";
export const VAR_PianoKeyColor = "--VAR_PianoKeyColor";

const PianoKeyboard: React.FC<PianoKeyboardProps> = React.memo(
  function PianoKeyboard(props) {
    const { COMPOSER, ENSEMBLE, THEME, SETTINGS, UI, KEYBOARD } =
      useControllers();

    const { ref, query } = useCreateResizeQueryWithRef<HTMLDivElement>({
      defaultWidth: UI.appWidth - 150,
    });

    const p = useProps(props);
    const I = useComposer();
    const ac = useAtomContext();
    const s = useStore(() => ({
      get displayRange(): InstrumentRange {
        return s.hasExcessiveAvailableSpace ? PianoRange : s.totalPlayableRange;
      },
      get totalPlayableRange(): InstrumentRange {
        const r =
          p.overrideDisplayRange ||
          s.instruments.map(ins => ins.range).flat() ||
          [];
        if (r.length === 0) return PianoRange;
        return r;
      },
      get lowestKeyInDisplayRange() {
        return Math.min(...s.displayRange?.flat());
      },
      get highestKeyInDisplayRange() {
        return Math.max(...s.displayRange?.flat());
      },
      get lowestKeyInPlayableRange() {
        return Math.min(...s.totalPlayableRange?.flat());
      },
      get highestKeyInPlayableRange() {
        return Math.max(...s.totalPlayableRange?.flat());
      },
      get displayKeyIndices() {
        return getKeyIndices(
          s.lowestKeyInDisplayRange,
          s.highestKeyInDisplayRange - s.lowestKeyInDisplayRange + 1
        );
      },
      get instruments() {
        return p.instruments;
      },
      keyIsInRange: (number: number) => {
        return s.totalPlayableRange.some(range => {
          if (range === number) return true;
          if (
            number >= (range as number[])[0] &&
            number <= (range as number[])[1]
          )
            return true;
          return false;
        });
      },
      get numberOfNaturalKeys() {
        return s.displayKeyIndices.filter(i => !isHalfStep(i)).length;
      },
      get width() {
        return query.width;
      },
      get naturalKeyWidth() {
        return s.width / s.numberOfNaturalKeys;
      },
      get hasExcessiveAvailableSpace() {
        return s.width > 1680;
      },
      get cursorPositionX() {
        return ac.canvas?.primaryCursor?.x ?? 0;
      },
      get playheadPositionX() {
        return ENSEMBLE.playback?.playheadPositionX ?? 0;
      },
      get playheadPositionInSeconds() {
        return ENSEMBLE.playback?.playheadPositionInSeconds ?? 0;
      },
      getJitteredVelocity() {
        return jitter(
          SETTINGS.composer.tools.quill.defaultVelocity,
          SETTINGS.composer.tools.quill.defaultVelocityJitter
        );
      },
      recordNoteOn: (number: number) => {
        const midiEvent: DisguisedNoteOnEvent = {
          type: "noteon",
          note: { number },
          velocity: s.getJitteredVelocity(),
          timestamp: Date.now(),
        };
        I.recorders.midi.processMidiEvent(
          midiEvent,
          I.recorders.midi.currentTimelinePositionInSeconds
        );
      },
      recordNoteOff: (number: number) => {
        const midiEvent: DisguisedNoteOffEvent = {
          type: "noteoff",
          note: { number },
          timestamp: Date.now(),
        };
        I.recorders.midi.processMidiEvent(
          midiEvent,
          I.recorders.midi.currentTimelinePositionInSeconds
        );
      },
      lastEnteredNote: null as Note | null,
      enterNoteOn: (midiNo: number) => {
        I.runInHistory(
          "Enter note",
          action(() => {
            const note = ac.createNote({
              x: ENSEMBLE.isPlaying
                ? s.playheadPositionX
                : KEYBOARD.pressed.shift
                ? s.lastEnteredNote?.startX ?? s.cursorPositionX
                : s.cursorPositionX,
              y: getYFromMidiNumber(midiNo),
              velocity: s.getJitteredVelocity(),
              width:
                s.lastEnteredNote?.width ?? SETTINGS.composer.snapUnitX ?? 0.25,
            });
            s.lastEnteredNote = note;
            note?.select();
          })
        );
      },
      enterNoteOff: (midiNo: number) => {},
      get useVirtualKeyboardAsInput() {
        return SETTINGS.composer.instruments.useVirtualKeyboardAsInput;
      },
      processNoteOn: (midiNo: number) => {
        if (COMPOSER.instance?.withPlayerUI) return;
        if (!s.useVirtualKeyboardAsInput) return;
        if (I.recorders.midi.isRecording) s.recordNoteOn(midiNo);
        else s.enterNoteOn(midiNo);
      },
      processNoteOff: (midiNo: number) => {
        if (COMPOSER.instance?.withPlayerUI) return;
        if (!s.useVirtualKeyboardAsInput) return;
        if (I.recorders.midi.isRecording) s.recordNoteOff(midiNo);
        else s.enterNoteOff(midiNo);
      },
      handleKeyDown: (
        midiNo: number,
        e: React.MouseEvent | React.TouchEvent
      ) => {
        ENSEMBLE.activateMidiKeysImmediately(midiNo);
        s.processNoteOn(midiNo);
      },
      handleKeyUp: (midiNo: number, e: React.MouseEvent | React.TouchEvent) => {
        ENSEMBLE.deactivateMidiKeyImmediately(midiNo);
        s.processNoteOff(midiNo);
      },
      handleKeyEnter: (midiNo: number, e: React.MouseEvent) => {
        if (!e.buttons) return;
        ENSEMBLE.activateMidiKeysImmediately(midiNo);
        s.processNoteOn(midiNo);
      },
      handleKeyLeave: (midiNo: number, e: React.MouseEvent) => {
        if (!e.buttons) return;
        ENSEMBLE.deactivateMidiKeyImmediately(midiNo);
        s.processNoteOff(midiNo);
      },
      handleKeyboardLeave: () => {
        if (ENSEMBLE.isPaused) {
          s.instruments.forEach(ins => ins.releaseAll());
        }
        if (s.useVirtualKeyboardAsInput)
          ENSEMBLE.activatedMidiKeys.forEach(k => s.processNoteOff(k));
      },
      get keyboard(): React.ReactNode {
        return (
          <ul css={style.keyboard}>
            {s.displayKeyIndices.map(number => {
              const className = isHalfStep(number) ? "halfStep" : "natural";
              return (
                <li
                  key={number}
                  className={className}
                  data-music-key={`${number}`}
                >
                  <PianoKey
                    number={number}
                    className={className}
                    css={style.pianoKey}
                    onKeyDown={s.handleKeyDown}
                    onKeyUp={s.handleKeyUp}
                    onKeyEnter={s.handleKeyEnter}
                    onKeyLeave={s.handleKeyLeave}
                    inRange={s.keyIsInRange(number)}
                    colorOverlays={
                      s.instrumentsToShowRanges
                        .map(ins => {
                          if (ins.midiNumberIsInRange(number))
                            return ins.appearance.color ?? fg10;
                          return null;
                        })
                        .filter(i => i) as string[]
                    }
                  />
                </li>
              );
            })}
          </ul>
        );
      },
      get instrumentsToShowRanges() {
        return SETTINGS.composer.instruments.showRangeOnKeyboard
          .map(name =>
            ENSEMBLE._instrumentArray.find(ins => ins.meta.name === name)
          )
          .filter(i => i) as Instrument[];
      },
    }));

    const style = useStyle(() => ({
      get container(): CSSPartial {
        return {
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          userSelect: "none",
          minHeight: 162,
          backgroundColor: cVar(VAR_PianoKeyboardBackground),
          transition: "background-color .5s",
          perspective: 1000,
          overflow: "auto",
          ...hideScrollbars(),
          "&:after": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: 20,
            backgroundImage:
              "linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0))",
            pointerEvents: "none",
            zIndex: 2,
            opacity: cVar(VAR_PianoKeyboardTopShaderOpacity),
            transition: "opacity .5s",
          },
          ...p.overrideStyles?.container,
        };
      },
      get keyboard(): CSSPartial {
        return {
          ...flex({ align: "stretch" }),
          ...resetListStyle(),
          flex: "1 1 100%",
          paddingBottom: 1,
          transition: "background-color .5s",
          ...scrollable,
          ...p.overrideStyles?.inner,
          "> li": {
            boxSizing: "border-box",
            "&.halfStep": {
              flex: "0 0 0",
            },
            "&.natural": {
              flex: s.naturalKeyWidth
                ? `1 0 ${s.naturalKeyWidth}px`
                : "1 1 auto",
            },
          },
        };
      },
      get pianoKey(): CSSPartial {
        return {
          position: "relative",
          height: "100%",
          paddingLeft: 0.5,
          paddingRight: 0.5,
          boxSizing: "border-box",
          transform: "translate3d(0,0,1px)",
          span: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            backgroundColor: p.keyColors?.naturals ?? cVar(VAR_PianoKeyColor),
            ".quillIsActive &, .playingFullTrack &": {
              backgroundColor:
                p.keyColors?.naturalsWhenPlaying ??
                p.keyColors?.naturals ??
                cVar(VAR_PianoKeyColor),
            },
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3,
            boxSizing: "border-box",
            minWidth: 24,
            width: s.naturalKeyWidth ? "100%" : undefined,
            maxWidth: 72,
            height: "100%",
            transformOrigin: "top",
            transition: "background-color .5s, filter .05s",
            "&:hover": {
              filter: cVar(VAR_PianoKeyHoverFilter),
            },
            ...(p.overrideStyles?.keys?.natural || {}),
          },
          "&.halfStep": {
            zIndex: 2,
            paddingLeft: 0,
            paddingRight: 0,
            transform: "translate3d(0,0,2px)",
            span: {
              backgroundColor: cVar(VAR_PianoKeyHalfStepColor),
              borderLeft: cVar(VAR_PianoKeyHalfStepBorder),
              borderRight: cVar(VAR_PianoKeyHalfStepBorder),
              borderBottom: cVar(VAR_PianoKeyHalfStepBorder),
              "html[data-theme='dark'] .quillIsActive &, html[data-theme='dark'] .playingFullTrack &":
                {
                  backgroundColor:
                    p.keyColors?.halfStepsWhenPlaying ??
                    p.keyColors?.halfSteps ??
                    THEME.isDarkTheme
                      ? THEME.blendWithBackgroundColor(
                          saturate(0.2, THEME.fg),
                          0.95
                        )
                      : cVar(VAR_ForegroundDarken),
                },
              position: "absolute",
              top: 0,
              left: "0%",
              transform: "translate(-50%)",
              minWidth: 12,
              width: s.naturalKeyWidth
                ? Math.floor(s.naturalKeyWidth * 0.5)
                : "61.8%",
              maxWidth: 64,
              height: "61.8%",
              borderBottomLeftRadius: 2,
              borderBottomRightRadius: 2,
              boxShadow: cVar(VAR_PianoKeyboardHalfStepKeyShadow),
              "html[data-theme='dark'] &": {
                ".PianoKey__colorOverlayLayer": {
                  opacity: 0.3,
                },
              },
              ...(p.overrideStyles?.keys?.halfStep || {}),
            },
          },
          "&[data-in-range=false]": {
            "html[data-theme='light'] &": {
              filter: "brightness(.9)",
            },
            "html[data-theme='dark'] &": {
              filter: "brightness(.5)",
            },
          },
        };
      },
    }));

    useOnMount(() => {
      if (IS_EMBEDDED) return;
      ref.current
        ?.querySelector('li[data-music-key="60"]')
        ?.scrollIntoView({ block: "center", inline: "center" });
    });

    return (
      <Observer
        children={() => (
          <div
            className={cx("PianoKeyboard", p.className)}
            css={style.container}
            onMouseLeave={s.handleKeyboardLeave}
            children={s.keyboard}
            ref={ref}
            data-key-width={s.naturalKeyWidth}
          />
        )}
      />
    );
  }
);

export interface PianoKeyProps {
  className?: string;
  number: number;
  activated?: boolean;
  inRange?: boolean;
  onKeyEnter: (number: number, event: React.MouseEvent) => void;
  onKeyLeave: (number: number, event: React.MouseEvent) => void;
  onKeyDown: (
    number: number,
    event: React.MouseEvent | React.TouchEvent
  ) => void;
  onKeyUp: (number: number, event: React.MouseEvent | React.TouchEvent) => void;
  colorOverlays: string[];
}

const pianoKeyStyles = {
  middleCMarkerStyle: {
    display: "inline-block",
    marginBottom: 5,
    textAlign: "center",
    fontSize: 0,
    color: "transparent",
    width: 5,
    height: 5,
    backgroundColor: fg30,
    borderRadius: "50%",
  } as CSSPartial,
  colorOverlayLayer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "0 0 3px 3px",
    display: "block",
    opacity: 0.1,
    zIndex: 2,
  } as CSSPartial,
};

const PianoKey: React.FC<PianoKeyProps> = React.memo(function PianoKey(props) {
  const { THEME } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    isTouchPlay: false,
    get isMiddleC(): boolean {
      return p.number === 60;
    },
    handleKeyDown: (e: React.MouseEvent) => {
      if (s.isTouchPlay) return;
      p.onKeyDown(p.number, e);
    },
    handleKeyUp: (e: React.MouseEvent) => {
      if (s.isTouchPlay) return;
      p.onKeyUp(p.number, e);
    },
    handleTouchStart: (e: React.TouchEvent) => {
      s.isTouchPlay = true;
      p.onKeyDown(p.number, e);
    },
    handleTouchEnd: (e: React.TouchEvent) => {
      p.onKeyUp(p.number, e);
      s.isTouchPlay = true;
    },
    handleKeyEnter: (e: React.MouseEvent) => p.onKeyEnter(p.number, e),
    handleKeyLeave: (e: React.MouseEvent) => p.onKeyLeave(p.number, e),
    get highlighter() {
      return (
        <PlayStateHighlighter
          respondToMidiNumber={p.number}
          highlightNotesAtCurrentCursor
          highlightQuillNote
          baseOpacityWhenOn={THEME.isDarkTheme ? 0.75 : 0.9}
        />
      );
    },
  }));

  return (
    <Observer>
      {() => (
        <div className={cx("PianoKey", p.className)} data-in-range={p.inRange}>
          <span
            onMouseDown={s.handleKeyDown}
            onMouseUp={s.handleKeyUp}
            onMouseEnter={s.handleKeyEnter}
            onMouseLeave={s.handleKeyLeave}
            onTouchStart={s.handleTouchStart}
            onTouchEnd={s.handleTouchEnd}
            onTouchCancel={s.handleTouchEnd}
            data-music-key={`${p.number}`}
          >
            {s.isMiddleC && <em css={pianoKeyStyles.middleCMarkerStyle}>C</em>}
            {s.highlighter}
            {p.colorOverlays.map((color, i) => (
              <em
                className="PianoKey__colorOverlayLayer"
                css={pianoKeyStyles.colorOverlayLayer}
                style={{ backgroundColor: color }}
                key={i}
              />
            ))}
          </span>
        </div>
      )}
    </Observer>
  );
});

export default PianoKeyboard;
