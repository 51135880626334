import { makeIconDef } from "./makeIconDef";

export const PencilIconDef = {
  pencil: makeIconDef("pencil", {
    regular: () => (
      <path
        d="M14.5 6.5L4.79289 16.2071C4.60536 16.3946 4.5 16.649 4.5 16.9142V20.5H8.08579C8.351 20.5 8.60536 20.3946 8.79289 20.2071L20 9C21.1046 7.89543 21.1046 6.10457 20 5V5C18.8954 3.89543 17.1046 3.89543 16 5L14.5 6.5ZM14.5 6.5L17 9"
        stroke="currentColor"
      />
    ),
  }),
};
