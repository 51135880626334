import { action, observable, runInAction, toJS, when } from "mobx";
import { io } from "socket.io-client";
import { diff } from "../base/utils/diff.utils";
import { isUnauthorizedError } from "../base/utils/errors.utils";
import {
  getMinimizedCompositionSnapshot,
  minimizeInterpretationSnapshot,
} from "../base/utils/snapshot.utils";
import { API_HOST } from "../env";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { isDevelopment } from "../base/env";

type ServerMessage = {
  type: "announcement";
  data: {
    heading: string;
    body: string;
  };
};

export const makeSyncController = () => {
  const userNamespace = `${API_HOST}/user`;

  const socket = io(userNamespace, {
    path: "/sync",
    // transports: ['websocket'],
  });

  const s = observable({
    ...makeControllerBase("SYNC"),
    socket,
    hasChanges: false,
    isSyncing: false,
    error: null as Error | null,
    socketId: "",
    markAsHasChanges: () => {
      s.hasChanges = true;
    },
    markAsHasNoChanges: () => {
      s.hasChanges = false;
    },
    get online(): boolean {
      return !!s.ROOT?.NETWORK.online;
    },
    get status() {
      if (!s.online) return "disconnected";
      if (s.error) return "error";
      if (s.isSyncing) return "syncing";
      if (s.hasChanges) return "has-changes";
      return "synced";
    },
    tryLoginBeforeSync: () => {
      s.ROOT?.AUTH.showQuickLoginDialog(() => {
        s.sync("session-timed-out-login-success-retry-sync");
      });
    },
    calculateChanges: () => {
      const { instance: composer } = s.ROOT!.COMPOSER;
      if (!composer) return;
      console.time("calculateChanges");
      const result = {
        composition: diff(
          getMinimizedCompositionSnapshot(composer.composition.$prev),
          getMinimizedCompositionSnapshot(composer.composition.$)
        ),
        interpretations: composer.composition.interpretations.map(intp => {
          return diff(
            minimizeInterpretationSnapshot(intp.$prev),
            minimizeInterpretationSnapshot(intp.$)
          );
        }),
        scores: composer.composition.scores.map(scr => {
          return diff(toJS(scr.$prev), toJS(scr.$));
        }),
      };
      console.timeEnd("calculateChanges");
      // console.info(result);
      return result;
    },
    sync: async (debugHandle: string) => {
      if (!s.online) return;
      if (!s.ROOT?.AUTH.isAuthenticated) return;
      if (s.ROOT.COMPOSER.instance?.readonly) return;
      await when(() => !s.isSyncing);
      runInAction(() => {
        s.error = null;
        s.isSyncing = true;
      });
      // console.info(s.calculateChanges());
      // if (isDevelopment) console.info("🔄 SYNC:", debugHandle);
      try {
        await s.ROOT?.COMPOSER.instance?.saveSelfAndRelatedRecords(debugHandle);
        s.markAsHasNoChanges();
      } catch (e) {
        runInAction(() => {
          s.error = e as Error;
        });
        if (isUnauthorizedError(e)) {
          s.tryLoginBeforeSync();
        }
      } finally {
        runInAction(() => {
          s.isSyncing = false;
        });
      }
    },
    disconnect: () => {
      socket.disconnect();
    },
  });

  socket.on("message", (event: ServerMessage) => {
    if (isDevelopment)
      console.info(`[SYNC] Received message with args:`, event);
    const { type, data } = event;
    switch (type) {
      case "announcement":
        s.ROOT?.DIALOGS.present({
          Heading: data.heading,
          Body: data.body,
        });
    }
  });

  s.init = makeRootControllerChildInitFn(
    s,
    action(() => {
      s.ready = true;
      socket.on("connect", () => {
        s.ROOT?.API.getInfo();
      });
      window.addEventListener("beforeunload", () => {
        s.disconnect();
      });
    })
  );

  return s;
};

export type SyncController = ReturnType<typeof makeSyncController>;
