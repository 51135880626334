import styled from "@emotion/styled";
import { round } from "lodash-es";

const Svg = styled.svg`
  transform: translateY(-0.5em);
  margin-left: 0.075em;
  opacity: 0.7;
  max-width: ${round(6 / 14, 2)}em;
  height: auto;
`;

export const ExternalLinkIcon = () => (
  <Svg width="6" height="6" viewBox="0 0 6 6" fill="none" stroke="currentColor">
    <path d="M1 1H5V5" />
    <path d="M1 5L5 1" />
  </Svg>
);
