/** @jsxImportSource @emotion/react */
import * as React from "react";
import { CSSPartial } from "../../../base/@types/css.types";
import FormLabel from "../../../base/components/FormLabel";
import Selector from "../../../base/components/Selector/Selector";
import SelectOptionVoice from "../../../base/components/Selector/SelectOptionVoice";
import Spacing from "../../../base/components/Spacing";
import { useProps, useStore } from "../../../base/utils/mobx.utils";
import { capitalize } from "../../../base/utils/string.utils";
import { PolySynthType } from "../PolySynth.instrument";
import EnvelopeVisualizer from "../../../components/composer/EnvelopeVisualizer";
import DraggableNumberInput from "../../../base/components/DraggableNumberInput";
import styled from "@emotion/styled";
import { useThemeController } from "../../../base/hooks/theme.hooks";
import { withOpacity } from "../../../base/utils/colors.utils";
import { UNITS } from "../../../base/constants/units.constant";
import { useComposer } from "../../../components/composer/ComposerApp.context";

interface P {
  instrument: PolySynthType;
}

const availableOscillatorTypes = [
  "sawtooth",
  "sine",
  "square",
  "triangle",
] as const;

const EnvelopeTable = styled.table`
  th {
    text-align: left;
  }
`;

const EnvelopeEditorWrap = styled.div<{ color: string }>`
  padding: 0.5em;
  background-color: ${p => withOpacity(p.color, 0.05)};
  border: ${UNITS.lineWidth}px solid ${p => withOpacity(p.color, 0.2)};
`;

const PolySynthControlPanel: React.FC<P> = props => {
  const THEME = useThemeController();
  const I = useComposer();
  const p = useProps(props);
  const s = useStore(() => ({
    get options() {
      return availableOscillatorTypes.map(type => ({
        Label: capitalize(type),
        value: type,
      }));
    },
  }));
  const styles = useStore(() => ({
    get container(): CSSPartial {
      return {
        padding: ".5em 1em 1em",
      };
    },
    get keyboard(): CSSPartial {
      return {};
    },
  }));

  return (
    <div className="PolySynthControlPanel" css={styles.container}>
      <FormLabel bold>Oscillator type</FormLabel>
      <Spacing size=".5em" />
      <Selector
        form={p.instrument.options.oscillator}
        field="type"
        options={s.options}
        optionRenderer={SelectOptionVoice}
        taskName={`Change "${p.instrument.nickName}" oscillator type`}
        mergeableId={`instrument-change-oscillator-${p.instrument._id}`}
        fullWidth
        disabled={I.editDisabled}
      />
      <Spacing />
      <FormLabel bold>Envelope</FormLabel>
      <Spacing size=".5em" />
      <EnvelopeEditorWrap
        color={p.instrument.appearance.color ?? THEME.primary}
      >
        <EnvelopeVisualizer
          envelope={p.instrument.options.envelope}
          color={p.instrument.appearance.color}
        />
        <Spacing size=".5em" />
        <EnvelopeTable>
          <tbody>
            <tr>
              <th>Attack</th>
              <th>Decay</th>
              <th>Sustain</th>
              <th>Release</th>
            </tr>
            <tr>
              <td>
                <DraggableNumberInput
                  form={p.instrument.options.envelope}
                  field="attack"
                  min={0}
                  step={0.01}
                  deltaScalar={0.01}
                  taskName={`Change "${p.instrument.nickName}" attack value`}
                  mergeableId={`instrument-change-attack-${p.instrument._id}`}
                  disabled={I.editDisabled}
                />
              </td>
              <td>
                <DraggableNumberInput
                  form={p.instrument.options.envelope}
                  field="decay"
                  min={0}
                  step={0.01}
                  deltaScalar={0.01}
                  taskName={`Change "${p.instrument.nickName}" decay value`}
                  mergeableId={`instrument-change-decay-${p.instrument._id}`}
                  disabled={I.editDisabled}
                />
              </td>
              <td>
                <DraggableNumberInput
                  form={p.instrument.options.envelope}
                  field="sustain"
                  min={0}
                  step={0.01}
                  deltaScalar={0.01}
                  max={1}
                  taskName={`Change "${p.instrument.nickName}" sustain value`}
                  mergeableId={`instrument-change-sustain-${p.instrument._id}`}
                  disabled={I.editDisabled}
                />
              </td>
              <td>
                <DraggableNumberInput
                  form={p.instrument.options.envelope}
                  field="release"
                  min={0}
                  step={0.01}
                  deltaScalar={0.01}
                  taskName={`Change "${p.instrument.nickName}" release value`}
                  mergeableId={`instrument-change-release-${p.instrument._id}`}
                  disabled={I.editDisabled}
                />
              </td>
            </tr>
          </tbody>
        </EnvelopeTable>
      </EnvelopeEditorWrap>
    </div>
  );
};

export default PolySynthControlPanel;
