/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Observer } from "mobx-react-lite";
import React from "react";
import Button from "../../base/components/Button";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { makeBooleanState } from "../../base/hooks/useBooleanState.hooks";
import { useStore } from "../../base/utils/mobx.utils";
import InterpretationAddInstrumentPanel from "../panels/InterpretationAddInstrumentPanel";
import { useComposer } from "./ComposerApp.context";

type AddInstrumentButtonProps = {
  fullWidth?: boolean;
  align?: "start" | "end" | "center";
};

const AddInstrumentButton: React.FC<AddInstrumentButtonProps> = props => {
  const { PORTALS } = useControllers();
  const I = useComposer();
  const s = useStore(() => ({
    shouldShowInstrumentPickerPanel: makeBooleanState(false),
    addInstrument: () => {
      s.shouldShowInstrumentPickerPanel.toggle();
    },
  }));
  return (
    <Observer
      children={() => (
        <>
          <Button
            onClick={s.addInstrument}
            fullWidth={props.fullWidth}
            disabled={!I.interpreter}
            align={props.align}
            css={css`
              height: 3.8rem;
            `}
          >
            + Add instruments
          </Button>

          {I.interpreter &&
            s.shouldShowInstrumentPickerPanel.value &&
            PORTALS.render(
              <InterpretationAddInstrumentPanel
                interpretation={I.interpreter.interpretation}
                onShouldClose={s.shouldShowInstrumentPickerPanel.toggle}
              />
            )}
        </>
      )}
    />
  );
};

export default AddInstrumentButton;
