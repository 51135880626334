import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { isString } from "./typeChecks.utils";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(duration);

if (process.env.NODE_ENV === "development") {
  Reflect.set(window, "dayjs", dayjs);
}

export const getTime = () => new Date().getTime();

export type Timestamp = string | null;
export type ValidTime = string | Date;

export const YYYYMMDD = "YYYY-MM-DD";
export const MMMMDYYYY = "MMMM D YYYY";
export const HHmmss = "HH:mm:ss";
export const YYYYMMDDHHmmss = `${YYYYMMDD} ${HHmmss}`;

export const isYYYYMMDDHHmmss = (string: unknown) =>
  isString(string) &&
  /^\d{4}-\d{2}-\d{2}(?:\s\d{2}:\d{2}:\d{2})?$/.exec(string);

export const getNowInMilliseconds = () => new Date().getTime();
export const getNowInSeconds = () => new Date().getTime() / 1000;

export const getNowTimestampUtc = () => dayjs.utc().toISOString();

export const toTimestampUtc = (time: ValidTime) =>
  dayjs(time).utc().toISOString();
