/** @jsxImportSource @emotion/react */
import React from "react";

type IconNewComposition27Props = {};

const IconNewComposition27: React.FC<IconNewComposition27Props> = props => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6V11H8V6H13V5H8V0H7V5H2V6H7ZM13.772 18.1823L13.7801 18.1847C14.6808 18.4514 15.1311 18.5848 15.2292 18.5875C15.7035 18.6007 15.8527 18.4893 15.9747 18.0308C15.9995 17.938 16 17.624 16 17V11V9V8L16 6L16 5.5H16.5C18.5 7.5 20.5 8 24 8L25 8V11L19 11H18.999C17.8954 11.0005 17.0009 11.8949 17 12.9984C17 12.9989 17 12.9995 17 13V17V22.5C17 24.9853 14.9853 27 12.5 27C10.0147 27 8 24.9853 8 22.5C8 20.0147 10.0147 18 12.5 18C12.669 18 12.8358 18.0093 13 18.0275C13.2649 18.0568 13.523 18.109 13.772 18.1823Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconNewComposition27;
