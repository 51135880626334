import * as React from "react";
import { AlignIconDefs } from "./Align.icons";
import { ArrowIconDef } from "./Arrow.icon";
import { ArrowCursorIconDef } from "./ArrowCursor.icons";
import { AtomTreeIconDef } from "./AtomTree.icon";
import { BackIconDef } from "./Back.icon";
import { BlankIconDef } from "./Blank.icon";
import { BlocksIconDef } from "./Blocks.icon";
import { BookIconDef } from "./Book.icon";
import { BoundingBoxIconDef } from "./BoundingBox.icon";
import { CDIconDef } from "./CD.icon";
import { CheckIconDef } from "./Check.icon";
import { ClapperboardIconDef } from "./Clapperboard.icon";
import { ClefIconDefs } from "./Clefs.icon";
import { CloseIconDef } from "./Close.icon";
import { CommentIconDef } from "./Comment.icon";
import { CopyIconDef } from "./Copy.icon";
import { CropIconDef } from "./Crop.icon";
import { DeleteIconDef } from "./Delete.icon";
import { DropdownArrowIconDef } from "./DropdownArrow.icon";
import { EnterIconDef } from "./Enter.icon";
import { FastForwardIconDef } from "./FastForward.icon";
import { FocusIconDef } from "./Focus.icon";
import { GramophoneIconDef } from "./Gramophone.icons";
import { GrandPianoIconDef } from "./GrandPiano.icon";
import { GroupIconDef } from "./Group.icon";
import { HandIconDef } from "./Hand.icon";
import { HarpsichordIconDef } from "./Harpsichord.icon";
import { KeyboardIconDef } from "./Keyboard.icon";
import { HistoryIconDefs } from "./History.icon";
import { HomeIconDef } from "./Home.icon";
import { ImportIconDef } from "./Import.icon";
import { InfoIconDef } from "./Info.icon";
import { InterpretationsIconDef } from "./Interpretations.iconDef";
import { KeyframeIconDef } from "./Keyframe.icon";
import { LibraryIconDef } from "./Library.icon";
import { LightbulbIconDef } from "./Lightbulb.iconDef";
import { LineIconDef } from "./Line.icon";
import { LoopIconDef } from "./Loop.icon";
import { MenuIconDef } from "./Menu.icon";
import { MetronomeIconDefs } from "./Metronome.icons";
import { MidiConnectorIconDef } from "./MidiConnector";
import { MinusIconDef } from "./Minus.icon";
import { MoonIconDef } from "./Moon.icons";
import { MoreIconDef } from "./More.icon";
import { NoteIconDefs } from "./Note.icons";
import { NotificationIconDef } from "./Notification.icon";
import { OrganIconDef } from "./Organ.icon";
import { PatternIconDef } from "./Pattern.icon";
import { PauseIconDef } from "./Pause.icons";
import { PencilIconDef } from "./Pencil.icon";
import { PeopleIconDef } from "./People.icon";
import { PersonIconDef } from "./Person.icon";
import { PianoKeyboardIconsDef } from "./PianoKeyboard.icons";
import { PlayIconDef } from "./Play.icons";
import { PlusIconDef } from "./Plus.icon";
import { QuillIconDef } from "./Quill.icon";
import { RainbowIconDef } from "./Rainbow.icon";
import { RecordIconDef } from "./Record.icon";
import { RecorderIconDef } from "./Recorder.icons";
import { RedoIconDef } from "./Redo.icon";
import { ReverbIconDef } from "./Reverb.icon";
import { RewindIconDef } from "./Rewind.icon";
import { ScissorsIconDef } from "./Scissors.icon";
import { ScoreIconDef } from "./Score.icon";
import { SearchIconDef } from "./Search.icon";
import { SelectionIconDef } from "./Selection.icon";
import { SettingsIconDef } from "./Settings.icon";
import { ShareIconDef } from "./Share.icon";
import { SparklesIconDef } from "./Sparkles.icon";
import { SpeakerIconDefs } from "./Speaker.icons";
import { StepsIconDef } from "./Steps.icon";
import { StopIconDef } from "./Stop.icon";
import { SunIconDef } from "./Sun.icon";
import { TextIconDef } from "./Text.icon";
import { TextCursorIconDef } from "./TextCursor.icon";
import { TriangleIconDef } from "./Triangle.icons";
import { UndoIconDef } from "./Undo.icon";
import { VoiceIconDef } from "./Voice.icon";
import { VoicesIconDef } from "./Voices.icon";
import { ComposerEditorIconDef } from "./ComposerEditor.icon";
import { SynthesizerIconDef } from "./Synthesizer.icon";
import { HarpIconDef } from "./Harp.icon";
import { FluteIconDef } from "./Flute.icon";
import { MarimbaIconDef } from "./Marimba.icon";
import { ButterflyIconDef } from "./Butterfly.icon";
import { BookmarksIconDef } from "./Bookmarks.icons";

export type IconDef = {
  regular: () => React.ReactElement;
  colored?: () => React.ReactElement;
};

export type IconVariant = keyof IconDef;

export const IconDefs = {
  ...AlignIconDefs,
  ...ArrowCursorIconDef,
  ...ArrowIconDef,
  ...AtomTreeIconDef,
  ...BackIconDef,
  ...BlankIconDef,
  ...BlocksIconDef,
  ...BookIconDef,
  ...BookmarksIconDef,
  ...BoundingBoxIconDef,
  ...ButterflyIconDef,
  ...CDIconDef,
  ...CheckIconDef,
  ...ClapperboardIconDef,
  ...ClefIconDefs,
  ...CloseIconDef,
  ...CommentIconDef,
  ...ComposerEditorIconDef,
  ...CopyIconDef,
  ...CropIconDef,
  ...DeleteIconDef,
  ...DropdownArrowIconDef,
  ...EnterIconDef,
  ...FastForwardIconDef,
  ...FluteIconDef,
  ...FocusIconDef,
  ...GramophoneIconDef,
  ...GrandPianoIconDef,
  ...GroupIconDef,
  ...HandIconDef,
  ...HarpIconDef,
  ...HarpsichordIconDef,
  ...HistoryIconDefs,
  ...HomeIconDef,
  ...ImportIconDef,
  ...InfoIconDef,
  ...InterpretationsIconDef,
  ...KeyboardIconDef,
  ...KeyframeIconDef,
  ...LibraryIconDef,
  ...LightbulbIconDef,
  ...LineIconDef,
  ...LoopIconDef,
  ...MarimbaIconDef,
  ...MenuIconDef,
  ...MetronomeIconDefs,
  ...MidiConnectorIconDef,
  ...MinusIconDef,
  ...MoonIconDef,
  ...MoreIconDef,
  ...NoteIconDefs,
  ...NotificationIconDef,
  ...OrganIconDef,
  ...PatternIconDef,
  ...PauseIconDef,
  ...PencilIconDef,
  ...PeopleIconDef,
  ...PersonIconDef,
  ...PianoKeyboardIconsDef,
  ...PlayIconDef,
  ...PlusIconDef,
  ...QuillIconDef,
  ...RainbowIconDef,
  ...RecorderIconDef,
  ...RecordIconDef,
  ...RedoIconDef,
  ...ReverbIconDef,
  ...RewindIconDef,
  ...ScissorsIconDef,
  ...ScoreIconDef,
  ...SearchIconDef,
  ...SelectionIconDef,
  ...SettingsIconDef,
  ...ShareIconDef,
  ...SparklesIconDef,
  ...SpeakerIconDefs,
  ...StepsIconDef,
  ...StopIconDef,
  ...SunIconDef,
  ...SynthesizerIconDef,
  ...TextCursorIconDef,
  ...TextIconDef,
  ...TriangleIconDef,
  ...UndoIconDef,
  ...VoiceIconDef,
  ...VoicesIconDef,
} as const;

export type IconName = keyof typeof IconDefs;

export const isValidSymbolIcon = (
  name: string,
  variant: IconVariant
): boolean => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return Boolean(IconDefs[name][variant]);
};
