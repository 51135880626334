///// <reference types="@emotion/react/types/css-prop" />

import "normalize.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import initReactFastClick from "react-fastclick";
import { configureMobx } from "./@configureMobx";
import { initSentry } from "./@initSentry";
import App from "./App";
import { detectBrowsers } from "./base/utils/browsersAndPlatforms.utils";
import { setupGsap } from "./base/utils/gsap.utils";
import { runPolyfills } from "./polyfills";
import { RootContext, RootControllerInstance } from "./RootContext";
import * as serviceWorker from "./serviceWorker";
import { primeAudioContext } from "./utils/primeAudioContext.utils";

configureMobx();
initSentry();
runPolyfills();
initReactFastClick();
setupGsap();
primeAudioContext();
detectBrowsers();

/**
 * Strict mode is runs some hooks twice (useState, useMemo & useReducer).
 * This might in rare occasions cause bugs where copies of objects between the two versions do not link up properly due to where the functions are called.
 * If suspecting that a bug might be related to this, turn it off.
 */

const enableStrictMode = false;

const app = (
  <RootContext.Provider value={RootControllerInstance} children={<App />} />
);

const root = createRoot(document.getElementById("root")!);

root.render(enableStrictMode ? <StrictMode children={app} /> : app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
