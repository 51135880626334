/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { CommonNoteSymbols } from "../../utils/note.utils";

type NoteIconProps = {
  type: CommonNoteSymbols | string;
  dots?: 1 | 2 | 3 | 4 | 5;
};

const NoteIcon: React.FC<NoteIconProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get symbol() {
      const NoteSymbol = Icons[p.type as CommonNoteSymbols];
      return NoteSymbol ? <NoteSymbol /> : null;
    },
  }));
  return (
    <Observer
      children={() => (
        <svg width="12" height="24" viewBox="0 0 12 24">
          {s.symbol}
        </svg>
      )}
    />
  );
};

const Icons = {
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 24C4.65685 24 6 22.6569 6 21C6 19.3431 4.65685 18 3 18C1.34315 18 0 19.3431 0 21C0 22.6569 1.34315 24 3 24ZM4.59008 22.5904C5.27302 21.9074 5.11463 20.6418 4.23631 19.7635C3.35799 18.8851 2.09234 18.7267 1.40941 19.4097C0.726478 20.0926 0.884869 21.3583 1.76319 22.2366C2.64151 23.1149 3.90715 23.2733 4.59008 22.5904Z"
        fill="currentColor"
      />
    </>
  ),
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 21C6 22.6569 4.65685 24 3 24C1.34315 24 0 22.6569 0 21C0 19.3431 1.34315 18 3 18C3.76835 18 4.46924 18.2889 5 18.7639L5 7H6L6 21ZM4.23631 19.7635C5.11463 20.6418 5.27302 21.9074 4.59008 22.5904C3.90715 23.2733 2.64151 23.1149 1.76319 22.2366C0.884869 21.3583 0.726478 20.0926 1.40941 19.4097C2.09234 18.7267 3.35799 18.8851 4.23631 19.7635Z"
        fill="currentColor"
      />
    </>
  ),
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 21C6 22.6569 4.65685 24 3 24C1.34315 24 0 22.6569 0 21C0 19.3431 1.34315 18 3 18C3.76835 18 4.46924 18.2889 5 18.7639L5 7H6L6 21Z"
        fill="currentColor"
      />
    </>
  ),
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7H5.85714L7.85714 9H9H12V10C12 11.1046 11.1046 12 10 12H8C6.89543 12 6 12.8954 6 14V21C6 22.6569 4.65685 24 3 24C1.34315 24 0 22.6569 0 21C0 19.3431 1.34315 18 3 18C3.76835 18 4.46924 18.2889 5 18.7639V12V10V9L5 8L5 7Z"
        fill="currentColor"
      />
    </>
  ),
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85714 7H5L5 8L5 9V10V11V13V15V18.7639C4.46924 18.2889 3.76835 18 3 18C1.34315 18 0 19.3431 0 21C0 22.6569 1.34315 24 3 24C4.65685 24 6 22.6569 6 21V17C6 15.8954 6.89543 15 8 15H10C10.5523 15 11 14.5523 11 14V13H6C6 11.8954 6.89543 11 8 11H11C11.5523 11 12 10.5523 12 10V9H9H7.85714L5.85714 7Z"
        fill="currentColor"
      />
    </>
  ),
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85714 6H5L5 8L5 9V10V11V13V14V16V18.7639C4.46924 18.2889 3.76835 18 3 18C1.34315 18 0 19.3431 0 21C0 22.6569 1.34315 24 3 24C4.65685 24 6 22.6569 6 21V17C6 16.4477 6.44772 16 7 16H9C9.55228 16 10 15.5523 10 15V14H6C6 13.4477 6.44772 13 7 13H10C10.5523 13 11 12.5523 11 12V11H6C6 10.4477 6.44772 10 7 10H11C11.5523 10 12 9.55228 12 9V8H9H7.85714L5.85714 6Z"
        fill="currentColor"
      />
    </>
  ),
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85714 4H5L5 6L5 7V8V9V11V12V14V15V17L5 18.7639C4.46924 18.2889 3.76835 18 3 18C1.34315 18 0 19.3431 0 21C0 22.6569 1.34315 24 3 24C4.65685 24 6 22.6569 6 21L6 18L6 17.9988C6.00065 17.4471 6.44812 17 7 17H10C10.5523 17 11 16.5523 11 16V15H6C6 14.4477 6.44772 14 7 14H11C11.5523 14 12 13.5523 12 13V12H6C6 11.4477 6.44772 11 7 11H10C10.5523 11 11 10.5523 11 10V9H6C6 8.44772 6.44772 8 7 8H11C11.5523 8 12 7.55228 12 7V6H9H7.85714L5.85714 4Z"
        fill="currentColor"
      />
    </>
  ),
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85714 1H5L5 3L5 4V5V6V8V9V11V12V14V15V17L5 18.7639C4.46924 18.2889 3.76835 18 3 18C1.34315 18 0 19.3431 0 21C0 22.6569 1.34315 24 3 24C4.65685 24 6 22.6569 6 21L6 18L6 17.9988C6.00065 17.4471 6.44812 17 7 17H11C11.5523 17 12 16.5523 12 16V15H6L6 14.999C6.00053 14.4472 6.44804 14 7 14H10C10.5523 14 11 13.5523 11 13V12H6L6 11.9991C6.00046 11.4473 6.448 11 7 11H11C11.5523 11 12 10.5523 12 10V9H6C6 8.44772 6.44772 8 7 8H10C10.5523 8 11 7.55228 11 7V6H6C6 5.44772 6.44772 5 7 5H11C11.5523 5 12 4.55228 12 4V3H9H7.85714L5.85714 1Z"
        fill="currentColor"
      />
    </>
  ),
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85714 1H5L5 3L5 4L5 18.7639C4.46924 18.2889 3.76835 18 3 18C1.34315 18 0 19.3431 0 21C0 22.6569 1.34315 24 3 24C4.65685 24 6 22.6569 6 21L6 18L6 17.9991C6.00046 17.4473 6.448 17 7 17H10C10.5523 17 11 16.5523 11 16V15.5H6V15.4993C6.00036 14.9474 6.44794 14.5 7 14.5H11C11.5523 14.5 12 14.0523 12 13.5V13H6V12.9994C6.00031 12.4474 6.44791 12 7 12H10C10.5523 12 11 11.5523 11 11V10.5H6V10.4995C6.00027 9.94744 6.44788 9.5 7 9.5H11C11.5523 9.5 12 9.05229 12 8.5V8H6V7.99954C6.00025 7.44747 6.44787 7 7 7H10C10.5523 7 11 6.55228 11 6V5.5H6V5.49958C6.00023 4.94749 6.44786 4.5 7 4.5H11C11.5523 4.5 12 4.05228 12 3.5V3H9H7.85714L5.85714 1Z"
        fill="currentColor"
      />
    </>
  ),
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85714 0H5L5 1.5L5 3L5 18.7639C4.46924 18.2889 3.76836 18 3 18C1.34315 18 0 19.3431 0 21C0 22.6569 1.34315 24 3 24C4.65685 24 6 22.6569 6 21L6 19.5L6 19.4988C6.00067 18.947 6.44813 18.5 7 18.5H11C11.5523 18.5 12 18.0523 12 17.5V17H6L6 16.9991C6.00048 16.4472 6.44801 16 7 16H10C10.5523 16 11 15.5523 11 15V14.5H6L6 14.4993C6.00039 13.9473 6.44796 13.5 7 13.5H11C11.5523 13.5 12 13.0523 12 12.5V12H6V11.9994C6.00034 11.4474 6.44792 11 7 11H10C10.5523 11 11 10.5523 11 10V9.5H6V9.49944C6.0003 8.94741 6.4479 8.5 7 8.5H11C11.5523 8.5 12 8.05229 12 7.5V7H6V6.99949C6.00028 6.44744 6.44789 6 7 6H10C10.5523 6 11 5.55228 11 5V4.5H6V4.49953C6.00026 3.94746 6.44787 3.5 7 3.5H11C11.5523 3.5 12 3.05228 12 2.5V2H9H7.85714L5.85714 0Z"
        fill="currentColor"
      />
    </>
  ),
  "": () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85714 0H5L5 1.5L5 3L5 18.7639C4.46924 18.2889 3.76836 18 3 18C1.34315 18 0 19.3431 0 21C0 22.6569 1.34315 24 3 24C4.65685 24 6 22.6569 6 21L6 18L6 17.999C6.00053 17.4472 6.44804 17 7 17H10C10.5523 17 11 16.5523 11 16H6L6 15.9992C6.00044 15.4473 6.44798 15 7 15H11C11.5523 15 12 14.5523 12 14H6V13.9993C6.00038 13.4473 6.44795 13 7 13H10C10.5523 13 11 12.5523 11 12H6V11.9994C6.00034 11.4474 6.44792 11 7 11H11C11.5523 11 12 10.5523 12 10H6V9.99943C6.00031 9.44741 6.44791 9 7 9H10C10.5523 9 11 8.55229 11 8H6V7.99947C6.00029 7.44743 6.44789 7 7 7H11C11.5523 7 12 6.55228 12 6H6V5.99951C6.00027 5.44745 6.44788 5 7 5H10C10.5523 5 11 4.55228 11 4H6V3.99953C6.00025 3.44746 6.44787 3 7 3H9H11C11.5523 3 12 2.55228 12 2H9H7.85714L5.85714 0Z"
        fill="currentColor"
      />
    </>
  ),
};

export default NoteIcon;
