/**
 * source:
 * https://github.com/stemkoski/stemkoski.github.com/blob/master/Three.js/js/shaders/VignetteShader.js
 */

export const DefaultVignetteEffectHorizontalValue = false;
export const DefaultVignetteEffectOffset = 0.3;
export const DefaultVignetteEffectDarkness = 0.1;

export const createVignetteShader = (options?: {
  // 0 - 3
  offset?: number;
  // 0 - 3
  darkness?: number;
}) => {
  return {
    uniforms: {
      tDiffuse: { type: "t", value: 0xffffff },
      offset: {
        type: "f",
        value: options?.offset ?? DefaultVignetteEffectOffset,
      },
      darkness: {
        type: "f",
        value: options?.darkness ?? DefaultVignetteEffectDarkness,
      },
    },

    vertexShader: [
      "varying vec2 vUv;",

      "void main() {",

      "vUv = uv;",
      "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

      "}",
    ].join("\n"),

    fragmentShader: [
      "uniform float offset;",
      "uniform float darkness;",

      "uniform sampler2D tDiffuse;",

      "varying vec2 vUv;",

      "void main() {",

      "vec4 color = texture2D(tDiffuse, vUv);",
      "float dist = distance(vUv, vec2(0.5));",
      "color.rgb *= smoothstep(0.8, offset * 0.1, dist * ( darkness + offset ));",
      "gl_FragColor = color;",

      "}",
    ].join("\n"),
  };
};
