/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { action, observable, reaction, runInAction } from "mobx";
import { Observer } from "mobx-react-lite";
import React, {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useContext,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  VAR_BlurBackdropFilter,
  VAR_PanelBackdropFilter,
  VAR_PanelBackground,
  VAR_PanelBorder,
  VAR_PanelBorderlessHeaderBackground,
  VAR_ShadowMedium,
  bg,
  bg50,
  fg02,
  fg05,
  fg10,
} from "../../constants/cssCustomProperties.constants";
import type { WorkspacePanelController } from "../../controllers/composer/workspace.controller";
import { CSSPartial } from "../@types/css.types";
import { UNITS } from "../constants/units.constant";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useControllers } from "../hooks/rootContext.hooks";
import { useCreateResizeQueryWithRef } from "../hooks/useCreateResizeQueryWithRef.hook";
import {
  ObservableRef,
  useObservableRef,
} from "../hooks/useObservableRef.hook";
import { mediaFromTablet } from "../styles/helpers/mediaQueries.styling";
import cx from "../utils/className.utils";
import { withOpacity } from "../utils/colors.utils";
import { cVar } from "../utils/customProperties.utils";
import { makeDisposerController } from "../utils/disposer.utils";
import { hasFocusedInputs } from "../utils/dom.utils";
import { createDraggableHandler } from "../utils/draggable.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import { cssValue } from "../utils/units.utils";
import { runAfter } from "../utils/waiters.utils";
import PanelResizeHandleSet from "./PanelResizeHandleSet";

export type PanelMeasurements = {
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
};

type PanelProps = {
  className?: string;
  id?: string;
  title?: string;
  controller?: WorkspacePanelController;
  onShouldClose?: () => void;
  canEscape?: boolean;
  width?: number;
  minWidth?: string | number;
  maxWidth?: string | number;
  height?: number;
  minHeight?: string | number;
  maxHeight?: string | number;
  borderRadius?: string | number;
  panelPadding?: string | number;
  measurementsGetter?: () => PanelMeasurements;
  fullscreen?: boolean;
  resizeable?: boolean;
  moveable?: boolean;
  layerRef?: ObservableRef<HTMLDivElement>;
  innerRef?: ObservableRef<HTMLDivElement>;
  doNotCloseWhenClickedOnBackdrop?: boolean;
};

export const VAR_PanelPadding = "--PanelPadding";
export const varPanelPadding = (multiplier = 1) => {
  if (multiplier === 1) return cVar(VAR_PanelPadding);
  return `calc(${cVar(VAR_PanelPadding)} * ${multiplier})`;
};
export const PANEL_DRAG_HANDLE = "PANEL_DRAG_HANDLE";

const PanelEnterAnimation = keyframes`
  from {
    transform: scale(.9);
    opacity: 0
  }
  to {
    transform: scale(1);
    opacity: 1
  }
`;

const BackdropEnterAnimation = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const panelStyle = {
  panelWrap: {
    border: cVar(VAR_PanelBorder),
    position: "fixed",
    fontSize: "1.2rem",
    overflow: "auto",
    opacity: 0,
    animation: `${PanelEnterAnimation} .1s .05s cubic-bezier(0,1,.3,1) forwards`,
    backgroundColor: cVar(VAR_PanelBackground),
    boxShadow: cVar(VAR_ShadowMedium),
    backdropFilter: cVar(VAR_PanelBackdropFilter),
    transition: "background-color .3s, filter .3s",
    transform: "translateZ(0)",
    borderRadius: 3,
  } as CSSPartial,
  fullscreen: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  } as CSSPartial,
  backdrop: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: bg50,
    filter: "brightness(0.9)",
    animation: `${BackdropEnterAnimation} .75s`,
  } as CSSPartial,
};

const Panel: React.FC<PropsWithChildren<PanelProps>> = props => {
  const { UI } = useControllers();
  const p = useProps(props);
  const { ref, query } = useCreateResizeQueryWithRef({
    defaultWidth: action(() => p.controller?.params.width ?? p.width ?? 375)(),
  });
  const ownLayerRef = useObservableRef();
  const layerRef = p.layerRef ?? ownLayerRef;

  const s = useStore(() => ({
    measurements:
      p.controller?.params ??
      p.measurementsGetter?.() ??
      observable({
        get x() {
          return UI.appWidth * 0.5 - (query.width ?? 375) / 2;
        },
        get y() {
          return UI.appHeight * 0.5 - (query.height ?? 375) / 2;
        },
        z: 1,
        width: p.width ?? 375,
        height: p.height ?? 0,
      }),
    measurementsOverride: {
      x: 0,
      y: 0,
      z: 0,
      width: 0,
      height: 0,
    },
    get maxHeight() {
      return UI.appHeight - 100;
    },
    get panelDynamicCss(): CSSPartial {
      return {
        position: "relative",
        [VAR_PanelPadding]: "1em",
        ...mediaFromTablet({
          [VAR_PanelPadding]: p.panelPadding,
        }),
      };
    },
    get panelWrapInlineStyle() {
      const width = s.measurements.width + s.measurementsOverride.width;
      const height = s.measurements.height + s.measurementsOverride.height;
      return {
        left: s.measurements.x + s.measurementsOverride.x,
        top: s.measurements.y + s.measurementsOverride.y,
        width: width ? width : "auto",
        height: height ? height : "auto",
        minWidth: p.minWidth,
        maxWidth: p.maxWidth ?? "100vw",
        minHeight: p.minHeight,
        maxHeight: p.maxHeight ?? s.maxHeight,
        borderRadius: p.borderRadius ?? "0",
        zIndex: p.controller
          ? s.measurements.z + s.measurementsOverride.z
          : undefined,
        "--PanelHeaderHeight": `${context.value.state.headerHeight}px`,
      } as CSSProperties;
    },
    get panelInlineStyle(): CSSProperties {
      return {
        width: "100%",
        height: p.controller?.meta.stretchInnerHeight ? "100%" : undefined,
      };
    },
    get x() {
      return p.controller?.params.x ?? query.boundingBox?.x ?? query.x;
    },
    get y() {
      return p.controller?.params.y ?? query.boundingBox?.y ?? query.y;
    },
    get isDroppingOutOnTheLeft() {
      return s.x < -query.width * 0.25;
    },
    get isDroppingOutOnTheRight() {
      return s.x + query.width > UI.appWidth + query.width * 0.125;
    },
    get isDroppingOutOnXAxis() {
      return s.isDroppingOutOnTheLeft || s.isDroppingOutOnTheRight;
    },
    get isDroppingOutAtTheTop() {
      return s.y < -10;
    },
    get isDroppingOutAtTheBottom() {
      return s.y > UI.appHeight - 48;
    },
    get isDroppingOutOnYAxis() {
      return s.isDroppingOutAtTheTop || s.isDroppingOutAtTheBottom;
    },
    get isDroppingOutOfViewport() {
      return s.isDroppingOutOnXAxis || s.isDroppingOutOnYAxis;
    },
    movePanelBackIntoViewport: () => {
      // console.info(
      //   `Moving panel ${p.controller?.meta.name} back into viewport, (was ${s.x}, ${s.y}: )`
      // );
      if (!p.controller) return;
      const newPosition = {
        x: p.controller.params.x,
        y: p.controller.params.y,
      };
      if (s.isDroppingOutOnTheLeft) {
        newPosition.x = 0;
      }
      if (s.isDroppingOutOnTheRight) {
        newPosition.x = UI.appWidth - query.width;
      }
      if (s.isDroppingOutAtTheTop) {
        newPosition.y = 0;
      }
      if (s.isDroppingOutAtTheBottom) {
        newPosition.y = UI.appHeight - query.height;
      }
      Object.assign(p.controller.params, newPosition);
    },
    recenter: () => {
      const { width = 360, height = 360 } = query ?? {};
      s.measurementsOverride.x = window.innerWidth / 2 - width / 2;
      s.measurementsOverride.y = window.innerHeight / 2 - height / 2;
    },
    handlePointerDownCapture: () => {
      p.controller?.moveToTopOfZStack();
    },
    handleResize: (delta: {
      x: number;
      y: number;
      width: number;
      height: number;
    }) => {
      if (p.controller) return;
      // console.info(delta);
    },
    handleReset: (dimension: "width" | "height") => {
      if (p.controller) return;
      // console.info(dimension);
    },
    get panel() {
      return (
        <div
          className={cx("PanelScreenWrapper", !p.fullscreen && "PanelLayer")}
          style={s.panelWrapInlineStyle}
          css={panelStyle.panelWrap}
          ref={ref}
          onPointerDownCapture={s.handlePointerDownCapture}
        >
          <div
            className={cx(
              "Panel",
              p.className,
              (p.controller || p.moveable) && "moveable"
            )}
            css={s.panelDynamicCss}
            style={s.panelInlineStyle}
            ref={p.innerRef}
          >
            {p.children}
            {(p.resizeable || p.controller?.meta.resizeable) && (
              <PanelResizeHandleSet
                controller={p.controller}
                onResize={p.controller ? undefined : s.handleResize}
                onReset={p.controller ? undefined : s.handleReset}
              />
            )}
          </div>
        </div>
      );
    },
    handleBackdropClick: () => {
      if (p.doNotCloseWhenClickedOnBackdrop) return;
      p.onShouldClose?.();
    },
  }));
  const context = useStore(() => ({
    get value() {
      return observable({
        get controller() {
          return p.controller ?? null;
        },
        props: p,
        state: {
          headerHeight: 0,
          get measurementsOverride() {
            return s.measurementsOverride;
          },
        },
      });
    },
  }));
  useHotkeys(
    "escape",
    action(e => {
      e.stopPropagation();
      if (p.canEscape && p.onShouldClose) {
        if (hasFocusedInputs(ref.current)) return;
        if (
          layerRef.current?.nextElementSibling?.className.includes("PanelLayer")
        )
          return;
        p.onShouldClose();
      }
    })
  );
  useOnMount(() => {
    const d = makeDisposerController();
    runAfter(
      action(() => {
        if (s.isDroppingOutOfViewport) s.movePanelBackIntoViewport();
      })
    );
    if (p.controller) {
      runInAction(() => {
        p.controller!.query = query;
      });
    }
    if (!p.fullscreen && p.layerRef) {
      d.add(
        reaction(
          () => ref.current,
          () => {
            if (p.layerRef) p.layerRef.current = ref.current;
          },
          { fireImmediately: true }
        )
      );
    }
    return d.dispose;
  });
  return (
    <Observer
      children={() => (
        <PanelContext.Provider value={context.value}>
          {p.fullscreen ? (
            <div
              className="PanelLayer FullScreenPanelLayer"
              css={panelStyle.fullscreen}
              ref={layerRef}
            >
              <div css={panelStyle.backdrop} onClick={s.handleBackdropClick} />
              {s.panel}
            </div>
          ) : (
            s.panel
          )}
        </PanelContext.Provider>
      )}
    />
  );
};

const PanelContext = React.createContext({
  controller: null as WorkspacePanelController | null,
  props: {} as PanelProps,
  state: {
    headerHeight: 0,
    measurementsOverride: {
      x: 0,
      y: 0,
      z: 0,
      width: 0,
      height: 0,
    },
  },
});
export const usePanelContext = () => useContext(PanelContext);

const panelLayoutWithSidebarStyle = css`
  display: grid;
  &[data-sidebar-position="left"] {
    grid-template-columns: var(--SidebarWidth, 300px) minmax(0, 1fr);
    grid-template-areas: "sidebar main";
    > aside {
      border-right: ${cVar(VAR_PanelBorder)};
    }
  }
  &[data-sidebar-position="right"] {
    grid-template-columns: minmax(0, 1fr) var(--SidebarWidth, 300px);
    grid-template-areas: "main sidebar";
    > aside {
      border-left: ${cVar(VAR_PanelBorder)};
    }
  }
  > div {
    grid-area: main;
  }
  > aside {
    grid-area: sidebar;
  }
`;

export const PanelLayoutWithSidebar = (
  props: PropsWithChildren<{
    Sidebar: ReactNode;
    sidebarPosition?: "left" | "right";
    sidebarWidth?: string | number;
  }>
) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get sidebarPosition() {
      return p.sidebarPosition ?? "left";
    },
    get sidebarWidth() {
      return cssValue(p.sidebarWidth);
    },
  }));
  return (
    <Observer
      children={() => (
        <div
          css={panelLayoutWithSidebarStyle}
          data-sidebar-position={s.sidebarPosition}
          style={{ "--SidebarWidth": s.sidebarWidth } as CSSProperties}
        >
          <div>{p.children}</div>
          <aside>{p.Sidebar}</aside>
        </div>
      )}
    />
  );
};

const panelSidebarStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PanelSidebar = (props: PropsWithChildren<{}>) => {
  return (
    <Observer
      children={() => <div css={panelSidebarStyle}>{props.children}</div>}
    />
  );
};

const PanelHeaderStyle: CSSPartial = {
  display: "flex",
  flex: "0 0 auto",
  alignItems: "center",
  position: "sticky",
  top: 0,
  left: 0,
  padding: varPanelPadding(),
  lineHeight: 1.4,
  transition: "background-color .3s, filter .05s",
  zIndex: 10,
  transform: "translateZ(0)",
  backgroundColor: bg,
  borderBottom: cVar(VAR_PanelBorder),
  boxSizing: "border-box",
  "&.borderless": {
    borderBottom: "none",
    'html[data-theme="dark"] &': {
      background: cVar(VAR_PanelBorderlessHeaderBackground),
    },
    "+ *": {
      paddingTop: 0,
    },
  },
  "&.moveable": {
    // "&:hover": {
    //   filter: "brightness(1.015)",
    // },
  },
};

export const PanelHeader = (
  p: PropsWithChildren<{
    className?: string;
    borderless?: boolean;
    End?: ReactNode;
  }>
) => {
  const panel = usePanelContext();
  const ref = useObservableRef();
  const s = useStore(() => ({
    draggable: createDraggableHandler({
      onMove: ({ deltaX, deltaY }) => {
        if (panel.controller?.params) {
          panel.controller.params.x += deltaX;
          panel.controller.params.y += deltaY;
        } else {
          panel.state.measurementsOverride.x += deltaX;
          panel.state.measurementsOverride.y += deltaY;
        }
      },
    }),
    handleDoubleClick: () => {
      panel.controller?.resetDimensions();
    },
  }));
  useOnMount(() => {
    const d = makeDisposerController();
    runInAction(() => {
      const resizeObserver = new ResizeObserver(entries => {
        setTimeout(
          action(() => {
            panel.state.headerHeight = (
              entries[0].target as HTMLDivElement
            ).offsetHeight;
          })
        );
      });
      if (ref.current) resizeObserver.observe(ref.current);
      d.add(() => {
        resizeObserver.disconnect();
      });
    });
    return d.dispose;
  });
  return (
    <Observer
      children={() => (
        <header
          className={cx(
            p.className,
            PANEL_DRAG_HANDLE,
            (panel.controller || panel.props.moveable) && "moveable",
            p.borderless && "borderless"
          )}
          css={PanelHeaderStyle}
          onPointerDown={s.draggable.handlePointerDown}
          onDoubleClick={s.handleDoubleClick}
          ref={ref}
        >
          <div css={{ flex: "1 1 auto" }}>{p.children}</div>
          {p.End && <div css={{ paddingLeft: "1em" }}>{p.End}</div>}
        </header>
      )}
    />
  );
};

const PanelCloseButtonStyle: CSSPartial = {
  appearance: "none",
  backgroundColor: "transparent",
  padding: 0,
  color: "inherit",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "2.75em",
  height: "2.75em",
  borderRadius: "50%",
  marginTop: "-.5em",
  marginRight: "-.375em",
  marginBottom: "-.5em",
  "&:hover": {
    backgroundColor: fg05,
  },
  "&:active": {
    backgroundColor: fg10,
    "html[data-theme='dark'] &": {
      backgroundColor: fg02,
    },
  },
  svg: {
    display: "block",
  },
};

export const PanelCloseButton = (p: {
  panel?: WorkspacePanelController;
  onClick?: () => void;
}) => {
  const handleClick = action((e: React.MouseEvent) => {
    if (p.panel) {
      p.panel.toggle();
    }
    p.onClick?.();
  });
  return (
    <button type="button" css={PanelCloseButtonStyle} onClick={handleClick}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
          d="M4.185 4.18499L13.815 13.815M13.815 4.18499L4.185 13.815"
          stroke="currentColor"
          strokeWidth={UNITS.lineWidth}
        />
      </svg>
    </button>
  );
};

const panelInnerStyle: CSSPartial = {
  position: "relative",
  transform: "translateZ(0)",
  padding: varPanelPadding(),
  "&:last-child": {
    paddingBottom: varPanelPadding(),
  },
};

export const PanelInner = (
  p: PropsWithChildren<{
    className?: string;
    padding?: string | number;
    stretchHeight?: boolean;
  }>
) => {
  return (
    <Observer
      children={() => (
        <div
          className={p.className}
          css={panelInnerStyle}
          style={{
            padding: p.padding,
            height: p.stretchHeight
              ? `calc(100% - var(--PanelHeaderHeight))`
              : "",
          }}
        >
          {p.children}
        </div>
      )}
    />
  );
};

export const PanelFooter = (
  p: PropsWithChildren<{
    className?: string;
    sticky?: boolean;
    noPaddingTop?: boolean;
  }>
) => {
  const { THEME } = useControllers();
  return (
    <Observer
      children={() => (
        <div
          className={cx("PanelFooter", p.className)}
          css={{
            backgroundColor: withOpacity(THEME.bg, 0.8),
            backdropFilter: cVar(VAR_BlurBackdropFilter),
            padding: varPanelPadding(),
            paddingTop: p.noPaddingTop ? 0 : undefined,
            ...(p.sticky
              ? {
                  position: "sticky",
                  bottom: 0,
                  left: 0,
                  zIndex: 10,
                }
              : {}),
          }}
          children={p.children}
        />
      )}
    />
  );
};

export default Panel;
