import { makeIconDef } from "./makeIconDef";

export const LightbulbIconDef = {
  lightbulb: makeIconDef("lightbulb", {
    regular: () => (
      <>
        <path
          opacity="0.2"
          d="M12.5 5.5C13.0253 5.5 13.5454 5.60346 14.0307 5.80448C14.516 6.0055 14.957 6.30014 15.3284 6.67157C15.6999 7.04301 15.9945 7.48396 16.1955 7.96927C16.3965 8.45457 16.5 8.97471 16.5 9.5"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9.5C18 11.1225 17.2987 12.5798 16.1796 13.5879C15.5432 14.1612 15 15.0166 15 16.0469C15 16.5733 14.5733 17 14.0469 17H14H12.5H11H10.9531C10.4267 17 10 16.5733 10 16.0469C10 15.0166 9.45676 14.1612 8.82038 13.5879C7.70126 12.5798 7 11.1225 7 9.5C7 6.46243 9.46243 4 12.5 4C15.5376 4 18 6.46243 18 9.5ZM10 17.752C9.40331 17.4178 9 16.7794 9 16.0469C9 15.381 8.64582 14.7766 8.15108 14.3309C6.83034 13.1412 6 11.4176 6 9.5C6 5.91015 8.91015 3 12.5 3C16.0899 3 19 5.91015 19 9.5C19 11.4176 18.1697 13.1412 16.8489 14.3309C16.3542 14.7766 16 15.381 16 16.0469C16 16.7794 15.5967 17.4178 15 17.752V18V19.5C15 20.8807 13.8807 22 12.5 22C11.1193 22 10 20.8807 10 19.5V18V17.752ZM11 18V19.5C11 20.3284 11.6716 21 12.5 21C13.3284 21 14 20.3284 14 19.5V18H12.5H11Z"
          fill="currentColor"
        />
      </>
    ),
  }),
};
