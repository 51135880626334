import { action } from "mobx";
import { Note, Ornament, OrnamentSnapshot } from "../@types";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { OrnamentationDef } from "../constants/ornaments.constants";
import { RuleController } from "../logic/interpreterRule.controller";
import { generateOrnamentNoteSnapshots } from "../logic/ornaments/index.ornamentDefs";
import { OrnamentSnapshotFactory } from "../models/atoms/Ornament.model";
import { insertAtomInOrder } from "../logic/atomFactoryMethods";

const debug = false;

export const createOrnament = action(
  (options: {
    forNote: Note;
    def: OrnamentationDef;
    rule?: RuleController | null;
    template?: Ornament | Partial<OrnamentSnapshot>;
  }) => {
    const { forNote, rule, def, template } = options;
    const context = forNote.context;
    if (!context || !def.identifier) return null;
    const itpId =
      (forNote._itpId ||
        (context.composer?.writeOrnamentsTo === "interpretation"
          ? context.interpretation?._id
          : null)) ??
      undefined;
    if (debug)
      console.info(
        `Adding new ${def.displayName} ornament for note ${forNote._id}${
          rule ? ` in rule ${rule._id}` : ""
        } ${template ? `from template:` : ""}`
      );
    if (template) console.info(template);
    const snapshot: OrnamentSnapshot = makeSnapshot(OrnamentSnapshotFactory, {
      parentIds: forNote.parentIds,
      _itpId: itpId,
      ...(template ?? {}),
    });
    if (!snapshot?.ornamentationType) {
      snapshot.ornamentationType = def.identifier;
    }
    snapshot.noteId = forNote._id;
    snapshot.ruleId = rule?._id ?? "";
    snapshot._id = context.getNextNewAtomId();
    if (rule) snapshot._itpId = context.interpretation?._id ?? "";
    context.addAtomSnapshots([snapshot]);
    if (forNote.interpreted.ornament?._itpId) {
      context.removeAtomsAndDescendants([forNote.interpreted.ornament]);
    }
    const newOrnament = context.getAtomById<Ornament>(snapshot._id)!;
    insertAtomInOrder(context.ornaments, newOrnament);
    if (newOrnament) {
      const ruleToWriteTo =
        rule ??
        (itpId
          ? forNote.lastOwnRule ??
            forNote.interpreter?.findOrCreateRuleForAtom(forNote)
          : null);
      if (ruleToWriteTo) {
        ruleToWriteTo.$.properties.ornamentId = newOrnament._id;
      } else {
        options.forNote.$.ornamentId = newOrnament._id;
      }
      if (!newOrnament.refAtom) {
        generateOrnamentNoteSnapshots(forNote, def.identifier).forEach($ =>
          context.createNote({
            ...$,
            parentIds: [newOrnament._id],
            _itpId: newOrnament._itpId,
          })
        );
      }
    }
    return newOrnament;
  }
);
