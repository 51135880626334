import {
  action,
  autorun,
  flow,
  observable,
  reaction,
  runInAction,
  when,
} from "mobx";
import {
  Atom,
  AtomBaseSnapshot,
  AtomContext,
  AtomContextOptions,
  AtomType,
  Bar,
  BarSnapshot,
  BpmChangeKeyframe,
  Chord,
  ChordCreationAutoAlign,
  DeleteBarOptions,
  Group,
  GroupLikeAtom,
  Keyframe,
  KeyframeSnapshot,
  MusicKeyChangeKeyframe,
  MusicScaleChangeKeyframe,
  NK,
  Note,
  NoteSnapshot,
  Ornament,
  OrnamentSnapshot,
  Pattern,
  RemoveAtomsOptions,
  Replica,
  ReplicaSnapshot,
  Section,
  SectionSnapshot,
  SpeedScalarKeyframe,
  SustainPedalKeyframe,
  TextNode,
  TextNodeSnapshot,
  TimeSignature,
  TimedAtom,
  Voice,
} from "../@types";
import {
  addManyToArrayIfNew,
  addOneToArrayIfNew,
  clearArray,
  keepTruthy,
  mergeNumberPairArraysInPlace,
  removeFromArrayById,
  removeManyFromArray,
  removeManyFromArrayById,
  removeOneFromArrayById,
  replaceContents,
  spliceFromArrayById,
  spliceManyFromArray,
} from "../base/utils/array.utils";
import { makeDisposerController } from "../base/utils/disposer.utils";
import { observeChangesToArray } from "../base/utils/observeChanges.util";
import { first, last, uniq } from "../base/utils/ramdaEquivalents.utils";
import { getRandomNumericString } from "../base/utils/random.utils";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { isNumber } from "../base/utils/typeChecks.utils";
import { runAfter } from "../base/utils/waiters.utils";
import { ComposerInstance } from "../components/composer/useCreateComposerInstance";
import { CCanvasState } from "../components/composerCanvas/useMakeComposerCanvasState";
import { RootControllerChildren } from "../controllers/_controller.types";
import { Composition } from "../models/Composition.model";
import { BarSnapshotFactory, makeBar } from "../models/atoms/Bar.model";
import { GroupSnapshotFactory, makeGroup } from "../models/atoms/Group.model";
import { makeVoice } from "../models/atoms/Voice.model";
import { createNote } from "../operations/createNote.operation";
import { repairAtom, repairAtomSnapshot } from "../repairers/atom.repairer";
import {
  findCommonParentsOfAtoms,
  getAtomsAtRoot,
  isAtomModel,
  isBarAtom,
  isChordAtom,
  isGroupAtom,
  isGroupLikeAtom,
  isNoteAtom,
  isNoteOrKeyframeAtom,
  isOrnamentAtom,
  isPatternAtom,
  isReplicaAtom,
  isSectionAtom,
  isVoiceAtom,
} from "../utils/atoms.utils";
import {
  atomSorterByEndX,
  insertAtomInOrder,
  sortAtoms,
} from "./atomFactoryMethods";
import {
  DuplicateAtomsOptions,
  duplicateAtoms,
} from "../operations/duplicateAtoms.operation";
import {
  removeAtoms,
  removeAtomsAndDescendants,
} from "../operations/removeAtoms.operation";
import {
  CreateVoiceOptions,
  createVoice,
} from "../operations/createVoice.operation";
import { AtomOrAtomContextThumbnailRenderer } from "../utils/thumbnails.utils";
import { makePattern } from "../models/atoms/Pattern.model";
import { ChordSnapshotFactory, makeChord } from "../models/atoms/Chord.models";
import { makeNote } from "../models/atoms/Note.model";
import {
  convertAbstractXToDurationInSeconds,
  convertDurationInSecondsToAbstractX,
  getBpxFromTimeSignature,
} from "../utils/beats.utils";
import { MusicKey } from "../constants/musicKeys.constants";
import { MusicScaleName } from "../constants/musicScales.constants";
import { makeKeyframe } from "../models/atoms/Keyframe.model";
import { createKeyframe } from "../operations/createKeyframe.operation";
import { Interpretation } from "../models/Interpretation.model";
import { makeOrnament } from "../models/atoms/Ornament.model";
import { OrnamentationDef } from "../constants/ornaments.constants";
import { createOrnament } from "../operations/createOrnament.operation";
import { RuleController } from "./interpreterRule.controller";
import {
  ScaledTimeMap,
  ScaledXMap,
  SpeedScalarMap,
  TimeMap,
  generateBpmMap,
  generateBpxMap,
  generateMusicKeyMap,
  generateMusicScaleNameMap,
  generateScaledTimeMap,
  generateScaledXMap,
  generateSpeedScalarMap,
  generateTimeMap,
  generateTimeSignatureMap,
  generateXpmMap,
  getScaledXValueFromMap,
} from "../utils/valueAtXMap.utils";
import { makeAtomCategorizer } from "../utils/atomCategorizer.utils";
import { makeTimeSignature44 } from "../constants/timeSignatures.constants";
import { KnownKeyframeControlPath } from "../constants/keyframe.constants";
import { filterKeyframeByControlPath } from "../utils/keyframe.utils";
import { ToolName } from "../tools/ToolName.enum";
import { createTextNode } from "../operations/createTextNode.operation";
import { makeTextNode } from "../models/atoms/TextNode.model";
import { PatternCreationSource, createPattern } from "../utils/patterns.utils";
import { createReplica } from "../utils/replicas.utils";
import { makeReplica } from "../models/atoms/Replica.model";
import { isDevelopment } from "../base/env";
import { createSection } from "../operations/createSection.operation";
import { makeSection } from "../models/atoms/Section.model";
import { isInfinity } from "../base/utils/math.utils";
import { adjustAtomArrayX } from "../operations/adjustAtomX.operation";
import { VisualizerState } from "../components/visualizer/visualizer";
import { getSelectableAtoms } from "../tools/makeSelectTool";

export const makeAtomContextOptions = () => ({
  musicKey: "" as MusicKey | "",
  musicScaleName: MusicScaleName.Ionian,
  timeSignature: null as TimeSignature | null,
});

export const DefaultAtomContextOptions = makeAtomContextOptions();

export const makeAtomContext = (options: {
  atomSnapshotArrayGetter: () => AtomBaseSnapshot[];
  optionsGetter?: () => AtomContextOptions;
  compositionGetter?: () => Composition;
}) => {
  const { atomSnapshotArrayGetter, optionsGetter, compositionGetter } = options;

  const d = makeDisposerController();

  const atomSnapshots: AtomBaseSnapshot[] = observable([]);
  const unsortedAtoms: Atom[] = observable([]);
  const atomsSortedByX: Atom[] = observable([]);
  const atomsSortedByEndX: Atom[] = observable([]);
  const atomMap = new Map<string, Atom>();

  const _ = observable({
    shouldSortXCounter: 0,
    shouldSortEndXCounter: 0,
    snapshotArrayObserversReady: false,
    largestAtomId: 0,
    isDeletingBar: false,
    get lastAtom() {
      switch (ac.composer?.tools.activeTool?.name) {
        case ToolName.Quill:
          return ac.canvas?.quillTool.interactingNote ?? ac.lastNoteByEndX;
        case ToolName.Keyframe:
          return (
            ac.canvas?.keyframeTool.interactingKeyframe ?? ac.lastNoteByEndX
          );
        case ToolName.Record:
          return (
            last(ac.composer.recorders.midi.recordedAtoms) ?? ac.lastNoteByEndX
          );
        default:
          return ac.lastNoteByEndX;
      }
    },
    get lastAtomEndX() {
      return (_.lastAtom as Atom)?.endX !== undefined
        ? (_.lastAtom as Atom).endX ?? 0
        : (_.lastAtom?.x ?? 0) + (_.lastAtom?.width ?? 0);
    },
    get minimumWidthRequired() {
      return Math.max(
        ac.composer?.recorders.midi.isRecording
          ? ac.composer.recorders.midi.currentTimelinePositionAbstractX
          : 0,
        ac.lastNoteOrKeyframeByEndX?.endX ?? 0
      );
    },
    get totalWidthOfAllBars() {
      return ac.bars.reduce((total, bar) => total + bar.width, 0);
    },
    get notEnoughBars() {
      if (!ac.composition?.$.options.automaticallyManageBars) return false;
      return _.totalWidthOfAllBars < _.minimumWidthRequired;
    },
  });

  const categorizerByX = makeAtomCategorizer(
    atomsSortedByX,
    "atoms",
    () => _.snapshotArrayObserversReady
  );
  const categorizerByEndX = makeAtomCategorizer(
    atomsSortedByEndX,
    "atomsSortedByEndX",
    () => _.snapshotArrayObserversReady
  );

  const ac: AtomContext = observable({
    get __isAtomContext() {
      return true;
    },
    id: getRandomNumericString(),
    get ROOT(): RootControllerChildren | undefined {
      return ac.composer?.ROOT;
    },
    ready: false,
    composer: null as ComposerInstance | null,
    get composition() {
      return compositionGetter?.() ?? null;
    },
    get compositionAtomSnapshots() {
      return atomSnapshotArrayGetter();
    },
    get interpretationAtomSnapshots() {
      return ac.interpretation?.$.atomSnapshots ?? [];
    },
    get writeContentToArray() {
      return ac.composer?.writeContentTo === "interpretation"
        ? ac.interpretationAtomSnapshots
        : ac.compositionAtomSnapshots;
    },
    get writeKeyframesToArray() {
      return ac.composer?.writeKeyframesTo === "interpretation"
        ? ac.interpretationAtomSnapshots
        : ac.compositionAtomSnapshots;
    },
    get atomSnapshots() {
      return atomSnapshots;
    },
    get interpreter() {
      return ac.composer?.interpreter ?? null;
    },
    get interpretation() {
      return ac.interpreter?.interpretation ?? null;
    },
    get timeSignature() {
      return (
        ac.composition?.options.timeSignature
          ? [...ac.composition?.options.timeSignature]
          : makeTimeSignature44()
      ) as TimeSignature;
    },
    get bpm() {
      return ac.interpretation?.options.bpm ?? 60;
    },
    get bpx() {
      return getBpxFromTimeSignature(ac.timeSignature);
    },
    get xpm() {
      return ac.bpm / ac.bpx;
    },
    get xpb() {
      return 4 / ac.timeSignature[1];
    },
    get xpsAfterEnd() {
      return (ac.lastBar?.xpm ?? ac.xpm) / 60;
    },
    get leadingBeats() {
      if (ac.composer?.renderer.job) {
        return (
          convertDurationInSecondsToAbstractX(
            ac.composer.renderer.job.options.leadingSilenceInSeconds,
            ac.xpm
          ) / ac.xpb
        );
      }
      return ac.interpretation?.options.leadingBeatsBeforeStart ?? 0;
    },
    get leadingBeatsWidth() {
      return ac.leadingBeats * ac.xpb;
    },
    get leadingBeatsInSeconds() {
      if (ac.composer?.renderer.job) {
        return ac.composer.renderer.job.options.leadingSilenceInSeconds;
      }
      return (
        convertAbstractXToDurationInSeconds(ac.leadingBeatsWidth, ac.bpm) ?? 0
      );
    },
    get trailingBeats() {
      return ac.trailingBeatsWidth / ac.xpb;
    },
    get trailingBeatsWidth() {
      return convertDurationInSecondsToAbstractX(
        ac.trailingBeatsInSeconds,
        ac.xpm
      );
    },
    get trailingBeatsInSeconds() {
      return ac.composer?.renderer.job?.options.trailingSilenceInSeconds ?? 0;
    },
    get options() {
      return optionsGetter ? optionsGetter() : DefaultAtomContextOptions;
    },
    canvas: null,
    setCanvas: (c: CCanvasState | null) => (ac.canvas = c),
    visualizers: [] as VisualizerState[],
    get unsortedAtoms() {
      return unsortedAtoms;
    },
    markAsShouldResortByX: () => {
      _.shouldSortXCounter++;
    },
    markAsShouldResortByEndX: () => {
      _.shouldSortEndXCounter++;
    },
    get atoms() {
      return atomsSortedByX;
    },
    get atomsSortedByX() {
      return atomsSortedByX;
    },
    get atomsReverseSortedByX() {
      return [...unsortedAtoms].reverse();
    },
    get atomsAtTopLevel() {
      return atomsSortedByX.filter(a => a.parents.length === 0);
    },
    get leafAtoms() {
      return categorizerByX.leafAtoms;
    },
    get notesAndKeyframes() {
      return categorizerByEndX.notesAndKeyframes;
    },

    get isLargeComposition() {
      return ac.notes.length > 2000;
    },

    get duration() {
      if (!ac.durationWithLeading || ac.durationWithLeading < 0) return 0;
      return ac.durationWithLeading - ac.leadingBeatsInSeconds;
    },
    get durationWithLeading() {
      const lastNoteEnd = ac.lastNoteByEndX?.interpreted.timeEndInSeconds ?? 0;
      const lastBarEnd = ac.lastBar?.interpreted.timeEndInSeconds ?? 0;
      return Math.max(lastNoteEnd, lastBarEnd);
    },
    get durationWithLeadingAndTrailingSilence() {
      return ac.durationWithLeading + ac.trailingBeatsInSeconds;
    },

    getAtomById: <T extends Atom = Atom>(id: string | null) =>
      id ? (atomMap.get(id) as T | null) : null,
    getAtomsByIds: <T extends Atom = Atom>(ids?: string[]) =>
      ids ? (ids.map(id => ac.getAtomById<T>(id)).filter(i => i) as T[]) : [],

    getNextNewAtomId(): string {
      _.largestAtomId++;
      const newId = `${_.largestAtomId}`;
      // console.info(`%cNEW ATOM ID USED: ${newId}`, "color: green");
      return newId;
    },

    get atomsSortedByEndX(): Atom[] {
      return atomsSortedByEndX;
    },
    get allSelectableAtoms() {
      return getSelectableAtoms(categorizerByX.selectableAtoms);
    },
    get allSelectableAtomsAtRoot() {
      return getAtomsAtRoot(ac.allSelectableAtoms);
    },
    get musicalAtoms() {
      return categorizerByX.musicalAtoms;
    },
    get musicalAtomsSortedByEndX() {
      return categorizerByEndX.musicalAtoms;
    },

    createNote: (template: Note | Partial<NoteSnapshot>) =>
      createNote(ac, template),

    createChord: (
      atoms: Atom[] = [],
      options?: {
        _id?: string;
        name?: string;
        parentIds?: string[];
        refAtomId?: string | null;
        align?: ChordCreationAutoAlign;
      }
    ) => {
      const commonParents = findCommonParentsOfAtoms(atoms);
      const $ = makeSnapshot(ChordSnapshotFactory, {
        _id: options?._id ?? ac.getNextNewAtomId(),
        name: options?.name,
        parentIds: keepTruthy([
          ...commonParents.map(p => p._id),
          ...(options?.parentIds ?? []),
        ]),
        refAtomId: options?.refAtomId,
      });
      console.info("Creating new chord:", $);
      ac.writeContentToArray.push($);
      const newChord = ac.getAtomById<Chord>($._id)!;
      insertAtomInOrder(ac.chords, newChord);
      atoms.forEach(n => n.addParents(newChord));
      if (options?.align && options.align !== "none") {
        const start = newChord.startX;
        const width = newChord.width;
        newChord.notesSorted.forEach(n => {
          if (options.align === "start" || options.align === "both")
            n.x = start;
          if (options.align === "both") n.width = width;
        });
      }
      return newChord;
    },

    createGroup: (
      atoms: Atom[] = [],
      options?: {
        _id?: string;
        name?: string;
        parentIds?: string[];
        refAtomId?: string | null;
      }
    ) => {
      const commonParents = findCommonParentsOfAtoms(atoms);
      const g = makeSnapshot(GroupSnapshotFactory, {
        _id: options?._id ?? ac.getNextNewAtomId(),
        name: options?.name,
        parentIds: keepTruthy([
          ...commonParents.map(p => p._id),
          ...(options?.parentIds ?? []),
        ]),
        refAtomId: options?.refAtomId,
      });
      // console.info("Adding new group:", g, atoms);
      ac.writeContentToArray.push(g);
      const newGroup = ac.getAtomById<Group>(g._id)!;
      atoms.forEach(n => {
        n.subtractParents(...commonParents);
        n.addParents(newGroup);
      });
      insertAtomInOrder(ac.groups, newGroup);
      insertAtomInOrder(ac.groupsAndPatterns, newGroup);
      insertAtomInOrder(ac.groupsAndPatternsAndReplicas, newGroup);
      return newGroup;
    },

    ungroup: (...atoms: Atom[]) => {
      const groups = atoms.filter(
        n =>
          isGroupAtom(n) ||
          isChordAtom(n) ||
          isOrnamentAtom(n) ||
          isPatternAtom(n) ||
          isReplicaAtom(n)
      ) as GroupLikeAtom[];
      const ungroupedAtoms = [...atoms.filter(n => !isGroupAtom(n))];
      groups.forEach(_g => {
        let group = _g;
        ungroupedAtoms.push(...group.children);
        if (
          isPatternAtom(group) ||
          isReplicaAtom(group) ||
          isChordAtom(group) ||
          isOrnamentAtom(group)
        ) {
          group = ac.convertToGroup(group);
        } else {
          group.children.forEach(c => {
            c.addParents(...group.parents);
            c.subtractParents(group);
          });
        }
        ac.removeAtom(group);
        ac.removeAtom(_g);
      });
      return ungroupedAtoms;
    },

    createKeyframe: (template: Keyframe | Partial<KeyframeSnapshot>) =>
      createKeyframe(ac, template),

    createTextNode: (template: TextNode | Partial<TextNodeSnapshot>) =>
      createTextNode(ac, template),

    createPattern: (source?: PatternCreationSource) =>
      createPattern(ac, source),

    createReplica: (source?: Partial<ReplicaSnapshot>, newId?: string) =>
      createReplica(ac, source, newId),

    createOrnament: (options: {
      forNote: Note;
      rule?: RuleController | null;
      template?: Ornament | Partial<OrnamentSnapshot>;
      def?: OrnamentationDef | null;
    }) =>
      options.def
        ? createOrnament({
            forNote: options.forNote,
            rule: options.rule,
            def: options.def,
            template: options.template,
          })
        : null,

    createVoice: (options?: CreateVoiceOptions) => createVoice(ac, options),
    deleteVoice: (voice: Voice) => {
      const instrumentIds = voice.interpreted.instruments.map(i => i._id);
      voice.childVoices.forEach(v => {
        v.$.parentVoiceId = null;
        if (
          v.rulePropertiesFlattened.instrumentIds?.join("_") !==
          instrumentIds.join("_")
        ) {
          const rule = ac.interpreter?.findOrCreateRuleForAtom(v);
          if (rule) rule.$.properties.instrumentIds = [...instrumentIds];
        }
      });
      ac.removeAtom(voice);
    },
    deleteVoiceAndDescendants: (voice: Voice) => {
      ac.removeAtomsAndDescendants([voice]);
    },

    createBars: (
      template?: Bar | Partial<BarSnapshot> | null,
      atIndex?: number,
      copies?: number
    ) => {
      // eslint-disable-next-line no-console
      if (atIndex) console.log(`Creating bar at index ${atIndex}`);
      const existingBarAtIndex = ac.bars.find(b => b.barIndex === atIndex);
      if (existingBarAtIndex) {
        const targetBarStartX = existingBarAtIndex.startX;
        const allAtomsToAdjust = ac.atomsAtTopLevel.filter(
          a => !isBarAtom(a) && a.startX !== null && a.startX >= targetBarStartX
        );
        adjustAtomArrayX(allAtomsToAdjust, existingBarAtIndex.width);
      }
      const indexOfFirstBarToAdd =
        atIndex ??
        (isNumber(ac.lastBar?.barIndex) ? ac.lastBar!.barIndex + 1 : 0);
      const newBarsSnapshots = Array(copies ?? 1)
        .fill(null)
        .map((n, i) => {
          const nextId = ac.getNextNewAtomId();
          const bar = makeSnapshot(BarSnapshotFactory, {
            ...(isAtomModel(template) ? template.$ : template),
            _id: nextId,
            barIndex: indexOfFirstBarToAdd + i,
          });
          return bar;
        });
      // console.info("Adding new bars:", newBarsSnapshots);
      const existingBars = [...ac.bars];
      ac.writeContentToArray.push(...newBarsSnapshots);
      if (atIndex && last(newBarsSnapshots)) {
        existingBars.forEach(bar => {
          if (bar.barIndex >= last(newBarsSnapshots)!.barIndex) {
            bar.barIndex += copies ?? 1;
          }
        });
      }
      const newBars = ac.getAtomsByIds<Bar>(newBarsSnapshots.map(b => b._id))!;
      addManyToArrayIfNew(ac.bars, newBars);
      // console.info("New Bars", newBars);
      return newBars;
    },

    createSection: (template: Section | Partial<SectionSnapshot>) =>
      createSection(ac, template),

    deleteSection: (section: Section) => {
      ac.removeAtom(section);
    },

    get notes() {
      return categorizerByX.notes;
    },
    get notesUnsorted() {
      return unsortedAtoms.filter(isNoteAtom);
    },
    get notesReversed() {
      return [...ac.notes].reverse();
    },
    get orphanNotes() {
      return ac.notes.filter(n => n.parents.length === 0);
    },
    get notesSortedByEndX() {
      return categorizerByEndX.notes;
    },
    get chords() {
      return categorizerByX.chords;
    },
    get chordsReversed() {
      return [...ac.chords].reverse();
    },
    get groups() {
      return categorizerByX.groups;
    },
    get groupsReversed() {
      return [...ac.groups].reverse();
    },
    get ornaments() {
      return categorizerByX.ornaments;
    },
    get ornamentsReversed() {
      return [...ac.ornaments].reverse();
    },
    get patterns() {
      return categorizerByX.patterns;
    },
    get patternsReversed() {
      return [...ac.patterns].reverse();
    },
    get replicas() {
      return categorizerByX.replicas;
    },
    get replicasReversed() {
      return [...ac.replicas].reverse();
    },
    get groupsAndPatterns() {
      return categorizerByX.groupsAndPatterns;
    },
    get groupsAndPatternsAndReplicas() {
      return categorizerByX.groupsAndPatternsAndReplicas;
    },
    get voices() {
      return categorizerByX.voices;
    },
    get voicesSortedByY() {
      return [...ac.voices].sort(
        (a, b) => a.descendantNotesYMean! - b.descendantNotesYMean! || 0
      );
    },
    get voicesReversed() {
      return [...ac.voices].reverse();
    },
    get topLevelVoices() {
      return ac.voices.filter(v => !v.parentVoice);
    },
    get topLevelVoicesOrdered() {
      return ac.voicesOrdered.filter(v => !v.parentVoice);
    },
    get writableVoices() {
      return ac.voices.filter(v => v.isWritable);
    },
    get voicesOrdered() {
      return [...ac.voices].sort((a, b) => a.order - b.order);
    },
    getVoiceByNumberOrName: (n: number | string) => {
      const voice =
        ac.voices.find(v => {
          return v.name === `${n}` || v.displayName === `${n}`;
        }) ??
        ac.voices[parseInt(`${n}`)] ??
        null;
      return voice;
    },
    get bars() {
      return categorizerByX.bars;
    },
    get barsReversed() {
      return [...ac.bars].reverse();
    },
    get barsSortedByEndX() {
      return categorizerByEndX.bars;
    },
    get firstVoice() {
      return first(ac.voices) ?? null;
    },
    get lastAtomByEndX() {
      return last(ac.atomsSortedByEndX) ?? null;
    },
    get lastNoteByEndX() {
      return last(ac.notesSortedByEndX) ?? null;
    },
    get lastKeyframeByEndX() {
      return last(ac.keyframesSortedByEndX) ?? null;
    },
    get lastNoteOrKeyframeByEndX(): NK | null {
      const toConsider = [
        ac.lastKeyframeByEndX,
        ac.lastNoteByEndX,
        ac.composer?.tools.quill.interactingNote,
        ac.composer?.tools.keyframe.interactingKeyframe,
      ]
        .filter(A => A && A.endX !== null)
        .sort((a, b) => b!.endX! - a!.endX!);
      return first(toConsider) ?? null;
    },
    get lastBar() {
      return last([...ac.bars].sort((a, b) => a.endX - b.endX)) ?? null;
    },
    get keyframes() {
      return categorizerByX.keyframes;
    },
    get keyframesReversed() {
      return [...ac.keyframes].reverse();
    },
    get keyframesSortedByEndX() {
      return categorizerByEndX.keyframes;
    },
    keyframesCategorized: {
      get [KnownKeyframeControlPath.sustainPedal]() {
        return filterKeyframeByControlPath(
          ac.keyframes,
          KnownKeyframeControlPath.sustainPedal
        ) as SustainPedalKeyframe[];
      },
      get [KnownKeyframeControlPath.speedScalar]() {
        return filterKeyframeByControlPath(
          ac.keyframes,
          KnownKeyframeControlPath.speedScalar
        ) as SpeedScalarKeyframe[];
      },
      get [KnownKeyframeControlPath.bpmChange]() {
        return filterKeyframeByControlPath(
          ac.keyframes,
          KnownKeyframeControlPath.bpmChange
        ) as BpmChangeKeyframe[];
      },
      get [KnownKeyframeControlPath.musicKeyChange]() {
        return filterKeyframeByControlPath(
          ac.keyframes,
          KnownKeyframeControlPath.musicKeyChange
        ) as MusicKeyChangeKeyframe[];
      },
      get [KnownKeyframeControlPath.musicScaleChange]() {
        return filterKeyframeByControlPath(
          ac.keyframes,
          KnownKeyframeControlPath.musicScaleChange
        ) as MusicScaleChangeKeyframe[];
      },
    },
    get textNodes() {
      return categorizerByX.textNodes;
    },
    get textNodesReversed() {
      return categorizerByEndX.textNodes;
    },
    get sections() {
      return categorizerByX.sections;
    },
    get sectionsReversed() {
      return categorizerByEndX.sections;
    },
    get hasSections() {
      return ac.sections.length > 0;
    },
    get hasMoreThanOneSection() {
      return ac.sections.length > 1;
    },
    get startX() {
      return 0;
    },
    get endX() {
      return ac.width;
    },
    get width() {
      return Math.max(ac.lastAtomByEndX?.endX ?? 0, ac.lastBar?.endX ?? 0);
    },
    get widthPt() {
      return ac.canvas?.convert.abstractX.toPtX(ac.width) ?? ac.width;
    },
    get height() {
      const topEdge = Math.min(...ac.musicalAtoms.map(n => n.startY || 0));
      const bottomEdge = Math.max(...ac.musicalAtoms.map(n => n.endY || 0));
      const height = bottomEdge - topEdge;
      if (isInfinity(height)) return 0;
      return height;
    },
    get heightPt() {
      return ac.canvas?.convert.abstractY.toPtY(ac.height) ?? ac.height;
    },
    get startY() {
      return Math.min(...ac.notes.map(n => n.y ?? 0));
    },
    get endY() {
      return Math.max(...ac.notes.map(n => n.endY ?? 0));
    },
    get centerY() {
      const centerY = (ac.endY + ac.startY) / 2;
      if (isNaN(centerY)) return 0;
      return centerY;
    },
    get playableNotes() {
      return categorizerByX.playableNotes;
    },
    get playableNotesSortedByEndX() {
      return categorizerByEndX.playableNotes;
    },
    get playableRangeStartX() {
      return Math.min(
        first(ac.playableNotes)?.startX ?? 0,
        first(ac.keyframes)?.startX ?? 0
      );
    },
    get playableRangeEndX() {
      return Math.max(
        last(ac.playableNotesSortedByEndX)?.endX ?? ac.playableRangeStartX,
        last(ac.keyframes)?.endX ?? ac.playableRangeStartX
      );
    },
    get playableRange(): [number, number] {
      return [ac.playableRangeStartX, ac.playableRangeEndX];
    },
    get playableRangePt(): [number, number] {
      const start =
        ac.canvas?.convert.abstractX.toPtWithOffsetLeft(ac.playableRange[0]) ??
        0;
      const end =
        ac.canvas?.convert.abstractX.toPtWithOffsetLeft(ac.playableRange[1]) ??
        0;
      return [start, end];
    },
    get playableWidth() {
      return ac.playableRange[1] - ac.playableRange[0];
    },
    get playableWidthPt() {
      return ac.playableRangePt[1] - ac.playableRangePt[0];
    },
    get playbackRange() {
      return [ac.playbackStartX, ac.playbackEndX] as [number, number];
    },
    get playbackStartX() {
      return ac.startX - ac.leadingBeatsWidth;
    },
    get playbackEndX() {
      return ac.width + ac.trailingBeatsWidth;
    },
    get playbackWidth() {
      return ac.playbackEndX - ac.playbackStartX;
    },
    constructAtom: ($: Partial<AtomBaseSnapshot>): Atom => {
      switch ($.type) {
        case AtomType.note:
          return makeNote($, ac);
        case AtomType.chord:
          return makeChord($, ac);
        case AtomType.group:
          return makeGroup($, ac);
        case AtomType.pattern:
          return makePattern($, ac);
        case AtomType.replica:
          return makeReplica($, ac);
        case AtomType.ornament:
          return makeOrnament($, ac);
        case AtomType.keyframe:
          return makeKeyframe($ as KeyframeSnapshot, ac);
        case AtomType.bar:
          return makeBar($, ac);
        case AtomType.voice:
          return makeVoice($, ac);
        case AtomType.textNode:
          return makeTextNode($ as TextNodeSnapshot, ac);
        case AtomType.section:
          return makeSection($ as SectionSnapshot, ac);
        default:
          throw Error(`Unknown AtomType "${$.type}" found in atom snapshot`);
      }
    },

    pushAtoms: <T extends Atom = Atom>(...newAtoms: T[]) => {
      unsortedAtoms.push(...newAtoms);
      newAtoms.forEach(a => atomMap.set(a._id, a));
    },
    constructAtomsFromSnapshots: <
      T extends Partial<AtomBaseSnapshot> = Partial<AtomBaseSnapshot>
    >(
      snapshots: T[]
    ) => {
      return snapshots.map(snapshot => ac.constructAtom(snapshot));
    },
    moveAtomsToInterpretation: (
      atoms: Atom[],
      int: Interpretation,
      includeAllDescendants = true
    ) => {
      const atomIds = atoms.map(a => a._id);
      const toMove = uniq(
        includeAllDescendants
          ? atoms
              .map(a => (isGroupLikeAtom(a) ? [a, ...a.descendants] : a))
              .flat()
          : atoms
      );
      const snapshots = toMove.map(a => a.$);
      atoms.forEach(a => {
        a._itpId = int._id;
        if (isOrnamentAtom(a)) {
          if (a.note) {
            if (a.note.ornamentId === a._id) a.note.ornamentId = null;
            const rule = a.interpreter?.findOrCreateRuleForAtom(a.note);
            if (rule) rule.properties.ornamentId = a._id;
          }
        }
      });
      removeFromArrayById(ac.interpretationAtomSnapshots, snapshots);
      removeFromArrayById(ac.compositionAtomSnapshots, snapshots);
      addManyToArrayIfNew(int.$.atomSnapshots, snapshots);
      ac.composer?.tools.select.updateSelection({
        debugCaller: "moveAtomsToInterpretation",
        atoms: ac.getAtomsByIds(atomIds),
      });
    },
    moveAtomsToComposition: (atoms: Atom[], includeAllDescendants = true) => {
      const atomIds = atoms.map(a => a._id);
      const toMove = uniq(
        includeAllDescendants
          ? atoms
              .map(a => (isGroupLikeAtom(a) ? [a, ...a.descendants] : a))
              .flat()
          : atoms
      );
      const snapshots = toMove.map(a => a.$);
      ac.composition?.interpretations.forEach(int => {
        removeManyFromArray(int.$.atomSnapshots, snapshots);
      });
      addManyToArrayIfNew(ac.compositionAtomSnapshots, snapshots);
      atoms.forEach(a => {
        a._itpId = "";
        if (isOrnamentAtom(a) && a.note) {
          a.note.ornamentId = a._id;
          a.rules.forEach(r => {
            r.$.properties.ornamentId = null;
          });
        }
      });
      ac.composer?.tools.select.updateSelection({
        debugCaller: "moveAtomsToComposition",
        atoms: ac.getAtomsByIds(atomIds),
      });
    },
    applyAutoSort: () => {
      if (ac.composition)
        replaceContents(
          ac.composition?.$.atomSnapshots,
          atomsSortedByX.map(a => a.$)
        );
      replaceContents(unsortedAtoms, atomsSortedByX);
    },
    cleanUp: {
      zeroWidthNotes: () => {
        const zeroWidthNotes = ac.notes.filter(n => n.width === 0);
        ac.removeAtoms(zeroWidthNotes);
        return zeroWidthNotes.map(n => n._id);
      },
      emptyChords: () => {
        const emptyChords = ac.chords.filter(c => c.isEmpty) ?? [];
        ac.removeAtoms(emptyChords);
        return emptyChords.map(n => n._id);
      },
      emptyPatterns: () => {
        const emptyPatterns = ac.patterns.filter(p => p.isEmpty) ?? [];
        ac.removeAtoms(emptyPatterns);
        return emptyPatterns.map(n => n._id);
      },
      emptyGroups: () => {
        const emptyGroups = ac.groups.filter(g => g.isEmpty) ?? [];
        ac.removeAtoms(emptyGroups);
        return emptyGroups.map(n => n._id);
      },
      emptyVoices: () => {
        const emptyVoices = ac.voices.filter(v => v.isEmpty) ?? [];
        for (const voice of emptyVoices) {
          ac.deleteVoice(voice);
        }
        return emptyVoices.map(n => n._id);
      },
      trailingBars: () => {
        if (!ac.lastAtomByEndX?.endX) return [];
        const trailingBars = ac.bars.filter(
          bar => bar.startX >= ac.lastAtomByEndX!.endX!
        );
        ac.removeAtoms(trailingBars);
        return trailingBars.map(n => n._id);
      },
      all: () => {
        const results = {
          zeroWidthNotes: ac.cleanUp.zeroWidthNotes(),
          emptyChords: ac.cleanUp.emptyChords(),
          emptyPatterns: ac.cleanUp.emptyPatterns(),
          emptyGroups: ac.cleanUp.emptyGroups(),
          emptyVoices: ac.cleanUp.emptyVoices(),
          trailingBars: ac.cleanUp.trailingBars(),
        };
        const allAtomIdsCleanedUp = Object.values(results).flat();
        console.info(
          `Cleaned up ${
            allAtomIdsCleanedUp.length
          } atoms: ${allAtomIdsCleanedUp.join(", ")}`
        );
        return allAtomIdsCleanedUp;
      },
    },
    addAtomSnapshots: <T extends AtomBaseSnapshot = AtomBaseSnapshot>(
      atomSnapshots: T[]
    ) => {
      atomSnapshots.forEach(a => {
        if (a._itpId) {
          if (ac.interpretation?._id === a._itpId) {
            addOneToArrayIfNew(ac.interpretationAtomSnapshots, a);
          } else {
            ac.composition?.interpretations
              .find(i => i._id === a._itpId)
              ?.$.atomSnapshots.push(a);
          }
        } else addOneToArrayIfNew(ac.compositionAtomSnapshots, a);
      });
    },
    removeAtomSnapshots: <T extends AtomBaseSnapshot = AtomBaseSnapshot>(
      atomSnapshots: T[]
    ) => {
      atomSnapshots.forEach(a => {
        if (a._itpId) removeOneFromArrayById(ac.interpretationAtomSnapshots, a);
        else removeOneFromArrayById(ac.compositionAtomSnapshots, a);
      });
    },
    removeAtoms: <T extends Atom = Atom>(
      atoms: T[],
      options?: RemoveAtomsOptions
    ) => {
      // if (atoms.length)
      //   console.info(
      //     "removing atoms",
      //     atoms.map(a => a._id)
      //   );
      if (atoms.length === 0) return;
      return removeAtoms(atoms, "context.removeAtoms", options);
    },
    removeAtomsByIds: (ids: string[], options?: RemoveAtomsOptions) => {
      const atoms = ac.getAtomsByIds(ids);
      ac.removeAtoms(atoms, options);
    },
    removeAtom: <T extends Atom = Atom>(
      atom: T,
      options?: RemoveAtomsOptions
    ) => ac.removeAtoms([atom], options),
    removeAtomById: (id: string, options?: RemoveAtomsOptions) => {
      const atom = ac.getAtomById(id);
      if (atom) ac.removeAtom(atom);
    },
    removeAtomsAndDescendants: <T extends Atom = Atom>(
      atoms: T[],
      options?: RemoveAtomsOptions
    ) => {
      if (atoms.length === 0) return;
      // console.info("atoms to remove (with descendants):", atoms);
      removeAtomsAndDescendants(atoms, options);
    },
    duplicateAtoms: (options: DuplicateAtomsOptions) => {
      const { atoms } = options;
      if (atoms.length === 0) return atoms;
      return duplicateAtoms(options);
    },
    deleteBar: (
      barToDelete: Bar,
      options?: DeleteBarOptions,
      isBatchDeletion?: boolean
    ) => {
      if (!isBatchDeletion) _.isDeletingBar = true;
      const nextBar = barToDelete.nextBar;
      const barEndX = barToDelete.endX;
      const barWidth = barToDelete.width;
      const barAtoms = [...barToDelete.atoms];
      if (barToDelete.isStartOfSection) {
        if (nextBar) {
          barToDelete.isStartOfSection.definedStartingBar = nextBar;
        } else {
          ac.removeAtom(barToDelete.isStartOfSection);
        }
      }
      ac.bars.forEach(b => {
        if (b.barIndex > barToDelete.barIndex) {
          b.barIndex -= 1;
        }
      });
      ac.removeAtom(barToDelete);
      removeOneFromArrayById(ac.bars, barToDelete);
      if (options?.deleteContents) {
        const allAtomsAfterTheBar = ac.atoms.filter(
          a => a.startX !== null && a.startX >= barEndX
        );
        ac.removeAtoms(barAtoms.filter(a => !isVoiceAtom(a)));
        allAtomsAfterTheBar.forEach(a => {
          if (isVoiceAtom(a) || isBarAtom(a) || isSectionAtom(a)) return;
          if (isNoteOrKeyframeAtom(a) || isPatternAtom(a) || isReplicaAtom(a)) {
            if (a.$.x !== null) a.$.x -= barWidth;
          }
        });
      }
      if (!isBatchDeletion)
        setTimeout(
          action(() => {
            _.isDeletingBar = false;
          })
        );
    },
    deleteBars: (bars: Bar[], options?: DeleteBarOptions) => {
      _.isDeletingBar = true;
      bars.forEach(b => ac.deleteBar(b, options));
      setTimeout(
        action(() => {
          _.isDeletingBar = false;
        })
      );
    },
    convertToGroup: (source: Pattern | Replica | Chord | Ornament) => {
      // console.info(`Converting ${source.displayName} to group...`);
      const { children } = source;
      if (isPatternAtom(source)) {
        source.replicas.forEach(rep => ac.convertToGroup(rep));
      }
      children.forEach(c => {
        if (c.refAtom) {
          c.$.x = c.x;
          c.$.y = c.y;
          c.$.width = c.width;
          c.$.height = c.height;
          if (isNoteAtom(c)) {
            c.$.velocity = c.velocity;
          }
        }
      });
      if (isOrnamentAtom(source) && source.note) {
        ac.removeAtom(source.note);
      }
      source.markAsDeleted();
      ac.interpreter?.deleteRules(source.ownRules);
      children.forEach(c => {
        c.subtractParents(source);
        c.$.refAtomId = null;
      });
      const group = ac.createGroup(children);
      if (source._itpId) {
        removeFromArrayById(ac.interpretationAtomSnapshots, [source]);
      } else {
        removeFromArrayById(ac.compositionAtomSnapshots, [source]);
      }
      return group;
    },
    selectAll: (type: AtomType) => {
      const atoms = ac[`${type}s`];
      ac.composer?.tools.select.updateSelection({
        debugCaller: `atomContext.selectAll(${type})`,
        atoms,
      });
      return atoms;
    },
    getOverlappingAtomsAtX: <T extends Atom = Atom>(
      x: number,
      _atoms?: T[]
    ) => {
      const atoms = _atoms ?? (ac.atoms as T[]);
      const result = new Set<Atom>();
      for (const atom of atoms) {
        if (atom.startX === null || atom.endX === null) continue;
        if (atom.startX <= x && atom.endX > x) result.add(atom);
        if (atom.startX > x) break;
      }
      return Array.from(result).sort(
        (a, b) => (a.width ?? 0) - (b.width ?? 0)
      ) as T[];
    },
    getOverlappingInterpretedAtomsAtX: <T extends TimedAtom = TimedAtom>(
      x: number,
      _atoms?: T[]
    ) => {
      const atoms = _atoms ?? (ac.atoms as T[]);
      const result = new Set<TimedAtom>();
      for (const atom of atoms) {
        if (isNoteAtom(atom) && atom.interpreted.ornament) continue;
        if (atom.interpreted.startX === null || atom.interpreted.endX === null)
          continue;
        if (atom.interpreted.startX <= x && atom.interpreted.endX > x)
          result.add(atom);
        if (atom.interpreted.startX > x) break;
      }
      return Array.from(result).sort(
        (a, b) => (a.interpreted.width ?? 0) - (b.interpreted.width ?? 0)
      ) as T[];
    },
    getOverlappingAtomsInXSpan: <T extends Atom = Atom>(
      x1: number,
      x2: number,
      _atoms?: T[]
    ) => {
      const atoms = _atoms ?? (ac.atoms as T[]);
      const result = new Set<Atom>();
      for (const atom of atoms) {
        if (atom.startX === null || atom.endX === null) continue;
        if (atom.startX < x2 && atom.endX > x1) result.add(atom);
        if (atom.startX >= x2) break;
      }
      return Array.from(result).sort(
        (a, b) => (a.width ?? 0) - (b.width ?? 0)
      ) as T[];
    },
    valueAtXMaps: observable({
      get timeSignature() {
        return generateTimeSignatureMap(ac);
      },
      get bpm() {
        return generateBpmMap(ac);
      },
      get bpx() {
        return generateBpxMap(ac);
      },
      get xpm() {
        return generateXpmMap(ac);
      },
      time: [] as TimeMap,
      speedScalar: [] as SpeedScalarMap,
      scaledX: [] as ScaledXMap,
      scaledTime: [] as ScaledTimeMap,
      get musicKey() {
        return generateMusicKeyMap(ac);
      },
      get musicScaleName() {
        return generateMusicScaleNameMap(ac);
      },
    }),
    getTime: (x: number) => {
      return getScaledXValueFromMap(
        x,
        ac.valueAtXMaps.time,
        1 / ac.xpsAfterEnd
      );
    },
    getScaledTime: (x: number) => {
      return getScaledXValueFromMap(
        x,
        ac.valueAtXMaps.scaledTime,
        1 / ac.xpsAfterEnd
      );
    },
    lastEditedNote: null,
    lastEditedKeyframe: null,
    lastEditedTextNode: null,
    thumbnailRenderer: null as AtomOrAtomContextThumbnailRenderer | null,
    dispose: () => {
      d.dispose();
    },
  });

  const init = action(() => {
    d.add(
      // if the array itself is replaced
      reaction(
        () => ac.compositionAtomSnapshots,
        (newArray, oldArray) => {
          spliceManyFromArray(atomSnapshots, oldArray, newArray);
        },
        { fireImmediately: true }
      )
    );
    d.add(
      observeChangesToArray(ac.compositionAtomSnapshots, {
        splice: (added, removed) => {
          removeFromArrayById(unsortedAtoms, removed);
          spliceFromArrayById(atomSnapshots, removed, added);
        },
      })
    );
    let disposeIntpSnapshotObserver: (() => void) | undefined;
    d.add(
      reaction(
        // if the array itself is replaced
        () => ac.interpretationAtomSnapshots,
        (newArray, oldArray) => {
          spliceManyFromArray(atomSnapshots, oldArray, newArray);
          disposeIntpSnapshotObserver?.();
          disposeIntpSnapshotObserver = observeChangesToArray(
            ac.interpretationAtomSnapshots,
            {
              splice: (added, removed) => {
                removeFromArrayById(unsortedAtoms, removed);
                spliceFromArrayById(atomSnapshots, removed, added);
              },
            }
          );
        },
        { fireImmediately: true }
      )
    );
    d.add(() => {
      disposeIntpSnapshotObserver?.();
    });
    d.add(
      autorun(() => {
        mergeNumberPairArraysInPlace(
          ac.valueAtXMaps.speedScalar,
          ac.composer?.recorders.speedMap.isRecording
            ? []
            : generateSpeedScalarMap(ac)
        );
      })
    );
    d.add(
      autorun(() => {
        mergeNumberPairArraysInPlace(
          ac.valueAtXMaps.scaledX,
          generateScaledXMap(ac)
        );
      })
    );
    d.add(
      autorun(() => {
        mergeNumberPairArraysInPlace(
          ac.valueAtXMaps.scaledX,
          generateScaledXMap(ac)
        );
      })
    );
    d.add(
      autorun(() => {
        mergeNumberPairArraysInPlace(ac.valueAtXMaps.time, generateTimeMap(ac));
      })
    );
    d.add(
      autorun(() => {
        mergeNumberPairArraysInPlace(
          ac.valueAtXMaps.scaledTime,
          generateScaledTimeMap(ac)
        );
      })
    );

    removeManyFromArrayById(
      ac.compositionAtomSnapshots,
      ac.interpretationAtomSnapshots
    );

    atomSnapshots.forEach(n => repairAtomSnapshot(n, ac));

    ac.addAtomSnapshots(atomSnapshots);

    _.largestAtomId = Math.max(
      ...[
        ...options.atomSnapshotArrayGetter(),
        ...(ac.composition?.interpretations.map(
          int => int.$.atomSnapshots ?? []
        ) ?? []),
      ]
        .flat()
        .map(n => +n._id),
      0
    );

    d.add(
      observeChangesToArray(atomSnapshots, {
        splice: async (added, removed) => {
          const actuallyRemoved = removed.filter(r => !added.includes(r));
          // console.log(
          //   `atomSnapshots detected ${actuallyRemoved.length} removed atoms:`,
          //   actuallyRemoved.map(a => `${a.type}#${a._id}`)
          // );

          const actuallyAdded = added.filter(a => !removed.includes(a));
          // console.log(
          //   `atomSnapshots detected ${actuallyAdded.length} added atoms:`,
          //   actuallyAdded.map(a => `${a.type}#${a._id}`)
          // );

          if (ac.composer?.recorders.midi.isRecording)
            await when(() => !ac.composer?.recorders.midi.isRecording);

          const atomsToRemove = keepTruthy(
            actuallyRemoved.map(a => atomMap.get(a._id))
          ) as Atom[];
          if (atomsToRemove.length > 0)
            ac.composer?.tools.select.updateSelection({
              atoms: atomsToRemove,
              mode: "subtract",
              debugCaller: "atomSnapshotsObserver",
            });
          actuallyRemoved.forEach(atom => {
            atomMap.get(atom._id)?.dispose();
            atomMap.delete(atom._id);
            if (ac.ready) {
              if (!atom._itpId || atom._itpId === ac.interpretation?._id) {
                ac.composer?.queue.recordAtomChangeBuffer("removed", atom);
              }
            }
          });

          if (ac.ready) {
            actuallyAdded.forEach(atom => {
              ac.composer?.queue.recordAtomChangeBuffer("added", atom);
            });
          }
          const newAtoms = ac.constructAtomsFromSnapshots(actuallyAdded);
          newAtoms.forEach(atom => atomMap.set(atom._id, atom));
          spliceFromArrayById(unsortedAtoms, actuallyRemoved, newAtoms);
        },
      })
    );

    const sortByStartX = action(() => {
      replaceContents(atomsSortedByX, sortAtoms(unsortedAtoms));
      // console.log("did resort X");
    });

    d.add(
      reaction(
        () => _.shouldSortXCounter,
        flow(function* () {
          // console.log("resort X queued");
          if (ac.composer?.queue.hasActivePriorityTasks)
            yield when(() => !!ac.composer?.queue.hasNoActivePriorityTasks);
          sortByStartX();
        }),
        { fireImmediately: true, delay: 200 }
      )
    );

    const sortByEndX = action(() => {
      replaceContents(
        atomsSortedByEndX,
        sortAtoms(unsortedAtoms, atomSorterByEndX)
      );
      // console.log("did resort endX");
    });

    d.add(
      reaction(
        () => _.shouldSortEndXCounter,
        flow(function* () {
          // console.log("resort endX queued");
          if (ac.composer?.queue.hasActivePriorityTasks)
            yield when(() => !!ac.composer?.queue.hasNoActivePriorityTasks);
          sortByEndX();
        }),
        { fireImmediately: true, delay: 200 }
      )
    );

    // if necessary, repair all atoms after they have been initiated
    unsortedAtoms.forEach(n => repairAtom(n, ac));

    runAfter(() => {
      runInAction(() => {
        _.snapshotArrayObserversReady = true;
      });
    });

    when(
      () =>
        !!(
          categorizerByX.ready &&
          categorizerByEndX.ready &&
          (ac.composer?.withEditorUI
            ? ac.canvas?.uiInitialRenderComplete
            : true)
        ),
      flow(function* () {
        ac.ready = true;

        sortByStartX();
        sortByEndX();

        const disposerForEnsuringMinimumOneVoiceAndOneBar = reaction(
          () => `${ac.voices.length}_${ac.bars.length}`,
          () => {
            const fn = () => {
              if (ac.voices.length < 1) {
                const voice = ac.createVoice({
                  name: "1",
                  atoms: ac.musicalAtoms,
                  color: ac.ROOT!.THEME.primary,
                });
                ac.composer?.setWriteToVoiceAs(
                  voice,
                  "autoSetOnlyVoiceJustCreated"
                );
              }
              if (ac.bars.length < 1) {
                ac.createBars();
              }
            };
            if (ac.voices.length === 0 || ac.bars.length === 0) {
              ac.composer?.queue.ignoreInHistory(
                "Auto-creation of first voice / bar",
                fn
              );
            } else {
              fn();
            }
          },
          { fireImmediately: true }
        );
        d.add(disposerForEnsuringMinimumOneVoiceAndOneBar);

        if (ac.composer?.withEditorUI) {
          d.add(
            reaction(
              () => _.notEnoughBars,
              function replenishBars() {
                if (_.notEnoughBars) {
                  if (_.isDeletingBar) return;
                  // console.info(ac.bars.map(b => `${b._id}@${b.x}`).join(", "));
                  console.info(
                    `not enough bars to cover the notes, width needed: ${_.minimumWidthRequired}, current width: ${ac.lastBar?.endX}`
                  );
                  const { barNumber, ...template } = ac.lastBar?.$ ?? {};
                  const newBarWidth = ac.lastBar?.width ?? 4;
                  const minExtraWidthNeeded =
                    _.minimumWidthRequired - (ac.lastBar?.endX ?? 0);
                  const numberOfBarsToAdd = Math.ceil(
                    minExtraWidthNeeded / newBarWidth
                  );
                  // console.info(
                  //   `automatically adding ${autoPluralizeWithNumber(
                  //     numberOfBarsToAdd,
                  //     "bar"
                  //   )}`
                  // );
                  ac.createBars(template, undefined, numberOfBarsToAdd);
                }
              },
              { fireImmediately: true }
            )
          );
        }
      })
    );

    return ac;
  });

  when(() => !!ac.ROOT, init);

  d.add(
    action(() => {
      categorizerByX.dispose();
      categorizerByEndX.dispose();
      unsortedAtoms.forEach(a => a.dispose());
      clearArray(unsortedAtoms);
      clearArray(atomsSortedByX);
      clearArray(atomsSortedByEndX);
      atomMap.clear();
      ac.composer = null;
      if (ac.composition) {
        ac.composition.$.atomSnapshots = [];
        ac.composition.atomContext = null;
      }
    })
  );

  if (isDevelopment) {
    Reflect.set(window, "ac", ac);
  }

  return ac;
};

export const findAtom = <T extends Atom>(
  array: T[],
  matcher: (n: T) => boolean
) => {
  return array.find(matcher) ?? null;
};

export const isAtomContext = (n: unknown): n is AtomContext =>
  Boolean(n && (n as AtomContext).__isAtomContext);
