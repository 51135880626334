import { action } from "mobx";
import { Atom } from "../@types";
import { isNonVoiceGroupLikeAtom } from "./atoms.utils";

export const makeCCanvasAtomId = (canvasId: string | undefined, atom: Atom) =>
  `canvas#${canvasId}_${atom.type}#${atom._id}`;

export const makeCCanvasAtomIdSelector = (
  canvasId: string | undefined,
  atom: Atom
) => `[data-id="canvas#${canvasId}_${atom.type}#${atom._id}"]`;

export const setAtomElementSelectionState = action(
  (atom: Atom, newState: boolean) => {
    atom._isSelected = newState;
    if (isNonVoiceGroupLikeAtom(atom)) {
      setAtomElementsSelectionState(atom.children, newState);
    }
  }
);

export const setAtomElementsSelectionState = (
  atoms: Atom[],
  newState: boolean
) => {
  atoms.forEach(atom => {
    setAtomElementSelectionState(atom, newState);
  });
};

export const getCCanvasElementAtomId = (element: SVGElement) => {
  let el = element;
  while (
    !el.getAttribute?.("data-atom-id") &&
    (el.parentElement as unknown as SVGElement) &&
    (el.parentElement as unknown as SVGElement)?.tagName !== "svg"
  ) {
    el = el.parentElement as unknown as SVGElement;
  }
  return el.getAttribute?.("data-atom-id") ?? "";
};
