import { observable } from "mobx";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "../_root.controller";
import { convertRecordMapToArray } from "../../base/utils/map.utils";
import { ExportJsonSchema, Package } from "../../models/Package.model";
import { ApiController } from "../api.controller";
import { LocalDBController } from "../localDB.controller";
import { ModelName } from "../../constants/modelName.constants";
import { removeOneFromArray } from "../../base/utils/array.utils";
import { makeSlug } from "../../base/utils/slug.utils";
import saveAs from "file-saver";
import { uniq } from "lodash-es";
import { Collection } from "../../models/Collection.model";

function getSecretParam() {
  const params = new URLSearchParams(window.location.search);
  return params.get("secret");
}

export const makePackagesController = () => {
  const s = observable({
    ...makeControllerBase("PACKAGES"),
    get API(): ApiController {
      return s.ROOT!.API;
    },
    get LOCALDB(): LocalDBController {
      return s.ROOT!.LOCALDB;
    },
    get all(): Package[] {
      return convertRecordMapToArray(s.LOCALDB.data.packages);
    },

    get: async (id: string) => {
      const secret = getSecretParam();
      return await s.API.get<Package>(
        `/packages/${id}${secret ? `?secret=${secret}` : ""}`,
        ModelName.packages
      );
    },

    saveNameChange: async (pkg: Package) => {
      return await s.API.patch(
        `/packages/${pkg._id}/update-name`,
        ModelName.packages,
        {
          _id: pkg._id,
          name: pkg.$.name,
        }
      );
    },
    delete: async (pkg: Package) => {
      if (pkg.composition)
        removeOneFromArray(pkg.composition.$.packageIds, pkg._id);
      const name = pkg.displayName;
      await s.API.delete(`/packages/${pkg._id}`, ModelName.packages);
      s.ROOT?.STATUS.displayMessage(`Deleted package “${name}”.`);
    },

    toggleReadAccess: async (pkg: Package) => {
      return await s.API.post<Package>(
        `/packages/${pkg._id}/make-${
          pkg.$.readAccess === "public" ? "private" : "public"
        }`,
        ModelName.packages
      );
    },

    export: async (pkg: Package) => {
      const response = await s.API.postRaw<{
        data: ExportJsonSchema;
      }>(`/packages/${pkg._id}/export`, ModelName.packages, {
        explicitlyAllowInReadonlyMode: true,
      });
      const slug = makeSlug(response.data.composition.title);
      saveAs(
        new Blob([JSON.stringify(response.data)], {
          type: "application/javascript;charset=utf-8",
        }),
        `${slug}-${response.data.timeCreated}.json`
      );
      return response.data;
    },

    loadContentsIntoLocalDB: async (pkg: Package) => {
      if (!pkg.contents) {
        await s.get(pkg._id);
      }
      if (pkg.contents) {
        s.LOCALDB.setOrMerge(ModelName.compositions, pkg.contents.composition);
        pkg.contents.interpretations.forEach(json =>
          s.LOCALDB.setOrMerge(ModelName.interpretations, json)
        );
        pkg.contents.scores.forEach(json =>
          s.LOCALDB.setOrMerge(ModelName.scores, json)
        );
        pkg.contents.artists.forEach(json => {
          s.LOCALDB.setOrMerge(ModelName.artists, json);
        });
        pkg.contents.arrangers.forEach(json => {
          s.LOCALDB.setOrMerge(ModelName.artists, json);
        });
        pkg.contents.collections.forEach(json => {
          s.LOCALDB.setOrMerge<Collection>(ModelName.collections, {
            ...json,
            compositionIds: uniq(
              [
                ...(json.compositionIds ?? []),
                pkg.contents?.composition?._id,
              ].filter(i => i)
            ) as string[],
          });
        });
      } else {
        throw Error(
          `Failed to load composition version “${pkg.displayName}” (#${pkg._id}).`
        );
      }
    },
  });

  s.init = makeRootControllerChildInitFn(s, () => {});

  return s;
};

export type PackagesController = ReturnType<typeof makePackagesController>;
