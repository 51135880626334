import React from "react";
import { ValidPoint, ValidRect } from "../@types";
import { subtractPoints } from "./geometry.utils";

export const detectTouchSupport = () =>
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  Reflect.get(navigator, "msMaxTouchPoints") > 0 ||
  document.documentElement.classList.contains("touch");

export const detectAndWatchForTouchSupport = () => {
  const onDetectingTouch = () => {
    document.documentElement.classList.add("touch");
  };
  if (detectTouchSupport()) {
    onDetectingTouch();
    return () => {};
  }
  window.addEventListener("touchstart", onDetectingTouch, { once: true });
  return () => {
    window.removeEventListener("touchstart", onDetectingTouch);
  };
};

export const isTouchEvent = (e: unknown): e is TouchEvent | React.TouchEvent =>
  "touches" in (e as TouchEvent | React.TouchEvent);

export const isMultiFingerTouchEvent = (e: unknown) =>
  isTouchEvent(e) && e.touches.length > 1;

export type iOSTouch = Touch & {
  touchType: string;
};

export const isApplePencilTouch = (e: Touch): e is iOSTouch =>
  (e as iOSTouch).touchType === "stylus";

export const touchEventContainAsApplePencilTouches = (
  e: unknown
): e is TouchEvent =>
  isTouchEvent(e) && getTouchArrayFromEvent(e).some(isApplePencilTouch);

export const getTouchArrayFromEvent = (e: TouchEvent | React.TouchEvent) =>
  Array(e.touches.length)
    .fill(null)
    .map((n, i) => e.touches.item(i))
    .filter(i => i) as Touch[];

export const getAverageXYFromTouchEvent = (e: TouchEvent | React.TouchEvent) =>
  getTouchArrayFromEvent(e).reduce<ValidPoint | null>(
    (prev, touch) => ({
      x: prev ? (prev.x + touch.clientX) / 2 : touch.clientX,
      y: prev ? (prev.y + touch.clientY) / 2 : touch.clientY,
    }),
    null
  ) ?? { x: 0, y: 0 };

export const getXYfromMouseEvent = (e: MouseEvent | React.MouseEvent) => ({
  x: e.clientX,
  y: e.clientY,
});

export const getXYFromMouseOrTouchEvent = (
  e: TouchEvent | React.TouchEvent | MouseEvent | React.MouseEvent
) => (isTouchEvent(e) ? getAverageXYFromTouchEvent(e) : getXYfromMouseEvent(e));

export const getMultiFingerTouchEventRect = (
  e: TouchEvent | React.TouchEvent
): ValidRect => {
  const touches = getTouchArrayFromEvent(e);
  const xArray = touches.map(touch => touch.clientX);
  const yArray = touches.map(touch => touch.clientY);
  return [
    { x: Math.min(...xArray), y: Math.min(...yArray) },
    { x: Math.max(...xArray), y: Math.max(...yArray) },
  ];
};

export const getZoomDeltaFromTouchRects = (
  curr: ValidRect,
  prev: ValidRect
) => {
  const currWidth = curr[1].x - curr[0].x;
  const currHeight = curr[1].y - curr[0].y;
  const prevWidth = prev[1].x - prev[0].x;
  const prevHeight = prev[1].y - prev[0].y;
  const xZoomDelta = (currWidth - prevWidth) / prevWidth;
  const yZoomDelta = (currHeight - prevHeight) / prevHeight;
  return ((xZoomDelta + yZoomDelta) / 2) * 50 * -1;
};

export const getPointerXYFromTouchOrMouseEvent = (
  event: MouseEvent | TouchEvent
) => {
  const source = isTouchEvent(event) ? (event.touches.item(0) as Touch) : event;
  return { x: source.clientX, y: source.clientY };
};

export const pointerHasMoved = (
  eventAtStart: TouchEvent | React.TouchEvent | MouseEvent | React.MouseEvent,
  eventAtEnd: TouchEvent | React.TouchEvent | MouseEvent | React.MouseEvent
) => {
  const isTouch = isTouchEvent(eventAtEnd);
  const xyAtStart = getXYFromMouseOrTouchEvent(eventAtStart);
  const xyAtEnd = getXYFromMouseOrTouchEvent(eventAtEnd);
  const delta = subtractPoints(xyAtEnd, xyAtStart);
  const pointerMovementTolerance = isTouch ? 6 : 0;
  return (
    Math.abs(delta.x) > pointerMovementTolerance ||
    Math.abs(delta.y) > pointerMovementTolerance
  );
};
