import { action, observable } from "mobx";
import { ReactNode } from "react";
import ReactDOM from "react-dom";
import { ObservableRef } from "../base/hooks/useObservableRef.hook";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";

export const makePortalRefRegistryEntry = (
  name: string,
  ref: ObservableRef
) => ({
  name,
  ref,
});

export type PortalRefRegistryEntry = ReturnType<
  typeof makePortalRefRegistryEntry
>;

export const makePortalsController = () => {
  const c = observable({
    ...makeControllerBase("PORTALS"),
    registry: {} as Record<string, PortalRefRegistryEntry>,
    registerRef: (name: string, ref: React.RefObject<HTMLDivElement>) => {
      c.registry[name] = makePortalRefRegistryEntry(name, ref);
      return action(() => {
        Reflect.deleteProperty(c.registry, name);
      });
    },
    render: (reactNode: ReactNode, portalName = "default") => {
      const portalEl = c.registry[portalName]?.ref?.current;
      // console.info(`Rendering content into portal#${portalName}`, portalEl);
      // console.info(reactNode);
      return ReactDOM.createPortal(reactNode, portalEl ?? document.body);
    },
    renderInvisible: (reactNode: ReactNode) => c.render(reactNode, "invisible"),
  });

  c.init = makeRootControllerChildInitFn(c, () => {});

  return c;
};

export type PortalsController = ReturnType<typeof makePortalsController>;
