import { makeIconDef } from "./makeIconDef";

export const NotificationIconDef = {
  notification: makeIconDef("notification", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4C12.7761 4 13 4.22386 13 4.5V5H12.5H12V4.5C12 4.22386 12.2239 4 12.5 4ZM14 5.20703V5V4.5C14 3.67157 13.3284 3 12.5 3C11.6716 3 11 3.67157 11 4.5V5V5.20703C8.6915 5.85996 7 7.98245 7 10.5V14C7 15.1046 6.10457 16 5 16V17V18H6H7H8H17H18H19H20V17V16C18.8954 16 18 15.1046 18 14V10.5C18 7.98245 16.3085 5.85996 14 5.20703ZM12.5 6C10.0147 6 8 8.01472 8 10.5V14C8 15.3062 7.16519 16.4175 6 16.8293V17H7H8H17H18H19V16.8293C17.8348 16.4175 17 15.3062 17 14V10.5C17 8.01472 14.9853 6 12.5 6ZM10.05 19C10.2817 20.1411 11.2906 21 12.5 21C13.7095 21 14.7184 20.1411 14.95 19H10.05Z"
        fill="currentColor"
      />
    ),
  }),
};
