/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import AppPage from "../../components/layout/AppPage";
import Button, { ButtonGroup } from "../../base/components/Button";
import { CSSPartial } from "../../base/@types/css.types";
import { makeBooleanState } from "../../base/hooks/useBooleanState.hooks";
import CollectionAddContentPanel from "../../components/panels/CollectionAddItemPanel";
import CollectionEditorPanel from "../../components/panels/CollectionEditorPanel";
import CompositionListDraggable from "../../components/directory/CompositionListDraggable";
import LineSeparator from "../../base/components/LineSeparator";
import Spacing from "../../base/components/Spacing";
import CollectionPageHeader from "../../components/shared/CollectionPageHeader";
import WithRequest from "../../base/components/ContainerWithRequest";
import {
  moveItemFromIndexToNewIndex,
  removeOneFromArray,
} from "../../base/utils/array.utils";
import { action } from "mobx";

type WorkshopCollectionSinglePageProps = {};

const style = {
  content: {} as CSSPartial,
};

const WorkshopCollectionSinglePage: React.FC<
  WorkshopCollectionSinglePageProps
> = props => {
  const { COLLECTIONS, NAVIGATOR, DIALOGS } = useControllers();
  const params = useParams<{ id: string }>();
  const p = useProps({ ...props, params });
  const s = useStore(() => ({
    get id(): string {
      return p.params.id ?? "";
    },
    get collection() {
      return COLLECTIONS.getFromLocalDB(s.id);
    },
    get collectionName(): string {
      return s.collection?.$.name ?? "Untitled Collection";
    },
    save: async () => {
      if (!s.collection) return;
      await COLLECTIONS.save(s.collection);
    },
    shouldShowCollectionAddContentPanel: makeBooleanState(false),
    shouldShowCollectionEditorPanel: makeBooleanState(false),
    get header() {
      return s.collection ? (
        <CollectionPageHeader
          collection={s.collection}
          End={
            <ButtonGroup>
              {/* <Button minWidth="9em" icon="play" iconVariant="filled" disabled>
                Play
              </Button> */}
              <Button
                minWidth="9em"
                icon="pencil"
                onClick={s.shouldShowCollectionEditorPanel.toggle}
              >
                Edit details
              </Button>
              <Button
                minWidth="9em"
                icon="plus"
                onClick={s.shouldShowCollectionAddContentPanel.toggle}
              >
                Add content
              </Button>
            </ButtonGroup>
          }
        />
      ) : null;
    },
    get list() {
      return s.collection ? (
        <CompositionListDraggable
          compositions={s.collection.compositions}
          EmptyMessage={<p>This collection is empty.</p>}
          mode="editor"
          enableContextMenu
          onDragEnd={action(({ itemIndex, destIndex }) => {
            moveItemFromIndexToNewIndex(
              s.collection!.$.compositionIds,
              itemIndex,
              destIndex
            );
            s.save();
          })}
          contextMenuConfigSetFactory={c => [
            {
              identifier: "remove-from-collection",
              Label: "Remove from collection",
              action: action(() => {
                removeOneFromArray(s.collection!.$.compositionIds, c._id);
                s.save();
              }),
            },
          ]}
        />
      ) : null;
    },
  }));

  return (
    <Observer
      children={() => (
        <AppPage
          className="LibraryCollectionSinglePage"
          title={s.collection ? s.collectionName : "Collection"}
        >
          <WithRequest
            id={["WorkshopCollectionSinglePage", s.id]}
            request={async () =>
              await COLLECTIONS.get(s.id, { includes: ["compositions"] })
            }
            children={() => {
              return s.collection ? (
                <div css={style.content}>
                  {s.header}
                  <LineSeparator margin="3em 0" />
                  {s.list}
                  <Spacing size="2em" />
                  {s.shouldShowCollectionAddContentPanel.value && (
                    <CollectionAddContentPanel
                      collection={s.collection}
                      onShouldClose={
                        s.shouldShowCollectionAddContentPanel.toggle
                      }
                    />
                  )}
                  {s.shouldShowCollectionEditorPanel.value && (
                    <CollectionEditorPanel
                      collection={s.collection}
                      onShouldClose={s.shouldShowCollectionEditorPanel.toggle}
                    />
                  )}
                </div>
              ) : (
                <p>Collection not found.</p>
              );
            }}
          />
        </AppPage>
      )}
    />
  );
};

export default WorkshopCollectionSinglePage;
