/** @jsxImportSource @emotion/react */
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import TextInput from "../../base/components/TextInput";
import Textarea from "../../base/components/Textarea";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Composition } from "../../models/Composition.model";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import LineSeparator from "../../base/components/LineSeparator";
import CompositionArtistsSelector from "../composer/CompositionArtistSelector";
import CompositionCollectionSelector from "../composer/CompositionCollectionSelector";
import MusicKeySelector from "../composer/MusicKeySelector";
import MusicScaleSelector from "../composer/MusicScaleSelector";
import TimeSignatureEditor from "../composer/TimeSignatureEditor";
import { uniq } from "../../base/utils/ramdaEquivalents.utils";
import { keepTruthy } from "../../base/utils/array.utils";
import { autoPluralize, pluralize } from "../../base/utils/string.utils";
import Checkbox from "../../base/components/Checkbox";
import Spacing from "../../base/components/Spacing";
import { useComposer } from "../composer/ComposerApp.context";

type CompositionSetupEditorProps = {
  composition: Composition;
  autoFocus?: boolean;
  metaOnly?: boolean;
};

const style = {
  component: {} as CSSPartial,
  table: {
    width: "100%",
    tableLayout: "auto",
    fontFeatureSettings: "'tnum' 1",
    borderCollapse: "collapse",
    tr: {
      "+ tr": {
        "th, td": {
          // borderTop: cVar(VAR_BorderForeground10),
        },
      },
    },
    "th, td": {
      paddingTop: ".25em",
      paddingBottom: ".25em",
      textAlign: "left",
      verticalAlign: "top",
    },
    th: {
      fontSize: "inherit",
      paddingRight: "1em",
      paddingTop: "1em",
      width: "0",
      whiteSpace: "nowrap",
    },
    td: {
      // width: "62%",
    },
  } as CSSPartial,
};

const CompositionSetupEditor: React.FC<CompositionSetupEditorProps> = props => {
  const { COMPOSITIONS, COLLECTIONS, AUTH } = useControllers();

  const p = useProps(props);

  const I = useComposer();

  const s = useStore(() => ({
    disableSubmit: (e: React.FormEvent) => e.preventDefault(),
    saveComp() {
      COMPOSITIONS.save(p.composition, { metaOnly: true });
    },
    get form() {
      return p.composition.$;
    },
    get artistPreferredTitles() {
      const array = keepTruthy(
        uniq(p.composition.artists.map(artist => artist.title))
      );
      if (array.length === 0) return ["Artist"];
      return array;
    },
    get artistFieldLabel() {
      return autoPluralize(
        p.composition.artists,
        s.artistPreferredTitles.join(" / "),
        s.artistPreferredTitles.map(t => pluralize(t)).join(" / "),
        s.artistPreferredTitles.map(t => pluralize(t)).join(" / ")
      );
    },
  }));
  useOnMount(
    action(() => {
      if (AUTH.isAdmin) COLLECTIONS.getAllAsAdmin();
      else COLLECTIONS.getAllOwn();
    })
  );
  return (
    <Observer
      children={() => (
        <div className="CompositionSetupEditor" css={style.component}>
          {/* {p.composition.atomContext && (
            <AtomMapThumbnailHtmlCanvas
              target={p.composition.atomContext}
              scalarX={10}
              scalarY={2}
              noteHeight={3}
            />
          )} */}
          <form onSubmit={s.disableSubmit}>
            <table css={style.table}>
              <tbody>
                <tr>
                  <th>Title</th>
                  <td>
                    <TextInput
                      form={s.form}
                      field="title"
                      mergeableTaskName="Edit composition title"
                      autoFocus={p.autoFocus}
                      disabled={I.editDisabled}
                    />
                    <Spacing size=".5em" />
                    <Checkbox
                      form={s.form.options}
                      field="renderTitleAsMarkdown"
                      disabled={I.editDisabled}
                    >
                      Render title as Markdown
                    </Checkbox>
                  </td>
                </tr>
                <tr>
                  <th>Subheading</th>
                  <td>
                    <TextInput
                      form={s.form}
                      field="subheading"
                      mergeableTaskName="Edit subheading title"
                      disabled={I.editDisabled}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>
                    <Textarea
                      form={s.form}
                      field="description"
                      taskName="Edit composition description"
                      resize="vertical"
                      disabled={I.editDisabled}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    {p.composition.collections.length === 1
                      ? "Collection"
                      : "Collections"}
                  </th>
                  <td>
                    <CompositionCollectionSelector
                      composition={p.composition}
                      disabled={I.editDisabled}
                    />
                  </td>
                </tr>
                <tr>
                  <th>{s.artistFieldLabel}</th>
                  <td>
                    <CompositionArtistsSelector
                      composition={p.composition}
                      forType="artists"
                      disabled={I.editDisabled}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Arranged by</th>
                  <td>
                    <CompositionArtistsSelector
                      composition={p.composition}
                      forType="arrangers"
                      disabled={I.editDisabled}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>

          {!p.metaOnly && (
            <>
              <LineSeparator />

              <form
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: ".5em",
                }}
              >
                <MusicKeySelector
                  form={p.composition.$.options}
                  field="musicKey"
                  Label="Key"
                  taskName="Change key"
                  disabled={I.editDisabled}
                />
                <MusicScaleSelector
                  form={p.composition.$.options}
                  field="musicScaleName"
                  Label="Scale"
                  taskName="Change scale"
                  disabled={I.editDisabled}
                />
                <TimeSignatureEditor
                  form={p.composition.$.options}
                  field="timeSignature"
                  Label="Time signature"
                  taskName="Change time signature"
                  disabled={I.editDisabled}
                />
              </form>

              <LineSeparator />

              <h3>More options</h3>

              <Spacing />

              <Checkbox
                form={p.composition.$.options}
                field="automaticallyManageBars"
                disabled={I.editDisabled}
              >
                Automatically manage bars
              </Checkbox>
            </>
          )}
        </div>
      )}
    />
  );
};

export default CompositionSetupEditor;
