import { first, last } from "lodash-es";
import { action } from "mobx";
import {
  Atom,
  AtomType,
  GroupLikeAtom,
  Keyframe,
  Note,
  Pattern,
  Replica,
  TextNode,
} from "../@types";
import { isNumber, isNumberLike } from "../base/utils/typeChecks.utils";
import { sortAtoms } from "../logic/atomFactoryMethods";
import { isBarAtom, isGroupLikeAtom } from "../utils/atoms.utils";

export const parseXAdjustmentDelta = (atom: Atom, _delta: number | string) => {
  if (isNumber(_delta)) {
    return {
      value: _delta,
      unit: null,
    };
  }
  const delta = _delta.trim();
  if (isNumberLike(delta)) {
    return {
      value: parseFloat(delta),
      unit: null,
    };
  }
  const moveByUnitMatch = /^(\+|-)?([\d\.]+)\s*(beats?|bars?)$/.exec(delta);
  if (!moveByUnitMatch) return { value: 0, unit: null };
  const multiplier = parseFloat(moveByUnitMatch[1] + moveByUnitMatch[2]);
  if (multiplier === 0) return { value: 0, unit: null };
  const unit = moveByUnitMatch[3];
  switch (unit) {
    case "beat":
    case "beats":
      const beatWidth = atom.startsInBar?.beatWidth ?? 1;
      return { value: beatWidth * multiplier, unit: "beats" };
    case "bar":
    case "bars":
      const barWidth =
        (multiplier < 0
          ? atom.startsInBar?.prevBar?.width
          : atom.startsInBar?.width) ?? 4;
      return { value: barWidth * multiplier, unit: "bars" };
  }
  return { value: parseInt(delta), unit: null };
};

export const adjustX = (
  atom: Note | Keyframe | TextNode,
  delta: number | string
) => {
  const { value } = parseXAdjustmentDelta(atom, delta);
  if (atom.x !== null) atom.x = atom.x + value;
};

export const adjustAtomArrayX = action(
  (atoms: Atom[], delta: number | string, ignoreIfHasOverride?: boolean) => {
    if (atoms.every(isBarAtom)) {
      const bars = sortAtoms(atoms);
      const allAtomsInSelectedBars = bars
        .map(a => a.topLevelAtomsStartingInThisBar)
        .flat();
      const firstBar = first(bars);
      if (!firstBar) return;
      if (parseFloat(`${delta}`) > 0) {
        const lastBar = last(bars);
        const nextBar = lastBar?.nextBar;
        if (!nextBar) return;
        const atomsInNextBar = nextBar.topLevelAtomsStartingInThisBar;
        adjustAtomArrayX(atomsInNextBar, `-${bars.length} bars`);
        adjustAtomArrayX(allAtomsInSelectedBars, "+1 bar");
        [nextBar, ...bars].forEach((b, i) => {
          b.barIndex = firstBar.barIndex + i;
        });
      } else {
        const prevBar = firstBar?.prevBar;
        if (!prevBar) return;
        const atomsInPrevBar = prevBar.topLevelAtomsStartingInThisBar;
        adjustAtomArrayX(atomsInPrevBar, `+${bars.length} bars`);
        adjustAtomArrayX(allAtomsInSelectedBars, "-1 bar");
        [...bars, prevBar].forEach((b, i) => {
          b.barIndex = prevBar.barIndex + i;
        });
      }
    } else {
      atoms.forEach(atom => {
        switch (atom.type) {
          case AtomType.note:
          case AtomType.keyframe:
          case AtomType.textNode:
            if (ignoreIfHasOverride && atom.$.x === null) break;
            adjustX(atom as Note | Keyframe | TextNode, delta);
            break;
          case AtomType.group:
          case AtomType.chord:
          case AtomType.voice:
          case AtomType.ornament:
            adjustAtomArrayX(
              (atom as GroupLikeAtom).children,
              delta,
              ignoreIfHasOverride
            );
            break;
          case AtomType.pattern: {
            const pattern = atom as Pattern;
            const { value } = parseXAdjustmentDelta(pattern, delta);
            pattern.$.x = pattern.anchor.x + value;
            adjustAtomArrayX(pattern.children, delta);
            break;
          }
          case AtomType.replica: {
            const replica = atom as Replica;
            const { value } = parseXAdjustmentDelta(replica, delta);
            replica.$.x = replica.anchor.x + value;
            replica.children.forEach(child => {
              if (isGroupLikeAtom(child)) {
                adjustAtomArrayX(child.children, delta, true);
              } else if (child.$.x !== null) {
                child.$.x += value;
              }
            });
            break;
          }
          case AtomType.bar: {
            break;
          }
          default: {
            // TODO: deal with other types
            console.warn(
              `adjustAtomArrayX for type ${atom.type} is not yet implemented`
            );
          }
        }
      });
    }
    return atoms;
  }
);
