import { round } from "lodash-es";
import { makePoint } from "../../models/geometry/makePoint.model";
import { makeValidRectWithCoords } from "../../models/geometry/makeRect.model";
import { Point, Rect, ValidPoint, ValidRect } from "../@types/geometry.types";
import { inRangeInclusive, max, min } from "./math.utils";
import { isNotNull, isNull, isNumber } from "./typeChecks.utils";

export const isPoint = (p: unknown): p is Point =>
  p instanceof Object &&
  (isNumber((p as UnknownObject).x) || isNull((p as UnknownObject).x)) &&
  (isNumber((p as UnknownObject).y) || isNull((p as UnknownObject).y));
export const isValidPoint = (point?: Point | null): point is ValidPoint =>
  !!point && isNotNull(point.x) && isNotNull(point.y);
export const addPoints = (a: ValidPoint, b: ValidPoint) =>
  ({
    x: a.x + b.x,
    y: a.y + b.y,
  } as ValidPoint);
export const subtractPoints = (a: ValidPoint, b: ValidPoint) =>
  ({
    x: a.x - b.x,
    y: a.y - b.y,
  } as ValidPoint);
export const roundPoint = (p: ValidPoint, precision?: number) => ({
  x: round(p.x, precision),
  y: round(p.y, precision),
});
export const getDistanceBetweenPoints = (a: ValidPoint, b: ValidPoint) => {
  const w = Math.abs(a.x - b.x);
  const h = Math.abs(a.y - b.y);
  return Math.sqrt(w * w + h * h);
};
export const isSamePoint = (a: Point, b: Point) => a.x === b.x && a.y === b.y;
export const pointIsInRect = (point: Point, rect: [Point, Point]) => {
  const [start = makePoint(), end = makePoint()] = rect;
  const xFloor = min(start.x, end.x);
  const xCeil = max(start.x, end.x);
  const yFloor = min(start.y, end.y);
  const yCeil = max(start.y, end.y);
  const { x, y } = point;
  return (
    inRangeInclusive(x, xFloor, xCeil) && inRangeInclusive(y, yFloor, yCeil)
  );
};

export const isValidRect = (rect: Rect): rect is ValidRect => {
  return isValidPoint(rect[0]) && isValidPoint(rect[1]);
};

export const getRectWidth = (rect: ValidRect) =>
  Math.abs(rect[0].x - rect[1].x);
export const getRectHeight = (rect: ValidRect) =>
  Math.abs(rect[0].y - rect[1].y);

export const formatRect = (rect: ValidRect) => {
  const upperLeftX = Math.min(rect[0].x, rect[1].x);
  const upperLeftY = Math.min(rect[0].y, rect[1].y);
  const lowerRightX = Math.max(rect[0].x, rect[1].x);
  const lowerRightY = Math.max(rect[0].y, rect[1].y);
  return makeValidRectWithCoords(
    upperLeftX,
    upperLeftY,
    lowerRightX,
    lowerRightY
  );
};

export const rectsDoNotIntersect = (a: Rect, b: Rect) => {
  if (!isValidRect(a) || !isValidRect(b)) return false;
  return formattedRectsDoNotIntersect(formatRect(a), formatRect(b));
};
export const formattedRectsDoNotIntersect = (a: ValidRect, b: ValidRect) => {
  const aLeftOfB = a[1].x < b[0].x;
  const aRightOfB = a[0].x > b[1].x;
  const aAboveB = a[1].y < b[0].y;
  const aBelowB = a[0].y > b[1].y;
  return aLeftOfB || aRightOfB || aAboveB || aBelowB;
};

export const rectsHaveIntersect = (a: Rect, b: Rect) =>
  !rectsDoNotIntersect(a, b);
export const formattedRectsHaveIntersect = (a: ValidRect, b: ValidRect) =>
  !formattedRectsDoNotIntersect(a, b);

export const degToRad = (degrees = 0) => {
  return degrees * (Math.PI / 180);
};
