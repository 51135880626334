export const NoOp = () => {};

export function isAsyncFunction(fn?: Function) {
  return fn?.constructor?.name === "AsyncFunction";
}

export const runAll = (...fns: VoidFunction[]) => fns.forEach(fn => fn?.());
export const runAllFn =
  (...fns: VoidFunction[]) =>
  () =>
    runAll(...fns);
