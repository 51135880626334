import { action, observable } from "mobx";
import * as React from "react";
import { useContext } from "react";
import { ResizeQuery } from "../../base/hooks/useResizeQuery";
import { ComposerController } from "../../controllers/composer.controller";
import { Composition } from "../../models/Composition.model";
import { ComposerInstance } from "./useCreateComposerInstance";

export type CanvasInsetValueSet = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export type ComposerUIContextObject = {
  ready: boolean;

  COMPOSER: ComposerController;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  resizeQuery: ResizeQuery;
  instance: ComposerInstance;
};

export const DefaultInnerOffset = { top: 0, right: 0, bottom: 0, left: 0 };

export const makeComposerUIContextObject = (
  COMPOSER?: ComposerController,
  composition?: Composition,
  ref?: React.MutableRefObject<HTMLDivElement | null>,
  resizeQuery?: ResizeQuery,
  instance?: ComposerInstance
) => {
  const s = observable({
    COMPOSER,
    composition,
    ref,
    resizeQuery,
    instance,
    get ready(): boolean {
      return Boolean(
        s.COMPOSER &&
          s.instance.atomContext &&
          s.ref &&
          s.resizeQuery &&
          s.instance
      );
    },
    get width(): number {
      return s.resizeQuery.width;
    },
    get height(): number {
      return s.resizeQuery.height;
    },
  }) as ComposerUIContextObject;

  return s;
};

export const defaultComposerUIContext = makeComposerUIContextObject();
export const ComposerUIContext = React.createContext(defaultComposerUIContext);
export const useComposerUIContext = () => useContext(ComposerUIContext);
export const useComposer = action(() => useComposerUIContext().instance);
export const useMaybeComposer = action(
  () => useComposer() as ComposerInstance | null
);
export const useInterpreter = action(() => useComposer().interpreter);
export const useAtomContext = action(() => useComposer().atomContext);
