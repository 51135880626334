import { isLightTheme } from "../base/utils/dom.utils";
import { mathMod, sumOfArray } from "../base/utils/ramdaEquivalents.utils";
import { isNumber } from "../base/utils/typeChecks.utils";
import { Accidental } from "../constants/accidentals.constants";
import {
  MusicKey,
  MusicKeyEquivalenceMap,
  MusicKeyNumber,
  MusicKeyOrderedInDiatonicScale,
} from "../constants/musicKeys.constants";
import {
  getDisplayAccidental,
  isDoubleFlatAccidental,
  isDoubleSharpAccidental,
  isFlatAccidental,
  isSharpAccidental,
} from "./accidentals.utils";
import { getMidiNumberFromFrequency } from "./frequency.utils";
import {
  flattenAuthoredNoteProperties,
  parseShorthand,
} from "./shorthands.utils";

export const baseMusicKeyRegex = /^[a-g]?(?!lat)/i;

export const getMusicKeyNumber = (k: MusicKey) => {
  const def = Object.entries(MusicKeyEquivalenceMap).find(e =>
    e[1].includes(k)
  );
  return parseInt(def![0]);
};

export const getMusicKeyYDistance = (a: MusicKey, b: MusicKey) =>
  getMusicKeyNumber(a) - getMusicKeyNumber(b);

export const incrementMusicKey = (
  k: MusicKey,
  increment = 1
): { key: MusicKey; octaveDiff: number } => {
  if (mathMod(increment, 12) === 0) {
    return {
      key: k,
      octaveDiff: increment / 12,
    };
  }
  // console.log(k);
  const musicKeyNumber = getMusicKeyNumber(k);
  // console.log(keyNumber);
  const inc = Math.round(increment);
  const newKeyNumber = mathMod(musicKeyNumber + inc, 12) as MusicKeyNumber;
  const equivalentKeyNames = MusicKeyEquivalenceMap[newKeyNumber];
  let key: MusicKey;
  if (equivalentKeyNames.length === 1) key = equivalentKeyNames[0];
  else
    key =
      equivalentKeyNames.find(k => k.includes(increment >= 0 ? "#" : "b")) ||
      equivalentKeyNames[0];
  const octaveDiff =
    Math.floor(
      Math.abs(((getIndexInOctaveFromCFromPitchClass(k) ?? 0) + increment) / 12)
    ) * (increment < 0 ? -1 : 1);
  return { key, octaveDiff };
};

export const isValidMusicKey = (string: string): string is MusicKey =>
  (Object.values(MusicKey) as string[]).includes(string);

export const parseBaseMusicKeyFromShorthand = (
  shorthand: string
): MusicKey | null => {
  const keyMatch = baseMusicKeyRegex.exec(shorthand);
  const key = keyMatch ? keyMatch[0] : null;
  if (!key) return null;
  const standardKey = key ? key.toUpperCase() : null;
  if (!standardKey) return null;
  if (isValidMusicKey(standardKey)) return standardKey;
  return null;
};

export const getPitchClassFromBaseKeyAndAccidentals = (
  baseKey?: MusicKey | string | null,
  accidentals?: Accidental[]
) => {
  if (!baseKey) return null;
  if (!isValidMusicKey(baseKey)) return null;
  if (!accidentals) return baseKey;
  const flats = accidentals.filter(isFlatAccidental);
  const doubleFlats = accidentals.filter(isDoubleFlatAccidental);
  const sharps = accidentals.filter(isSharpAccidental);
  const doubleSharps = accidentals.filter(isDoubleSharpAccidental);
  const increments = [
    flats.map(f => -1),
    doubleFlats.map(f => -2),
    sharps.map(s => +1),
    doubleSharps.map(s => +2),
  ].flat();
  return incrementMusicKey(baseKey, sumOfArray(increments)).key;
};

export const getPitchClassFromIndexInOctaveFromC = (n: number) => {
  // console.log(n);
  return MusicKeyEquivalenceMap[n as MusicKeyNumber]?.[0];
};

export const getPitchClassFromMidiNumber = (y: number | null) => {
  return y === null
    ? null
    : getPitchClassFromIndexInOctaveFromC(
        getIndexInOctaveFromCFromMidiNumber(y)
      );
};
export const getIndexInOctaveFromCFromMidiNumber = (y: number) => {
  return Math.abs(Math.round(y) % 12) as MusicKeyNumber;
};
export const getOctaveFromMidiNumber = (num: number) => {
  return Math.floor(num / 12);
};
export const getPitchClassDiff = (a: MusicKey, b: MusicKey) => {
  return getMusicKeyIndex(a)! - getMusicKeyIndex(b)!;
};
export const getIndexInOctaveFromCFromPitchClass = (k: MusicKey | null) => {
  if (k === null) return null;
  for (const e of Object.entries(MusicKeyEquivalenceMap)) {
    if (e[1].includes(k)) return +e[0];
  }
  return null;
};

export const getIndexInDiatonicScaleFromPitchClass = (k: MusicKey | null) => {
  if (k === null) return null;
  for (const e of Object.entries(MusicKeyOrderedInDiatonicScale)) {
    if (e[1] === k[0]) return +e[0];
  }
  return null;
};

export const getMusicKeyDescriptorFromMidiNumber = (
  n?: number | string | null
) => {
  if (!n) return;
  const number = +n;
  if (!number || number < 0) return;
  try {
    const key = MusicKeyEquivalenceMap[(number % 12 || 0) as MusicKeyNumber][0];
    const octave = Math.floor(number / 12) - 1;
    return {
      name: `${key}${octave}`,
      key,
      octave,
    };
  } catch (e) {
    console.error(number);
  }
};
export type MusicKeyDescriptor = ReturnType<
  typeof getMusicKeyDescriptorFromMidiNumber
>;

export const getMidiNumberFromMusicKeyName = (n?: number | string | null) => {
  if (!n) return;
  if (isNumber(n)) return n;
  const parsed = parseShorthand(n);
  return flattenAuthoredNoteProperties(parsed).midiNumber;
};

export const getYFromMidiNumber = <T extends number | null>(midiNumber: T) => {
  return (midiNumber === null ? midiNumber : 60 - midiNumber) as T;
};
export const getMidiNumberFromY = <T extends number | null>(y: T) => {
  return (y === null ? null : 60 - y) as T;
};

export const getNoteNameFromNumber = (
  value: number,
  inputType: "midi" | "frequency"
) => {
  if (inputType === "midi") return getMusicKeyName(value);
  return getMusicKeyName(getMidiNumberFromFrequency(value));
};

export const getClosestTonicMidiNumber = (
  midiNumber: number,
  tonic: MusicKey
) => {
  const tonicMusicKeyIndex = getMusicKeyIndex(tonic) ?? 0;
  const octave = Math.round(midiNumber / 12);
  return octave * 12 + tonicMusicKeyIndex;
};

export const getMusicKeyIndex = (key: MusicKey | null) => {
  if (!key) return null;
  const keyDef = Object.entries(MusicKeyEquivalenceMap).find(entry => {
    if (entry[1].includes(key)) return true;
  });
  if (keyDef) return +keyDef[0];
  return null;
};

export const getMusicKeyName = (which?: string | number | null) => {
  return isNaN(+(which ?? ""))
    ? (which as string)
    : getMusicKeyDescriptorFromMidiNumber(which)?.name;
};

export const getMusicKeyDisplayName = (
  key?: string,
  options?: {
    showMajorOrMinor?: boolean;
  }
) => {
  if (!key) return "";
  const pitchClass = key[0];
  const isMajorKey = pitchClass.toUpperCase() === pitchClass;
  const accidental = getDisplayAccidental(key[1] || "");
  return `${pitchClass}${accidental}${
    options?.showMajorOrMinor ? (isMajorKey ? " Major" : " Minor") : ""
  }`;
};

export const getMusicKeyDOMEl = (midiNumber: number) =>
  document.documentElement.querySelector(
    `span[data-music-key="${midiNumber}"]`
  );

export const changeMusicKeyDOMElBrightness = (
  _midiNumber: number,
  brightness?: number
) => {
  const midiNumber = Math.round(_midiNumber);
  const keyEl = getMusicKeyDOMEl(midiNumber);
  if (keyEl) {
    const invertBrightness =
      isLightTheme() && keyEl?.getAttribute("data-is-half-step") === "true";
    let b = brightness ?? 0.8;
    b = invertBrightness ? (b < 1 ? b + 2 : b * 0.5) : b;
    (keyEl as HTMLDivElement).style.filter = `brightness(${b})`;
  }
  return () => resetKeyDOMElBrightness(midiNumber);
};

export const resetKeyDOMElBrightness = (midiNumber: number) => {
  const keyEl = getMusicKeyDOMEl(midiNumber);
  if (keyEl) (keyEl as HTMLDivElement).style.removeProperty("filter");
};
