import { makeIconDef } from "./makeIconDef";

export const ImportIconDef = {
  import: makeIconDef("import", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0306 6.32499C18.3449 5.07641 19.5871 4.5 21 4.5V5.5C19.9129 5.5 18.9051 5.92358 17.7194 7.04999C16.6331 8.08194 15.4282 9.67532 13.916 12H18.5V13H12.5H12V12.5V6.5H13V11.5744C14.5581 9.16989 15.8385 7.45747 17.0306 6.32499ZM5 10C5 9.17159 5.67157 8.50001 6.5 8.50001H10V9.50001H6.5C6.22386 9.50001 6 9.72387 6 10V18.5C6 18.7761 6.22386 19 6.5 19H18.5C18.7761 19 19 18.7761 19 18.5V15H20V18.5C20 19.3284 19.3284 20 18.5 20H6.5C5.67157 20 5 19.3284 5 18.5V10Z"
        fill="currentColor"
      />
    ),
  }),
};
