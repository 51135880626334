import { flow, observable } from "mobx";
import { sortByLastUpdated } from "../../base/utils/array.utils";
import { convertRecordMapToArray } from "../../base/utils/map.utils";
import { makeSnapshot } from "../../base/utils/snapshot.utils";
import { getNowTimestampUtc } from "../../base/utils/time.utils";
import { ModelName } from "../../constants/modelName.constants";
import {
  Collection,
  CollectionSnapshot,
  CollectionSnapshotFactory,
} from "../../models/Collection.model";
import { makeRequest } from "../../utils/request.utils";
import { ApiController, GetRequestOptions } from "../api.controller";
import { LocalDBController } from "../localDB.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "../_root.controller";

export const makeCollectionsController = () => {
  const s = observable({
    ...makeControllerBase("COLLECTIONS"),

    get API(): ApiController {
      return s.ROOT!.API;
    },
    get LOCALDB(): LocalDBController {
      return s.ROOT!.LOCALDB;
    },
    get all(): Collection[] {
      return sortByLastUpdated(
        convertRecordMapToArray(s.LOCALDB.data.collections)
      );
    },
    get allNonArchived(): Collection[] {
      return s.all.filter(c => !c.$.timeArchived);
    },
    get allArchived(): Collection[] {
      return s.all.filter(c => !!c.$.timeArchived);
    },
    get own(): Collection[] {
      return s.all.filter(c => c.owner === s.ROOT!.AUTH.user);
    },
    get ownNonArchived(): Collection[] {
      return s.own.filter(c => !c.$.timeArchived);
    },
    get ownArchived(): Collection[] {
      return s.own.filter(c => !!c.$.timeArchived);
    },

    create: async (template?: CollectionSnapshot) => {
      return await s.API.post<Collection>(
        "/collections/",
        ModelName.collections,
        makeSnapshot(CollectionSnapshotFactory, {
          name: "New collection",
          ...template,
        })
      );
    },

    get: (id: string, options?: GetRequestOptions) =>
      s.API.get<Collection>(
        `/collections/${id}`,
        ModelName.collections,
        options
      ),

    getFromLocalDB: (id: string) => {
      return s.LOCALDB.get<Collection>(ModelName.collections, id);
    },

    getAllOwn: async () =>
      await makeRequest("COLLECTIONS.getAllOwn", () =>
        s.API.getMany<Collection>("/collections", ModelName.collections)
      ).fetch(),

    getAllPublic: async () =>
      await makeRequest("COLLECTIONS.getAllPublic", () =>
        s.API.getMany<Collection>("/public/collections", ModelName.collections)
      ).fetch(),

    getAllAsAdmin: async () =>
      await makeRequest("COLLECTIONS.getAllAsAdmin", () =>
        s.API.getMany<Collection>("/admin/collections", ModelName.collections)
      ).fetch(),

    save: async (collection: Collection | CollectionSnapshot) => {
      return await s.API.patch<Collection>(
        "/collections/" + collection._id,
        ModelName.collections,
        collection
      );
    },

    archive: (collection: Collection) =>
      flow(function* () {
        collection.$.timeArchived = getNowTimestampUtc();
        const savedCollection = (yield s.save(collection)) as Collection;
        s.LOCALDB.remove(collection);
        return savedCollection;
      })(),

    reset: () => {
      // c.composerAppState = null;
    },
  });

  s.init = makeRootControllerChildInitFn(s, () => {
    s.ready = true;
  });

  return s;
};

export type CollectionsController = ReturnType<
  typeof makeCollectionsController
>;
