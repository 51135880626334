import { observable } from "mobx";

export type makeObservableObject = <
  T extends UnknownObject,
  S extends UnknownObject = UnknownObject
>(
  factory: (source?: S) => T,
  source?: S
) => T;

export const makeObservableObject = <
  T extends UnknownObject,
  S extends UnknownObject = UnknownObject
>(
  factory: (source?: S) => T,
  source?: S
) => {
  const s = factory(source);
  return observable(s);
};
