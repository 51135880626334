import { AtomType, Pattern } from "../../@types";
import { ValidPoint, ValidRect } from "../../base/@types";
import { addPoints, subtractPoints } from "../../base/utils/geometry.utils";
import { subtract } from "../../base/utils/math.utils";
import { copyWithJSON } from "../../base/utils/object.utils";
import { createAtomFactory } from "../../logic/Atom.factory";
import {
  getPatternTransformedAnchor,
  getPatternTransformedBoundingBox,
} from "../../transformers/transform.controller";
import { getAtomSetBoundingBox } from "../../utils/atoms.utils";
import { getDefaultAnchorOfNoteSet } from "../../utils/patterns.utils";
import { makePoint } from "../geometry/makePoint.model";
import {
  GroupSnapshotFactory,
  makeGroupExtendedMembersFactory,
} from "./Group.model";

export const PatternSnapshotFactory = () => ({
  ...GroupSnapshotFactory(AtomType.pattern),
  boundingBoxRelativeToAnchor: [makePoint(0, 0), makePoint(0, 0)] as ValidRect,
  useClickThroughBoundingBox: false,
});

export const makePatternExtendedMembersFactory = (P: Pattern) => ({
  get displayName(): string {
    return P.name || `Pattern ${P._id}`;
  },
  set displayName(value: string) {
    P.name = value;
  },
  get _anchor() {
    if (P.$.x !== null && P.$.y !== null) {
      return {
        x: P.$.x,
        y: P.$.y,
      };
    }
    return P.defaultAnchor;
  },
  get anchor() {
    return getPatternTransformedAnchor(P);
  },
  set anchor(point: ValidPoint) {
    P.$.x = point.x;
    P.$.y = point.y;
  },
  get _boundingBox() {
    return [
      addPoints(P._anchor, P.$.boundingBoxRelativeToAnchor[0]),
      addPoints(P._anchor, P.$.boundingBoxRelativeToAnchor[1]),
    ] as ValidRect;
  },
  set _boundingBox(rect) {
    P.$.boundingBoxRelativeToAnchor = [
      subtractPoints(rect[0], P._anchor),
      subtractPoints(rect[1], P._anchor),
    ] as ValidRect;
  },
  get boundingBox() {
    return getPatternTransformedBoundingBox(P);
  },
  set boundingBox(rect) {
    P._boundingBox = rect;
  },
  get x() {
    return P.boundingBox[0].x;
  },
  set x(v) {
    const diff = v - P.boundingBox[0].x;
    P.boundingBox[0].x += diff;
    P.boundingBox[1].x += diff;
  },
  get y() {
    return P.boundingBox[0].y;
  },
  set y(v) {
    const diff = v - P.boundingBox[0].y;
    P.boundingBox[0].y += diff;
    P.boundingBox[1].y += diff;
  },
  get x2() {
    return P.boundingBox[1].x;
  },
  set x2(v) {
    P.boundingBox[1].x = v;
  },
  get y2() {
    return P.boundingBox[1].y;
  },
  set y2(v) {
    P.boundingBox[1].y = v;
  },
  get width() {
    return Math.abs(P.x2 - P.x);
  },
  get height() {
    return Math.abs(P.y2 - P.y);
  },
  get replicas() {
    return P.context?.replicas.filter(r => r.pattern === P) ?? [];
  },
  get defaultAnchor() {
    return getDefaultAnchorOfNoteSet(P.descendantNotes);
  },
  get xyRelToAnchor() {
    return {
      x: P.x - P.anchor.x,
      y: P.y - P.anchor.y,
    };
  },
  setAnchorX: (v: number) => {
    const newX = parseFloat(`${v}`);
    const diff = subtract(newX, P.$.x);
    P.$.x = newX;
    if (diff) {
      P.replicas.forEach(replica => {
        if (replica.$.x !== null) replica.$.x += diff;
      });
      P.boundingBox = [
        {
          x: P.boundingBox[0].x - diff,
          y: P.boundingBox[0].y,
        },
        {
          x: P.boundingBox[1].x - diff,
          y: P.boundingBox[1].y,
        },
      ];
    }
  },
  setAnchorY: (v: number) => {
    const newY = parseFloat(`${v}`);
    const diff = subtract(newY, P.$.y);
    P.$.y = newY;
    if (diff) {
      P.replicas.forEach(replica => {
        if (replica.$.y !== null) replica.$.y += diff;
      });
      P.boundingBox = [
        {
          x: P.boundingBox[0].x,
          y: P.boundingBox[0].y - diff,
        },
        {
          x: P.boundingBox[1].x,
          y: P.boundingBox[1].y - diff,
        },
      ];
    }
  },
  resetAnchor: () => {
    const newAnchor = copyWithJSON(P.defaultAnchor);
    P.setAnchorX(newAnchor.x);
    P.setAnchorY(newAnchor.y);
  },
  resetBoundingBox: () => {
    P.boundingBox = getAtomSetBoundingBox(P.descendants);
  },
});

export const makePattern = createAtomFactory<Pattern>({
  type: AtomType.pattern,
  snapshotFactory: PatternSnapshotFactory,
  extendedPropertiesFactories: [
    makeGroupExtendedMembersFactory,
    makePatternExtendedMembersFactory,
  ],
});
