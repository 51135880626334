/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, {
  CSSProperties,
  HTMLProps,
  RefObject,
  SyntheticEvent,
  useRef,
} from "react";
import { useProps, useStore } from "../utils/mobx.utils";
import { useControllers } from "../hooks/rootContext.hooks";
import cx from "../utils/className.utils";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { action } from "mobx";

type P = {
  imageId: string;
  className?: string;
  onLoad?: HTMLProps<HTMLImageElement>["onLoad"];
  innerRef?: RefObject<HTMLImageElement>;
  style?: CSSProperties;
  eager?: boolean;
  fadeInOnLoad?: boolean;
  includeDimensionAttributes?: boolean;
};

const ImageFromFileRecord: React.FC<P> = props => {
  const { FILE_RECORDS, LOCALDB } = useControllers();
  const p = useProps(props);
  const ownRef = useRef<HTMLImageElement>(null);
  const ref = props.innerRef ?? ownRef;
  const s = useStore(() => ({
    get id() {
      return p.imageId;
    },
    get image() {
      return LOCALDB.getFileRecord(s.id);
    },
    get url() {
      return s.shouldLoad ? s.image?.url : undefined;
    },
    shouldLoad: p.eager,
    loaded: false,
    error: false,
    handleOnLoad: (e: SyntheticEvent<HTMLImageElement>) => {
      s.loaded = true;
      p.onLoad?.(e);
    },
    refreshSignedUrl: async () => {
      try {
        await FILE_RECORDS.get(s.id);
      } catch (e) {
        console.error(e);
        s.error = true;
      }
    },
    handleError: () => {
      s.refreshSignedUrl();
    },
  }));
  useOnMount(
    action(() => {
      if (p.eager) return;
      const observer = new IntersectionObserver(
        action(entries => {
          if (entries[0].isIntersecting) {
            s.shouldLoad = true;
            observer.disconnect();
          }
        }),
        { rootMargin: "200px" }
      );
      if (ref.current) observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    })
  );
  return (
    <Observer
      children={() =>
        s.error ? null : (
          <img
            className={cx(p.className, s.loaded && "loaded")}
            ref={ref}
            src={s.url}
            onError={s.handleError}
            onLoad={s.handleOnLoad}
            width={
              p.includeDimensionAttributes
                ? s.image?.$.width ?? undefined
                : undefined
            }
            height={
              p.includeDimensionAttributes
                ? s.image?.$.height ?? undefined
                : undefined
            }
            style={{
              ...p.style,
              aspectRatio:
                s.image?.$.width && s.image.$.height
                  ? `${s.image.$.width / s.image.$.height}`
                  : undefined,
              transition: p.fadeInOnLoad ? "opacity 0.2s" : undefined,
              opacity: s.loaded ? 1 : 0,
            }}
          />
        )
      }
    />
  );
};

export default ImageFromFileRecord;
