/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { PersonIconOutlined18 } from "../../base/components/icons/Person.icons";
import { fg10 } from "../../constants/cssCustomProperties.constants";
import ImageFromFileRecord from "../../base/components/ImageFromFileRecord";

type PersonIconProps = {
  size?: string | number;
  className?: string;
  imageId?: string | null;
  eager?: boolean;
  fadeInOnLoad?: boolean;
};

const PersonIcon: React.FC<PersonIconProps> = props => {
  return (
    <Observer
      children={() => (
        <figure
          className={props.className}
          css={{
            position: "relative",
            display: "block",
            backgroundColor: fg10,
            width: props.size,
            borderRadius: "50%",
            margin: 0,
            overflow: ["hidden", "clip"],
            "&:before": {
              display: "block",
              content: "''",
              paddingTop: "100%",
            },
            img: {
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            svg: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          {props.imageId ? (
            <ImageFromFileRecord
              imageId={props.imageId}
              eager={props.eager}
              fadeInOnLoad={props.fadeInOnLoad}
              includeDimensionAttributes
            />
          ) : (
            <PersonIconOutlined18 />
          )}
        </figure>
      )}
    />
  );
};

export default PersonIcon;
