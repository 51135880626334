import { action } from "mobx";
import {
  AtomContext,
  GroupLikeAtom,
  Replica,
  ReplicaSnapshot,
  Voice,
} from "../@types";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { ReplicaSnapshotFactory } from "../models/atoms/Replica.model";
import { createSnapshotCopyWithNewId, isLeafAtom } from "./atoms.utils";

export const createReplica = action(
  (ac: AtomContext, source?: Partial<ReplicaSnapshot>, newId?: string) => {
    if (!source?.patternId) return null;
    const nextId = newId ?? ac.getNextNewAtomId();
    const $ = makeSnapshot(ReplicaSnapshotFactory, {
      ...source,
      _id: nextId,
    });
    ac.writeContentToArray.push($);
    const replica = ac.getAtomById<Replica>(nextId);
    return replica;
  }
);

export const duplicateGroupLikeAtomsByCopyingSnapshots = action(
  (options: {
    groupLikeAtom: GroupLikeAtom;
    offsetX: number;
    parentIds?: string[];
    inVoice?: Voice | null;
  }) => {
    const { groupLikeAtom, offsetX, parentIds, inVoice } = options;
    const g$Copy = createSnapshotCopyWithNewId(groupLikeAtom);
    if (g$Copy.$.x !== null) g$Copy.$.x += offsetX;
    if (parentIds) g$Copy.$.parentIds = parentIds;
    if (inVoice) g$Copy.$.voiceId = inVoice._id;
    const descendantsSnapshotCopies = groupLikeAtom.descendants.map(
      createSnapshotCopyWithNewId
    );
    const $Copies = [g$Copy, ...descendantsSnapshotCopies];
    descendantsSnapshotCopies.forEach(a => {
      if (isLeafAtom(a.$) && a.$.refAtomId) {
        if (a.$.x !== null) a.$.x += offsetX;
      }
      a.$.parentIds = a.$.parentIds.map(pId => {
        const copy = $Copies.find(d => d.prevId === pId);
        if (copy) return copy.$._id;
        return pId;
      });
    });
    groupLikeAtom.context?.writeContentToArray.push(...$Copies.map(c => c.$));
    return groupLikeAtom.context?.getAtomById(g$Copy.$._id) ?? null;
  }
);
