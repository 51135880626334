import styled from "@emotion/styled";
import { fg } from "../../constants/cssCustomProperties.constants";
import cx from "../utils/className.utils";

type Props = {
  centered?: boolean;
};

const Wrap = styled.div`
  /* opacity: 0; */
  /* @keyframes LoadingIndicatorEnter {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: LoadingIndicatorEnter 0.3s linear forwards; */
  svg {
    display: block;
    overflow: visible;
    width: 3.2rem;
    height: 3.2rem;
    @keyframes LoadingIndicatorSegmentSpin {
      0% {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    g {
      animation: LoadingIndicatorSegmentSpin 0.6s linear infinite;
      transform-origin: 24px 24px;
    }
  }
  &.centered {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }
`;

const AccentedLoadingIndicator = (props: Props) => {
  return (
    <Wrap className={cx(props.centered && "centered")}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
        <circle
          opacity="0.3"
          cx="24"
          cy="24"
          r="23"
          stroke="currentColor"
          strokeWidth="2"
        />
        <g>
          <path
            d="M40.2635 7.73654C42.3992 9.87229 44.0934 12.4078 45.2492 15.1983C46.4051 17.9888 47 20.9796 47 24"
            stroke={fg}
            strokeWidth="2"
            strokeOpacity={1}
          />
        </g>
      </svg>
    </Wrap>
  );
};

export default AccentedLoadingIndicator;
