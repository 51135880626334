/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { preFlex } from "../../base/styles/partials/predefinedFlexBox.stylePartials";
import SnapXControl from "../../components/composer/SnapXControl";
import { useComposer } from "../../components/composer/ComposerApp.context";
import IsolatedAtomDisplay from "../../components/composer/IsolatedAtomDisplay";

type P = {};

const SelectToolControlBar: React.FC<P> = props => {
  const I = useComposer();
  const select = I.tools.select;
  return (
    <Observer
      children={() => (
        <div css={preFlex.simpleInlineFlexAlignCenterWithSmallerGap}>
          {select.isolatedAtom && <IsolatedAtomDisplay />}
          {I.editable && <SnapXControl />}
        </div>
      )}
    />
  );
};

export default SelectToolControlBar;
