/** @jsxImportSource @emotion/react */
import { reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { bg } from "../../constants/cssCustomProperties.constants";
import { CSSPartial } from "../@types/css.types";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useControllers } from "../hooks/rootContext.hooks";
import { useCreateResizeQueryWithRef } from "../hooks/useCreateResizeQueryWithRef.hook";
import cx from "../utils/className.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import { runAfter } from "../utils/waiters.utils";
import ClickOutside from "./ClickOutside";
import styled from "@emotion/styled";

type DropdownLikeUIProps = {
  Header: ReactNode;
  children: () => ReactNode;
  dropdownClass?: string;
  disabled?: boolean;
};

const style = {
  wrap: {
    cursor: "not-allowed",
  } as CSSPartial,
  dropdown: {
    position: "fixed",
    backgroundColor: bg,
    borderRadius: 3,
    overflow: "auto",
  } as CSSPartial,
};

const Header = styled.button`
  appearance: none;
  background-color: ${bg};
  border: 0;
  color: inherit;
  font: inherit;
  padding: 0;
  border-radius: 0;
  width: 100%;
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const DropdownLikeUI: React.FC<DropdownLikeUIProps> = props => {
  const { PORTALS } = useControllers();
  const { ref, query } = useCreateResizeQueryWithRef<HTMLButtonElement>();
  const p = useProps(props);
  const s = useStore(() => ({
    isOpen: false,
    toggle: (value?: boolean) => {
      const newValue = value ?? !s.isOpen;
      if (newValue) query.recalc();
      s.isOpen = newValue;
    },
    handleHeaderPointerDown: () => {
      if (p.disabled) return;
      if (s.isOpen) s.closeDropdown();
      else s.toggle(true);
    },
    closeDropdown: () => {
      runAfter(() => s.toggle(false));
    },
    get children() {
      return p.children();
    },
  }));

  useOnMount(() =>
    reaction(
      () => p.disabled,
      disabled => {
        if (disabled) s.isOpen = false;
      },
      { fireImmediately: true }
    )
  );

  return (
    <Observer
      children={() => (
        <div
          css={style.wrap}
          className={cx(
            "DropdownLikeUI",
            s.isOpen && "open",
            p.disabled && "disabled"
          )}
        >
          <Header
            onPointerDown={s.handleHeaderPointerDown}
            ref={ref}
            disabled={p.disabled}
          >
            {p.Header}
          </Header>
          {!!(s.isOpen && !p.disabled) &&
            PORTALS.render(
              <ClickOutside
                className={cx("DropdownLikeUI__dropdown", p.dropdownClass)}
                css={style.dropdown}
                onClick={s.closeDropdown}
                onClickOutside={s.closeDropdown}
                style={{
                  top: query.bottomFromViewportTop ?? 0,
                  left: query.left ?? 0,
                  width: query.width,
                  maxHeight: (query.bottomFromViewportBottom ?? 300) - 10,
                }}
              >
                {s.children}
              </ClickOutside>
            )}
        </div>
      )}
    />
  );
};

export default DropdownLikeUI;
