/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { Suspense } from "react";
import LoadingIndicator from "../../base/components/LoadingIndicator";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useStore } from "../../base/utils/mobx.utils";
import { Composition } from "../../models/Composition.model";
import { flow } from "mobx";
import LoadingIndicatorBlock from "../../base/components/LoadingIndicatorBlock";
import { convertMidiFileToComposition } from "../../utils/midi/midiImport.utils";

type TestPageMidiImporterProps = {};

const ComposerAppManager = React.lazy(
  () => import("../../components/composer/ComposerAppManager")
);

const TestPageMidiImporter: React.FC<TestPageMidiImporterProps> = props => {
  const { LOCALDB } = useControllers();
  const s = useStore(() => ({
    composition: null as Composition | null,
  }));
  useOnMount(() => {
    flow(function* () {
      s.composition = yield convertMidiFileToComposition(
        LOCALDB,
        "/midi/piano_sonata_331_1_(c)oguri.mid",
        undefined,
        true
      );
    })();
  });
  return (
    <Observer
      children={() =>
        s.composition ? (
          <Suspense fallback={<LoadingIndicatorBlock />}>
            <ComposerAppManager composition={s.composition} mode="editor" />
          </Suspense>
        ) : (
          <LoadingIndicator />
        )
      }
    />
  );
};

export default TestPageMidiImporter;
