import { makeIconDef } from "./makeIconDef";

export const GramophoneIconDef = {
  gramophone: makeIconDef("gramophone", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0002 3.5H12.8941L12.7972 3.54309L8.29715 5.54309L8.21232 5.5808L8.14667 5.64645L5.14667 8.64645L5.08102 8.71209L5.04332 8.79693L3.59056 12.0656C3.18186 12.9852 3.77669 14.0384 4.77522 14.1633L7.23569 14.4708C7.43614 14.4959 7.63959 14.4802 7.83382 14.4247L10.8089 13.5747C10.9154 13.5442 11.0291 13.55 11.1319 13.5912L12.8591 14.2821C13.0027 14.3395 13.1117 14.4602 13.1542 14.609L13.5515 16L6.5 16C6.22386 16 6 16.2239 6 16.5C6 16.7761 6.22386 17 6.5 17H6C5.44772 17 5 17.4477 5 18V21L3.5 21C3.22386 21 3 21.2239 3 21.5C3 21.7761 3.22386 22 3.5 22H21.5C21.7761 22 22 21.7761 22 21.5C22 21.2239 21.7761 21 21.5 21L20 21V18C20 17.4477 19.5523 17 19 17L18.5 17C18.7761 17 19 16.7761 19 16.5C19 16.2239 18.7761 16 18.5 16H18V12.7052C18 12.5021 17.9587 12.3011 17.8787 12.1144L16.5511 9.0162L18.2238 5.67082C18.7225 4.67347 17.9973 3.5 16.8822 3.5H13.0002ZM14.1157 14.3343L14.5915 16H17V12.7052C17 12.6375 16.9862 12.5705 16.9596 12.5083L15.7663 9.72347L13.7575 10.9287C13.5207 11.0708 13.2136 10.994 13.0715 10.7572C12.9294 10.5205 13.0062 10.2133 13.243 10.0713L15.6181 8.64616L17.3294 5.22361C17.4956 4.89116 17.2539 4.5 16.8822 4.5H13.5002V6V7C13.5002 7.27614 13.2764 7.5 13.0002 7.5C12.7241 7.5 12.5002 7.27614 12.5002 7V6V4.76938L9.36856 6.16123L11.3538 8.14645C11.549 8.34171 11.549 8.65829 11.3538 8.85355C11.1585 9.04882 10.8419 9.04882 10.6467 8.85355L8.50022 6.70711L6.26143 8.9459L7.53355 9.9C7.6201 9.96491 7.72537 10 7.83356 10L9.00022 9.99999C9.27636 9.99999 9.50022 10.2238 9.50022 10.5C9.50023 10.7761 9.27637 11 9.00023 11L7.83356 11C7.509 11 7.1932 10.8947 6.93355 10.7L5.70226 9.77653L4.50437 12.4718C4.36814 12.7783 4.56641 13.1294 4.89926 13.171L7.35972 13.4785C7.42654 13.4869 7.49436 13.4817 7.5591 13.4632L10.5342 12.6132C10.8538 12.5218 11.1947 12.5393 11.5033 12.6627L13.2305 13.3536C13.6614 13.526 13.9882 13.8881 14.1157 14.3343ZM14.1228 18L6 18V21H19V18L18 18H17.5H14.5H14.1228Z"
        fill="currentColor"
      />
    ),
  }),
};
