export const BreakpointDefs = {
  phone: 320,
  "phone-md": 375,
  "phone-lg": 480,
  "phone-xl": 520,
  tablet: 640,
  "tablet-md": 768,
  "tablet-lg": 920,
  "tablet-xl": 1024,
  desktop: 1194,
  "desktop-md": 1280,
  "desktop-ml": 1440,
  "desktop-lg": 1520,
  "desktop-xl": 1680,
};

export const BreakpointNames = Object.keys(BreakpointDefs);

export type BreakpointName = keyof typeof BreakpointDefs;

export function isBreakpointName(string: unknown): string is BreakpointName {
  return BreakpointNames.includes(string as BreakpointName);
}

export function breakpoint(bp: BreakpointName): number {
  return BreakpointDefs[bp];
}
