/** @jsxImportSource @emotion/react */
import * as React from "react";
import { runAndIgnoreError } from "../utils/errors.utils";
import { isString } from "../utils/typeChecks.utils";
import { isProduction } from "../env";

const defaultMessage = "An unknown error occurred.";

const ErrorRenderer = (props: { error?: string | Error | null | unknown }) => {
  let errorMessage = defaultMessage;

  runAndIgnoreError(() => {
    errorMessage = `${
      isString(props.error) ? props.error : (props.error as Error)?.message
    }`;
    if (isProduction && /Loading chunk [\d]+ failed/.test(errorMessage)) {
      errorMessage = "Network error while loading the app.";
    }
  });

  return <div className="ErrorRenderer">{errorMessage}</div>;
};

export default ErrorRenderer;
