import { observable } from "mobx";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { FileRecord, FileRecordSnapshot } from "../models/FileRecord.model";
import { ModelName } from "../constants/modelName.constants";

export const makeBucketController = () => {
  const c = observable({
    ...makeControllerBase("BUCKET"),
    upload: async (options: {
      existingFileRecordId?: string | null;
      fileName: string;
      contentBase64: string | null;
      ignoreMissingFile?: boolean;
      purpose?: "general" | "avatar";
    }) => {
      if (!c.ROOT) return;
      if (!options.contentBase64) return;
      const response = await c.ROOT?.API.postRaw<FileRecordSnapshot>(
        `/bucket/upload`,
        options
      );
      return c.ROOT.LOCALDB.setOrMerge<FileRecord>(
        ModelName.fileRecords,
        response
      );
    },
    get: async (id: string) => {
      if (!c.ROOT) return null;
      const existing = c.ROOT.LOCALDB.get<FileRecord>(
        ModelName.fileRecords,
        id
      );
      if (existing) return existing;
      else return await c.ROOT.FILE_RECORDS.get(id);
    },
  });
  c.init = makeRootControllerChildInitFn(c, () => {
    c.ready = true;
  });
  return c;
};

export type BucketController = ReturnType<typeof makeBucketController>;
