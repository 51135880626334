/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Helmet } from "react-helmet";
import { useControllers } from "../hooks/rootContext.hooks";

type HelmetColorThemeUpdaterProps = {};

const HelmetColorThemeUpdater: React.FC<
  HelmetColorThemeUpdaterProps
> = props => {
  const { THEME } = useControllers();
  return (
    <Observer
      children={() => (
        <Helmet>
          <meta name="theme-color" content={THEME.bg} />
        </Helmet>
      )}
    />
  );
};

export default HelmetColorThemeUpdater;
