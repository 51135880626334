/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import * as React from "react";
import cx from "../utils/className.utils";

type FormLabelProps = {
  className?: string;
  htmlFor?: string;
  bold?: boolean;
};

const FormLabel: React.FC<React.PropsWithChildren<FormLabelProps>> = observer(
  p => {
    return (
      <label
        className={cx("FormLabel", p.className)}
        htmlFor={p.htmlFor}
        children={p.children}
        css={
          p.bold
            ? {
                display: "block",
                fontWeight: 700,
              }
            : undefined
        }
      />
    );
  }
);

export default FormLabel;
