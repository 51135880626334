/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

export function reportError(e: string | Error | unknown) {
  console.error(e);
  Sentry.captureException(e);
}

export function runAndIgnoreError(fn?: () => void) {
  try {
    fn && fn();
  } catch (e) {
    console.warn("An error was ignored:", e);
  }
}

export const isNetworkError = (e: unknown) => {
  return (
    e && e instanceof Object && (e as AxiosError).message === "Network Error"
  );
};

export const isUnauthorizedError = (e: unknown) => {
  return (e as AxiosError).response?.status === 401;
};
export const isAccessDeniedError = (e: unknown) => {
  return (e as AxiosError).response?.status === 403;
};
export const isAuthError = (e: unknown) => {
  return (
    (e as AxiosError).response?.status === 401 ||
    (e as AxiosError).response?.status === 403
  );
};

export const readAxiosErrorMessage = (e: unknown, fallback?: string) =>
  `${
    ((e as AxiosError).response?.data as { message: string } | undefined)
      ?.message ??
    (e as AxiosError).response?.data ??
    fallback ??
    (e as AxiosError).message
  }`;
