/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { action, autorun } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useMaybeComposer } from "../../components/composer/ComposerApp.context";
import {
  bg30,
  fg20,
  fg30,
} from "../../constants/cssCustomProperties.constants";
import { FormControlProps } from "../@types";
import { UNITS } from "../constants/units.constant";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useControllers } from "../hooks/rootContext.hooks";
import { useObservableRef } from "../hooks/useObservableRef.hook";
import cx from "../utils/className.utils";
import { debounce } from "../utils/debounce.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import { isNotNil } from "../utils/ramdaEquivalents.utils";
import FormLabel from "./FormLabel";

type ColorInputProps<T extends {} = {}> = FormControlProps<T, string> & {
  Label?: React.ReactNode;
  inline?: boolean;
  isEmpty?: boolean;
  SwatchLabel?: React.ReactNode;
  sideEffectOnBeforeApplyingValue?: (newValue: string) => void;
};

const ColorInputOuter = styled.div`
  border-radius: 2px;
  &.inline {
    display: inline-block;
  }
  label {
    margin-bottom: 0.5em;
  }
`;
const ColorInputInner = styled.div`
  position: relative;
  min-width: 2em;
  height: 2.5em;
  border-radius: inherit;
`;

const ColorInputInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const Swatch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: ${UNITS.lineWidth}px solid ${fg20};
  border-radius: inherit;
  font-weight: 500;
  font-size: 85%;
  .empty & {
    background-image: linear-gradient(
      to bottom right,
      transparent 47.5%,
      ${fg30} 47.5%,
      ${fg30} 49%,
      ${bg30} 50%,
      ${bg30} 51.5%,
      transparent 51.5%
    );
  }
  &:not(.disabled) {
    @media (hover: hover) {
      &:hover {
        border-color: ${fg30};
        filter: brightness(1.1);
      }
    }
  }
`;

const ColorInput = <T extends {} = {}>(
  props: React.PropsWithChildren<ColorInputProps<T>>
) => {
  const { THEME } = useControllers();
  const p = useProps(props);
  const I = useMaybeComposer();
  const inputRef = useObservableRef<HTMLInputElement>();
  const s = useStore(() => ({
    get outerValue(): string {
      if (p.getter) return `${p.getter()}`;
      if (p.form && isNotNil(p.field))
        return (p.form[p.field] === null ? "" : p.form[p.field]) as string;
      return "";
    },
    set outerValue(v: string) {
      if (p.setter) p.setter(v);
      if (p.form && isNotNil(p.field)) Reflect.set(p.form, p.field, v);
    },
    _innerValue: "" as string,
    get innerValue(): string {
      return s._innerValue;
    },
    set innerValue(v: string) {
      // TODO: check color validity
      s._innerValue = v;
    },
    handleChange: (e: React.FormEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.value;
      s.innerValue = newValue;
      s.applyValueToOuter();
    },
    applyValueToOuter: debounce(
      action(() => {
        const fn = action(() => {
          if (s.outerValue === s.innerValue) return;
          p.sideEffectOnBeforeApplyingValue?.(s.innerValue);
          const prev = s.outerValue;
          s.outerValue = s.innerValue;
          p.onChange?.(s.innerValue, prev);
        });
        const taskName = p.mergeableTaskName ?? p.taskName;
        if (taskName && I)
          I.runInHistory(taskName, fn, {
            mergeableId: p.mergeableTaskName ?? p.mergeableId,
          });
        else fn();
      }),
      {
        fireImmediately: true,
        duration: 100,
      }
    ),
    handleSwatchClick: () => {
      inputRef.current?.click();
    },
  }));

  useOnMount(() =>
    autorun(() => {
      s.innerValue = s.outerValue;
    })
  );

  return (
    <Observer
      children={() => (
        <ColorInputOuter
          className={cx(
            "ColorInput",
            p.inline && "inline",
            p.isEmpty && "empty"
          )}
        >
          {p.Label && <FormLabel bold>{p.Label}</FormLabel>}
          <ColorInputInner>
            <ColorInputInput
              id={p.id}
              type="color"
              value={s.innerValue}
              onChange={s.handleChange}
              ref={inputRef}
              disabled={p.disabled}
            />
            <Swatch
              className={cx(p.disabled && "disabled")}
              style={{
                backgroundColor: s.innerValue,
                color: THEME.getContrastColor(s.innerValue),
              }}
              onClick={s.handleSwatchClick}
            >
              {props.SwatchLabel}
            </Swatch>
          </ColorInputInner>
        </ColorInputOuter>
      )}
    />
  );
};

export default ColorInput;
