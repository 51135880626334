/** @jsxImportSource @emotion/react */
import { first } from "lodash-es";
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, Group } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import Button from "../../base/components/Button";
import DraggableNumberInput from "../../base/components/DraggableNumberInput";
import PillTag from "../../base/components/PillTag";
import Spacing from "../../base/components/Spacing";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useMixedValueFormState } from "../../base/utils/form.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import {
  autoPluralize,
  autoPluralizeWithNumber,
} from "../../base/utils/string.utils";
import AtomBarRangeDisplay from "../composer/AtomBarRangeDisplay";
import AtomRulesetEditor from "../composer/AtomRulesetEditor";
import AtomVoiceSelector from "../composer/AtomVoiceSelector";
import ReferrerCounter from "../composer/ReferrerCounter";
import { useComposer } from "../composer/ComposerApp.context";

interface GroupPropertyPanelProps {
  atom?: Atom;
  atoms?: Atom[];
}

const style = {
  grid: {
    display: "grid",
    gridGap: "1em",
    gridTemplateColumns: "1fr 1fr 1fr",
    fontSize: "1.2rem",
  } as CSSPartial,
  header: {
    display: "flex",
    justifyContent: "space-between",
  } as CSSPartial,
  headerInner: {
    paddingRight: "1em",
  } as CSSPartial,
  title: {
    fontSize: "1.6rem",
  } as CSSPartial,
};

const GroupPropertyPanel: React.FC<GroupPropertyPanelProps> = props => {
  const { SETTINGS } = useControllers();
  const p = useProps(props);
  const I = useComposer();
  const _ = useStore(() => ({
    get groups(): Group[] {
      return [p.atom, ...(p.atoms ?? [])].filter(i => i) as Group[];
    },
  }));

  const form = useMixedValueFormState(
    () => _.groups,
    ["x", "y", "z", "width", "_isHidden", "name"]
  );

  const s = useStore(() => ({
    get ids() {
      return _.groups.map(n => n._id).join(",");
    },
    get firstGroup() {
      return first(_.groups) as Group;
    },
    get hasExactlyOneGroup() {
      return _.groups.length === 1;
    },
    get title(): string {
      return _.groups.length > 1
        ? `${autoPluralizeWithNumber(_.groups, "group")}`
        : s.firstGroup.displayName;
    },
    createPatternFromGroup: () => {
      if (!s.firstGroup?.context) return;
      const pattern = s.firstGroup.context.createPattern(
        s.hasExactlyOneGroup ? s.firstGroup : _.groups
      );
      if (pattern)
        s.firstGroup.context.composer?.tools.select.updateSelection({
          debugCaller: "selectPatternAfterCreation",
          mode: "replace",
          atoms: [pattern],
        });
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="GroupPropertyPanel">
          <header css={style.header}>
            <div css={style.headerInner}>
              <h3 css={style.title}>{s.title}</h3>
              {s.hasExactlyOneGroup && (
                <p>
                  <strong>
                    <AtomBarRangeDisplay atom={s.firstGroup} />
                    {s.firstGroup.referrers.length > 0 && (
                      <>
                        , <ReferrerCounter atom={s.firstGroup} />
                      </>
                    )}
                  </strong>
                </p>
              )}
            </div>
            {s.hasExactlyOneGroup && (
              <div>
                <PillTag color={s.firstGroup.appearance?.colorInContext}>
                  #{s.firstGroup._id}
                </PillTag>
              </div>
            )}
          </header>
          <Spacing />
          <TextInput
            form={form}
            field="name"
            Label="Name"
            autoComplete="off"
            disabled={I.editDisabled}
          />
          <Spacing />
          <div css={style.grid}>
            <DraggableNumberInput
              form={form}
              field="x"
              Label="X"
              step={SETTINGS.composer.snapUnitX / 2}
              deltaScalar={0.1}
              precision={3}
              taskName={`Adjust ${autoPluralize(
                _.groups,
                "group X",
                undefined,
                "X value of groups"
              )}`}
              mergeableId={`adjust-group-x-${s.ids}`}
              fullWidth
              disabled={I.editDisabled}
            />
            <TextInput
              form={form}
              field="y"
              Label="Y"
              step={1}
              type="number"
              taskName={`Adjust ${autoPluralize(
                _.groups,
                "group Y",
                undefined,
                "Y value of groups"
              )}`}
              mergeableId={`adjust-group-y-${s.ids}`}
              disabled={I.editDisabled}
            />
            {/* {s.hasExactlyOneGroup && (
              <GroupLikeAtomChildrenSpacingEditor atom={s.firstGroup} />
            )} */}
          </div>
          <Spacing />
          <AtomVoiceSelector
            atoms={_.groups}
            fullWidth
            disabled={I.editDisabled}
          />
          <Spacing />
          {I.editable && (
            <>
              <Button fullWidth onClick={s.createPatternFromGroup}>
                ❖ Create pattern from {autoPluralize(_.groups, "group")}
              </Button>
              <Spacing />
            </>
          )}
          <AtomRulesetEditor atoms={_.groups} />
        </div>
      )}
    />
  );
};

export default GroupPropertyPanel;
