import { Note } from "../../@types";
import { OrnamentationType } from "../../constants/ornaments.constants";
import { Ornamentation_GraceNoteDef } from "./appoggiatura.ornamentDef";
import { Ornamentation_NullDef } from "./null.ornamentDef";
import { Ornamentation_TrillDef } from "./trill.ornamentDef";
import { Ornamentation_ShortTrillDef } from "./shortTrill.ornamentDef";
import { Ornamentation_TurnDef } from "./turn.ornamentDef";
import { Ornamentation_CustomDef } from "./custom.ornamentDef";
import { Ornamentation_MordentDef } from "./mordent.ornamentDef";

export const ornamentationDefMap = new Map([
  [OrnamentationType.null, Ornamentation_NullDef],
  [OrnamentationType.appoggiatura, Ornamentation_GraceNoteDef],
  [OrnamentationType.mordent, Ornamentation_MordentDef],
  [OrnamentationType.turn, Ornamentation_TurnDef],
  [OrnamentationType.shortTrill, Ornamentation_ShortTrillDef],
  [OrnamentationType.trill, Ornamentation_TrillDef],
  [OrnamentationType.custom, Ornamentation_CustomDef],
]);

export const ornamentationDefs = Array.from(ornamentationDefMap.values());

export const getOrnamentDefByIdentifier = (identifier: string) => {
  return ornamentationDefMap.get(identifier as OrnamentationType) ?? null;
};

export const generateOrnamentNoteSnapshots = (
  note: Note,
  type: OrnamentationType
) => {
  const generator = getOrnamentDefByIdentifier(type)?.generator;
  if (note.x === null || note.y === null) return [];
  if (!generator) {
    console.warn(
      `Ornament generator for type ${type} has not yet been implemented.`
    );
    return [];
  }
  return generator(note).map(n => ({
    x: n.x as number,
    y: n.y as number,
    width: n.width,
    velocity: n.velocity as number,
  }));
};
