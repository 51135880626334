/** @jsxImportSource @emotion/react */
import React from "react";

type CompositionListIcon27Props = {};

const CompositionListIcon27: React.FC<CompositionListIcon27Props> = props => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2H11V1H1V2ZM16 6L16 5.5H16.5C18.5 7.5 20.5 8 24 8L25 8V11L19 11H18.999C17.8954 11.0005 17.0009 11.8949 17 12.9984L17 13V17V22.5C17 24.9853 14.9853 27 12.5 27C10.0147 27 8 24.9853 8 22.5C8 20.0147 10.0147 18 12.5 18C12.669 18 12.8358 18.0093 13 18.0275C13.2649 18.0568 13.523 18.109 13.772 18.1823L13.7801 18.1847C14.6808 18.4514 15.1311 18.5848 15.2292 18.5875C15.7035 18.6007 15.8527 18.4893 15.9747 18.0308C15.9995 17.938 16 17.624 16 17V11V9V8L16 6ZM11 6H1V5H11V6ZM1 10H9V9H1V10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CompositionListIcon27;
