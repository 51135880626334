/** @jsxImportSource @emotion/react */
import { when } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { CSSProperties, ReactNode } from "react";
import { useMaybeComposer } from "../../components/composer/ComposerApp.context";
import {
  VAR_InputBackground,
  VAR_InputBorder,
  VAR_InputFocusOutline,
  VAR_InputForeground,
  fg50,
  varPrimary,
  varPrimary20,
} from "../../constants/cssCustomProperties.constants";
import { QueueableTask } from "../../controllers/composer/queue.controller";
import { FormControlProps } from "../@types";
import { CSSPartial } from "../@types/css.types";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useObservableRef } from "../hooks/useObservableRef.hook";
import cx from "../utils/className.utils";
import { withOpacity } from "../utils/colors.utils";
import { cVar } from "../utils/customProperties.utils";
import { useProps, useStore, useStyle } from "../utils/mobx.utils";
import { isNotNil } from "../utils/ramdaEquivalents.utils";
import { getRandomNumericString } from "../utils/random.utils";
import FormLabel from "./FormLabel";
import ResetButton from "./ResetButton";
import SpaceBetween from "./SpaceBetween";

export type TextareaProps<F extends {} = {}, V = unknown> = FormControlProps<
  F,
  V
> & {
  name?: string;
  type?: HTMLTextAreaElement["type"];
  Label?: ReactNode;
  autoComplete?: HTMLTextAreaElement["autocomplete"];
  autoFocus?: boolean;
  autoCapitalize?: HTMLTextAreaElement["autocapitalize"];
  required?: boolean;
  optional?: boolean;
  selectContentOnFocus?: boolean;
  rows?: HTMLTextAreaElement["rows"];
  onBlur?: (value: string) => void;
  dataAttributes?: Record<`data-${string}`, string | number | boolean>;
  resize?: CSSProperties["resize"];
  resettable?: boolean;
  defaultValue?: string;
};

const Textarea = <F extends AnyObject = AnyObject>(
  props: React.PropsWithChildren<TextareaProps<F>>
) => {
  const p = useProps(props);
  const I = useMaybeComposer();
  const s = useStore(() => ({
    get name() {
      return p.name ?? p.field ?? getRandomNumericString();
    },
    get value(): string | undefined {
      return p.getter
        ? `${p.getter()}`
        : p.form && p.field
        ? `${p.form[p.field]}`
        : undefined;
    },
    set value(v) {
      p.setter
        ? p.setter(`${v}`)
        : p.form && p.field
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (p.form[p.field] = `${v}` as any)
        : void undefined;
    },
    handleChange: (e: React.FormEvent<HTMLTextAreaElement>) => {
      s.value = (e.target as HTMLTextAreaElement).value;
    },
    hasFocus: false,
    task: null as QueueableTask | null,
    handleFocus: () => {
      s.hasFocus = true;
      const taskName = p.mergeableTaskName ?? p.taskName;
      if (taskName)
        s.task =
          I?.queue.createTaskInHistory(taskName, {
            mergeableId: p.mergeableTaskName ?? p.mergeableId,
          }) ?? null;
    },
    handleBlur: () => {
      s.hasFocus = false;
      p.onBlur?.(`${s.value ?? ""}`);
      if (s.task) {
        s.task.commit();
        s.task = null;
      }
    },
  }));
  const ref = useObservableRef<HTMLTextAreaElement>();
  useOnMount(() => {
    const setupAutoResize = () => {
      const el = ref.current!;
      el.setAttribute(
        "style",
        `height:${el.scrollHeight}px;overflow-y:hidden;`
      );
      el.addEventListener(
        "input",
        () => {
          el.style.height = "auto";
          el.style.height = `${el.scrollHeight}px`;
        },
        false
      );
    };
    return when(
      () => !!ref.current,
      () => {
        if (p.selectContentOnFocus) {
          ref.current!.select();
          setupAutoResize();
        }
      }
    );
  });
  const style = useStyle(() => ({
    get wrapper() {
      return {
        label: {
          marginBottom: "0.5em",
          color: p.resettable ? varPrimary : "currentcolor",
        },
      };
    },
    get textarea(): CSSPartial {
      return {
        display: "block",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: cVar(VAR_InputBackground),
        color: cVar(VAR_InputForeground),
        border: cVar(VAR_InputBorder),
        borderColor: p.color ? withOpacity(p.color, 0.2) : undefined,
        borderRadius: 2,
        fontFeatureSettings: "'tnum' 1",
        padding: ".5em",
        resize: p.resize,
        lineHeight: "1.2",
        minHeight: "2.2em",
        "&:focus": {
          outline: cVar(VAR_InputFocusOutline),
          backgroundColor: p.color ? withOpacity(p.color, 0.2) : varPrimary20,
          outlineColor: p.color,
        },
        "&[disabled]": {
          color: fg50,
        },
      };
    },
  }));
  return (
    <Observer
      children={() => (
        <div
          className={cx("Textarea", p.className)}
          css={style.wrapper}
          {...p.dataAttributes}
        >
          {(p.Label || p.resettable) && (
            <FormLabel htmlFor={p.name} bold>
              {p.resettable && p.form && isNotNil(p.field) ? (
                <SpaceBetween>
                  {p.Label}
                  <ResetButton
                    form={p.form}
                    field={p.field}
                    default={p.defaultValue}
                  />
                </SpaceBetween>
              ) : (
                <>{p.Label}</>
              )}
            </FormLabel>
          )}
          <textarea
            name={p.name}
            id={p.id ?? p.name}
            value={s.value}
            onChange={s.handleChange}
            autoComplete={props.autoComplete}
            autoFocus={props.autoFocus}
            autoCapitalize={props.autoCapitalize}
            onFocus={s.handleFocus}
            onBlur={s.handleBlur}
            rows={props.rows}
            ref={ref}
            css={style.textarea}
            disabled={p.disabled}
          />
        </div>
      )}
    />
  );
};

export default Textarea;
