import { action, observable } from "mobx";
import {
  Atom,
  AtomContext,
  AtomType,
  Group,
  GroupLikeAtom,
  GroupLikeType,
  GroupShape,
  Instrument,
  Note,
  TimedAtomInterpretedProperties,
} from "../../@types";
import { add, applyFormula, getSign, round } from "../../base/utils/math.utils";
import {
  isNotNil,
  meanOfArray,
  uniq,
} from "../../base/utils/ramdaEquivalents.utils";
import { isNumber } from "../../base/utils/typeChecks.utils";
import {
  createAtomFactory,
  makeAtomBaseSnapshot,
} from "../../logic/Atom.factory";
import {
  AtomSorter,
  atomSorterByEndX,
  getInterpretedInstrumentsOfAtom,
  sortAtoms,
} from "../../logic/atomFactoryMethods";
import { adjustAtomArrayX } from "../../operations/adjustAtomX.operation";
import { adjustAtomArrayY } from "../../operations/adjustAtomY.operation";
import {
  getAtomSetX,
  getAtomSetX2,
  getAtomSetY,
  getAtomSetY2,
  getInterpretedBpmOfAtom,
  getInterpretedBpxOfAtom,
  getInterpretedXpmOfAtom,
  isBarAtom,
  isGroupLikeAtom,
  isKeyframeAtom,
  isMusicalAtom,
  isNoteAtom,
  isNoteOrKeyframeAtom,
  isVoiceAtom,
} from "../../utils/atoms.utils";
import { syncableGroupLikeAtomInitFunction } from "../../utils/syncableGroupLikeAtoms.utils";
import { ValidRect } from "../../base/@types";
import { makeRect } from "../geometry/makeRect.model";

export const GroupSnapshotFactory = (type?: GroupLikeType) => ({
  ...makeAtomBaseSnapshot(type ?? AtomType.group),
});

export const makeGroupExtendedMembersFactory = (G: GroupLikeAtom) => {
  const children = observable([]);
  return {
    get displayName() {
      return G.name?.trim() || `Group ${G._id}`;
    },
    set displayName(v) {
      G.name = `${v}`;
    },
    get x() {
      return getGroupX(G);
    },
    set x(v) {
      setGroupX(v, G);
    },
    get y() {
      return getGroupY(G);
    },
    set y(v) {
      setGroupY(v, G);
    },
    get x2() {
      return getGroupX2(G);
    },
    get y2() {
      return getGroupY2(G);
    },
    get width() {
      return getGroupWidth(G);
    },
    get height() {
      return getGroupHeight(G);
    },
    get startX() {
      return getGroupStartX(G);
    },
    get startY() {
      return getGroupStartY(G);
    },
    get endX() {
      return getGroupEndX(G);
    },
    get endY() {
      return getGroupEndY(G);
    },
    get children() {
      return children;
    },
    get childrenSorted() {
      return [...G.children].sort(AtomSorter);
    },
    get childrenIds() {
      return G.children.map(c => c._id);
    },
    get childrenSpacing() {
      return getComputedGroupChildrenSpacing(G);
    },
    set childrenSpacing(v) {
      setGroupChildrenSpacing(v, G);
    },
    get descendantNotesYMean() {
      return getGroupDescendantNotesCenterYMean(G);
    },
    get descendants() {
      return getDescendantAtomsOfGroup(G);
    },
    get descendantsReversed() {
      return [...G.descendants].reverse();
    },
    get nonGroupDescendants() {
      return getNonGroupDescendantsOfGroup(G);
    },
    get descendantNotes() {
      return getDescendantNotesOfGroup(G);
    },
    get descendantNotesReversed() {
      return [...G.descendantNotes].reverse();
    },
    get descendantNotesSortedByEndX() {
      return sortAtoms(G.descendantNotes, atomSorterByEndX);
    },
    get descendantKeyframes() {
      return getDescendantKeyframesOfGroup(G);
    },
    get descendantNotesAndKeyframes() {
      return getDescendantNotesAndKeyframesOfGroup(G);
    },
    get descendantNotesAndKeyframesSortedByEndX() {
      return sortAtoms(G.descendantNotesAndKeyframes, atomSorterByEndX);
    },
    get voice() {
      return getVoiceOfGroup(G);
    },
    get shape() {
      return getGroupShape(G);
    },
    get isEmpty() {
      return getGroupEmptiness(G);
    },
    get timeStartInSeconds() {
      return getGroupTimeStartInSeconds(G);
    },
    get timeEndInSeconds() {
      return getGroupTimeEndInSeconds(G);
    },
    pushAtom: (atom: Atom) => atom.addParents(G),
    pushAtoms: (...atoms: Atom[]) => pushAtomsToGroup(G, ...atoms),
    interpreted: observable({
      get instruments(): Instrument[] {
        return getInterpretedInstrumentsOfAtom(G);
      },
      get arpeggioOffset() {
        return 0;
      },
      get startX() {
        return applyFormula(G.startX, G.rulePropertiesFlattened.start ?? "");
      },
      get endX() {
        return add(G.interpreted.startX, G.interpreted.width);
      },
      get width() {
        return applyFormula(
          G.width,
          G.rulePropertiesFlattened.width ?? ""
        ) as number;
      },
      get timeStartInSeconds() {
        return getGroupTimeStartInSecondsInterpreted(G);
      },
      get timeEndInSeconds() {
        return getGroupTimeEndInSecondsInterpreted(G);
      },
      get durationInSeconds() {
        if (
          G.interpreted.timeStartInSeconds === null ||
          G.interpreted.timeEndInSeconds === null
        )
          return 0;
        return (
          G.interpreted.timeEndInSeconds - G.interpreted.timeStartInSeconds
        );
      },
      get bpm() {
        return getInterpretedBpmOfAtom(G);
      },
      get bpx() {
        return getInterpretedBpxOfAtom(G);
      },
      get xpm() {
        return getInterpretedXpmOfAtom(G);
      },
    }) as TimedAtomInterpretedProperties,
    get hitBox() {
      const childrenHitBoxes = G.children
        .map(c => c.hitBox)
        .filter(i => !!i) as ValidRect[];
      if (childrenHitBoxes.length === 0) return null;
      const minX = Math.min(...childrenHitBoxes.map(c => c[0].x));
      const maxX = Math.max(...childrenHitBoxes.map(c => c[1].x));
      const minY = Math.min(...childrenHitBoxes.map(c => c[0].y));
      const maxY = Math.max(...childrenHitBoxes.map(c => c[1].y));
      return makeRect(minX, minY, maxX, maxY) as ValidRect;
    },
  };
};

export const getGroupX = (g: GroupLikeAtom) => getAtomSetX(g.children);
export const setGroupX = (v: number | null, g: GroupLikeAtom) => {
  if (v === null || g.x === null) return;
  const delta = v - g.x;
  adjustAtomArrayX(g.children, delta);
};
export const getGroupY = (g: GroupLikeAtom) => getAtomSetY(g.children);
export const setGroupY = (v: number | null, g: GroupLikeAtom) => {
  if (v === null || g.y === null) return;
  const delta = v - g.y;
  adjustAtomArrayY(g.children, delta);
};
export const getGroupX2 = (g: GroupLikeAtom) => getAtomSetX2(g.children);
export const getGroupY2 = (g: GroupLikeAtom) => getAtomSetY2(g.children);
export const getGroupStartX = (g: GroupLikeAtom) =>
  g.x !== null && g.x2 !== null ? Math.min(g.x, g.x2) : null;
export const getGroupStartY = (g: GroupLikeAtom) =>
  g.y !== null && g.y2 !== null ? Math.min(g.y, g.y2) : null;
export const getGroupEndX = (g: GroupLikeAtom) =>
  g.x !== null && g.x2 !== null ? Math.max(g.x, g.x2) : null;
export const getGroupEndY = (g: GroupLikeAtom) =>
  g.y !== null && g.y2 !== null ? Math.max(g.y, g.y2) : null;
export const getGroupWidth = (g: GroupLikeAtom) =>
  Math.abs(
    (g.endX === null ? Infinity : g.endX) - (g.startX === null ? 0 : g.startX)
  );
export const getGroupHeight = (g: GroupLikeAtom) =>
  Math.abs(
    (g.endY === null ? Infinity : g.endY) - (g.startY === null ? 0 : g.startY)
  );
export const getGroupTimeStartInSeconds = (g: GroupLikeAtom) =>
  Math.min(
    ...(g.descendants
      .map(n =>
        isMusicalAtom(n) || isBarAtom(n) ? n.timeStartInSeconds : null
      )
      .filter(i => isNotNil(i)) as number[])
  );
export const getGroupTimeStartInSecondsInterpreted = (g: GroupLikeAtom) =>
  Math.min(
    ...(g.descendants
      .map(n =>
        isMusicalAtom(n) || isBarAtom(n)
          ? n.interpreted.timeStartInSeconds
          : null
      )
      .filter(i => isNotNil(i)) as number[])
  );
export const getGroupTimeEndInSeconds = (g: GroupLikeAtom) =>
  Math.min(
    ...(g.descendants
      .map(n => (isMusicalAtom(n) || isBarAtom(n) ? n.timeEndInSeconds : null))
      .filter(i => isNotNil(i)) as number[])
  );
export const getGroupTimeEndInSecondsInterpreted = (g: GroupLikeAtom) =>
  Math.min(
    ...(g.descendants
      .map(n =>
        isMusicalAtom(n) || isBarAtom(n) ? n.interpreted.timeEndInSeconds : null
      )
      .filter(i => isNotNil(i)) as number[])
  );
export const getAtomsInContext = (
  ids: string[],
  context?: AtomContext | null
) =>
  ids
    .map(id => context?.atomsSortedByX.find(n => n._id === id))
    .filter(i => i) as unknown as Atom[];

export const pushAtomsToGroup = (g: GroupLikeAtom, ...atoms: Atom[]) => {
  atoms.forEach(n => n.addParents(g));
};

export const getDescendantAtomsOfGroup = (g: GroupLikeAtom) => {
  return uniq(
    (isVoiceAtom(g) && g.childVoices.length > 0 ? g.childVoices : g.children)
      .map(a => (isGroupLikeAtom(a) ? [a, ...a.descendants] : a))
      .flat()
      .filter(a => a !== g)
  );
};
export const getNonGroupDescendantsOfGroup = (g: GroupLikeAtom) => {
  return g.descendants.filter(n => !isGroupLikeAtom(n)) as Note[];
};
export const getDescendantNotesOfGroup = (g: GroupLikeAtom) => {
  return g.descendants.filter(isNoteAtom);
};
export const getDescendantKeyframesOfGroup = (g: GroupLikeAtom) => {
  return g.descendants.filter(isKeyframeAtom);
};
export const getDescendantNotesAndKeyframesOfGroup = (g: GroupLikeAtom) => {
  return g.descendants.filter(isNoteOrKeyframeAtom);
};
export const getGroupDescendantNotesCenterYMean = (g: GroupLikeAtom) => {
  if (isVoiceAtom(g) && g.childVoices.length > 0) {
    return meanOfArray(
      g.childVoices.map(n => n.centerY).filter(i => isNumber(i)) as number[]
    );
  } else {
    if (g.descendantNotes.length === 0) return 0;
    return meanOfArray(
      g.descendantNotes.map(n => n.centerY).filter(i => isNumber(i)) as number[]
    );
  }
};
export const getVoiceOfGroup = (g: GroupLikeAtom) => {
  if (isVoiceAtom(g)) return g;
  const voices = g.descendantNotes.map(n => n.voice).filter(v => v);
  const hasOnlyOneVoice = new Set(voices).size === 1;
  if (hasOnlyOneVoice) return voices[0];
  const allParentVoices = uniq(voices.map(v => v?.parentVoice));
  if (allParentVoices.length === 1) return allParentVoices[0];
  return null;
};
export const getGroupShape = (g: GroupLikeAtom): GroupShape => {
  const { descendantNotes: notes } = g;
  let lastPlayableNoteMidiNumber = null as number | null;
  const result: GroupShape = {
    melody: [],
    melodyDirection: [],
    rhythm: [],
  };
  for (let i = 0; i < notes.length; i++) {
    const note = notes[i];
    result.rhythm.push(note.beatCount);
    if (note.midiNumber === null) {
      result.melody.push(null);
      result.melodyDirection.push(null);
      continue;
    } else if (i === 0) {
      result.melody.push(0);
      result.melodyDirection.push(0);
      continue;
    }
    const prev = notes[i - 1];
    const prevMidiNum = prev.midiNumber;
    if (prevMidiNum === null) {
      if (lastPlayableNoteMidiNumber === null) {
        result.melody.push(null);
        result.melodyDirection.push(null);
        continue;
      }
    } else {
      lastPlayableNoteMidiNumber = prevMidiNum;
    }
    result.melody.push(note.midiNumber - lastPlayableNoteMidiNumber);
    result.melodyDirection.push(
      getSign(note.midiNumber - lastPlayableNoteMidiNumber)
    );
  }
  return result;
};

export const getGroupEmptiness = (g: GroupLikeAtom) => {
  return g.children.length === 0;
};

export const getComputedGroupChildrenSpacing = (g: GroupLikeAtom) => {
  const allNonNullSpacing = g.childrenSorted
    .map((c, i) => {
      if (i === 0 || c.startX === null) return null;
      const prev = g.children[i - 1];
      if (prev.endX === null) return null;
      return round(c.startX - prev.endX, 4);
    })
    .filter(i => i !== null) as number[];
  if (allNonNullSpacing.length === 0) return null;
  if (allNonNullSpacing.every(s => s === allNonNullSpacing[0]))
    return allNonNullSpacing[0];
  return null;
};

export const setGroupChildrenSpacing = action(
  (v: number | null, g: GroupLikeAtom) => {
    const spacing = v ?? 0;
    if (g.children.length === 0) return;
    g.childrenSorted.forEach((c, i) => {
      if (i === 0) return;
      const prev = g.childrenSorted[i - 1];
      if (prev.endX === null) return;
      c.$.x = prev.endX + spacing;
    });
  }
);

export const makeGroup = createAtomFactory<Group>({
  type: AtomType.group,
  snapshotFactory: GroupSnapshotFactory,
  extendedPropertiesFactories: [makeGroupExtendedMembersFactory],
  init: syncableGroupLikeAtomInitFunction,
});
