import { Bar } from "../@types";
import { DialogsController } from "../controllers/dialogs.controller";

export const askForBarDeletionOptions = async (
  DIALOGS: DialogsController,
  barsToDelete: Bar[]
) => {
  const barName = barsToDelete.length > 1 ? "bars" : "bar";
  const barsHaveContent = barsToDelete.some(
    b => b.atomsStartingInThisBar.length > 0
  );
  if (!barsHaveContent) return true;
  return await DIALOGS.present<boolean | null>({
    Heading: `Deleting ${barName}`,
    Body: `Do you wish to delete the contents of the ${barName}, or just the ${barName} ${
      barsToDelete.length > 1 ? "themselves" : "itself"
    }?`,
    actions: [
      {
        label: `Cancel`,
        isActionForEscape: false,
        action: () => null,
      },
      {
        label: `Just the ${barName}`,
        action: () => false,
      },
      {
        label: `${barName} and contents`,
        buttonColorMode: "solid",
        isActionForEnter: true,
        action: () => true,
      },
    ],
  });
};
