/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { CSSProperties, PropsWithChildren } from "react";
import { CSSPartial } from "../@types/css.types";

type SpaceChildrenProps = {
  size?: string | number;
};

const style = {
  "> * + *": {
    marginTop: "var(--SpaceChildrenSize)",
  },
} as CSSPartial;

const SpaceChildren: React.FC<
  PropsWithChildren<SpaceChildrenProps>
> = props => {
  return (
    <Observer
      children={() => (
        <div
          css={style}
          style={
            {
              "--SpaceChildrenSize": props.size ?? "1em",
            } as CSSProperties
          }
        >
          {props.children}
        </div>
      )}
    />
  );
};

export default SpaceChildren;
