import { toJS } from "mobx";
import { getNoteSnapshotAfterAddingOrSubtractingStepsInScale } from "../../utils/musicScale.utils";
import {
  OrnamentationDef,
  OrnamentationType,
} from "../../constants/ornaments.constants";

export const Ornamentation_MordentDef: OrnamentationDef = {
  identifier: OrnamentationType.mordent,
  displayName: "Mordent",
  defaultSymbol: "",
  symbolFactory: orn => "",
  generator: (note, options) => {
    const $ = toJS(note.$);
    const lowerNoteBase = getNoteSnapshotAfterAddingOrSubtractingStepsInScale(
      note,
      -1
    );
    if (!note.width) return [];
    const ornamentNoteWidth =
      options?.ornamentNoteWidth ??
      Math.min(note.width * (note.width <= 0.25 ? 1 / 3 : 1 / 4), 0.125);
    return [$, lowerNoteBase, $].map((snapshot, i) => ({
      ...snapshot,
      x: note.x! + i * ornamentNoteWidth,
      width: i === 2 ? note.width! - ornamentNoteWidth * 2 : ornamentNoteWidth,
      velocity: note.velocity,
      appearance: note.appearance,
    }));
  },
};
