import { StandardModel } from "../../models/StandardModel";
import { Has_Id } from "../../traits/hasId.trait";
import { isArray } from "./typeChecks.utils";

export type RecordMap<T = UnknownObject> = Map<string, T>;

export const makeRecordMap = <T extends Has_Id>(items?: T[]): RecordMap<T> =>
  new Map<string, T>(items?.map(i => [i._id, i]));

export const convertRecordMapToArray = <T extends StandardModel>(
  map?: RecordMap<T> | T[] | null
) => (isArray(map) ? map : Array.from(map?.values() || []));

export const convertSnapshotArrayToMap = <T extends Has_Id>(arr: T[]) => {
  const result = {} as UnknownObject;
  arr.forEach(i => (result[i._id] = i));
  return result;
};
export const convertArrayWithIdToMap = <T extends { id: string }>(arr: T[]) => {
  const result = new Map<string, T>();
  arr.forEach(i => result.set(i.id, i));
  return result;
};

export const retrieveFromRecordMap = <T extends Has_Id>(
  map?: RecordMap<T>,
  ...ids: string[]
) => {
  const collection = makeRecordMap<T>();
  if (!map) return collection;
  ids.forEach(id => {
    const value = map.get(id);
    if (value) collection.set(id, value);
  });
  return collection;
};

export const findInMapByKey = <KeyType = string, ValueType = unknown>(
  map: Map<KeyType, ValueType> | undefined,
  predicate: (key: KeyType, value?: ValueType) => unknown
) => {
  if (!map) return undefined;
  const iterator = map.entries();
  let current = iterator.next();
  while (!current.done) {
    const entry = current.value;
    if (Boolean(predicate(entry[0], entry[1]))) return entry;
    current = iterator.next();
  }
};
export const findInMapByValue = <KeyType = string, ValueType = unknown>(
  map: Map<KeyType, ValueType> | undefined,
  predicate: (value: ValueType, key?: KeyType) => unknown
) => {
  if (!map) return undefined;
  const iterator = map.entries();
  let current = iterator.next();
  while (!current.done) {
    const entry = current.value;
    if (Boolean(predicate(entry[1], entry[0]))) return entry;
    current = iterator.next();
  }
};

export const findAllInMapByValue = <KeyType = string, ValueType = unknown>(
  map: Map<KeyType, ValueType> | null,
  predicate: (value: ValueType, key?: KeyType) => unknown
) => {
  if (!map) return new Map();
  const iterator = map.entries();
  let current = iterator.next();
  const result = new Map<KeyType, ValueType>();
  while (!current.done) {
    const entry = current.value;
    if (Boolean(predicate(entry[1], entry[0]))) result.set(entry[0], entry[1]);
    current = iterator.next();
  }
  return result;
};

export const getFirstValueInMap = <KeyType = string, ValueType = unknown>(
  map: Map<KeyType, ValueType>
): ValueType | undefined => {
  const iterator = map.entries();
  const first = iterator.next().value as ValueType[];
  return first ? first[1] : undefined;
};

export const addRecordsToMap = <V extends Has_Id>(
  map: Map<string, V>,
  ...items: V[]
) => {
  items.forEach(i => map.set(i._id, i));
};
