import { makeIconDef } from "./makeIconDef";

export const BoundingBoxIconDef = {
  "bounding-box": makeIconDef("bounding-box", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4H5V5H4V4ZM3 3H4H5H6V4H11V3H12H13H14V4H19V3H20H21H22V4V5V6H21V11H22V12V13V14H21V19H22V20V21V22H21H20H19V21H14V22H13H12H11V21H6V22H5H4H3V21V20V19H4V14H3V13V12V11H4V6H3V5V4V3ZM6 6V5H11V6H12H13H14V5H19V6H20V11H19V12V13V14H20V19H19V20H14V19H13H12H11V20H6V19H5V14H6V13V12V11H5V6H6ZM4 20H5V21H4V20ZM5 12H4V13H5V12ZM12 4H13V5H12V4ZM13 21V20H12V21H13ZM20 4H21V5H20V4ZM21 20H20V21H21V20ZM20 12H21V13H20V12ZM13 12.5C13 12.7761 12.7761 13 12.5 13C12.2239 13 12 12.7761 12 12.5C12 12.2239 12.2239 12 12.5 12C12.7761 12 13 12.2239 13 12.5ZM14 12.5C14 13.3284 13.3284 14 12.5 14C11.6716 14 11 13.3284 11 12.5C11 11.6716 11.6716 11 12.5 11C13.3284 11 14 11.6716 14 12.5Z"
        fill="currentColor"
      />
    ),
  }),
};
