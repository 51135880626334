import { flow, observable } from "mobx";
import { convertRecordMapToArray } from "../../base/utils/map.utils";
import { getSnapshot, makeSnapshot } from "../../base/utils/snapshot.utils";
import { ModelName } from "../../constants/modelName.constants";
import {
  Score,
  ScoreSnapshot,
  ScoreSnapshotFactory,
} from "../../models/Score.model";
import { ApiController } from "../api.controller";
import { LocalDBController } from "../localDB.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "../_root.controller";

export const makeScoresController = () => {
  const s = observable({
    ...makeControllerBase("SCORES"),

    get API(): ApiController {
      return s.ROOT!.API;
    },
    get LOCALDB(): LocalDBController {
      return s.ROOT!.LOCALDB;
    },
    get all(): Score[] {
      return convertRecordMapToArray(s.LOCALDB.data.scores);
    },

    create: async (
      compositionId: string,
      template?: Partial<ScoreSnapshot>
    ) => {
      return await s.API.post<Score>(
        `/scores/`,
        ModelName.scores,
        makeSnapshot(ScoreSnapshotFactory, {
          name: "New Score",
          ...template,
          compositionId,
        })
      );
    },

    get: (id: string): Promise<Score> =>
      flow(function* () {
        const score: Score = yield s.API.get<Score>(
          "/scores/" + id,
          ModelName.scores
        );
        if (score.$.name === "New Score") {
          score.$.name = "Default";
          yield s.save(score);
        }
        return score;
      })(),

    save: (scr: Score | Partial<ScoreSnapshot>): Promise<Score> =>
      flow(function* () {
        const payload = getSnapshot<ScoreSnapshot>(scr);
        const saved: Score = yield s.API.patch<Score>(
          `/scores/${payload._id}`,
          ModelName.scores,
          payload
        );
        return saved;
      })(),

    reset: () => {
      // c.composerAppState = null;
    },
  });

  s.init = makeRootControllerChildInitFn(s, () => {});

  return s;
};

export type ScoresController = ReturnType<typeof makeScoresController>;
