/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { CSSPartial } from "../../../base/@types/css.types";
import {
  fg10,
  varPrimary,
  varPrimary30,
  varPrimary80,
} from "../../../constants/cssCustomProperties.constants";

type DataTableProps = {};

export type DataTableAction<T> = {
  label: string;
  action: (c: T) => unknown;
};

const style = {
  wrapper: {} as CSSPartial,
  table: {
    borderCollapse: "collapse",
    minWidth: "100%",
    fontFeatureSettings: "'tnum' 1",
    th: {
      textAlign: "left",
      fontWeight: 500,
    },
    thead: {
      th: {
        borderBottom: `.5px solid ${fg10}`,
        paddingBottom: "1em",
      },
    },
    "th, td": {
      paddingTop: ".5em",
      paddingBottom: ".5em",
      paddingLeft: "0",
      paddingRight: ".5em",
      "&:last-child": {
        paddingRight: "0",
      },
    },
    tbody: {
      tr: {
        "th, td": {
          borderBottom: `.5px solid ${fg10}`,
        },
        "&:hover": {
          "th, td": {
            borderBottom: `.5px solid ${varPrimary30}`,
          },
        },
      },
      "th, h2, h3, h4": {
        fontWeight: 500,
      },
      a: {
        textDecoration: "none",
        "&:hover": {
          color: varPrimary,
        },
      },
    },
  } as CSSPartial,
  actionsCell: {
    "a, button": {
      display: "inline-block",
      color: varPrimary80,
      fontWeight: "500",
      paddingTop: ".5em",
      paddingBottom: ".5em",
      "&:hover": {
        color: varPrimary,
      },
      marginRight: ".5em",
      "&:last-child": {
        marginRight: 0,
      },
    },
  } as CSSPartial,
};

const DataTable: React.FC<PropsWithChildren<DataTableProps>> = props => {
  return (
    <Observer
      children={() => (
        <div css={style.wrapper}>
          <table css={style.table}>{props.children}</table>
        </div>
      )}
    />
  );
};

export const DataTableActionsCell = (props: PropsWithChildren<{}>) => {
  return <td css={style.actionsCell}>{props.children}</td>;
};

export default DataTable;
