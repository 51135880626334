/** @jsxImportSource @emotion/react */
import { Global } from "@emotion/react";
import { Observer } from "mobx-react-lite";
import React from "react";
import {
  bg,
  fg,
  varFontMono,
  varFontSans,
  varPrimary,
  varPrimary30,
} from "../../constants/cssCustomProperties.constants";
import { CSSPartial } from "../@types/css.types";
import {
  mediaFromDesktopLg,
  mediaFromDesktopMd,
} from "../styles/helpers/mediaQueries.styling";
import { fontRenderingStyles } from "../styles/partials/fontRendering.stylePartials";
import { removeTapHighlight } from "../styles/partials/removeTapHighlight.stylePartials";
import { allHeadings } from "../styles/selectors/headings.selectors";
import { comma } from "../utils/array.utils";
import { useStore } from "../utils/mobx.utils";

type CSSGlobalsProps = {};

const CSSGlobals: React.FC<CSSGlobalsProps> = props => {
  const s = useStore(() => ({
    get globals(): CSSPartial {
      return {
        "*": {
          // outline: "1px solid red",
          boxSizing: "border-box",
          outlineColor: varPrimary,
          accentColor: varPrimary,
        },
        ":root": {
          ...fontRenderingStyles,
          ...removeTapHighlight,
          fontSize: 10,
          backgroundColor: bg,
          color: fg,
          userSelect: "none",
          // transition: 'background-color, .5s',
          fontFamily: varFontSans,
          accentColor: varPrimary,
          "&.dark": {
            colorScheme: "dark",
          },
          "&.light": {
            colorScheme: "light",
          },
          "&.hide": {
            body: {
              opacity: 0,
              pointerEvents: "none",
            },
          },
        },
        body: {
          fontSize: "1.2rem",
          ...mediaFromDesktopMd({
            fontSize: "1.3rem",
          }),
          ...mediaFromDesktopLg({
            fontSize: "1.4rem",
          }),
        },
        [comma(allHeadings, "p")]: {
          margin: 0,
        },
        a: {
          color: "inherit",
        },
        "code, kbd, pre": {
          fontFamily: varFontMono,
        },
        "::selection": {
          backgroundColor: varPrimary30,
        },
        "[data-smallcaps], [data-smcp]": {
          fontFeatureSettings: "'smcp'",
          textTransform: "lowercase",
        },
      };
    },
  }));
  return (
    <Observer
      children={() => (
        <>
          <Global styles={s.globals} />
        </>
      )}
    />
  );
};

export default CSSGlobals;
