import { makeIconDef } from "./makeIconDef";

export const PersonIconDef = {
  person: makeIconDef("person", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9999 7.5C14.9999 8.88071 13.8807 10 12.4999 10C11.1192 10 9.99994 8.88071 9.99994 7.5C9.99994 6.11929 11.1192 5 12.4999 5C13.8807 5 14.9999 6.11929 14.9999 7.5ZM15.9999 7.5C15.9999 9.433 14.4329 11 12.4999 11C10.5669 11 8.99994 9.433 8.99994 7.5C8.99994 5.567 10.5669 4 12.4999 4C14.4329 4 15.9999 5.567 15.9999 7.5ZM17.8034 15.6967C18.972 16.8654 19.7152 18.3801 19.9331 20L5.06699 20C5.2849 18.3801 6.02808 16.8654 7.19676 15.6967C8.60328 14.2902 10.5109 13.5 12.5001 13.5C14.4892 13.5 16.3968 14.2902 17.8034 15.6967ZM20.0001 21C20.5523 21 21.0061 20.5507 20.9413 20.0022C20.7184 18.1154 19.8675 16.3467 18.5105 14.9896C16.9164 13.3955 14.7544 12.5 12.5001 12.5C10.2457 12.5 8.08371 13.3955 6.48966 14.9896C5.13258 16.3467 4.28177 18.1154 4.05879 20.0022C3.99398 20.5507 4.44778 21 5.00006 21H20.0001Z"
        fill="currentColor"
      />
    ),
  }),
};
