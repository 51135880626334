import { reaction, when } from "mobx";
import { AtomType, Pattern, Replica } from "../../@types";
import { ValidRect } from "../../base/@types";
import { makeDisposerController } from "../../base/utils/disposer.utils";
import { copyWithJSON } from "../../base/utils/object.utils";
import { createAtomFactory } from "../../logic/Atom.factory";
import {
  getReplicaTransformedAnchor,
  getReplicaTransformedBoundingBox,
  makeScaleTransformStep,
  makeTransformController,
} from "../../transformers/transform.controller";
import { getAtomSetBoundingBox } from "../../utils/atoms.utils";
import {
  getIndexInMusicScale,
  getScaleStepDiffFromYDiff,
} from "../../utils/musicScale.utils";
import { createSyncableGroupLikeAtomSynchronizer } from "../../utils/syncableGroupLikeAtoms.utils";
import {
  GroupSnapshotFactory,
  makeGroupExtendedMembersFactory,
} from "./Group.model";

export const ReplicaSnapshotFactory = () => ({
  ...GroupSnapshotFactory(AtomType.replica),
  patternId: "",
  scale: {
    x: 1,
    y: 1, // Replicas cannot be scaled on the Y axis, this is not in use.
  },
  snapToScale: false,
  useClickThroughBoundingBox: null as boolean | null,
});

export const makeReplicaExtendedMembersFactory = (R: Replica) => ({
  get name() {
    return R.$.name || R.pattern?.name || "";
  },
  set name(value: string) {
    R.name = value;
  },
  get displayName(): string {
    return R.name || `Replica ${R._id}`;
  },
  set displayName(value: string) {
    R.name = value;
  },
  get pattern() {
    return R.context?.getAtomById<Pattern>(R.$.patternId) ?? null;
  },
  get refAtom() {
    return R.pattern;
  },
  get _anchor() {
    return {
      x: R.$.x ?? R.pattern?.anchor.x ?? 0,
      y: R.$.y ?? R.pattern?.anchor.y ?? 0,
    };
  },
  get anchor() {
    return getReplicaTransformedAnchor(R);
  },
  get _anchorDiffFromSource() {
    return {
      x: R._anchor.x - (R.pattern?.anchor.x ?? 0),
      y: R._anchor.y - (R.pattern?.anchor.y ?? 0),
    };
  },
  get anchorDiffFromSource() {
    return {
      x: R.anchor.x - (R.pattern?.anchor.x ?? 0),
      y: R.anchor.y - (R.pattern?.anchor.y ?? 0),
    };
  },
  get scaleStepDiffFromSource() {
    if (!R.pattern) return 0;
    const yDiff = R.anchorDiffFromSource.y;
    if (yDiff === 0) return 0;
    const firstNoteInPattern = R.pattern.descendantNotes[0];
    const firstNoteIndexInOriginalScale =
      firstNoteInPattern?.indexInMusicScale ??
      getIndexInMusicScale(
        R.pattern.closestTonicMidiNumber,
        R.pattern.musicKey,
        R.pattern.musicScaleName
      );
    return getScaleStepDiffFromYDiff(
      yDiff,
      firstNoteIndexInOriginalScale,
      R.pattern.musicScaleName
    );
  },
  get widthDiffFromSource() {
    return (R.width ?? 0) - (R.pattern?.width ?? 0);
  },
  get heightDiffFromSource() {
    return (R.height ?? 0) - (R.pattern?.height ?? 0);
  },
  get xyRelToAnchor() {
    return {
      x: R.x - R.anchor.x,
      y: R.y - R.anchor.y,
    };
  },
  get _boundingBox() {
    const P = R.pattern;
    if (!P) return getAtomSetBoundingBox(R.descendants);
    const { x: diffX, y: diffY } = R._anchorDiffFromSource;
    return [
      {
        x: P.xyRelToAnchor.x + diffX + P.anchor.x,
        y: P.xyRelToAnchor.y + diffY + P.anchor.y,
      },
      {
        x: P.xyRelToAnchor.x + P.width + diffX + P.anchor.x,
        y: P.xyRelToAnchor.y + P.height + diffY + P.anchor.y,
      },
    ] as ValidRect;
  },
  get boundingBox() {
    return getReplicaTransformedBoundingBox(R);
  },
  get _x() {
    return R._boundingBox[0].x;
  },
  set _x(v) {
    const diff = v - R._boundingBox[0].x;
    R._boundingBox[0].x += diff;
    R._boundingBox[1].x += diff;
  },
  get x() {
    return R.boundingBox[0].x;
  },
  set x(v) {
    const diff = v - R.boundingBox[0].x;
    R.boundingBox[0].x += diff;
    R.boundingBox[1].x += diff;
  },
  get _y() {
    return R._boundingBox[0].y;
  },
  set _y(v) {
    const diff = v - R._boundingBox[0].y;
    R._boundingBox[0].y += diff;
    R._boundingBox[1].y += diff;
  },
  get y() {
    return R.boundingBox[0].y;
  },
  set y(v) {
    const diff = v - R.boundingBox[0].y;
    R.boundingBox[0].y += diff;
    R.boundingBox[1].y += diff;
  },
  get x2() {
    return R.boundingBox[1].x;
  },
  set x2(v) {
    R.boundingBox[1].x = v;
  },
  get y2() {
    return R.boundingBox[1].y;
  },
  set y2(v) {
    R.boundingBox[1].y = v;
  },
  get width() {
    return Math.abs(R.x2 - R.x);
  },
  get height() {
    return Math.abs(R.y2 - R.y);
  },
  scaleTransformStep: null,
  transformOnSource: null,
  get useClickThroughBoundingBox() {
    return (
      R.$.useClickThroughBoundingBox ??
      R.pattern?.useClickThroughBoundingBox ??
      false
    );
  },
});

export const makeReplica = createAtomFactory<Replica>({
  type: AtomType.replica,
  snapshotFactory: ReplicaSnapshotFactory,
  extendedPropertiesFactories: [
    makeGroupExtendedMembersFactory,
    makeReplicaExtendedMembersFactory,
  ],
  init: (R, ac) => {
    const d = makeDisposerController();
    createSyncableGroupLikeAtomSynchronizer(R, d);
    d.add(
      when(
        () => !!ac?.ready,
        () => {
          d.add(
            reaction(
              () => `${R.pattern?.$.x}_${R.pattern?.$.y}`,
              () => {
                if (!R.pattern) {
                  R.context?.removeAtom(R);
                  return;
                }
                if (R.pattern.$.x === null || R.pattern.$.y === null) {
                  R.pattern.anchor = copyWithJSON(R.pattern.anchor);
                }
              },
              { fireImmediately: true }
            )
          );
          d.add(
            when(
              () => R.$.scale.x !== 1 || R.$.scale.y !== 1,
              () => {
                R.scaleTransformStep = makeScaleTransformStep(
                  () => R.$.scale,
                  () => R._anchor
                );
                R.transformOnSource = makeTransformController({
                  targetsGetter: () => [R],
                  originGetter: () => R._anchor,
                  stepsGetter: () => [R.scaleTransformStep!],
                });
                d.add(R.transformOnSource.dispose);
              }
            )
          );
        }
      )
    );
    return d.dispose;
  },
});
