import { makeIconDef } from "./makeIconDef";

export const MetronomeIconDefs = {
  "metronome-left": makeIconDef("metronome-left", {
    regular: () => (
      <>
        <path
          d="M12.5 17.5L4.5 9.5"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1435 4.20339C11.6845 3.05682 13.3156 3.05673 13.8567 4.20325L20.9522 19.2389C20.9837 19.3057 21 19.3785 21 19.4523V20.2584C21 20.3919 20.8919 20.5 20.7584 20.5V20.5H4.2438V20.5C4.10915 20.5 4 20.3908 4 20.2562V19.457C4 19.3832 4.01632 19.3103 4.0478 19.2436L6.33538 14.3948C6.48305 14.0818 6.89641 14.0099 7.14113 14.2546L12.1463 19.2598C12.3416 19.4551 12.6582 19.4551 12.8534 19.2598C13.0487 19.0645 13.0487 18.748 12.8534 18.5527L7.34069 13.04C7.19147 12.8907 7.152 12.6639 7.24204 12.4731L11.1435 4.20339ZM12.9523 4.63003C12.772 4.24786 12.2283 4.24788 12.0479 4.63007L8.16086 12.8692C8.07538 13.0504 8.1063 13.2651 8.23945 13.4148L9.50013 14.8323C9.59501 14.939 9.73097 15 9.87374 15H17.0572C17.4239 15 17.6659 14.6183 17.5094 14.2866L12.9523 4.63003ZM12.5 14C12.7761 14 13 13.7761 13 13.5V7.50001C13 7.22387 12.7761 7.00001 12.5 7.00001C12.2239 7.00001 12 7.22387 12 7.50001V13.5C12 13.7761 12.2239 14 12.5 14Z"
          fill="currentColor"
        />
      </>
    ),
  }),
  "metronome-right": makeIconDef("metronome-right", {
    regular: () => (
      <>
        <path
          d="M12.5001 17.5L20.5001 9.49999"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8565 4.20338C13.3155 3.05682 11.6844 3.05673 11.1433 4.20324L4.04782 19.2389C4.01633 19.3057 4 19.3785 4 19.4523V20.2584C4 20.3918 4.10815 20.5 4.24155 20.5H20.7562C20.8908 20.5 21 20.3908 21 20.2562V19.4569C21 19.3832 20.9837 19.3103 20.9522 19.2436L18.6646 14.3948C18.5169 14.0818 18.1036 14.0099 17.8589 14.2546L17.7299 14.3836C17.658 14.4554 17.639 14.5646 17.6824 14.6565C17.7577 14.8162 17.6412 15 17.4647 15H17.2655C17.1682 15 17.0748 15.0387 17.006 15.1075L12.8537 19.2598C12.6584 19.4551 12.3418 19.4551 12.1466 19.2598C11.9513 19.0645 11.9513 18.748 12.1466 18.5527L14.8457 15.8536C15.1607 15.5386 14.9376 15 14.4922 15H7.94281C7.57607 15 7.33411 14.6183 7.49063 14.2866L12.0477 4.63002C12.228 4.24785 12.7717 4.24787 12.9521 4.63007L16.8536 12.8997C17.0012 13.2127 17.4146 13.2847 17.6593 13.0399C17.8085 12.8907 17.848 12.6639 17.758 12.4731L13.8565 4.20338ZM12.5 14C12.2239 14 12 13.7761 12 13.5V7.5C12 7.22386 12.2239 7 12.5 7C12.7761 7 13 7.22386 13 7.5V13.5C13 13.7761 12.7761 14 12.5 14Z"
          fill="currentColor"
        />
      </>
    ),
  }),
  "metronome-off": makeIconDef("metronome-off", {
    regular: () => (
      <>
        <path
          d="M12.5 17.5L20.5 9.5"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8565 4.20339C13.3155 3.05682 11.6844 3.05673 11.1433 4.20325L4.04782 19.2389C4.01633 19.3057 4 19.3785 4 19.4523V20.2584C4 20.3919 4.10815 20.5 4.24155 20.5V20.5H20.7562V20.5C20.8908 20.5 21 20.3908 21 20.2562V19.3655C21 19.3519 20.997 19.3386 20.9912 19.3263L20.9663 19.2735C20.9464 19.2313 20.8865 19.2269 20.8536 19.2598V19.2598C20.6583 19.4551 20.3417 19.4551 20.1464 19.2598L16.8536 15.967C16.6584 15.7717 16.3418 15.7717 16.1465 15.967L12.8537 19.2598C12.6584 19.4551 12.3418 19.4551 12.1466 19.2598C11.9513 19.0645 11.9513 18.748 12.1466 18.5527L15.7007 14.9985C15.7517 14.9476 15.7517 14.865 15.7007 14.8141V14.8141C15.6477 14.7611 15.561 14.7636 15.5111 14.8197L15.4257 14.9156C15.378 14.9693 15.3096 15 15.2378 15H7.94281C7.57607 15 7.33411 14.6183 7.49063 14.2866L12.0477 4.63003C12.228 4.24786 12.7717 4.24788 12.9521 4.63007L16.7354 12.6493C16.7697 12.7221 16.8916 12.7184 16.9464 12.6594V12.6594C17.1345 12.4573 17.4509 12.4458 17.6531 12.6339L17.8419 12.8096C17.8557 12.8224 17.8772 12.822 17.8906 12.8087V12.8087C17.9011 12.7982 17.9038 12.7823 17.8975 12.7689L13.8565 4.20339ZM16.3742 13.8492C16.315 13.9158 16.3179 14.0171 16.381 14.0801V14.0801C16.4468 14.1459 16.5534 14.1459 16.6191 14.0801L16.9784 13.7209C17.0771 13.6222 17.0742 13.4612 16.9719 13.3661V13.3661V13.3661C16.9252 13.3226 16.8484 13.316 16.806 13.3637L16.3742 13.8492ZM17.5556 14.5578C17.3632 14.7503 17.3632 15.0623 17.5556 15.2548L18.3914 16.0906C18.7879 16.487 19.4364 16.0308 19.1972 15.5237L19.1647 15.4547C19.1375 15.3972 19.0996 15.3453 19.053 15.302L18.2398 14.5455C18.0456 14.3648 17.7432 14.3703 17.5556 14.5578V14.5578ZM12.8536 11.9669C12.9473 12.0607 13 12.1879 13 12.3205V13.5C13 13.7761 12.7761 14 12.5 14C12.2239 14 12 13.7761 12 13.5V11.3205C12 11.244 12.0924 11.2058 12.1464 11.2598V11.2598L12.8536 11.9669ZM12 9.75C12 9.47386 12.2239 9.25 12.5 9.25C12.7761 9.25 13 9.47386 13 9.75V7.50001C13 7.22387 12.7761 7.00001 12.5 7.00001C12.2239 7.00001 12 7.22387 12 7.50001V9.75Z"
          fill="currentColor"
        />
        <path
          d="M20.5 17.5L12.5 9.5"
          stroke="currentColor"
          strokeLinecap="round"
        />
      </>
    ),
  }),
};
