/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";

const SpaceBetween: React.FC<PropsWithChildren> = props => {
  return (
    <Observer
      children={() => (
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          {props.children}
        </div>
      )}
    />
  );
};

export default SpaceBetween;
