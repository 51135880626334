import { Note, NoteSnapshot, Ornament } from "../@types";

export enum OrnamentationType {
  null = "",
  appoggiatura = "appoggiatura",
  mordent = "mordent",
  turn = "turn",
  shortTrill = "short-trill",
  trill = "trill",
  custom = "custom",
}

export type OrnamentGeneratorOptions = {
  ornamentNoteWidth?: number;
  startFromLower?: boolean;
  /** only applicable for trills */
  startsWith?: "turn" | string;
  /** only applicable for trills */
  endsWith?: "turn" | string;
};
export type OrnamentationDef = {
  identifier: OrnamentationType | "";
  displayName: string;
  defaultSymbol: string;
  symbolFactory: (orn?: Ornament) => string;
  generator: (note: Note, options?: OrnamentGeneratorOptions) => NoteSnapshot[];
};
