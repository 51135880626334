/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, TextNode } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import Spacing from "../../base/components/Spacing";
import PillTag from "../../base/components/PillTag";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import Textarea from "../../base/components/Textarea";
import { grid6col } from "../../base/styles/partials/grids.stylePartials";
import { useMixedValueFormState } from "../../base/utils/form.utils";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { autoPluralize } from "../../base/utils/string.utils";
import { first } from "lodash-es";
import ReferrerCounter from "../composer/ReferrerCounter";
import { useComposer } from "../composer/ComposerApp.context";
import Selector from "../../base/components/Selector/Selector";
import SelectOptionRect from "../../base/components/Selector/SelectOptionRect";
import FormLabel from "../../base/components/FormLabel";
import DraggableNumberInput from "../../base/components/DraggableNumberInput";
import Checkbox from "../../base/components/Checkbox";
import ResetButton from "../../base/components/ResetButton";
import styled from "@emotion/styled";

type TextNodePropertyPanelProps = {
  atom?: Atom;
  atoms?: Atom[];
};

const style = {
  grid: {
    display: "grid",
    gridGap: "1em",
    gridTemplateColumns: "1fr 1fr 1fr",
    fontSize: "1.2rem",
  } as CSSPartial,
  header: {
    display: "flex",
    justifyContent: "space-between",
  } as CSSPartial,
  headerInner: {
    paddingRight: "1em",
  } as CSSPartial,
  title: {
    fontSize: "1.6rem",
  } as CSSPartial,
};

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FontStyleCheckboxSet = styled.div`
  display: flex;
  align-items: center;
  height: 2.5em;
  > * {
    + * {
      margin-left: 1em;
    }
  }
`;

const TextNodePropertyPanel: React.FC<TextNodePropertyPanelProps> = props => {
  const { SETTINGS } = useControllers();
  const p = useProps(props);
  const I = useComposer();
  const _ = useStore(() => ({
    get textNodes(): TextNode[] {
      return [p.atom, ...(p.atoms ?? [])].filter(i => i) as TextNode[];
    },
  }));

  const form = useMixedValueFormState(
    () => _.textNodes,
    [
      "x",
      "y",
      "width",
      "height",
      "z",
      "markdown",
      "fontFamily",
      "fontSize",
      "fontWeight",
      "italic",
    ]
  );
  const s = useStore(() => ({
    get ids() {
      return _.textNodes.map(n => n._id).join(",");
    },
    get firstTextNode() {
      return first(_.textNodes);
    },
    get hasJustOneNode() {
      return _.textNodes.length === 1;
    },

    get bold() {
      return (form.fontWeight as number) >= 600;
    },
    set bold(v) {
      if (v) form.fontWeight = 700;
      else form.fontWeight = 400;
    },

    get canReset() {
      return _.textNodes.every(t => !!t.refAtom);
    },
    get canResetX() {
      return s.canReset && _.textNodes.some(t => t.$.x !== null);
    },
    get canResetY() {
      return s.canReset && _.textNodes.some(t => t.$.y !== null);
    },
    get canResetWidth() {
      return s.canReset && _.textNodes.some(t => t.$.width !== null);
    },
    get canResetHeight() {
      return s.canReset && _.textNodes.some(t => t.$.height !== null);
    },
    get canResetMarkdown() {
      return s.canReset && _.textNodes.some(t => t.$.markdown !== null);
    },
    get canResetFont() {
      return s.canReset && _.textNodes.some(t => t.$.fontFamily !== null);
    },
    get canResetFontSize() {
      return s.canReset && _.textNodes.some(t => t.$.fontSize !== null);
    },
    get canResetFontWeight() {
      return s.canReset && _.textNodes.some(t => t.$.fontWeight !== null);
    },
    get canResetItalic() {
      return s.canReset && _.textNodes.some(t => t.$.italic !== null);
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="TextNodePropertyPanel">
          <header css={style.header}>
            <div css={style.headerInner}>
              <h3 css={style.title}>
                {s.hasJustOneNode
                  ? s.firstTextNode?.displayName ?? "Text"
                  : "Text nodes"}
              </h3>
              {s.hasJustOneNode &&
                s.firstTextNode &&
                s.firstTextNode.referrers.length > 0 && (
                  <p>
                    <ReferrerCounter atom={s.firstTextNode} />
                  </p>
                )}
            </div>
            <div>
              <PillTag
                color={s.firstTextNode?.voice?.appearance?.colorInContext}
              >
                #{s.firstTextNode?._id}
              </PillTag>
            </div>
          </header>
          <Spacing />
          <div css={grid6col.grid}>
            <TextInput
              form={form}
              type="number"
              field="x"
              Label="X"
              css={grid6col.half}
              step={SETTINGS.composer.snapUnitX}
              taskName={`Adjust ${autoPluralize(
                _.textNodes,
                "text node X",
                undefined,
                "X value of text nodes"
              )}`}
              mergeableId={`adjust-textNode-x-${s.ids}`}
              resettable={s.canResetX}
              disabled={I.editDisabled}
            />
            <TextInput
              form={form}
              type="number"
              field="y"
              Label="Y"
              css={grid6col.half}
              step={1}
              taskName={`Adjust ${autoPluralize(
                _.textNodes,
                "text node Y",
                undefined,
                "Y value of text nodes"
              )}`}
              mergeableId={`adjust-textNode-y-${s.ids}`}
              resettable={s.canResetY}
              disabled={I.editDisabled}
            />
            <TextInput
              form={form}
              type="number"
              field="width"
              Label="Width"
              css={grid6col.half}
              step={SETTINGS.composer.snapUnitX}
              taskName={`Adjust ${autoPluralize(
                _.textNodes,
                "text node width",
                undefined,
                "width of text nodes"
              )}`}
              mergeableId={`adjust-textNode-width-${s.ids}`}
              resettable={s.canResetWidth}
              disabled={I.editDisabled}
            />
            <TextInput
              form={form}
              type="number"
              field="height"
              Label="Height"
              css={grid6col.half}
              step={SETTINGS.composer.snapUnitX}
              taskName={`Adjust ${autoPluralize(
                _.textNodes,
                "text node height",
                undefined,
                "height of text nodes"
              )}`}
              mergeableId={`adjust-textNode-height-${s.ids}`}
              resettable={s.canResetHeight}
              disabled={I.editDisabled}
            />
            <div css={grid6col.full}>
              <Flex>
                <FormLabel bold>Font</FormLabel>
                <ResetButton form={form} field="fontFamily">
                  Reset
                </ResetButton>
              </Flex>
              <Spacing size=".5em" />
              <Selector
                fullWidth
                form={form}
                field="fontFamily"
                options={[
                  { Label: "Sans-serif", value: "sans" },
                  { Label: "Serif", value: "serif" },
                  { Label: "Monospace", value: "mono" },
                ]}
                optionRenderer={SelectOptionRect}
                taskName={`Change text node${
                  _.textNodes.length !== 1 ? "s" : ""
                } font`}
                mergeableId={`change-textNode-font-${s.ids}`}
                disabled={I.editDisabled}
              />
            </div>
            <DraggableNumberInput
              fullWidth
              Label="Font size"
              form={form}
              field="fontSize"
              css={grid6col.half}
              min={10}
              max={72}
              step={1}
              precision={0}
              taskName={`Change text node${
                _.textNodes.length !== 1 ? "s" : ""
              } font size`}
              mergeableId={`change-textNode-fontSize-${s.ids}`}
              disabled={I.editDisabled}
              resettable={s.canResetFontSize}
            />
            <div css={grid6col.half}>
              <FormLabel bold>Font style</FormLabel>
              <Spacing size="0.5em" />
              <FontStyleCheckboxSet>
                <Checkbox
                  form={s}
                  field="bold"
                  taskName={`Change text node${
                    _.textNodes.length !== 1 ? "s" : ""
                  } font weight`}
                  mergeableId={`change-textNode-fontWeight-${s.ids}`}
                  disabled={I.editDisabled}
                  resettable={s.canResetFontWeight}
                >
                  Bold
                </Checkbox>
                <Checkbox
                  form={form}
                  field="italic"
                  taskName={`Change text node${
                    _.textNodes.length !== 1 ? "s" : ""
                  } font style`}
                  mergeableId={`change-textNode-italic-${s.ids}`}
                  disabled={I.editDisabled}
                  resettable={s.canResetItalic}
                >
                  Italic
                </Checkbox>
              </FontStyleCheckboxSet>
            </div>
            <Textarea
              className={`TextNodePropertyPanel__textarea`}
              dataAttributes={{
                "data-atom-id": s.firstTextNode?._id ?? "",
              }}
              css={grid6col.full}
              Label="Content (Markdown)"
              form={form}
              field="markdown"
              rows={8}
              taskName={`Edit ${autoPluralize(
                _.textNodes,
                "text node content",
                undefined,
                "content of text nodes"
              )}`}
              mergeableId={`adjust-textNode-markdown-${s.ids}`}
              resettable={s.canResetMarkdown}
              autoFocus={!form.markdown}
              disabled={I.editDisabled}
            />
          </div>
        </div>
      )}
    />
  );
};

export default TextNodePropertyPanel;
