import { CSSPartial } from "../../@types/css.types";

export const scrollable = () =>
  ({
    overflow: "auto",
    // WebkitOverflowScrolling: "touch",
    // scrollBehavior: 'smooth',
  } as CSSPartial);

export const hideScrollbars = () =>
  ({
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  } as CSSPartial);

export const hideScrollbarsCSSString = () => `
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
