import { action, observable, reaction, when } from "mobx";
import { clamp } from "three/src/math/MathUtils";
import { powerEfficiencyMode } from "../../controllers/settings.controller";
import { ObservableRef } from "../hooks/useObservableRef.hook";
import { makeDisposerController } from "./disposer.utils";

export const createResponsiveCanvas = action((containerRef: ObservableRef) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;

  const onResizeRef = {
    current: null as (() => void) | null,
  };
  const onReadyRef = {
    current: null as (() => void) | null,
  };

  const d = makeDisposerController();

  d.add(
    when(
      () => state.ready,
      () => {
        onReadyRef.current?.();
      }
    )
  );

  const handleResize = action(
    (
      width = containerRef.current?.clientWidth ?? 0,
      height = containerRef.current?.clientHeight ?? 0
    ) => {
      state.pixelRatio = window.devicePixelRatio;
      if (powerEfficiencyMode.on || (width > 1680 && state.pixelRatio >= 2)) {
        state.pixelRatio = 1;
        state.forcedLowDensity = true;
        ctx.canvas.style.setProperty("image-rendering", "pixelated");
      } else {
        state.forcedLowDensity = false;
        ctx.canvas.style.setProperty("image-rendering", "auto");
      }
      state.width = width;
      state.height = height;
      ctx.canvas.setAttribute("width", `${width * state.pixelRatio}`);
      ctx.canvas.setAttribute("height", `${height * state.pixelRatio}`);
      ctx.canvas.style.setProperty("width", `${width}px`);
      ctx.canvas.style.setProperty("height", `${height}px`);
      ctx.scale(state.pixelRatio, state.pixelRatio);
      if (!state.ready) state.ready = true;
      onResizeRef.current?.();
    }
  );

  const state = observable({
    ready: false,
    forcedLowDensity: false,
    context: ctx,
    containerRef,
    width: containerRef.current?.clientWidth ?? 0,
    height: containerRef.current?.clientHeight ?? 0,
    pixelRatio: 1,
    onResize: (fn: (() => void) | null) => {
      onResizeRef.current = fn;
    },
    onReady: (fn: () => void) => {
      if (state.ready) fn();
      else onReadyRef.current = fn;
    },
    dispose: () => {
      d.dispose();
      resizeObserver.disconnect();
    },
  });

  const resizeObserver = new ResizeObserver(entries => {
    setTimeout(() => {
      const { width, height } = entries[0].contentRect;
      handleResize(width, height);
    });
  });

  resizeObserver.observe(containerRef.current!);
  containerRef.current!.append(canvas);

  d.add(
    reaction(
      () => powerEfficiencyMode.on,
      () => {
        handleResize();
      }
    )
  );

  return state;
});

export type ResponsiveCanvasState = ReturnType<typeof createResponsiveCanvas>;

type LineOptions = {
  ctx: CanvasRenderingContext2D;
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  stroke: string;
  strokeWidth?: number;
};

export const paintLine = ({
  ctx,
  x0,
  y0,
  x1,
  y1,
  stroke,
  strokeWidth,
}: LineOptions) => {
  ctx.beginPath();
  ctx.moveTo(x0, y0);
  ctx.lineTo(x1, y1);
  ctx.strokeStyle = stroke;
  ctx.lineWidth = strokeWidth ?? 1;
  ctx.stroke();
};

export const paintScaledLine = (
  line: LineOptions,
  { transformOrigin, zoom }: CanvasPainterScaleOptions
) => {
  if (zoom === 1) {
    paintLine(line);
  } else {
    const { x0, y0, x1, y1 } = line;
    const x0z = x0 + (x0 - transformOrigin.x) * (zoom - 1);
    const y0z = y0 + (y0 - transformOrigin.y) * (zoom - 1);
    const x1z = x1 + (x1 - transformOrigin.x) * (zoom - 1);
    const y1z = y1 + (y1 - transformOrigin.y) * (zoom - 1);
    paintLine({
      ...line,
      x0: x0z,
      y0: y0z,
      x1: x1z,
      y1: y1z,
    });
  }
};

type VerticalLineOptions = {
  ctx: CanvasRenderingContext2D;
  x: number;
  stroke: string;
  strokeWidth?: number;
};

export const paintScaledVerticalLine = (
  { ctx, x, stroke, strokeWidth }: VerticalLineOptions,
  scaleOptions: CanvasPainterScaleOptions
) => {
  paintScaledLine(
    {
      ctx,
      x0: x,
      y0: 0,
      x1: x,
      y1: ctx.canvas.height,
      stroke,
      strokeWidth,
    },
    scaleOptions
  );
};

type HorizontalLineOptions = {
  ctx: CanvasRenderingContext2D;
  y: number;
  stroke: string;
  strokeWidth?: number;
};

export const paintScaledHorizontalLine = (
  { ctx, y, stroke, strokeWidth }: HorizontalLineOptions,
  scaleOptions: CanvasPainterScaleOptions
) => {
  paintScaledLine(
    {
      ctx,
      x0: 0,
      y0: y,
      x1: ctx.canvas.width,
      y1: y,
      stroke,
      strokeWidth,
    },
    scaleOptions
  );
};

type RoundRectOptions = {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  w: number;
  h: number;
  r?: number;
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
};

export const paintRect = ({
  ctx,
  x,
  y,
  w,
  h,
  r = 0,
  fill,
  stroke,
  strokeWidth,
}: RoundRectOptions) => {
  const x1 = x + w;
  const y1 = y + h;
  const minR = clamp(r, 0, Math.min(w / 2, h / 2));
  ctx.beginPath();
  ctx.moveTo(x1 - minR, y);
  ctx.quadraticCurveTo(x1, y, x1, y + minR);
  ctx.lineTo(x1, y1 - minR);
  ctx.quadraticCurveTo(x1, y1, x1 - minR, y1);
  ctx.lineTo(x + minR, y1);
  ctx.quadraticCurveTo(x, y1, x, y1 - minR);
  ctx.lineTo(x, y + minR);
  ctx.quadraticCurveTo(x, y, x + minR, y);
  ctx.closePath();
  if (fill) {
    ctx.fillStyle = fill;
    ctx.fill();
  }
  if (stroke || strokeWidth) {
    if (fill && h <= (strokeWidth ?? 1) * 3) return;
    ctx.strokeStyle = stroke ?? fill ?? "red";
    ctx.lineWidth = strokeWidth ?? 1;
    ctx.stroke();
  }
};

export type CanvasPainterScaleOptions = {
  transformOrigin: { x: number; y: number };
  zoom: number;
};

export const paintScaledRect = (
  rect: RoundRectOptions,
  { transformOrigin, zoom }: CanvasPainterScaleOptions
) => {
  if (zoom === 1) {
    paintRect(rect);
  } else {
    const { x: x0, y: y0, w, h } = rect;
    const x1 = x0 + w;
    const y1 = y0 + h;
    const x0z = x0 + (x0 - transformOrigin.x) * (zoom - 1);
    const y0z = y0 + (y0 - transformOrigin.y) * (zoom - 1);
    const x1z = x1 + (x1 - transformOrigin.x) * (zoom - 1);
    const y1z = y1 + (y1 - transformOrigin.y) * (zoom - 1);
    paintRect({
      ...rect,
      x: x0z,
      y: y0z,
      w: x1z - x0z,
      h: y1z - y0z,
    });
  }
};
