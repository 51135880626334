export enum MusicKey {
  "C" = "C",
  "Csharp" = "C#",

  "D" = "D",
  "DFlat" = "Db",
  "DSharp" = "D#",

  "E" = "E",
  "EFlat" = "Eb",

  "F" = "F",
  "Fsharp" = "F#",

  "G" = "G",
  "GFlat" = "Gb",
  "GSharp" = "G#",

  "A" = "A",
  "AFlat" = "Ab",
  "ASharp" = "A#",

  "B" = "B",
  "BFlat" = "Bb",
}

/** refactor: sharp / flat is contextual */
export const MusicKeyEquivalenceMap = {
  0: [MusicKey.C],
  1: [MusicKey.Csharp, MusicKey.DFlat],
  2: [MusicKey.D],
  3: [MusicKey.DSharp, MusicKey.EFlat],
  4: [MusicKey.E],
  5: [MusicKey.F],
  6: [MusicKey.Fsharp, MusicKey.GFlat],
  7: [MusicKey.G],
  8: [MusicKey.GSharp, MusicKey.AFlat],
  9: [MusicKey.A],
  10: [MusicKey.ASharp, MusicKey.BFlat],
  11: [MusicKey.B],
};

export const MusicKeyOrderedInDiatonicScale = {
  0: MusicKey.C,
  1: MusicKey.D,
  2: MusicKey.E,
  3: MusicKey.F,
  4: MusicKey.G,
  5: MusicKey.A,
  6: MusicKey.B,
};

export type MusicKeyNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
