import { action, observable } from "mobx";
import { useStore } from "../utils/mobx.utils";
import { isBoolean } from "../utils/typeChecks.utils";

export const makeBooleanState = (defaultValue: boolean) => {
  const s = observable({
    value: defaultValue,
    /** only reacts to boolean values. this means you can pass this function directly as event handlers and it will ignore the event passed in. */
    toggle: action((givenValue?: unknown) => {
      s.value = isBoolean(givenValue) ? givenValue : !s.value;
    }),
  });
  return s;
};

export const useBooleanState = (defaultValue: boolean) =>
  useStore(() => makeBooleanState(defaultValue));
