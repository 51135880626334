import { computed, makeObservable } from "mobx";
import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { has_id } from "../traits/hasId.trait";
import { StandardModel, appendMissingKeys } from "./StandardModel";
import { User, UserSnapshot } from "./User.model";
import { Composition, CompositionSnapshot } from "./Composition.model";
import { InterpretationSnapshot } from "./Interpretation.model";
import { ScoreSnapshot } from "./Score.model";
import { CollectionSnapshot } from "./Collection.model";
import { ArtistSnapshot } from "./Artist.model";
import { AccessType } from "./_index.model";

export type PackageContents = {
  composition: CompositionSnapshot;
  collections: CollectionSnapshot[];
  artists: ArtistSnapshot[];
  arrangers: ArtistSnapshot[];
  authors: Pick<
    UserSnapshot,
    "username" | "givenName" | "middleName" | "surname"
  >[];
  interpretations: InterpretationSnapshot[];
  scores: ScoreSnapshot[];
};

export type ExportJsonSchema = PackageContents & {
  timeCreated: string;
  interpretations: (InterpretationSnapshot & {
    thumbnailDarkBase64: string;
    thumbnailLightBase64: string;
  })[];
};

export const PackageSnapshotFactory = () => ({
  ...has_id(),
  name: "",
  versionNumber: 0,
  compositionId: "",
  json: "",
  size: 0,
  timeCreated: "",
  ownerId: "",
  readAccess: "private" as AccessType,
});

const defaultSnapshot = PackageSnapshotFactory();

export type PackageSnapshot = ReturnType<typeof PackageSnapshotFactory>;

export class Package extends StandardModel<
  ModelName.packages,
  PackageSnapshot
> {
  constructor(LOCALDB: LocalDBController, $ = PackageSnapshotFactory()) {
    appendMissingKeys($, defaultSnapshot);
    super(ModelName.packages, LOCALDB, $);
    makeObservable(this, {
      displayName: computed,
      fallbackName: computed,
      owner: computed,
      composition: computed,
      contents: computed,
      isPublic: computed,
      isPrivate: computed,
    });
  }
  get displayName() {
    return this.$.name || this.fallbackName;
  }
  get fallbackName() {
    return `Version ${this.$.versionNumber ?? "snapshot"}`;
  }
  get composition() {
    return (
      this.LOCALDB.get<Composition>(
        ModelName.compositions,
        this.$.compositionId
      ) ||
      this.LOCALDB.ROOT?.COMPOSITIONS.all.find(c => c.packages.includes(this))
    );
  }
  get owner() {
    return this.LOCALDB.get<User>(ModelName.users, this.$.ownerId);
  }
  get contents() {
    return this.$.json ? (JSON.parse(this.$.json) as PackageContents) : null;
  }
  get isPublic() {
    return this.$.readAccess === "public";
  }
  get isPrivate() {
    return this.$.readAccess !== "public";
  }
}
