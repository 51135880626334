import { action, when } from "mobx";
import {
  Atom,
  AtomType,
  Bar,
  Chord,
  Group,
  Keyframe,
  LeafAtom,
  MusicalAtom,
  Note,
  Ornament,
  Pattern,
  Replica,
  Section,
  SelectableAtom,
  TextNode,
  Voice,
} from "../@types";
import { useOnBeforeUnmount } from "../base/hooks/lifecycle.hooks";
import { clearArray, replaceContents } from "../base/utils/array.utils";
import { makeDisposerController } from "../base/utils/disposer.utils";
import { useStore } from "../base/utils/mobx.utils";
import { makeObservableObject } from "../base/utils/observable.utils";
import { observeChangesToArray } from "../base/utils/observeChanges.util";
import { first } from "../base/utils/ramdaEquivalents.utils";
import { isPlayableNote } from "./note.utils";

const debug = false;

const makeAtomArrayHolders = () => ({
  voices: [] as Voice[],
  bars: [] as Bar[],
  groups: [] as Group[],
  patterns: [] as Pattern[],
  replicas: [] as Replica[],
  notes: [] as Note[],
  chords: [] as Chord[],
  ornaments: [] as Ornament[],
  keyframes: [] as Keyframe[],
  notesAndKeyframes: [] as (Note | Keyframe)[],
  textNodes: [] as TextNode[],
  sections: [] as Section[],
  playableNotes: [] as Note[],
  groupsAndPatterns: [] as (Group | Pattern)[],
  groupsAndPatternsAndReplicas: [] as (Group | Pattern | Replica)[],
  musicalAtoms: [] as MusicalAtom[],
  selectableAtoms: [] as SelectableAtom[],
  leafAtoms: [] as LeafAtom[],
});

export const makeAtomCategorizer = (
  atoms: Atom[],
  debugHandler: string,
  waitUntil?: () => boolean
) => {
  const _ = makeAtomArrayHolders();
  const d = makeDisposerController();
  const s = makeObservableObject(() => ({
    ready: false,
    ...makeAtomArrayHolders(),
    dispose: d.dispose,
  }));
  const categorize = action(function categorize() {
    if (debug) console.time(`🗂 categorize #${debugHandler}`);
    Object.values(_).forEach(arr => {
      clearArray<unknown>(arr);
    });
    atoms.forEach(
      action(a => {
        switch (a.type) {
          case AtomType.voice:
            _.voices.push(a as Voice);
            break;
          case AtomType.bar:
            _.bars.push(a as Bar);
            break;
          case AtomType.group:
            _.groups.push(a as Group);
            _.groupsAndPatterns.push(a as Group);
            _.groupsAndPatternsAndReplicas.push(a as Group);
            _.musicalAtoms.push(a as Group);
            _.selectableAtoms.push(a as Group);
            break;
          case AtomType.pattern:
            _.patterns.push(a as Pattern);
            _.groupsAndPatterns.push(a as Pattern);
            _.groupsAndPatternsAndReplicas.push(a as Pattern);
            _.musicalAtoms.push(a as Pattern);
            _.selectableAtoms.push(a as Pattern);
            break;
          case AtomType.replica:
            _.replicas.push(a as Replica);
            _.musicalAtoms.push(a as Replica);
            _.selectableAtoms.push(a as Replica);
            _.groupsAndPatternsAndReplicas.push(a as Replica);
            break;
          case AtomType.ornament:
            _.ornaments.push(a as Ornament);
            _.musicalAtoms.push(a as Ornament);
            _.selectableAtoms.push(a as Ornament);
            break;
          case AtomType.chord:
            _.chords.push(a as Chord);
            _.musicalAtoms.push(a as Chord);
            _.selectableAtoms.push(a as Chord);
            break;
          case AtomType.ornament:
            _.ornaments.push(a as Ornament);
            _.selectableAtoms.push(a as Ornament);
            break;
          case AtomType.note:
            _.notes.push(a as Note);
            _.musicalAtoms.push(a as Note);
            _.selectableAtoms.push(a as Note);
            _.leafAtoms.push(a as Note);
            if (isPlayableNote(a as Note)) {
              _.playableNotes.push(a as Note);
            }
            _.notesAndKeyframes.push(a as Note);
            break;
          case AtomType.keyframe:
            _.keyframes.push(a as Keyframe);
            _.selectableAtoms.push(a as Keyframe);
            _.leafAtoms.push(a as Keyframe);
            _.notesAndKeyframes.push(a as Keyframe);
            break;
          case AtomType.textNode:
            _.textNodes.push(a as TextNode);
            _.selectableAtoms.push(a as TextNode);
            _.leafAtoms.push(a as TextNode);
            break;
          case AtomType.section:
            _.sections.push(a as Section);
            break;
        }
      })
    );
    Object.entries(_).forEach(([key, arr]) => {
      replaceContents<unknown>(Reflect.get(s, key) as unknown[], arr);
    });
    if (debug) console.timeEnd(`🗂 categorize #${debugHandler}`);
  });
  const init = () => {
    categorize();
    if (!atoms) return;
    s.ready = true;
    d.add(
      observeChangesToArray(atoms, {
        splice: async () => {
          const Q = first(atoms)?.context?.composer?.queue;
          if (Q && s.ready)
            await when(
              () =>
                Q.hasNoActivePriorityTasks &&
                !Q.composer.tools.record.isRecording
            );
          categorize();
        },
      })
    );
    d.add(() => {
      Object.values(_).forEach(arr => {
        clearArray<unknown>(arr);
      });
    });
  };
  if (waitUntil) when(waitUntil, init);
  else init();
  return s;
};

export const useAtomCategorizer = (atoms: Atom[], debugHandler: string) => {
  const s = useStore(() => makeAtomCategorizer(atoms, debugHandler));
  useOnBeforeUnmount(s.dispose);
  return s;
};
