/** @jsxImportSource @emotion/react */
import { isNil, isNumber } from "lodash-es";
import { reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { useComposer } from "../../components/composer/ComposerApp.context";
import {
  VAR_InputBorder,
  fg02,
  fg05,
  fg50,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";
import { QueueableTask } from "../../controllers/composer/queue.controller";
import { CSSPartial } from "../@types/css.types";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useObservableRef } from "../hooks/useObservableRef.hook";
import cx from "../utils/className.utils";
import { cVar } from "../utils/customProperties.utils";
import { createDraggableHandler } from "../utils/draggable.utils";
import { clamp, percentage, round } from "../utils/math.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import { isNotNil } from "../utils/ramdaEquivalents.utils";
import resolveAfter from "../utils/waiters.utils";
import ClickOutside from "./ClickOutside";
import FormLabel from "./FormLabel";
import ResetButton from "./ResetButton";
import SpaceBetween from "./SpaceBetween";
import TextInput from "./TextInput";
import { clampLines } from "../styles/helpers/clampLines";

type DraggableNumberInputProps<T extends {} = {}> = {
  className?: string;
  min?: number;
  max?: number;
  form: T;
  field: keyof T & string;
  labelDisplayer?: (number: number) => ReactNode;
  visualWidthGetter?: (value: number, min?: number, max?: number) => number;
  primaryDirection?: "x" | "y";
  defaultValue?: number;
  precision?: number;
  triangle?: boolean;
  deltaScalar?: number | ((deltaX: number) => number);
  icon?: (number: number) => ReactNode;
  fullWidth?: boolean;
  resettable?: boolean;
  step?: number;
  height?: string | number;
  onInteractionStart?: () => void | Promise<void>;
  onInteractionEnd?: () => void | Promise<void>;
  pillShape?: boolean;
  pillShapeLeft?: boolean;
  pillShapeRight?: boolean;
  Label?: ReactNode;
  disabled?: boolean;
  taskName?: string;
  mergeableTaskName?: string;
  mergeableId?: string;
  onChange?: (v?: number, prev?: number) => void;
  minDeltaThreshold?: number;
};

const style = {
  outer: {
    userSelect: "none",
    label: {
      marginBottom: ".5em",
    },
    "&.resettable": {
      label: {
        color: varPrimary,
      },
    },
    "&.disabled": {
      pointerEvents: "none",
      ".DraggableNumberInput_control": {
        color: fg50,
      },
    },
    "&.fullWidth": {
      width: "100%",
    },
  } as CSSPartial,
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    height: "2.5em",
    width: "5.5em",
    overflow: "hidden",
    textAlign: "right",
    padding: ".5em .75em .5em .5em",
    fontWeight: 500,
    cursor: "ew-resize",
    "&[data-primary-direction='y']": {
      cursor: "ns-resize",
    },
    borderRadius: ".25em",
    "&.pillShape": {
      borderRadius: "1em",
    },
    "&.pillShapeLeft": {
      borderRadius: "1em .25em .25em 1em",
    },
    "&.pillShapeRight": {
      borderRadius: ".25em 1em 1em .25em",
    },
    "&.fullWidth": {
      width: "100%",
    },
    "&.showFocusRing": {
      borderColor: varPrimary,
    },
    ".DraggableNumberInput_background": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: fg02,
      borderRadius: "inherit",
      border: cVar(VAR_InputBorder),
    },
    ".DraggableNumberInput_foreground": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      i: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "currentColor",
        opacity: 0.1,
      },
      b: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "currentColor",
        opacity: 0.1,
      },
    },
    ".DraggableNumberInput_iconWrapper": {
      textAlign: "left",
      ...clampLines(1),
      svg: {
        display: "block",
      },
    },
    ".DraggableNumberInput_textLabel": {
      position: "relative",
      cursor: "text",
    },
    ".TextInput input[type='number']": {
      position: "absolute",
      top: -1,
      left: -1,
      right: -1,
      bottom: -1,
      padding: "0 0.5em",
      width: "calc(100% + 2px)",
      height: "calc(100% + 2px)",
      minWidth: "unset",
      maxWidth: "unset",
      minHeight: "unset",
      margin: 0,
    },
    "&.triangle": {
      ".DraggableNumberInput_foreground": {
        clipPath: "polygon(0 100%, 100% 0, 100% 100%, 0 100%)",
      },
    },
    "&:hover": {
      ".DraggableNumberInput_background": {
        backgroundColor: fg05,
      },
      ".DraggableNumberInput_foreground": {
        i: {
          opacity: 0.2,
        },
        b: {
          opacity: 0.3,
        },
      },
    },
  } as CSSPartial,
};

const DraggableNumberInput = <T extends {} = {}>(
  props: DraggableNumberInputProps<T>
) => {
  const ref = useObservableRef();
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    get fullRange() {
      if (isNil(p.max) || isNil(p.min)) return Infinity;
      return p.max - p.min;
    },
    get shouldShowBackdropForeground() {
      return s.fullRange !== Infinity && s.valueLabel !== null;
    },
    get realValue() {
      return p.form[p.field] as number;
    },
    set realValue(v: number) {
      Reflect.set(p.form, p.field, v);
    },
    get displayValue() {
      if (s.hasFocus)
        return round(
          clamp(s.realValue + s.deltaScaledAfterThreshold, p.min, p.max),
          p.precision
        );
      return round(s.realValue + s.deltaScaledAfterThreshold, p.precision);
    },
    get valueDisplayWidth() {
      if (p.visualWidthGetter)
        return p.visualWidthGetter(s.displayValue, p.min, p.max);
      return (s.displayValue - (p.min ?? 0)) / s.fullRange;
    },
    deltaRaw: 0,
    get minDeltaThreshold() {
      return p.minDeltaThreshold;
      // return (
      //   p.minDeltaThreshold ??
      //   (isNumberLike(p.deltaScalar) ? 1 / p.deltaScalar : 0)
      // );
    },
    get deltaRawAfterThreshold() {
      if (
        isNil(s.minDeltaThreshold) ||
        (isNumber(s.minDeltaThreshold) &&
          Math.abs(s.deltaRaw) >= s.minDeltaThreshold)
      ) {
        return s.deltaRaw;
      }
      return 0;
    },
    get deltaScaledAfterThreshold() {
      if (isNumber(p.deltaScalar) || !p.deltaScalar)
        return s.deltaRawAfterThreshold * (p.deltaScalar ?? 0.5);
      return p.deltaScalar(s.deltaRawAfterThreshold);
    },
    get valueLabel() {
      if (p.labelDisplayer) {
        return p.labelDisplayer(s.displayValue);
      }
      const value = round(s.displayValue, p.precision);
      if (isNaN(+value)) return null;
      return value;
    },
    hasFocus: false,
    task: null as QueueableTask | null,
    get taskName() {
      return p.mergeableTaskName ?? p.taskName;
    },
    get mergeableId() {
      return p.mergeableTaskName ?? p.mergeableId;
    },
    startTask: () => {
      if (s.taskName)
        s.task = I.queue.createTaskInHistory(s.taskName, {
          mergeableId: s.mergeableId,
        });
    },
    commitTask: () => {
      s.task?.commit();
      s.task = null;
    },
    draggable: createDraggableHandler({
      onStart: ({ e }) => {
        if (s.doubleClicked) return;
        if (s.directEditMode) return;
        e.stopPropagation();
        s.hasFocus = true;
        s.startTask();
        p.onInteractionStart?.();
      },
      onMove: ({ deltaX, deltaY }) => {
        s.deltaRaw += deltaX;
        s.deltaRaw -= deltaY;
        if (s.deltaRawAfterThreshold) s.applyDisplayValue();
      },
      onEnd: () => {
        s.handleInteractionEnd();
      },
    }),
    handleInteractionEnd: async () => {
      s.hasFocus = false;
      await p.onInteractionEnd?.();
      s.commitTask();
    },
    applyDisplayValue: () => {
      if (s.directEditMode) return;
      if (s.hasFocus) {
        const prev = s.realValue;
        const hasChanges = s.realValue !== s.displayValue;
        s.realValue = s.displayValue;
        s.deltaRaw = 0;
        if (hasChanges) p.onChange?.(s.realValue, prev);
      }
    },
    directEditMode: false,
    manuallyEnteredEditMode: false,
    exitDirectEditMode: () => {
      s.directEditMode = false;
      s.manuallyEnteredEditMode = false;
      s.commitTask();
    },
    handleTextLabelPointerDown: (e: React.PointerEvent) => {
      e.stopPropagation();
      s.handleInteractionEnd();
      s.directEditMode = true;
      s.manuallyEnteredEditMode = true;
    },
    reset: () => {
      if (p.defaultValue !== null && p.defaultValue !== undefined)
        s.realValue = p.defaultValue;
    },
    doubleClicked: false,
    handleDoubleClick: async () => {
      s.doubleClicked = true;
      s.manuallyEnteredEditMode = true;
      s.startTask();
      s.reset();
      await resolveAfter(300);
      s.doubleClicked = false;
      s.commitTask();
    },
    get primaryDirection() {
      return p.primaryDirection === "y" ? "y" : "x";
    },
    handleInputFocus: () => {
      s.startTask();
    },
    get fieldLabel() {
      return (
        (p.Label || p.resettable) && (
          <FormLabel bold>
            {p.resettable && p.form && isNotNil(p.field) ? (
              <SpaceBetween>
                {p.Label}
                <ResetButton
                  form={p.form}
                  field={p.field}
                  default={p.defaultValue}
                />
              </SpaceBetween>
            ) : (
              <>{p.Label}</>
            )}
          </FormLabel>
        )
      );
    },
    get inner() {
      return (
        <div
          className={cx(
            "DraggableNumberInput_control",
            p.pillShape && "pillShape",
            p.pillShapeLeft && "pillShapeLeft",
            p.pillShapeRight && "pillShapeRight",
            p.triangle && "triangle",
            p.fullWidth && "fullWidth",
            s.directEditMode && "showFocusRing"
          )}
          css={style.wrapper}
          ref={ref}
          onDoubleClick={s.handleDoubleClick}
          data-primary-direction={s.primaryDirection}
          style={{
            height: p.height,
          }}
        >
          <span className="DraggableNumberInput_background" />
          {s.shouldShowBackdropForeground && (
            <>
              <span
                className="DraggableNumberInput_foreground"
                data-delta={s.deltaScaledAfterThreshold}
              >
                {p.triangle && <i></i>}
                <b
                  style={{
                    width:
                      s.primaryDirection === "x"
                        ? percentage(s.valueDisplayWidth)
                        : undefined,
                    height:
                      s.primaryDirection === "y"
                        ? percentage(s.valueDisplayWidth)
                        : undefined,
                  }}
                ></b>
              </span>
            </>
          )}
          {s.directEditMode ? (
            <ClickOutside onClickOutside={s.exitDirectEditMode}>
              <TextInput
                type="number"
                form={s}
                field="realValue"
                step={p.step}
                min={p.min}
                max={p.max}
                autoFocus={s.manuallyEnteredEditMode}
                selectOnFocus
                onFocus={s.handleInputFocus}
                onBlur={s.handleInteractionEnd}
              />
            </ClickOutside>
          ) : (
            <>
              <span className="DraggableNumberInput_iconWrapper">
                {p.icon?.(s.displayValue)}
              </span>
              <span
                className="DraggableNumberInput_textLabel"
                onPointerDown={s.handleTextLabelPointerDown}
              >
                {s.valueLabel}
              </span>
            </>
          )}
        </div>
      );
    },
  }));
  useOnMount(() =>
    reaction(
      () => s.valueLabel === null,
      v => {
        if (v) s.directEditMode = true;
      },
      { fireImmediately: true }
    )
  );
  return (
    <Observer
      children={() => (
        <div
          css={style.outer}
          className={cx(
            p.className,
            p.disabled && "disabled",
            p.resettable && "resettable",
            p.fullWidth && "fullWidth"
          )}
          onPointerDown={s.draggable.handlePointerDown}
        >
          {s.fieldLabel}
          {s.inner}
        </div>
      )}
    />
  );
};

export default DraggableNumberInput;
