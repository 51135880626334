import { observable } from "mobx";
import { AtomType, Bar, Section } from "../../@types";
import { makeTimeSignature44 } from "../../constants/timeSignatures.constants";
import {
  createAtomFactory,
  makeAtomBaseSnapshot,
} from "../../logic/Atom.factory";

export const SectionSnapshotFactory = () => ({
  ...makeAtomBaseSnapshot(AtomType.section),
  name: "",
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  definedStartingBarId: "",
});

export const makeSectionExtendedProperties = (S: Section) => ({
  get name() {
    return S.$.name || `Section ${S.sectionNumber}`;
  },
  set name(v) {
    S.$.name = v;
  },
  get index() {
    return S.context?.sections.indexOf(S) ?? 0;
  },
  get sectionNumber() {
    return S.index + 1;
  },
  get definedStartingBar() {
    return S.context?.getAtomById<Bar>(S.definedStartingBarId) ?? null;
  },
  set definedStartingBar(bar: Bar | null) {
    S.$.definedStartingBarId = bar?._id ?? "";
  },
  get bars() {
    return S.context?.bars.filter(b => b.section === S);
  },
  get nextSection() {
    return S.context?.sections.find(s => s.x > S.x);
  },
  get x() {
    return S.definedStartingBar?.x ?? 0;
  },
  get startX() {
    return S.x;
  },
  get endX() {
    return S.nextSection?.startX ?? S.context?.lastBar?.endX ?? 0;
  },
  get width() {
    return S.endX - S.startX;
  },
  get y() {
    return -Infinity;
  },
  get y2() {
    return Infinity;
  },
  get height() {
    return Infinity;
  },
  get startY() {
    return -Infinity;
  },
  get endY() {
    return Infinity;
  },
  get voice() {
    return null;
  },
  get section() {
    return S;
  },
  get timeSignature() {
    return S.definedStartingBar?.timeSignature ?? makeTimeSignature44();
  },
  atoms: observable([]),
  get startsWithPickupMeasure() {
    // also called "Anacrusis" (https://en.wikipedia.org/wiki/Anacrusis#Music)
    return S.definedStartingBar?.barNumber === 0;
  },
});

export const makeSection = createAtomFactory<Section>({
  type: AtomType.section,
  snapshotFactory: SectionSnapshotFactory,
  extendedPropertiesFactories: [makeSectionExtendedProperties],
});
