import { makeIconDef } from "./makeIconDef";

export const MoonIconDef = {
  moon: makeIconDef("moon", {
    regular: () => (
      <path
        d="M7.02032 17.4999C11.9909 17.4999 16.0203 13.4705 16.0203 8.49991C16.0203 6.83197 15.5662 5.26895 14.7748 3.92905C18.3982 5.09249 21.0204 8.49053 21.0204 12.4999C21.0204 17.4705 16.9909 21.4999 12.0204 21.4999C8.71896 21.4999 5.83192 19.7224 4.26546 17.0703C5.13419 17.3494 6.06008 17.4999 7.02032 17.4999Z"
        stroke="currentColor"
      />
    ),
  }),
};
