import { makeIconDef } from "./makeIconDef";

export const TextCursorIconDef = {
  "text-cursor": makeIconDef("text-cursor", {
    regular: () => (
      <path
        d="M12.5 5L11.518 4.05723C11.1455 3.69967 10.6492 3.5 10.1329 3.5H8M12.5 5V20M12.5 5L13.4821 4.05723C13.8546 3.69967 14.3509 3.5 14.8672 3.5H17M12.5 20L11.518 20.9428C11.1455 21.3003 10.6492 21.5 10.1329 21.5H8M12.5 20L13.4821 20.9428C13.8546 21.3003 14.3509 21.5 14.8672 21.5H17"
        stroke="currentColor"
      />
    ),
  }),
};
