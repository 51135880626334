import { getValueOfKey } from "../base/utils/object.utils";
import { symmetricDifference } from "../base/utils/ramdaEquivalents.utils";
import {
  Accidental,
  allAccidentalsSortedByLength,
  doubleFlatAccidentals,
  doubleSharpAccidentals,
  flatAccidentals,
  sharpAccidentals,
} from "../constants/accidentals.constants";
import { parseBaseMusicKeyFromShorthand } from "./musicKey.utils";

export const isFlatAccidental = (s: string) =>
  (flatAccidentals as unknown as string[]).includes(s);
export const isDoubleFlatAccidental = (s: string) =>
  (doubleFlatAccidentals as unknown as string[]).includes(s);
export const isSharpAccidental = (s: string) =>
  (sharpAccidentals as unknown as string[]).includes(s);
export const isDoubleSharpAccidental = (s: string) =>
  (doubleSharpAccidentals as unknown as string[]).includes(s);

const makeGlobalRegex = (a: string) => new RegExp(`${a}`, "g");
const ambiguousAccidentalArray = ["b", "f"];
const ambiguousAccidentalRegexArray =
  ambiguousAccidentalArray.map(makeGlobalRegex);
const unambiguousAccidentalArray = symmetricDifference(
  allAccidentalsSortedByLength,
  ambiguousAccidentalArray
);
const unambiguousAccidentalRegexArrayWithGlobalFlag =
  unambiguousAccidentalArray.map(makeGlobalRegex);

export const parseAccidentalsFromShorthand = (
  shorthand: string
): Accidental[] => {
  const accidentals = [] as Accidental[];
  let string = shorthand.split("'")[0];
  const extractAccidentals = (regex: RegExp) => {
    const match = string.match(regex);
    if (match) {
      accidentals.push(...(match as Accidental[]));
      match.forEach(s => (string = string.replace(s, "")));
    }
  };
  unambiguousAccidentalRegexArrayWithGlobalFlag.forEach(extractAccidentals);
  const baseKey = parseBaseMusicKeyFromShorthand(string);
  if (baseKey) string = string.replace(new RegExp(`^${baseKey}`, "i"), "");
  ambiguousAccidentalRegexArray.forEach(extractAccidentals);
  return accidentals;
};

const accidentalDefs = {
  b: "♭",
  "♭": "♭",
  "#": "♯",
  "♯": "♯",
};
export const getDisplayAccidental = (a: string) =>
  a in accidentalDefs ? getValueOfKey(a, accidentalDefs as StringObject) : a;
