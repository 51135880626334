/** @jsxImportSource @emotion/react */
import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import LoadingIndicatorBlock from "../../base/components/LoadingIndicatorBlock";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import CollectionGallery from "./CollectionGallery";

type CollectionIndexProps = {
  className?: string;
  linkTo?: "discover" | "workshop";
  appearance?: "disc" | "entry";
  own?: boolean;
};

const CollectionIndex: React.FC<CollectionIndexProps> = props => {
  const p = useProps(props);

  const { COLLECTIONS } = useControllers();

  const s = useStore(() => ({
    get all() {
      return [
        ...COLLECTIONS[p.own ? "ownNonArchived" : "allNonArchived"],
      ].reverse();
    },
    loaded: false,
    loadData: () =>
      flow(function* () {
        if (s.all.length > 0) s.loaded = true;
        yield COLLECTIONS.getAllOwn();
        s.loaded = true;
      })(),
  }));

  useOnMount(() => {
    s.loadData();
  });

  return (
    <Observer
      children={() => (
        <div
          className={cx("CollectionIndex", p.className)}
          css={{
            a: {
              textDecoration: "none",
            },
          }}
        >
          {s.loaded ? (
            <div>
              <CollectionGallery
                collections={s.all}
                linkTo={p.linkTo}
                appearance={p.appearance}
              />
            </div>
          ) : (
            <LoadingIndicatorBlock />
          )}
        </div>
      )}
    />
  );
};

export default CollectionIndex;
