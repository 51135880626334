import { action, flow, reaction, runInAction, when } from "mobx";
import {
  Atom,
  AttackReleaseParams,
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  InstrumentSnapshot,
  InstrumentStatus,
  Note,
  StandardInstrumentOptions,
} from "../../@types";
import { makeDisposerController } from "../../base/utils/disposer.utils";
import { makeObservableStore } from "../../base/utils/mobx.utils";
import { getRandomNumericString } from "../../base/utils/random.utils";
import * as Tone from "tone";
import { PianoRange } from "../../constants/instruments.constants";
import { EnsembleController } from "../../controllers/ensemble.controller";
import { ColorPalette } from "../../theming/colorPalette";
import { noteIsInRange } from "../../utils/instrument.utils";
import { getFrequencyFromStringOrNumber } from "../../utils/frequency.utils";
import PolySynthControlPanel from "./ui/PolySynthControlPanel";
import type { NonCustomOscillatorType } from "tone/build/esm/source/oscillator/OscillatorInterface";
import { clamp } from "../../base/utils/math.utils";
import { recursiveMergeWithTypeCast } from "../../base/utils/object.utils";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";
import {
  InstrumentPrivateStore,
  makeInstrumentPrivateStore,
} from "../_factory/instrumentPrivateStore";
import { getNoteAttackParams } from "../../utils/playback.utils";
import resolveAfter, { runAfter } from "../../base/utils/waiters.utils";

export type PolySynthOptions = StandardInstrumentOptions & {
  oscillator: {
    type: NonCustomOscillatorType;
  };
  envelope: {
    attack: number;
    decay: number;
    sustain: number;
    release: number;
    // TODO
    // attackCurve: EnvelopeCurve;
    // releaseCurve: EnvelopeCurve;
    // decayCurve: BasicEnvelopeCurve;
  };
};

export const createDefaultEnvelope = () => ({
  attack: 0.005,
  decay: 0.1,
  sustain: 0.3,
  release: 0.2,
});

export type EnvelopeOptions = ReturnType<typeof createDefaultEnvelope>;

export const makeDefaultPolySynthOptions = () => ({
  ...makeDefaultInstrumentOptions(),
  oscillator: {
    type: "triangle",
  },
  envelope: createDefaultEnvelope(),
});

export type PolySynthType = Instrument<PolySynthOptions>;
export type PolySynthMeta = InstrumentMeta<PolySynthOptions>;

export const PolySynthName = "poly-synth";

export const PolySynthMeta: PolySynthMeta = {
  name: PolySynthName,
  icon: "synthesizer",
  displayName: "PolySynth",
  source: "Tone.js",
  sourceWebsite: "https://tonejs.github.io/",
  ControlPanel: PolySynthControlPanel,
  range: PianoRange,
  defaultColor: ColorPalette.blue,
  type: "keyboards",
  hasSampledVelocity: true,
};

export const makePolySynth = async (
  ENSEMBLE: EnsembleController,
  snapshot: InstrumentSnapshot<PolySynthOptions>
): Promise<PolySynthType> => {
  runInAction(() => {
    snapshot.options = Object.assign(
      { ...makeDefaultPolySynthOptions() },
      snapshot.options
    );
  });

  const d = makeDisposerController();
  const synth = new Tone.Synth();
  await when(() => !!ENSEMBLE.ready);
  const polySynth = new Tone.PolySynth(Tone.Synth)
    .connect(ENSEMBLE.reverb!)
    .connect(ENSEMBLE.masterVolumeController!);

  const _ = makeInstrumentPrivateStore(ENSEMBLE, PolySynthMeta, snapshot);

  const s = makeObservableStore({
    get _id(): string {
      return s.$?._id ?? getRandomNumericString();
    },
    meta: PolySynthMeta,
    get nickName(): string {
      return s.options.nickName || s.meta.displayName || s.meta.name;
    },
    appearance: {
      get color(): string {
        return s.options.color || s.meta.defaultColor || "";
      },
      set color(value: string) {
        s.options.color = value;
      },
    },
    status: InstrumentStatus.connected,
    isLoading: false,
    initiated: true,
    synth,
    polySynth,
    get $() {
      return snapshot;
    },
    $patch: ($: InstrumentSnapshot<PolySynthOptions>) => {
      recursiveMergeWithTypeCast(s.$, $);
      return s;
    },
    get options(): PolySynthOptions {
      return s.$.options ?? makeDefaultPolySynthOptions;
    },
    get range(): InstrumentRange {
      return s.meta.range;
    },
    attack: (
      which: string | number,
      after?: number,
      options?: AttackReleaseParams
    ) => {
      _.attackAllOutputs(which, after, options);
      if (!_.shouldSendToLocal) return s;
      const frequency = getFrequencyFromStringOrNumber({
        which,
        inputType: options?.inputType,
        a4: _.a4,
      });
      if (!frequency) return s;
      const when = after ?? _.now();
      const velocity = clamp(options?.velocity ?? 0.62, 0, 1);
      polySynth.triggerAttack(frequency, when, velocity);
      return s;
    },
    release: async (
      which: string | number,
      after?: number,
      options?: AttackReleaseParams
    ) => {
      await when(() => !_.shouldSustain);
      _.releaseAllOutputs(which, after, options);
      runInAction(() => {
        if (!_.shouldSendToLocal) return;
        const frequency = getFrequencyFromStringOrNumber({
          which,
          inputType: options?.inputType,
          a4: _.a4,
        });
        if (!frequency) return;
        const at = after ?? _.now();
        polySynth.triggerRelease(frequency, at);
      });
    },
    releaseAll: () => {
      polySynth.releaseAll();
    },
    _triggerFactory:
      (n: string | number, duration: number, velocity?: number) =>
      (after: string | number) => {
        if (duration <= 0) {
          console.warn(
            "A request to play a note with a zero or negative duration has been cancelled.",
            n
          );
          return;
        } else if (duration > 15) {
          console.warn(
            "A note is scheduled to play for",
            duration,
            "seconds. Make sure this was intended?"
          );
        }
        const when = after ?? _.now();
        polySynth.triggerAttackRelease(n, duration, when, velocity);
      },
    setNote(which: string | number, when?: number) {
      synth.setNote(which, when);
      return s;
    },
    getNoteAttackParams: (n: Note) => {
      return getNoteAttackParams(n, _ as unknown as InstrumentPrivateStore);
    },
    attackNote: (n: Note, options?: AttackReleaseParams) =>
      new Promise<void>(
        action(resolve => {
          const params = s.getNoteAttackParams(n);
          if (!params) return;
          const { midiNumber, frequency, beatCount, velocity } = params;
          if (!midiNumber || !beatCount || beatCount <= 0) return false;
          const shouldPlay =
            n.interpreted.notesStartingAtTheSameTimeWithSamePitchAndInstrument
              .length === 0 ||
            n.interpreted
              .notesStartingAtTheSameTimeWithSamePitchAndInstrumentIncludingSelf[0]
              ._id === n._id;
          if (!shouldPlay) return false;
          const at = options?.time ?? _.now();
          _.attackAllOutputs(midiNumber, at, {
            velocity,
          });
          runAfter(() => {
            _.activeNotes.push(frequency);
          }, at);
          if (_.shouldSendToLocal) {
            polySynth.triggerAttack(frequency, undefined, velocity);
          }
        })
      ),
    releaseNote: async (n: Note) => {
      const params = s.getNoteAttackParams(n);
      if (!params) return;
      const { midiNumber, frequency } = params;
      const at = _.now();
      if (_.shouldSustain) await when(() => !_.shouldSustain);
      _.releaseAllOutputs(midiNumber, at);
      polySynth.triggerRelease(frequency, at);
    },
    playNote: flow(function* (n: Note, options?: AttackReleaseParams) {
      s.attackNote(n, options);
      yield resolveAfter(n.interpreted.adjustedDuration * 1000);
      s.releaseNote(n);
    }),
    sustainPedalDown: () => {
      _.shouldSustain = true;
    },
    sustainPedalUp: () => {
      _.shouldSustain = false;
    },
    midiNumberIsInRange: (n: number) => noteIsInRange(n, s.range),
    get supportsMasterTuning() {
      return true;
    },
    get supportsVelocity() {
      return true;
    },
    canPlay: (n: Atom | string | number) => {
      return true;
      // TODO
      // return s.state === InstrumentState.connected && s.noteNumberIsInRange(n);
    },
    init: () => {
      polySynth.set(s.options);
      d.add(
        reaction(
          () => JSON.stringify(s.options),
          () => {
            polySynth.set(s.options);
          }
        )
      );
      d.add(
        reaction(
          () => s.options.volumeAdjustment,
          () => {
            polySynth.set({
              volume: s.options.volumeAdjustment,
            });
          },
          { fireImmediately: true }
        )
      );
      return Promise.resolve();
    },
    disconnect: () => {
      s.releaseAll();
      s.status = InstrumentStatus.disconnected;
    },
    errors: [] as Error[],
    get hasErrors(): boolean {
      return s.errors.length > 0;
    },
    dispose: () => {
      _.dispose();
      d.dispose();
      s.disconnect();
    },
  });

  return s as PolySynthType;
};
