/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { useStyle } from "../../../base/utils/mobx.utils";

const AuthForm: React.FC<PropsWithChildren> = props => {
  const style = useStyle(() => ({
    get form() {
      return {
        "> *": {
          "+ *": {
            marginTop: ".75em",
          },
        },
      };
    },
  }));
  return (
    <Observer
      children={() => (
        <div className="AuthForm" css={style.form}>
          {props.children}
        </div>
      )}
    />
  );
};

export default AuthForm;
