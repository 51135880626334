import { makeIconDef } from "./makeIconDef";

export const HarpIconDef = {
  harp: makeIconDef("harp", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51359 3.50752C7.87132 3.45791 7.20209 3.49533 6.48803 3.66366C6.80633 4.01786 7 4.48632 7 5.00001C7 6.10458 6.10457 7.00001 5 7.00001C3.89543 7.00001 3 6.10458 3 5.00001C3 4.1178 3.5712 3.36901 4.36394 3.10328C5.89703 2.37204 7.28906 2.15921 8.60985 2.26123C10.0491 2.3724 11.3701 2.85614 12.6356 3.37111C12.8492 3.45806 13.0617 3.54592 13.2736 3.63355L13.2736 3.63357L13.2736 3.63358L13.2737 3.6336L13.2738 3.63366C15.6049 4.59777 17.8652 5.53258 20.869 4.8889L21.131 6.11115C20.5652 6.23238 20.0215 6.30347 19.4974 6.33333C19.3831 13.777 15.4707 19.6936 13.5 21.5H12.5L12.5 21.5H10.367C10.1486 21.5 9.95545 21.3583 9.88997 21.1499L5.7291 7.91078C6.23139 7.78537 6.68355 7.53336 7.04972 7.1906L7.73509 8.60166L9.05117 4.62201C9.28854 4.67232 9.53144 4.73645 9.77857 4.81115L8.20506 9.56924L9.09177 11.3948L11.1125 5.2845C11.3434 5.37552 11.576 5.47039 11.8091 5.56674L9.56174 12.3624L10.4485 14.188L13.1203 6.10879C13.355 6.20353 13.5886 6.29527 13.82 6.38156L10.9184 15.1556L11.8051 16.9811L15.1678 6.81277C15.4211 6.87636 15.6689 6.92614 15.9096 6.95834L12.2751 17.9487L13.4073 20.2797C15.1625 17.3554 16.9588 11.2562 16.9993 6.17462C15.458 5.89556 14.0912 5.32766 12.8146 4.79724C12.5954 4.70614 12.3788 4.61615 12.1644 4.52891C10.9111 4.01888 9.74464 3.60261 8.51359 3.50752Z"
        fill="currentColor"
      />
    ),
  }),
};
