import {
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  StandardInstrumentOptions,
} from "../../@types";
import { STATIC_HOST } from "../../env";
import { ColorPalette } from "../../theming/colorPalette";
import { createCompositeSamplerInstrumentFactory } from "../_factory/compositeSamplerInstrumentFactory";
import EnglishHarpsichordControlPanel from "./EnglishHarpsichordControlPanel";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";

export type EnglishHarpsichordSamplerName = "normal" | "lute";

export type EnglishHarpsichordOptions = StandardInstrumentOptions & {
  activatedSamplers: EnglishHarpsichordSamplerName[];
};

export const makeDefaultEnglishHarpsichordOptions =
  (): EnglishHarpsichordOptions => ({
    ...makeDefaultInstrumentOptions(),
    activatedSamplers: ["normal"],
  });

export type EnglishHarpsichordType = Instrument<EnglishHarpsichordOptions>;

export const EnglishHarpsichordName = "english-harpsichord";

export const EnglishHarpsichordMeta: InstrumentMeta<EnglishHarpsichordOptions> =
  {
    name: EnglishHarpsichordName,
    displayName: "English Harpsichord",
    source: "VS Chamber Orchestra: Community Edition",
    sourceWebsite: "https://github.com/sgossner/VSCO-2-CE",
    icon: "harpsichord",
    range: [[29, 88]] as InstrumentRange,
    defaultColor: ColorPalette.teal,
    ControlPanel: EnglishHarpsichordControlPanel,
    type: "keyboards",
  };

export const makeEnglishHarpsichord = createCompositeSamplerInstrumentFactory({
  defaultOptionsBagBuilder: makeDefaultEnglishHarpsichordOptions,
  instrumentMeta: EnglishHarpsichordMeta,
  getters: {
    samplers: (n, o) => o?.activatedSamplers ?? ["normal"],
    velocity: {
      attack: 0.7,
      release: 0.5,
    },
  },
  compositeSamplerConfigArray: [
    {
      name: "normal",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/english-harpsichord/normal/`,
          volume: 8,
          urls: {
            "A#0": "ZuckermannKitHarpsi_Normal_Sus_Asharp0_rr1.mp3",
            "A#1": "ZuckermannKitHarpsi_Normal_Sus_Asharp1_rr1.mp3",
            "A#2": "ZuckermannKitHarpsi_Normal_Sus_Asharp2_rr1.mp3",
            "A#3": "ZuckermannKitHarpsi_Normal_Sus_Asharp3_rr1.mp3",
            "A#4": "ZuckermannKitHarpsi_Normal_Sus_Asharp4_rr1.mp3",
            C1: "ZuckermannKitHarpsi_Normal_Sus_C1_rr1.mp3",
            C2: "ZuckermannKitHarpsi_Normal_Sus_C2_rr1.mp3",
            C3: "ZuckermannKitHarpsi_Normal_Sus_C3_rr1.mp3",
            C4: "ZuckermannKitHarpsi_Normal_Sus_C4_rr1.mp3",
            C5: "ZuckermannKitHarpsi_Normal_Sus_C5_rr1.mp3",
            D1: "ZuckermannKitHarpsi_Normal_Sus_D1_rr1.mp3",
            D2: "ZuckermannKitHarpsi_Normal_Sus_D2_rr1.mp3",
            D3: "ZuckermannKitHarpsi_Normal_Sus_D3_rr1.mp3",
            D4: "ZuckermannKitHarpsi_Normal_Sus_D4_rr1.mp3",
            D5: "ZuckermannKitHarpsi_Normal_Sus_D5_rr1.mp3",
            E1: "ZuckermannKitHarpsi_Normal_Sus_E1_rr1.mp3",
            E2: "ZuckermannKitHarpsi_Normal_Sus_E2_rr1.mp3",
            E3: "ZuckermannKitHarpsi_Normal_Sus_E3_rr1.mp3",
            E4: "ZuckermannKitHarpsi_Normal_Sus_E4_rr1.mp3",
            E5: "ZuckermannKitHarpsi_Normal_Sus_E5_rr1.mp3",
            "F#1": "ZuckermannKitHarpsi_Normal_Sus_Fsharp1_rr1.mp3",
            "F#2": "ZuckermannKitHarpsi_Normal_Sus_Fsharp2_rr1.mp3",
            "F#3": "ZuckermannKitHarpsi_Normal_Sus_Fsharp3_rr1.mp3",
            "F#4": "ZuckermannKitHarpsi_Normal_Sus_Fsharp4_rr1.mp3",
            "G#1": "ZuckermannKitHarpsi_Normal_Sus_Gsharp1_rr1.mp3",
            "G#2": "ZuckermannKitHarpsi_Normal_Sus_Gsharp2_rr1.mp3",
            "G#3": "ZuckermannKitHarpsi_Normal_Sus_Gsharp3_rr1.mp3",
            "G#4": "ZuckermannKitHarpsi_Normal_Sus_Gsharp4_rr1.mp3",
          },
          octaveOffset: 1,
        },
        release: {
          baseUrl: `${STATIC_HOST}/instruments/english-harpsichord/normal-release/`,
          urls: {
            "G#4": "ZuckermannKitHarpsi_Normal_Rel_Gsharp4_rr1.mp3",
            "A#0": "ZuckermannKitHarpsi_Normal_Rel_Asharp0_rr1.mp3",
            "A#1": "ZuckermannKitHarpsi_Normal_Rel_Asharp1_rr1.mp3",
            "A#3": "ZuckermannKitHarpsi_Normal_Rel_Asharp3_rr1.mp3",
            "A#4": "ZuckermannKitHarpsi_Normal_Rel_Asharp4_rr1.mp3",
            B2: "ZuckermannKitHarpsi_Normal_Rel_B2_rr1.mp3",
            C1: "ZuckermannKitHarpsi_Normal_Rel_C1_rr1.mp3",
            C2: "ZuckermannKitHarpsi_Normal_Rel_C2_rr1.mp3",
            C3: "ZuckermannKitHarpsi_Normal_Rel_C3_rr1.mp3",
            C4: "ZuckermannKitHarpsi_Normal_Rel_C4_rr1.mp3",
            C5: "ZuckermannKitHarpsi_Normal_Rel_C5_rr1.mp3",
            D1: "ZuckermannKitHarpsi_Normal_Rel_D1_rr1.mp3",
            D2: "ZuckermannKitHarpsi_Normal_Rel_D2_rr1.mp3",
            D3: "ZuckermannKitHarpsi_Normal_Rel_D3_rr1.mp3",
            D4: "ZuckermannKitHarpsi_Normal_Rel_D4_rr1.mp3",
            D5: "ZuckermannKitHarpsi_Normal_Rel_D5_rr1.mp3",
            E1: "ZuckermannKitHarpsi_Normal_Rel_E1_rr1.mp3",
            E2: "ZuckermannKitHarpsi_Normal_Rel_E2_rr1.mp3",
            E3: "ZuckermannKitHarpsi_Normal_Rel_E3_rr1.mp3",
            E4: "ZuckermannKitHarpsi_Normal_Rel_E4_rr1.mp3",
            E5: "ZuckermannKitHarpsi_Normal_Rel_E5_rr1.mp3",
            "F#1": "ZuckermannKitHarpsi_Normal_Rel_Fsharp1_rr1.mp3",
            "F#2": "ZuckermannKitHarpsi_Normal_Rel_Fsharp2_rr1.mp3",
            "F#3": "ZuckermannKitHarpsi_Normal_Rel_Fsharp3_rr1.mp3",
            "F#4": "ZuckermannKitHarpsi_Normal_Rel_Fsharp4_rr1.mp3",
            "G#1": "ZuckermannKitHarpsi_Normal_Rel_Gsharp1_rr1.mp3",
            "G#2": "ZuckermannKitHarpsi_Normal_Rel_Gsharp2_rr1.mp3",
            "G#3": "ZuckermannKitHarpsi_Normal_Rel_Gsharp3_rr1.mp3",
          },
          octaveOffset: 1,
        },
      },
    },
    {
      name: "lute",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/english-harpsichord/lute/`,
          volume: 7,
          urls: {
            C3: "ZuckermannKitHarpsi_Lute_Sus_C3_rr1.mp3",
            "A#0": "ZuckermannKitHarpsi_Lute_Sus_Asharp0_rr1.mp3",
            "A#1": "ZuckermannKitHarpsi_Lute_Sus_Asharp1_rr1.mp3",
            "A#2": "ZuckermannKitHarpsi_Lute_Sus_Asharp2_rr1.mp3",
            "A#3": "ZuckermannKitHarpsi_Lute_Sus_Asharp3_rr1.mp3",
            "A#4": "ZuckermannKitHarpsi_Lute_Sus_Asharp4_rr1.mp3",
            C1: "ZuckermannKitHarpsi_Lute_Sus_C1_rr1.mp3",
            C4: "ZuckermannKitHarpsi_Lute_Sus_C4_rr1.mp3",
            C5: "ZuckermannKitHarpsi_Lute_Sus_C5_rr1.mp3",
            D1: "ZuckermannKitHarpsi_Lute_Sus_D1_rr1.mp3",
            D2: "ZuckermannKitHarpsi_Lute_Sus_D2_rr1.mp3",
            D3: "ZuckermannKitHarpsi_Lute_Sus_D3_rr1.mp3",
            D4: "ZuckermannKitHarpsi_Lute_Sus_D4_rr1.mp3",
            D5: "ZuckermannKitHarpsi_Lute_Sus_D5_rr1.mp3",
            E1: "ZuckermannKitHarpsi_Lute_Sus_E1_rr1.mp3",
            E2: "ZuckermannKitHarpsi_Lute_Sus_E2_rr1.mp3",
            E3: "ZuckermannKitHarpsi_Lute_Sus_E3_rr1.mp3",
            E4: "ZuckermannKitHarpsi_Lute_Sus_E4_rr1.mp3",
            E5: "ZuckermannKitHarpsi_Lute_Sus_E5_rr1.mp3",
            "F#1": "ZuckermannKitHarpsi_Lute_Sus_Fsharp1_rr1.mp3",
            "F#2": "ZuckermannKitHarpsi_Lute_Sus_Fsharp2_rr1.mp3",
            "F#3": "ZuckermannKitHarpsi_Lute_Sus_Fsharp3_rr1.mp3",
            "F#4": "ZuckermannKitHarpsi_Lute_Sus_Fsharp4_rr1.mp3",
            "G#1": "ZuckermannKitHarpsi_Lute_Sus_Gsharp1_rr1.mp3",
            "G#2": "ZuckermannKitHarpsi_Lute_Sus_Gsharp2_rr1.mp3",
            "G#3": "ZuckermannKitHarpsi_Lute_Sus_Gsharp3_rr1.mp3",
            "G#4": "ZuckermannKitHarpsi_Lute_Sus_Gsharp4_rr1.mp3",
          },
          octaveOffset: 1,
        },
        release: {
          baseUrl: `${STATIC_HOST}/instruments/english-harpsichord/lute-release/`,
          urls: {
            "A#0": "ZuckermannKitHarpsi_Lute_Rel_Asharp0_rr1.mp3",
            "A#1": "ZuckermannKitHarpsi_Lute_Rel_Asharp1_rr1.mp3",
            "A#3": "ZuckermannKitHarpsi_Lute_Rel_Asharp3_rr1.mp3",
            "A#4": "ZuckermannKitHarpsi_Lute_Rel_Asharp4_rr1.mp3",
            A2: "ZuckermannKitHarpsi_Lute_Rel_A2_rr1.mp3",
            C1: "ZuckermannKitHarpsi_Lute_Rel_C1_rr1.mp3",
            C3: "ZuckermannKitHarpsi_Lute_Rel_C3_rr1.mp3",
            C4: "ZuckermannKitHarpsi_Lute_Rel_C4_rr1.mp3",
            D2: "ZuckermannKitHarpsi_Lute_Rel_D2_rr1.mp3",
            D3: "ZuckermannKitHarpsi_Lute_Rel_D3_rr1.mp3",
            D4: "ZuckermannKitHarpsi_Lute_Rel_D4_rr1.mp3",
            D5: "ZuckermannKitHarpsi_Lute_Rel_D5_rr1.mp3",
            E1: "ZuckermannKitHarpsi_Lute_Rel_E1_rr1.mp3",
            E2: "ZuckermannKitHarpsi_Lute_Rel_E2_rr1.mp3",
            E3: "ZuckermannKitHarpsi_Lute_Rel_E3_rr1.mp3",
            E4: "ZuckermannKitHarpsi_Lute_Rel_E4_rr1.mp3",
            E5: "ZuckermannKitHarpsi_Lute_Rel_E5_rr1.mp3",
            "F#1": "ZuckermannKitHarpsi_Lute_Rel_Fsharp1_rr1.mp3",
            "F#3": "ZuckermannKitHarpsi_Lute_Rel_Fsharp3_rr1.mp3",
            "F#4": "ZuckermannKitHarpsi_Lute_Rel_Fsharp4_rr1.mp3",
            "G#1": "ZuckermannKitHarpsi_Lute_Rel_Gsharp1_rr1.mp3",
            "G#2": "ZuckermannKitHarpsi_Lute_Rel_Gsharp2_rr1.mp3",
            "G#3": "ZuckermannKitHarpsi_Lute_Rel_Gsharp3_rr1.mp3",
            "G#4": "ZuckermannKitHarpsi_Lute_Rel_Gsharp4_rr1.mp3",
            G2: "ZuckermannKitHarpsi_Lute_Rel_G2_rr1.mp3",
            D1: "ZuckermannKitHarpsi_Lute_Rel_D1_rr1.mp3",
          },
          octaveOffset: 1,
        },
      },
    },
  ],
});
