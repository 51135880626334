import { makeIconDef } from "./makeIconDef";

export const PatternIconDef = {
  pattern: makeIconDef("pattern", {
    regular: () => (
      <path
        d="M12.118 3.54182C12.3133 3.34655 12.6298 3.34656 12.8251 3.54182L15.7818 6.49848C15.977 6.69374 15.977 7.01033 15.7818 7.20559L12.4716 10.5158L9.16134 7.20559C8.96608 7.01033 8.96608 6.69374 9.16134 6.49848L12.118 3.54182ZM6.49911 9.16152C6.69437 8.96626 7.01095 8.96626 7.20621 9.16152L10.5164 12.4717L7.20621 15.782C7.01095 15.9772 6.69437 15.9772 6.49911 15.782L3.54244 12.8253C3.34718 12.63 3.34718 12.3134 3.54244 12.1182L6.49911 9.16152ZM17.738 9.16152C17.9333 8.96626 18.2498 8.96626 18.4451 9.16152L21.4018 12.1182C21.597 12.3134 21.597 12.63 21.4018 12.8253L18.4451 15.782C18.2498 15.9772 17.9333 15.9772 17.738 15.782L14.4278 12.4717L17.738 9.16152ZM9.16134 17.7379L12.4716 14.4277L15.7818 17.7379C15.977 17.9331 15.977 18.2497 15.7818 18.445L12.8251 21.4017C12.6298 21.5969 12.3133 21.5969 12.118 21.4017L9.16134 18.445C8.96608 18.2497 8.96608 17.9331 9.16134 17.7379Z"
        stroke="currentColor"
      />
    ),
  }),
};
