import { action, observable } from "mobx";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { isDevelopment } from "../base/env";

export type FileUploadEndpointResponse = {
  fileName: string;
  url: string;
};

export const makeNetworkController = () => {
  const c = observable({
    ...makeControllerBase("NETWORK"),
    online: window.navigator.onLine,
  });
  c.init = makeRootControllerChildInitFn(c, () => {
    window.addEventListener(
      "online",
      action(() => {
        c.online = true;
        if (isDevelopment)
          console.info(
            "%c🌍 Navigator online",
            "background-color: green; color: white"
          );
      })
    );
    window.addEventListener(
      "offline",
      action(() => {
        c.online = false;
        if (isDevelopment)
          console.info(
            "%c🚨 Navigator offline",
            "background-color: red; color: white"
          );
      })
    );
    c.ready = true;
  });
  return c;
};

export type NetworkController = ReturnType<typeof makeNetworkController>;
