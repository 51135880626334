import { marked } from "marked";
import { sanitize } from "dompurify";
import { useEffect, useRef } from "react";

export const Markdown = (props: { children: string; inline?: boolean }) => {
  const ref = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    ref.current?.querySelectorAll<HTMLAnchorElement>("a").forEach(anchor => {
      anchor.setAttribute("target", "_blank");
      anchor.setAttribute("rel", "noopener noreferrer");
      anchor.addEventListener(
        "pointerdown",
        e => {
          e.stopPropagation();
        },
        { capture: true }
      );
    });
  }, [props.children]);
  return (
    <span
      ref={ref}
      dangerouslySetInnerHTML={{
        __html: sanitize(
          marked[props.inline ? "parseInline" : "parse"](
            props.children
          ) as string
        ),
      }}
    />
  );
};

export const stripMarkdown = (source: string) => {
  const div = document.createElement("div");
  div.innerHTML = sanitize(marked.parse(source) as string);
  return div.innerText;
};
