import { attackOutput, stopOutput } from "../../utils/midi/midi.utils";
import { CompositeSampler, createCompositeSampler } from "./compositeSampler";
import * as Tone from "tone";
import { action, observable, reaction } from "mobx";
import {
  AttackReleaseNumberInputType,
  AttackReleaseParams,
  CompositeSamplerConfig,
  InstrumentMeta,
  InstrumentSnapshot,
  Note,
  StandardInstrumentOptions,
  ValueGetterSet,
} from "../../@types";
import { makeDisposerController } from "../../base/utils/disposer.utils";
import { EnsembleController } from "../../controllers/ensemble.controller";
import { getFrequencyFromStringOrNumber } from "../../utils/frequency.utils";
import { sortBy } from "lodash-es";

export const makeInstrumentPrivateStore = <O extends StandardInstrumentOptions>(
  ENSEMBLE: EnsembleController,
  instrumentMeta: InstrumentMeta<O>,
  snapshot: InstrumentSnapshot<O>,
  compositeSamplerConfigMap: Map<string, CompositeSamplerConfig> = new Map(),
  getters?: ValueGetterSet<O>
) => {
  const { MIDI } = ENSEMBLE.ROOT!;
  const d = makeDisposerController();
  const samplers = observable({
    map: new Map<string, CompositeSampler>(
      [...compositeSamplerConfigMap.entries()].map(([name, config]) => [
        name,
        createCompositeSampler({ ...config, ENSEMBLE }),
      ])
    ),
    get array() {
      return Array.from(samplers.map).map(i => i[1]);
    },
    get default() {
      return samplers.array[0]!;
    },
    get: (name: string) => {
      return samplers.map.get(name) ?? samplers.default;
    },
    get velocityToSamplerMap(): [number, string][] {
      const configArray = Array.from(compositeSamplerConfigMap.entries());
      if (
        instrumentMeta.hasSampledVelocity &&
        configArray.every(c => c[1].velocityRangeFrom === undefined)
      ) {
        configArray.forEach((c, i) => {
          c[1].velocityRangeFrom = i / configArray.length;
        });
      }
      if (
        instrumentMeta.hasSampledVelocity ||
        configArray.some(c => c[1].velocityRangeFrom !== undefined)
      )
        return sortBy([...configArray], "velocityRangeFrom")
          .reverse()
          .map((c, i) => {
            const curr = c[1].velocityRangeFrom ?? i / configArray.length;
            return [curr, c[1].name] as [number, string];
          });
      return [[0, samplers.array[0]!.name]];
    },
    get hasMultipleSampledVelocities() {
      return samplers.velocityToSamplerMap.length > 1;
    },
    getSamplerNameByVelocity: (v: number) => {
      return (
        samplers.velocityToSamplerMap.find(def => v >= def[0])?.[1] ??
        samplers.default.name
      );
    },
  });
  const s = observable({
    shouldSustain: false,
    activeNotes: [] as (string | number)[],
    now: () => {
      return (
        ENSEMBLE.isPlaying && !ENSEMBLE?.composer?.tools.record.isRecording
          ? Tone.now
          : Tone.immediate
      )();
    },
    get a4() {
      return ENSEMBLE.masterTuning;
    },
    getFrequency: (
      which: string | number,
      inputType?: AttackReleaseNumberInputType
    ) => {
      return getFrequencyFromStringOrNumber({
        which,
        inputType,
        a4: s.a4,
      });
    },
    get options() {
      return snapshot.options;
    },
    get getters() {
      return {
        sampler:
          getters?.samplers ??
          action(n => {
            if (n && samplers.hasMultipleSampledVelocities) {
              const samplerName = samplers.getSamplerNameByVelocity(
                n.interpreted.velocity ?? n.velocity ?? 0.5
              );
              if (samplerName) return [samplerName];
            }
            return [samplers.default.name];
          }),
        velocity: {
          attack:
            getters?.velocity?.attack ??
            action((note?: Note) => {
              if (samplers.hasMultipleSampledVelocities) return 1;
              return note?.interpreted?.velocity ?? note?.velocity ?? 0.5;
            }),
          release:
            getters?.velocity?.release ??
            action((note?: Note) => {
              if (samplers.hasMultipleSampledVelocities) return 1;
              return note?.interpreted?.velocity ?? note?.velocity ?? 0.5;
            }),
        },
      };
    },
    get samplers() {
      return samplers;
    },
    getSamplersForNote: (note?: Note) => {
      return s.getters.sampler(note, s.options).map(samplers.get);
    },
    getSamplersByVelocity: (v: number) => {
      return [samplers.get(samplers.getSamplerNameByVelocity(v))];
    },
    get outputs() {
      return snapshot.options.outputs.map(def => {
        const output = MIDI.outputs.find(o => o.name === def.name);
        return {
          output,
          channels: def.channels,
          enabled: def.enabled,
        };
      });
    },
    get shouldSendToLocal() {
      if (s.noMidiOutputsAvailable) {
        if (s.noMidiOutputsSelected) return true;
        return !snapshot.options.shouldDisableIfExternalOutputsUnavailable;
      }
      return snapshot.options.sendToLocal;
    },
    get noMidiOutputsSelected() {
      return s.outputs.length === 0 || s.outputs.every(o => !o.enabled);
    },
    get noMidiOutputsAvailable() {
      return s.outputs.every(o => !o.output);
    },
    attackAllOutputs: (
      which: string | number,
      after: number | undefined,
      options?: AttackReleaseParams
    ) => {
      s.outputs.forEach(o => {
        if (o.enabled && o.output) {
          attackOutput({
            output: o.output,
            which: which,
            channels: o.channels,
            velocity: options?.velocity,
            time: after,
            immediate: options?.immediate,
          });
        }
      });
    },
    releaseAllOutputs: (
      which: string | number,
      after?: number,
      options?: AttackReleaseParams
    ) => {
      s.outputs.forEach(o => {
        if (o.enabled && o.output) {
          stopOutput({
            output: o.output,
            which: which,
            channels: o.channels,
            velocity: options?.velocity,
            time: after,
            immediate: options?.immediate,
          });
        }
      });
    },
    attackReleaseOnAllOutputs: (
      which: string | number,
      duration?: number,
      after?: number,
      options?: AttackReleaseParams
    ) => {
      s.outputs.forEach(o => {
        if (o.enabled && o.output) {
          attackOutput({
            output: o.output,
            which,
            channels: o.channels,
            velocity: options?.velocity,
            time: after,
            duration,
            immediate: options?.immediate,
          });
        }
      });
    },
    dispose: () => {
      d.dispose();
      s.samplers.map.forEach(sampler => sampler.dispose());
      s.samplers.map.clear();
    },
  });
  d.add(
    reaction(
      () => JSON.stringify(snapshot),
      (curr, prev) => {
        ENSEMBLE.interpreter?.atomContext.composer?.queue.recordInstrumentChangeBuffer(
          "changed",
          ENSEMBLE.interpreter.interpretation._id,
          JSON.parse(curr) as InstrumentSnapshot,
          JSON.parse(prev) as InstrumentSnapshot
        );
      }
    )
  );
  return s;
};

export type InstrumentPrivateStore = ReturnType<
  typeof makeInstrumentPrivateStore
>;
