import { makeIconDef } from "./makeIconDef";

export const BlocksIconDef = {
  blocks: makeIconDef("blocks", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5H11V12H5V5ZM4 4H5H11H12V5V12V13H11H5H4V12V5V4ZM5 15H11V20H5V15ZM4 14H5H11H12V15V20V21H11H5H4V20V15V14ZM20 5H14V10H20V5ZM14 4H13V5V10V11H14H20H21V10V5V4H20H14ZM14 13H20V20H14V13ZM13 12H14H20H21V13V20V21H20H14H13V20V13V12Z"
        fill="currentColor"
      />
    ),
  }),
};
