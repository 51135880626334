import { action, observable, reaction, when } from "mobx";
import {
  Atom,
  AtomType,
  GroupLikeAtom,
  Instrument,
  MusicalAtom,
  Note,
  RemoveAtomsOptions,
  SelectableAtom,
  Tool,
} from "../@types";
import { ValidPoint, ValidRect } from "../base/@types/geometry.types";
import { IconName } from "../base/components/Symbols/iconDefs/_index.iconDefs";
import {
  addManyToArrayIfNew,
  addOneToArrayIfNew,
  clearArray,
  mapToIds,
  removeManyFromArray,
} from "../base/utils/array.utils";
import {
  formattedRectsHaveIntersect,
  pointIsInRect,
} from "../base/utils/geometry.utils";
import { first, isNotNil, last } from "../base/utils/ramdaEquivalents.utils";
import { snapByRounding } from "../base/utils/snap.utils";
import { isArray } from "../base/utils/typeChecks.utils";
import { ToolManager } from "../components/composer/toolWheel/toolManager";
import { SELECTION_GROUP_ID } from "../constants/selection.constants";
import { atomSorterByEndX, sortAtoms } from "../logic/atomFactoryMethods";
import { ZERO_POINT } from "../models/geometry/makePoint.model";
import { makeGroup } from "../models/atoms/Group.model";
import {
  UpdateAtomSelectionOptions,
  updateAtomSelection,
} from "../operations/updateAtomSelection.operation";
import {
  TranslateTransformParams,
  makeTranslateTransformStep,
} from "../transformers/transform.controller";
import {
  isAtomModel,
  isBarAtom,
  isGroupLikeAtom,
  isKeyframeAtom,
  isLeafAtom,
  isNoteAtom,
  isPatternOrReplicaAtom,
  isSectionAtom,
  isSelectableAtom,
  isTextNodeAtom,
} from "../utils/atoms.utils";
import { ToolName } from "./ToolName.enum";
import { makeDisposerController } from "../base/utils/disposer.utils";
import { WSPanelSelectionMeta } from "../components/composer/workspace/WSPanelSelection";
import SelectToolControlBar from "./controlBars/SelectToolControlBar";
import {
  getUrlParams,
  removeUrlParam,
  setUrlParam,
} from "../base/utils/urlParams.utils";
import { isSpeedScalarKeyframe } from "../utils/keyframe.utils";
import { clamp, round } from "lodash-es";
import {
  clearAllContextMenus,
  hasAnyOpenedContextMenus,
} from "../base/components/ContextMenu/ContextMenu";
import resolveAfter, { runAfter } from "../base/utils/waiters.utils";
import { ColorPalette } from "../theming/colorPalette";
import { askForBarDeletionOptions } from "../dialogs/askForBarDeletionOptions";
import { QueueableTask } from "../controllers/composer/queue.controller";
import { isRightClickEvent } from "../base/utils/ui.utils";
import { WorkspacePanelMeta } from "../components/composer/workspace/WorkspacePanelIndex";

const debug = false;

export type SelectionMode = "rect" | "range";

const temporarilyDisablePointerEvents = async () => {
  document.documentElement.style.pointerEvents = "none";
  await resolveAfter();
  document.documentElement.style.removeProperty("pointer-events");
};

export const getSelectableAtoms = (A: Atom[]) =>
  A.filter(a => !a._isLocked && !a._isHidden) as SelectableAtom[];

export const makeSelectTool = (manager?: ToolManager) => {
  const d = makeDisposerController();

  const selectionPseudoGroup = makeGroup(
    observable({
      _id: SELECTION_GROUP_ID,
      name: "SELECTION",
    })
  );

  const _ = observable({
    isDuplicateMode: false,
    asyncTask: null as QueueableTask | null,
    activatedMidiNumbers: [] as number[],
    activateNoteKey: (
      midiNumber: number,
      instruments: Instrument[],
      velocity: number
    ) => {
      const n = round(midiNumber, 3);
      _.activatedMidiNumbers.push(midiNumber);
      s.composer?.ROOT.ENSEMBLE.activateMidiKeysImmediately(n, {
        instruments,
        velocity,
      });
      return () => _.deactivateNoteKey(n);
    },
    deactivateNoteKey: (midiNumber: number) => {
      const n = round(midiNumber, 3);
      s.composer?.ROOT.ENSEMBLE.deactivateMidiKeyImmediately(n);
    },
    deactivateAll: () =>
      _.activatedMidiNumbers.forEach(n => _.deactivateNoteKey(n)),
    watchMidiNumberChange: (note: Note) => {
      return reaction(
        () => note.midiNumber,
        newValue => {
          _.deactivateAll();
          if (newValue)
            _.activateNoteKey(
              newValue,
              note.interpreted.instruments,
              note.interpreted.velocity
            );
        }
      );
    },
    handlePointerMoveStart: () => {
      if (
        s.composer?.ROOT.KEYBOARD.pressed.shift ||
        s.composer?.ROOT.KEYBOARD.pressed.meta
      )
        return;
      const pointerPosition =
        s.manager?.parent?.focusedCanvas?.pointerPosition.onCanvas.abstract;
      if (!pointerPosition) return;
      const hitBox = s.selectionPseudoGroup.hitBox;
      if (!hitBox) return;
      const selectionRect = [
        { x: hitBox[0].x, y: hitBox[0].y - 0.5 },
        { x: hitBox[1].x, y: hitBox[1].y + 0.5 },
      ] as ValidRect;
      const pointerIsInsideOfSelectionRect = pointIsInRect(
        pointerPosition,
        selectionRect
      );
      if (pointerIsInsideOfSelectionRect)
        s.handleTranslateStart("CCanvasPointerMoveStart");
    },
    handlePointerReleaseFn: (atom: Atom) =>
      action(() => {
        if (isNoteAtom(atom)) {
          const isBeingWrittenByQuill =
            s.composer?.tools.quill.interactingNote === atom;
          if (!isBeingWrittenByQuill && s.pointerContact?.hasMoved)
            s.handleTranslateEnd("CCanvasPointerRelease", true);
          runAfter(_.deactivateAll);
          _.disposePointerDownListeners();
        }
      }),
    globalDisposers: [] as (() => void)[],
    disposePointerDownListeners: () => {
      _.globalDisposers.forEach(fn => fn());
      _.deactivateAll();
    },
  });

  const s = observable({
    name: ToolName.Select as const,
    get displayName(): string {
      if (s.atomSelection.length === 1) {
        return s.atomSelection[0].displayName;
      }
      return "Select";
    },
    controlBar: SelectToolControlBar,
    options: {},
    buttonIcon: "arrow-cursor" as IconName,
    mode: "rect" as SelectionMode,
    setMode: (m: SelectionMode) => (s.mode = m),
    get isActivated(): boolean {
      return s.manager?.activeTool === s;
    },
    onActivate: () => {
      // console.info("select tool activated");
    },
    manager,
    setManager: (manager: ToolManager) => (s.manager = manager),
    get managerParent() {
      return s.manager?.parent ?? null;
    },
    get atomContext() {
      return s.managerParent?.atomContext;
    },
    get canvas() {
      return s.managerParent?.focusedCanvas;
    },
    get composition() {
      return s.managerParent?.composition ?? null;
    },
    get composer() {
      return s.managerParent?.COMPOSER.instance ?? null;
    },
    isolatedAtom: null as GroupLikeAtom | null,
    enterIsolationMode: (a: GroupLikeAtom) => {
      if (s.atomSelection.length === 1 && s.atomSelection[0] === a) {
        s.updateSelection([]);
      }
      s.isolatedAtom = a;
    },
    exitIsolationMode: (debugCaller: string) => {
      if (debug) console.info("existing isolation mode:", debugCaller);
      s.isolatedAtom = null;
    },
    get isInIsolationMode() {
      return !!s.enterIsolationMode;
    },
    get sortedAtoms() {
      return getSelectableAtoms(
        s.isolatedAtom?.childrenSorted ??
          s.managerParent?.atomContext.atomsSortedByX ??
          []
      );
    },
    get notes() {
      return getSelectableAtoms(
        s.isolatedAtom?.descendantNotes ??
          s.managerParent?.atomContext.notes ??
          []
      );
    },
    get visibleNotesAndKeyframes() {
      if (s.canvas && s.isolatedAtom)
        return s.isolatedAtom.descendantNotesAndKeyframes.filter(
          a =>
            a.startX !== null && a.startX > s.canvas!.visible.abstractXSpan.end
        );
      return s.canvas?.visible.notesAndKeyframes ?? s.notes;
    },
    get selectableAtoms() {
      return s.sortedAtoms.filter(isSelectableAtom);
    },
    get visibleSelectableAtoms() {
      if (!s.canvas) return s.notes;
      const atoms = [] as Atom[];
      for (const note of s.visibleSelectableAtoms) {
        if (
          note.startX !== null &&
          note.startX > s.canvas.visible.abstractXSpan.end
        )
          break;
        atoms.push(note);
      }
      return atoms;
    },
    get visibleSelectableAtomsAtRootOfIsolationLevel() {
      if (!s.canvas) return s.notes;
      const atoms = [] as Atom[];
      for (const note of s.selectableAtoms) {
        if (
          note.startX !== null &&
          note.startX > s.canvas.visible.abstractXSpan.end &&
          (s.isolatedAtom
            ? !s.isolatedAtom.children.includes(note)
            : note.parents.length > 0)
        )
          break;
        atoms.push(note);
      }
      return atoms;
    },

    get selectionRect() {
      return s.canvas?.userSelectionRectOnCanvas ?? null;
    },
    get beatUnitWidth() {
      return s.canvas?.ptPerX;
    },
    get noteHeight() {
      return s.canvas?.ptPerY;
    },

    get pointerContact() {
      return s.canvas?.pointerContact ?? null;
    },
    get selectionRectInBeatsAndNoteNumber() {
      return s.canvas?.userSelectionRectInBeatsAndNoteNumber ?? null;
    },
    get KEYBOARD() {
      return s.manager?.parent?.COMPOSER.ROOT?.KEYBOARD;
    },
    get metaOrControlPressed(): boolean {
      return !!(s.KEYBOARD?.pressed.meta || s.KEYBOARD?.pressed.ctrl);
    },
    get atomsToScan(): Atom[] {
      return s.metaOrControlPressed
        ? s.visibleNotesAndKeyframes
        : s.visibleSelectableAtomsAtRootOfIsolationLevel;
    },
    get atomsInSelectionRect() {
      if (!s.selectionRectInBeatsAndNoteNumber) return [];
      const result = [] as Atom[];
      const [upperLeft, lowerRight] = s.selectionRectInBeatsAndNoteNumber;
      for (const n of s.atomsToScan /** sorted by X */) {
        if (isPatternOrReplicaAtom(n) && n.useClickThroughBoundingBox) continue;
        if (isNotNil(n.startX) && n.startX > lowerRight.x) break;
        // if (
        //   isNotNil(n.endX) &&
        //   n.endX < upperLeft.x // atom ended before screen visible screen X span
        // )
        //   continue;
        // if (isNotNil(n.endY) && n.endY < upperLeft.y) continue; // atom is completely above selection box
        // if (isNotNil(n.startY) && n.startY > lowerRight.y) continue; // atom is completely below selection box
        if (!n.hitBox) continue;
        if (
          formattedRectsHaveIntersect(
            n.hitBox,
            s.selectionRectInBeatsAndNoteNumber
          )
        )
          addOneToArrayIfNew(result, n);
      }
      return result;
    },
    get selectionPseudoGroup() {
      return selectionPseudoGroup;
    },
    get allNotesInSelection() {
      const notes = new Set(selectionPseudoGroup.descendantNotes);
      s.atomSelection.forEach(atom => {
        if (isBarAtom(atom))
          atom.atoms.forEach(n => {
            if (isNoteAtom(n)) notes.add(n);
          });
      });
      return Array.from(notes);
    },
    get allKeyframesInSelection() {
      const keyframes = new Set(selectionPseudoGroup.descendantKeyframes);
      s.atomSelection.forEach(atom => {
        if (isBarAtom(atom))
          atom.atoms.forEach(n => {
            if (isKeyframeAtom(n)) keyframes.add(n);
          });
      });
      return Array.from(keyframes);
    },
    get allNotesInSelectionSortedByEndX() {
      return sortAtoms(s.allNotesInSelection, atomSorterByEndX);
    },
    get allKeyframesInSelectionSortedByEndX() {
      return sortAtoms(s.allKeyframesInSelection, atomSorterByEndX);
    },
    get allSpeedScalarsInSelection() {
      return s.allKeyframesInSelection.filter(isSpeedScalarKeyframe);
    },
    atomSelection: [] as Atom[],
    __previousSelection: [] as Atom[],
    get hasOnlyNotesInSelection() {
      return s.atomSelection.every(isNoteAtom);
    },
    pausePlayback: () => {
      s.manager?.parent?.COMPOSER.ROOT?.ENSEMBLE.pause();
    },
    get shouldPauseOnSelectionUpdate() {
      return (
        s.manager?.parent?.COMPOSER.ROOT?.SETTINGS.playback.pauseOnSelection ??
        true
      );
    },
    updateSelection: (
      options?:
        | Atom
        | Atom[]
        | (UpdateAtomSelectionOptions & {
            debugCaller?: string;
            operationName?: string;
          })
    ) => {
      if (!options) return;
      if (debug) if ("debugCaller" in options) console.info(options);
      if (s.atomContext?.composer?.tools.record.isRecording) {
        s.atomContext.composer.tools.record.stop();
      }
      if (isArray(options)) {
        // array of atoms supplied
        updateAtomSelection({
          debugCaller: "selectTool - array supplied",
          selectionTool: s,
          mode: "replace",
          atoms: options,
          updateCursorPosition:
            options.length === 1 ? !isGroupLikeAtom(options[0]) : true,
        });
      } else if (isAtomModel(options)) {
        // one single atom supplied
        updateAtomSelection({
          debugCaller: "selectTool - atom supplied",
          selectionTool: s,
          mode: "replace",
          atoms: [options],
          updateCursorPosition:
            !isGroupLikeAtom(options) && !isSectionAtom(options),
        });
      } else {
        const atoms = options.atoms ?? [];
        updateAtomSelection({
          debugCaller: options.debugCaller,
          selectionTool: s,
          mode: options.mode ?? "replace",
          atoms: atoms,
          setWriteToVoiceAs: options.setWriteToVoiceAs,
          updateCursorPosition:
            options.updateCursorPosition ??
            (atoms.length === 1
              ? !isGroupLikeAtom(atoms[0]) && !isSectionAtom(atoms[0])
              : true),
        });
      }
      s.updateSelectionPseudoGroup();
      if (s.shouldPauseOnSelectionUpdate) s.pausePlayback();
    },
    get hasSelection() {
      return !!s.atomSelection.length;
    },
    get hasNotesInSelection() {
      return s.selectionPseudoGroup.descendantNotes.length > 0;
    },
    clearSelection: (options: { debugHandle: string }) => {
      if (debug)
        console.info(`%cClearSelection.${options.debugHandle}`, "color: green");
      s.exitIsolationMode("clearSelection");
      if (!s.hasSelection) return;
      updateAtomSelection({
        debugCaller: `selectTool.clearSelection.${options.debugHandle}`,
        mode: "clear",
        selectionTool: s,
      });
      if (s.shouldPauseOnSelectionUpdate) s.pausePlayback();
    },
    get selectedSingleAtomType() {
      if (s.atomSelection.length !== 1) return null;
      return s.lastSelectedAtom?.type;
    },
    get onlySelectedNote() {
      const firstAtom = first(s.atomSelection);
      return s.atomSelection.length === 1 && isNoteAtom(firstAtom)
        ? firstAtom
        : null;
    },
    get lastSelectedAtom() {
      return last(s.atomSelection);
    },
    lastSelectionRectContentHasBeenPushed: false,
    updateSelectionPseudoGroup: () => {
      const toRemove = selectionPseudoGroup.children.filter(
        atom => !s.atomSelection.includes(atom)
      );
      const toAdd = s.atomSelection.filter(
        atom => !selectionPseudoGroup.children.includes(atom)
      );
      toRemove.forEach(atom => {
        atom.subtractParents(selectionPseudoGroup);
      });
      removeManyFromArray(selectionPseudoGroup.children, toRemove);
      toAdd.forEach(atom => {
        selectionPseudoGroup.pushAtom(atom);
      });
      addManyToArrayIfNew(selectionPseudoGroup.children, toAdd);
    },
    get shiftIsPressed() {
      return !!s.manager?.parent?.COMPOSER.ROOT?.KEYBOARD.pressed.shift;
    },
    get metaIsPressed() {
      return !!s.manager?.parent?.COMPOSER.ROOT?.KEYBOARD.pressed.meta;
    },
    get altIsPressed() {
      return !!s.manager?.parent?.COMPOSER.ROOT?.KEYBOARD.pressed.alt;
    },
    changesInCurrDraw: [] as Atom[],
    handleSelectionDrawMove: () => {
      if (!s.isActivated) return;
      const atoms = s.atomsInSelectionRect;
      const currentSelectionIds = s.atomSelection
        .map(n => n._id)
        .sort()
        .join(",");
      const newSelectionIds = atoms
        .map(n => n._id)
        .sort()
        .join(",");
      if (currentSelectionIds === newSelectionIds) return;
      const mode =
        s.shiftIsPressed || s.metaIsPressed
          ? "add"
          : s.altIsPressed
          ? "subtract"
          : "replace";
      const added: Atom[] = [];
      const subtracted: Atom[] = [];
      if (mode === "replace") {
        s.updateSelection({
          debugCaller: "selectTool.handleSelectionDrawMove",
          atoms: atoms,
          mode: "replace",
        });
        return;
      }
      if (mode === "add") {
        added.push(...atoms.filter(a => !s.changesInCurrDraw.includes(a)));
        subtracted.push(...s.changesInCurrDraw.filter(a => !atoms.includes(a)));
        addManyToArrayIfNew(s.changesInCurrDraw, added);
        removeManyFromArray(s.changesInCurrDraw, subtracted);
      } else if (mode === "subtract") {
        subtracted.push(...atoms.filter(a => !s.changesInCurrDraw.includes(a)));
        added.push(...s.changesInCurrDraw.filter(a => !atoms.includes(a)));
        addManyToArrayIfNew(s.changesInCurrDraw, subtracted);
        removeManyFromArray(s.changesInCurrDraw, added);
      }
      const newSelection = [...s.atomSelection];
      addManyToArrayIfNew(newSelection, added);
      removeManyFromArray(newSelection, subtracted);
      s.updateSelection({
        debugCaller: "selectTool.handleSelectionDrawMove",
        atoms: newSelection,
        mode: "replace",
      });
    },
    handleSelectionDrawEnd: (endedOnInputs?: boolean) => {
      if (!s.isActivated) return;
      const atoms = s.atomsInSelectionRect;
      const shouldUpdateSelection =
        !s.selectionTransform?.isActive &&
        atoms &&
        !s.lastSelectionRectContentHasBeenPushed &&
        !endedOnInputs;
      if (s.canvas) s.canvas.isDrawingSelectionRect = false;
      if (shouldUpdateSelection) {
        if (!s.pointerContact?.hasMoved) {
          s.clearSelection({
            debugHandle: "selectTool.handleSelectionDrawEnd",
          });
        } else if (!s.shiftIsPressed && !s.metaIsPressed && !s.altIsPressed) {
          s.updateSelection({
            debugCaller: "selectTool.handleSelectionDrawEnd",
            atoms: atoms,
          });
        }
      }
      s.lastSelectionRectContentHasBeenPushed = true;
      clearArray(s.changesInCurrDraw);
    },
    get selectionTransform() {
      return s.canvas?.selectionTransform;
    },
    get deltaInAbstractUnits(): ValidPoint {
      if (
        !s.pointerContact ||
        s.composer?.interpreter?.isAdjustingInterpretationViaHandles
      )
        return ZERO_POINT;
      const { deltaX, deltaY } = s.pointerContact;
      const paramsInPt = { x: deltaX, y: deltaY };
      return (
        s.canvas?.convert.ptPointFromYCenter.toAbstractPoint(paramsInPt) ??
        paramsInPt
      );
    },
    get snapX(): number {
      return s.managerParent?.COMPOSER.instance?.units.snapX ?? 0.25;
    },
    get isScaling() {
      const { x, y } = s.selectionTransform?.firstScale?.params ?? {};
      return (
        typeof x === "number" && typeof y === "number" && (x !== 1 || x !== 1)
      );
    },
    translateTransformParamsBeforeSnapping: observable({
      get x(): number {
        if (s.isScaling) return 0;
        if (s.canvas?.isDrawingSelectionRect) return 0;
        if (s.KEYBOARD?.pressed.controlOrAlt) return s.deltaInAbstractUnits.x;
        return snapByRounding(
          s.deltaInAbstractUnits.x,
          clamp(s.snapX, 0.125, 0.5)
        );
      },
      get y(): number {
        if (s.isScaling) return 0;
        if (s.canvas?.isDrawingSelectionRect) return 0;
        if (s.KEYBOARD?.pressed.controlOrAlt) return s.deltaInAbstractUnits.y;
        return snapByRounding(s.deltaInAbstractUnits.y, 1);
      },
    } as TranslateTransformParams),
    translateTransformParams: observable({
      get x() {
        if (s.KEYBOARD?.pressed.shift) {
          if (
            s.translateTransformParamsBeforeSnapping.x >=
            s.translateTransformParamsBeforeSnapping.y
          )
            return s.translateTransformParamsBeforeSnapping.x;
          return 0;
        }
        return s.translateTransformParamsBeforeSnapping.x;
      },
      get y() {
        if (s.KEYBOARD?.pressed.shift) {
          if (
            s.translateTransformParamsBeforeSnapping.y >
            s.translateTransformParamsBeforeSnapping.x
          )
            return s.translateTransformParamsBeforeSnapping.y;
          return 0;
        }
        return s.translateTransformParamsBeforeSnapping.y;
      },
    }),
    handleTranslateStart: (callerDebugName: string) => {
      if (!s.composer) return;
      if (!!s.selectionTransform?.translates.length) return;
      _.isDuplicateMode = !!(
        s.composer.editable &&
        s.KEYBOARD?.pressed.optionOrCtrl &&
        s.atomSelection.length > 0
      );
      if (_.isDuplicateMode) {
        _.asyncTask = s.composer.queue.createTaskInHistory(
          _.isDuplicateMode ? "Duplicate atoms" : "Move atoms",
          {
            highPriority: true,
          }
        );
        const duplicatedAtoms = s.atomContext?.duplicateAtoms({
          atoms: s.atomSelection,
          atPosition:
            s.selectionPseudoGroup.startX ??
            s.canvas?.pointerPosition.onCanvas.abstract.x ??
            s.canvas?.primaryCursor?.x ??
            0,
        });
        s.updateSelection({
          atoms: duplicatedAtoms,
          debugCaller: "alt/option + drag duplicate",
          mode: "replace",
        });
      }
      if (debug)
        console.info(
          `handleTranslateStart called by ${callerDebugName}, selection:`,
          s.atomSelection.map(a => `${a.type}#${a._id}`).join(",")
        );
      if (s.selectedSingleAtomType === AtomType.bar) {
        s.clearSelection({
          debugHandle: "selectTool.handleTranslateStart",
        });
      } else {
        if (!s.selectionTransform || s.composer.editDisabled) return;
        s.selectionTransform?.add(
          makeTranslateTransformStep(() => s.translateTransformParams)
        );
        window.addEventListener("mouseup", s.handleTranslateEnd, {
          once: true,
        });
        window.addEventListener("mouseleave", s.handleTranslateEnd, {
          once: true,
        });
        window.addEventListener("blur", s.handleTranslateEnd, { once: true });
      }
    },
    handleTranslateEnd: (
      debugHandle: unknown,
      inHistory: boolean | undefined = true
    ) => {
      window.removeEventListener("mouseup", s.handleTranslateEnd);
      window.removeEventListener("mouseleave", s.handleTranslateEnd);
      window.removeEventListener("blur", s.handleTranslateEnd);
      if (!s.composer || !s.pointerContact?.hasMoved) {
        s.selectionTransform?.reset();
        return;
      }
      const hasScaled =
        s.selectionTransform?.firstScale &&
        (s.selectionTransform.firstScale.params.x !== 1 ||
          s.selectionTransform.firstScale.params.y !== 1);
      if (hasScaled) return;
      if (!s.selectionTransform?.isActive) return;
      const apply = action(() => {
        s.selectionTransform?.applyTransform("selectTool.handleTranslateEnd");
        s.selectionTransform?.reset();
      });
      if (inHistory) {
        if (_.asyncTask) {
          apply();
          _.asyncTask.commit();
          _.asyncTask = null;
        } else {
          s.composer.runInHistory(
            _.isDuplicateMode ? "Duplicate atoms" : "Move atoms",
            apply,
            { mergeableId: "selectionTranslation" }
          );
        }
      } else apply();
    },
    deleteSelectedAtoms: () => {
      if (!s.composer) return;
      s.composer?.runInHistory("Delete selected atoms", async () => {
        const bars = s.atomSelection.filter(isBarAtom);
        const options: RemoveAtomsOptions = {};
        if (bars.length > 0) {
          const deleteBarContents = s.composer?.ROOT.DIALOGS
            ? await askForBarDeletionOptions(s.composer.ROOT.DIALOGS, bars)
            : false;
          if (deleteBarContents === null) return;
          else options.deleteContents = deleteBarContents;
        }
        s.composer?.atomContext.removeAtomsAndDescendants(
          s.atomSelection,
          options
        );
        s.clearSelection({ debugHandle: "deleteSelectedAtoms" });
      });
    },
    findSelectableRectangularAtomAtPointerEvent: (
      e: React.PointerEvent | React.MouseEvent | React.TouchEvent,
      options?: {
        onlyCheckAmongCurrentSelection?: boolean;
      }
    ) => {
      if (!s.atomContext) return null;
      const XY =
        s.atomContext.canvas!.getCanvasAbstractPointFromPointerEvent(e);
      const atomsToCheck = options?.onlyCheckAmongCurrentSelection
        ? s.atomSelection
        : s.atomsToScan;
      const allAtomsAtPosition = atomsToCheck.filter(n => {
        if (isPatternOrReplicaAtom(n) && n.useClickThroughBoundingBox) return;

        const startX = Math.min(
          n.startX ?? 0,
          (n as MusicalAtom).interpreted?.startX ?? n.startX ?? 0
        );
        const endX = Math.max(
          n.endX ?? 0,
          (n as MusicalAtom).interpreted?.endX ?? n.endX ?? 0
        );
        return (
          n.type !== AtomType.keyframe &&
          startX <= XY.x &&
          endX >= XY.x &&
          n.startY! - (n.appearance?.noteYScalar ?? 1) / 2 <= XY.y &&
          n.endY! + (n.appearance?.noteYScalar ?? 1) / 2 >= XY.y
        );
      });
      if (allAtomsAtPosition.length === 0) return null;
      const sortedByPriority = allAtomsAtPosition.sort((a, b) => {
        // return < 0 to sort a before b
        if (isLeafAtom(a) && isGroupLikeAtom(b)) return -1;
        if (isNoteAtom(a) && !!a.ornamentParent) return -1;
        if (a.parents.length > b.parentIds.length) {
          if (a._isSelected && !b._isSelected) return -1;
          return 1;
        }
        if ((a.width ?? 0) < (b.width ?? 0)) return -1;
        if (a.voiceId === s.atomContext?.composer?.selectedVoices[0]._id)
          return -1;
        if ((a.height ?? 0) < (b.height ?? 0)) return -1;
        if ((a.z || a.voice?.z || 0) > (b.z || b.voice?.z || 0)) return -1;
        const ax = (a as MusicalAtom).interpreted?.startX ?? a.startX ?? 0;
        const bx = (b as MusicalAtom).interpreted?.startX ?? b.startX ?? 0;
        return bx - ax;
      });
      return sortedByPriority[0];
    },
    selectedAtomFromCurrentClick: null as Atom | null,
    handlePointerDownCaptureOnAtom: (
      atom: Atom,
      e: React.PointerEvent | React.MouseEvent | React.TouchEvent
    ) => {
      if (!s.composer) return;
      if (s.composer.tools.quill.isActivated) return;
      const isOnlyAtomInSelection =
        s.atomSelection.length === 1 && s.atomSelection[0] === atom;
      // console.log({
      //   isOnlyAtomInSelection,
      //   atomSelection: s.atomSelection.map(a => `${a.type}${a._id}`),
      //   atom,
      //   atomIdentifier: `${atom.type}${atom._id}`,
      //   firstSelection: s.atomSelection[0],
      //   firstSelectionIdentifier: `${s.atomSelection[0]?.type}${s.atomSelection[0]?._id}`,
      //   isEqual: atom === s.atomSelection[0],
      // });
      if (
        isRightClickEvent(e) &&
        s.atomSelection.length > 0 &&
        !isOnlyAtomInSelection
      ) {
        temporarilyDisablePointerEvents();
        s.composer.focusedCanvas?.actionsManagerContextMenu?.open(e);
        return;
      }
      if (hasAnyOpenedContextMenus()) return;
      if (s.composer.tools.activeTool?.name === ToolName.Hand) return;
      e.stopPropagation();
      clearAllContextMenus();
      s.composer.tools.activate(ToolName.Select);
      const modifierKeysPressed = e.shiftKey || e.metaKey || e.ctrlKey;
      if (!isSpeedScalarKeyframe(atom)) {
        if (
          !isOnlyAtomInSelection ||
          (atom._isSelected && modifierKeysPressed)
        ) {
          s.updateSelection({
            debugCaller: "CCanvasAtomPointerDownCapture",
            mode: modifierKeysPressed
              ? atom._isSelected
                ? "subtract"
                : "add"
              : "replace",
            atoms: [atom],
            setWriteToVoiceAs: true,
          });
        }
      }
      if (isNoteAtom(atom)) {
        if (debug)
          console.info(
            `%c🎸 ${atom.keyName || "Note"} clicked`,
            "color: red; font-style: italic",
            atom
          );
        if (atom.midiNumber)
          _.activateNoteKey(
            atom.midiNumber,
            atom.interpreted.instruments,
            atom.interpreted.velocity
          );
        const isBeingWrittenByQuill =
          s.composer.tools.quill.interactingNote === atom;
        if (!isBeingWrittenByQuill) {
          window.addEventListener("pointermove", _.handlePointerMoveStart, {
            once: true,
          });
          _.globalDisposers.push(_.watchMidiNumberChange(atom));
        }
      } else if (isKeyframeAtom(atom)) {
        if (debug)
          console.info(
            `%c🔸 Keyframe ${atom._id} clicked`,
            "color: orange; font-style: italic",
            atom
          );
        if (!isSpeedScalarKeyframe(atom) || atom._isSelected) {
          window.addEventListener("pointermove", _.handlePointerMoveStart, {
            once: true,
          });
        }
      }
      if (isTextNodeAtom(atom)) {
        atom.select();
        window.addEventListener("pointermove", _.handlePointerMoveStart, {
          once: true,
        });
      } else {
        window.addEventListener("pointermove", _.handlePointerMoveStart, {
          once: true,
        });
      }
      const handlePointerRelease = _.handlePointerReleaseFn(atom);
      window.addEventListener("pointerleave", handlePointerRelease);
      window.addEventListener("pointerup", handlePointerRelease);
      _.globalDisposers.push(() => {
        window.removeEventListener("pointermove", _.handlePointerMoveStart);
        window.removeEventListener("blur", handlePointerRelease);
        window.removeEventListener("pointerleave", handlePointerRelease);
        window.removeEventListener("pointerup", handlePointerRelease);
      });
    },
    findAndSelectAtomAtPointerPosition: (
      e: React.PointerEvent | React.MouseEvent | React.TouchEvent
    ) => {
      const selectableAtomAtXY =
        s.findSelectableRectangularAtomAtPointerEvent(e);
      if (selectableAtomAtXY) {
        s.handlePointerDownCaptureOnAtom(selectableAtomAtXY, e);
      }
      s.selectedAtomFromCurrentClick = selectableAtomAtXY;
      return selectableAtomAtXY;
    },
    get selectionColor() {
      const atomsHaveSameColor =
        new Set(
          s.selectionPseudoGroup.children
            .map(n => n.appearance?.colorInContext)
            .filter(i => i)
        ).size === 1;
      const defaultColor =
        s.managerParent?.COMPOSER.ROOT?.THEME.primary ?? ColorPalette.gray;
      if (atomsHaveSameColor)
        return (
          s.selectionPseudoGroup.children[0]?.appearance?.colorInContext ||
          defaultColor
        );
      return defaultColor;
    },
    dispose: () => {
      d.dispose();
    },
  });

  d.add(
    reaction(
      () => s.atomContext,
      () => (selectionPseudoGroup.context = s.atomContext)
    )
  );

  d.add(
    reaction(
      () => s.pointerContact?.id,
      () => (s.lastSelectionRectContentHasBeenPushed = false)
    )
  );

  d.add(
    reaction(
      () => s.atomSelection.filter(a => a._isDeleted),
      deleted => {
        removeManyFromArray(s.atomSelection, deleted);
        removeManyFromArray(selectionPseudoGroup.children, deleted);
      }
    )
  );

  d.add(
    reaction(
      () => mapToIds(s.atomSelection),
      idList => {
        if (s.atomSelection.length) {
          setUrlParam("a", idList.join(","));
          when(
            () => !s.composer?.focusedCanvas?.pointerContact?.isInteracting,
            () =>
              s.composer?.workspace.switchToMeta(
                WSPanelSelectionMeta as unknown as WorkspacePanelMeta
              )
          );
          const voiceOfLastSelectedAtom = last(s.atomSelection)?.voice;
          if (voiceOfLastSelectedAtom)
            s.composer?.updateVoiceSelection(voiceOfLastSelectedAtom);
        } else {
          s.composer?.workspace.hideByMeta(
            WSPanelSelectionMeta as unknown as WorkspacePanelMeta
          );
          removeUrlParam("a");
        }
      }
    )
  );

  const { a: selection } = getUrlParams();

  if (selection) {
    d.add(
      when(
        () => !!s.atomContext?.ready,
        () => {
          const atoms = s.atomContext?.getAtomsByIds(selection.split(","));
          if (atoms?.length) {
            s.updateSelection({
              atoms,
              debugCaller: "selection from URL params",
            });
          }
        }
      )
    );
  }

  return s;
};

export type SelectTool = ReturnType<typeof makeSelectTool>;

export const isSelectTool = (t: Tool | null): t is SelectTool =>
  t?.name === ToolName.Select;
