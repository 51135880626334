/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { FormControlProps } from "../../base/@types";
import Dropdown from "../../base/components/Dropdown";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Artist, ArtistSnapshot } from "../../models/Artist.model";

type ArtistTitleSelectorProps<T extends UnknownObject = UnknownObject> =
  FormControlProps<T> & {
    artist: Artist | ArtistSnapshot;
  };

const ArtistTitleSelector: React.FC<ArtistTitleSelectorProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get form() {
      return "$" in p.artist ? p.artist.$ : p.artist;
    },
  }));
  return (
    <Observer
      children={() => (
        <Dropdown
          className={p.className}
          form={s.form}
          field="title"
          Label="Title"
          options={[
            { label: "Artist", value: "Artist" },
            { label: "Composer", value: "Composer" },
          ]}
          taskName={p.taskName}
          mergeableId={p.mergeableId}
          mergeableTaskName={p.mergeableTaskName}
        />
      )}
    />
  );
};

export default ArtistTitleSelector;
