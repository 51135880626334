import { action } from "mobx";
import { Atom, Bar, Voice } from "../@types";
import { keepTruthy } from "../base/utils/array.utils";
import { add } from "../base/utils/math.utils";
import { copyWithJSON } from "../base/utils/object.utils";
import { isArray, isNumber } from "../base/utils/typeChecks.utils";

import {
  isBarAtom,
  isGroupLikeAtom,
  isKeyframeAtom,
  isNoteAtom,
  isPatternAtom,
  isReplicaAtom,
  isTextNodeAtom,
} from "../utils/atoms.utils";
import { duplicateGroupLikeAtomsByCopyingSnapshots } from "../utils/replicas.utils";
import { runAfter } from "../base/utils/waiters.utils";

type DuplicateAtomOptions = {
  atom: Atom;
  offsetX?: number;
  parentIds?: string[];
  inVoice?: Voice | null;
};

const duplicateAtom = action((options: DuplicateAtomOptions): Atom | null => {
  const { atom, offsetX = atom.width ?? 1, parentIds, inVoice } = options;
  if (!atom.context) return null;
  let newAtom: Atom | null = null;
  if (isBarAtom(atom)) {
    newAtom = atom.context.createBars(atom, atom.barIndex + 1) as Bar;
  } else if (isPatternAtom(atom)) {
    newAtom = atom.context.createReplica({
      patternId: atom._id,
      x: atom.anchor.x + offsetX,
      y: atom.anchor.y,
    });
  } else if (isReplicaAtom(atom)) {
    try {
      newAtom = duplicateGroupLikeAtomsByCopyingSnapshots({
        groupLikeAtom: atom,
        offsetX,
        parentIds,
        inVoice,
      });
    } catch (e) {
      newAtom = atom.context.createReplica({
        patternId: atom.patternId,
        scale: copyWithJSON(atom.$.scale),
        snapToScale: atom.$.snapToScale,
        parentIds: [...atom.$.parentIds],
        voiceId: atom.$.voiceId,
        x: atom.anchor.x + offsetX,
        y: atom.anchor.y,
      });
    }
  } else if (isGroupLikeAtom(atom)) {
    const duplicatedChildren = atom.children.map(n =>
      duplicateAtom({ atom: n, offsetX, parentIds: [], inVoice })
    ) as Atom[];
    newAtom = atom.context.createGroup(keepTruthy(duplicatedChildren), {
      parentIds: options.parentIds ?? atom.parentIds,
      refAtomId: atom.refAtomId,
    });
  } else if (isNoteAtom(atom)) {
    const x = isNumber(atom.x) ? atom.x + offsetX : atom.x;
    newAtom = atom.context.createNote({
      ...atom.$,
      voiceId: inVoice?._id ?? atom.$.voiceId,
      parentIds: parentIds ?? atom.$.parentIds,
      x,
    });
  } else if (isKeyframeAtom(atom)) {
    const x = add(atom.x, offsetX);
    newAtom = atom.context.createKeyframe({
      ...atom.$,
      voiceId: inVoice?._id ?? atom.$.voiceId,
      parentIds: parentIds ?? atom.$.parentIds,
      x,
    });
  } else if (isTextNodeAtom(atom)) {
    const x = (atom.x ?? 0) + offsetX;
    newAtom = atom.context.createTextNode({
      ...atom.$,
      x,
    });
  } else {
    console.warn("unhandled atom type in duplicateAtom operation", atom.type);
  }
  if (newAtom)
    atom.rules.forEach(rule => {
      atom.interpreter?.findOrCreateRuleForAtom(newAtom!, rule.$);
    });
  return newAtom;
});

export const duplicateAtomMultipleTimes = (
  options: DuplicateAtomOptions & { times?: number }
) => {
  const times = options.times ?? 1;
  const results = [options.atom];
  let nextToDuplicate = options.atom as Atom | null;
  for (let i = 0; i < times; i++) {
    if (nextToDuplicate === null) break;
    nextToDuplicate = duplicateAtom({
      ...options,
      atom: nextToDuplicate,
    });
    if (nextToDuplicate) results.push(nextToDuplicate);
    else break;
  }
  return results;
};

export type DuplicateAtomsOptions = {
  atoms: Atom[];
  atPosition?: number;
  inVoice?: Voice | null;
};

export const duplicateAtoms = action((options: DuplicateAtomsOptions) => {
  const { atoms, atPosition, inVoice } = options;
  if (atoms.length === 0) return atoms;
  const context = atoms[0].context;
  if (!context) {
    throw Error(
      `Attempted to duplicate atoms when there's no context available.`
    );
  }
  const startX = Math.min(
    ...(atoms.map(n => n.startX).filter(i => i !== null) as number[])
  );
  const endX = Math.max(
    ...(atoms.map(n => n.endX).filter(i => i !== null) as number[])
  );
  const positionToPlaceNewAtoms = atPosition ?? endX;
  const offset = positionToPlaceNewAtoms - startX;
  const results = keepTruthy(
    atoms.map(n => duplicateAtom({ atom: n, offsetX: offset, inVoice })).flat()
  ) as Atom[];
  const asArray = isArray(results) ? results : results ? [results] : [];
  if (asArray.length > 0)
    context.composer?.tools.select.updateSelection({
      debugCaller: "duplicateAtoms",
      atoms: asArray,
    });
  runAfter(() => {
    const newCursorPosition = Math.max(...results.map(r => r.endX ?? 0));
    context.canvas?.setPrimaryCursorPosition(newCursorPosition);
  });
  return asArray;
});
