/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Pattern } from "../../@types";
import Accordion from "../../base/components/Accordion";
import { UNITS } from "../../base/constants/units.constant";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { autoPluralizeWithNumber } from "../../base/utils/string.utils";
import { fg10, fg15 } from "../../constants/cssCustomProperties.constants";
import ReplicaEntry from "./ReplicaEntry";

type PatternReplicaManagerProps = {
  pattern: Pattern;
};

const patternReplicaManagerStyle = css`
  border: ${UNITS.lineWidth}px solid ${fg15};
`;
const headerStyle = css`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 1em;
  color: inherit;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${fg10};
  }
`;
const innerStyle = css`
  padding: 1em;
  border-top: ${UNITS.lineWidth}px solid ${fg10};
  > * + * {
    margin-top: 1em;
  }
`;

const PatternReplicaManager: React.FC<PatternReplicaManagerProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get replicas() {
      return p.pattern.replicas;
    },
    showDetails: false,
    toggleDetails: () => {
      s.showDetails = !s.showDetails;
    },
  }));
  return (
    <Observer
      children={() => (
        <Accordion
          css={patternReplicaManagerStyle}
          Summary={
            <button css={headerStyle} type="button">
              <h4>{autoPluralizeWithNumber(s.replicas, "Replica")}</h4>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="currentColor"
              >
                <path d="M2.3999 4.84L5.9999 8.44L9.5999 4.84" />
              </svg>
            </button>
          }
        >
          {() => (
            <div css={innerStyle}>
              {s.replicas.map(r => (
                <ReplicaEntry selectReplicaOnClick replica={r} key={r._id} />
              ))}
            </div>
          )}
        </Accordion>
      )}
    />
  );
};

export default PatternReplicaManager;
