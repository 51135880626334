import { makeIconDef } from "./makeIconDef";

export const SynthesizerIconDef = {
  synthesizer: makeIconDef("synthesizer", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0H7V4H7.5V5H20C20.5523 5 21 5.44772 21 6V11.5V12V19.5C21 20.3284 20.3284 21 19.5 21H5.5C4.67157 21 4 20.3284 4 19.5V12V11.5V6C4 5.44772 4.44772 5 5 5H5.5V4H6V0ZM6 6V7H5.5V8H6H7H7.5V7H7V6H6ZM18.5 11C19.0523 11 19.5 10.5523 19.5 10C19.5 9.44772 19.0523 9 18.5 9C17.9477 9 17.5 9.44772 17.5 10C17.5 10.5523 17.9477 11 18.5 11ZM17 12V16.75C17 16.8881 16.8881 17 16.75 17H15.625V20H19.5C19.7761 20 20 19.7761 20 19.5V12H17ZM14 12H11V16.75C11 16.8881 10.8881 17 10.75 17H10.3125V20H14.625V17H14.25C14.1119 17 14 16.8881 14 16.75V12ZM8 12H5V19.5C5 19.7761 5.22386 20 5.5 20H9.3125V17H8.25C8.11193 17 8 16.8881 8 16.75V12ZM6 11H7V8.5H6V11ZM9 11H10V9.5H9V11ZM12 11H13V8.5H12V11ZM15 11H16V9.5H15V11ZM19.5 7C19.5 7.55228 19.0523 8 18.5 8C17.9477 8 17.5 7.55228 17.5 7C17.5 6.44772 17.9477 6 18.5 6C19.0523 6 19.5 6.44772 19.5 7ZM10.5 8H10V6H9V8H8.5V9H9H10H10.5V8ZM13.5 7H13V6H12V7H11.5V8H12H13H13.5V7ZM16.5 8H16V6H15V8H14.5V9H15H16H16.5V8Z"
        fill="currentColor"
      />
    ),
  }),
};
