import { makeIconDef } from "./makeIconDef";

export const BookIconDef = {
  book: makeIconDef("book", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5V6V9V17V18V19V20H4H10C10.6134 20 11.1837 20.1841 11.6589 20.5H13.3411C13.8163 20.1841 14.3866 20 15 20H21H22V19V18V17V9V6V5H21H15C13.9565 5 13.0375 5.53276 12.5 6.34111C11.9625 5.53276 11.0435 5 10 5H4H3ZM21 9V6H15C13.8954 6 13 6.89543 13 8V9V17.5352C13.5881 17.1949 14.2711 17 15 17H21V9ZM21 19V18H15C14.2316 18 13.5308 18.2889 13 18.7639V19V19.5352C13.5881 19.1949 14.2711 19 15 19H21ZM12 9V8C12 6.89543 11.1046 6 10 6H4V9V17H10C10.7289 17 11.4119 17.1949 12 17.5352V9ZM12 18.7639C11.4692 18.2889 10.7684 18 10 18H4V19H10C10.7289 19 11.4119 19.1949 12 19.5352V19V18.7639ZM10 8H6V9H10V8ZM15 8H19V9H15V8ZM9 10H6V11H9V10ZM15 10H19V11H15V10ZM10 12H6V13H10V12ZM6 14H9V15H6V14ZM18 12H15V13H18V12Z"
        fill="currentColor"
      />
    ),
  }),
};
