import { action, observable } from "mobx";
import { Atom, Pattern, Replica } from "../@types";
import { ArrayElementType, Point, ValidPoint, ValidRect } from "../base/@types";
import {
  addOneToArrayIfNew,
  clearArray,
  moveItemToNewIndex,
  removeOneFromArray,
} from "../base/utils/array.utils";
import { makeDisposerController } from "../base/utils/disposer.utils";
import { subtractPoints } from "../base/utils/geometry.utils";
import { approxEq, round } from "../base/utils/math.utils";
import { watchObservableArrayChanges } from "../base/utils/mobx.utils";
import { copyWithJSON } from "../base/utils/object.utils";
import { first, isNil, uniq } from "../base/utils/ramdaEquivalents.utils";
import { KeyboardController } from "../controllers/keyboard.controller";
import { ZERO_POINT } from "../models/geometry/makePoint.model";
import {
  atomIsDirectlySelected,
  isGroupLikeAtom,
  isLeafAtom,
  isPatternAtom,
  isReplicaAtom,
  isTextNodeAtom,
} from "../utils/atoms.utils";

const debug = false;

export enum TransformStepType {
  "translate" = "translate",
  "scale" = "scale",
}

export type TransformStep<
  T extends TransformStepType = TransformStepType,
  Params extends UnknownObject = UnknownObject
> = {
  type: T;
  getParams: () => Params;
  params: Params;
  transform: null | TransformController;
  getOrigin?: () => Point;
  origin: Point;
};

export type TranslateTransformParams = {
  x: number;
  y: number;
};
export type SerializedTranslateTransformStep = {
  type: TransformStepType.translate;
  params: TranslateTransformParams;
  origin?: Point;
};
export type ScaleTransformParams = {
  x: number;
  y: number;
};
export type SerializedScaleTransformStep = {
  type: TransformStepType.scale;
  params: ScaleTransformParams;
  origin?: Point;
};
export type SerializedTransformStep =
  | SerializedTranslateTransformStep
  | SerializedScaleTransformStep;

export const makeTransformStepFactory =
  <T extends TransformStepType, Params extends UnknownObject>(type: T) =>
  (paramsGetter: () => Params, originGetter?: () => Point) => {
    const _ = observable({
      origin: ZERO_POINT as Point,
    });
    const s = observable({
      type,
      getParams: paramsGetter,
      getOrigin: originGetter,
      transform: null as null | TransformController,
      get params() {
        return s.getParams();
      },
      get origin() {
        return s.getOrigin?.() ?? ZERO_POINT;
      },
    });
    return s;
  };

export const makeTranslateTransformStep = makeTransformStepFactory<
  TransformStepType.translate,
  TranslateTransformParams
>(TransformStepType.translate);

export type TranslateTransformStep = ReturnType<
  typeof makeTranslateTransformStep
>;

export const makeScaleTransformStep = makeTransformStepFactory<
  TransformStepType.scale,
  ScaleTransformParams
>(TransformStepType.scale);
export type ScaleTransformStep = ReturnType<typeof makeScaleTransformStep>;

export type SupportedTransformStep =
  | TranslateTransformStep
  | ScaleTransformStep;

export const SELECTION_TRANSFORM_NAME = "SELECTION_TRANSFORM";
export type TransformController = {
  readonly name: string;
  readonly targets: Atom[];
  readonly steps: TransformStep[];
  origin: Point;
  readonly isActive: boolean;
  add: (step: SupportedTransformStep) => void;
  first: <T extends TransformStep = TransformStep>(
    type: TransformStepType
  ) => T | null;
  translates: TranslateTransformStep[];
  scales: ScaleTransformStep[];
  firstTranslate: TranslateTransformStep | null;
  firstScale: ScaleTransformStep | null;
  applyTransform: (debugCaller: string) => Atom[];
  reset: () => void;
  dispose: () => void;
  isSelectionTransform: boolean;
};

export const makeTransformController = (options: {
  name?: string;
  targetsGetter: () => Atom[];
  initialOrigin?: Point;
  originGetter?: () => Point;
  KEYBOARD?: KeyboardController;
  stepsGetter?: () => TransformStep[];
}) => {
  const d = makeDisposerController();
  const _ = observable({
    origin: options.originGetter?.() ?? ZERO_POINT,
    steps: [] as TransformStep[],
  });
  const s: TransformController = observable({
    get targets() {
      return options.targetsGetter();
    },
    get name() {
      if (options.name) return options.name;
      const uniqueStepNames = uniq(s.steps.map(step => step.type));
      if (uniqueStepNames.length === 1) {
        return uniqueStepNames[0];
      }
      return "Transform";
    },
    get isSelectionTransform() {
      return s.name === SELECTION_TRANSFORM_NAME;
    },
    get steps() {
      if (options.stepsGetter) return [...options.stepsGetter(), ..._.steps];
      return _.steps;
    },
    get origin() {
      if (options.originGetter) return options.originGetter();
      return _.origin;
    },
    set origin(point) {
      if (options.originGetter) {
        console.warn(
          `Cannot set transform origin to a transform controller whose origin is provided with a getter`
        );
        return;
      }
      _.origin = point;
    },
    get isActive() {
      return (
        s.translates.some(t => t.params.x !== 0 || t.params.y !== 0) ||
        s.scales.some(scale => scale.params.x !== 1 || scale.params.y !== 1)
      );
    },
    add: (step: SupportedTransformStep) => {
      if (debug) console.info("adding step to transform", s.name, step);
      if (options.stepsGetter) {
        console.warn(
          `Cannot add transform steps to a transform controller whose steps are provided with a getter`
        );
        return;
      }
      step.getOrigin = () => s.origin;
      _.steps.push(step);
      step.transform = s;
      if (debug) console.info(`transform now has ${s.steps.length} steps`);
      return step;
    },
    first: <T extends TransformStep = TransformStep>(type: TransformStepType) =>
      (s.steps.find(step => step.type === type) ?? null) as T | null,
    get translates() {
      return s.steps.filter(
        step => step.type === TransformStepType.translate
      ) as TranslateTransformStep[];
    },
    get scales() {
      return s.steps.filter(
        step => step.type === TransformStepType.scale
      ) as ScaleTransformStep[];
    },
    get firstTranslate() {
      return first(s.translates) ?? null;
    },
    get firstScale() {
      return first(s.scales) ?? null;
    },
    applyTransform: (debugCaller: string) => {
      const targets = s.targets;
      const context = first(targets)?.context;
      if (!context) return targets;
      if (debug) {
        console.info(
          `%c– ${s.name} applyTransform caller: ${debugCaller}`,
          "color: #B65B35"
        );
        console.info("applying transforms to targets:", targets);
        console.info(
          `${s.steps.length} steps: ${s.translates.length} translates, ${s.scales.length} scales`
        );
        if (s.firstTranslate)
          console.info(
            s.firstTranslate.type,
            s.firstTranslate.params.x,
            s.firstTranslate.params.y
          );
        if (s.firstScale)
          console.info(
            s.firstScale,
            s.firstScale.params.x,
            s.firstScale.params.y
          );
      }
      targets.forEach(target => {
        applyTransformsToAtom(target);
      });
      s.reset();
      return targets;
    },
    reset: () => {
      clearArray(s.steps);
    },
    dispose: () => {
      if (debug) console.info(`disposing transform controller "${s.name}"`);
      d.dispose();
    },
  });
  d.add(
    watchObservableArrayChanges(
      options.targetsGetter,
      action(t => addOneToArrayIfNew(t.transforms, s)),
      action(t => removeOneFromArray(t.transforms, s))
    )
  );
  d.add(() => {
    s.targets.forEach(t => {
      removeOneFromArray(t.transforms, s);
    });
  });
  d.add(
    watchObservableArrayChanges(
      () => s.steps,
      action(step => {
        step.transform = s;
      }),
      action(step => {
        if (step.transform === s) step.transform = null;
      })
    )
  );
  if (debug) console.info("🔬 Created transform controller", s.name);
  return s;
};

const xAxisProps = ["x", "width"] as const;
const yAxisProps = ["y", "height"] as const;
export type TransformablePropOnXAxis = ArrayElementType<typeof xAxisProps>;
export type TransformablePropOnYAxis = ArrayElementType<typeof yAxisProps>;
export type TransformableProp =
  | TransformablePropOnXAxis
  | TransformablePropOnYAxis;

export const isXAxisProp = (prop: string): prop is TransformablePropOnXAxis =>
  (xAxisProps as unknown as string[]).includes(prop);
export const isYAxisProp = (prop: string): prop is TransformablePropOnYAxis =>
  (yAxisProps as unknown as string[]).includes(prop);

export const getTransformedValueOfProp = (
  atom: Atom,
  prop: TransformableProp
) => {
  const preTransformValue = (atom[("_" + prop) as keyof Atom] ?? null) as
    | number
    | null;
  if (isNil(preTransformValue)) return preTransformValue;
  return atom.allTransformSteps.reduce(
    (prev: number | null, step, i): number | null => {
      if (prev === null) return null;
      if (
        atom.refAtom?._isSelected &&
        step.transform?.isSelectionTransform &&
        atom.$?.[prop] === null
      )
        return prev;
      switch (step.type) {
        case TransformStepType.translate:
          if (atom.replica && step.transform?.isSelectionTransform) return prev;
          const translate = step as TranslateTransformStep;
          switch (prop) {
            case "x":
              return prev + translate.params.x;
            case "y":
              return prev + translate.params.y;
          }
          return prev;
        case TransformStepType.scale:
          const { x: scalarX, y: scalarY } = (step as ScaleTransformStep)
            .params;
          switch (prop) {
            case "x":
              if (step.origin.x === null) return null;
              if (
                atom.refAtom &&
                atom.$.x !== null &&
                !step.transform?.isSelectionTransform
              )
                return prev;
              return (prev - step.origin.x) * scalarX + step.origin.x;
            case "y":
              if (step.origin.y === null) return null;
              if (
                atom.refAtom &&
                atom.$.y !== null &&
                !step.transform?.isSelectionTransform
              )
                return prev;
              return (prev - step.origin.y) * scalarY + step.origin.y;
            case "width":
              return Math.abs(prev * scalarX);
            case "height":
              return Math.abs(prev * scalarY);
            default:
              return prev;
          }
        default:
          return prev;
      }
    },
    preTransformValue
  );
};

export const setTransformedValueOfProp = (
  atom: Atom,
  prop: TransformableProp,
  value: number | null
) => {
  Reflect.set(atom, `_${prop}`, value);
};

export const getPatternTransformedAnchor = (P: Pattern) => {
  const _anchor = {
    _x: P._anchor.x,
    _y: P._anchor.y,
    allTransformSteps: P.allTransformSteps,
  } as unknown as Atom;
  return {
    x: getTransformedValueOfProp(_anchor, "x") as number,
    y: getTransformedValueOfProp(_anchor, "y") as number,
  } as ValidPoint;
};

export const getReplicaTransformedAnchor = (R: Replica) => {
  const _anchor = {
    _x: R._anchor.x,
    _y: R._anchor.y,
    allTransformSteps: R.allTransformSteps.filter(
      t => t !== R.scaleTransformStep
    ),
  } as unknown as Atom;
  return {
    x: getTransformedValueOfProp(_anchor, "x") as number,
    y: getTransformedValueOfProp(_anchor, "y") as number,
  } as ValidPoint;
};

export const getPatternTransformedBoundingBox = (P: Pattern) => {
  const point1 = {
    _x: P._boundingBox[0].x,
    _y: P._boundingBox[0].y,
    allTransformSteps: P.allTransformSteps,
  } as unknown as Atom;
  const point2 = {
    _x: P._boundingBox[1].x,
    _y: P._boundingBox[1].y,
    allTransformSteps: P.allTransformSteps,
  } as unknown as Atom;
  const transformedPoint1 = {
    x: getTransformedValueOfProp(point1, "x") as number,
    y: getTransformedValueOfProp(point1, "y") as number,
  };
  const transformedPoint2 = {
    x: getTransformedValueOfProp(point2, "x") as number,
    y: getTransformedValueOfProp(point2, "y") as number,
  };
  return [
    {
      x: Math.min(transformedPoint1.x, transformedPoint2.x),
      y: Math.min(transformedPoint1.y, transformedPoint2.y),
    },
    {
      x: Math.max(transformedPoint1.x, transformedPoint2.x),
      y: Math.max(transformedPoint1.y, transformedPoint2.y),
    },
  ] as ValidRect;
};

export const getReplicaTransformedBoundingBox = (R: Replica) => {
  const point1 = {
    _x: R._boundingBox[0].x,
    _y: R._boundingBox[0].y,
    allTransformSteps: R.allTransformSteps,
  } as Atom & {
    _x: number;
    _y: number;
  };
  const point2 = {
    _x: R._boundingBox[1].x,
    _y: R._boundingBox[1].y,
    allTransformSteps: R.allTransformSteps,
  } as unknown as Atom;
  const transformedPoint1 = {
    x: getTransformedValueOfProp(point1, "x") as number,
    y: getTransformedValueOfProp(point1, "y") as number,
  };
  const transformedPoint2 = {
    x: getTransformedValueOfProp(point2, "x") as number,
    y: getTransformedValueOfProp(point2, "y") as number,
  };
  return [
    {
      x: Math.min(transformedPoint1.x, transformedPoint2.x),
      y: Math.min(transformedPoint1.y, transformedPoint2.y),
    },
    {
      x: Math.max(transformedPoint1.x, transformedPoint2.x),
      y: Math.max(transformedPoint1.y, transformedPoint2.y),
    },
  ] as ValidRect;
};

export const applyTransformsToAtom = (A: Atom) => {
  if (isReplicaAtom(A)) {
    const scales = A.allTransformSteps.filter(
      step => step.type === TransformStepType.scale
    ) as ScaleTransformStep[];
    const translateX = A.anchor.x - A._anchor.x;
    const translateY = A.anchor.y - A._anchor.y;
    const scaleX = scales.reduce((prev, curr) => prev * curr.params.x, 1);
    const scaleY = scales.reduce((prev, curr) => prev * curr.params.y, 1);
    if (A.pattern) {
      if (!approxEq(translateX, 0)) {
        if (A.$.x === null) A.$.x = A.pattern.anchor.x;
        A.$.x += translateX;
      }
      if (!approxEq(translateY, 0)) {
        if (A.$.y === null) A.$.y = A.pattern.anchor.y;
        A.$.y += translateY;
      }
    }
    A.children.forEach(applyTransformsToAtom);
    if (!A.refAtom?.hasActiveTransforms) {
      if (scaleX !== A.scale.x) A.scale.x = scaleX;
      if (scaleY !== A.scale.y) A.scale.y = scaleY;
    }
  } else if (isGroupLikeAtom(A)) {
    if (isPatternAtom(A)) {
      const newBoundingBox = copyWithJSON(A.boundingBox);
      A.$.x = A.anchor.x;
      A.$.y = A.anchor.y;
      A.$.boundingBoxRelativeToAnchor = [
        subtractPoints(newBoundingBox[0], A._anchor),
        subtractPoints(newBoundingBox[1], A._anchor),
      ] as ValidRect;
    }
    A.children.forEach(applyTransformsToAtom);
  } else if (isLeafAtom(A) && A.refAtom) {
    const isDirectlySelected = atomIsDirectlySelected(A);
    if (isDirectlySelected || A.$.x !== null) A.$.x = round(A.startX, 4);
    if (isDirectlySelected || A.$.y !== null) A.$.y = round(A.y, 4);
    if (isDirectlySelected || A.$.width !== null) A.$.width = round(A.width, 4);
    if (isDirectlySelected || A.$.height !== null)
      A.$.height = round(A.height, 4);
  } else {
    const { startX, y, startY, width, height } = A;
    A.$.x = round(startX, 4);
    A.$.width = round(width, 4);
    if (isTextNodeAtom(A)) {
      A.$.height = round(height, 4);
    }
    if (isLeafAtom(A)) {
      if (debug) console.info(`Applying transform to ${A.type} ${A._id}`, A);
      A.$.y = y === null ? null : Math.round(y);
    } else {
      A.$.y = round(startY, 4);
      A.$.height = round(height, 4);
    }
  }
};

export const sortAtomTransforms = (transforms: TransformController[]) => {
  const selectionTransform = transforms.find(t => t.isSelectionTransform);
  if (selectionTransform)
    return moveItemToNewIndex(
      transforms,
      selectionTransform,
      transforms.length - 1
    );
  return transforms;
};
