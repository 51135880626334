/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import {
  mediaFromDesktop,
  mediaFromDesktopLg,
  mediaFromTablet,
} from "../../base/styles/helpers/mediaQueries.styling";

import { useStore } from "../../base/utils/mobx.utils";
import { varFontSerif } from "../../constants/cssCustomProperties.constants";
import { Collection } from "../../models/Collection.model";
import CollectionEntry from "./CollectionEntry";

interface CollectionGalleryProps {
  collections: Collection[];
  linkTo?: "discover" | "workshop";
  appearance?: "disc" | "entry";
}

const EmptyWarning = styled.div`
  margin-top: 0.5em;
  opacity: 0.6;
  font-size: 1.8rem;
  font-weight: 200;
  font-family: ${varFontSerif};
`;

const CollectionGallery: React.FC<CollectionGalleryProps> = observer(p => {
  const style = useStore(() => ({
    get gallery(): CSSPartial {
      return {
        display: "grid",
        maxWidth: "100%",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto",
        gridGap: "2em",
        ...mediaFromTablet({
          "&[data-appearance='disc']": {
            gridTemplateColumns: "repeat(auto-fill, minmax(22rem, 1fr))",
          },
          "&[data-appearance='entry']": {
            gridTemplateColumns: "repeat(auto-fill, minmax(28rem, 1fr))",
          },
        }),
        ...mediaFromDesktop({
          gridGap: "2.5em",
        }),
        ...mediaFromDesktopLg({
          "&[data-appearance='disc']": {
            gridTemplateColumns: "repeat(auto-fill, minmax(40rem, 1fr))",
          },
          "&[data-appearance='entry']": {
            gridTemplateColumns: "repeat(auto-fill, minmax(42rem, 1fr))",
          },
        }),
      };
    },
  }));

  return (
    <div
      className="CollectionGallery"
      data-appearance={p.appearance}
      css={style.gallery}
    >
      {p.collections.length === 0 && (
        <EmptyWarning>No collections found.</EmptyWarning>
      )}
      {p.collections.map(comp => (
        <CollectionEntry
          key={comp._id}
          collection={comp}
          linkTo={p.linkTo}
          appearance={p.appearance}
        />
      ))}
    </div>
  );
});

export default CollectionGallery;
