import { Sampler } from "tone";
import { SamplerFactoryConfig } from "../../@types";
import { EnsembleController } from "../../controllers/ensemble.controller";
import { extendSampleSet } from "./extendSample";
import * as Tone from "tone";

const createSamplerUrlLoadBatches = (urls: Record<string, string>) => {
  const result: Record<string, string>[] = [];
  const entries = Object.entries({ ...urls });
  for (let i = 0; i < 4; i++) {
    result[i] = result[i] ?? {};
    let j = 0;
    let entry = entries[4 * j + i];
    while (entry) {
      result[i][entry[0]] = entry[1];
      j++;
      entry = entries[4 * j + i];
    }
  }
  return result;
};

export const loadSampler = (
  o: SamplerFactoryConfig & {
    name: string;
    ENSEMBLE: EnsembleController;
  }
) =>
  new Promise<Sampler>(async (resolve, reject) => {
    // console.info(`[${o.name}] Sampler loading...`);
    const urls = Object.entries(o.urls)
      .map(([key, url]) => [key, encodeURIComponent(url)])
      .reduce((prev, curr) => {
        const key = o.octaveOffset
          ? curr[0].replace(
              /([\D#]+)(\d+)/i,
              (match, pitchClass: string, octave: string) =>
                `${pitchClass}${+octave + (o.octaveOffset ?? 0)}`
            )
          : curr[0];
        return { ...prev, [key]: curr[1] };
      }, {});
    const sampler = new Tone.Sampler({
      urls: o.extendable
        ? await extendSampleSet({ baseUrl: o.baseUrl, urls })
        : urls,
      baseUrl: o.baseUrl,
      onload: () => {
        if (o.volume) sampler.volume.value = o.volume;
        resolve(sampler);
      },
      onerror: e => {
        console.error(`Sampler ${o.baseUrl} load failed`);
        reject(e);
      },
    })
      .connect(o.ENSEMBLE.reverb!)
      .connect(o.ENSEMBLE.masterVolumeController!);
  });
