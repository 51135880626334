import { makeIconDef } from "./makeIconDef";

export const PlayIconDef = {
  play: makeIconDef("play", {
    regular: () => <path d="M8 19V6L19 12.5L8 19Z" fill="currentColor" />,
  }),
  "play-larger": makeIconDef("play-larger", {
    regular: () => <path d="M6 21V4L21 12.5L6 21Z" fill="currentColor" />,
  }),
};
