import { CSSProperties } from "react";
import { space } from "../../utils/array.utils";
import { isNumber } from "../../utils/typeChecks.utils";
import { px } from "../../utils/units.utils";

export const padding = (...args: (number | string)[]) => space(...args);
export const margin = padding;

export const border = (
  width: number | string,
  color = "currentColor",
  style: CSSProperties["borderStyle"] = "solid"
) => space(isNumber(width) ? px(width) : width, color, style);
