import { makeIconDef } from "./makeIconDef";

export const AlignIconDefs = {
  "align-left": makeIconDef("align-left", {
    regular: () => (
      <>
        <line x1="4.5" y1="4" x2="4.5" y2="21" stroke="currentColor" />
        <rect x="7" y="8" width="14" height="3" fill="currentColor" />
        <rect x="7" y="14" width="8" height="3" fill="currentColor" />
      </>
    ),
  }),
  "align-x-center": makeIconDef("align-x-center", {
    regular: () => (
      <>
        <line x1="12.5" y1="4" x2="12.5" y2="21" stroke="currentColor" />
        <rect x="4" y="8" width="17" height="3" fill="currentColor" />
        <rect x="8" y="14" width="9" height="3" fill="currentColor" />
      </>
    ),
  }),
  "align-right": makeIconDef("align-right", {
    regular: () => (
      <>
        <line x1="20.5" y1="4" x2="20.5" y2="21" stroke="currentColor" />
        <rect x="4" y="8" width="14" height="3" fill="currentColor" />
        <rect x="10" y="14" width="8" height="3" fill="currentColor" />
      </>
    ),
  }),
  "align-top": makeIconDef("align-top", {
    regular: () => (
      <>
        <line x1="4" y1="4.5" x2="21" y2="4.5" stroke="currentColor" />
        <rect
          x="8"
          y="21"
          width="14"
          height="3"
          transform="rotate(-90 8 21)"
          fill="currentColor"
        />
        <rect
          x="14"
          y="15"
          width="8"
          height="3"
          transform="rotate(-90 14 15)"
          fill="currentColor"
        />
      </>
    ),
  }),
  "align-y-center": makeIconDef("align-y-center", {
    regular: () => (
      <>
        <line x1="4" y1="12.5" x2="21" y2="12.5" stroke="currentColor" />
        <rect
          x="8"
          y="21"
          width="17"
          height="3"
          transform="rotate(-90 8 21)"
          fill="currentColor"
        />
        <rect
          x="14"
          y="17"
          width="9"
          height="3"
          transform="rotate(-90 14 17)"
          fill="currentColor"
        />
      </>
    ),
  }),
  "align-bottom": makeIconDef("align-bottom", {
    regular: () => (
      <>
        <line x1="4" y1="20.5" x2="21" y2="20.5" stroke="currentColor" />
        <rect
          x="8"
          y="18"
          width="14"
          height="3"
          transform="rotate(-90 8 18)"
          fill="currentColor"
        />
        <rect
          x="14"
          y="18"
          width="8"
          height="3"
          transform="rotate(-90 14 18)"
          fill="currentColor"
        />
      </>
    ),
  }),
};
