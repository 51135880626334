import { makeIconDef } from "./makeIconDef";

export const GroupIconDef = {
  group: makeIconDef("group", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49995 5C6.67153 5 5.99995 5.67157 5.99995 6.5V9.58579C5.99995 9.71839 5.94728 9.84557 5.85351 9.93934L3.6464 12.1464L3.29285 12.5L3.6464 12.8536L5.85351 15.0607C5.94728 15.1544 5.99995 15.2816 5.99995 15.4142V18.5C5.99995 19.3284 6.67153 20 7.49995 20H7.99995V19H7.49995C7.22381 19 6.99995 18.7761 6.99995 18.5V15.4142C6.99995 15.0164 6.84192 14.6349 6.56061 14.3536L4.70706 12.5L6.56061 10.6464C6.84192 10.3651 6.99995 9.98361 6.99995 9.58579V6.5C6.99995 6.22386 7.22381 6 7.49995 6H7.99995V5H7.49995ZM9.99995 8L17 8V5H9.99995L9.99995 8ZM9.99995 14H19V11H9.99995L9.99995 14ZM9.99995 17H21V20H9.99995L9.99995 17Z"
        fill="currentColor"
      />
    ),
  }),
};
