import { makeIconDef } from "./makeIconDef";

export const PianoKeyboardIconsDef = {
  "piano-keyboard": makeIconDef("piano-keyboard", {
    regular: () => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 5V19.5C5 19.7761 5.22386 20 5.5 20H9.3125V14H8.5C8.36193 14 8.25 13.8881 8.25 13.75V5H5ZM10.3125 20V14H10.6562C10.7943 14 10.9062 13.8881 10.9062 13.75V5H14.0938V13.75C14.0938 13.8881 14.2057 14 14.3438 14H14.625V20H10.3125ZM15.625 14V20H19.5C19.7761 20 20 19.7761 20 19.5V5H16.75V13.75C16.75 13.8881 16.6381 14 16.5 14H15.625ZM15.625 21H14.625H10.3125H9.3125H5.5C4.67157 21 4 20.3284 4 19.5V4.5C4 4.22386 4.22386 4 4.5 4H20.5C20.7761 4 21 4.22386 21 4.5V19.5C21 20.3284 20.3284 21 19.5 21H15.625Z"
          fill="currentColor"
        />
      </>
    ),
  }),
  "piano-keyboard-alt": makeIconDef("piano-keyboard-alt", {
    regular: () => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 5V19.5C3 19.7761 3.22386 20 3.5 20H7V14H6.25C6.11193 14 6 13.8881 6 13.75V5H3ZM8.5 5V13.75C8.5 13.8881 8.38807 14 8.25 14H8V20H12V14H11.5C11.3619 14 11.25 13.8881 11.25 13.75V5H8.5ZM13.75 5V13.75C13.75 13.8881 13.6381 14 13.5 14H13V20H17V14H16.75C16.6119 14 16.5 13.8881 16.5 13.75V5H13.75ZM18 20V14H18.75C18.8881 14 19 13.8881 19 13.75V5H22V19.5C22 19.7761 21.7761 20 21.5 20H18ZM8 21H12H13H17H18H21.5C22.3284 21 23 20.3284 23 19.5V4.5C23 4.22386 22.7761 4 22.5 4H2.5C2.22386 4 2 4.22386 2 4.5V19.5C2 20.3284 2.67157 21 3.5 21H7H8Z"
          fill="currentColor"
        />
      </>
    ),
  }),
};
