/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { CSSPartial } from "../../base/@types/css.types";
import Checkbox from "../../base/components/Checkbox";
import { FromTabletSpan } from "../../base/components/mediaQueries/tablet.mediaqueries";
import Spacing from "../../base/components/Spacing";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { varPrimary } from "../../constants/cssCustomProperties.constants";
import NoteIcon from "../shared/NoteIcon";

type SnapXControlProps = {
  showIcon?: boolean;
};

const style = {
  control: {
    display: "flex",
    alignItems: "center",
    'input[type="number"]': {
      width: "6.5em",
    },
    svg: {
      display: "inline-block",
    },
    button: {
      appearance: "none",
      backgroundColor: "transparent",
      color: "inherit",
      borderRadius: 0,
      border: 0,
      width: 24,
      padding: "0 0 5px 0",
      textAlign: "center",
      opacity: 0.7,
      "&:hover": {
        opacity: 0.9,
      },
      "&:active": {
        opacity: 0.7,
      },
      "&.selected": {
        color: varPrimary,
        opacity: 1,
      },
    },
    "&.disabled": {
      ".inner": {
        opacity: 0.6,
        "&:hover": {
          opacity: 1,
        },
      },
    },
  } as CSSPartial,
};

const SnapXControl: React.FC<SnapXControlProps> = props => {
  const { COMPOSER, SETTINGS } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get I() {
      return COMPOSER.instance;
    },
    get interpretation() {
      return COMPOSER.instance?.selectedInterpretation;
    },
    setter: (value: number) => {
      if (!SETTINGS.composer.snapUnitXEnabled)
        SETTINGS.composer.snapUnitXEnabled = true;
      SETTINGS.composer.snapUnitX = value;
    },
    handleInputChange: () => {
      if (!SETTINGS.composer.snapUnitXEnabled)
        SETTINGS.composer.snapUnitXEnabled = true;
    },
    showLargerUnits: false,
    showSmallerUnits: false,
  }));
  return (
    <Observer
      children={() => (
        <div
          className={cx(
            p.showIcon && "showIcon",
            !SETTINGS.composer.snapUnitXEnabled && "disabled"
          )}
          css={style.control}
        >
          <Checkbox
            Label="Snap X"
            form={SETTINGS.composer}
            field="snapUnitXEnabled"
          />
          <Spacing size=".625em" />
          <FromTabletSpan>
            {s.showSmallerUnits && (
              <>
                <SnapXOptionButton
                  currentValue={SETTINGS.composer.snapUnitX}
                  value={0.0625 / 2 / 2 / 2 / 2}
                  onClick={s.setter}
                >
                  <NoteIcon type="" />
                </SnapXOptionButton>
                <SnapXOptionButton
                  currentValue={SETTINGS.composer.snapUnitX}
                  value={0.0625 / 2 / 2 / 2}
                  onClick={s.setter}
                >
                  <NoteIcon type="" />
                </SnapXOptionButton>
                <SnapXOptionButton
                  currentValue={SETTINGS.composer.snapUnitX}
                  value={0.0625 / 2 / 2}
                  onClick={s.setter}
                >
                  <NoteIcon type="" />
                </SnapXOptionButton>

                <SnapXOptionButton
                  currentValue={SETTINGS.composer.snapUnitX}
                  value={0.0625 / 2}
                  onClick={s.setter}
                >
                  <NoteIcon type="" />
                </SnapXOptionButton>
              </>
            )}
            <SnapXOptionButton
              currentValue={SETTINGS.composer.snapUnitX}
              value={0.0625}
              onClick={s.setter}
            >
              <NoteIcon type="" />
            </SnapXOptionButton>
          </FromTabletSpan>
          <SnapXOptionButton
            currentValue={SETTINGS.composer.snapUnitX}
            value={0.125}
            onClick={s.setter}
          >
            <NoteIcon type="" />
          </SnapXOptionButton>
          <SnapXOptionButton
            currentValue={SETTINGS.composer.snapUnitX}
            value={0.25}
            onClick={s.setter}
          >
            <NoteIcon type="" />
          </SnapXOptionButton>
          <SnapXOptionButton
            currentValue={SETTINGS.composer.snapUnitX}
            value={0.5}
            onClick={s.setter}
          >
            <NoteIcon type="" />
          </SnapXOptionButton>
          <SnapXOptionButton
            currentValue={SETTINGS.composer.snapUnitX}
            value={1}
            onClick={s.setter}
          >
            <NoteIcon type="" />
          </SnapXOptionButton>
          {s.showLargerUnits && (
            <>
              <SnapXOptionButton
                currentValue={SETTINGS.composer.snapUnitX}
                value={2}
                onClick={s.setter}
              >
                <NoteIcon type="" />
              </SnapXOptionButton>
              <SnapXOptionButton
                currentValue={SETTINGS.composer.snapUnitX}
                value={4}
                onClick={s.setter}
              >
                <NoteIcon type="" />
              </SnapXOptionButton>
            </>
          )}
          <Spacing size=".5em" />
          <TextInput
            form={SETTINGS.composer}
            field="snapUnitX"
            type="number"
            min={0.0625}
            step={0.25}
            title="X snap"
            onChange={s.handleInputChange}
          />
        </div>
      )}
    />
  );
};

const SnapXOptionButton = (
  props: PropsWithChildren<{
    currentValue: number;
    value: number;
    onClick: (value: number) => void;
  }>
) => (
  <button
    className={cx(props.currentValue === props.value && "selected")}
    onClick={() => props.onClick(props.value)}
    type="button"
  >
    {props.children}
  </button>
);

export default SnapXControl;
