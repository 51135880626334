/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { CSSPartial } from "../@types/css.types";
import { useControllers } from "../hooks/rootContext.hooks";
import cx from "../utils/className.utils";
import { useProps, useStyle } from "../utils/mobx.utils";

type PillTagProps = {
  className?: string;
  color?: string | null;
  foregroundColor?: string | null;
};

const PillTag: React.FC<PropsWithChildren<PillTagProps>> = props => {
  const p = useProps(props);
  const { THEME } = useControllers();
  const style = useStyle(() => ({
    get color() {
      return p.color ?? THEME.fg;
    },
    get contrastColor() {
      return p.color
        ? THEME.getContrastColor(style.color)
        : THEME.contrastContextColors.foreground;
    },
    get tag(): CSSPartial {
      return {
        display: "inline-block",
        backgroundColor: style.color,
        color: p.foregroundColor ?? style.contrastColor,
        fontWeight: 700,
        textTransform: "uppercase",
        padding: ".1em .5em",
        borderRadius: ".2em",
        whiteSpace: "nowrap",
        letterSpacing: ".02em",
        fontSize: "85%",
      };
    },
  }));
  return (
    <Observer
      children={() => (
        <span className={cx("PillTag", p.className)} css={style.tag}>
          {p.children}
        </span>
      )}
    />
  );
};

export default PillTag;
