import { makeIconDef } from "./makeIconDef";

export const HomeIconDef = {
  home: makeIconDef("home", {
    regular: () => (
      <path
        d="M6 9V18.5H10.2386C10.2449 18.5 10.25 18.4949 10.25 18.4886V13.75C10.25 12.5074 11.2574 11.5 12.5 11.5C13.7426 11.5 14.75 12.5074 14.75 13.75V18.4886C14.75 18.4949 14.7551 18.5 14.7614 18.5H19V9L12.8049 4.2345C12.6251 4.09625 12.3749 4.09625 12.1951 4.2345L6 9Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
    ),
  }),
};
