import { Ornament } from "../@types";

export const detachOrnamentFromOriginalNote = (o: Ornament) => {
  if (o.note) {
    o.note.rulesRecursive.forEach(rule => {
      if (rule.properties.ornamentId === o._id)
        rule.properties.ornamentId = null;
    });
    if (o.note.$.ornamentId === o._id) o.note.$.ornamentId = null;
  }
};
