import { PropsWithChildren } from "react";
import LoadingIndicatorBlock from "../../base/components/LoadingIndicatorBlock";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useStore } from "../../base/utils/mobx.utils";
import { getUrlParams } from "../../base/utils/urlParams.utils";
import { contactEmailLink } from "../../constants/customerService.constants";
import AuthPage from "./components/AuthPage";

const ResetPasswordPage = (props: PropsWithChildren<{}>) => {
  const { AUTH } = useControllers();
  const s = useStore(() => ({
    token: "",
    noToken: null as boolean | null,
  }));
  useOnMount(() => {
    const { token } = getUrlParams<{ token?: string }>();
    if (token) s.token = token;
    else s.noToken = true;
  });
  return (
    <AuthPage title="Reset password">
      {s.noToken === null ? (
        <LoadingIndicatorBlock />
      ) : (
        <>
          {s.noToken ? (
            <p>
              This link seems to be invalid. Please contact us if you are having
              problems resetting your password.
            </p>
          ) : (
            <p>
              Reset password feature is not yet available. Please{" "}
              <a href={`${contactEmailLink}?subject=Reset password`}>
                contact us
              </a>{" "}
              if you are having problems resetting your password.
            </p>
          )}
        </>
      )}
    </AuthPage>
  );
};

export default ResetPasswordPage;
