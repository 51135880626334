import { makeIconDef } from "./makeIconDef";

export const BookmarksIconDef = {
  bookmark: makeIconDef("bookmark", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6.5C7 5.67157 7.67157 5 8.5 5H16.5C17.3284 5 18 5.67157 18 6.5V18.0858C18 19.4221 16.3843 20.0914 15.4393 19.1464L12.8536 16.5607C12.6583 16.3654 12.3417 16.3654 12.1464 16.5607L9.56066 19.1464C8.61571 20.0914 7 19.4221 7 18.0858V6.5ZM8.5 6C8.22386 6 8 6.22386 8 6.5V18.0858C8 18.5312 8.53857 18.7543 8.85355 18.4393L11.4393 15.8536C12.0251 15.2678 12.9749 15.2678 13.5607 15.8536L16.1464 18.4393C16.4614 18.7543 17 18.5312 17 18.0858V6.5C17 6.22386 16.7761 6 16.5 6H8.5Z"
        fill="currentColor"
      />
    ),
  }),
  bookmarks: makeIconDef("bookmarks", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.5C5 4.67157 5.67157 4 6.5 4H14.5C15.3284 4 16 4.67157 16 5.5V7H18.5C19.3284 7 20 7.67157 20 8.5V20.0858C20 21.4221 18.3843 22.0914 17.4393 21.1464L14.8536 18.5607C14.6583 18.3654 14.3417 18.3654 14.1464 18.5607L11.5607 21.1464C10.6157 22.0914 9 21.4221 9 20.0858V16.7071L7.56066 18.1464C6.61571 19.0914 5 18.4221 5 17.0858V5.5ZM9 15.2929V8.5C9 7.67157 9.67157 7 10.5 7H15V5.5C15 5.22386 14.7761 5 14.5 5H6.5C6.22386 5 6 5.22386 6 5.5V17.0858C6 17.5312 6.53857 17.7543 6.85355 17.4393L9 15.2929ZM10 8.5C10 8.22386 10.2239 8 10.5 8H18.5C18.7761 8 19 8.22386 19 8.5V20.0858C19 20.5312 18.4614 20.7543 18.1464 20.4393L15.5607 17.8536C14.9749 17.2678 14.0251 17.2678 13.4393 17.8536L10.8536 20.4393C10.5386 20.7543 10 20.5312 10 20.0858V8.5Z"
        fill="currentColor"
      />
    ),
  }),
  "bookmark-share": makeIconDef("bookmark-share", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6248 6.76777L15.3318 6.06079L15.3319 6.06066C15.5882 5.80438 15.9141 5.66022 16.2488 5.62818C16.2688 5.62626 16.2889 5.62475 16.3091 5.62363C16.7203 5.60085 17.1391 5.74653 17.4532 6.06066C17.5772 6.18463 17.6749 6.3249 17.7464 6.47457L18.1603 6.06066L18.4785 5.74247C18.3875 5.60504 18.2814 5.47463 18.1603 5.35355C17.7623 4.95559 17.2636 4.71985 16.7462 4.64632C16.3513 4.59019 15.9456 4.62858 15.5655 4.76147C15.5365 4.77161 15.5077 4.7823 15.479 4.79354C15.1678 4.91547 14.8762 5.10214 14.6248 5.35355L13.9177 6.06066C12.9414 7.03697 12.9414 8.61988 13.9177 9.59619C14.894 10.5725 16.4769 10.5725 17.4532 9.59619L17.8425 9.20693C17.429 9.21112 17.0149 9.14249 16.6211 9.00103C16.0321 9.47214 15.1705 9.43482 14.6248 8.88909C14.039 8.3033 14.039 7.35355 14.6248 6.76777ZM17.4531 8.18211C17.8781 8.24252 18.3157 8.19344 18.7203 8.03489C19.0315 7.91296 19.3231 7.72629 19.5745 7.47487L20.2816 6.76777C21.2579 5.79146 21.2579 4.20854 20.2816 3.23223C19.3053 2.25592 17.7224 2.25592 16.7461 3.23223L16.3568 3.6215C16.7703 3.61731 17.1844 3.68594 17.5782 3.8274C18.1672 3.35629 19.0288 3.39361 19.5745 3.93934C20.1603 4.52513 20.1603 5.47487 19.5745 6.06066L18.8675 6.76764L18.8674 6.76777C18.5958 7.03942 18.2458 7.1851 17.8902 7.2048C17.479 7.22758 17.0602 7.0819 16.7461 6.76777C16.6221 6.6438 16.5244 6.50353 16.4529 6.35386L16.039 6.76777L15.7208 7.08596C15.8118 7.22338 15.9179 7.35379 16.039 7.47487C16.437 7.87284 16.9357 8.10858 17.4531 8.18211ZM8.5 5H12V6H8.5C8.22386 6 8 6.22385 8 6.5V18.0858C8 18.5312 8.53857 18.7543 8.85355 18.4393L11.4393 15.8536C12.0251 15.2678 12.9749 15.2678 13.5607 15.8535L16.1464 18.4393C16.4614 18.7543 17 18.5312 17 18.0858V12H18V18.0858C18 19.4221 16.3843 20.0914 15.4393 19.1464L12.8536 16.5607C12.6583 16.3654 12.3417 16.3654 12.1464 16.5607L9.56066 19.1464C8.61571 20.0914 7 19.4221 7 18.0858V6.5C7 5.67157 7.67157 5 8.5 5Z"
        fill="currentColor"
      />
    ),
  }),
};
