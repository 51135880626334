import { CSSPartial } from "../../@types/css.types";
import { joinTruthy, joinTruthyWithSpace } from "../../utils/array.utils";
import {
  BreakpointName,
  breakpoint,
  isBreakpointName,
} from "./breakpoints.styleHelper";

export function mediaFromTo(
  dimension: "width" | "height",
  breakpointFloor?: BreakpointName | number,
  breakpointCeil?: BreakpointName | number
) {
  if (!breakpointFloor && !breakpointCeil) return "";
  const floor = isBreakpointName(breakpointFloor)
    ? breakpoint(breakpointFloor)
    : breakpointFloor;
  const ceil = isBreakpointName(breakpointCeil)
    ? breakpoint(breakpointCeil)
    : breakpointCeil;
  const floorQuery = floor && `min-${dimension}: ${floor}px`;
  const ceilQuery = ceil && `max-${dimension}: ${ceil - 1}px`;
  const query =
    (floorQuery || ceilQuery) && joinTruthy(" and ", floorQuery, ceilQuery);
  return joinTruthyWithSpace(`@media`, query && `(${query})`);
}

export const mediaScreenFrom = (breakpointFloor: BreakpointName | number) => {
  return mediaFromTo("width", breakpointFloor);
};
export const mediaScreenUpto = (breakpointCeil: BreakpointName | number) => {
  return mediaFromTo("width", undefined, breakpointCeil);
};

export const mediaScreenFromWithProperties = (
  breakpointFloor: BreakpointName | number,
  cssProperties: CSSPartial
) => {
  const query = mediaScreenFrom(breakpointFloor);
  return query
    ? {
        [query]: cssProperties,
      }
    : cssProperties;
};
export const mediaScreenUptoWithProperties = (
  breakpointCeil: BreakpointName | number,
  cssProperties: CSSPartial
) => {
  const query = mediaScreenUpto(breakpointCeil);
  return query
    ? {
        [query]: cssProperties,
      }
    : cssProperties;
};

export const mediaOnlyPhones = (cssProperties: CSSPartial) =>
  mediaScreenUptoWithProperties("tablet", cssProperties);
export const mediaFromPhoneLg = (cssProperties: CSSPartial) =>
  mediaScreenFromWithProperties("phone-lg", cssProperties);
export const mediaFromTablet = (cssProperties: CSSPartial) =>
  mediaScreenFromWithProperties("tablet", cssProperties);
export const mediaFromTabletLg = (cssProperties: CSSPartial) =>
  mediaScreenFromWithProperties("tablet-lg", cssProperties);
export const mediaUptoDesktop = (cssProperties: CSSPartial) =>
  mediaScreenUptoWithProperties("desktop", cssProperties);
export const mediaFromDesktop = (cssProperties: CSSPartial) =>
  mediaScreenFromWithProperties("desktop", cssProperties);
export const mediaFromDesktopMd = (cssProperties: CSSPartial) =>
  mediaScreenFromWithProperties("desktop-md", cssProperties);
export const mediaFromDesktopMl = (cssProperties: CSSPartial) =>
  mediaScreenFromWithProperties("desktop-ml", cssProperties);
export const mediaFromDesktopLg = (cssProperties: CSSPartial) =>
  mediaScreenFromWithProperties("desktop-lg", cssProperties);

export const onlyPhones = mediaScreenUpto("tablet");

export const fromTablet = mediaScreenFrom("tablet");
export const fromTabletMd = mediaScreenFrom("tablet-md");
export const fromTabletLg = mediaScreenFrom("tablet-lg");
export const fromTabletXl = mediaScreenFrom("tablet-xl");
export const fromDesktop = mediaScreenFrom("desktop");
export const fromDesktopMd = mediaScreenFrom("desktop-md");
export const fromDesktopMl = mediaScreenFrom("desktop-ml");
export const fromDesktopLg = mediaScreenFrom("desktop-lg");

export const uptoTablet = mediaScreenUpto("tablet");
export const uptoTabletMd = mediaScreenUpto("tablet-md");
export const uptoTabletLg = mediaScreenUpto("tablet-lg");
export const uptoTabletXl = mediaScreenUpto("tablet-xl");
export const uptoDesktop = mediaScreenUpto("desktop");
export const uptoDesktopMd = mediaScreenUpto("desktop-md");
export const uptoDesktopMl = mediaScreenUpto("desktop-ml");
export const uptoDesktopLg = mediaScreenUpto("desktop-lg");
