import { AtomContext } from "../@types";
import { HexOrContextColorName, SystemColorSchemeName } from "../base/@types";
import { isHex } from "../base/utils/colors.utils";

export type AppearanceColorDef =
  | HexOrContextColorName
  | AppearanceColorDefObject
  | null;
export type AppearanceColorDefObject = {
  dark?: HexOrContextColorName | null;
  light?: HexOrContextColorName | null;
};
export type RequiredAppearanceColorDefObject = {
  dark: string;
  light: string;
};
export type AppearanceSnapshot = {
  color?: AppearanceColorDef;
  highlight?: AppearanceColorDef;
  noteStyleType?: string | null;
  noteYScalar?: number | null;
  noteRoundedness?: number | null;
};
export const makeAppearanceBase = (): AppearanceSnapshot => ({});

export const hasAppearance = () =>
  ({
    appearance: undefined,
  } as unknown as {
    appearance: AppearanceSnapshot;
  });

export const getColorFromAppearanceInContext = (
  ac?: AtomContext,
  appearance?: AppearanceSnapshot
) => {
  const { color } = appearance || {};
  if (color) {
    if (isHex(color)) return color;
    return getColorFromColorDefObjectInContext(ac, color);
  }
  return null;
};

export const getHighlightColorFromAppearanceInContext = (
  ac?: AtomContext,
  appearance?: AppearanceSnapshot
) => {
  const { highlight } = appearance || {};
  if (highlight) {
    if (isHex(highlight)) return highlight;
    return getColorFromColorDefObjectInContext(ac, highlight);
  }
  return null;
};

export const getColorFromColorDefObjectInContext = (
  ac?: AtomContext,
  def?: AppearanceColorDefObject
) => {
  if (!def) return null;
  const theme =
    ac?.ROOT?.THEME.preferredColorScheme ??
    document.documentElement.getAttribute("data-theme") ??
    "dark";
  const fallback = theme === "dark" ? "light" : "dark";
  return def[theme as SystemColorSchemeName] || def[fallback] || null;
};

export const getColorFromColorDef = (
  theme: SystemColorSchemeName,
  def?: AppearanceColorDef
) => {
  if (!def) return null;
  if (isHex(def)) return def;
  const fallback = theme === "dark" ? "light" : "dark";
  return def[theme] || def[fallback] || null;
};

export type HasAppearance = Partial<ReturnType<typeof hasAppearance>>;
