import { action } from "mobx";
import { Atom, AtomContext, Voice } from "../@types";
import { isGroupLikeAtom, isVoiceAtom } from "../utils/atoms.utils";

type AssignVoiceParams = {
  context: AtomContext;
  newVoiceNumber?: number;
  newVoiceId?: string;
  newVoice?: Voice;
  atoms: Atom[];
};

const applyVoiceChangeToAtoms = action(
  (atoms: Atom[], newVoiceId: string | null) => {
    atoms.forEach(n => {
      if (isVoiceAtom(n)) return;
      n.$.voiceId = newVoiceId;
      if (isGroupLikeAtom(n)) {
        n.descendantNotes.forEach(d => (d.$.voiceId = newVoiceId));
      }
    });
  }
);

export const assignVoice = action((params: AssignVoiceParams) => {
  const { context, newVoiceNumber, atoms, newVoiceId, newVoice } = params;
  if (!newVoiceNumber && !newVoiceId && !newVoice) {
    applyVoiceChangeToAtoms(atoms, null);
  } else {
    const voice =
      newVoice ??
      ((newVoiceId && context.getAtomById<Voice>(newVoiceId)) ||
        (newVoiceNumber
          ? context.getVoiceByNumberOrName(newVoiceNumber)
          : null));
    applyVoiceChangeToAtoms(atoms, voice?._id ?? null);
  }
  return atoms;
});
