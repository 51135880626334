import { flow } from "mobx";
import { PropsWithChildren } from "react";
import Button from "../../base/components/Button";
import Spacing from "../../base/components/Spacing";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { readAxiosErrorMessage } from "../../base/utils/errors.utils";
import { useStore } from "../../base/utils/mobx.utils";
import { contactEmailLink } from "../../constants/customerService.constants";
import AuthPage from "./components/AuthPage";

const ForgotPasswordPage = (props: PropsWithChildren<{}>) => {
  const { AUTH } = useControllers();
  const s = useStore(() => ({
    form: {
      email: "",
    },
    error: null as string | null,
    send: async () =>
      await flow(function* () {
        if (!s.form.email) return;
        s.error = null;
        try {
          yield AUTH.forgotPassword(s.form);
        } catch (e) {
          reportError(e);
          s.error = readAxiosErrorMessage(e);
        }
      })(),
  }));
  return (
    <AuthPage title="Reset password">
      <p>
        Please{" "}
        <a href={`${contactEmailLink}?subject=Reset password`}>contact us</a>{" "}
        with the email you have signed up with to request a new password.
      </p>
      <Spacing size="1em" />
      <Button to="/auth/login" fullWidth>
        Log in
      </Button>
    </AuthPage>
  );
};

export default ForgotPasswordPage;
