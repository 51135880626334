export const hasXYZ = () => ({
  x: 0,
  y: 0,
  z: 0,
});
export const hasOptionalXYZ = () => ({
  x: null as number | null,
  y: null as number | null,
  z: null as number | null,
});

export type HasXYZ = ReturnType<typeof hasXYZ>;
export type HasOptionalXYZ = ReturnType<typeof hasOptionalXYZ>;
