import { CSSPartial } from "../../@types/css.types";
import { T, cond } from "../../utils/ramdaEquivalents.utils";

export const flex = (o?: {
  inline?: boolean;
  direction?: "row" | "column";
  reverse?: boolean;
  align?: "inherit" | "start" | "end" | "center" | "stretch" | "baseline";
  justify?:
    | "inherit"
    | "start"
    | "end"
    | "center"
    | "stretch"
    | "space-between"
    | "space-around";
  spacing?: number | string;
  wrap?:
    | true
    | "inherit"
    | "initial"
    | "wrap"
    | "nowrap"
    | "revert"
    | "unset"
    | "wrap-reverse";
}) => {
  return {
    display: o?.inline ? "inline-flex" : "flex",
    alignItems: o?.align,
    flexWrap: o?.wrap === true ? "wrap" : o?.wrap,
    flexDirection: cond([
      [
        () => o?.direction === "column",
        () => (o?.reverse ? "column-reverse" : "column"),
      ],
      [T, () => (o?.reverse ? "row-reverse" : "row")],
    ])(),
    justifyContent: cond([
      [() => o?.justify === "start", () => "flex-start"],
      [() => o?.justify === "end", () => "flex-end"],
      [() => T, () => o?.justify],
    ])(),
    "> * + *": {
      [o?.direction === "column" ? "marginTop" : "marginLeft"]: o?.spacing,
    },
  } as CSSPartial;
};

export const flexCenter = (inline = false) =>
  flex({ inline, align: "center", justify: "center" });
