import { AxiosError } from "axios";
import { flow, observable, runInAction, when } from "mobx";
import { SystemColorSchemeName } from "../../base/@types";
import {
  addOneToArrayIfNew,
  removeOneFromArray,
} from "../../base/utils/array.utils";
import { convertRecordMapToArray } from "../../base/utils/map.utils";
import { isStandardModel } from "../../base/utils/models.utils";
import { first } from "../../base/utils/ramdaEquivalents.utils";
import { getSnapshot, makeSnapshot } from "../../base/utils/snapshot.utils";
import { getNowTimestampUtc } from "../../base/utils/time.utils";
import { getUrlParams } from "../../base/utils/urlParams.utils";
import { ModelName } from "../../constants/modelName.constants";
import { Composition } from "../../models/Composition.model";
import {
  Interpretation,
  InterpretationSnapshot,
  InterpretationSnapshotFactory,
} from "../../models/Interpretation.model";
import { getAtomContextThumbnail } from "../../utils/thumbnails.utils";
import { ApiController } from "../api.controller";
import { LocalDBController } from "../localDB.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "../_root.controller";
import { FileRecord } from "../../models/FileRecord.model";
import { runAfter } from "../../base/utils/waiters.utils";

export const makeInterpretationsController = () => {
  const s = observable({
    ...makeControllerBase("INTERPRETATIONS"),

    get API(): ApiController {
      return s.ROOT!.API;
    },
    get LOCALDB(): LocalDBController {
      return s.ROOT!.LOCALDB;
    },
    get all(): Interpretation[] {
      return convertRecordMapToArray(s.LOCALDB.data.interpretations);
    },

    create: async (options: {
      compositionId: string;
      template?: Partial<InterpretationSnapshot>;
      shouldSyncBeforeCreation?: boolean;
    }) => {
      if (!s.ROOT) return;
      if (options.shouldSyncBeforeCreation) {
        if (s.ROOT.SYNC.hasChanges)
          await s.ROOT.SYNC.sync("savingBeforeCreatingNewInterpretation");
      }
      const { compositionId, template } = options;
      const composition =
        s.LOCALDB.data.compositions.get(compositionId) ??
        (await s.ROOT.COMPOSITIONS.get(compositionId));
      if (!composition) return null;
      const newInterpretation = await s.API.post<Interpretation>(
        `/interpretations/`,
        ModelName.interpretations,
        makeSnapshot(InterpretationSnapshotFactory, {
          name: "New Interpretation",
          ...template,
          compositionId,
        })
      );
      addOneToArrayIfNew(
        composition.$.interpretationIds,
        newInterpretation._id
      );
      await s.ROOT.COMPOSITIONS.save(composition);
      return newInterpretation;
    },

    duplicate: async (interpretationId: string) => {
      if (!s.ROOT) return;
      if (s.ROOT.SYNC.hasChanges)
        await s.ROOT.SYNC.sync("savingBeforeCreatingNewInterpretation");
      const newInterpretation = await s.API.post<Interpretation>(
        `/interpretations/${interpretationId}/duplicate`,
        ModelName.interpretations
      );
      const composer = s.ROOT.COMPOSER.instance;
      if (!composer || !composer.interpreter) return;
      runAfter(async () => {
        await composer.switchToInterpretationAsync(newInterpretation);
        runInAction(() => {
          composer.atomContext.atoms.forEach(a => {
            if (a._itpId !== newInterpretation._id) return;
            a._updateId();
          });
        });
      });
      return newInterpretation;
    },

    get: (id: string): Promise<Interpretation> =>
      flow(function* () {
        const interpretation: Interpretation = yield s.API.get<Interpretation>(
          "/interpretations/" + id,
          ModelName.interpretations
        );
        return interpretation;
      })(),

    save: (
      itp: Interpretation | Partial<InterpretationSnapshot>
    ): Promise<Interpretation> =>
      flow(function* () {
        const payload = getSnapshot<InterpretationSnapshot>(itp);
        const savedItp: Interpretation = yield s.API.patch<Interpretation>(
          `/interpretations/${payload._id}`,
          ModelName.interpretations,
          payload
        );
        if (
          isStandardModel(itp) &&
          itp.composition?.atomContext?.interpretation === itp
        ) {
          s.updateThumbnailSet(itp);
        }
        return savedItp;
      })(),

    updateThumbnail: async (
      itp: Interpretation,
      theme: SystemColorSchemeName
    ) => {
      const atomContext = itp.composition?.atomContext;
      if (
        !atomContext ||
        atomContext.interpretation !== itp ||
        !atomContext.composer
      ) {
        console.warn(
          `Unable to update thumbnail for interpretation#${itp._id}. It must first be loaded as the active interpretation.`
        );
        return;
      }
      await atomContext.composer.queue.ignoreInHistory(
        "Update thumbnails",
        flow(function* () {
          const pngBase64: string = yield getAtomContextThumbnail(
            atomContext,
            theme
          );
          const existingFileRecordId =
            (theme === "dark"
              ? itp.$.thumbnailDarkId
              : itp.$.thumbnailLightId) ?? undefined;
          const fileRecord: FileRecord = yield s.ROOT?.BUCKET.upload({
            existingFileRecordId,
            fileName: `thumbnail-${itp._id}-${theme}.png`,
            contentBase64: pngBase64,
            ignoreMissingFile: true,
          });
          s.ROOT?.LOCALDB.setOrMerge(ModelName.fileRecords, fileRecord);
          if (
            fileRecord &&
            (!existingFileRecordId || existingFileRecordId !== fileRecord._id)
          ) {
            () => {
              if (theme === "dark") {
                itp.$.thumbnailDarkId = fileRecord._id;
                if (itp.isDefault && itp.composition) {
                  itp.composition.$.thumbnailDarkId = fileRecord._id;
                }
              }
              if (theme === "light") {
                itp.$.thumbnailLightId = fileRecord._id;
                if (itp.isDefault && itp.composition) {
                  itp.composition.$.thumbnailLightId = fileRecord._id;
                }
              }
            };
            yield s.API.postRaw<Interpretation>(
              `/interpretations/${itp._id}/attach-thumbnail`,
              {
                type: theme,
                id: fileRecord._id,
              }
            );
          }
        })
      );
    },

    updateThumbnailSet: async (itp: Interpretation) => {
      await Promise.all([
        s.updateThumbnail(itp, "dark"),
        s.updateThumbnail(itp, "light"),
      ]);
      return itp;
    },

    archive: (i: Interpretation) =>
      flow(function* () {
        i.$.timeArchived = getNowTimestampUtc();
        return (yield s.save(i)) as Interpretation;
      })(),

    delete: async (itp: Interpretation): Promise<void> => {
      const id = itp._id;
      const comp = itp.composition;
      if (comp) {
        removeOneFromArray(comp.$.interpretationIds, id);
        await s.ROOT!.COMPOSITIONS.save(comp);
      }
      await s.API.delete(`/interpretations/${id}`);
    },

    createFreshInterpretationForComp: (
      composition: Composition,
      name = "Default",
      withId?: string
    ): Promise<Interpretation | null> =>
      flow(function* () {
        if (!composition._id) return null;
        const itp: Interpretation = yield s.create({
          compositionId: composition._id,
          template: {
            _id: withId ?? undefined,
            name,
          },
          shouldSyncBeforeCreation: false,
        });
        return itp;
      })(),

    retrieveSelectedOrCreateInterpretationForComp: (comp: Composition) =>
      new Promise<Interpretation | null>(async (resolve, reject) => {
        const { interpretationId } = getUrlParams();
        const selectedItpId =
          interpretationId ?? first(comp.$.interpretationIds);
        const rest = comp.$.interpretationIds.filter(
          id => id !== selectedItpId
        );
        if (selectedItpId) {
          try {
            const firstItp = await s.get(selectedItpId);
            resolve(firstItp);
          } catch (e) {
            reportError(e);
            try {
              if (
                (e as AxiosError).response?.status === 404 &&
                comp.$.interpretationIds.length === 1
              ) {
                comp.$.interpretationIds = [];
                resolve(await s.createFreshInterpretationForComp(comp));
                s.ROOT!.DIALOGS.attention({
                  Heading: `Could not find interpretation ${selectedItpId}`,
                  Body: "A new interpretation has been created.",
                });
              } else {
                when(
                  () => comp.interpretations.length > 0,
                  () => {
                    resolve(first(comp.interpretations) ?? null);
                  }
                );
              }
            } catch (e) {
              reportError(e);
              reject("Error getting interpretation for given composition.");
            }
          }
        } else {
          resolve(await s.createFreshInterpretationForComp(comp));
        }
        for (const id of rest) {
          try {
            await s.get(id);
          } catch (e) {
            if ((e as AxiosError).response?.status === 404) {
              removeOneFromArray(comp.$.interpretationIds, id);
            }
          }
        }
      }),

    reset: () => {
      // c.composerAppState = null;
    },
  });

  s.init = makeRootControllerChildInitFn(s, () => {});

  return s;
};

export type InterpretationsController = ReturnType<
  typeof makeInterpretationsController
>;
