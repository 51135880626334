/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Observer } from "mobx-react-lite";
import React from "react";
import { cVar } from "../../base/utils/customProperties.utils";
import {
  VAR_BorderForeground10,
  fg05,
  varFontMono,
} from "../../constants/cssCustomProperties.constants";
import { action } from "mobx";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { withOpacity } from "../../base/utils/colors.utils";
import { useComposer } from "./ComposerApp.context";

type IsolatedAtomDisplayProps = {};

const IsolatedAtomDisplayButton = styled.button`
  appearance: none;
  color: inherit;
  background-color: transparent;
  margin-right: 1em;
  border: ${cVar(VAR_BorderForeground10)};
  padding: 0.5em 0.5em 0.5em 0.75em;
  border-radius: 0.25em;
  svg {
    margin-right: 0.5em;
  }
  code {
    font-family: ${varFontMono};
    padding: 0.1em 0.25em;
    border-radius: 0.25em;
    font-weight: 500;
  }
  &:hover {
    background-color: ${fg05};
  }
`;

const IsolatedAtomDisplay: React.FC<IsolatedAtomDisplayProps> = props => {
  const { THEME } = useControllers();
  const I = useComposer();
  const select = I.tools.select;
  return (
    <Observer
      children={() =>
        select.isolatedAtom ? (
          <IsolatedAtomDisplayButton
            onClick={action(() => {
              select.clearSelection({
                debugHandle: "manuallyExitIsolationModeFromSelectControlBar",
              });
            })}
          >
            <svg width="7" height="7" viewBox="0 0 7 7" fill="none">
              <path
                d="M6 1L1 6M1 1L6 6"
                stroke="currentColor"
                strokeLinecap="round"
              />
            </svg>
            Isolated{" "}
            <code
              style={{
                backgroundColor: withOpacity(
                  select.isolatedAtom.appearance.colorInContext ?? THEME.fg,
                  0.1
                ),
                color:
                  select.isolatedAtom.appearance.colorInContext ?? THEME.fg,
              }}
            >
              {select.isolatedAtom.displayName}
            </code>
          </IsolatedAtomDisplayButton>
        ) : null
      }
    />
  );
};

export default IsolatedAtomDisplay;
