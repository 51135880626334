import { action, flow, observable } from "mobx";
import { VAR_VH } from "../constants/cssCustomProperties.constants";
import { DisplayMode } from "../base/constants/DisplayMode.enum";
import { ResizeQuery } from "../base/hooks/useResizeQuery";
import { breakpoint } from "../base/styles/helpers/breakpoints.styleHelper";
import { bindClass } from "../base/utils/dom.utils";
import { getValueOfKey } from "../base/utils/object.utils";
import PointerDetector from "../base/utils/pointerDetector.utils";
import resolveAfter from "../base/utils/waiters.utils";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { UNITS } from "../base/constants/units.constant";

export type UIController = ReturnType<typeof makeUIController>;

export type RegisterableComponentIdentifiers =
  | "titleBar"
  | "composerHeader"
  | "composerHeaderTitle"
  | "composerHeaderSubheading"
  | "composerHeaderMeta"
  | "composerHeaderCollectionsAndArtists"
  | "visualizerHeader"
  | "instrument"
  | "voiceTabSet"
  | "dock"
  | "toolWheel";
export type ComponentRegistryEntry<T extends HTMLElement = HTMLElement> = {
  identifier: RegisterableComponentIdentifiers;
  query: ResizeQuery | null;
  ref: React.MutableRefObject<T | null> | null;
};

declare global {
  interface Screen {
    availLeft: number | undefined;
    availTop: number | undefined;
  }
}

const makeComponentRegistryEntry = (
  identifier: RegisterableComponentIdentifiers
) => {
  return {
    [identifier]: {
      identifier,
      query: null as ResizeQuery | null,
      ref: null as React.MutableRefObject<HTMLDivElement | null> | null,
    },
  };
};

export const makeUIController = () => {
  const c = observable({
    ...makeControllerBase("UI"),

    appWidth: window.innerWidth,
    appHeight: window.innerHeight,
    screen: { ...window.screen },
    screenX: window.screenX,
    screenY: window.screenY,
    pixelDensity: window.devicePixelRatio,
    hasFocus: document.hasFocus(),

    get isFullScreen(): boolean {
      return (
        c.screenX === (c.screen.availLeft ?? 0) &&
        c.screenY === (c.screen.availTop ?? 0)
      );
    },

    get displayMode(): DisplayMode {
      if (c.appWidth >= breakpoint("desktop")) return DisplayMode.desktop;
      if (c.appWidth >= breakpoint("tablet")) return DisplayMode.tablet;
      return DisplayMode.phone;
    },
    get isPhone(): boolean {
      return c.displayMode === DisplayMode.phone;
    },
    get isTablet(): boolean {
      return c.displayMode === DisplayMode.tablet;
    },
    get isDesktop(): boolean {
      return c.displayMode === DisplayMode.desktop;
    },

    pointerTypes: [] as string[],

    appContainerRef: null as React.RefObject<HTMLDivElement> | null,
    registerAppContainerRef: (ref: React.RefObject<HTMLDivElement>) => {
      c.appContainerRef = ref;
    },

    componentRegistry: {
      ...makeComponentRegistryEntry("titleBar"),
      ...makeComponentRegistryEntry("composerHeader"),
      ...makeComponentRegistryEntry("composerHeaderTitle"),
      ...makeComponentRegistryEntry("composerHeaderSubheading"),
      ...makeComponentRegistryEntry("composerHeaderMeta"),
      ...makeComponentRegistryEntry("composerHeaderCollectionsAndArtists"),
      ...makeComponentRegistryEntry("visualizerHeader"),
      ...makeComponentRegistryEntry("instrument"),
      ...makeComponentRegistryEntry("voiceTabSet"),
      ...makeComponentRegistryEntry("toolWheel"),
      ...makeComponentRegistryEntry("dock"),
    } as Record<RegisterableComponentIdentifiers, ComponentRegistryEntry>,
    registerComponent: flow(function* (r: ComponentRegistryEntry) {
      yield resolveAfter();
      const registry = getValueOfKey(r.identifier, c.componentRegistry);
      if (registry) {
        registry.identifier = r.identifier;
        registry.query = r.query;
        registry.ref = r.ref;
      }
    }),
    deregisterComponent: (identifier: RegisterableComponentIdentifiers) => {
      const registry = getValueOfKey(identifier, c.componentRegistry);
      if (registry) {
        registry.query = null;
        if (registry.ref) registry.ref.current = null;
        registry.ref = null;
      }
    },
  });

  c.init = makeRootControllerChildInitFn(c, () => {
    window.addEventListener("contextmenu", e => e.preventDefault());
    const measureWindowDimensions = action(() => {
      c.appWidth = c.appContainerRef?.current?.clientWidth || window.innerWidth;
      c.appHeight =
        c.appContainerRef?.current?.clientHeight || window.innerHeight;
      c.screen = { ...window.screen };
      c.screen.availLeft = window.screen.availLeft;
      c.screen.availTop = window.screen.availTop;
      c.screenX = window.screenX;
      c.screenY = window.screenY;
      document.body.style.setProperty(
        VAR_VH,
        window.innerHeight !== window.outerHeight
          ? `${window.innerHeight}px`
          : "100vh"
      );
      c.pixelDensity = window.devicePixelRatio;
      if (
        window.devicePixelRatio < 2 ||
        c.ROOT?.SETTINGS.computedValues.highContrastBorders
      ) {
        UNITS.lineWidth = 1;
      } else {
        UNITS.lineWidth = 0.5;
      }
      c.ready = true;
    });
    new PointerDetector({
      onDetectingMouse: action(() => c.pointerTypes.push("mouse")),
      onDetectingTouch: action(() => c.pointerTypes.push("touch")),
      onDetectingStylus: action(() => c.pointerTypes.push("stylus")),
    });
    window.addEventListener("resize", measureWindowDimensions);
    bindClass(document.documentElement, () => c.isFullScreen, "fullscreen");
    measureWindowDimensions();
    window.addEventListener(
      "focus",
      action(() => {
        c.hasFocus = true;
      })
    );
    window.addEventListener(
      "blur",
      action(() => {
        c.hasFocus = false;
      })
    );
  });

  return c;
};
