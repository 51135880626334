export function spaceChildren(y?: string | number, x?: string | number) {
  const yValue = y ?? "1em";
  const xValue = x;
  return {
    "> * + *": {
      marginTop: yValue,
      marginInlineStart: xValue,
    },
  };
}
