import { observable } from "mobx";
import { Atom, AtomType, Voice } from "../../@types";
import { uniq } from "../../base/utils/ramdaEquivalents.utils";
import { createAtomFactory } from "../../logic/Atom.factory";
import {
  GroupSnapshotFactory,
  makeGroupExtendedMembersFactory,
  pushAtomsToGroup,
} from "./Group.model";

export const VoiceSnapshotFactory = () => ({
  ...GroupSnapshotFactory(AtomType.voice),
  manualOrder: 0,
  solo: false,
  childrenIds: [] as string[],
  parentVoiceId: null as string | null,
});

export const makeVoiceExtendedMembers = (V: Voice) => ({
  get displayName(): string {
    return V.name || `Voice ${V._id}`;
  },
  set displayName(value: string) {
    V.name = value;
  },
  get voice() {
    return null;
  },
  get x() {
    return 0;
  },
  get x2() {
    return V.context?.lastNoteByEndX?.endX ?? 0;
  },
  get startX() {
    return 0;
  },
  get endX() {
    return V.context?.lastNoteByEndX?.endX ?? 0;
  },
  get width() {
    return V.context?.lastNoteByEndX?.endX ?? 0;
  },
  get voiceIndex() {
    return V.context ? V.context.voicesSortedByY.indexOf(V) : 0;
  },
  get order() {
    switch (V.context?.composition?.options.voiceOrder) {
      case "manual":
        return V.manualOrder;
      case "by-pitch":
      default:
        return V.voiceIndex;
    }
  },
  set order(v: number) {
    switch (V.context?.composition?.options.voiceOrder) {
      case "by-pitch":
        return;
      case "manual":
      default:
        V.manualOrder = v;
    }
  },
  children: observable([]),
  get parents() {
    return [];
  },
  get childVoices() {
    return (
      V.context?.voices.filter(voice => voice.$.parentVoiceId === V._id) ?? []
    );
  },
  get childVoicesOrdered() {
    return [...V.childVoices].sort((a, b) => a.order - b.order);
  },
  get parentVoice() {
    return V.context?.getAtomById<Voice>(V.$.parentVoiceId) ?? null;
  },
  set parentVoice(pv) {
    V.$.parentVoiceId = pv?._id ?? null;
  },
  get ancestorVoices() {
    return V.parentVoice?.voicePath ?? [];
  },
  get voicePath() {
    return uniq([...(V.parentVoice?.voicePath ?? []), V].filter(i => i));
  },
  get hasChildVoices() {
    return V.childVoices.length > 0;
  },
  get isWritable() {
    return !V.hasChildVoices;
  },
  pushAtoms: (...atoms: Atom[]) => pushAtomsToGroup(V, ...atoms),
  get muted() {
    return !!V.rulePropertiesFlattened.disabled;
  },
  select: () => {
    V.context?.composer?.selectAtoms(V.descendants);
  },
});

export const makeVoice = createAtomFactory<Voice>({
  type: AtomType.voice,
  snapshotFactory: VoiceSnapshotFactory,
  extendedPropertiesFactories: [
    makeGroupExtendedMembersFactory,
    makeVoiceExtendedMembers,
  ],
});
