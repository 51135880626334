/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import {
  varFontSerif,
  varPrimary,
  varPrimary10,
  varPrimary20,
  varPrimary30,
} from "../../constants/cssCustomProperties.constants";
import { UNITS } from "../constants/units.constant";

export type LargeButtonProps = {
  Icon: ReactNode;
  Label: ReactNode;
  onClick?: () => void;
  width?: string | number;
  height?: string | number;
  aspectRatio?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
};

const LargeButton: React.FC<LargeButtonProps> = props => {
  return (
    <button
      css={{
        appearance: "none",
        display: "grid",
        alignItems: "start",
        justifyItems: "start",
        gridTemplateRows: "minmax(auto, 1fr) auto",
        gridGap: "1em",
        padding: "1.4rem",
        borderRadius: "3px",
        border: `${UNITS.lineWidth}px solid ${varPrimary30}`,
        backgroundColor: varPrimary10,
        textAlign: "left",
        color: varPrimary,
        width: props.width ?? "12em",
        height: props.height ?? "auto",
        fontFamily: varFontSerif,
        fontWeight: 100,
        '[data-theme="light"] &': {
          fontWeight: 300,
        },
        fontSize: "2.4rem",
        cursor: "pointer",
        aspectRatio: props.aspectRatio ?? "1/1",
        "&:hover": {
          backgroundColor: varPrimary20,
          borderColor: varPrimary30,
        },
        "&:active": {
          filter: "brightness(.9)",
        },
      }}
      onClick={props.onClick}
      type={props.type ?? "button"}
    >
      {props.Icon}
      <span>{props.Label}</span>
    </button>
  );
};

export default LargeButton;
