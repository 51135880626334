import { NumberRange } from "../base/@types";
import { IconName } from "../base/components/Symbols/iconDefs/_index.iconDefs";
import { EnsembleController } from "../controllers/ensemble.controller";
import { Note } from "./atomsAndAtomContext.types";

export enum InstrumentStatus {
  connected = "connected",
  disconnected = "disconnected",
}
export type InstrumentRange = NumberRange[];
export type InstrumentAppearance = {
  color?: string;
};

export type InstrumentSelectedOutputDef = {
  name: string;
  channels: string[];
  enabled: boolean;
};

export type StandardInstrumentOptions = {
  nickName: string;
  color: string;
  outputs: InstrumentSelectedOutputDef[];
  sendToLocal: boolean;
  shouldDisableIfExternalOutputsUnavailable: boolean;
  activatedSamplers?: string[];
  volumeAdjustment: number;
};
export type AttackReleaseNumberInputType = "midi" | "frequency";

export type AttackReleaseParams = {
  inputType?: AttackReleaseNumberInputType;
  velocity?: number;
  time?: number;
  immediate?: boolean;
  midiChannel?: number;
  note?: Note;
  onComplete?: () => void;
};

export type InstrumentType =
  | "keyboards"
  | "woodwind"
  | "strings"
  | "brass"
  | "percussion";

export type InstrumentMeta<
  Options extends StandardInstrumentOptions = StandardInstrumentOptions
> = {
  name: string;
  displayName: string;
  defaultColor?: string;
  type: InstrumentType;
  icon?: IconName;
  source?: string;
  sourceWebsite?: string;
  range: InstrumentRange;
  ControlPanel?: React.FC<{
    instrument: Instrument<Options>;
  }>;
  /** loadable, but not shown in selectors */
  hidden?: boolean;
  hasSampledVelocity?: boolean;
  supportsSustainPedal?: boolean;
};

export interface Instrument<
  Options extends StandardInstrumentOptions = StandardInstrumentOptions
> {
  _id: string;
  $: InstrumentSnapshot<Options>;
  $patch: ($: InstrumentSnapshot<Options>) => Instrument<Options>;
  nickName: string;
  meta: InstrumentMeta<Options>;
  isLoading?: boolean;
  initiated?: boolean;
  status: InstrumentStatus;
  range: InstrumentRange;
  options: Options;
  readonly supportsMasterTuning: boolean;
  readonly supportsVelocity: boolean;
  appearance: InstrumentAppearance;
  attack(
    which: string | number,
    after?: number,
    options?: AttackReleaseParams
  ): void;
  release(
    which: string | number,
    after?: number,
    options?: AttackReleaseParams
  ): Promise<void>;
  releaseAll: () => void;
  setNote?(which: string | number, after?: number): this;
  midiNumberIsInRange(n: number): boolean;
  canPlay(n: Note | string | number): boolean;
  playNote(n: Note, options?: AttackReleaseParams): Promise<void>;
  getNoteAttackParams: (n: Note) =>
    | false
    | {
        beatCount: number;
        midiNumber: number;
        frequency: number;
        velocity: number;
      };
  attackNote: (n: Note, options?: AttackReleaseParams) => void;
  releaseNote: (n: Note) => void;
  sustainPedalIsDown?: boolean;
  sustainPedalDown?: () => void;
  sustainPedalUp?: () => void;
  init: () => Promise<void>;
  disconnect: () => Promise<void> | void;
  errors: Error[];
  hasErrors: boolean;
  dispose: () => void;
}

export type AnyInstrument = Instrument<StandardInstrumentOptions & AnyObject>;

export type InstrumentFactory<
  Options extends StandardInstrumentOptions = StandardInstrumentOptions
> = (
  ENSEMBLE: EnsembleController,
  snapshot: InstrumentSnapshot<Options>
) => Instrument<Options> | Promise<Instrument<Options>>;

export type InstrumentSnapshot<
  O extends StandardInstrumentOptions = StandardInstrumentOptions
> = {
  _id: string;
  name: string;
  options: O;
};

export type InstrumentDef<
  Options extends StandardInstrumentOptions = StandardInstrumentOptions
> = {
  meta: InstrumentMeta<Options>;
  factory: InstrumentFactory<Options>;
  defaultOptionsFactory: () => Options;
};

export type SamplerFactoryConfig = {
  name?: string;
  baseUrl: string;
  urls: Record<string, string>;
  volume?: number;
  octaveOffset?: number;
  extendable?: boolean;
};

export type CompositeSamplerConfig = {
  name: string;
  velocityRangeFrom?: number;
  samplerConfigs: {
    sustain: SamplerFactoryConfig;
    release?: SamplerFactoryConfig;
  };
  releaseDuration?: number;
};

export type SamplerGetter<
  O extends StandardInstrumentOptions = StandardInstrumentOptions
> = (note?: Note, instrumentOptions?: O) => string[];
export type VelocityGetter<
  O extends StandardInstrumentOptions = StandardInstrumentOptions
> = ((note?: Note, instrumentOptions?: O) => number) | number;

export type ValueGetterSet<
  O extends StandardInstrumentOptions = StandardInstrumentOptions
> = {
  samplers?: SamplerGetter<O>;
  velocity?: {
    attack?: VelocityGetter<O>;
    release?: VelocityGetter<O>;
  };
};
