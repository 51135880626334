import { observable } from "mobx";
import { convertRecordMapToArray } from "../../base/utils/map.utils";
import { makeSnapshot } from "../../base/utils/snapshot.utils";
import { ModelName } from "../../constants/modelName.constants";
import {
  Artist,
  ArtistSnapshot,
  ArtistSnapshotFactory,
} from "../../models/Artist.model";
import { Composition } from "../../models/Composition.model";
import { makeRequest } from "../../utils/request.utils";
import { ApiController, GetRequestOptions } from "../api.controller";
import { LocalDBController } from "../localDB.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "../_root.controller";

export const makeArtistsController = () => {
  const s = observable({
    ...makeControllerBase("ARTISTS"),

    get API(): ApiController {
      return s.ROOT!.API;
    },
    get LOCALDB(): LocalDBController {
      return s.ROOT!.LOCALDB;
    },
    get all(): Artist[] {
      return convertRecordMapToArray(s.LOCALDB.data.artists);
    },
    get allNonArchived(): Artist[] {
      return s.all.filter(a => !a.$.timeArchived);
    },
    get allArchived(): Artist[] {
      return s.all.filter(a => !!a.$.timeArchived);
    },
    get own(): Artist[] {
      return s.ROOT?.AUTH.user
        ? s.all.filter(a => a.owner === s.ROOT?.AUTH.user)
        : [];
    },
    get ownNonArchived(): Artist[] {
      return s.own.filter(a => !a.$.timeArchived);
    },

    getFromLocalDB: (id: string) => {
      return s.LOCALDB.get<Artist>(ModelName.artists, id);
    },

    create: async (template?: ArtistSnapshot) => {
      return await s.API.post<Artist>(
        "/artists",
        ModelName.artists,
        makeSnapshot(ArtistSnapshotFactory, {
          givenName: "New artist",
          ...template,
        })
      );
    },

    get: async (
      id: string,
      options?: GetRequestOptions & {
        withCompositionMetaList?: boolean;
      }
    ) => {
      const artist = await s.API.get<Artist>(
        "/artists/" + id,
        ModelName.artists,
        options
      );
      if (options?.withCompositionMetaList) {
        await s.API.getMany<Composition>(
          "/compositions/query",
          ModelName.compositions,
          {
            select: "-atomSnapshots",
            queries: {
              artistIds: id,
            },
          }
        );
      }
      return artist;
    },

    getAllOwn: async () =>
      await s.API.getMany<Artist>("/artists", ModelName.artists),

    getAllPublic: async () =>
      await makeRequest(
        "ARTISTS.getAllPublic",
        async () =>
          await s.API.getMany<Artist>("/public/artists", ModelName.artists)
      ).fetch(),

    save: async (artist: Artist | ArtistSnapshot) =>
      await s.API.patch<Artist>(
        "/artists/" + artist._id,
        ModelName.artists,
        artist
      ),

    reset: () => {},
  });

  s.init = makeRootControllerChildInitFn(s, () => {
    // s.getAllPublic();
  });

  return s;
};

export type ArtistsController = ReturnType<typeof makeArtistsController>;
