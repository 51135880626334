import { makeIconDef } from "./makeIconDef";

export const TextIconDef = {
  text: makeIconDef("text", {
    regular: () => (
      <path
        d="M7.43196 5.496H11.536V19.944H8.82396V21H16.552V19.944H13.84V5.496H17.944L20.416 9.072V3.744L19.12 4.44H6.25596L4.95996 3.744V9.072L7.43196 5.496Z"
        fill="currentColor"
      />
    ),
  }),
};
