import React from "react";
import { isIOS, isMac } from "./browsersAndPlatforms.utils";

export const eventHasMetaOrCtrlKey = (e: React.MouseEvent) => {
  return isMac || isIOS ? e.metaKey : e.ctrlKey;
};

export const getClientXFromMouseOrTouchEvent = (
  e: MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent
) => {
  return (e as MouseEvent).clientX ?? (e as TouchEvent).touches[0].clientX;
};
export const getClientYFromMouseOrTouchEvent = (
  e: MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent
) => {
  return (e as MouseEvent).clientY ?? (e as TouchEvent).touches[0].clientY;
};
