/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import {
  fromDesktopMd,
  fromTabletMd,
} from "../../base/styles/helpers/mediaQueries.styling";
import { useProps, useStore } from "../../base/utils/mobx.utils";

import { Composition } from "../../models/Composition.model";
import { ComposerAppUIMode } from "../composer/useCreateComposerInstance";
import CompositionEntry from "./CompositionEntry";

interface CompositionGalleryProps {
  compositions: Composition[];
  EmptyMessage?: React.ReactNode;
  mode?: ComposerAppUIMode;
  enableContextMenu?: boolean;
  columns?: number;
}

const style = {
  gallery: {} as CSSPartial,
};

const CompositionList: React.FC<CompositionGalleryProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get columns() {
      return p.compositions.length < 2 ? 1 : p.columns ?? 1;
    },
    get singleColumn() {
      return s.columns === 1;
    },
    get hasMoreThanOneColumns() {
      return s.columns > 1;
    },
  }));
  return (
    <Observer
      children={() => (
        <div className="CompositionList" css={style.gallery}>
          {p.compositions.length === 0 ? (
            p.EmptyMessage ?? <p>No compositions found.</p>
          ) : (
            <div
              css={css`
                display: grid;
                grid-gap: 1em;
                grid-template-columns: 1fr;
                align-items: stretch;
                ${fromTabletMd} {
                  grid-template-columns: repeat(${s.columns}, 1fr);
                  grid-gap: ${s.hasMoreThanOneColumns ? "2em" : "3em"};
                }
                ${fromDesktopMd} {
                  grid-gap: ${s.hasMoreThanOneColumns ? "3em" : "4em"};
                }
              `}
            >
              {p.compositions.map((comp, i, arr) => (
                <CompositionEntry
                  composition={comp}
                  mode={p.mode ?? "player"}
                  showPrimaryCollection
                  enableContextMenu={p.enableContextMenu}
                  key={comp._id}
                  titleFontSize={
                    p.columns && p.columns > 1 ? "2.2rem" : "2.4rem"
                  }
                />
              ))}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default CompositionList;
