/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import Button from "../../base/components/Button";
import WithRequest from "../../base/components/ContainerWithRequest";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import IconPlus18 from "../../base/icons/18/Plus.icon.18";
import { useStore } from "../../base/utils/mobx.utils";
import ArtistIndex from "../../components/directory/ArtistIndex";
import NewArtistPanel from "../../components/panels/NewArtistPanel";
import { varFontSerif } from "../../constants/cssCustomProperties.constants";
// import ArtistFinder from "../../components/Directory/ArtistFinder";
import WorkshopAreaPageTemplate from "./_WorkshopArea.pageTemplate";
import WorkshopAreaPageHeader from "./_WorkshopAreaPageHeader";

type WorkshopArtistIndexPageProps = {};

const WorkshopArtistIndexPage: React.FC<
  WorkshopArtistIndexPageProps
> = props => {
  const { ARTISTS } = useControllers();
  const s = useStore(() => ({
    shouldRenderNewArtistPanel: false,
    showNewArtistPanel: () => {
      s.shouldRenderNewArtistPanel = true;
    },
    closeNewArtistPanel: () => {
      s.shouldRenderNewArtistPanel = false;
    },
  }));

  return (
    <Observer
      children={() => (
        <WorkshopAreaPageTemplate title="Artists & Composers">
          <WorkshopAreaPageHeader
            EndSlot={
              <Button onClick={s.showNewArtistPanel} Icon={<IconPlus18 />}>
                New artist entry
              </Button>
            }
          >
            <h1>Artists</h1>
            {/* <h2 className="serif">
              Manage artists, composers, arrangers and more
            </h2> */}
          </WorkshopAreaPageHeader>
          <WithRequest
            id="workshopArtistIndex"
            request={ARTISTS.getAllOwn}
            children={() => (
              <>
                <ArtistIndex
                  artists={ARTISTS.ownNonArchived}
                  css={{ fontFamily: varFontSerif }}
                  linkTo="workshop"
                />
                {s.shouldRenderNewArtistPanel && (
                  <NewArtistPanel onShouldClose={s.closeNewArtistPanel} />
                )}
              </>
            )}
          />
        </WorkshopAreaPageTemplate>
      )}
    />
  );
};

export default WorkshopArtistIndexPage;
