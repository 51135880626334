import { flow, observable } from "mobx";
import { isDevelopment } from "../base/env";
import { APIControllerOptions, makeApiController } from "./api.controller";
import { makeArtistsController } from "./models/artists.controller";
import { makeAuthController } from "./auth.controller";
import { makeClipboardController } from "./clipboard.controller";
import { makeCollectionsController } from "./models/collections.controller";
import { makeCommonController } from "./common.controller";
import { makeComposerController } from "./composer.controller";
import { makeCompositionsController } from "./models/compositions.controller";
import { makeDialogsController } from "./dialogs.controller";
import { makeEnsembleController } from "./ensemble.controller";
import { makeInterpretationsController } from "./models/interpretations.controller";
import { makeKeyboardController } from "./keyboard.controller";
import { makeLocalDBController } from "./localDB.controller";
import { makeMidiController } from "./midi.controller";
import { makeNavigatorController } from "./navigator.controller";
import { makePortalsController } from "./portals.controller";
import { makeSettingsController } from "./settings.controller";
import {
  StorageControllerOptions,
  makeStorageController,
} from "./storage.controller";
import { makeSyncController } from "./sync.controller";
import { makeThemeController } from "./theme.controller";
import { makeUIController } from "./ui.controller";
import { Controller, RootController } from "./_controller.types";
import { makeScoresController } from "./models/scores.controller";
import { makeStatusController } from "./status.controller";
import { makeBucketController } from "./bucket.controller";
import { makeCommandPaletteController } from "./commandPalette.controller";
import { makeUsersController } from "./models/users.controller";
import { makeAdminController } from "./admin.controller";
import { makeNetworkController } from "./network.controller";
import { makeFileRecordsController } from "./models/fileRecords.controller";
import { makePackagesController } from "./models/packages.controller";
import { makeRenderJobsController } from "./models/renderJobs.controller";

export const makeRootController = (options: {
  api: APIControllerOptions;
  storage: StorageControllerOptions;
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const c: Required<RootController> = observable({
    name: "ROOT",
    get ROOT() {
      return c;
    },
    ready: false,
    init: () =>
      new Promise<true>(
        flow(function* (resolve, reject) {
          yield c.NETWORK.init(c);
          yield c.UI.init(c);
          yield c.STATUS.init(c);
          yield c.SETTINGS.init(c);
          yield c.THEME.init(c);
          yield c.NAVIGATOR.init(c);
          yield c.STORAGE.init(c);
          yield c.API.init(c);
          yield c.SYNC.init(c);
          yield c.LOCALDB.init(c);
          yield c.AUTH.init(c);
          yield c.BUCKET.init(c);
          yield c.COMMON.init(c);
          yield c.KEYBOARD.init(c);
          yield c.CLIPBOARD.init(c);
          yield c.DIALOGS.init(c);
          yield c.PORTALS.init(c);

          yield c.MIDI.init(c);
          yield c.ENSEMBLE.init(c);

          yield c.FILE_RECORDS.init(c);
          yield c.USERS.init(c);
          yield c.ARTISTS.init(c);
          yield c.COLLECTIONS.init(c);
          yield c.COMPOSITIONS.init(c);
          yield c.INTERPRETATIONS.init(c);
          yield c.SCORES.init(c);
          yield c.PACKAGES.init(c);
          yield c.RENDER_JOBS.init(c);

          yield c.COMPOSER.init(c);

          yield c.ADMIN.init(c);
          yield c.COMMAND_PALETTE.init(c);

          c.ready = true;

          resolve(true);
        })
      ),
    NETWORK: makeNetworkController(),
    UI: makeUIController(),
    STATUS: makeStatusController(),
    SETTINGS: makeSettingsController(),
    THEME: makeThemeController(),
    NAVIGATOR: makeNavigatorController(),
    STORAGE: makeStorageController(options.storage),
    API: makeApiController(options.api),
    LOCALDB: makeLocalDBController(),
    SYNC: makeSyncController(),
    AUTH: makeAuthController(),
    BUCKET: makeBucketController(),
    COMMON: makeCommonController(),
    KEYBOARD: makeKeyboardController(),
    CLIPBOARD: makeClipboardController(),
    DIALOGS: makeDialogsController(),
    PORTALS: makePortalsController(),
    MIDI: makeMidiController(),
    ENSEMBLE: makeEnsembleController(),
    FILE_RECORDS: makeFileRecordsController(),
    RENDER_JOBS: makeRenderJobsController(),
    USERS: makeUsersController(),
    ARTISTS: makeArtistsController(),
    COLLECTIONS: makeCollectionsController(),
    COMPOSITIONS: makeCompositionsController(),
    INTERPRETATIONS: makeInterpretationsController(),
    SCORES: makeScoresController(),
    PACKAGES: makePackagesController(),
    COMPOSER: makeComposerController(),
    ADMIN: makeAdminController(),
    COMMAND_PALETTE: makeCommandPaletteController(),
  });

  if (isDevelopment) Reflect.set(window, "ROOT", c);

  return c;
};

export const makeControllerBase = <T extends string = string>(name: T) => ({
  ROOT: undefined as RootController | undefined,
  name,
  ready: false,
  init: (R?: RootController) => new Promise<true>(resolve => resolve(true)),
});

export const makeRootControllerChildInitFn = (
  controller: UnknownObject,
  fn: () => void,
  onError?: (e: Error | unknown) => void
) => {
  return (ROOT?: RootController) =>
    new Promise<true>((resolve, reject) => {
      try {
        controller.ROOT = ROOT;
        fn && fn();
        controller.ready = true;
        if (isDevelopment)
          Reflect.set(window, (controller as Controller).name, controller);
        resolve(true);
      } catch (e) {
        reject(e);
        onError && onError(e);
      }
    });
};
