import { Instrument, InstrumentRange } from "../@types";
import { inRangeInclusive } from "../base/utils/math.utils";
import { isArray } from "../base/utils/typeChecks.utils";
import resolveAfter, { runUntilTruthy } from "../base/utils/waiters.utils";
import { WorkspaceController } from "../controllers/composer/workspace.controller";
import { IS_EMBEDDED } from "../env";
import { getMidiNumberFromMusicKeyName } from "./musicKey.utils";

export const noteIsInRange = (
  which: string | number,
  range: InstrumentRange
) => {
  const number = getMidiNumberFromMusicKeyName(which);
  if (!number) return false;
  return range.some(n =>
    isArray(n) ? inRangeInclusive(number, ...n) : n === number
  );
};

export const openInstrumentManager = async (
  ws: WorkspaceController,
  ins: Instrument
) => {
  ws.switchTo("instruments");
  const getInstrumentManagerHeader = () =>
    document.querySelector<HTMLDivElement>(
      `[data-instrument-manager-id="${ins._id}"] header`
    );
  await runUntilTruthy(() => !!getInstrumentManagerHeader(), { timeout: 1000 });
  getInstrumentManagerHeader()?.click();
  await resolveAfter();
  if (IS_EMBEDDED) return;
  const manager = document.querySelector<HTMLDivElement>(
    `[data-instrument-manager-id="${ins._id}"]`
  );
  manager?.scrollIntoView();
};
