import { Atom, AtomContext, Keyframe, SpeedScalarKeyframe } from "../@types";
import { first } from "../base/utils/ramdaEquivalents.utils";
import { KnownKeyframeControlPath } from "../constants/keyframe.constants";
import { MusicKey } from "../constants/musicKeys.constants";
import { MusicScaleName } from "../constants/musicScales.constants";

export const filterKeyframeByControlPath = (
  keyframes: Keyframe[],
  controlPath: KnownKeyframeControlPath
) => {
  return keyframes.filter(k => k.controlPath === controlPath);
};

export const isSpeedScalarKeyframe = (
  keyframe: Atom
): keyframe is SpeedScalarKeyframe =>
  (keyframe as Keyframe).controlPath === KnownKeyframeControlPath.speedScalar;

export const getKeyframeControlPathDefaultValue = (
  path: KnownKeyframeControlPath | string | null,
  atomContext: AtomContext
) => {
  const referenceKeyframe =
    atomContext.lastEditedKeyframe ??
    first(atomContext.keyframes) ??
    first(atomContext.notes);
  switch (path) {
    case KnownKeyframeControlPath.sustainPedal:
      // no need to assign value. the presence of this keyframe means the penal is down.
      return 1;
    case KnownKeyframeControlPath.speedScalar:
      return 0.9;
    case KnownKeyframeControlPath.musicScaleChange:
      return referenceKeyframe?.musicScaleName ?? MusicScaleName.Ionian;
    case KnownKeyframeControlPath.musicKeyChange:
      return referenceKeyframe?.musicKey ?? MusicKey.C;
    case KnownKeyframeControlPath.bpmChange:
      return referenceKeyframe?.bpm ?? 60;
    default:
      return "";
  }
};
