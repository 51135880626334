import { css } from "@emotion/react";
import { action, reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import TextInput from "../../base/components/TextInput";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { runAfter } from "../../base/utils/waiters.utils";
import { varFontMono } from "../../constants/cssCustomProperties.constants";
import { RuleController } from "../../logic/interpreterRule.controller";
import { useComposer } from "./ComposerApp.context";

export const RuleSelectorEditor = (props: {
  rule: RuleController;
  disabled?: boolean;
}) => {
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    innerValue: p.rule.selector,
    applyValue: () => {
      I.runInHistory(
        "Edit rule selector",
        action(() => {
          p.rule.selector = s.innerValue;
          // asynchronous mobx reactions will happen here. the resulting selector might change if the new value contains non-existent IDs which will be automatically cleaned up.
          runAfter(s.sync);
        }),
        {
          mergeableId: `edit-rule-selector-${p.rule._id}`,
        }
      );
    },
    sync: () => {
      if (s.innerValue !== p.rule.selector) s.innerValue = p.rule.selector;
    },
  }));
  useOnMount(() => reaction(() => p.rule.selector, s.sync));
  return (
    <Observer
      children={() => (
        <TextInput
          css={css`
            font-family: ${varFontMono};
          `}
          form={s}
          field="innerValue"
          onBlur={s.applyValue}
          disabled={p.disabled}
        />
      )}
    />
  );
};
