import { CSSPartial } from "../../@types/css.types";

export const preFlex = {
  simpleFlex: {
    display: "flex",
    "> *": {
      "+ *": {
        marginLeft: "1em",
      },
    },
  } as CSSPartial,
  simpleFlexAlignCenter: {
    display: "flex",
    alignItems: "center",
    "> *": {
      "+ *": {
        marginLeft: "1em",
      },
    },
  } as CSSPartial,
  simpleInlineFlexAlignCenter: {
    display: "inline-flex",
    alignItems: "center",
    "> *:not(hr)": {
      "+ *": {
        marginLeft: "1em",
      },
    },
    "> hr": {
      "+ *": {
        marginLeft: ".5em",
      },
    },
  } as CSSPartial,
  simpleInlineFlexAlignBaseline: {
    display: "inline-flex",
    alignItems: "baseline",
    "> *": {
      "+ *": {
        marginLeft: "1em",
      },
    },
  } as CSSPartial,
  simpleInlineFlexAlignCenterWithSmallerGap: {
    display: "inline-flex",
    alignItems: "center",
    "> *": {
      "+ *": {
        marginLeft: ".375em",
      },
    },
  } as CSSPartial,
  simpleInlineFlexAlignBaselineWithSmallerGap: {
    display: "inline-flex",
    alignItems: "center",
    "> *": {
      "+ *": {
        marginLeft: ".375em",
      },
    },
  } as CSSPartial,
};
