import Axios, { AxiosRequestConfig } from "axios";
import { action, observable, runInAction } from "mobx";
import { AUTH_TOKEN_KEY } from "../base/constants/storageKeys.constants";
import { isStandardModel } from "../base/utils/models.utils";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { isObject } from "../base/utils/typeChecks.utils";
import {
  ModelAPIName,
  ModelName,
  getModelNameFromAPIName,
} from "../constants/modelName.constants";
import { API_HOST } from "../env";
import { StandardModel } from "../models/StandardModel";
import { ControllerBase } from "./_controller.types";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";

export type APIControllerOptions = {
  baseURL?: string;
};

export type IncludablesSimple =
  | "compositions"
  | "interpretations"
  | "scores"
  | "artists"
  | "arrangers"
  | "collections"
  | "authors"
  | "thumbnailDark"
  | "thumbnailLight"
  | "owner";

export type IncludablesRecursive =
  | `${IncludablesSimple}.${IncludablesSimple}`
  | `${IncludablesSimple}.${IncludablesSimple}.${IncludablesSimple}`;

export type Includables = IncludablesSimple | IncludablesRecursive;

export type GetRequestOptions = {
  getFromLocalById?: string;
  select?: string;
  includes?: Includables[];
  queries?: Record<string, unknown>;
  params?: Record<string, unknown>;
};

export type IncludeDoc<I extends StandardModel = StandardModel> = {
  type: ModelAPIName;
  data: I["$"];
}[];

export type DataWithIncludes<
  T extends StandardModel = StandardModel,
  I extends StandardModel = StandardModel
> = {
  data: T["$"];
  includes?: IncludeDoc<I>;
};
export type DataArrayWithIncludes<
  T extends StandardModel = StandardModel,
  I extends StandardModel = StandardModel
> = {
  data: T["$"][];
  includes?: IncludeDoc<I>;
};

type ApiInfo = {
  world: string;
  dev: string;
  time: Date;
};

export type ApiController = ControllerBase & {
  readonly: boolean;

  info: ApiInfo;
  infoLastRetrievedAt: Date | null;
  getInfo: () => Promise<void>;

  lastToken: string | null;
  setLastToken: (t: string | null) => void;

  get: <T extends StandardModel = StandardModel>(
    url: string,
    modelName: ModelName,
    options?: GetRequestOptions
  ) => Promise<T | null>;

  getRaw: <T>(
    url: string,
    options?: {
      headers?: Record<string, string>;
      params?: Record<string, unknown>;
    }
  ) => Promise<T | null>;

  getMany: <T extends StandardModel = StandardModel>(
    url: string,
    modelName: ModelName,
    options?: GetRequestOptions
  ) => Promise<T[]>;

  post: <T extends StandardModel = StandardModel, P = T | T["$"]>(
    url: string,
    modelName: ModelName,
    payload?: P
  ) => Promise<T>;

  postRaw: <T, P = unknown>(
    url: string,
    body?: P,
    options?: {
      headers?: Record<string, string>;
      explicitlyAllowInReadonlyMode?: boolean;
    }
  ) => Promise<T>;

  patch: <T extends StandardModel = StandardModel, P = T | T["$"]>(
    url: string,
    modelName: ModelName,
    payload: P
  ) => Promise<T>;

  patchRaw: <T, P = unknown>(
    url: string,
    body?: P,
    options?: { headers?: Record<string, string> }
  ) => Promise<T>;

  put: <T extends StandardModel = StandardModel>(
    url: string,
    modelName: ModelName,
    payload: T | T["$"]
  ) => Promise<T>;

  putRaw: <T, P = unknown>(
    url: string,
    body?: P,
    options?: { headers?: Record<string, string> }
  ) => Promise<T>;

  delete: <T = unknown>(url: string, model?: T) => Promise<void>;

  deleteRaw: <T = void, P = unknown>(
    url: string,
    options?: { headers?: Record<string, string> }
  ) => Promise<T>;

  reset: () => void;
};

const makeGetRequestParams = (options?: GetRequestOptions) => ({
  select: options?.select,
  includes: options?.includes,
  queries: options?.queries,
  ...options?.params,
});

export const makeApiController = (options?: APIControllerOptions) => {
  const axios = Axios.create({
    baseURL: `${options?.baseURL || API_HOST}/api/v0`,
    timeout: 20000, // time out a request if it's longer than ten seconds
  });

  const makeAuthHeaderPartial = () => {
    const token = c.ROOT?.STORAGE.get<string>(AUTH_TOKEN_KEY);
    if (!token) return false;
    if (!c.lastToken) {
      c.lastToken = token;
    } else {
      if (token !== c.lastToken) {
        c.ROOT?.AUTH.logout();
        throw Error("Your session token had expired. Please log in again.");
      }
    }
    return {
      "x-access-token": token,
    };
  };

  const makeHeaders = (): AxiosRequestConfig["headers"] => {
    const authHeaderPartial = makeAuthHeaderPartial();
    const baseHeaderPartial = {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      // 'Access-Control-Allow-Origin': '*',
    };
    if (authHeaderPartial) {
      return {
        ...authHeaderPartial,
        ...baseHeaderPartial,
      };
    } else {
      return baseHeaderPartial;
    }
  };

  const processBeforeReturn = <T extends StandardModel = StandardModel>(
    snapshot: T["$"],
    modelName: T["_name"]
  ) => {
    return runInAction(() => {
      if (!isObject(snapshot)) {
        throw Error(
          `Snapshot "${JSON.stringify(
            snapshot
          )}" is not an object, please use API methods suffixed with "raw" to retrieve it.`
        );
      }
      const { LOCALDB } = c.ROOT!;
      if (!snapshot._id) {
        throw Error(
          `Snapshot ${modelName}#${snapshot._id} does not have a local constructor, you should not retrieve it with API.`
        );
      }
      return LOCALDB.setOrMerge<T>(modelName, snapshot);
    });
  };

  const processIncludedData = <I extends StandardModel = StandardModel>(
    includes: IncludeDoc<I>
  ) => {
    includes.forEach(inc => {
      processBeforeReturn<I>(inc.data, getModelNameFromAPIName(inc.type));
    });
  };

  const c: ApiController = observable({
    ...makeControllerBase("API"),

    get readonly() {
      return (
        !c.ROOT ||
        !c.ROOT.AUTH.isAuthenticated ||
        (c.ROOT.COMPOSER.instance
          ? c.ROOT.COMPOSER.instance.readonly ||
            c.ROOT.COMPOSER.instance.isDemoMode
          : false)
      );
    },

    info: {
      world: "",
      dev: "",
      time: new Date(),
    },
    infoLastRetrievedAt: null,
    getInfo: async () => {
      const info = await c.getRaw<ApiInfo>("/info");
      runInAction(() => {
        Object.assign(c.info, info);
      });
    },

    lastToken: null as string | null,
    setLastToken: (token: string | null) => (c.lastToken = token),

    get: async <
      T extends StandardModel = StandardModel,
      I extends StandardModel = StandardModel
    >(
      url: string,
      modelName: ModelName,
      options?: GetRequestOptions
    ) => {
      if (modelName && options?.getFromLocalById) {
        const { LOCALDB } = c.ROOT!;
        const entry = LOCALDB.get(modelName, options.getFromLocalById);
        if (entry) return entry as unknown as T;
      }
      const { data } = await axios.get<DataWithIncludes<T>>(url, {
        headers: makeHeaders(),
        params: makeGetRequestParams(options),
      });
      if (!data) return null;
      Reflect.deleteProperty(data, "nodeSnapshots");
      if (data.includes) processIncludedData(data.includes);
      return processBeforeReturn<T>(data.data, modelName);
    },
    getRaw: async <T = unknown>(
      url: string,
      options?: {
        headers?: Record<string, string>;
        params?: Record<string, unknown>;
      }
    ) => {
      const { data } = await axios.get(url, {
        headers: { ...makeHeaders(), ...options?.headers },
        params: options?.params,
      });
      if (!data) return null;
      return data as T;
    },
    getMany: async <
      T extends StandardModel = StandardModel,
      I extends StandardModel = StandardModel
    >(
      url: string,
      modelName: ModelName,
      options?: GetRequestOptions
    ) => {
      const { LOCALDB } = c.ROOT!;
      const data: DataArrayWithIncludes<T> = (
        await axios.get(url, {
          headers: makeHeaders(),
          params: makeGetRequestParams(options),
        })
      ).data;
      if (data.includes) processIncludedData(data.includes);
      if (!data.data) return [];
      const entries = data.data;
      const result = LOCALDB.setOrMergeMany<T>(modelName, entries);
      return result || entries;
    },
    post: async <T extends StandardModel = StandardModel, P = T | T["$"]>(
      url: string,
      modelName: ModelName,
      payload?: P
    ) => {
      if (c.readonly) {
        throw Error(`API.post called in readonly mode with URL ${url}`);
      }
      const _payload = payload ? getSnapshot(payload) : payload;
      if (_payload) Reflect.deleteProperty(_payload, "_id");
      const { data } = await axios.post<DataWithIncludes<T>>(url, _payload, {
        headers: makeHeaders(),
      });
      if (!data.data)
        throw Error(
          `Expected post request to receive data from server. Received ${data} instead.`
        );
      if (data.includes) processIncludedData(data.includes);
      return processBeforeReturn<T>(data.data, modelName);
    },
    postRaw: async <T, P = unknown>(
      url: string,
      body?: P,
      options?: {
        headers?: Record<string, string>;
        explicitlyAllowInReadonlyMode?: boolean;
      }
    ) => {
      if (
        c.ROOT?.COMPOSER.instance?.readonly &&
        !options?.explicitlyAllowInReadonlyMode
      ) {
        throw Error(`API.postRaw called in readonly mode with URL ${url}`);
      }
      const { data } = await axios.post<T>(url, body, {
        headers: {
          ...makeHeaders(),
          ...options?.headers,
        },
      });
      if (!data)
        throw Error(
          `Expected post request to receive data from server. Received ${data} instead.`
        );
      return data;
    },
    patch: async <T extends StandardModel = StandardModel, P = T | T["$"]>(
      url: string,
      modelName: ModelName,
      payload: P
    ) => {
      if (c.readonly) {
        throw Error(`API.patch called in readonly mode with URL ${url}`);
      }
      const _payload = payload ? getSnapshot(payload) : payload;
      const { data } = await axios.patch<DataWithIncludes<T>>(url, _payload, {
        headers: makeHeaders(),
      });
      if (!data.data)
        throw Error(
          `Expected patch request to receive data from server. Received ${data} instead.`
        );
      if (data.includes) processIncludedData(data.includes);
      return processBeforeReturn<T>(data.data, modelName);
    },
    patchRaw: async <T, P = unknown>(
      url: string,
      body?: P,
      options?: {
        headers?: Record<string, string>;
      }
    ) => {
      if (c.readonly) {
        throw Error(`API.patchRaw called in readonly mode with URL ${url}`);
      }
      const response = await axios.patch<T>(url, body, {
        headers: {
          ...makeHeaders(),
          ...options?.headers,
        },
      });
      return response.data;
    },
    put: async <T extends StandardModel = StandardModel>(
      url: string,
      modelName: ModelName,
      payload: T | T["$"]
    ) => {
      if (c.readonly) {
        throw Error(`API.put called in readonly mode with URL ${url}`);
      }
      const _payload = payload ? getSnapshot(payload) : payload;
      const { data } = await axios.put<DataWithIncludes<T>>(url, _payload, {
        headers: makeHeaders(),
      });
      if (data.includes) processIncludedData(data.includes);
      return processBeforeReturn<T>(data.data, modelName);
    },
    putRaw: async <T, P = unknown>(
      url: string,
      body?: P,
      options?: {
        headers?: Record<string, string>;
      }
    ) => {
      if (c.readonly) {
        throw Error(`API.putRaw called in readonly mode with URL ${url}`);
      }
      const response = await axios.put<T>(url, body, {
        headers: { ...makeHeaders(), ...options?.headers },
      });
      return response.data;
    },
    delete: async <T = unknown>(url: string, model?: T) => {
      if (c.readonly) {
        throw Error(`API.delete called in readonly mode with URL ${url}`);
      }
      try {
        await axios.delete(url, {
          headers: makeHeaders(),
        });
        if (model && isStandardModel(model as UnknownObject)) {
          c.ROOT?.LOCALDB.remove(model as unknown as StandardModel);
        }
      } catch (e) {
        throw e;
      }
    },
    deleteRaw: async <T = void>(
      url: string,
      options?: {
        headers?: Record<string, string>;
      }
    ) => {
      if (c.readonly) {
        throw Error(`API.deleteRaw called in readonly mode with URL ${url}`);
      }
      const response = await axios.delete<T>(url, {
        headers: { ...makeHeaders(), ...options?.headers },
      });
      return response.data;
    },
    reset: () => {
      c.setLastToken(null);
    },
  });

  c.init = makeRootControllerChildInitFn(
    c,
    action(() => {
      c.ready = true;
    })
  );

  return c;
};
