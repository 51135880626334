/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import Button from "../../base/components/Button";
import FormLabel from "../../base/components/FormLabel";
import Panel, {
  PanelHeader,
  PanelInner,
  PanelLayoutWithSidebar,
  PanelSidebar,
} from "../../base/components/Panel";
import Spacing from "../../base/components/Spacing";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { grid6col } from "../../base/styles/partials/grids.stylePartials";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { makeSnapshot } from "../../base/utils/snapshot.utils";
import { varFontSerif } from "../../constants/cssCustomProperties.constants";
import { MusicKey } from "../../constants/musicKeys.constants";
import { MusicScaleName } from "../../constants/musicScales.constants";
import { makeTimeSignature44 } from "../../constants/timeSignatures.constants";
import {
  Composition,
  CompositionSnapshotFactory,
  makeCompositionOptions,
} from "../../models/Composition.model";
import KeyboardInstrumentSelector from "../composer/KeyboardInstrumentSelector";
import MusicKeySelector from "../composer/MusicKeySelector";
import MusicScaleSelector from "../composer/MusicScaleSelector";
import TimeSignatureEditor from "../composer/TimeSignatureEditor";

type NewCompositionPanelProps = {
  onShouldClose?: (newComposition?: Composition) => void;
};

const headingStyle = css`
  font-size: 2.6rem;
  font-weight: 100;
  line-height: 1;
  font-family: ${varFontSerif};
`;

const NewCompositionPanel: React.FC<NewCompositionPanelProps> = props => {
  const { COMPOSITIONS, THEME } = useControllers();
  const navigate = useNavigate();
  const p = useProps(props);
  const s = useStore(() => ({
    snapshot: makeSnapshot(CompositionSnapshotFactory, {
      options: {
        ...makeCompositionOptions(),
        musicKey: MusicKey.C,
        musicScaleName: MusicScaleName.Ionian,
        timeSignature: makeTimeSignature44(),
      },
    }),
    createComposition: async () =>
      await flow(function* () {
        try {
          const newComp: Composition = yield COMPOSITIONS.create(s.snapshot);
          p.onShouldClose?.(newComp);
          navigate(`/compose/${newComp._id}`);
        } catch (e) {
          console.error(e);
        }
      })(),
  }));
  return (
    <Observer
      children={() => (
        <Panel
          id="new-composition"
          title="New composition"
          fullscreen
          canEscape
          onShouldClose={p.onShouldClose}
          width={820}
          panelPadding="2.4rem"
        >
          <PanelLayoutWithSidebar
            sidebarWidth={250}
            Sidebar={
              <PanelSidebar>
                <PanelHeader>
                  <h2 css={headingStyle}>
                    New
                    <br />
                    composition
                  </h2>
                </PanelHeader>
                <PanelInner
                  css={css`
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-between;
                    align-items: flex-start;
                  `}
                >
                  <p>
                    Set up your new composition. You can always change those
                    settings later.
                  </p>
                  <Spacing size="2em" />
                  {p.onShouldClose && (
                    <Button onClick={() => p.onShouldClose?.()}>Cancel</Button>
                  )}
                </PanelInner>
              </PanelSidebar>
            }
          >
            <PanelInner>
              <div css={grid6col.grid}>
                <TextInput
                  form={s.snapshot}
                  field="title"
                  Label="Title"
                  placeholder="Enter a title"
                  minHeight="2.5em"
                  height="2.5em"
                  css={css`
                    ${grid6col.full}
                    font-size: 2rem;
                    input {
                      font-family: ${varFontSerif};
                      padding-top: 0;
                      padding-bottom: 0;
                      font-weight: 300;
                    }
                    margin-bottom: 0.5em;
                  `}
                  autoFocus
                  onEscape={() => p.onShouldClose?.()}
                  onEnter={s.createComposition}
                />
                <MusicKeySelector
                  form={s.snapshot.options}
                  field="musicKey"
                  Label="Key"
                  css={grid6col.third}
                />
                <MusicScaleSelector
                  form={s.snapshot.options}
                  field="musicScaleName"
                  Label="Scale"
                  css={grid6col.third}
                />
                <TimeSignatureEditor
                  form={s.snapshot.options}
                  field="timeSignature"
                  Label="Time signature"
                  css={grid6col.third}
                />
                <div css={grid6col.full}>
                  <Spacing size=".5em" />
                  <FormLabel bold>Default instrument:</FormLabel>
                  <Spacing size=".5em" />
                  <KeyboardInstrumentSelector
                    css={grid6col.full}
                    form={s.snapshot.options}
                    field="defaultInstrumentName"
                  />
                </div>
              </div>
              <Spacing />
              <Button
                fullWidth
                onClick={s.createComposition}
                icon="check"
                color={THEME.primary}
                minHeight="3.75em"
              >
                Confirm
              </Button>
            </PanelInner>
          </PanelLayoutWithSidebar>
        </Panel>
      )}
    />
  );
};

export default NewCompositionPanel;
