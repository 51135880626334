import { observable } from "mobx";
import { Atom } from "../@types";
import { HexOrContextColorName } from "../base/@types";
import { isColorHexCode } from "../base/utils/colors.utils";
import {
  AppearanceSnapshot,
  HasAppearance,
  getColorFromColorDefObjectInContext,
  getHighlightColorFromAppearanceInContext,
} from "../traits/hasAppearance.trait";
import { first, isString } from "lodash-es";
import { isNoteAtom } from "../utils/atoms.utils";

const getKey = (key: keyof AppearanceSnapshot, obj?: HasAppearance | null) => {
  return obj?.appearance?.[key];
};
const recursivelyGetAppearanceKey = <T>(
  key: keyof AppearanceSnapshot,
  n: Atom
) => {
  return (getKey(key, n.rulePropertiesFlattened ?? {}) ||
    getKey(key, first(n.parents)) ||
    getKey(key, n.voice)) as T | undefined;
};

const recursivelyGetKeyWithNumericValue = <T>(
  key: keyof AppearanceSnapshot,
  n: Atom
) => {
  return (getKey(key, n.rulePropertiesFlattened) ??
    getKey(key, first(n.parents)) ??
    getKey(key, n.voice)) as T | undefined;
};

export const getAtomColorInContext = (
  A: Atom,
  type: "color" | "highlight" = "color"
) => {
  const fn =
    type === "color"
      ? getColorFromColorDefObjectInContext
      : getHighlightColorFromAppearanceInContext;
  let ownColor = fn(A.context, A.rulePropertiesFlattened.appearance);
  if (isNoteAtom(A)) {
    const THEME = A.context?.ROOT?.THEME;
    const { colorNotesByPitch } = THEME?.theme.options ?? {};
    ownColor =
      colorNotesByPitch && THEME
        ? THEME.themeVariant.octave[A.indexInOctaveFromC ?? 0]
        : ownColor;
  }
  if (ownColor) return ownColor;
  const recursive = recursivelyGetAppearanceKey(type ?? "color", A);
  if (recursive)
    return isString(recursive) ? recursive : fn(A.context, recursive);
  return null;
};

const setAppearanceKey = (
  A: Atom,
  key: keyof AppearanceSnapshot,
  value: unknown
) => {
  let rule = A.lastOwnRule;
  if (!rule) rule = A.interpreter?.findOrCreateRuleForAtom(A) ?? null;
  if (!rule) return;
  if (!rule.$.properties.appearance) rule.$.properties.appearance = {};
  Reflect.set(rule.$.properties.appearance, key, value);
};

export const makeAtomAppearanceController = (A: Atom) => {
  const a: AtomAppearanceController = observable({
    get color() {
      return recursivelyGetAppearanceKey("color", A) ?? null;
    },
    set color(v: string | null) {
      setAppearanceKey(A, "color", v);
    },
    get highlight() {
      return recursivelyGetAppearanceKey("highlight", A) ?? null;
    },
    set highlight(v: string | null) {
      setAppearanceKey(A, "highlight", v);
    },
    get colorInContext() {
      return getAtomColorInContext(A);
    },
    set colorInContext(v: HexOrContextColorName | null) {
      if (v === null || isColorHexCode(v)) {
        setAppearanceKey(A, "color", v);
      }
    },
    get highlightColorInContext() {
      return getAtomColorInContext(A, "highlight");
    },
    set highlightColorInContext(v: HexOrContextColorName | null) {
      if (v === null || isColorHexCode(v)) {
        setAppearanceKey(A, "highlight", v);
      }
    },
    get noteStyleType() {
      return recursivelyGetAppearanceKey("noteStyleType", A) ?? null;
    },
    set noteStyleType(v: string | null) {
      setAppearanceKey(A, "noteStyleType", v);
    },
    get noteYScalar() {
      return recursivelyGetKeyWithNumericValue("noteYScalar", A) || null;
    },
    set noteYScalar(v: number | null) {
      setAppearanceKey(A, "noteYScalar", v);
    },
    get noteRoundedness() {
      return recursivelyGetKeyWithNumericValue("noteRoundedness", A) || null;
    },
    set noteRoundedness(v: number | null) {
      setAppearanceKey(A, "noteRoundedness", v);
    },
  });
  return a;
};

export type AtomAppearanceController = Required<AppearanceSnapshot> & {
  colorInContext: string | null;
  highlightColorInContext: string | null;
};
