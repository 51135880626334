/** @jsxImportSource @emotion/react */
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import Button from "../../base/components/Button";
import Panel, {
  PanelCloseButton,
  PanelFooter,
  PanelHeader,
  PanelInner,
} from "../../base/components/Panel";
import Serif from "../../base/components/Serif";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { addManyToArrayIfNew } from "../../base/utils/array.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Collection } from "../../models/Collection.model";
import { Composition } from "../../models/Composition.model";

import CompositionFinder from "../directory/CompositionFinder";

type CollectionAddContentPanelProps = {
  collection: Collection;
  onShouldClose?: () => void;
};

const CollectionAddContentPanel: React.FC<
  CollectionAddContentPanelProps
> = props => {
  const p = useProps(props);
  const { COLLECTIONS, COMPOSITIONS } = useControllers();
  const s = useStore(() => ({
    source: "",
    sourceSetter: (v: string) => action(() => (s.source = v)),
    get width() {
      return 400;
    },
    get sourcePicker() {
      return (
        <div
          css={{
            display: "grid",
            gridAutoRows: "1fr",
            gridGap: ".5em",
          }}
        >
          {/* <Button
            Icon={<CompositionListIcon27 />}
            Label={<>Existing composition(s)</>}
            onClick={s.sourceSetter("existing-compositions")}
            minHeight="5em"
            padding="1em 1.5em"
            gap="1em"
            align="start"
          /> */}
          {/* <Button
            Icon={<IconNewComposition27 />}
            onClick={s.createNewCompInThisCollection}
            Label={<>New composition</>}
            minHeight="5em"
            padding="1em 1.5em"
            gap="1em"
            align="start"
          /> */}
          {/* <LargeButton
                Icon={<IconCollectionList27 />}
                Label="Existing collection(s)"
              />
              <LargeButton
                Icon={<IconNewCollection27 />}
                Label={
                  <>
                    New
                    <br />
                    sub-collection
                  </>
                }
              /> */}
        </div>
      );
    },
    selectedCompositions: new Set<Composition>(),
    get itemPicker() {
      switch (s.source) {
        case "existing-compositions": {
          return (
            <CompositionFinder
              onConfirm={s.addCompositionsToCollection}
              selectionSet={s.selectedCompositions}
            />
          );
        }
      }
      return null;
    },
    addCompositionsToCollection: () => {
      const ids = Array.from(s.selectedCompositions).map(c => c._id);
      addManyToArrayIfNew(p.collection.$.compositionIds, ids);
      COLLECTIONS.save(p.collection);
      p.onShouldClose?.();
    },
    createNewCompInThisCollection: () => {},
    addSelectionToCollection: () => {
      if (s.source === "existing-compositions") {
        s.addCompositionsToCollection();
      }
    },
    get footer() {
      return (
        <PanelFooter sticky css={{ textAlign: "right" }}>
          <Button onClick={s.addSelectionToCollection}>
            Add to collection
          </Button>
        </PanelFooter>
      );
    },
  }));

  useOnMount(() => {
    s.sourceSetter("existing-compositions")();
  });

  return (
    <Observer
      children={() => (
        <Panel
          id="collection-add-item"
          title="Add content to collection"
          fullscreen
          canEscape
          onShouldClose={p.onShouldClose}
          width={s.width}
        >
          <PanelHeader End={<PanelCloseButton onClick={p.onShouldClose} />}>
            <h2>
              <Serif>Add to this collection</Serif>
            </h2>
          </PanelHeader>
          <PanelInner>{s.source ? s.itemPicker : s.sourcePicker}</PanelInner>
          {s.source && s.footer}
        </Panel>
      )}
    />
  );
};

export default CollectionAddContentPanel;
