import { toJS } from "mobx";
import { NoteSnapshot } from "../../@types";
import {
  OrnamentationDef,
  OrnamentationType,
} from "../../constants/ornaments.constants";
import { getNoteSnapshotAfterAddingOrSubtractingStepsInScale } from "../../utils/musicScale.utils";

export const Ornamentation_GraceNoteDef: OrnamentationDef = {
  identifier: OrnamentationType.appoggiatura,
  displayName: "Appoggiatura",
  defaultSymbol: "",
  symbolFactory: orn => "",
  generator: (note, options) => {
    if (!note.width) return [];
    const ornamentNoteWidth = options?.ornamentNoteWidth ?? note.width / 2;
    const graceNoteSnapshot = {
      ...getNoteSnapshotAfterAddingOrSubtractingStepsInScale(
        note,
        options?.startFromLower ? -1 : 1
      ),
      width: ornamentNoteWidth,
    };
    const shortenedNoteSnapshot: NoteSnapshot = {
      ...toJS(note.$),
      x: note.x! + ornamentNoteWidth,
      width: note.width - ornamentNoteWidth,
      velocity: note.velocity,
    };
    return [graceNoteSnapshot, shortenedNoteSnapshot];
  },
};
