import { makeIconDef } from "./makeIconDef";

export const RewindIconDef = {
  rewind: makeIconDef("rewind", {
    regular: () => (
      <path
        d="M5 18L4 18L4 7L5 7L5 18ZM21 17L14 12.5L21 8L21 17ZM6 12.5L13 17L13 8L6 12.5Z"
        fill="currentColor"
      />
    ),
  }),
};
