/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import { lighten } from "polished";
import * as React from "react";
import { Voice } from "../../../@types";
import { useAtomContext } from "../../../components/composer/ComposerApp.context";
import InterpretationMark from "../../../components/shared/InterpretationMark";
import PlayStateHighlighter from "../../../components/shared/PlayStateHighlighter";
import { CSSPartial } from "../../@types/css.types";
import { UNITS } from "../../constants/units.constant";
import { useControllers } from "../../hooks/rootContext.hooks";
import cx from "../../utils/className.utils";
import { withOpacity } from "../../utils/colors.utils";
import { renderRenderable } from "../../utils/components.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import SymbolIcon from "../SymbolIcon";
import { SelectorOptionRendererProps } from "./Selector";

const SelectOptionVoice = <T extends UnknownObject = UnknownObject>(
  props: React.PropsWithChildren<SelectorOptionRendererProps<T>>
) => {
  const p = useProps(props);
  const context = useAtomContext();

  const { THEME, SETTINGS } = useControllers();

  const s = useStore(() => ({
    handleClick: () => {
      p.onClick && p.onClick(p.option);
    },
    get voice() {
      return context.getAtomById<Voice>(p.option.value);
    },
    get color() {
      return p.option.color ?? THEME.fg;
    },
    get contrastColor(): string {
      return THEME.getContrastColor(s.color);
    },
    get style(): CSSPartial {
      return {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: p.fullWidth ? "1 0 auto" : undefined,
        position: "relative",
        padding: ".5em 1em",
        minHeight: "2.5em",
        borderRadius: 0,
        border: `${UNITS.lineWidth}px solid ${withOpacity(THEME.bg, 0.5)}`,
        borderLeftWidth: 2,
        backgroundColor: withOpacity(s.color, 0.1),
        color: "inherit",
        cursor: "default",
        textAlign: "center",
        "&:hover": {
          filter: "brightness(1.1)",
        },
        "&.selected": {
          border: `${UNITS.lineWidth}px solid ${lighten(0.1, s.color)}`,
          backgroundColor: withOpacity(s.color, 0.6),
          color: s.contrastColor,
        },
        ".SelectOptionVoice__label": {
          position: "relative",
          zIndex: 2,
          fontWeight: 500,
        },
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <span
          className={cx("SelectOptionVoice", p.isSelected && "selected")}
          onClick={s.handleClick}
          css={s.style}
          data-value={p.option.value}
        >
          {s.voice?._itpId && (
            <InterpretationMark
              color={s.voice.appearance?.colorInContext}
              size={6}
            />
          )}
          {p.option.icon && <SymbolIcon icon={p.option.icon} />}
          {p.isSelected && (
            <PlayStateHighlighter
              respondToVoiceId={p.option.value}
              gradientOpacityMap={[0.8, 0.4]}
              baseOpacityWhenOn={0.8}
              baseOpacityWhenOff={0.2}
            />
          )}
          <span className="SelectOptionVoice__label">
            {renderRenderable(p.option.Label ?? p.option.value)}
          </span>
        </span>
      )}
    />
  );
};

export default SelectOptionVoice;
