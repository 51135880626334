import { makeIconDef } from "./makeIconDef";

export const ArrowIconDef = {
  arrow: makeIconDef("arrow", {
    regular: () => (
      <path
        d="M14.8536 6.14645L20.8536 12.1465L21.2071 12.5L20.8536 12.8536L14.8536 18.8536L14.1464 18.1465L19.2929 13H4V12H19.2929L14.1464 6.85356L14.8536 6.14645Z"
        fill="currentColor"
      />
    ),
  }),
};
