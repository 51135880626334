import { observer } from "mobx-react-lite";
import * as React from "react";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Collection } from "../../models/Collection.model";

interface CollectionNameProps {
  className?: string;
  collection: Collection;
  shouldShowCatalogueNumber?: boolean;
}

const CollectionName: React.FC<CollectionNameProps> = observer(props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get name(): string {
      return p.collection.$.name || "Untitled Collection";
    },
  }));

  return (
    <span className={cx("CollectionName", p.className)}>
      {s.name}
      {/* {p.shouldShowCatalogueNumber && p.collection.artists.length === 1 && (
        <>
          , <CatalogueNumber collection={p.collection} />
        </>
      )} */}
    </span>
  );
});

export default CollectionName;
