export const equalByJsonSnapshot = (a: unknown, b: unknown) => {
  return JSON.stringify(a) === JSON.stringify(b);
};
export const equalByString = (a: unknown, b: unknown) => `${a}` === `${b}`;

export const equalById = (
  a: { id?: string | null; _id?: string | null },
  b: { id?: string | null; _id?: string | null }
) => {
  if (!a && !b) return true;
  if (!a || !b) return false;
  return equalByString(a.id || a._id, b.id || b._id);
};
