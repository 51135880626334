/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { withOpacity } from "../../base/utils/colors.utils";
import { fg, fg05 } from "../../constants/cssCustomProperties.constants";

type InterpretationMarkProps = {
  size?: number;
  color?: string | undefined | null;
  outlined?: boolean;
};

const style = {
  position: "absolute",
  top: 0,
  left: 0,
  pointerEvents: "none",
} as CSSPartial;

const InterpretationMark: React.FC<InterpretationMarkProps> = props => {
  const { SETTINGS } = useControllers();
  return (
    <Observer
      children={() => {
        const size = props.size ?? 5;
        const fill = props.outlined
          ? props.color
            ? withOpacity(props.color, 0.5)
            : fg05
          : props.color ?? fg;
        const stroke = props.outlined ? props.color ?? fg : undefined;
        return (
          <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            css={style}
          >
            <use
              xlinkHref="#interpretation-mark"
              fill={fill}
              stroke={stroke}
              width={size}
              height={size}
              css={{ pointerEvents: "none" }}
            />
          </svg>
        );
      }}
    />
  );
};

export default InterpretationMark;
