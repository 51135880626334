/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { varFontSerif } from "../../constants/cssCustomProperties.constants";
import { CSSPartial } from "../@types/css.types";
import { useProps, useStyle } from "../utils/mobx.utils";
import AccentedLoadingIndicator from "./AccentedLoadingIndicator";
import { loadingIndicatorEnter } from "./LoadingIndicator";

type LoadingIndicatorBlockProps = {
  height?: number | string;
  message?: ReactNode;
  delay?: string | number;
};

const LoadingIndicatorBlock: React.FC<LoadingIndicatorBlockProps> = props => {
  const p = useProps(props);
  const style = useStyle(() => ({
    get block(): CSSPartial {
      return {
        height: p.height,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        paddingTop: "2em",
        paddingBottom: "2em",
      };
    },
    get message(): CSSPartial {
      return {
        margin: "1.5em 0",
        fontFamily: varFontSerif,
        fontSize: "1.6rem",
        animation: `${loadingIndicatorEnter} .6s ${p.delay ?? ""} linear`,
      };
    },
  }));
  return (
    <Observer
      children={() => (
        <div className="LoadingIndicatorBlock" css={style.block}>
          <AccentedLoadingIndicator centered />
          {p.message && <div css={style.message}>{p.message}</div>}
        </div>
      )}
    />
  );
};

export default LoadingIndicatorBlock;
