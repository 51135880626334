import { makeIconDef } from "./makeIconDef";

export const SelectionIconDef = {
  selection: makeIconDef("selection", {
    regular: () => (
      <>
        <path
          d="M10.5 4.5H6.5C5.39543 4.5 4.5 5.39543 4.5 6.5V10.5M14.5 20.5H18.5C19.6046 20.5 20.5 19.6046 20.5 18.5V14.5M20.5 10.5V6.5C20.5 5.39543 19.6046 4.5 18.5 4.5H14.5M4.5 14.5V18.5C4.5 19.6046 5.39543 20.5 6.5 20.5H10.5"
          stroke="currentColor"
        />
        ,
      </>
    ),
  }),
};
