import { makeIconDef } from "./makeIconDef";

export const RecordIconDef = {
  record: makeIconDef("record", {
    regular: () => <circle cx="12.5" cy="12.5" r="7.5" fill="#E14446" />,
  }),
  "record-rec": makeIconDef("record-rec", {
    regular: () => (
      <>
        <path
          d="M2.03429 16.9623V13.025H2.40177L5.24978 16.9623H7.63843V16.8836L5.00042 13.34L4.63293 12.8937C6.22099 12.6444 7.11345 11.7519 7.11345 10.3738C7.11345 8.89078 6.20787 8.03769 4.25232 8.03769H0V16.9623H2.03429ZM4.05546 9.70449C4.75105 9.70449 5.07916 10.0195 5.07916 10.5838C5.07916 11.2794 4.60668 11.6075 3.76672 11.6075H2.03429V9.70449H4.05546Z"
          fill="currentColor"
        />
        <path
          d="M9.03588 16.9623H15.9393V15.1643H11.0702V13.3268H14.6269V11.5288H11.0702V9.83574H15.7425V8.03769H9.03588V16.9623Z"
          fill="currentColor"
        />
        <path
          d="M18.9759 12.5C18.9759 10.5445 19.6977 9.57325 21.0758 9.57325C22.1783 9.57325 22.7557 10.2426 23.1757 11.5157L25 10.9907C24.3307 8.62829 22.887 7.84082 21.1546 7.84082C18.5822 7.84082 16.9285 9.66512 16.9285 12.5C16.9285 15.3349 18.5822 17.1592 21.1546 17.1592C22.887 17.1592 24.3307 16.3717 25 14.0093L23.1757 13.4843C22.7557 14.7574 22.1783 15.4268 21.0758 15.4268C19.6977 15.4268 18.9759 14.4555 18.9759 12.5Z"
          fill="currentColor"
        />
      </>
    ),
  }),
};
