/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import { darken, lighten } from "polished";
import React from "react";
import FormLabel from "../../base/components/FormLabel";
import Selector from "../../base/components/Selector/Selector";
import SelectOptionVoice from "../../base/components/Selector/SelectOptionVoice";
import Spacing from "../../base/components/Spacing";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { ColorPalette } from "../../theming/colorPalette";
import {
  EnglishHarpsichordMeta,
  EnglishHarpsichordType,
} from "./EnglishHarpsichord.instrument";
import { useComposer } from "../../components/composer/ComposerApp.context";

type EnglishHarpsichordControlPanelProps = {
  instrument: EnglishHarpsichordType;
};

const EnglishHarpsichordControlPanel: React.FC<
  EnglishHarpsichordControlPanelProps
> = props => {
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    get color() {
      return (
        p.instrument.appearance.color ?? EnglishHarpsichordMeta.defaultColor!
      );
    },
    get options() {
      return [
        {
          Label: "Normal",
          value: "normal",
          color: darken(0.1, s.color),
        },
        {
          Label: "Lute",
          value: "lute",
          color: lighten(0.1, s.color),
        },
      ];
    },
  }));
  return (
    <Observer
      children={() => (
        <div css={{ padding: ".5em 1em 1em" }}>
          <FormLabel bold>Stops</FormLabel>
          <Spacing size=".5em" />
          <Selector
            form={p.instrument.options}
            field="activatedSamplers"
            options={s.options}
            optionRenderer={SelectOptionVoice}
            color={ColorPalette.teal}
            fullWidth
            singleChoice
            taskName={`Change the stops of "${p.instrument.nickName}"`}
            mergeableId={`instrument-change-stops-${p.instrument._id}`}
            disabled={I.editDisabled}
          />
        </div>
      )}
    />
  );
};

export default EnglishHarpsichordControlPanel;
