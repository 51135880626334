/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, AtomType } from "../../@types";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import BarPropertyPanel from "./BarPropertyPanel";
import GroupPropertyPanel from "./GroupPropertyPanel";
import AtomPropertyPanel from "./AtomPropertyPanel";
import NotePropertyPanel from "./NotePropertyPanel";
import VoicePropertyPanel from "./VoicePropertyPanel";
import PatternPropertyPanel from "./PatternPropertyPanel";
import ChordPropertyPanel from "./ChordPropertyPanel";
import KeyframePropertyPanel from "./KeyframePropertyPanel";
import OrnamentPropertyPanel from "./OrnamentPropertyPanel";
import TextNodePropertyPanel from "./TextNodePropertyPanel";
import ReplicaPropertyPanel from "./ReplicaPropertyPanel";
import SectionPropertyPanel from "./SectionPropertyPanel";

interface AtomPropertyPanelDispatcherProps {
  atom: Atom;
}

const AtomPropertyPanelDispatcher: React.FC<AtomPropertyPanelDispatcherProps> =
  React.memo(function AtomPropertyPanelDispatcher(props) {
    const { atom } = props;
    const p = useProps({ atom });
    const s = useStore(() => ({
      get type(): AtomType {
        return p.atom.type;
      },
      get panel() {
        switch (s.type) {
          case AtomType.note:
            return NotePropertyPanel;
          case AtomType.ornament:
            return OrnamentPropertyPanel;
          case AtomType.keyframe:
            return KeyframePropertyPanel;
          case AtomType.chord:
            return ChordPropertyPanel;
          case AtomType.group:
            return GroupPropertyPanel;
          case AtomType.pattern:
            return PatternPropertyPanel;
          case AtomType.replica:
            return ReplicaPropertyPanel;
          case AtomType.bar:
            return BarPropertyPanel;
          case AtomType.voice:
            return VoicePropertyPanel;
          case AtomType.textNode:
            return TextNodePropertyPanel;
          case AtomType.section:
            return SectionPropertyPanel;
          default:
            return AtomPropertyPanel;
        }
      },
      get color() {
        return p.atom.appearance?.colorInContext;
      },
    }));

    return (
      <Observer>
        {() => (
          <div className="AtomPropertyPanel" css={{ position: "relative" }}>
            <s.panel atom={p.atom} />
            {/* {isLocalhost && (
              <div>
                <Spacing />
                <LineSeparator />
                <Spacing />
                <AtomDebugInfo atom={p.atom} />
              </div>
            )} */}
          </div>
        )}
      </Observer>
    );
  });

export default AtomPropertyPanelDispatcher;
