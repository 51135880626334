import { last } from "../base/utils/ramdaEquivalents.utils";

export const enum MusicScaleName {
  Ionian = "Ionian",
  Dorian = "Dorian",
  Phrygian = "Phrygian",
  Lydian = "Lydian",
  Mixolydian = "Mixolydian",
  Aeolian = "Aeolian",
  Locrian = "Locrian",
}
export type MusicScaleDef = {
  name: MusicScaleName;
  displayName: string;
  commonName?: string;
  map: number[];
  intervals: number[];
  mode: number;
};
export const DiatonicScaleSourceIntervalList = [2, 2, 1, 2, 2, 2, 1];
export const makeDiatonicScaleIntervalList = (mode: number) => [
  ...DiatonicScaleSourceIntervalList.slice(mode - 1),
  ...DiatonicScaleSourceIntervalList.slice(0, mode - 1),
];
export const makeDiatonicScaleMap = (mode: number) =>
  makeDiatonicScaleIntervalList(mode).reduce(
    (prev, curr, i, arr) =>
      i === arr.length - 1 ? prev : [...prev, last(prev)! + curr],
    [0]
  );
export const DiatonicModeNames = [
  MusicScaleName.Ionian,
  MusicScaleName.Dorian,
  MusicScaleName.Phrygian,
  MusicScaleName.Lydian,
  MusicScaleName.Mixolydian,
  MusicScaleName.Aeolian,
  MusicScaleName.Locrian,
];
export const isDiatonicScaleDef = (scaleDef: MusicScaleDef) =>
  DiatonicModeNames.includes(scaleDef.name);
export const isDiatonicScaleName = (scaleName: MusicScaleName) =>
  DiatonicModeNames.includes(scaleName);

export const MusicScaleDefMap: Record<MusicScaleName, MusicScaleDef> = {
  [MusicScaleName.Ionian]: {
    name: MusicScaleName.Ionian,
    displayName: "Ionian",
    commonName: "Major",
    intervals: makeDiatonicScaleIntervalList(1),
    map: makeDiatonicScaleMap(1),
    mode: 1,
  },
  [MusicScaleName.Dorian]: {
    name: MusicScaleName.Dorian,
    displayName: "Dorian",
    intervals: makeDiatonicScaleIntervalList(2),
    map: makeDiatonicScaleMap(2),
    mode: 2,
  },
  [MusicScaleName.Phrygian]: {
    name: MusicScaleName.Phrygian,
    displayName: "Phrygian",
    intervals: makeDiatonicScaleIntervalList(3),
    map: makeDiatonicScaleMap(3),
    mode: 3,
  },
  [MusicScaleName.Lydian]: {
    name: MusicScaleName.Lydian,
    displayName: "Lydian",
    intervals: makeDiatonicScaleIntervalList(4),
    map: makeDiatonicScaleMap(4),
    mode: 4,
  },
  [MusicScaleName.Mixolydian]: {
    name: MusicScaleName.Mixolydian,
    displayName: "Mixolydian",
    intervals: makeDiatonicScaleIntervalList(5),
    map: makeDiatonicScaleMap(5),
    mode: 5,
  },
  [MusicScaleName.Aeolian]: {
    name: MusicScaleName.Aeolian,
    displayName: "Aeolian",
    commonName: "Minor",
    intervals: makeDiatonicScaleIntervalList(6),
    map: makeDiatonicScaleMap(6),
    mode: 6,
  },
  [MusicScaleName.Locrian]: {
    name: MusicScaleName.Locrian,
    displayName: "Locrian",
    intervals: makeDiatonicScaleIntervalList(7),
    map: makeDiatonicScaleMap(7),
    mode: 7,
  },
};
