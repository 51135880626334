/** @jsxImportSource @emotion/react */
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { CSSPartial } from "../@types/css.types";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useProps, useStore } from "../utils/mobx.utils";
import { isArray } from "../utils/typeChecks.utils";
import TextInput from "./TextInput";

type NumberArrayEditorProps<T extends {} = {}> = {
  form: T;
  field: StringKeyOf<T>;
  fixedLength?: number;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
};

const style = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "100%",
    marginLeft: "-.125em",
    marginRight: "-.125em",
    "> *": {
      margin: ".125em",
    },
  } as CSSPartial,
};

const NumberArrayEditor = function <T extends {} = {}>(
  props: NumberArrayEditorProps<T>
) {
  const p = useProps(props);
  const s = useStore(() => ({
    get array() {
      return p.form[p.field] as unknown as number[];
    },
    get length(): number {
      return p.fixedLength ?? s.array?.length ?? 0;
    },
  }));
  useOnMount(
    action(() => {
      if (!isArray(s.array)) {
        Reflect.set(p.form, p.field, Array(s.length).fill(0));
      }
    })
  );
  return (
    <Observer
      children={() => (
        <div css={style.container}>
          {Array(s.length)
            .fill(0)
            .map((n, i) => (
              <TextInput
                key={i}
                form={s.array as unknown as Record<string, number>}
                field={`${i}`}
                type="number"
                min={p.min}
                max={p.max}
                step={p.step}
                width="6em"
                disabled={p.disabled}
              />
            ))}
        </div>
      )}
    />
  );
};

export default NumberArrayEditor;
