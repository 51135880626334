/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Atom } from "../../@types";
import Button from "../../base/components/Button";
import Spacing from "../../base/components/Spacing";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { autoPluralize } from "../../base/utils/string.utils";
import { getAtomSetStartX, isNoteAtom } from "../../utils/atoms.utils";
import { useComposer } from "../composer/ComposerApp.context";

type AtomCommonControlsProps = {
  atoms: Atom[];
};

const Wrap = styled.div``;

const Grid = styled.div`
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: 1fr 1fr;
`;

const AtomCommonControls: React.FC<AtomCommonControlsProps> = props => {
  const p = useProps(props);
  const I = useComposer();
  const { ENSEMBLE } = useControllers();
  const s = useStore(() => ({
    duplicate: () => {
      I.runInHistory("Duplicate", () => {
        const newAtoms = I.atomContext.duplicateAtoms({
          atoms: p.atoms,
        });
        if (newAtoms.length === 1 && isNoteAtom(newAtoms[0])) {
          ENSEMBLE.playNote(newAtoms[0]);
        }
      });
    },
    deleteAtoms: () =>
      I.runInHistory(`Delete ${autoPluralize(p.atoms, "element")}`, () => {
        const newCursorX = getAtomSetStartX(p.atoms) ?? 0;
        I.tools.select.deleteSelectedAtoms();
        I.focusedCanvas?.primaryCursor?.moveTo(newCursorX);
      }),
  }));
  return (
    <Observer
      children={() => (
        <Wrap>
          <h4>Actions</h4>
          <Spacing size=".5em" />
          <Grid>
            <Button onClick={s.duplicate}>Duplicate</Button>
            <Button onClick={s.deleteAtoms}>Delete</Button>
          </Grid>
        </Wrap>
      )}
    />
  );
};

export default AtomCommonControls;
