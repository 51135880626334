/** @jsxImportSource @emotion/react */
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import Panel, {
  PanelCloseButton,
  PanelHeader,
} from "../../../base/components/Panel";
import SymbolIcon from "../../../base/components/SymbolIcon";
import { useObservableRef } from "../../../base/hooks/useObservableRef.hook";
import { hasFocusedInputs } from "../../../base/utils/dom.utils";
import { useProps } from "../../../base/utils/mobx.utils";
import { WorkspacePanelController } from "../../../controllers/composer/workspace.controller";
import { useControllers } from "../../../base/hooks/rootContext.hooks";

type WSPanelProps = {
  panel?: WorkspacePanelController;
};

const WSPanel: React.FC<PropsWithChildren<WSPanelProps>> = props => {
  const { DIALOGS } = useControllers();
  const p = useProps(props);
  const layerRef = useObservableRef();
  const innerRef = useObservableRef();
  useHotkeys(
    "escape",
    action(() => {
      if (hasFocusedInputs(layerRef.current)) return;
      if (DIALOGS.hasDialogs) return;
      if (
        layerRef.current?.nextElementSibling?.className.includes("PanelLayer")
      )
        return;
      if (p.panel?.isOnTopOfPanelStack) p.panel.toggle();
    })
  );
  return (
    <Observer
      children={() =>
        p.panel ? (
          <Panel
            id={p.panel.id}
            title={p.panel.title}
            controller={p.panel}
            height={p.panel.meta.height}
            minWidth={p.panel.meta.minWidth}
            maxHeight={p.panel.meta.maxHeight}
            minHeight={p.panel.meta.minHeight}
            layerRef={layerRef}
            innerRef={innerRef}
          >
            <PanelHeader
              End={<PanelCloseButton panel={p.panel} />}
              borderless={p.panel.meta.borderlessHeader}
            >
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  "> *": {
                    "+ *": {
                      marginLeft: ".5em",
                    },
                  },
                }}
              >
                <SymbolIcon icon={p.panel.icon} variant="colored" />
                <h3>{p.panel.title}</h3>
              </div>
            </PanelHeader>
            {props.children}
          </Panel>
        ) : null
      }
    />
  );
};

export default WSPanel;
