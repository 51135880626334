import {
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  StandardInstrumentOptions,
} from "../../@types";
import { STATIC_HOST } from "../../env";
import { ColorPalette } from "../../theming/colorPalette";
import { createCompositeSamplerInstrumentFactory } from "../_factory/compositeSamplerInstrumentFactory";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";

export type HarpOptions = StandardInstrumentOptions;

export const makeDefaultHarpOptions = (): HarpOptions => ({
  ...makeDefaultInstrumentOptions(),
});

export type HarpType = Instrument<HarpOptions>;

export const HarpName = "harp";

export const HarpMeta: InstrumentMeta<HarpOptions> = {
  name: HarpName,
  displayName: "Harp",
  source: "VS Chamber Orchestra: Community Edition",
  sourceWebsite: "https://github.com/sgossner/VSCO-2-CE",
  icon: "harp",
  range: [[31, 89]] as InstrumentRange,
  defaultColor: ColorPalette.flamingo,
  type: "percussion",
  hasSampledVelocity: true,
};

export const makeHarp = createCompositeSamplerInstrumentFactory({
  defaultOptionsBagBuilder: makeDefaultHarpOptions,
  instrumentMeta: HarpMeta,
  compositeSamplerConfigArray: [
    {
      name: "mf",
      velocityRangeFrom: 0,
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/harp/mf/`,
          urls: {
            G5: "KSHarp_G5_mf1.mp3",
            G3: "KSHarp_G3_mf1.mp3",
            F6: "KSHarp_F6_mf1.mp3",
            F4: "KSHarp_F4_mf1.mp3",
            F2: "KSHarp_F2_mf1.mp3",
            E5: "KSHarp_E5_mf1.mp3",
            E3: "KSHarp_E3_mf1.mp3",
            D6: "KSHarp_D6_mf1.mp3",
            D4: "KSHarp_D4_mf1.mp3",
            D2: "KSHarp_D2_mf1.mp3",
            C5: "KSHarp_C5_mf1.mp3",
            B6: "KSHarp_B6_mf1.mp3",
            B5: "KSHarp_B5_mf1.mp3",
            B3: "KSHarp_B3_mf1.mp3",
            B1: "KSHarp_B1_mf1.mp3",
            A6: "KSHarp_A6_mf1.mp3",
            A4: "KSHarp_A4_mf1.mp3",
            A2: "KSHarp_A2_mf1.mp3",
          },
          volume: 20,
        },
      },
    },
    {
      name: "f",
      velocityRangeFrom: 0.5,
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/harp/f/`,
          urls: {
            G3: "KSHarp_G3_f1.mp3",
            A2: "KSHarp_A2_f1.mp3",
            A4: "KSHarp_A4_f1.mp3",
            A6: "KSHarp_A6_f1.mp3",
            B1: "KSHarp_B1_f1.mp3",
            B3: "KSHarp_B3_f1.mp3",
            B5: "KSHarp_B5_f1.mp3",
            B6: "KSHarp_B6_f1.mp3",
            C3: "KSHarp_C3_f1.mp3",
            C5: "KSHarp_C5_f1.mp3",
            D2: "KSHarp_D2_f1.mp3",
            D4: "KSHarp_D4_f1.mp3",
            D6: "KSHarp_D6_f1.mp3",
            D7: "KSHarp_D7_f1.mp3",
            E1: "KSHarp_E1_f1.mp3",
            E3: "KSHarp_E3_f1.mp3",
            E5: "KSHarp_E5_f1.mp3",
            F2: "KSHarp_F2_f1.mp3",
            F4: "KSHarp_F4_f1.mp3",
            F6: "KSHarp_F6_f1.mp3",
            F7: "KSHarp_F7_f1.mp3",
            G1: "KSHarp_G1_f1.mp3",
            G5: "KSHarp_G5_f1.mp3",
          },
          volume: 15,
        },
      },
    },
  ],
});
