/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Observer } from "mobx-react-lite";
import React from "react";
import {
  varFontSans,
  varPrimary,
  varPrimary10,
  varPrimary20,
} from "../../constants/cssCustomProperties.constants";
import { UNITS } from "../constants/units.constant";
import { isMac } from "../utils/browsersAndPlatforms.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import { isString } from "../utils/typeChecks.utils";

export const Kbd = styled.kbd`
  font-family: ${varFontSans};
  font-weight: 500;
  background-color: ${varPrimary10};
  border: ${UNITS.lineWidth}px solid ${varPrimary10};
  border-bottom: 2px solid ${varPrimary20};
  color: ${varPrimary};
  border-radius: 0.2em;
  padding: 0 0.4em;
  margin: 0.1em 0.2em 0.1em 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: baseline;
  min-width: 2em;
  white-space: nowrap;
  line-height: 1.4;
  text-transform: uppercase;
  font-size: 80%;
`;

export type KeyboardShortcutDef =
  | string
  | readonly [mac: string, windows?: string];
type KeyboardShortcutProps = {
  def?: KeyboardShortcutDef;
  children?: string;
};

const replaceWithSymbols = (string: string) =>
  string
    .replace(/command|cmd/i, "⌘")
    .replace("⌘", !isMac ? "Ctrl" : "⌘")
    .replace(/shift/i, isMac ? "⇧" : "")
    .replace(/control|ctrl/i, isMac ? "⌃" : "")
    .replace(/alt|option/i, isMac ? "⌥" : "")
    .replace(/left/i, "←")
    .replace(/right/i, "→")
    .replace(/up/i, "↑")
    .replace(/down/i, "↓");

const KeyboardShortcut = (props: KeyboardShortcutProps) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get def() {
      return p.def ?? p.children ?? "";
    },
    get shortcut() {
      if (isString(s.def)) return s.def;
      return isMac ? s.def[0] : s.def[1] ?? s.def[0];
    },
    get symbols() {
      if (s.shortcut === "+") return ["+"];
      return replaceWithSymbols(s.shortcut).split("+");
    },
    get shortcutDisplay() {
      return isString(s.def)
        ? replaceWithSymbols(s.def).split(" ")
        : s.symbols.join(" ");
    },
  }));
  return (
    <Observer
      children={() =>
        isString(s.shortcutDisplay) ? (
          <Kbd>{s.shortcutDisplay}</Kbd>
        ) : (
          <>
            {s.shortcutDisplay.map(k => (
              <Kbd key={k}>{k}</Kbd>
            ))}
          </>
        )
      }
    />
  );
};

export default KeyboardShortcut;
