/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import React from "react";
import { CSSPartial } from "../@types/css.types";
import cx from "../utils/className.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import {
  IconName,
  IconVariant,
  isValidSymbolIcon,
} from "./Symbols/iconDefs/_index.iconDefs";

type IconProps = {
  className?: string;
  icon?: IconName;
  name?: IconName;
  variant?: IconVariant;
  onClick?: (e: React.MouseEvent) => void;
  size?: string | number;
  naked?: boolean;
};

const SymbolIcon: React.FC<IconProps> = observer(props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get _name(): IconName {
      return p.name || p.icon || "arrow";
    },
    get _variant(): IconVariant {
      if (!p.variant || p.variant === "regular") return "regular";
      return isValidSymbolIcon(s._name, p.variant) ? p.variant : "regular";
    },
    get id(): string {
      return `${s._name}${s._variant !== "regular" ? `-${s._variant}` : ""}`;
    },
    get style(): CSSPartial {
      return {
        width: p.size ?? "2.4rem",
        height: p.size ?? "2.4rem",
        display: "inline-block",
        verticalAlign: "middle",
        color: "inherit",
      };
    },
  }));

  return (
    <svg
      className={cx("SymbolIcon", p.className)}
      css={s.style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      data-name={s._name}
      data-variant={s._variant}
      onClick={p.onClick}
    >
      <use xlinkHref={`#icon-${s.id}`} />
    </svg>
  );
});

export default SymbolIcon;
