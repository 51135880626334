/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Composition } from "../../models/Composition.model";
import { useProps } from "../../base/utils/mobx.utils";
import { Markdown } from "../../base/components/Markdown";
import { LibraryEntryCompositionSummary } from "../../models/LibraryEntry.model";

type CompositionTitleProps = {
  composition: Composition | LibraryEntryCompositionSummary;
  includeKeyNameIfRequired?: boolean;
};

const CompositionTitle: React.FC<CompositionTitleProps> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() =>
        p.composition.options.renderTitleAsMarkdown ? (
          <Markdown inline>{p.composition.title}</Markdown>
        ) : (
          <span>{p.composition.title}</span>
        )
      }
    />
  );
};

export default CompositionTitle;
