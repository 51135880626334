/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import Button from "../../base/components/Button";
import IconPlus18 from "../../base/icons/18/Plus.icon.18";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Artist } from "../../models/Artist.model";
import NewArtistPanel from "../panels/NewArtistPanel";

type NewArtistButtonProps = {
  shouldNavigateToNewArtistAfterCreation?: boolean;
  onShouldClose?: (newArtist?: Artist) => void;
};

const NewArtistButton: React.FC<NewArtistButtonProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    shouldShowPanel: false,
    showNewArtistPanel: () => {
      s.shouldShowPanel = true;
    },
    closeNewArtistPanel: (newArtist?: Artist) => {
      s.shouldShowPanel = false;
      p.onShouldClose?.(newArtist);
    },
  }));

  return (
    <Observer
      children={() => (
        <>
          <Button onClick={s.showNewArtistPanel} Icon={<IconPlus18 />}>
            New artist
          </Button>
          {s.shouldShowPanel && (
            <NewArtistPanel
              onShouldClose={s.closeNewArtistPanel}
              shouldNavigateToNewArtistAfterCreation={
                p.shouldNavigateToNewArtistAfterCreation
              }
            />
          )}
        </>
      )}
    />
  );
};

export default NewArtistButton;
