/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Instrument } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import LoadingIndicator from "../../base/components/LoadingIndicator";
import SymbolIcon from "../../base/components/SymbolIcon";
import { UNITS } from "../../base/constants/units.constant";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import cx from "../../base/utils/className.utils";
import { withOpacity } from "../../base/utils/colors.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { useComposer } from "../composer/ComposerApp.context";
import PlayStateHighlighter from "./PlayStateHighlighter";

type InstrumentEntryProps = {
  className?: string;
  instrument: Instrument;
  onClick?: (instrument: Instrument) => void;
};

const InstrumentEntry: React.FC<InstrumentEntryProps> = props => {
  const { SETTINGS, THEME } = useControllers();
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    get isInteractable() {
      return Boolean(p.onClick);
    },
    handleComponentClick: () => {
      p.onClick?.(p.instrument);
    },
  }));
  const style = useStore(() => ({
    get color() {
      return (
        p.instrument.appearance?.color ??
        p.instrument.meta.defaultColor ??
        THEME.fg
      );
    },
    get component(): CSSPartial {
      return {
        position: "relative",
        backgroundColor: withOpacity(style.color, 0.15),
        border: `${UNITS.lineWidth}px solid ${withOpacity(style.color, 0.5)}`,
        borderRadius: ".25em",
        "&:hover": {
          filter: s.isInteractable ? "brightness(1.1)" : undefined,
          borderColor: s.isInteractable ? style.color : undefined,
        },
        "&:active": {
          filter: s.isInteractable ? "brightness(.9)" : undefined,
        },
      };
    },
    get inner(): CSSPartial {
      return {
        display: "flex",
        zIndex: 1,
      };
    },
    get iconWrapper(): CSSPartial {
      return {
        flex: "0 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1em",
      };
    },
    get label(): CSSPartial {
      return {
        flex: "1 1 auto",
        display: "grid",
        gridGap: ".5em",
        gridTemplateColumns: "minmax(0,1fr) minmax(2em, auto)",
        alignItems: "center",
        padding: "1em 1em 1em 0",
      };
    },
    get title(): CSSPartial {
      return {
        fontSize: "1.4rem",
        fontWeight: 500,
      };
    },
    get controls(): CSSPartial {
      return {
        textAlign: "right",
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <div
          className={cx("InstrumentEntry", p.className)}
          onClick={s.handleComponentClick}
          css={style.component}
        >
          <PlayStateHighlighter
            baseOpacityWhenOn={0.3}
            gradientOpacityMap={[0.8, 0.5]}
            respondToInstrument={p.instrument}
          />
          <div css={style.inner}>
            <div css={style.iconWrapper}>
              <SymbolIcon icon={p.instrument.meta.icon ?? "piano-keyboard"} />
            </div>
            <div css={style.label}>
              <p css={style.title}>{p.instrument.nickName}</p>
              <div css={style.controls}>
                {p.instrument.isLoading && <LoadingIndicator />}
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default InstrumentEntry;
