/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import chroma from "chroma-js";
import { isString } from "lodash-es";
import { action, reaction, runInAction } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { Atom } from "../../@types";
import ColorInput from "../../base/components/ColorInput";
import FormLabel from "../../base/components/FormLabel";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { ColorPalette } from "../../theming/colorPalette";
import {
  AppearanceColorDefObject,
  RequiredAppearanceColorDefObject,
} from "../../traits/hasAppearance.trait";
import { varAccent } from "../../constants/cssCustomProperties.constants";
import { useComposer } from "./ComposerApp.context";

type AppearanceColorPickerProps = {
  className?: string;
  atom: Atom;
  Label?: ReactNode;
  taskName: (mode: keyof AppearanceColorDefObject) => string;
  mergeableId: (mode: keyof AppearanceColorDefObject) => string;
  onChange?: (
    value: RequiredAppearanceColorDefObject,
    prev: RequiredAppearanceColorDefObject
  ) => void;
  type?: "color" | "highlight";
};

const AppearanceColorPickerWrap = styled.div``;
const InputSet = styled.div`
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: 1fr auto 1fr;
  align-items: end;
  label {
    text-transform: uppercase;
    font-size: 85%;
  }
`;
const LinkButton = styled.button`
  appearance: none;
  background-color: transparent;
  color: inherit;
  border: 0;
  padding: 0.5em;
  cursor: pointer;
  margin-bottom: 0.25em;
  &[disabled] {
    opacity: 0.5;
  }
`;
const ResetButton = styled.button`
  appearance: none;
  background-color: transparent;
  color: ${varAccent};
  border: 0;
  padding: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
`;

const makeDefaultInnerValue = () => ({
  dark: "#666666",
  light: "#666666",
});

const AppearanceColorPicker: React.FC<AppearanceColorPickerProps> = props => {
  const { THEME } = useControllers();
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    get appearance() {
      return p.atom.appearance;
    },
    get type() {
      return p.type ?? "color";
    },
    get isEmpty() {
      return !s.appearance[s.type];
    },
    internal: makeDefaultInnerValue(),
    wasLinked: false,
    applyValue: () => {
      const prev = { ...s.internal };
      if (s.wasLinked) {
        if (THEME.isDarkTheme) s.internal.light = s.internal.dark;
        else s.internal.dark = s.internal.light;
      }
      if (p.atom.appearance) {
        p.atom.appearance[s.type] = { ...s.internal };
      } else {
        p.atom.setAppearance({
          [s.type]: { ...s.internal },
        });
      }
      s.wasLinked = s.internal.dark === s.internal.light;
      p.onChange?.(s.internal, prev);
    },
    get linked() {
      return s.internal.dark === s.internal.light;
    },
    set linked(v) {
      if (v) {
        if (THEME.isDarkTheme) s.internal.light = s.internal.dark;
        else s.internal.dark = s.internal.light;
      } else {
        if (THEME.isDarkTheme)
          s.internal.light = chroma(s.internal.dark).darken(0.1).hex();
        else s.internal.dark = chroma(s.internal.light).brighten(0.1).hex();
      }
      s.wasLinked = v;
    },
    toggleLink: () => {
      I.runInHistory(
        "Unlink color light/dark mode",
        action(() => {
          s.linked = !s.linked;
        })
      );
    },
    get canClear() {
      return !!(s.type === "highlight" && s.appearance.highlight);
    },
    clear: () => {
      I.runInHistory(
        "Clear highlight color",
        action(() => {
          s.appearance[s.type] = null;
          s.internal = makeDefaultInnerValue();
        })
      );
    },
  }));
  useOnMount(() => {
    const d = reaction(
      () => JSON.stringify(s.appearance?.[s.type]),
      () => {
        if (!s.appearance) return;
        if (!s.appearance[s.type]) return;
        const colorDef = s.appearance[s.type];
        if (isString(colorDef)) {
          s.internal.dark = s.internal.light = colorDef || ColorPalette.gray;
        } else if (colorDef) {
          s.internal.dark = colorDef.dark || colorDef.light || s.internal.dark;
          s.internal.light =
            colorDef.light || colorDef.dark || s.internal.light;
        }
      },
      { fireImmediately: true }
    );
    runInAction(() => {
      s.wasLinked = s.internal.dark === s.internal.light;
    });
    return d;
  });
  return (
    <Observer
      children={() => (
        <AppearanceColorPickerWrap className={p.className}>
          {(p.Label || s.canClear) && (
            <FormLabel
              bold
              css={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: ".5em",
              }}
            >
              <span>{p.Label}</span>
              {s.canClear && (
                <ResetButton onClick={s.clear} disabled={I.editDisabled}>
                  × Clear
                </ResetButton>
              )}
            </FormLabel>
          )}
          <InputSet>
            <ColorInput
              form={s.internal}
              field="dark"
              Label="Dark mode"
              taskName={p.taskName("dark")}
              mergeableId={p.mergeableId("dark")}
              onChange={s.applyValue}
              isEmpty={s.isEmpty}
              disabled={I.editDisabled}
            />
            <LinkButton onClick={s.toggleLink} disabled={I.editDisabled}>
              {s.linked ? <LinkIcon /> : <UnlinkedIcon />}
            </LinkButton>
            <ColorInput
              form={s.internal}
              field="light"
              Label="Light mode"
              taskName={p.taskName("light")}
              mergeableId={p.mergeableId("light")}
              onChange={s.applyValue}
              isEmpty={s.isEmpty}
              disabled={I.editDisabled}
            />
          </InputSet>
        </AppearanceColorPickerWrap>
      )}
    />
  );
};

const LinkIcon = () => (
  <svg width="14" height="7" viewBox="0 0 14 7" fill="currentColor">
    <path d="M3 1.5L6 1.5C7.10457 1.5 8 2.39543 8 3.5C8 3.68353 7.97528 3.86128 7.92899 4.03011C7.86748 4.25445 7.90513 4.50481 8.07483 4.66391V4.66391C8.31553 4.88956 8.70564 4.83906 8.81882 4.52915C8.93604 4.20817 9 3.86155 9 3.5C9 1.84315 7.65686 0.5 6 0.5H3C1.34315 0.5 0 1.84315 0 3.5C0 5.15685 1.34315 6.5 3 6.5H5.10359C5.19089 6.5 5.2333 6.39016 5.17157 6.32843V6.32843C5.06024 6.21709 4.95614 6.09987 4.85959 5.97749C4.6383 5.697 4.31579 5.5 3.95852 5.5H3C1.89543 5.5 1 4.60457 1 3.5C1 2.39543 1.89543 1.5 3 1.5ZM11 5.5L8 5.5C6.89543 5.5 6 4.60457 6 3.5C6 3.31647 6.02472 3.13872 6.07101 2.96989C6.13252 2.74555 6.09487 2.49519 5.92517 2.33609V2.33609C5.68447 2.11044 5.29436 2.16094 5.18118 2.47085C5.06396 2.79183 5 3.13845 5 3.5C5 5.15685 6.34315 6.5 8 6.5L11 6.5C12.6569 6.5 14 5.15685 14 3.5C14 1.84315 12.6569 0.5 11 0.5H8.89641C8.80911 0.5 8.7667 0.609844 8.82843 0.671573V0.671573C8.93976 0.782909 9.04386 0.900133 9.14041 1.02251C9.3617 1.30301 9.68421 1.5 10.0415 1.5L11 1.5C12.1046 1.5 13 2.39543 13 3.5C13 4.60457 12.1046 5.5 11 5.5Z" />
  </svg>
);

const UnlinkedIcon = () => (
  <svg width="14" height="7" viewBox="0 0 14 7" fill="currentColor">
    <path d="M6 1C6 1.27614 5.77614 1.5 5.5 1.5L3 1.5C1.89543 1.5 1 2.39543 1 3.5C1 4.60457 1.89543 5.5 3 5.5H5.5C5.77614 5.5 6 5.72386 6 6V6C6 6.27614 5.77614 6.5 5.5 6.5H3C1.34315 6.5 0 5.15685 0 3.5C0 1.84315 1.34315 0.5 3 0.5H5.5C5.77614 0.5 6 0.723858 6 1V1ZM8 6C8 5.72386 8.22386 5.5 8.5 5.5H11C12.1046 5.5 13 4.60457 13 3.5C13 2.39543 12.1046 1.5 11 1.5L8.5 1.5C8.22386 1.5 8 1.27614 8 1V1C8 0.723858 8.22386 0.5 8.5 0.5H11C12.6569 0.5 14 1.84315 14 3.5C14 5.15685 12.6569 6.5 11 6.5H8.5C8.22386 6.5 8 6.27614 8 6V6Z" />
  </svg>
);

export default AppearanceColorPicker;
