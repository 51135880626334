import { action } from "mobx";
import { Atom } from "../@types";
import {
  addManyToArrayIfNew,
  addOneToArrayIfNew,
  clearArray,
  removeManyFromArray,
  removeOneFromArray,
  replaceContents,
} from "../base/utils/array.utils";
import { difference, first } from "../base/utils/ramdaEquivalents.utils";
import { runAfter } from "../base/utils/waiters.utils";
import { SELECTION_GROUP_ID } from "../constants/selection.constants";

import { SelectTool } from "../tools/makeSelectTool";
import { isBarAtom, isVoiceAtom } from "../utils/atoms.utils";
import {
  setAtomElementSelectionState,
  setAtomElementsSelectionState,
} from "../utils/cCanvas.utils";

const debug = false;

export type UpdateAtomSelectionOptions = {
  debugCaller: string;
  mode?: ArrayOperationMode;
  setWriteToVoiceAs?: boolean;
  updateCursorPosition?: boolean;
  atoms?: Atom[];
};

const removeSelectionPseudoGroupFromParentList = (
  canvasId: string | undefined,
  atoms: Atom[]
) => {
  atoms.forEach(
    action(atom => {
      removeOneFromArray(atom.parentIds, SELECTION_GROUP_ID);
      setAtomElementSelectionState(atom, false);
    })
  );
};

export const updateAtomSelection = function (
  options: UpdateAtomSelectionOptions & {
    selectionTool: SelectTool;
  }
) {
  const { selectionTool, mode, atoms } = options;
  if (debug)
    console.info(
      `%c updateAtomSelection | ${options.debugCaller} | ${options.mode}`,
      "color: teal",
      options
    );
  const canvasId = selectionTool.canvas?.id;
  const { atomSelection } = selectionTool;
  const previousSelection = [...selectionTool.__previousSelection];
  const context = selectionTool.atomContext;
  const previousCursorPosition = context?.canvas?.primaryCursor?.x;
  // if (context) clearEmptyAtoms(context);
  if (mode === "clear") {
    if (debug) console.info("clearing selection");
    removeSelectionPseudoGroupFromParentList(canvasId, atomSelection);
    atomSelection.splice(0);
    clearArray(selectionTool.selectionPseudoGroup.children);
  }
  if (!atoms) return;
  switch (mode) {
    case "subtract": {
      removeSelectionPseudoGroupFromParentList(canvasId, atoms);
      removeManyFromArray(atomSelection, atoms);
      removeManyFromArray(selectionTool.selectionPseudoGroup.children, atoms);
      setAtomElementsSelectionState(atoms, false);
      return;
    }
    case "replace": {
      const diff = difference(atomSelection, atoms);
      removeSelectionPseudoGroupFromParentList(canvasId, diff);
      setAtomElementsSelectionState(diff, false);
      replaceContents(atomSelection, atoms);
      setAtomElementsSelectionState(atoms, true);
      break;
    }
    case "add": {
      addManyToArrayIfNew(atomSelection, atoms);
      setAtomElementsSelectionState(atoms, true);
      break;
    }
    case "toggle": {
      atoms.forEach(atom => {
        if (atomSelection.includes(atom)) {
          removeOneFromArray(atomSelection, atom);
          removeOneFromArray(selectionTool.selectionPseudoGroup.children, atom);
          setAtomElementSelectionState(atom, false);
        } else {
          addOneToArrayIfNew(atomSelection, atom);
          setAtomElementSelectionState(atom, true);
        }
      });
      break;
    }
  }
  if (options.setWriteToVoiceAs) {
    runAfter(() => {
      const firstAtom = first(atoms);
      // set the voice to match the first atom - if it's not a bar
      if (isBarAtom(firstAtom)) return;
      const voice = isVoiceAtom(firstAtom) ? firstAtom : firstAtom?.voice;
      if (voice)
        selectionTool.canvas?.composer?.setWriteToVoiceAs(
          voice,
          "updateSelection"
        );
    });
  }
  if (options.updateCursorPosition && atoms.length) {
    const endX = Math.max(
      ...(atoms.map(n => n.endX).filter(i => i !== null) as number[])
    );
    const newCursorPosition = endX;
    context?.canvas?.setPrimaryCursorPosition(newCursorPosition, false);
  }
  replaceContents(selectionTool.selectionPseudoGroup.children, atomSelection);
  const result = {
    mode,
    previousSelection,
    previousCursorPosition,
    newSelection: atoms,
  };
  return result;
};
