import { AtomType, Bar, Instrument, Note, Ornament } from "../../@types";
import { MusicKey } from "../../constants/musicKeys.constants";
import {
  createAtomFactory,
  makeAtomBaseSnapshot,
} from "../../logic/Atom.factory";
import {
  getTransformedValueOfProp,
  setTransformedValueOfProp,
} from "../../transformers/transform.controller";
import {
  getIndexInOctaveFromCFromMidiNumber,
  getIndexInOctaveFromCFromPitchClass,
  getMidiNumberFromY,
  getMusicKeyDisplayName,
  getPitchClassDiff,
  getPitchClassFromMidiNumber,
  getYFromMidiNumber,
} from "../../utils/musicKey.utils";
import {
  getFrequencyFromMidiNumber,
  getMidiNumberFromFrequency,
} from "../../utils/frequency.utils";
import { observable } from "mobx";
import {
  add,
  applyFormula,
  approxEq,
  subtract,
} from "../../base/utils/math.utils";
import { clamp } from "three/src/math/MathUtils";
import {
  getInterpretedInstrumentsOfAtom,
  getNextAtomInArray,
  getPrevAtomInArray,
} from "../../logic/atomFactoryMethods";
import {
  getInterpretedBpmOfAtom,
  getInterpretedBpxOfAtom,
  getInterpretedDurationInSecondsOfAtom,
  getInterpretedEndXOfNoteOrKeyframe,
  getInterpretedStartXOfNoteOrKeyframe,
  getInterpretedTimeEndInSecondsOfAtom,
  getInterpretedTimeStartInSecondsOfAtom,
  getInterpretedWidthOfAtom,
  getInterpretedXpmOfAtom,
  getTimeEndInSecondsOfAtom,
  getTimeStartInSecondsOfAtom,
  isOrnamentAtom,
} from "../../utils/atoms.utils";
import { transpose } from "../../utils/transposition.utils";
import {
  getNoteAutoAdjustedDuration,
  setupNoteScheduler,
} from "../../utils/playback.utils";
import { makeRect } from "../geometry/makeRect.model";
import { ValidRect } from "../../base/@types";

export const NoteSnapshotFactory = () => ({
  ...makeAtomBaseSnapshot(AtomType.note),
  velocity: null as number | null,
  ornamentId: null as string | null,
  disableOrnamentReplicaSync: false,
});

export const makeNoteExtendedMembers = (N: Note) => {
  const _ = observable({
    lastNoteOnEventId: null as null | number,
    _on: false,
  });
  const result = {
    get _on() {
      return _._on;
    },
    _markNoteOn: () => {
      const id = Math.random();
      _.lastNoteOnEventId = id;
      _._on = true;
      return () => {
        if (id !== _.lastNoteOnEventId) return;
        _._on = false;
      };
    },
    _hardMarkNoteOff: () => {
      _._on = false;
    },
    get pitchClassNumber() {
      return N.indexInOctaveFromC;
    },
    get pitchClass() {
      return getPitchClassFromMidiNumber(N.midiNumber);
    },
    set pitchClass(newPitchClass: MusicKey | null) {
      if (!newPitchClass) N.$.y = null;
      if (!N.pitchClass)
        N.$.y = 60 + (getIndexInOctaveFromCFromPitchClass(newPitchClass) ?? 0);
      const diff = getPitchClassDiff(newPitchClass!, N.pitchClass!) ?? 0;
      N.$.y = (N.$.y ?? 0) + diff;
    },
    get _x() {
      return (
        N.$.x ??
        (N.refAtom
          ? add(N.replica?.anchor.x ?? null, N.refAtom.xyRelToAnchor?.x ?? null)
          : null)
      );
    },
    set _x(newValue) {
      N.$.x = newValue;
    },
    get x() {
      return getTransformedValueOfProp(N, "x");
    },
    set x(newValue) {
      setTransformedValueOfProp(N, "x", newValue);
    },
    get _width() {
      return N.$.width ?? N.refAtom?.width ?? null;
    },
    set _width(v) {
      N.$.width = v;
    },
    get width() {
      return getTransformedValueOfProp(N, "width");
    },
    set width(v) {
      setTransformedValueOfProp(N, "width", v);
    },
    get octave() {
      if (N.midiNumber === null) return 4;
      return Math.floor(N.midiNumber / 12) - 1;
    },
    set octave(o: number) {
      const diff = o - N.octave;
      N.y = (N.y ?? 0) - diff * 12;
    },
    get keyName() {
      return `${N.pitchClass ?? "?"}${N.octave}`;
    },
    get displayKeyName() {
      return N.pitchClass
        ? `${getMusicKeyDisplayName(N.pitchClass)}${N.octave}`
        : N.keyName;
    },
    get displayName() {
      return N.name || `Note ${N.displayKeyName}`;
    },
    set displayName(v) {
      N.name = `${v}`;
    },
    get indexInOctaveFromC() {
      return N.midiNumber
        ? getIndexInOctaveFromCFromMidiNumber(N.midiNumber)
        : null;
    },
    get midiNumber() {
      return getMidiNumberFromY(N.centerY);
    },
    set midiNumber(v) {
      N.$.y = getYFromMidiNumber(v);
    },
    get frequency() {
      return N.midiNumber
        ? getFrequencyFromMidiNumber(
            N.midiNumber,
            N.context?.ROOT?.ENSEMBLE.masterTuning
          )
        : null;
    },
    set frequency(f) {
      if (f === null) N.$.y = null;
      else N.midiNumber = getMidiNumberFromFrequency(f);
    },
    get _y() {
      if (N.$.y !== null) return N.$.y;
      if (!N.refAtom || !N.replica || !N.replica.pattern) return null;
      if (N.replica.snapToScale) {
        if (N.refAtom.y === null) return null;
        return transpose({
          y: N.refAtom.y,
          patternAnchorY: N.replica.pattern.anchor.y,
          replicaScaleStepDiffFromSource: N.replica.scaleStepDiffFromSource,
          source: {
            key: N.refAtom.musicKey,
            scale: N.refAtom.musicScaleName,
          },
          target: {
            key: N.musicKey,
            scale: N.musicScaleName,
          },
        });
      } else {
        return add(N.replica?.anchor.y, N.refAtom.xyRelToAnchor?.y ?? null);
      }
    },
    set _y(newValue) {
      N.$.y = newValue;
    },
    get y() {
      return getTransformedValueOfProp(N, "y");
    },
    set y(v) {
      setTransformedValueOfProp(N, "y", v);
    },
    get centerY() {
      return N.y;
    },
    set centerY(v) {
      N.$.y = v;
    },
    get height() {
      return 0;
    },
    get timeStartInSeconds() {
      return getTimeStartInSecondsOfAtom(N);
    },
    get timeEndInSeconds() {
      return getTimeEndInSecondsOfAtom(N);
    },
    get durationInSeconds() {
      if (N.timeStartInSeconds === null || N.timeEndInSeconds === null)
        return 0;
      return N.timeEndInSeconds - N.timeStartInSeconds;
    },
    get velocity() {
      return N.$.velocity ?? N.refAtom?.velocity ?? 0.5;
    },
    set velocity(v) {
      N.$.velocity = v;
    },
    get muted() {
      return !!N.interpreted.ornament || !!N.rulePropertiesFlattened.disabled;
    },
    get nextNote() {
      return getNextAtomInArray(N, N.voice?.descendantNotes);
    },
    get prevNote() {
      return getPrevAtomInArray(N, N.voice?.descendantNotesReversed);
    },
    // get notationValue() {
    //   if (!N.beatCount) return 0;
    //   const bar = first(N.bars);
    //   const { timeSignature = makeTimeSignature44() } = bar ?? {};
    //   return (N.beatCount * 1) / timeSignature[1];
    // },
    get chord() {
      return N.context?.chords.find(c => c.descendantNotes.includes(N)) ?? null;
    },
    get indexInChord() {
      return N.chord?.notesSorted.indexOf(N) ?? 0;
    },
    get ornamentInComp() {
      return N.$.ornamentId
        ? N.context?.getAtomById<Ornament>(N.$.ornamentId) ?? null
        : null;
    },
    get ornamentParent() {
      return N.parents.find(isOrnamentAtom);
    },
    get isInOrnament() {
      return N.parents.some(isOrnamentAtom);
    },
    get _anchor() {
      return N.replica?._anchor ?? { x: 0, y: 0 };
    },
    interpreted: observable({
      get instruments(): Instrument[] {
        return getInterpretedInstrumentsOfAtom(N);
      },
      get supportsVelocity() {
        return N.interpreted.instruments.some(i => i.supportsVelocity);
      },
      get arpeggioOffset() {
        return N.chord && N.chord.arpeggio.length >= N.indexInChord + 1
          ? N.chord.arpeggio[N.indexInChord] ?? 0
          : 0;
      },
      get startX() {
        return add(
          getInterpretedStartXOfNoteOrKeyframe(N),
          N.interpreted.arpeggioOffset
        );
      },
      get endX() {
        return getInterpretedEndXOfNoteOrKeyframe(N);
      },
      get width() {
        return subtract(
          getInterpretedWidthOfAtom(N),
          N.interpreted.arpeggioOffset
        )!;
      },
      get timeStartInSeconds() {
        return getInterpretedTimeStartInSecondsOfAtom(N);
      },
      get timeEndInSeconds() {
        return getInterpretedTimeEndInSecondsOfAtom(N);
      },
      get durationInSeconds() {
        return getInterpretedDurationInSecondsOfAtom(N);
      },
      get bpm() {
        return getInterpretedBpmOfAtom(N);
      },
      get bpx() {
        return getInterpretedBpxOfAtom(N);
      },
      get xpm() {
        return getInterpretedXpmOfAtom(N);
      },
      get velocity() {
        return clamp(
          applyFormula(
            N.velocity,
            N.rulePropertiesFlattened.velocity ?? null
          ) ?? N.velocity,
          0,
          1
        );
      },
      get ornament() {
        return N.interpreted.ornamentId
          ? N.context?.getAtomById<Ornament>(N.interpreted.ornamentId) ?? null
          : null;
      },
      get ornamentId() {
        return N.rulePropertiesFlattened.ornamentId ?? N.$.ornamentId;
      },
      get nextImmediateNotesWithSameInstrument() {
        if (N.interpreted.endX === null || !N.endsInBar) return [];
        return (
          [
            N.endsInBar,
            N.interpreted.endX > N.endsInBar.endX ? N.endsInBar.nextBar : null,
          ].filter(b => b) as Bar[]
        )
          .map(b => b.notes)
          .flat()
          .filter(n => {
            return (
              !n.muted &&
              n.y === N.y &&
              n.interpreted.startX !== null &&
              approxEq(n.interpreted.startX, N.interpreted.endX!) &&
              n.interpreted.instruments.some(ins =>
                N.interpreted.instruments.includes(ins)
              )
            );
          });
      },
      get overlappingNotesWithSameInstrument() {
        if (N.interpreted.endX === null || N.interpreted.startX === null)
          return [];
        return (
          N.endsInBar?.notes.filter(n => {
            return (
              n !== N &&
              !n.muted &&
              n.y === N.y &&
              n.interpreted.startX !== null &&
              n.interpreted.endX !== null &&
              n.interpreted.endX > N.interpreted.startX! &&
              n.interpreted.startX < N.interpreted.endX! &&
              n.interpreted.instruments.some(ins =>
                N.interpreted.instruments.includes(ins)
              )
            );
          }) ?? []
        ).sort((a, b) => {
          if (
            a.interpreted.timeStartInSeconds === null ||
            b.interpreted.timeStartInSeconds === null
          )
            return -1;
          return (
            a.interpreted.timeStartInSeconds - b.interpreted.timeStartInSeconds
          );
        });
      },
      get someNextImmediateNoteIsSamePitch() {
        return N.interpreted.nextImmediateNotesWithSameInstrument.some(
          n => !n.muted && n.y === N.y
        );
      },
      get notesStartingAtTheSameTimeWithSamePitchAndInstrumentIncludingSelf() {
        if (N.interpreted.timeStartInSeconds === null) return [];
        return (
          N.startsInBar?.notes.filter(n => {
            return (
              !n.muted &&
              n.interpreted.timeStartInSeconds !== null &&
              n.y === N.y &&
              approxEq(
                n.interpreted.timeStartInSeconds,
                N.interpreted.timeStartInSeconds!,
                0.01
              ) &&
              n.interpreted.instruments.some(ins =>
                N.interpreted.instruments.includes(ins)
              )
            );
          }) ?? []
        ).sort(
          (a, b) =>
            b.interpreted.durationInSeconds - a.interpreted.durationInSeconds
        );
      },
      get notesStartingAtTheSameTimeWithSamePitchAndInstrument() {
        return N.interpreted.notesStartingAtTheSameTimeWithSamePitchAndInstrumentIncludingSelf.filter(
          n => n !== N
        );
      },
      get adjustedDuration() {
        return getNoteAutoAdjustedDuration(N);
      },
    }),
    get hitBox() {
      if (
        N.startX === null ||
        N.interpreted.startX === null ||
        N.endX === null ||
        N.interpreted.endX === null ||
        N.y === null
      )
        return null;
      return makeRect(
        Math.min(N.startX, N.interpreted.startX),
        N.y - (N.appearance.noteYScalar ?? 1) / 2,
        Math.min(N.endX, N.interpreted.endX),
        N.y + (N.appearance.noteYScalar ?? 1) / 2
      ) as ValidRect;
    },
  };
  return result;
};

export const makeNote = createAtomFactory<Note>({
  type: AtomType.note,
  snapshotFactory: NoteSnapshotFactory,
  extendedPropertiesFactories: [makeNoteExtendedMembers],
  init: n => {
    return setupNoteScheduler(n);
  },
});
