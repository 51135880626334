import { makeIconDef } from "./makeIconDef";

export const ArrowCursorIconDef = {
  "arrow-cursor": makeIconDef("arrow-cursor", {
    regular: () => (
      <path
        d="M6 3L7.5 22.5L12.5 15H21L6 3Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    ),
  }),
};
