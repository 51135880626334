/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import LoadingIndicator from "../../base/components/LoadingIndicator";
import Spacing from "../../base/components/Spacing";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { flex } from "../../base/styles/helpers/flex.styleHelper";
import { keepTruthy } from "../../base/utils/array.utils";
import { useCompleteProgressBar } from "../../base/utils/globalProgressBar.utils";
import { useStore } from "../../base/utils/mobx.utils";
import { runAfter } from "../../base/utils/waiters.utils";

// eslint-disable-next-line @typescript-eslint/ban-types
type LogoutPageProps = {};

const LogoutPage: React.FC<LogoutPageProps> = props => {
  const { UI, AUTH } = useControllers();
  const s = useStore(() => ({
    username: AUTH.user?.$.username ?? AUTH.user?.$.givenName,
    get pageStyle() {
      return {
        ...flex({ align: "center", justify: "center", direction: "column" }),
        width: UI.appWidth,
        height: UI.appHeight,
        fontSize: "1.4rem",
      };
    },
  }));
  useOnMount(() => {
    runAfter(
      () => {
        AUTH.logout();
      },
      AUTH.isAuthenticated ? 618 : 0
    );
  });
  useCompleteProgressBar();
  return (
    <Observer
      children={() => (
        <div className="LogoutPage" css={s.pageStyle}>
          <LoadingIndicator delay={0} />
          <Spacing />
          <p>{keepTruthy(["See you later", s.username]).join(", ")}!</p>
        </div>
      )}
    />
  );
};

export default LogoutPage;
