import { makeIconDef } from "./makeIconDef";

export const ReverbIconDef = {
  reverb: makeIconDef("reverb", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.93505C6 5.48174 5.47838 5.24618 5.1671 5.57572C3.46072 7.38216 2.41455 9.8189 2.41455 12.5C2.41455 15.1811 3.46072 17.6178 5.1671 19.4243C5.47838 19.7538 6 19.5182 6 19.0649C6 18.9258 5.94503 18.7926 5.85019 18.6908C4.33903 17.0683 3.41455 14.8921 3.41455 12.5C3.41455 10.1079 4.33903 7.93167 5.85019 6.30918C5.94503 6.20735 6 6.07421 6 5.93505ZM19 19.065C19 18.9258 19.055 18.7927 19.1498 18.6908C20.661 17.0683 21.5855 14.8921 21.5855 12.5C21.5855 10.1078 20.661 7.93164 19.1498 6.30914C19.055 6.20732 19 6.07417 19 5.93502C19 5.48171 19.5216 5.24615 19.8329 5.57568C21.5393 7.38213 22.5855 9.81888 22.5855 12.5C22.5855 15.1811 21.5393 17.6178 19.8329 19.4243C19.5216 19.7538 19 19.5183 19 19.065ZM9.53846 8.8637C9.53846 8.43166 9.06624 8.18175 8.75024 8.47637C7.67337 9.48038 7 10.9115 7 12.5C7 14.0884 7.67337 15.5196 8.75024 16.5236C9.06624 16.8182 9.53846 16.5683 9.53846 16.1363C9.53846 15.9786 9.46909 15.8299 9.35629 15.7198C8.51949 14.9026 8 13.762 8 12.5C8 11.238 8.51949 10.0973 9.35629 9.28019C9.46909 9.17004 9.53846 9.02136 9.53846 8.8637ZM15.4615 16.1363C15.4615 15.9786 15.5309 15.8299 15.6437 15.7198C16.4805 14.9026 17 13.762 17 12.5C17 11.238 16.4805 10.0973 15.6437 9.28019C15.5309 9.17004 15.4615 9.02136 15.4615 8.8637C15.4615 8.43166 15.9338 8.18175 16.2498 8.47637C17.3266 9.48038 18 10.9115 18 12.5C18 14.0884 17.3266 15.5196 16.2498 16.5236C15.9338 16.8182 15.4615 16.5683 15.4615 16.1363ZM12.5 14C13.3284 14 14 13.3284 14 12.5C14 11.6716 13.3284 11 12.5 11C11.6716 11 11 11.6716 11 12.5C11 13.3284 11.6716 14 12.5 14Z"
        fill="currentColor"
      />
    ),
  }),
};
