import { action, observable, reaction } from "mobx";
import { reportError } from "../base/utils/errors.utils";
import {
  clearLocalStorage,
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../utils/localStorage.utils";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";

export type StorageControllerOptions = {
  separator?: string;
};

export type StorageStoreSetterOptions = {
  expireAfter?: string;
  // clearAfterSession?: boolean,
};

export const makeStorageController = (options?: StorageControllerOptions) => {
  const { separator = "::" } = options || {};

  const makeKey = action((...keys: (string | string[])[]) => {
    try {
      return [...keys.flat()].join(separator);
    } catch (e) {
      return "";
    }
  });

  const makePublicKey = action((...keys: (string | string[])[]) => {
    return ["PUBLIC", ...keys.flat()].join(separator);
  });

  const store = <T>(
    key: string,
    value: T,
    options: StorageStoreSetterOptions = {}
  ) => {
    try {
      return setLocalStorageItem(key, value);
    } catch (e) {
      reportError(e);
      return null;
    }
  };

  const c = observable({
    ...makeControllerBase("STORAGE"),

    get: <T>(...keys: (string | string[])[]) => {
      const key = makeKey(...keys.flat());
      const value = getLocalStorageItem<string>(key);
      try {
        if (value !== null) return value as T;
        const publicKey = makePublicKey(...keys.flat());
        const publicValue = getLocalStorageItem<string>(publicKey);
        return (publicValue ?? value) as T;
      } catch (e) {
        reportError(e);
        return null;
      }
    },
    set: <T>(
      keys: string[],
      valueSource: T,
      options: StorageStoreSetterOptions = {}
    ) => {
      const key = makeKey(...keys);
      return store(key, valueSource, options);
    },
    sync: <T>(
      keys: string[],
      valueGetter: () => T,
      valueSetter: (v: T) => void
    ) => {
      const existingValue = c.get<T>(keys);
      if (existingValue !== null) valueSetter(existingValue);
      return reaction(
        valueGetter,
        value => {
          c.set(keys, value);
        },
        { fireImmediately: true }
      );
    },
    remove: (...keys: (string | string[])[]) => {
      const key = makeKey(...keys);
      const publicKey = makePublicKey(...keys);
      removeLocalStorageItem(key);
      removeLocalStorageItem(publicKey);
    },
    removeByNamespace: (...keys: (string | string[])[]) => {
      const key = makeKey(...keys);
      return removeLocalStorageItem(key);
    },
    clear: () => {
      clearLocalStorage();
    },
  });

  const watchForStorageChanges = () => {
    const handler = (e: StorageEvent) => {
      if (e.key?.match(/_cap_[^:]*::auth/)) {
        console.warn("Auth related storage key changed externally");
        if (e.key.includes("logout") && e.newValue === "true") {
          const AUTH = c.ROOT?.AUTH;
          if (AUTH?.isAuthenticated) {
            AUTH.logout();
            window.removeEventListener("storage", handler);
          }
        }
      }
    };
    window.addEventListener("storage", handler);
  };

  c.init = makeRootControllerChildInitFn(
    c,
    action(() => {
      watchForStorageChanges();
      c.ready = true;
    })
  );

  return c;
};

export type StorageController = ReturnType<typeof makeStorageController>;
