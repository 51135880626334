import { sortByLength } from "../base/utils/array.utils";
import { ArrayElementType } from "../base/@types";

export const flatAccidentals = ["flat", "f", "b", "♭"] as const;
export const doubleFlatAccidentals = ["doubleflat", "df", "𝄫"] as const;
export const sharpAccidentals = ["sharp", "s", "#", "♯"] as const;
export const doubleSharpAccidentals = ["doublesharp", "ds", "𝄪"] as const;

export const accidentalValueMap = new Map([
  ["-1", [...flatAccidentals]],
  ["-2", [...doubleFlatAccidentals]],
  ["+1", [...sharpAccidentals]],
  ["+2", [...doubleSharpAccidentals]],
]);

export type FlatAccidental = ArrayElementType<typeof flatAccidentals>;
export type DoubleFlatAccidental = ArrayElementType<
  typeof doubleFlatAccidentals
>;
export type SharpAccidental = ArrayElementType<typeof sharpAccidentals>;
export type DoubleSharpAccidental = ArrayElementType<
  typeof doubleSharpAccidentals
>;

export const allAccidentals = [
  ...flatAccidentals,
  ...doubleFlatAccidentals,
  ...sharpAccidentals,
  ...doubleSharpAccidentals,
] as const;

export const allAccidentalsSortedByLength = sortByLength([...allAccidentals]);

export type Accidental = ArrayElementType<typeof allAccidentals>;
