import { action } from "mobx";
import { ContextColorName, HexOrContextColorName } from "../@types";
import { isCSSColorKeyword } from "../utils/colors.utils";
import { useStore } from "../utils/mobx.utils";
import { useRootController } from "./rootContext.hooks";

export const useThemeController = action(() => useRootController().THEME);

export const useThemeColors = action(
  () => useThemeController().themeVariant.colors
);
export const useContrastContextColors = action(
  () => useThemeController().contrastContextColors
);
export const useThemeColorsWithContrastColors = action(
  () => useThemeController().contextColorsWithContrastColors
);

export const useGetThemeColor = action(
  (colorName: ContextColorName) => useThemeColorsWithContrastColors()[colorName]
);

export type ColorDefSet = {
  color: string;
  contrastColor: string | undefined;
};

export const useGetColorFromString = (
  c: () => HexOrContextColorName | null | undefined,
  fallback?: () => HexOrContextColorName | null
): ColorDefSet => {
  const THEME = useThemeController();
  const withContrastColors = useThemeColorsWithContrastColors();

  const s = useStore(() => ({
    get _color() {
      return c() || fallback?.() || "primary";
    },
    get color(): string {
      if (isCSSColorKeyword(s._color)) return s._color;
      return (
        withContrastColors[s._color as ContextColorName]?.color || s._color
      );
    },
    get contrastColor(): string | undefined {
      if (!s.color) return withContrastColors.primary.contrast;
      if (isCSSColorKeyword(s.color)) return undefined;
      if (s.color in withContrastColors)
        return THEME.getContrastColor(
          withContrastColors[s.color as ContextColorName].color
        );
      if (s.color in withContrastColors)
        return withContrastColors[s.color as ContextColorName].contrast;
      return THEME.getContrastColor(s.color);
    },
  }));

  return s;
};
