import { makeIconDef } from "./makeIconDef";

export const QuillIconDef = {
  quill: makeIconDef("quill", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.372 4.18698L14.372 4.18697C14.5138 4.05329 14.6505 3.92428 14.7795 3.79528C19.5984 1.65356 23.1364 4.21539 21.7402 7.00788C20.5979 9.29238 18.7449 9.51238 16.0368 9.8339L16.0368 9.83391C15.3916 9.9105 14.6979 9.99286 13.9537 10.1103C15.8196 10.4806 17.3903 10.5844 20.0335 10.6388C18.4203 12.6169 16.4654 12.793 14.5736 12.9634C13.5682 13.054 12.5806 13.1429 11.6716 13.5H15.8505C13.843 16.0094 11.8355 16.5783 8.94605 16.6397C9.54684 17.1043 10.3447 17.483 11.5131 17.9313C9.87831 18.2297 8.82849 18.1798 7.64627 17.7974C8.01555 18.3786 8.50186 18.8906 9.45866 19.3898C8.06179 19.0998 7.16311 18.976 6.45198 19.1653C8.16658 14.9969 12.5172 8.12195 18.5946 5.40157C11.132 8.0668 7.28452 15.0437 5.13714 19.8606C4.06934 20.3983 3.53543 22 3.53543 22H3C3.53447 20.6638 4.09858 19.3869 4.70219 18.1767C4.81225 17.5565 4.5263 17.1011 4.07087 16.6457H5.16967C4.58895 15.792 4.22642 14.5784 4.49648 13.2657C4.78582 13.9679 5.12877 14.2476 5.51724 14.359C5.61507 12.0994 6.41943 9.68541 7.99997 8.5L7.99997 11.9476C8.65708 8.87122 9.9831 6.27898 12.1024 4.86614C11.7252 6.37485 11.6137 7.61786 11.7679 8.59518C12.3427 6.10098 13.4601 5.04711 14.372 4.18698Z"
        fill="currentColor"
      />
    ),
  }),
};
