import styled from "@emotion/styled";
import { Observer } from "mobx-react-lite";
import { Instrument, StandardInstrumentOptions } from "../../@types";
import IndicatorLight from "../../base/components/IndicatorLight";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { PedalIconPed } from "../../base/icons/PedalIconPed";
import { useProps, useStore } from "../../base/utils/mobx.utils";

type Props<O extends StandardInstrumentOptions = StandardInstrumentOptions> = {
  instrument?: Instrument<O>;
};

const SustainPenalStatusIndicatorWrap = styled.div`
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
`;

const SustainPenalStatusIndicator = <
  O extends StandardInstrumentOptions = StandardInstrumentOptions
>(
  props: Props<O>
) => {
  const { ENSEMBLE } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get on() {
      return p.instrument
        ? p.instrument.sustainPedalIsDown
        : ENSEMBLE._instrumentArray.some(ins => ins.sustainPedalIsDown);
    },
  }));
  return (
    <Observer
      children={() => (
        <SustainPenalStatusIndicatorWrap>
          <IndicatorLight on={s.on} />
          <PedalIconPed />
        </SustainPenalStatusIndicatorWrap>
      )}
    />
  );
};

export default SustainPenalStatusIndicator;
