/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import DateTimeFormatter from "../../base/components/DateTimeFormatter";
import { useProps } from "../../base/utils/mobx.utils";
import { Composition } from "../../models/Composition.model";
import {
  DataTableAction,
  DataTableActionsCell,
} from "../../pages/Admin/components/DataTable";
import OwnerLink from "../../pages/Admin/components/OwnerLink";
import cx from "../../base/utils/className.utils";
import Button from "../../base/components/Button";
import ArtistEntry from "../directory/ArtistEntry";
import styled from "@emotion/styled";
import { bg, fg10 } from "../../constants/cssCustomProperties.constants";
import { hideScrollbarsCSSString } from "../../base/styles/partials/scrollable.stylePartials";
import CompositionTitle from "../composer/CompositionTitle";

type P = {
  composition: Composition;
  index: number;
  viewAsAdmin?: boolean;
  showArchiveDate?: boolean;
  showArtists?: boolean;
  actions?: DataTableAction<Composition>[];
};

const Preview = styled.div`
  width: 8em;
  height: 3em;
  position: relative;
  overflow: auto;
  display: flex;
  align-items: center;
  padding: 0.5em;
  border-radius: 3px;
  transition: 0.2s;
  border: 1px solid ${fg10};
  ${hideScrollbarsCSSString()}
  &:hover {
    transform: scale(1.5);
    background-color: ${bg};
  }
  img {
    display: block;
    max-height: 100%;
  }
`;

const CompositionTableRow: React.FC<P> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <tr className={cx(p.composition.isArchived && "archived")}>
          <td>{p.index + 1}</td>
          <td>
            <Preview>
              {p.composition.thumbnailDark?.$.url && (
                <img src={p.composition.thumbnailDark.$.url} />
              )}
            </Preview>
          </td>
          <th>
            <h4>
              {p.viewAsAdmin ? (
                <Link to={`/admin/compositions/${p.composition._id}`}>
                  <CompositionTitle composition={p.composition} />
                </Link>
              ) : (
                <CompositionTitle composition={p.composition} />
              )}
            </h4>
          </th>
          <td>{p.composition.$.interpretationIds.length}</td>
          <td>{p.composition.$.scoreIds.length}</td>
          {p.viewAsAdmin && (
            <td>
              {p.composition.$.ownerId && (
                <OwnerLink userId={p.composition.$.ownerId} />
              )}
            </td>
          )}
          {p.showArtists && (
            <td>
              {p.composition.artists.map(a => (
                <ArtistEntry
                  asLink
                  key={a._id}
                  artist={a}
                  linkTo="admin"
                  appearance="entry"
                  photoSize="2em"
                />
              ))}
            </td>
          )}
          <td>
            <DateTimeFormatter
              value={p.composition.$.timeCreated}
              format="YYYY-MM-DD"
            />
          </td>
          <td>
            <DateTimeFormatter
              value={p.composition.$.timeUpdated}
              format="YYYY-MM-DD"
            />
          </td>
          {p.showArchiveDate && (
            <td>
              <DateTimeFormatter
                value={p.composition.$.timeArchived}
                format="YYYY-MM-DD"
              />
            </td>
          )}
          <DataTableActionsCell>
            {p.viewAsAdmin && (
              <Link to={`/admin/compositions/${p.composition._id}`}>
                Details
              </Link>
            )}
            {
              !p.composition.$.timeArchived ? (
                <>
                  <Link to={`/compose/${p.composition._id}`}>
                    Open in Composer
                  </Link>
                  <Link to={`/play/${p.composition._id}`}>Play</Link>
                </>
              ) : null // TODO: unarchive / delete permanently
            }
            {p.actions?.map(a => (
              <Button
                key={a.label}
                appearance="text"
                onClick={() => {
                  a.action(p.composition);
                }}
              >
                {a.label}
              </Button>
            ))}
          </DataTableActionsCell>
        </tr>
      )}
    />
  );
};

export default CompositionTableRow;
