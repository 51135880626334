/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { prop } from "../utils/ramdaEquivalents.utils";

const AllCaps = (props: React.PropsWithChildren) => {
  return (
    <Observer
      children={() => (
        <div
          css={{
            textTransform: "uppercase",
            fontWeight: 700,
            fontSize: "82.5%",
          }}
          {...prop}
        >
          {props.children}
        </div>
      )}
    />
  );
};

export default AllCaps;
