/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import {
  VAR_BlurBackdropFilter,
  VAR_PanelDockBottomHeight,
  bg,
  fg,
  fg10,
  varPrimary,
  varPrimaryContrast,
} from "../../constants/cssCustomProperties.constants";
import SymbolIcon from "../../base/components/SymbolIcon";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { mediaFromTablet } from "../../base/styles/helpers/mediaQueries.styling";
import { supportsBackdropBlur } from "../../base/styles/helpers/supports.cssHelpers";
import { cVar } from "../../base/utils/customProperties.utils";
import { useStore } from "../../base/utils/mobx.utils";
import PlayStateHighlighter from "../shared/PlayStateHighlighter";
import cx from "../../base/utils/className.utils";

type Props = {
  float?: boolean;
  presentational?: boolean;
};

const buttonStyle: CSSPartial = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  appearance: "none",
  backgroundColor: varPrimary,
  backdropFilter: cVar(VAR_BlurBackdropFilter),
  color: varPrimaryContrast,
  border: 0,
  position: "relative",
  "&.presentational": {
    pointerEvents: "none",
  },
  "&.float": {
    position: "fixed",
    border: 0,
    bottom: `calc(${cVar(VAR_PanelDockBottomHeight)} + 16px)`,
    right: 16,
    ...mediaFromTablet({
      bottom: `calc(${cVar(VAR_PanelDockBottomHeight)} + 28px)`,
      right: 28,
    }),
  },
  borderRadius: "50%",
  width: "3em",
  height: "3em",
  ...mediaFromTablet({
    width: "5em",
    height: "5em",
  }),
  transition: ".1s",
  "&:hover": {
    filter: "brightness(1.1)",
  },
  "&:active": {
    filter: "brightness(.9)",
  },
  "&.playing": {
    color: fg,
    backgroundColor: bg,
    [supportsBackdropBlur]: {
      backgroundColor: fg10,
    },
  },
  "&:focus": {
    outline: "none",
  },
  svg: {
    position: "relative",
    zIndex: 1,
  },
};

const FlashyPlayButton: React.FC<Props> = props => {
  const { ENSEMBLE } = useControllers();

  const s = useStore(() => ({
    get playButtonIcon() {
      return ENSEMBLE.isPlaying ? "pause" : "play";
    },
  }));
  return (
    <Observer
      children={() => (
        <button
          className={cx(
            props.float && "float",
            ENSEMBLE.isPlaying ? "playing" : "paused",
            props.presentational && "presentational"
          )}
          css={buttonStyle}
          onClick={props.presentational ? undefined : ENSEMBLE.togglePlayState}
          type="button"
        >
          {!props.presentational && (
            <PlayStateHighlighter
              borderRadius="50%"
              gradientOpacityMap={[0.9, 0.5]}
            />
          )}
          <SymbolIcon icon={s.playButtonIcon} size="32px" />
        </button>
      )}
    />
  );
};

export default FlashyPlayButton;
