/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSSPartial } from "../../base/@types/css.types";
import ContextMenu, {
  ContextMenuDecoration,
  ContextMenuItemConfig,
  useContextMenuController,
} from "../../base/components/ContextMenu/ContextMenu";
import {
  fg02,
  fg10,
  varFontSerif,
  varPrimary,
  varPrimary05,
} from "../../constants/cssCustomProperties.constants";
import DateTimeFormatter from "../../base/components/DateTimeFormatter";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Composition } from "../../models/Composition.model";
import { ComposerAppUIMode } from "../composer/useCreateComposerInstance";
import { mediaFromTablet } from "../../base/styles/helpers/mediaQueries.styling";
import { autoPluralizeWithNumber } from "../../base/utils/string.utils";
import CollectionName from "./CollectionName";
import CompositionThumbnail from "../shared/CompositionThumbnail";
import FlashyPlayButton from "../player/FlashyPlayButton";
import { action } from "mobx";
import CompositionEditorPanel from "../panels/CompositionEditorPanel";
import { UNITS } from "../../base/constants/units.constant";
import CompositionTitle from "../composer/CompositionTitle";
import { makeSlug } from "../../base/utils/slug.utils";

interface CompositionEntryProps {
  className?: string;
  composition: Composition;
  mode: ComposerAppUIMode | "static" | "admin";
  height?: string | number;
  thumbnailHeight?: string | number;
  titleFontSize?: string | number;
  showPrimaryCollection?: boolean;
  enableContextMenu?: boolean;
  contextMenuConfigSetFactory?: (c: Composition) => ContextMenuItemConfig[];
}

const style = {
  component: {
    appearance: "none",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    color: "inherit",
    textDecoration: "none",
    "&:before": {
      ...mediaFromTablet({
        content: '""',
      }),
      display: "block",
      position: "absolute",
      top: "-.5em",
      left: "-.5em",
      right: "-.5em",
      bottom: "-.5em",
      backgroundColor: fg02,
      border: `${UNITS.lineWidth}px solid ${fg10}`,
      opacity: 0,
      transition: ".2s",
      borderRadius: ".2em",
    },
    "&:focus, &.menuOpen": {
      color: varPrimary,
      "&:before": {
        borderColor: varPrimary,
        backgroundColor: varPrimary05,
      },
    },
    "&:hover, &:focus, &.menuOpen": {
      "&:before": {
        opacity: 1,
        top: "-1em",
        left: "-1em",
        right: "-1em",
        bottom: "-1em",
        transition: ".1s",
      },
      ".PlayButtonWrap": {
        opacity: 1,
        transform: "scale(1)",
      },
    },
    "&:active": {
      filter: "brightness(.9)",
    },
  } as CSSPartial,
  figure: {
    position: "relative",
    padding: "1em 0 2em 0",
    flex: "1 1 auto",
  } as CSSPartial,
  header: {
    position: "relative",
    padding: "0",
    lineHeight: 1.35,
    h3: {
      fontFamily: varFontSerif,
      fontSize: "var(--titleFontSize, 2rem)",
      ...mediaFromTablet({
        fontSize: "var(--titleFontSize, 2.2rem)",
      }),
      fontWeight: 200,
      lineHeight: 1.1,
      paddingRight: "10%",
      "+ *": {
        marginTop: ".6rem",
      },
    },
  } as CSSPartial,
  meta: {
    opacity: 0.4,
    fontSize: "1.2rem",
  } as CSSPartial,
  collectionAndArtists: {
    fontWeight: 500,
    opacity: 0.6,
    fontSize: "1.4rem",
  } as CSSPartial,
  playButtonWrap: {
    position: "absolute",
    bottom: 0,
    right: 0,
    opacity: 0,
    transform: "scale(0.6)",
    transition: ".2s",
    button: {
      width: "4em",
      height: "4em",
    },
  } as CSSPartial,
};

const CompositionEntry: React.FC<CompositionEntryProps> = props => {
  const { DIALOGS, COMPOSITIONS, AUTH, PORTALS, KEYBOARD } = useControllers();
  const navigate = useNavigate();

  const p = useProps(props);
  const s = useStore(() => ({
    get mode() {
      if (p.mode) return p.mode;
      if (AUTH.user && p.composition.$.ownerId === AUTH.user._id) return "edit";
      return null;
    },
    get className() {
      return cx(
        "CompositionEntry",
        contextMenu.isOpen && "menuOpen",
        p.className
      );
    },
    get editUrl() {
      return `/compose/${p.composition._id}`;
    },
    get playUrl() {
      return `/play/${p.composition._id}?i=${makeSlug(p.composition.$.title)}`;
    },
    get defaultUrl() {
      if (s.mode === "editor") return s.editUrl;
      else if (s.mode === "player") return s.playUrl;
      return "";
    },
    archiveComposition: async () => {
      await COMPOSITIONS.archive(p.composition);
    },
    deleteComposition: async () => {
      const confirm = await DIALOGS.attention({
        Heading: "Confirm",
        Body: `Are you sure you want to permanently delete the composition "${p.composition.titlePlaintext}"?`,
      });
      if (!confirm) return;
      await COMPOSITIONS.delete(p.composition);
    },
    duplicateComposition: async () => {
      return await COMPOSITIONS.duplicate(p.composition);
    },
    exportComposition: async () => {
      await COMPOSITIONS.export(p.composition._id);
    },
    get shouldShowCollectionNames() {
      return p.showPrimaryCollection && p.composition.collections.length > 0;
    },
    get shouldShowArtistNames() {
      return p.composition.artists.length > 0;
    },
    get style() {
      return {
        "--titleFontSize": p.titleFontSize,
      } as React.CSSProperties;
    },
    handleBeforeNavigate: () => {
      if (KEYBOARD.pressed.metaOrCtrl) return;
      window.ProgressBar?.reset();
    },
    showQuickEditor: false,
    closeQuickEditor: () => {
      s.showQuickEditor = false;
    },
    saveAndCloseQuickEditor: async () => {
      await COMPOSITIONS.save(p.composition, { metaOnly: true });
      s.closeQuickEditor();
    },
    get inner(): ReactNode {
      return (
        <>
          <CompositionThumbnail
            composition={p.composition}
            css={style.figure}
          />
          <header css={style.header}>
            <h3>
              <CompositionTitle composition={p.composition} />
            </h3>
            <p css={style.collectionAndArtists}>
              {s.shouldShowCollectionNames &&
                p.composition.collections
                  .slice(0, p.showPrimaryCollection ? 1 : undefined)
                  .map(c => (
                    <CollectionName
                      key={c._id}
                      collection={c}
                      shouldShowCatalogueNumber
                    />
                  ))}
              {s.shouldShowCollectionNames && s.shouldShowArtistNames && " • "}
              {s.shouldShowArtistNames && (
                <>
                  {p.composition.artists
                    .map(artist => artist.fullName)
                    .join(", ")}
                </>
              )}
            </p>
            {s.mode === "editor" && (
              <p css={style.meta}>
                {p.composition.$.interpretationIds.length > 1 && (
                  <>
                    <span>
                      {autoPluralizeWithNumber(
                        p.composition.$.interpretationIds,
                        "Interpretation"
                      )}
                    </span>{" "}
                    •{" "}
                  </>
                )}
                <span>
                  Last edited:{" "}
                  <DateTimeFormatter
                    value={p.composition.$.timeUpdated}
                    format="LL"
                  />
                </span>
              </p>
            )}
            {s.mode === "player" && (
              <div className="PlayButtonWrap" css={style.playButtonWrap}>
                <FlashyPlayButton presentational />
              </div>
            )}
          </header>
          {contextMenu.isOpen && (
            <ContextMenu
              controller={contextMenu}
              onShouldClose={() => contextMenu.close()}
            />
          )}
        </>
      );
    },
  }));

  const contextMenu = useContextMenuController(
    action(() => {
      return {
        configSet: p.contextMenuConfigSetFactory?.(p.composition) ?? [
          {
            identifier: "open",
            Label: "Open",
            action: () => navigate(s.defaultUrl),
          },
          ...(s.mode === "edit"
            ? [
                {
                  identifier: "open-play-mode",
                  Label: "Open in play mode",
                  action: () => s.playUrl,
                },
                {
                  identifier: "quick-edit",
                  Label: "Quick edit...",
                  action: action(() => {
                    s.showQuickEditor = true;
                  }),
                },
              ]
            : []),
          {
            identifier: "export",
            Label: "Export as JSON",
            action: s.exportComposition,
          },
          {
            identifier: "duplicate",
            Label: "Make a copy",
            action: s.duplicateComposition,
          },
          ContextMenuDecoration.separator,
          {
            identifier: "archive",
            Label: "Archive",
            action: s.archiveComposition,
          },
          {
            identifier: "delete",
            Label: "Delete permanently",
            action: s.deleteComposition,
          },
        ],
      };
    })()
  );

  return (
    <Observer
      children={() => (
        <>
          {s.defaultUrl ? (
            <Link
              className={s.className}
              to={s.defaultUrl}
              css={style.component}
              onContextMenu={
                AUTH.isAuthenticated &&
                !!(p.contextMenuConfigSetFactory || p.enableContextMenu)
                  ? contextMenu.toggle
                  : undefined
              }
              style={s.style}
              onClick={s.handleBeforeNavigate}
            >
              {s.inner}
            </Link>
          ) : (
            <div
              className={s.className}
              css={style.component}
              style={s.style}
              // onContextMenu={contextMenu.toggle}
            >
              {s.inner}
            </div>
          )}
          {s.showQuickEditor &&
            PORTALS.render(
              <CompositionEditorPanel
                composition={p.composition}
                onShouldClose={s.closeQuickEditor}
                onSave={s.saveAndCloseQuickEditor}
              />
            )}
        </>
      )}
    />
  );
};

export default CompositionEntry;
