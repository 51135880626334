/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Composition } from "../../models/Composition.model";
import CompositionTableRow from "./CompositionTableRow";
import DataTable, {
  DataTableAction,
} from "../../pages/Admin/components/DataTable";
import cx from "../../base/utils/className.utils";
import { CSSPartial } from "../../base/@types/css.types";

type P = {
  compositions: Composition[];
  viewAsAdmin?: boolean;
  showArtists?: boolean;
  actions?: DataTableAction<Composition>[];
};

const style = {
  tbody: {
    "&.someArchived": {
      "tr.archived": {
        opacity: 0.5,
        "&:hover": {
          opacity: 1,
        },
      },
    },
  } as CSSPartial,
};

const CompositionTable: React.FC<P> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get anyArchived() {
      return p.compositions.some(c => !!c.$.timeArchived);
    },
    get someArchived() {
      return s.anyArchived && !s.allArchived;
    },
    get allArchived() {
      return p.compositions.every(c => !!c.$.timeArchived);
    },
  }));
  return (
    <Observer
      children={() => (
        <DataTable>
          <thead>
            <tr>
              <th>No.</th>
              <th>Preview</th>
              <th>Title</th>
              <th>Interpretations</th>
              <th>Scores</th>
              {p.viewAsAdmin && <th>Owner</th>}
              {p.showArtists && <th>Artists</th>}
              <th>Created on</th>
              <th>Last updated</th>
              {s.anyArchived && <th>Archived on</th>}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody
            css={style.tbody}
            className={cx(s.someArchived && "someArchived")}
          >
            {p.compositions.map((c, i) => (
              <CompositionTableRow
                composition={c}
                key={c._id}
                index={i}
                showArchiveDate={s.anyArchived}
                viewAsAdmin={p.viewAsAdmin}
                showArtists={p.viewAsAdmin}
                actions={p.actions}
              />
            ))}
          </tbody>
        </DataTable>
      )}
    />
  );
};

export default CompositionTable;
