/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Atom } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import Button from "../../base/components/Button";
import {
  VAR_PanelBorder,
  VAR_SeparatorBorder,
  fg02,
} from "../../constants/cssCustomProperties.constants";
import { cVar } from "../../base/utils/customProperties.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { useComposer, useInterpreter } from "./ComposerApp.context";
import RuleEditor from "./RuleEditor";
import { RuleController } from "../../logic/interpreterRule.controller";
import { first, uniq } from "../../base/utils/ramdaEquivalents.utils";
import InterpretationMark from "../shared/InterpretationMark";
import { reverse } from "lodash-es";
import { paths } from "../../base/constants/paths.constants";
import { ExternalLinkIcon } from "../../base/components/ExternalLinkIcon";
import { externalLinkAttrs } from "../../base/utils/links.utils";

type AtomRulesEditorProps = {
  atom?: Atom;
  atoms?: Atom[];
};

const style = {
  editor: {
    position: "relative",
    backgroundColor: fg02,
    border: cVar(VAR_PanelBorder),
  } as CSSPartial,
  header: {
    position: "relative",
    padding: "1em",
    backgroundColor: fg02,
    h3: {
      fontSize: "inherit",
    },
    "* + *": {
      marginTop: ".5em",
    },
    p: {
      opacity: 0.7,
    },
  } as CSSPartial,
  content: {
    "> *": {
      borderBottom: cVar(VAR_SeparatorBorder),
    },
  } as CSSPartial,
  buttonSection: {
    // display: "grid",
    // gridTemplateColumns: "1fr 1fr",
    // gridGap: ".5em",
    padding: ".5em",
  } as CSSPartial,
};

const AtomRulesetEditor: React.FC<AtomRulesEditorProps> = props => {
  const p = useProps(props);
  const interpreter = useInterpreter();
  const I = useComposer();
  const s = useStore(() => ({
    get atoms() {
      return [p.atom, ...(p.atoms ?? [])].filter(i => i) as Atom[];
    },
    get color(): string | null {
      const firstAtomColor = first(s.atoms)!.appearance?.colorInContext;
      return firstAtomColor
        ? s.atoms.every(a => a.appearance?.colorInContext === firstAtomColor)
          ? firstAtomColor
          : null
        : null;
    },
    get rulesOrderedByPriority(): RuleController[] {
      if (s.atoms.length === 1) return reverse([...s.atoms[0].rulesRecursive]);
      return [...uniq(s.atoms.map(a => a.rulesRecursive).flat())].sort(
        (a, b) => +b._id - +a._id
      );
    },
    addNewRule: () => {
      I.runInHistory("Add rule", () => {
        interpreter?.findOrCreateRuleWithRuleSnapshot(
          {
            selector: s.atoms.map(a => a._id).join(", "),
          },
          { alwaysCreate: true }
        );
      });
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="AtomRulesetEditor" css={style.editor}>
          <InterpretationMark color={s.color} />
          <header css={style.header}>
            <h3>Interpretation rules</h3>
            {s.rulesOrderedByPriority.length > 1 && (
              <p>
                Values in newer rules overrides older rules, and values in more
                specific rules overrides broader selectors.{" "}
                <a href={paths.learnInterpretations} {...externalLinkAttrs}>
                  Learn more <ExternalLinkIcon />
                </a>
                .
              </p>
            )}
            {I.editDisabled && s.rulesOrderedByPriority.length === 0 && (
              <p>This element has no interpretation rules.</p>
            )}
          </header>
          {I.editable && (
            <div css={style.buttonSection}>
              <Button
                colorMode="translucent"
                onClick={s.addNewRule}
                fullWidth
                children={`+ Add rule`}
              />
            </div>
          )}
          <div css={style.content}>
            {s.rulesOrderedByPriority.map((rule, i) => (
              <RuleEditor
                key={rule._id}
                rule={rule}
                openOnMount={i === 0 && p.atom?.ownRules.includes(rule)}
              />
            ))}
          </div>
        </div>
      )}
    />
  );
};

export default AtomRulesetEditor;
