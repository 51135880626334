import { AxiosError } from "axios";
import saveAs from "file-saver";
import { flow, observable, runInAction } from "mobx";
import { removeOneFromArray } from "../../base/utils/array.utils";
import { isAccessDeniedError } from "../../base/utils/errors.utils";
import { convertRecordMapToArray } from "../../base/utils/map.utils";
import { makeSlug } from "../../base/utils/slug.utils";
import {
  getMinimizedCompositionSnapshot,
  getSnapshot,
  makeSnapshot,
  minimizeInterpretationSnapshot,
} from "../../base/utils/snapshot.utils";
import { isString } from "../../base/utils/typeChecks.utils";
import { ModelName } from "../../constants/modelName.constants";
import { SELECTION_GROUP_ID } from "../../constants/selection.constants";
import {
  Composition,
  CompositionSnapshot,
  CompositionSnapshotFactory,
} from "../../models/Composition.model";
import { ApiController, GetRequestOptions } from "../api.controller";
import { LocalDBController } from "../localDB.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "../_root.controller";
import { ExportJsonSchema, Package } from "../../models/Package.model";

export const makeCompositionsController = () => {
  const s = observable({
    ...makeControllerBase("COMPOSITIONS"),

    get API(): ApiController {
      return s.ROOT!.API;
    },
    get LOCALDB(): LocalDBController {
      return s.ROOT!.LOCALDB;
    },
    get all(): Composition[] {
      return convertRecordMapToArray(s.LOCALDB.data.compositions);
    },
    get allNonArchived(): Composition[] {
      return s.all.filter(comp => !comp.$.timeArchived);
    },
    get allArchived(): Composition[] {
      return s.all.filter(comp => !!comp.$.timeArchived);
    },
    get own(): Composition[] {
      return s.all.filter(c => c.owner === s.ROOT!.AUTH.user);
    },
    get ownNonArchived(): Composition[] {
      return s.own.filter(c => !c.$.timeArchived);
    },
    get ownArchived(): Composition[] {
      return s.own.filter(c => !!c.$.timeArchived);
    },

    create: async (template?: Partial<CompositionSnapshot>) => {
      const snapshot = makeSnapshot(CompositionSnapshotFactory, {
        ...template,
      });
      if (!snapshot.title) snapshot.title = "Untitled";
      return await s.API.post<Composition>(
        "/compositions",
        ModelName.compositions,
        snapshot
      );
    },

    get: (id: string, options?: GetRequestOptions): Promise<Composition> =>
      flow(function* () {
        // console.info(`Retrieving comp #${id}...`);
        const comp: Composition = yield s.API.get<Composition>(
          "/compositions/" + id,
          ModelName.compositions,
          options
        );
        return comp;
      })(),

    getAllOwnMeta: (): Promise<Composition[]> =>
      flow(function* () {
        const compositions: Composition[] = yield s.API.getMany<Composition>(
          "/compositions",
          ModelName.compositions,
          {
            select: "-atomSnapshots",
          }
        );
        return compositions;
      })(),

    getAllPublicMeta: (): Promise<Composition[]> =>
      flow(function* () {
        const compositions: Composition[] = yield s.API.getMany<Composition>(
          "/public/compositions",
          ModelName.compositions,
          { select: "-atomSnapshots" }
        );
        return compositions;
      })(),

    getAllArchivedMeta: (): Promise<Composition[]> =>
      flow(function* () {
        const compositions: Composition[] = yield s.API.getMany<Composition>(
          "/compositions/archived",
          ModelName.compositions,
          { select: "-atomSnapshots" }
        );
        return compositions;
      })(),

    getProcessedSnapshotBeforeSave: (
      comp: Composition | CompositionSnapshot,
      o?: { metaOnly?: boolean }
    ) => {
      const snapshot = getSnapshot<CompositionSnapshot>(comp);
      if ((comp as Composition).atomContext) {
        snapshot._duration = (comp as Composition).atomContext?.duration ?? 0;
      }
      if (o?.metaOnly) Reflect.deleteProperty(snapshot, "atomSnapshots");
      else
        snapshot.atomSnapshots.forEach(n => {
          removeOneFromArray(n.parentIds, SELECTION_GROUP_ID);
        });
      return snapshot;
    },

    save: (
      comp: Composition | CompositionSnapshot,
      o?: { metaOnly?: boolean }
    ) =>
      new Promise<Composition>((resolve, reject) =>
        flow(function* () {
          const progress = s.ROOT!.STATUS.registerProgress(
            "Saving composition..."
          );
          try {
            const snapshot = s.getProcessedSnapshotBeforeSave(comp, o);
            const id = snapshot._id || "";
            const savedComp: Composition = yield s.API[id ? "patch" : "post"](
              `/compositions/${id}`,
              ModelName.compositions,
              snapshot
            );
            resolve(savedComp);
          } catch (e) {
            reject(e);
          } finally {
            progress.complete();
          }
        })()
      ),

    saveSelfAndRelatedRecords: (comp: Composition) =>
      new Promise<Composition>((resolve, reject) =>
        flow(function* () {
          if (!comp.atomContext) {
            throw Error("Unexpectedly saving comp without an AtomContext");
          }
          const progress = s.ROOT!.STATUS.registerProgress(
            "Saving composition..."
          );
          try {
            const snapshot: CompositionSnapshot =
              yield s.getProcessedSnapshotBeforeSave(comp);
            const id = snapshot._id;
            if (!id) {
              throw Error(
                "COMPOSITIONS.saveSelfAndRelatedRecords is not yet available for new compositions"
              );
            }
            const currentInterpretationId =
              s.ROOT?.COMPOSER.instance?.interpreter?.interpretation._id;
            const payload = {
              composition: getMinimizedCompositionSnapshot(snapshot),
              interpretations: comp.interpretations.map(rec => {
                const $ = minimizeInterpretationSnapshot(rec.$);
                if (
                  currentInterpretationId &&
                  rec._id === currentInterpretationId
                ) {
                  $._duration = s.ROOT?.ENSEMBLE?.playback?.totalDuration ?? 0;
                }
                return $;
              }),
              scores: comp.scores.map(rec => rec.$),
            };
            yield s.API.patch(
              `/compositions/${id}/save`,
              ModelName.compositions,
              payload
            );
            if (comp.atomContext.interpretation)
              yield s.ROOT?.INTERPRETATIONS.updateThumbnailSet(
                comp.atomContext.interpretation
              );
            resolve(comp);
          } catch (e) {
            console.error(e);
            reject(e);
          } finally {
            progress.complete();
          }
        })()
      ),

    createPackage: (comp: Composition) => {
      return new Promise<Package>((resolve, reject) =>
        flow(function* () {
          try {
            const packagedComp: Package = yield s.API.post<Package>(
              `/compositions/${comp._id}/create-package`,
              ModelName.packages
            );
            comp.$.packageIds.push(packagedComp._id);
            resolve(packagedComp);
          } catch (e) {
            console.error(e);
            reject(e);
          }
        })()
      );
    },

    listPackages: (comp: Composition) =>
      s.API.getMany<Package>(
        `/compositions/${comp._id}/packages`,
        ModelName.packages
      ),

    archive: (comp: Composition) =>
      flow(function* () {
        return (yield s.API.post(
          `/compositions/${comp._id}/archive`,
          ModelName.compositions
        )) as Composition;
      })(),

    unarchive: (comp: Composition) =>
      flow(function* () {
        return (yield s.API.post(
          `/compositions/${comp._id}/unarchive`,
          ModelName.compositions
        )) as Composition;
      })(),

    delete: async (comp: Composition | string): Promise<void> => {
      const id = isString(comp) ? comp : comp._id;
      await s.API.delete(`/compositions/${id}`);
      runInAction(() => {
        if (comp instanceof Composition) {
          s.LOCALDB.remove(comp);
          s.LOCALDB.removeMany(comp.interpretations);
          s.LOCALDB.removeMany(comp.scores);
          if (comp.thumbnailDark) s.LOCALDB.remove(comp.thumbnailDark);
          if (comp.thumbnailLight) s.LOCALDB.remove(comp.thumbnailLight);
        }
      });
    },

    duplicate: async (comp: Composition | string) => {
      const id = isString(comp) ? comp : comp._id;
      return await s.API.post<Composition>(
        `/compositions/${id}/duplicate`,
        ModelName.compositions
      );
    },

    export: async (comp: Composition | string, download = true) => {
      if (!s.ROOT?.AUTH.isAuthenticated) {
        s.ROOT?.AUTH.showQuickLoginDialog(() => {
          s.export(comp, download);
        });
        return;
      }
      if (!isString(comp) && comp.$.ownerId) {
        if (comp.$.ownerId !== s.ROOT.AUTH.user?._id && !s.ROOT.AUTH.isAdmin) {
          s.ROOT.STATUS.displayMessage(
            "You do not have the access to export this composition."
          );
          return;
        }
      }
      const progress = s.ROOT.STATUS.registerProgress(
        "Exporting composition..."
      );
      try {
        if (
          !isString(comp) &&
          comp.$.atomSnapshots &&
          comp.$.atomSnapshots.length > 0
        ) {
          if (s.ROOT.SYNC.hasChanges) {
            const confirmSave = await s.ROOT.DIALOGS.attention({
              Heading: "Export composition",
              Body: "You have some unsaved changes. To continue with the export, your changes will be synced to the server.",
            });
            if (!confirmSave) {
              progress.complete();
              return;
            }
            await s.ROOT.SYNC.sync("sync-before-export");
          }
        }

        const id = isString(comp) ? comp : comp._id;
        const response = await s.API.postRaw<{
          data: ExportJsonSchema;
        }>(`/compositions/${id}/export`, ModelName.compositions);
        if (download) {
          const slug = makeSlug(response.data.composition.title);
          saveAs(
            new Blob([JSON.stringify(response.data)], {
              type: "application/javascript;charset=utf-8",
            }),
            `${slug}-${response.data.timeCreated}.json`
          );
        }
        return response.data;
      } catch (e) {
        console.error(e);
        if (isAccessDeniedError(e)) {
          s.ROOT?.AUTH.showQuickLoginDialog(() => {
            s.export(comp, download);
          });
        }
      } finally {
        progress.complete();
      }
    },

    import: async (json: string) => {
      try {
        return await s.API.post<Composition, { json: string }>(
          `/compositions/import`,
          ModelName.compositions,
          { json }
        );
      } catch (e) {
        s.ROOT?.DIALOGS.error({
          Heading: `${(e as AxiosError).response?.data || "Import failed."}`,
        });
      }
    },

    reset: () => {
      // c.composerAppState = null;
    },
  });

  s.init = makeRootControllerChildInitFn(s, () => {});

  return s;
};

export type CompositionsController = ReturnType<
  typeof makeCompositionsController
>;
