import { makeIconDef } from "./makeIconDef";

export const UndoIconDef = {
  undo: makeIconDef("undo", {
    regular: () => (
      <path
        d="M7.59619 8.59619L13.3455 9.1244L10.9283 3.88127L7.59619 8.59619ZM16.435 17.435C14.0919 19.7782 10.2929 19.7782 7.94974 17.435L7.24264 18.1421C9.97631 20.8758 14.4085 20.8758 17.1421 18.1421L16.435 17.435ZM11.7215 7.21077C13.411 7.07864 15.1437 7.65847 16.435 8.94975L17.1421 8.24264C15.6355 6.73597 13.6121 6.05986 11.6436 6.21381L11.7215 7.21077ZM16.435 8.94975C18.7782 11.2929 18.7782 15.0919 16.435 17.435L17.1421 18.1421C19.8758 15.4085 19.8758 10.9763 17.1421 8.24264L16.435 8.94975Z"
        fill="currentColor"
      />
    ),
  }),
};
