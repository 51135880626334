import { InstrumentRange } from "../@types";

export const PianoRange: InstrumentRange = [[21, 108]];

export const getRangeMinMax = (range: InstrumentRange) => {
  const flattenedRange = range.flat();
  const min = Math.min(...flattenedRange);
  const max = Math.max(...flattenedRange);
  return [min, max];
};

export const getRangeSize = (range: InstrumentRange) => {
  const [min, max] = getRangeMinMax(range);
  return max - min;
};
