/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import { Observer, observer } from "mobx-react-lite";
import { HexOrContextColorName } from "../@types";
import { CSSPartial } from "../@types/css.types";
import cx from "../utils/className.utils";
import { useProps, useStore } from "../utils/mobx.utils";

interface LoadingIndicatorProps {
  className?: string;
  delay?: string | 0;
  color?: HexOrContextColorName | null;
}

export const loadingIndicatorEnter = keyframes`
  0% { opacity: 0; transform: scale(.9) }
  75% { opacity: 0; transform: scale(.9) }
  100% { opacity: 1 }
`;
export const loadingIndicatorSpin = keyframes`
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
`;

const LoadingIndicator: React.FC<LoadingIndicatorProps> = observer(props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get style(): CSSPartial {
      return {
        display: "inline-block",
        position: "relative",
        width: 20,
        height: 20,
        opacity: 0,
        animation: `${loadingIndicatorEnter} .6s linear forwards`,
        animationDelay: `${p.delay ?? "0"}`,
      };
    },
    get spinner(): CSSPartial {
      return {
        display: "block",
        width: "inherit",
        height: "inherit",
        animation: `${loadingIndicatorSpin} .5s linear infinite`,
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <div className={cx("LoadingIndicator", p.className)} css={s.style}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            css={s.spinner}
          >
            <path
              d="M14.9497 5.05025C16.0949 6.1954 16.8076 7.70219 16.9663 9.31388C17.125 10.9256 16.72 12.5424 15.8203 13.889C14.9205 15.2355 13.5817 16.2285 12.032 16.6986C10.4822 17.1687 8.81743 17.0869 7.32122 16.4672C5.82501 15.8474 4.58997 14.728 3.82655 13.2998C3.06313 11.8715 2.81856 10.2227 3.1345 8.63437C3.45045 7.046 4.30737 5.61632 5.55925 4.58893C6.81113 3.56154 8.38051 3 10 3"
              stroke="currentColor"
              strokeWidth=".5"
            />
          </svg>
        </div>
      )}
    />
  );
});

export default LoadingIndicator;
