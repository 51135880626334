export type TimeSignature = [number, number];
export const CommonTimeSignatures: Record<string, TimeSignature> =
  Object.freeze({
    "4/4": [4, 4],
    "3/4": [3, 4],
    "2/4": [2, 4],
    "2/2": [2, 2],
    "6/8": [6, 8],
    "9/8": [9, 8],
    "12/8": [12, 8],
  });

export const makeTimeSignature44 = () => [4, 4] as TimeSignature;
