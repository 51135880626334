import {
  Instrument,
  InstrumentMeta,
  StandardInstrumentOptions,
} from "../../@types";
import { IconName } from "../../base/components/Symbols/iconDefs/_index.iconDefs";
import { isMobileOS } from "../../base/utils/browsersAndPlatforms.utils";
import { PianoRange } from "../../constants/instruments.constants";
import { getLocalStorageItem } from "../../utils/localStorage.utils";
import SalamanderGrandControlPanel from "./ui/SalamanderGrandControlPanel";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";
import { createCompositeSamplerInstrumentFactory } from "../_factory/compositeSamplerInstrumentFactory";
import { STATIC_HOST } from "../../env";
import {
  MusicKeyEquivalenceMap,
  MusicKeyNumber,
} from "../../constants/musicKeys.constants";
import { ColorPalette } from "../../theming/colorPalette";

let secretStepsAdjustment = getLocalStorageItem<number>("PIANO_VELOCITY_STEPS");

if (secretStepsAdjustment)
  secretStepsAdjustment = parseInt(secretStepsAdjustment as unknown as string);
/** upto 16. Default load 7 on desktop, already a lot – but use localStorage.setItem('PIANO_VELOCITY_STEPS', 16) to set it to full 16 steps */
const DEFAULT_VELOCITY_STEPS = isMobileOS ? 3 : secretStepsAdjustment ?? 16;

export type SalamanderGrandPianoOptions = StandardInstrumentOptions & {
  /** upto 16 */
  velocities: number;
};

export const makeSalamanderGrandPianoDefaultOptions =
  (): SalamanderGrandPianoOptions => ({
    ...makeDefaultInstrumentOptions(),
    velocities: DEFAULT_VELOCITY_STEPS,
  });

export type SalamanderGrandPianoInstrumentType =
  Instrument<SalamanderGrandPianoOptions>;
export type SalamanderGrandPianoInstrumentMeta =
  InstrumentMeta<SalamanderGrandPianoOptions>;

export const SalamanderGrandPianoName = "salamander-grand-piano";

export const SalamanderGrandPianoMeta: SalamanderGrandPianoInstrumentMeta = {
  name: SalamanderGrandPianoName,
  icon: "grand-piano" as IconName,
  displayName: "Salamander Grand Piano",
  source: "Alexander Holm",
  sourceWebsite: "https://archive.org/details/SalamanderGrandPianoV3",
  ControlPanel: SalamanderGrandControlPanel,
  range: PianoRange,
  type: "keyboards",
  defaultColor: ColorPalette.ivory,
  hasSampledVelocity: true,
  supportsSustainPedal: true,
};

/**
 * Velocities to load
 */
const velocities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

/**
 * All the notes of audio samples
 */
export const allNotes: number[] = [
  21, 24, 27, 30, 33, 36, 39, 42, 45, 48, 51, 54, 57, 60, 63, 66, 69, 72, 75,
  78, 81, 84, 87, 90, 93, 96, 99, 102, 105, 108,
];

const getNoteNameFromMidiNumber = (n: number) => {
  const key = MusicKeyEquivalenceMap[
    (n % 12 || 0) as MusicKeyNumber
  ][0].replace("#", "s");
  const octave = Math.floor(n / 12) - 1;
  return `${key}${octave}`;
};

function getNoteUrl(midi: number, vel: number): string {
  return `${getNoteNameFromMidiNumber(midi)}v${vel}.mp3`;
}

export const makeSalamanderGrandPiano = createCompositeSamplerInstrumentFactory(
  {
    defaultOptionsBagBuilder: makeSalamanderGrandPianoDefaultOptions,
    instrumentMeta: SalamanderGrandPianoMeta,
    compositeSamplerConfigArray: velocities.map((v, i) => ({
      name: `v${v}`,
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/salamander-grand-piano/`,
          volume: 8,
          urls: allNotes.reduce((map, note) => {
            map[`${note}`] = getNoteUrl(note, v);
            return map;
          }, {} as Record<string, string>),
        },
      },
    })),
  }
);
