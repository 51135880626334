import { toJS } from "mobx";
import { isOdd } from "../../base/utils/math.utils";
import { first, second, uniq } from "../../base/utils/ramdaEquivalents.utils";
import {
  OrnamentationDef,
  OrnamentationType,
} from "../../constants/ornaments.constants";
import { getNoteSnapshotAfterAddingOrSubtractingStepsInScale } from "../../utils/musicScale.utils";

// TODO
export const Ornamentation_TrillDef: OrnamentationDef = {
  identifier: OrnamentationType.trill,
  displayName: "Trill",
  defaultSymbol: "",
  symbolFactory: orn => {
    if (!orn || !orn.children.length) return "";
    const startsWithTurn =
      uniq(orn.children.slice(0, 4).map(n => n.y)).length > 2;
    const endsWithTurn =
      uniq(orn.children.slice(orn.children.length - 4).map(n => n.y)).length >
      2;
    if (startsWithTurn) {
      const fromLower = first(orn.children)!.y! < second(orn.children)!.y!;
      return fromLower ? (endsWithTurn ? "" : "") : endsWithTurn ? "" : "";
    } else if (endsWithTurn) {
      return "";
    }
    return "";
  },
  generator: (note, options) => {
    const $ = toJS(note.$);
    const higherNote = getNoteSnapshotAfterAddingOrSubtractingStepsInScale(
      note,
      1
    );
    const lowerNote = getNoteSnapshotAfterAddingOrSubtractingStepsInScale(
      note,
      -1
    );
    if (!note.width) return [];
    const ornamentNoteDuration =
      options?.ornamentNoteWidth ??
      Math.min(note.width * (note.width <= 0.25 ? 1 / 4 : 1 / 8), 0.125);
    const startsWithTurn = options?.startsWith === "turn";
    const endsWithTurn = options?.endsWith === "turn";
    const prefixWidth = startsWithTurn ? ornamentNoteDuration * 4 : 0;
    const suffixWidth = endsWithTurn ? ornamentNoteDuration * 4 : 0;
    const widthToShake = note.width - prefixWidth - suffixWidth;
    const prefixNotes = startsWithTurn
      ? options?.startFromLower
        ? [lowerNote, $, higherNote, $]
        : [higherNote, $, lowerNote, $]
      : [];
    const suffixNotes = endsWithTurn ? [higherNote, $, lowerNote, $] : [];
    let numberOfNotesInShake = Math.floor(widthToShake / ornamentNoteDuration);
    if (isOdd(numberOfNotesInShake)) numberOfNotesInShake += 1;
    const notesInShake = Array(numberOfNotesInShake)
      .fill(0)
      .map((n, i) => {
        if (isOdd(i)) return $;
        return higherNote;
      });
    return [...prefixNotes, ...notesInShake, ...suffixNotes].map(
      (snapshot, i) => ({
        ...snapshot,
        x: note.x! + i * ornamentNoteDuration,
        width: ornamentNoteDuration,
        velocity: note.velocity,
        appearance: note.appearance,
      })
    );
  },
};
