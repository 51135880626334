/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { CSSPartial } from "../../@types/css.types";

type AppSymbolsProps = {};

const cssStyle = {
  position: "fixed",
  top: -25,
  left: -25,
  width: 25,
  height: 25,
  overflow: ["hidden", "clip"],
  opacity: 0,
} as CSSPartial;

const AppSymbols: React.FC<AppSymbolsProps> = props => {
  return (
    <Observer
      children={() => (
        <div className="IconSymbols" css={cssStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 24 24"
          >
            <symbol
              id="interpretation-mark"
              width="5"
              height="5"
              viewBox="0 0 5 5"
            >
              <path d="M0 0H5L0 5V0Z" />
            </symbol>
          </svg>
        </div>
      )}
    />
  );
};

export default AppSymbols;
