/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import Panel, {
  PanelCloseButton,
  PanelHeader,
  PanelInner,
} from "../../base/components/Panel";
import Serif from "../../base/components/Serif";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Collection } from "../../models/Collection.model";
import CollectionSetupEditor from "../shared/CollectionSetupEditor";

type CollectionEditorPanelProps = {
  collection: Collection;
  onShouldClose?: () => void;
};

const CollectionEditorPanel: React.FC<CollectionEditorPanelProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    closeSelf: () => {
      p.onShouldClose?.();
    },
  }));
  return (
    <Observer
      children={() => (
        <Panel
          id="collection-editor"
          title="Edit Collection"
          fullscreen
          canEscape
          minWidth="38em"
        >
          <PanelHeader End={<PanelCloseButton onClick={s.closeSelf} />}>
            <h2>
              <Serif>Edit Collection</Serif>
            </h2>
          </PanelHeader>
          <PanelInner>
            <CollectionSetupEditor
              collection={p.collection}
              onSave={s.closeSelf}
              onCancel={s.closeSelf}
              autoFocus
              includeSaveButton
            />
          </PanelInner>
        </Panel>
      )}
    />
  );
};

export default CollectionEditorPanel;
