import { makeIconDef } from "./makeIconDef";

export const BackIconDef = {
  back: makeIconDef("back", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1464 6.14645L4.14645 12.1465L3.79289 12.5L4.14645 12.8536L10.1464 18.8536L10.8536 18.1465L5.70711 13H21V12H5.70711L10.8536 6.85356L10.1464 6.14645Z"
        fill="currentColor"
      />
    ),
  }),
};
