/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import { mediaFromDesktop } from "../../base/styles/helpers/mediaQueries.styling";

import { useStore } from "../../base/utils/mobx.utils";
import { Artist } from "../../models/Artist.model";
import ArtistEntry from "./ArtistEntry";

interface ArtistGalleryProps {
  artists: Artist[];
  linkTo?: "discover" | "workshop";
}

const EmptyWarning = styled.div`
  margin-top: 0.5em;
  opacity: 0.6;
  font-size: 1.8rem;
  font-weight: 200;
`;

const ArtistGallery: React.FC<ArtistGalleryProps> = observer(p => {
  const style = useStore(() => ({
    get gallery(): CSSPartial {
      return {
        display: "grid",
        maxWidth: "100%",
        gridTemplateColumns: "repeat(auto-fill, minmax(15rem, 1fr))",
        gridTemplateRows: "auto",
        gridGap: "2em",
        ...mediaFromDesktop({
          gridGap: "3.5em",
          gridTemplateColumns: "repeat(auto-fill, minmax(18rem, 1fr))",
        }),
      };
    },
  }));

  return (
    <div className="ArtistGallery" css={style.gallery}>
      {p.artists.length === 0 && <EmptyWarning>No artists found.</EmptyWarning>}
      {p.artists.map(comp => (
        <ArtistEntry
          key={comp._id}
          artist={comp}
          appearance="prominent"
          asLink
          linkTo={p.linkTo}
        />
      ))}
    </div>
  );
});

export default ArtistGallery;
