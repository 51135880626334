import {
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  StandardInstrumentOptions,
} from "../../@types";
import { STATIC_HOST } from "../../env";
import { ColorPalette } from "../../theming/colorPalette";
import { createCompositeSamplerInstrumentFactory } from "../_factory/compositeSamplerInstrumentFactory";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";

export type FluteOptions = StandardInstrumentOptions & {};

export const makeDefaultFluteOptions = (): FluteOptions => ({
  ...makeDefaultInstrumentOptions(),
});

export type FluteType = Instrument<FluteOptions>;

export const FluteName = "flute";

export const FluteMeta: InstrumentMeta<FluteOptions> = {
  name: FluteName,
  displayName: "Flute",
  source: "VS Chamber Orchestra: Community Edition",
  sourceWebsite: "https://github.com/sgossner/VSCO-2-CE",
  icon: "flute",
  range: [[60, 96]] as InstrumentRange,
  defaultColor: ColorPalette.yellow,
  type: "woodwind",
};

export const makeFlute = createCompositeSamplerInstrumentFactory({
  defaultOptionsBagBuilder: makeDefaultFluteOptions,
  instrumentMeta: FluteMeta,
  compositeSamplerConfigArray: [
    {
      name: "vibrato",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/flute/mp3/susvib/`,
          volume: 16,
          urls: {
            A4: "LDFlute_susvib_A3_v1_1.mp3",
            A5: "LDFlute_susvib_A4_v1_1.mp3",
            A6: "LDFlute_susvib_A5_v1_1.mp3",
            C4: "LDFlute_susvib_C3_v1_1.mp3",
            C5: "LDFlute_susvib_C4_v1_1.mp3",
            C6: "LDFlute_susvib_C5_v1_1.mp3",
            C7: "LDFlute_susvib_C6_v1_1.mp3",
            E4: "LDFlute_susvib_E3_v1_1.mp3",
            E5: "LDFlute_susvib_E4_v1_1.mp3",
            E6: "LDFlute_susvib_E5_v1_1.mp3",
          },
        },
      },
    },
  ],
});
