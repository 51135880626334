import { action, computed, makeObservable, observable, reaction } from "mobx";
import {
  DisposerController,
  makeDisposerController,
} from "../base/utils/disposer.utils";
import { copyWithJSON } from "../base/utils/object.utils";
import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { Has_Id } from "../traits/hasId.trait";

export abstract class StandardModel<
  N extends ModelName = ModelName,
  T extends Has_Id = Has_Id
> {
  protected d = makeDisposerController();
  $prev: T;
  get _isStandardModel() {
    return true as const;
  }
  constructor(
    public _name: N,
    protected LOCALDB: LocalDBController,
    public $: T
  ) {
    this.$prev = copyWithJSON($);
    makeObservable(this, {
      _id: computed,
      $: observable,
      $patch: action,
      $prev: observable.shallow,
      $setPrev: action,
    });
  }
  get _id() {
    return this.$._id;
  }
  set _id(newId: string) {
    this.$._id = newId;
  }
  protected get ROOT() {
    return this.LOCALDB.ROOT;
  }
  $patch(newSnapshot: Partial<T>) {
    const { _id, ...rest } = newSnapshot;
    Object.assign(this.$, rest);
    this.$setPrev(this.$);
  }
  $setPrev(newSnapshot: Partial<T>) {
    this.$prev = copyWithJSON(newSnapshot) as T;
  }
  $dispose() {
    this.d.dispose();
  }
}

export const addChangeDetectionToModel = <
  N extends ModelName = ModelName,
  T extends Has_Id = Has_Id
>(
  model: StandardModel<N, T>,
  LOCALDB: LocalDBController,
  snapshotMaker: ($: T) => string,
  disposer: DisposerController
) => {
  disposer.add(
    reaction(
      () => snapshotMaker(model.$),
      (curr, prev) => {
        LOCALDB.ROOT?.COMPOSER.instance?.queue.recordModelChangeBuffer(
          "changed",
          model._name,
          JSON.parse(curr) as Has_Id,
          JSON.parse(prev) as Has_Id
        );
      }
    )
  );
};

export const appendMissingKeys = <T extends {}>($: T, defaultSnapshot: T) => {
  Object.keys(defaultSnapshot).forEach(key => {
    if (!(key in $)) Reflect.set($, key, Reflect.get(defaultSnapshot, key));
  });
};
