import { observer } from "mobx-react-lite";
import * as React from "react";
import { useControllers } from "../../hooks/rootContext.hooks";
import Dialog from "./Dialog";

export const DialogStack: React.FC = observer(() => {
  const { DIALOGS } = useControllers();
  return DIALOGS.lastDialog ? (
    <Dialog dialog={DIALOGS.lastDialog} key={DIALOGS.lastDialog.id} />
  ) : null;
});
