import dayjs from "dayjs";
import { HasIdOrLocalId, Has_Id } from "../../traits/hasId.trait";
import { Timestamp } from "./time.utils";
import { action } from "mobx";

export const keepTruthy = <T>(arr: T[]) => arr.filter(i => i);

export const isInArray = <T>(arr: T[], el: T) => arr.indexOf(el) >= 0;
export const isNotInArray = <T>(arr: T[], el: T) => arr.indexOf(el) >= 0;

export const addOneToArrayIfNew = <T>(arr?: T[], itemToAdd?: T) => {
  if (!arr) return arr;
  if (arr.indexOf(itemToAdd as T) >= 0) return arr;
  arr.push(itemToAdd as T);
  return arr;
};

export const addManyToArrayIfNew = <T>(arr: T[], itemsToAdd: T[]) => {
  if (!arr) return arr;
  const toPush = [] as T[];
  for (const item of itemsToAdd) {
    if (arr.indexOf(item) >= 0) continue;
    toPush.push(item);
  }
  arr.push(...toPush);
  return arr;
};
export const addManyToArrayIfNewById = <
  T extends HasIdOrLocalId = HasIdOrLocalId
>(
  arr: T[],
  itemsToAdd: T[]
) => {
  if (!arr) return arr;
  const toPush = [] as T[];
  for (const item of itemsToAdd) {
    const index = arr.findIndex(a => {
      const aid = a.id || a._id;
      const iid = item.id || item._id;
      return aid === iid;
    });
    if (index >= 0) continue;
    toPush.push(item);
  }
  arr.push(...toPush);
  return arr;
};

export const removeOneFromArray = <T>(arr: T[], itemToRemove: unknown) => {
  const i = arr.indexOf(itemToRemove as T);
  if (i >= 0) arr.splice(i, 1);
  return arr;
};

export const removeOneFromArrayById = <
  T extends HasIdOrLocalId = HasIdOrLocalId
>(
  arr: T[],
  itemToRemove: HasIdOrLocalId
) => {
  return removeFromArrayById(arr, [itemToRemove]);
};

export const removeManyFromArray = <T>(arr: T[], itemsToRemove: T[]) => {
  for (const item of itemsToRemove) {
    const i = arr.indexOf(item);
    if (i >= 0) arr.splice(i, 1);
  }
  return arr;
};

export const removeManyFromArrayById = <
  T extends HasIdOrLocalId = HasIdOrLocalId
>(
  arr: T[],
  itemsToRemove: T[]
) => {
  for (const item of itemsToRemove) {
    const index = arr.findIndex(a => {
      const aid = a.id || a._id;
      const iid = item.id || item._id;
      return aid === iid;
    });
    if (index >= 0) arr.splice(index, 1);
  }
  return arr;
};

export const spliceManyFromArray = <T>(
  arr: T[],
  itemsToRemove?: T[],
  itemsToAdd?: T[]
) => {
  if (itemsToRemove?.length) {
    const _ = [...arr];
    for (const item of itemsToRemove) {
      const i = _.indexOf(item);
      if (i >= 0) _.splice(i, 1);
    }
    if (itemsToAdd) _.push(...itemsToAdd);
    replaceContents(arr, _);
  } else {
    if (itemsToAdd) arr.push(...itemsToAdd);
  }
  return arr;
};

export const removeFromArrayById = <T extends HasIdOrLocalId = HasIdOrLocalId>(
  arr: T[],
  itemsToRemove: HasIdOrLocalId[]
) => {
  for (const item of itemsToRemove) {
    const index = arr.findIndex(a => {
      const aid = a.id || a._id;
      const iid = item.id || item._id;
      return aid === iid;
    });
    if (index >= 0) arr.splice(index, 1);
  }
  return arr;
};

export const spliceFromArrayById = <T extends HasIdOrLocalId = HasIdOrLocalId>(
  arr: T[],
  itemsToRemove?: HasIdOrLocalId[],
  itemsToAdd?: T[]
) => {
  if (itemsToRemove?.length) {
    const _ = [...arr];
    for (const item of itemsToRemove) {
      const index = _.findIndex(a => {
        const aid = a.id || a._id;
        const iid = item.id || item._id;
        return aid === iid;
      });
      if (index >= 0) _.splice(index, 1);
    }
    if (itemsToAdd) _.push(...itemsToAdd);
    replaceContents(arr, _);
  } else {
    if (itemsToAdd) arr.push(...itemsToAdd);
  }
  return arr;
};

type JoinerArgType = string | number | boolean | undefined | null;

export const joinTruthy = (separator: string, ...args: JoinerArgType[]) => {
  return args.filter((x: unknown) => x).join(separator);
};

export const joinTruthyFactory =
  (separator = ",") =>
  (...args: JoinerArgType[]) =>
    joinTruthy(separator, ...args);

export const joinTruthyWithSpace = joinTruthyFactory(" ");

export const space = (...args: JoinerArgType[]) => args.join(" ");
export const comma = (...args: JoinerArgType[]) => args.join(",");

export const sortByLength = <T extends { length: number }>(arr: T[]) => {
  return arr.sort((a, b) => b.length - a.length);
};
export const sortByLastUpdated = <
  T extends {
    timeUpdated?: Timestamp;
    $?: { timeUpdated?: Timestamp };
  }
>(
  arr: T[]
) => {
  return [...arr].sort((a, b) =>
    dayjs(a.$?.timeUpdated ?? a.timeUpdated).diff(
      b.$?.timeUpdated ?? b.timeUpdated
    )
  );
};

export function swapInPlaceBetweenArrays<T>(
  arrI: T[],
  arrJ: T[],
  i: number,
  j: number,
  swapIf?: (a: T, b: T) => boolean
) {
  if (arrI[i] === void 0 || arrJ[j] === void 0) return false;
  if (swapIf && !swapIf(arrI[i], arrJ[j])) return false;
  [arrI[i], arrJ[j]] = [arrJ[j], arrI[i]];
  return true;
}

export const swapInPlace = <T>(a: T[], i: number, j: number) => {
  swapInPlaceBetweenArrays(a, a, i, j);
  return a;
};

export function insertElementsAtIndex<T>(arr: T[], i: number, ...els: T[]) {
  const result = [...arr];
  result.splice(i, 0, ...els);
  return result;
}

export const excludeItemsInArray = <T>(arr: T[], ...itemsToExclude: T[]) => {
  return arr.filter(i => !itemsToExclude.includes(i));
};

export const clearArray = <T>(arr: T[]) => arr.splice(0, arr.length);

export const moveItemToNewIndex = <T>(arr: T[], item: T, newIndex: number) => {
  const currentIndex = arr.indexOf(item);
  arr.splice(currentIndex, 1);
  arr.splice(newIndex, 0, item);
  return arr;
};
export const moveItemFromIndexToNewIndex = <T>(
  arr: T[],
  itemIndex: number,
  newIndex: number
) => {
  const item = arr[itemIndex];
  arr.splice(itemIndex, 1);
  arr.splice(newIndex, 0, item);
  return arr;
};

export const mapToIds = <T extends Has_Id>(arr: T[]) => arr.map(a => a._id);

export const replaceContents = <T>(arr: T[], newContent: T[]) => {
  if (
    arr.length !== newContent.length ||
    arr.some((v, i) => v !== newContent[i])
  ) {
    arr.splice(0, arr.length, ...newContent);
  }
};

export function replaceItemInArray<T>(arr: T[], oldItem: T, newItem: T) {
  const index = arr.indexOf(oldItem);
  arr.splice(index, 1, newItem);
  return arr;
}

export const updateContents = <T>(existing: T[], newContent: T[]) => {
  const added = newContent.filter(i => !existing.includes(i));
  const toRemove = existing.filter(i => !newContent.includes(i));
  removeManyFromArray(existing, toRemove);
  existing.push(...added);
};

export const chunkArray = <T>(arr: T[], chunkSize: number) => {
  return Array(Math.ceil(arr.length / chunkSize))
    .fill(null)
    .map((c, i) => arr.slice(i * chunkSize, i * chunkSize + chunkSize));
};

export const getMostCommonElementInArray = <T>(array: T[]) => {
  if (array.length == 0) return null;
  const modeMap = new Map<T, number>();
  let maxEl = array[0],
    maxCount = 1;
  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    if (!modeMap.has(el)) modeMap.set(el, 1);
    else modeMap.set(el, modeMap.get(el)! + 1);
    if (modeMap.get(el)! > maxCount) {
      maxEl = el;
      maxCount = modeMap.get(el)!;
    }
  }
  return maxEl;
};

export type NumberPair = [number, number];

export const mergeNumberPairArraysInPlace = action(
  (a: NumberPair[], b: NumberPair[]) => {
    const bMap = new Map<number, number>(b);

    // First, remove elements from A not found in B
    let i = 0;
    while (i < a.length) {
      if (!bMap.has(a[i][0])) {
        a.splice(i, 1);
      } else {
        i++;
      }
    }

    // Now, merge B into A by updating or inserting in the correct position
    b.forEach(([bKey, bValue]) => {
      const indexInA = a.findIndex(([aKey]) => aKey >= bKey);
      if (indexInA === -1) {
        // If bKey is greater than any present, push to the end
        a.push([bKey, bValue]);
      } else if (a[indexInA][0] === bKey) {
        // Update existing element if found
        if (a[indexInA][1] !== bValue) {
          a[indexInA][1] = bValue;
        }
      } else {
        // Insert new element from B before the first element in A that has a greater key
        a.splice(indexInA, 0, [bKey, bValue]);
      }
    });
  }
);
