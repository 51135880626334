/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { CSSPartial } from "../../base/@types/css.types";
import TextInput, {
  TextInputKeyboardEvent,
} from "../../base/components/TextInput";
import {
  useControllers,
  useShortcut,
} from "../../base/hooks/rootContext.hooks";
import { useObservableRef } from "../../base/hooks/useObservableRef.hook";
import { cVar } from "../../base/utils/customProperties.utils";
import { useStore } from "../../base/utils/mobx.utils";
import { elementIsVisibleInScrollParent } from "../../base/utils/scroll.utils";
import { runAfter } from "../../base/utils/waiters.utils";
import {
  VAR_PanelBackdropFilter,
  VAR_PanelBackground,
  VAR_PanelBorder,
  VAR_ShadowMedium,
  bg30,
} from "../../constants/cssCustomProperties.constants";
import { IS_EMBEDDED } from "../../env";
import PaletteCommand from "./PaletteCommandEntry";
import { action, reaction } from "mobx";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { makeDisposerController } from "../../base/utils/disposer.utils";

const style = {
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
  } as CSSPartial,
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: bg30,
  } as CSSPartial,
  panel: {
    border: cVar(VAR_PanelBorder),
    backgroundColor: cVar(VAR_PanelBackground),
    boxShadow: cVar(VAR_ShadowMedium),
    backdropFilter: cVar(VAR_PanelBackdropFilter),
    width: "40em",
  } as CSSPartial,
  header: {
    input: {
      padding: ".75em",
      height: "2.5em",
      "&:focus": {
        outline: "none",
      },
    },
  } as CSSPartial,
  list: {
    position: "relative",
    overflow: "auto",
    maxHeight: "25em",
  } as CSSPartial,
};

const CommandPalette = () => {
  const { COMMAND_PALETTE: CP, PORTALS, DIALOGS, COMPOSER } = useControllers();
  const listRef = useObservableRef();
  const s = useStore(() => ({
    _focusIndex: 0,
    get focusIndex() {
      if (s._focusIndex <= 0) return 0;
      if (s._focusIndex >= CP.matchedCommands.length)
        return CP.matchedCommands.length - 1;
      return s._focusIndex;
    },
    set focusIndex(v) {
      s._focusIndex = v;
    },
    executeFocusedCommand: () => {
      const command = CP.matchedCommands[s.focusIndex];
      CP.execute(command);
      s._focusIndex = 0;
    },
    handleKeyDown: (e: TextInputKeyboardEvent) => {
      switch (e.key) {
        case "ArrowUp": {
          s.focusIndex--;
          break;
        }
        case "ArrowDown": {
          s.focusIndex++;
          break;
        }
      }
      runAfter(
        action(() => {
          if (IS_EMBEDDED) return;
          const highlighted =
            listRef.current?.querySelector<HTMLButtonElement>(".hasHighlight");
          if (!highlighted) return;
          if (
            !elementIsVisibleInScrollParent({
              el: highlighted,
              scrollParent: listRef.current,
              visibleHeightRangeOffsetTop: 24,
              visibleHeightRangeOffsetBottom: -24,
            })
          ) {
            highlighted.scrollIntoView();
          }
        }),
        50
      );
    },
    handleKeyUp: (e: TextInputKeyboardEvent) => {
      switch (e.key) {
        case "Enter": {
          s.executeFocusedCommand();
          break;
        }
        case "Escape": {
          CP.hide();
        }
      }
    },
  }));
  useShortcut(
    "toggleCommandPalette",
    action(e => {
      if (IS_EMBEDDED && COMPOSER.instance?.withPlayerUI) return;
      e.preventDefault();
      if (DIALOGS.hasDialogs) return;
      CP.show();
    })
  );
  useHotkeys(
    "enter",
    action(() => {
      if (CP.shouldRender) s.executeFocusedCommand();
    })
  );
  useHotkeys(
    "escape",
    action(() => {
      if (DIALOGS.hasDialogs) return;
      CP.hide();
    })
  );

  useOnMount(() => {
    const d = makeDisposerController();
    d.add(
      reaction(
        () => CP.shouldRender,
        () => {
          runAfter(() => {
            document
              .querySelector<HTMLInputElement>(".CommandPalette input")
              ?.focus();
          });
        }
      )
    );

    const handleWindowBlur = () => {
      CP.hide();
    };
    window.addEventListener("blur", handleWindowBlur);
    d.add(() => {
      window.removeEventListener("blur", handleWindowBlur);
    });
    return d.dispose;
  });

  return (
    <Observer
      children={() =>
        CP.shouldRender
          ? PORTALS.render(
              <div className="CommandPalette" css={style.container}>
                <div css={style.backdrop} onClick={CP.hide} />
                <section css={style.panel}>
                  <header css={style.header}>
                    <TextInput
                      form={CP}
                      field="query"
                      placeholder="Search or enter a command..."
                      autoCorrect="off"
                      autoCapitalize="off"
                      autoComplete="off"
                      appearance="transparent"
                      onKeyUp={s.handleKeyUp}
                      onKeyDown={s.handleKeyDown}
                    />
                  </header>
                  <div css={style.list} ref={listRef}>
                    {CP.matchedCommands.map((c, i) => (
                      <PaletteCommand
                        command={c}
                        hasHighlight={i === s.focusIndex}
                        key={c.label}
                        onClick={CP.execute}
                      />
                    ))}
                  </div>
                </section>
              </div>
            )
          : null
      }
    />
  );
};

export default CommandPalette;
