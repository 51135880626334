import { makeIconDef } from "./makeIconDef";

export const InfoIconDef = {
  info: makeIconDef("info", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12.5C21 17.1944 17.1944 21 12.5 21C7.80558 21 4 17.1944 4 12.5C4 7.80558 7.80558 4 12.5 4C17.1944 4 21 7.80558 21 12.5ZM22 12.5C22 17.7467 17.7467 22 12.5 22C7.25329 22 3 17.7467 3 12.5C3 7.25329 7.25329 3 12.5 3C17.7467 3 22 7.25329 22 12.5ZM13.5 8C13.5 8.55228 13.0523 9 12.5 9C11.9477 9 11.5 8.55228 11.5 8C11.5 7.44772 11.9477 7 12.5 7C13.0523 7 13.5 7.44772 13.5 8ZM9 11.5C9 11.2239 9.22386 11 9.5 11H12.5C12.6393 11 12.7722 11.0581 12.8669 11.1603C12.9615 11.2625 13.0092 11.3995 12.9985 11.5383L12.5663 17.1579L14.7764 16.0528C15.0234 15.9293 15.3237 16.0294 15.4472 16.2764C15.5707 16.5234 15.4706 16.8237 15.2236 16.9472L12.2236 18.4472C12.0625 18.5278 11.8705 18.5154 11.721 18.4149C11.5715 18.3144 11.4877 18.1413 11.5015 17.9617L11.9601 12H9.5C9.22386 12 9 11.7761 9 11.5Z"
        fill="currentColor"
      />
    ),
  }),
};
