/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import Button, { ButtonGroup } from "../../base/components/Button";
import FileUploader from "../../base/components/FileUploader";
import Spacing from "../../base/components/Spacing";
import Textarea from "../../base/components/Textarea";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { grid6col } from "../../base/styles/partials/grids.stylePartials";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { copyWithJSON } from "../../base/utils/object.utils";
import { Artist } from "../../models/Artist.model";
import ArtistTitleSelector from "../composer/ArtistTitleSelector";
import { FileRecord } from "../../models/FileRecord.model";

type ArtistSetupEditorProps = {
  artist: Artist;
  autoFocus?: boolean;
  includeSubmitButton?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
};

const ArtistSetupEditor: React.FC<ArtistSetupEditorProps> = props => {
  const { ARTISTS, THEME } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    snapshot: p.includeSubmitButton ? copyWithJSON(p.artist.$) : p.artist.$,
    get panelTitle(): string {
      return `Edit ${s.snapshot.title ?? "Artist"}`;
    },
    handlePhotoUpload: (file: FileRecord) => {
      s.snapshot.imageId = file._id;
    },
    save: async () => {
      await ARTISTS.save(s.snapshot);
      p.onSave?.();
    },
  }));
  return (
    <Observer
      children={() => (
        <>
          <div css={grid6col.grid}>
            <TextInput
              form={s.snapshot}
              field="givenName"
              Label="Given Name"
              placeholder=""
              css={grid6col.third}
              autoFocus={p.autoFocus}
              onEnter={s.save}
            />
            <TextInput
              form={s.snapshot}
              field="middleName"
              Label="Middle Name"
              placeholder=""
              css={grid6col.third}
              onEnter={s.save}
            />
            <TextInput
              form={s.snapshot}
              field="surname"
              Label="Surname"
              placeholder=""
              css={grid6col.third}
              onEnter={s.save}
            />
            <TextInput
              form={s.snapshot}
              field="dateOfBirth"
              Label="Date of birth"
              type="date"
              css={grid6col.third}
              placeholder=""
              onEnter={s.save}
            />
            <TextInput
              form={s.snapshot}
              field="catalogueIdentifier"
              Label="Catalogue Name"
              css={grid6col.third}
              placeholder=""
              onEnter={s.save}
            />
            <ArtistTitleSelector artist={s.snapshot} css={grid6col.third} />
            <Textarea
              form={s.snapshot}
              field="description"
              Label="Description (Optional)"
              css={grid6col.full}
              rows={3}
            />
            <div css={grid6col.full}>
              <h4>Photo</h4>
              <Spacing size=".5em" />
              <FileUploader
                previewImage={p.artist.image}
                acceptOnlyImages
                onUpload={s.handlePhotoUpload}
                purpose="avatar"
                sizeLimitInMB={5}
              />
            </div>
          </div>
          {p.includeSubmitButton && (
            <>
              <Spacing />
              <ButtonGroup>
                <Button fullWidth onClick={p.onCancel}>
                  Discard changes
                </Button>
                <Button fullWidth onClick={s.save} color={THEME.primary}>
                  Save changes
                </Button>
              </ButtonGroup>
            </>
          )}
        </>
      )}
    />
  );
};

export default ArtistSetupEditor;
