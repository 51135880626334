import { action } from "mobx";
import { AtomContext, Note, NoteSnapshot } from "../@types";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { NoteSnapshotFactory } from "../models/atoms/Note.model";
import { insertAtomInOrder } from "../logic/atomFactoryMethods";
import { isPlayableNote } from "../utils/note.utils";

export const createNote = action(
  (context: AtomContext, template: Note | Partial<NoteSnapshot>) => {
    // console.info("Adding new note from template:", template);
    const snapshot: NoteSnapshot = makeSnapshot(NoteSnapshotFactory, template);
    if (!snapshot._id || context.getAtomById(snapshot._id))
      snapshot._id = context.getNextNewAtomId();
    if (!snapshot.voiceId)
      snapshot.voiceId = context.composer?.writeToVoice?._id ?? null;
    if (context.composer?.writeContentTo === "interpretation") {
      if (!snapshot._itpId)
        snapshot._itpId =
          context.composer.interpreter?.interpretation._id ?? "";
    }
    context.addAtomSnapshots([snapshot]);
    const newNote = context.getAtomById<Note>(snapshot._id)!;
    if (
      newNote.parentIds.length === 0 &&
      context.ROOT?.SETTINGS.composer.writeNewNotesIntoOverlappingPattern &&
      !newNote.refAtom &&
      newNote.startX !== null &&
      newNote.startY !== null &&
      newNote.endY !== null
    ) {
      const firstOverlappingPattern = context.patternsReversed.find(
        p =>
          p.startX <= newNote.startX! &&
          p.endX > newNote.startX! &&
          p.startY <= newNote.startY! &&
          p.endY >= newNote.endY!
      );
      if (firstOverlappingPattern) newNote.addParents(firstOverlappingPattern);
    }
    insertAtomInOrder(context.notes, newNote);
    insertAtomInOrder(context.notesAndKeyframes, newNote);
    if (isPlayableNote(newNote))
      insertAtomInOrder(context.playableNotes, newNote);
    context.lastEditedNote = newNote;
    if (!newNote.refAtom)
      context.canvas?.setPrimaryCursorPosition(newNote.endX);
    // console.info("Added new note:", newNote);
    return newNote;
  }
);
