/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import Button from "../../base/components/Button";
import InfoBox from "../../base/components/InfoBox";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { grid6col } from "../../base/styles/partials/grids.stylePartials";
import { readAxiosErrorMessage } from "../../base/utils/errors.utils";
import { useStore } from "../../base/utils/mobx.utils";

const UserPasswordSelfUpdater: React.FC<{}> = props => {
  const { AUTH, DIALOGS } = useControllers();
  const s = useStore(() => ({
    form: {
      oldPassword: "",
      password: "",
      confirm: "",
    },
    get passwordDoNotMatch() {
      return s.form.password !== s.form.confirm;
    },
    submit: async () => {
      try {
        await AUTH.updatePassword(s.form);
        s.form.password = s.form.confirm = "";
        DIALOGS.success({
          Heading: "Password updated.",
        });
      } catch (e) {
        DIALOGS.error({
          Heading: readAxiosErrorMessage(e),
        });
      }
    },
  }));
  return (
    <Observer
      children={() => (
        <div css={grid6col.grid}>
          <TextInput
            css={grid6col.third}
            Label="Old password"
            form={s.form}
            field="oldPassword"
            type="password"
            autoComplete="password"
            autoCapitalize="off"
            autoCorrect="off"
          />
          <TextInput
            css={grid6col.third}
            Label="New password"
            form={s.form}
            field="password"
            type="password"
            autoComplete="new-password"
            autoCapitalize="off"
            autoCorrect="off"
          />
          <TextInput
            css={grid6col.third}
            Label="Confirm password"
            form={s.form}
            field="confirm"
            autoComplete="new-password"
            type="password"
            autoCapitalize="off"
            autoCorrect="off"
          />
          {s.form.password && s.form.confirm && s.passwordDoNotMatch && (
            <InfoBox css={grid6col.full}>
              Please make sure your passwords match.
            </InfoBox>
          )}
          <Button
            css={grid6col.third}
            onClick={s.submit}
            disabled={
              !s.form.oldPassword || !s.form.password || s.passwordDoNotMatch
            }
          >
            Confirm update password
          </Button>
        </div>
      )}
    />
  );
};

export default UserPasswordSelfUpdater;
