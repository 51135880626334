import webmidi, { Output } from "webmidi";
import { runAfter } from "../../base/utils/waiters.utils";
import * as Tone from "tone";

export const MIDI_CONTROLLER_HOLDPEDAL_NUMBER = 64;

export const getMidiTimeInMilliseconds = () => webmidi.time;
export const getMidiTimeInSeconds = () => webmidi.time / 1000;

export const getLookAheadTime = () => {
  return (Tone.now() - Tone.immediate()) * 1000;
};

export const attackOutput = (o: {
  output: Output;
  which: number | string;
  channels: string[];
  velocity?: number;
  time?: number;
  duration?: number;
  immediate?: boolean;
}) => {
  if (o.duration === 0) return;
  const lookAheadTime = o.immediate ? 0 : getLookAheadTime();
  o.channels.forEach(channel => {
    o.output?.playNote(o.which, parseInt(`${channel}`), {
      rawVelocity: false,
      velocity: o.velocity ?? 0.5,
      time: `+${lookAheadTime + (o.time ?? 0)}`,
    });
  });
  if (o.duration && o.duration > 0) {
    runAfter(() => stopOutput(o), o.duration * 1000);
  }
};

export const stopOutput = (o: {
  output: Output;
  which: number | string;
  channels: string[];
  velocity?: number;
  time?: number;
  immediate?: boolean;
}) => {
  const lookAheadTime = o.immediate ? 0 : getLookAheadTime();
  o.channels.forEach(channel => {
    o.output?.stopNote(o.which, parseInt(`${channel}`), {
      rawVelocity: false,
      velocity: o.velocity ?? 0.5,
      time: `+${lookAheadTime + (o.time ?? 0)}`,
    });
  });
};
