import { action } from "mobx";
import {
  Atom,
  AtomType,
  GroupLikeAtom,
  Keyframe,
  Note,
  Pattern,
  Replica,
  TextNode,
} from "../@types";
import { isNumber, isNumberLike } from "../base/utils/typeChecks.utils";

export const parseYAdjustmentDelta = (atom: Atom, _delta: number | string) => {
  if (isNumber(_delta)) {
    return {
      value: _delta,
      unit: null,
    };
  }
  const delta = _delta.trim();
  if (isNumberLike(delta)) {
    return {
      value: parseFloat(delta),
      unit: null,
    };
  }
  const moveByUnitMatch = /^(\+|-)?([\d\.]+)\s*(octaves?|scaleSteps?)$/.exec(
    delta
  );
  if (!moveByUnitMatch) return { value: 0, unit: null };
  const multiplier = parseFloat(moveByUnitMatch[1] + moveByUnitMatch[2]);
  if (multiplier === 0) return { value: 0, unit: null };
  const unit = moveByUnitMatch[3];
  switch (unit) {
    case "octave":
    case "octaves":
      return { value: multiplier * 12, unit: "octaves" };
    case "scaleStep":
    case "scaleSteps":
      console.warn("moving on Y axis by scaleSteps are not implemented yet");
      return { value: multiplier * 1, unit: "scaleSteps" };
  }
  return { value: parseInt(delta), unit: null };
};

export const adjustY = (
  atom: Note | Keyframe | TextNode,
  delta: string | number
) => {
  const { value } = parseYAdjustmentDelta(atom, delta);
  if (atom.y !== null) {
    atom.y = Math[value >= 0 ? "floor" : "ceil"](atom.y + value);
  }
};

export const adjustAtomArrayY = action(
  (atoms: Atom[], delta: string | number) => {
    atoms.forEach(atom => {
      switch (atom.type) {
        case AtomType.note:
        case AtomType.keyframe:
        case AtomType.textNode:
          adjustY(atom as Note | Keyframe | TextNode, delta);
          break;
        case AtomType.chord:
        case AtomType.group:
        case AtomType.voice:
        case AtomType.ornament:
          adjustAtomArrayY((atom as GroupLikeAtom).children, delta);
          break;
        case AtomType.pattern: {
          const pattern = atom as Pattern;
          const { value } = parseYAdjustmentDelta(pattern, delta);
          pattern.$.y = pattern.anchor.y + value;
          adjustAtomArrayY(pattern.children, delta);
          break;
        }
        case AtomType.replica: {
          const replica = atom as Replica;
          const { value } = parseYAdjustmentDelta(replica, delta);
          replica.$.y = replica.anchor.y + value;
          break;
        }
        default:
          console.warn(
            `adjustAtomArrayY for type ${atom.type} is not yet implemented`
          );
      }
    });
    return atoms;
  }
);
