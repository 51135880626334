/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Voice } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import Accordion from "../../base/components/Accordion";
import Button from "../../base/components/Button";
import Checkbox from "../../base/components/Checkbox";
import { Code } from "../../base/components/Code";
import FormLabel from "../../base/components/FormLabel";
import LineSeparator from "../../base/components/LineSeparator";
import TextInput from "../../base/components/TextInput";
import { clampLines } from "../../base/styles/helpers/clampLines";
import { DefaultSansFontStack } from "../../base/styles/helpers/fontFamilyFallbacks.styleHelper";
import cx from "../../base/utils/className.utils";
import { round } from "../../base/utils/math.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import {
  fg05,
  fg10,
  fg15,
} from "../../constants/cssCustomProperties.constants";
import {
  RuleController,
  makeRuleSnapshot,
} from "../../logic/interpreterRule.controller";
import { ColorPalette } from "../../theming/colorPalette";
import {
  isBarAtom,
  isChordAtom,
  isGroupLikeAtom,
  isNoteAtom,
  isSectionAtom,
  isVoiceAtom,
} from "../../utils/atoms.utils";
import ArpeggioEditor from "./ArpeggioEditor";
import { useComposer, useInterpreter } from "./ComposerApp.context";
import OrnamentEditor from "./OrnamentEditor";
import { RuleSelectorEditor } from "./RuleSelectorEditor";

type RuleEditorProps = {
  rule: RuleController;
  openOnMount?: boolean;
  canSelectAtoms?: boolean;
};

const style = {
  editor: {
    "&.duplicateHint": {
      outline: `.5px dashed ${ColorPalette.green}`,
    },
    "&.deleteHint": {
      outline: `.5px dashed ${ColorPalette.red}`,
    },
    "+ *": {
      marginTop: 2,
    },
  } as CSSPartial,
  body: {
    padding: "1em",
  } as CSSPartial,
  header: {
    display: "grid",
    gridTemplateColumns: "auto minmax(0, 1fr)",
    gridGap: ".5em",
    alignItems: "baseline",
    paddingBottom: ".5em",
  } as CSSPartial,
  table: {
    width: "100%",
    borderCollapse: "collapse",
    th: {
      textAlign: "left",
      paddingTop: ".5em",
      paddingBottom: ".5em",
    },
    tbody: {
      "th, td": {
        borderTop: `.5px solid ${fg10}`,
        paddingRight: ".5em",
        minWidth: "4em",
        "&:last-child": {
          paddingRight: 0,
        },
      },
    },
    ".TextInput": {
      minHeight: "2em",
    },
    input: {
      width: "100%",
      minHeight: "inherit",
    },
    "th.number": {
      textAlign: "right",
    },
    "td.number": {
      textAlign: "right",
      fontFeatureSettings: "'tnum' 1",
      fontFamily: DefaultSansFontStack,
    },
  } as CSSPartial,
  footer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: ".5em",
    ".canSelectAtoms &": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  } as CSSPartial,
};

const Summary = styled.div`
  padding: 0.75em 1em;
  font-weight: 700;
  background-color: ${fg05};
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  &:hover {
    background-color: ${fg10};
  }
  [data-open="true"] & {
    background-color: ${fg10};
    &:hover {
      background-color: ${fg15};
    }
  }
  h4 {
    &:before {
      content: "⏷";
      display: inline-block;
      margin-right: 0.5em;
      transition: 0.1s;
      transform: rotate(-180deg);
      [data-open="true"] & {
        transform: rotate(0);
      }
    }
    span {
      font-weight: 400;
      opacity: 0.7;
    }
  }
  div {
    text-align: right;
    display: flex;
  }
  code {
    margin: 0;
    ${clampLines(1)}
    + * {
      margin-left: 0.5em;
    }
  }
`;

const RuleEditor: React.FC<RuleEditorProps> = props => {
  const p = useProps(props);
  const I = useComposer();
  const interpreter = useInterpreter();

  const s = useStore(() => ({
    duplicateHint: false,
    deleteHint: false,
    get ruleSnapshot() {
      return p.rule.$ ?? makeRuleSnapshot();
    },
    get properties() {
      return p.rule.$?.properties ?? makeRuleSnapshot().properties ?? {};
    },
    get onlyNoteAtom() {
      if (p.rule.atoms.length === 1) {
        const firstAtom = p.rule.atoms[0];
        if (isNoteAtom(firstAtom)) return firstAtom;
        return null;
      }
      return null;
    },
    get canContainNotes() {
      return p.rule.atoms.some(
        a =>
          isNoteAtom(a) ||
          isGroupLikeAtom(a) ||
          isBarAtom(a) ||
          isSectionAtom(a)
      );
    },
    get isAllChords() {
      return p.rule.atoms.every(isChordAtom);
    },
    delete: () => {
      I.runInHistory("Delete rule", () => {
        interpreter?.deleteRule(p.rule);
      });
    },
    duplicate: () => {
      I.runInHistory("Duplicate rule", () => {
        interpreter?.duplicateRule(p.rule);
      });
    },
    selectAtoms: () => {
      if (p.rule.isForSingleVoice) {
        I.selectAtoms((p.rule.atoms[0] as Voice).descendants);
      } else {
        I.selectAtoms(p.rule.atoms);
      }
    },
    toggleDuplicateHintFn: (newValue: boolean) =>
      action(() => (s.duplicateHint = newValue)),
    toggleDeleteHintFn: (newValue: boolean) =>
      action(() => (s.deleteHint = newValue)),
  }));

  return (
    <Observer
      children={() => (
        <Accordion
          className={cx(
            "RuleEditor",
            s.duplicateHint && "duplicateHint",
            s.deleteHint && "deleteHint",
            p.canSelectAtoms && "canSelectAtoms"
          )}
          css={style.editor}
          openOnMount={p.openOnMount}
          Summary={
            <Summary>
              <h4>
                Rule #{p.rule._id}{" "}
                {p.rule.atoms.length === 1 &&
                  (isVoiceAtom(p.rule.atoms[0]) ? (
                    <span>  •   Voice {p.rule.atoms[0].displayName}</span>
                  ) : (
                    <span>  •   {p.rule.atoms[0].displayName}</span>
                  ))}
              </h4>
              <div>
                <Code>{s.ruleSnapshot.selector}</Code>
              </div>
            </Summary>
          }
        >
          {() => (
            <div css={style.body}>
              <header css={style.header}>
                <FormLabel bold>Selector</FormLabel>
                <RuleSelectorEditor rule={p.rule} disabled={I.editDisabled} />
              </header>
              {s.isAllChords && (
                <div>
                  <FormLabel bold>Arpeggio</FormLabel>
                  <ArpeggioEditor rule={p.rule} disabled={I.editDisabled} />
                </div>
              )}
              {s.canContainNotes && (
                <table css={style.table}>
                  <tbody>
                    <tr>
                      <th>Attribute</th>
                      {s.onlyNoteAtom && <th className="number">Raw</th>}
                      <th>Formula</th>
                      {s.onlyNoteAtom && <th className="number">Final</th>}
                    </tr>
                    <tr>
                      <th>Start X</th>
                      {s.onlyNoteAtom && (
                        <td className="number">
                          {round(s.onlyNoteAtom.startX, 3)}
                        </td>
                      )}
                      <td>
                        <TextInput
                          form={s.properties}
                          field="start"
                          taskName="Edit rule start X value"
                          mergeableId={`edit-rule-start-x-${p.rule._id}`}
                          disabled={I.editDisabled}
                        />
                      </td>
                      {s.onlyNoteAtom && (
                        <td className="number">
                          {round(
                            s.onlyNoteAtom.interpreted.timeStartInSeconds,
                            3
                          )}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th>Width</th>
                      {s.onlyNoteAtom && (
                        <td className="number">
                          {round(s.onlyNoteAtom.width, 3)}
                        </td>
                      )}
                      <td>
                        <TextInput
                          form={s.properties}
                          field="width"
                          taskName="Edit rule width value"
                          mergeableId={`edit-rule-width-${p.rule._id}`}
                          disabled={I.editDisabled}
                        />
                      </td>
                      {s.onlyNoteAtom && (
                        <td className="number">
                          {round(s.onlyNoteAtom.interpreted.width, 3)}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th>Velocity</th>
                      {s.onlyNoteAtom && (
                        <td className="number">
                          {round(s.onlyNoteAtom.velocity, 3)}
                        </td>
                      )}
                      <td>
                        <TextInput
                          form={s.properties}
                          field="velocity"
                          taskName="Edit rule velocity value"
                          mergeableId={`edit-rule-velocity-${p.rule._id}`}
                          disabled={I.editDisabled}
                        />
                      </td>
                      {s.onlyNoteAtom && (
                        <td className="number">
                          {round(s.onlyNoteAtom.interpreted.velocity, 3)}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              )}
              <LineSeparator />
              {s.onlyNoteAtom && (
                <>
                  <OrnamentEditor
                    note={s.onlyNoteAtom}
                    rule={p.rule}
                    disabled={I.editDisabled}
                  />
                  <LineSeparator />
                </>
              )}
              <Checkbox
                form={s.properties}
                field="disabled"
                Label={
                  s.onlyNoteAtom
                    ? `Disable this ${s.onlyNoteAtom.type}`
                    : "Disable applicable elements"
                }
                color={s.onlyNoteAtom?.appearance?.colorInContext ?? undefined}
                taskName={"Disable applicable elements of rule"}
                mergeableId={`edit-rule-disabled-${p.rule._id}`}
                disabled={I.editDisabled}
              />
              <LineSeparator />
              <footer css={style.footer}>
                {p.canSelectAtoms && (
                  <Button
                    onClick={s.selectAtoms}
                    disabled={p.rule.atoms.length === 0}
                  >
                    {p.rule.atoms.length === 1
                      ? `Select ${p.rule.atoms[0].type}`
                      : `Select elements (${p.rule.atoms.length})`}
                  </Button>
                )}
                {I.editable && (
                  <>
                    <Button
                      onClick={s.duplicate}
                      onMouseEnter={s.toggleDuplicateHintFn(true)}
                      onMouseLeave={s.toggleDuplicateHintFn(false)}
                    >
                      Duplicate
                    </Button>
                    <Button
                      onClick={s.delete}
                      onMouseEnter={s.toggleDeleteHintFn(true)}
                      onMouseLeave={s.toggleDeleteHintFn(false)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </footer>
            </div>
          )}
        </Accordion>
      )}
    />
  );
};

export default RuleEditor;
