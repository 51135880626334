import { Midi, Track } from "@tonejs/midi";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import {
  AtomContext,
  GroupLikeAtom,
  Instrument,
  Note,
  Voice,
} from "../../@types";
import { first } from "../../base/utils/ramdaEquivalents.utils";
import { isAtomContext } from "../../logic/AtomContext.maker";
import { ColorPalette } from "../../theming/colorPalette";

export const DefaultVoiceColorsFromDefaultPalette = [
  ColorPalette.pistachio,
  ColorPalette.yellow,
  ColorPalette.red,
  ColorPalette.eucalyptus,
  ColorPalette.gray,
  ColorPalette.teal,
  ColorPalette.apricot,
  ColorPalette.orange,
  ColorPalette.neonGreen,
  ColorPalette.purple,
  ColorPalette.blue,
  ColorPalette.violet,
  ColorPalette.green,
  ColorPalette.lightGray,
];

export type MidiExportTrackCreationSource =
  | "by-instruments"
  | "by-voices"
  | "as-single-track";

// remember to update the API counterpart
export const makeMidiExportOptionsBag = () => ({
  createTracks: "by-voices" as MidiExportTrackCreationSource,
  includeDisabledNotes: false,
  applyInterpretation: {
    velocity: true,
    timing: true,
  },
  overrides: {
    velocity: 0,
  },
});

export type MidiExportOptions = ReturnType<typeof makeMidiExportOptionsBag>;

export const exportMidi = (
  target: AtomContext | GroupLikeAtom,
  options = (isAtomContext(target) ? target : target.context)?.interpretation?.$
    .midiExportOptions ?? makeMidiExportOptionsBag()
) => {
  const context = isAtomContext(target) ? target : target.context;
  if (!context?.composition) return null;
  const midi = new Midi();
  const tracks = [] as Track[];
  const notesToSequence = (
    isAtomContext(target) ? target.notes : target.descendantNotes
  ).filter(
    n =>
      !n.interpreted.ornament &&
      (options.includeDisabledNotes ? true : !n.muted) &&
      n.x !== null &&
      n.y !== null
  );
  const firstNote = first(notesToSequence);
  if (!firstNote) return null;
  const firstNoteStartX =
    (options.applyInterpretation.timing
      ? firstNote.interpreted.timeStartInSeconds
      : firstNote.timeStartInSeconds) ?? 0;
  const addNote = (n: Note, track: Track) => {
    if (n.midiNumber == null) return;
    const shouldPlay =
      n.interpreted.notesStartingAtTheSameTimeWithSamePitchAndInstrument
        .length === 0 ||
      n.interpreted
        .notesStartingAtTheSameTimeWithSamePitchAndInstrumentIncludingSelf[0]
        ._id === n._id;
    if (!shouldPlay) return;
    const midi = Math.round(n.midiNumber);
    const velocity = options.applyInterpretation.velocity
      ? n.interpreted.velocity
      : options.overrides.velocity || n.velocity;
    const time = options.applyInterpretation.timing
      ? n.interpreted.timeStartInSeconds
      : n.timeStartInSeconds;
    if (midi === null || time === null) return;
    const timeEnd = options.applyInterpretation.timing
      ? time + n.interpreted.adjustedDuration
      : n.timeEndInSeconds;
    if (timeEnd === null) return;
    const duration = timeEnd - time;
    track.addNote({
      midi,
      time: time - firstNoteStartX,
      duration,
      velocity,
    });
  };
  switch (options.createTracks) {
    case "as-single-track": {
      const track = midi.addTrack();
      notesToSequence.forEach(note => addNote(note, track));
      tracks.push(track);
      break;
    }
    case "by-voices": {
      const tracksMap = new Map<Voice, Track>();
      context.writableVoices.forEach(voice => {
        if (voice.descendantNotes.length > 0) {
          tracksMap.set(voice, midi.addTrack());
        }
      });
      notesToSequence.forEach(note => {
        if (!note.voice) return;
        const track = tracksMap.get(note.voice);
        if (track) addNote(note, track);
      });
      break;
    }
    case "by-instruments": {
      const tracksMap = new Map<Instrument, Track>();
      context.interpreter?.instruments.forEach(ins =>
        tracksMap.set(ins, midi.addTrack())
      );
      notesToSequence.forEach(note => {
        note.interpreted.instruments.forEach(ins => {
          const track = tracksMap.get(ins);
          if (track) addNote(note, track);
        });
      });
      break;
    }
  }
  const arr = midi.toArray();
  const blob = new Blob([arr], { type: "audio/midi" });
  midi.header.setTempo(context.bpm);
  const fileName = `${[
    context.composition.titlePlaintext,
    isAtomContext(target) ? "" : target.displayName,
    dayjs().format("LLL"),
  ]
    .filter(i => i)
    .join(" – ")}.midi`;
  return saveAs(blob, fileName);
};
