import { observable, runInAction } from "mobx";
import Dialog from "../base/constructors/dialog.constructor";
import { DialogConfig, IDialog } from "../base/@types";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { ColorPalette } from "../theming/colorPalette";

export const makeDialogsController = () => {
  const c = observable({
    ...makeControllerBase("DIALOGS"),
    dialogs: [] as IDialog[],
    present: <T>(d: DialogConfig) => {
      return new Promise((resolve: (d: Promise<T>) => void, reject) => {
        const dialog = new Dialog<T>(d, c);
        runInAction(() => c.dialogs.push(dialog));
        resolve(dialog.promise);
      });
    },
    success: (d: DialogConfig) => {
      return c.present({
        color: ColorPalette.green,
        defaultActions: d.actions ? undefined : ["positive"],
        ...d,
      });
    },
    attention: (d: DialogConfig) => {
      return c.present({
        color: ColorPalette.orange,
        // defaultActions: d.actions ? undefined : ["positive"],
        ...d,
      });
    },
    error: (d: DialogConfig) => {
      return c.present({
        color: ColorPalette.red,
        defaultActions: d.actions ? undefined : ["positive"],
        ...d,
      });
    },
    dismiss: (d: IDialog | string) => {
      const index = c.dialogs.findIndex(dialog =>
        typeof d === "string" ? dialog.config.name === d : dialog === d
      );
      const dialog = c.dialogs[index];
      if (!dialog) return;
      if (dialog.status === "closed") {
        c.dialogs.splice(index, 1);
      } else {
        dialog.close();
      }
    },
    get hasDialogs(): boolean {
      return c.dialogs.filter(o => o.status === "opened").length > 0;
    },
    get lastDialog(): IDialog {
      return c.dialogs[c.dialogs.length - 1];
    },
  });

  c.init = makeRootControllerChildInitFn(c, () => {});

  return c;
};

export type DialogsController = ReturnType<typeof makeDialogsController>;
