import { makeIconDef } from "./makeIconDef";

export const LoopIconDef = {
  loop: makeIconDef("loop", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25732 11.433C6.25732 12.6668 6.92946 13.7435 7.92753 14.3171L6.84097 15.4037C5.58208 14.5332 4.75732 13.0794 4.75732 11.433C4.75732 8.76877 6.91714 6.60896 9.5814 6.60896L13.6967 6.60896L12.3288 5.24114L13.3895 4.18048L16.0377 6.82863L16.568 7.35896L16.0377 7.88929L13.3895 10.5374L12.3288 9.47678L13.6967 8.10896L9.5814 8.10896C7.74556 8.10896 6.25732 9.5972 6.25732 11.433ZM17.743 12.567C17.743 11.3332 17.0708 10.2565 16.0727 9.68288L17.1593 8.59632C18.4182 9.46683 19.243 10.9206 19.243 12.567C19.243 15.2312 17.0831 17.391 14.4189 17.391H10.3036L11.6714 18.7589L10.6108 19.8195L7.96262 17.1714L7.43229 16.641L7.96262 16.1107L10.6108 13.4626L11.6714 14.5232L10.3036 15.891H14.4189C16.2547 15.891 17.743 14.4028 17.743 12.567Z"
        fill="currentColor"
      />
    ),
  }),
};
