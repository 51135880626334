/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { TimedAtom } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { cVar } from "../../base/utils/customProperties.utils";
import { useProps, useStore, useStyle } from "../../base/utils/mobx.utils";
import {
  VAR_InputBackground,
  VAR_InputBorder,
  VAR_InputFocusOutline,
  VAR_InputForeground,
  bg,
  fg05,
  fg20,
  fg50,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";
import { isVoiceAtom } from "../../utils/atoms.utils";
import AtomSelectInstrumentsPanel from "../panels/AtomSelectInstrumentPanel";
import styled from "@emotion/styled";
import SymbolIcon from "../../base/components/SymbolIcon";
import { useWorkspace } from "../../controllers/composer/useWorkspace";
import { first } from "lodash-es";
import { openInstrumentManager } from "../../utils/instrument.utils";
import { useComposer } from "./ComposerApp.context";

type AtomInstrumentSelectorProps = {
  className?: string;
  atom: TimedAtom;
};

const Flex = styled.div`
  display: flex;
  > * + * {
    margin-left: 0.5em;
  }
`;

const ConfigButton = styled.button`
  appearance: none;
  background-color: ${bg};
  color: inherit;
  border: 1px solid ${fg20};
  border-radius: 2px;
  &:hover {
    background-color: ${fg05};
  }
  &:active {
    background-color: ${bg};
  }
  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const AtomInstrumentSelector: React.FC<AtomInstrumentSelectorProps> = props => {
  const p = useProps(props);
  const { PORTALS } = useControllers();
  const ws = useWorkspace();
  const I = useComposer();
  const s = useStore(() => ({
    shouldRenderSelector: false,
    toggleSelector: () => {
      s.shouldRenderSelector = !s.shouldRenderSelector;
    },
    get inheritsFromParent() {
      return (
        isVoiceAtom(p.atom) &&
        !!p.atom.parentVoice &&
        p.atom.ownRules.every(r => !r.$.properties.instrumentIds?.length)
      );
    },
    get instruments() {
      return p.atom.interpreted.instruments;
    },
    get hasExactlyOneInstrument() {
      return p.atom.interpreted.instruments.length === 1;
    },
    get textLabel(): string {
      return s.inheritsFromParent
        ? ""
        : s.instruments.map(ins => ins.nickName).join(", ");
    },
    get isChildVoice() {
      return isVoiceAtom(p.atom) && !!p.atom.parentVoice;
    },
    get placeholder(): string {
      return s.isChildVoice ? "Inherit from parent voice" : "None";
    },
    openInstrumentPanel: () => {
      const instrument = first(p.atom.interpreted.instruments);
      if (!instrument) return;
      openInstrumentManager(ws, instrument);
    },
  }));
  const style = useStyle(() => ({
    get select(): CSSPartial {
      return {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        width: "100%",
        appearance: "none",
        paddingRight: "2em",
        fontFeatureSettings: "'tnum' 1",
        backgroundColor: cVar(VAR_InputBackground),
        color: cVar(VAR_InputForeground),
        border: cVar(VAR_InputBorder),
        minHeight: "2.5em",
        paddingLeft: ".5em",
        paddingTop: ".5em",
        paddingBottom: ".5em",
        lineHeight: "1.5em",
        borderRadius: 2,
        "&:hover": {
          filter: "brightness(1.1)",
        },
        "&:focus": {
          outline: cVar(VAR_InputFocusOutline),
          outlineColor: varPrimary,
        },
        "&[disabled]": {
          color: fg50,
        },
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <div className={p.className}>
          <Flex>
            <button
              type="button"
              css={style.select}
              onClick={s.toggleSelector}
              disabled={I.editDisabled}
            >
              {s.textLabel || s.placeholder}
            </button>
            {s.hasExactlyOneInstrument && (
              <ConfigButton type="button" onClick={s.openInstrumentPanel}>
                <SymbolIcon icon="settings" />
              </ConfigButton>
            )}
          </Flex>
          {s.shouldRenderSelector &&
            PORTALS.render(
              <AtomSelectInstrumentsPanel
                atom={p.atom}
                onShouldClose={s.toggleSelector}
              />
            )}
        </div>
      )}
    />
  );
};

export default AtomInstrumentSelector;
