import { makeIconDef } from "./makeIconDef";

export const StepsIconDef = {
  steps: makeIconDef("steps", {
    regular: () => (
      <path
        d="M0.5 15.5V11C0.5 10.7239 0.723858 10.5 1 10.5H5C5.27614 10.5 5.5 10.2761 5.5 10V6C5.5 5.72386 5.72386 5.5 6 5.5H10C10.2761 5.5 10.5 5.27614 10.5 5V1C10.5 0.723858 10.7239 0.5 11 0.5H15.5"
        stroke="currentColor"
      />
    ),
  }),
};
