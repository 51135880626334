import { makeIconDef } from "./makeIconDef";

export const SearchIconDef = {
  search: makeIconDef("search", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4805 5.59619C15.6576 7.77335 15.6576 11.3032 13.4805 13.4804C11.3033 15.6576 7.77341 15.6576 5.59625 13.4804C3.41909 11.3032 3.41909 7.77335 5.59625 5.59619C7.77341 3.41903 11.3033 3.41903 13.4805 5.59619ZM14.8558 14.1487C14.6708 13.9637 14.6617 13.6683 14.8179 13.4585C16.7345 10.884 16.5244 7.22589 14.1876 4.88908C11.6199 2.3214 7.45683 2.3214 4.88914 4.88908C2.32146 7.45677 2.32146 11.6198 4.88914 14.1875C7.22593 16.5243 10.8841 16.7344 13.4585 14.8179C13.6683 14.6617 13.9637 14.6708 14.1487 14.8558L20.6465 21.3535C20.8417 21.5488 21.1583 21.5488 21.3536 21.3535C21.5488 21.1583 21.5488 20.8417 21.3536 20.6464L14.8558 14.1487Z"
        fill="currentColor"
      />
    ),
  }),
};
