import { observable } from "mobx";
import * as React from "react";
import { useContext } from "react";
import { AtomContext } from "../../@types";
import { ResizeQuery } from "../../base/hooks/useResizeQuery";
import { ComposerController } from "../../controllers/composer.controller";
import { CCanvasState } from "./useMakeComposerCanvasState";

export type ComposerCanvasContextObject = {
  COMPOSER: ComposerController;
  atomContext: AtomContext;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  resizeQuery: ResizeQuery;
  canvas?: CCanvasState;
};

export const makeComposerCanvasContextObject = (
  COMPOSER?: ComposerController,
  atomContext?: AtomContext,
  ref?: React.MutableRefObject<HTMLDivElement | null>,
  resizeQuery?: ResizeQuery,
  canvas?: CCanvasState
) => {
  const s: ComposerCanvasContextObject = observable({
    COMPOSER,
    atomContext,
    ref,
    resizeQuery,
    canvas,
  }) as ComposerCanvasContextObject;

  canvas?.setCanvasContext(s);

  return s;
};

export const defaultComposerCanvasContext = makeComposerCanvasContextObject();

export const ComposerCanvasContext = React.createContext(
  defaultComposerCanvasContext
);

export const useComposerCanvasContext = () => useContext(ComposerCanvasContext);
export const useComposerCanvasState = () => useComposerCanvasContext().canvas!;
