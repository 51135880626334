import { first } from "lodash-es";
import { AtomType, Chord } from "../../@types";
import { createAtomFactory } from "../../logic/Atom.factory";
import { syncableGroupLikeAtomInitFunction } from "../../utils/syncableGroupLikeAtoms.utils";
import {
  GroupSnapshotFactory,
  makeGroupExtendedMembersFactory,
} from "./Group.model";
import { Chord as TonalChord } from "tonal";

export const ChordSnapshotFactory = () => ({
  ...GroupSnapshotFactory(AtomType.chord),
});

const makeChordExtendedMembersFactory = (C: Chord) => ({
  get displayName(): string {
    return C.name || C.firstMatchedChord?.symbol
      ? `Chord ${C.firstMatchedChord?.symbol}`
      : `Chord ${C._id}`;
  },
  get notesSorted() {
    return [...C.descendantNotes]
      .filter(n => n.startX !== null && n.startY !== null && !!n.width)
      .sort((a, b) => {
        return b.startX! - a.startX! || b.startY! - a.startY! || 0;
      });
  },
  get arpeggio() {
    return C.rulePropertiesFlattened.arpeggio ?? [];
  },
  get matchedChords() {
    return TonalChord.detect(
      C.notesSorted.map(n => n.pitchClass).filter(i => i) as string[]
    ).map(name => {
      const info = TonalChord.get(name);
      return {
        name: info.name,
        symbol: info.symbol,
        aliases: info.aliases.filter(a => !!a),
        quality: info.quality,
        tonic: info.tonic,
        type: info.type,
        root: info.root,
      };
    });
  },
  get firstMatchedChord() {
    return first(C.matchedChords) ?? null;
  },
});

export const makeChord = createAtomFactory<Chord>({
  type: AtomType.chord,
  snapshotFactory: ChordSnapshotFactory,
  extendedPropertiesFactories: [
    makeGroupExtendedMembersFactory,
    makeChordExtendedMembersFactory,
  ],
  init: syncableGroupLikeAtomInitFunction,
});
