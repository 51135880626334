/** @jsxImportSource @emotion/react */
import { action, flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import Button from "../../base/components/Button";
import LargeButton from "../../base/components/LargeButton";
import Panel, {
  PanelCloseButton,
  PanelFooter,
  PanelHeader,
  PanelInner,
} from "../../base/components/Panel";
import Serif from "../../base/components/Serif";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import CompositionListIcon27 from "../../base/icons/27/CompositionList.icon.27";
import IconNewComposition27 from "../../base/icons/27/NewComposition.icon.27";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Artist } from "../../models/Artist.model";
import { Composition } from "../../models/Composition.model";
import CompositionFinder from "../directory/CompositionFinder";

type ArtistAddContentPanelProps = {
  artist: Artist;
  onShouldClose?: () => void;
};

const ArtistAddContentPanel: React.FC<ArtistAddContentPanelProps> = props => {
  const p = useProps(props);
  const { COMPOSITIONS } = useControllers();
  const s = useStore(() => ({
    source: "",
    sourceSetter: (v: string) => action(() => (s.source = v)),
    get width() {
      return 400;
    },
    get sourcePicker() {
      return (
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridAutoRows: "1fr",
            gridGap: ".5em",
          }}
        >
          <LargeButton
            Icon={<CompositionListIcon27 />}
            Label="Existing composition(s)"
            onClick={s.sourceSetter("existing-compositions")}
          />
          <LargeButton
            Icon={<IconNewComposition27 />}
            onClick={s.createNewCompInThisArtist}
            Label={
              <>
                New
                <br />
                composition
              </>
            }
          />
          {/* <LargeButton
                Icon={<IconArtistList27 />}
                Label="Existing artist(s)"
              />
              <LargeButton
                Icon={<IconNewArtist27 />}
                Label={
                  <>
                    New
                    <br />
                    sub-artist
                  </>
                }
              /> */}
        </div>
      );
    },
    selectedCompositions: new Set<Composition>(),
    get itemPicker() {
      switch (s.source) {
        case "existing-compositions": {
          return (
            <CompositionFinder
              onConfirm={s.addCompositionsToArtist}
              selectionSet={s.selectedCompositions}
            />
          );
        }
      }
      return null;
    },
    addCompositionsToArtist: () => {
      Array.from(s.selectedCompositions).forEach(c => {
        c.$.artistIds.push(p.artist._id);
      });
      p.onShouldClose?.();
    },
    createNewCompInThisArtist: flow(function* () {}),
    addSelectionToArtist: () => {
      if (s.source === "existing-compositions") {
        s.addCompositionsToArtist();
      }
    },
    get footer() {
      return (
        <PanelFooter sticky css={{ textAlign: "right" }}>
          <Button onClick={s.addSelectionToArtist}>Set artist</Button>
        </PanelFooter>
      );
    },
  }));

  useOnMount(() => {
    // s.sourceSetter("existing-compositions")();
  });

  return (
    <Observer
      children={() => (
        <Panel
          id="artist-add-item"
          title="Add content to artist"
          fullscreen
          canEscape
          onShouldClose={p.onShouldClose}
          width={s.width}
        >
          <PanelHeader End={<PanelCloseButton onClick={p.onShouldClose} />}>
            <h2>
              <Serif>Add to this artist</Serif>
            </h2>
          </PanelHeader>
          <PanelInner>{s.source ? s.itemPicker : s.sourcePicker}</PanelInner>
          {s.source && s.footer}
        </Panel>
      )}
    />
  );
};

export default ArtistAddContentPanel;
