import { useStore } from "../utils/mobx.utils";

export const useObservableRef = <T extends Element = HTMLDivElement>(
  initialValue?: T
) => {
  const ref = useStore(() => ({
    _current: initialValue || null,
    // use of explicit getter and setter to avoid mobx strict-mode warnings,
    // otherwise modifying `current` value will not happen through action()
    get current() {
      return ref._current;
    },
    set current(el: T | null) {
      ref._current = el;
    },
  }));
  return ref as { current: T | null };
};

export type ObservableRef<T extends HTMLElement | SVGElement = HTMLDivElement> =
  {
    current: T | null;
  };
