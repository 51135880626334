/** @jsxImportSource @emotion/react */
import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { CSSPartial } from "../../@types/css.types";
import { useControllers } from "../../hooks/rootContext.hooks";
import { useCreateResizeQueryWithRef } from "../../hooks/useCreateResizeQueryWithRef.hook";
import { withOpacity } from "../../utils/colors.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import resolveAfter from "../../utils/waiters.utils";
import SymbolIcon from "../SymbolIcon";
import ContextMenu, {
  ContextMenuController,
  ContextMenuItemConfigObject,
} from "./ContextMenu";

type ContextMenuItemProps = {
  controller?: ContextMenuController;
  config: ContextMenuItemConfigObject;
  onClick: (config: ContextMenuItemConfigObject) => void;
  noIcon?: boolean;
  color?: string | null;
};

const ContextMenuItem: React.FC<ContextMenuItemProps> = props => {
  const { THEME } = useControllers();
  const p = useProps(props);
  const { ref, query } = useCreateResizeQueryWithRef();
  const _ = useStore(() => ({
    shouldShowSubmenu: false,
  }));
  const s = useStore(() => ({
    get shouldShowSubmenu() {
      return p.controller?.openedSubmenu === p.config ?? _.shouldShowSubmenu;
    },
    set shouldShowSubmenu(v) {
      if (p.controller) {
        if (v) {
          p.controller.openedSubmenu = p.config;
        } else {
          if (p.controller.openedSubmenu === p.config) {
            p.controller.openedSubmenu = null;
          }
        }
      } else _.shouldShowSubmenu = v;
    },
    get disabled() {
      return (
        p.config.disabled || (!p.config.action && !p.config.children?.length)
      );
    },
    get submenuOrigin() {
      return {
        x: (query.boundingBox?.x ?? 0) + query.width,
        y: query.boundingBox?.y ?? 0,
      };
    },
    handleClick: (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      p.onClick(p.config);
    },
    showSubmenu: () => {
      if (!!p.config.children?.length) {
        s.shouldShowSubmenu = true;
      }
    },
    hideSubmenu: flow(function* () {
      yield resolveAfter();
      s.shouldShowSubmenu = false;
    }),
    toggleSubmenu: () => {
      if (s.shouldShowSubmenu) s.hideSubmenu();
      else s.showSubmenu();
    },
    handleMouseEnter: () => {
      s.showSubmenu();
    },
    handleTouchStart: () => {
      s.showSubmenu();
    },
    handleMouseLeave: () => {
      s.hideSubmenu();
    },
    handleSubmenuExecuteItem: () => {
      p.controller?.close();
    },
  }));

  const style = useStore(() => ({
    get color() {
      return p.config.color ?? p.color ?? THEME.primary;
    },
    get item(): CSSPartial {
      return {
        display: "flex",
        alignItems: "center",
        position: "relative",
        padding: p.noIcon ? ".62em 1em" : ".5em 1em .5em .5em",
        backgroundColor: s.shouldShowSubmenu
          ? withOpacity(style.color, 0.3)
          : "transparent",
        borderRadius: ".125em",
        ...(s.disabled
          ? {
              opacity: 0.38,
              cursor: "not-allowed",
            }
          : {
              "&:hover": {
                backgroundColor: withOpacity(style.color, 0.3),
              },
            }),
      };
    },
    get label(): CSSPartial {
      return {
        fontWeight: 500,
        fontSize: "1.2rem",
        flex: "1 1 auto",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
      };
    },
    get icon(): CSSPartial {
      return {
        flex: "1 1 auto",
        marginRight: ".5em",
      };
    },
    get labelText(): CSSPartial {
      return {
        flex: "1 1 auto",
        marginRight: ".5em",
      };
    },
    get submenuTriangleIndicator(): CSSPartial {
      return {
        flex: "0 0 auto",
        fontSize: "60%",
        verticalAlign: "middle",
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <div
          className="ContextMenuItem"
          css={style.item}
          key={p.config.identifier}
          onClick={s.handleClick}
          onMouseEnter={s.handleMouseEnter}
          onMouseLeave={s.handleMouseLeave}
          onTouchStart={s.handleTouchStart}
          data-displaying-submenu={s.shouldShowSubmenu}
          ref={ref}
        >
          {!p.noIcon && (
            <SymbolIcon icon={p.config.icon ?? "blank"} css={style.icon} />
          )}
          <div css={style.label}>
            <span css={style.labelText}>{p.config.Label}</span>
            {!!p.config.children?.length && (
              <span css={style.submenuTriangleIndicator}>►</span>
            )}
          </div>
          {s.shouldShowSubmenu && (
            <ContextMenu
              origin={s.submenuOrigin}
              configSet={p.config.children}
              onShouldClose={s.hideSubmenu}
              onExecuteItem={s.handleSubmenuExecuteItem}
              color={p.color}
            />
          )}
        </div>
      )}
    />
  );
};

export default ContextMenuItem;
