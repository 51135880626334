import { normalizeString } from "./string.utils";

export const makeSlug = (string?: string) => {
  if (string === undefined || string === null) return "";
  return normalizeString(string)
    .toLowerCase()
    .trim()
    .replace(/(\s|_)+/g, "-")
    .replace(/&/g, " and ")
    .replace(/[^\w\-]+/g, "-")
    .replace(/\-+/g, "-")
    .replace(/^\-/g, "")
    .replace(/\-$/g, "");
};
