import { makeIconDef } from "./makeIconDef";

export const MidiConnectorIconDef = {
  "midi-connector": makeIconDef("midi-connector", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3751 20.2792C17.8888 19.4353 20.5001 16.2726 20.5001 12.5C20.5001 8.08174 16.9184 4.5 12.5 4.5C8.08174 4.5 4.5 8.08174 4.5 12.5C4.5 16.2725 7.11119 19.4351 10.6248 20.2791C10.7574 19.3623 11.5464 18.6579 12.4999 18.6579C13.4535 18.6579 14.2425 19.3623 14.3751 20.2792ZM21.5001 12.5C21.5001 17.4706 17.4706 21.5001 12.5 21.5001C7.52946 21.5001 3.5 17.4706 3.5 12.5C3.5 7.52946 7.52946 3.5 12.5 3.5C17.4706 3.5 21.5001 7.52946 21.5001 12.5ZM7.81166 13.6288C8.42947 13.6288 8.93031 13.128 8.93031 12.5102C8.93031 11.8924 8.42947 11.3915 7.81166 11.3915C7.19385 11.3915 6.69301 11.8924 6.69301 12.5102C6.69301 13.128 7.19385 13.6288 7.81166 13.6288ZM10.3035 9.19492C10.3035 9.81273 9.80263 10.3136 9.18482 10.3136C8.56701 10.3136 8.06618 9.81273 8.06618 9.19492C8.06618 8.57711 8.56701 8.07627 9.18482 8.07627C9.80263 8.07627 10.3035 8.57711 10.3035 9.19492ZM12.49 8.93051C13.1078 8.93051 13.6087 8.42968 13.6087 7.81187C13.6087 7.19406 13.1078 6.69322 12.49 6.69322C11.8722 6.69322 11.3714 7.19406 11.3714 7.81187C11.3714 8.42968 11.8722 8.93051 12.49 8.93051ZM16.9237 9.18476C16.9237 9.80257 16.4229 10.3034 15.8051 10.3034C15.1872 10.3034 14.6864 9.80257 14.6864 9.18476C14.6864 8.56695 15.1872 8.06611 15.8051 8.06611C16.4229 8.06611 16.9237 8.56695 16.9237 9.18476ZM17.188 13.6085C17.8058 13.6085 18.3067 13.1076 18.3067 12.4898C18.3067 11.872 17.8058 11.3712 17.188 11.3712C16.5702 11.3712 16.0694 11.872 16.0694 12.4898C16.0694 13.1076 16.5702 13.6085 17.188 13.6085Z"
        fill="currentColor"
      />
    ),
  }),
};
