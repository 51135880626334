import { AtomBaseSnapshot, AtomType } from "../../@types";
import { BarSnapshotFactory } from "./Bar.model";
import { ChordSnapshotFactory } from "./Chord.models";
import { GroupSnapshotFactory } from "./Group.model";
import { KeyframeSnapshotFactory } from "./Keyframe.model";
import { NoteSnapshotFactory } from "./Note.model";
import { OrnamentSnapshotFactory } from "./Ornament.model";
import { PatternSnapshotFactory } from "./Pattern.model";
import { ReplicaSnapshotFactory } from "./Replica.model";
import { SectionSnapshotFactory } from "./Section.model";
import { TextNodeSnapshotFactory } from "./TextNode.model";
import { VoiceSnapshotFactory } from "./Voice.model";

const makeDef = <T extends AtomBaseSnapshot>(factory: () => T) => {
  const baseline = factory();
  const { _id, type, ...properties } = baseline;
  return {
    baseline,
    properties: Object.keys(properties) as Omit<keyof T, "_id" | "type">[],
  };
};

export const BaselineAtomSnapshotSet = {
  [AtomType.bar]: makeDef(BarSnapshotFactory),
  [AtomType.chord]: makeDef(ChordSnapshotFactory),
  [AtomType.group]: makeDef(GroupSnapshotFactory),
  [AtomType.keyframe]: makeDef(KeyframeSnapshotFactory),
  [AtomType.note]: makeDef(NoteSnapshotFactory),
  [AtomType.ornament]: makeDef(OrnamentSnapshotFactory),
  [AtomType.pattern]: makeDef(PatternSnapshotFactory),
  [AtomType.replica]: makeDef(ReplicaSnapshotFactory),
  [AtomType.section]: makeDef(SectionSnapshotFactory),
  [AtomType.textNode]: makeDef(TextNodeSnapshotFactory),
  [AtomType.voice]: makeDef(VoiceSnapshotFactory),
};
