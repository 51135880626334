/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, Section } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import Spacing from "../../base/components/Spacing";
import PillTag from "../../base/components/PillTag";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import TimeSignatureEditor from "../composer/TimeSignatureEditor";
import TextInput from "../../base/components/TextInput";
import { css } from "@emotion/react";
import { autoPluralizeWithNumber } from "../../base/utils/string.utils";
import { varFontSerif } from "../../constants/cssCustomProperties.constants";
import AllCaps from "../../base/components/AllCaps.component";
import AtomRulesetEditor from "../composer/AtomRulesetEditor";
import { useComposer } from "../composer/ComposerApp.context";

type P = {
  atom: Atom;
};

const style = {
  grid: {
    display: "grid",
    gridGap: "1em",
    gridTemplateColumns: "1fr 1fr 1fr",
    fontSize: "1.2rem",
  } as CSSPartial,
  header: {
    display: "flex",
    justifyContent: "space-between",
  } as CSSPartial,
  headerInner: {
    paddingRight: "1em",
    "> * + *": {
      marginTop: ".5rem",
    },
  } as CSSPartial,
  title: {
    fontSize: "1.8rem",
    fontFamily: varFontSerif,
    fontWeight: 300,
    // fontStyle: "italic",
  } as CSSPartial,
  form: {
    display: "grid",
    gridGap: "1em",
    gridTemplateColumns: "1fr 1fr",
    fontSize: "1.2rem",
  } as CSSPartial,
};

const SectionPropertyPanel: React.FC<P> = props => {
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    get section() {
      return p.atom as Section;
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="SectionPropertyPanel">
          <header css={style.header}>
            <div css={style.headerInner}>
              <AllCaps>Section #{s.section.sectionNumber}</AllCaps>
              <h3 css={style.title}>{s.section.name}</h3>
              <p>
                {autoPluralizeWithNumber(s.section.bars.length, "bar")}, BPM ={" "}
                {s.section.bpm}
              </p>
            </div>
            <div>
              <PillTag color={s.section.voice?.appearance?.colorInContext}>
                #{s.section._id}
              </PillTag>
            </div>
          </header>
          <Spacing />
          <div css={style.form}>
            <TextInput
              css={css`
                grid-column-end: span 2;
              `}
              form={s.section}
              field="name"
              taskName="Edit section name"
              Label="Section name"
              mergeableId={`edit-section-name-${s.section._id}`}
              autoComplete="off"
              disabled={I.editDisabled}
            />
            {s.section.definedStartingBar && (
              <TimeSignatureEditor
                form={s.section.definedStartingBar}
                field="timeSignature"
                Label="Time signature"
                disabled={I.editDisabled}
              />
            )}
          </div>
          <Spacing />
          <AtomRulesetEditor atom={s.section} />
        </div>
      )}
    />
  );
};

export default SectionPropertyPanel;
