/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import * as React from "react";
import { VAR_SeparatorBorder } from "../../constants/cssCustomProperties.constants";
import { cVar } from "../utils/customProperties.utils";
import { useProps } from "../utils/mobx.utils";

interface LineSeparatorProps {
  opacity?: number;
  margin?: string | number;
  dashed?: boolean;
}

const LineSeparator: React.FC<LineSeparatorProps> = observer(props => {
  const p = useProps(props);
  return (
    <span
      className="LineSeparator"
      css={{
        display: "block",
        borderTop: cVar(VAR_SeparatorBorder),
        borderTopStyle: p.dashed ? "dashed" : "solid",
        height: 0,
        margin: p.margin ?? "1em 0",
        opacity: p.opacity ?? 1,
      }}
    />
  );
});

export default LineSeparator;
