/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { useComposer } from "../../components/composer/ComposerApp.context";
import {
  varPrimary,
  varPrimary20,
  varPrimary40,
} from "../../constants/cssCustomProperties.constants";
import { usePropsFast, useStore } from "../utils/mobx.utils";
import { ResetIcon } from "./icons/Reset.icon";
import styled from "@emotion/styled";
import cx from "../utils/className.utils";

type ResetButtonProps<T extends {}, R = null> = {
  form: T;
  field: (string | number) & keyof T;
  default?: R;
  className?: string;
};

export const ResetButtonButton = styled.button`
  appearance: none;
  background-color: transparent;
  color: inherit;
  padding: 0.1em 0.25em;
  border: 0;
  border-radius: 0.2em;
  font-weight: 500;
  cursor: pointer;
  background-color: ${varPrimary20};
  font-size: 80%;
  text-transform: uppercase;
  margin-top: -0.1em;
  margin-bottom: -0.1em;
  display: inline-flex;
  align-items: center;
  > * {
    + * {
      margin-left: 0.25em;
    }
  }
  > div {
    margin-right: 0.1em;
  }
  &:hover {
    color: ${varPrimary};
    background-color: ${varPrimary40};
  }
`;

function ResetButton<T extends {}, R = null>(
  props: PropsWithChildren<ResetButtonProps<T, R>>
) {
  const p = usePropsFast(props);
  const I = useComposer();
  const s = useStore(() => ({
    reset: () => {
      I.runInHistory(`Reset "${p.field}" value`, () => {
        Reflect.set(p.form, p.field, p.default ?? null);
      });
    },
  }));
  return (
    <Observer
      children={() => (
        <ResetButtonButton
          className={cx("ResetButton", p.className)}
          onClick={s.reset}
          type="button"
        >
          <ResetIcon />
          {props.children ? <div>{props.children}</div> : null}
        </ResetButtonButton>
      )}
    />
  );
}

export default ResetButton;
