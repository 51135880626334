/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../../base/@types/css.types";
import { useControllers } from "../../../base/hooks/rootContext.hooks";
import { flex } from "../../../base/styles/helpers/flex.styleHelper";
import { border } from "../../../base/styles/helpers/shorthands.styleHelpers";
import { withOpacity } from "../../../base/utils/colors.utils";
import { useProps, useStore } from "../../../base/utils/mobx.utils";
import { BlanchetHarpsichordSamplerName } from "../BlanchetHarpsichord.instrument";
import { BlanchetColors } from "./BlanchetHarpsichordControlPanel";

interface ManualSelectButtonProps {
  manual: BlanchetHarpsichordSamplerName;
  displayName?: string;
  activated?: boolean;
  onClick: () => void;
}

const BlanchetManualSelectButton: React.FC<ManualSelectButtonProps> = props => {
  const { THEME } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get color(): string {
      return p.activated ? BlanchetColors.red : THEME.fg;
    },
    get containerStyle(): CSSPartial {
      return {
        position: "relative",
        ...flex({
          inline: true,
          reverse: true,
          align: "center",
          justify: "center",
        }),
        textAlign: "left",
        padding: ".5em",
        borderRadius: 3,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: withOpacity(s.color, THEME.isDarkTheme ? 0.3 : 0.1),
        },
        "&:before": {
          content: "''",
          position: "absolute",
          display: "block",
          right: "100%",
          top: "50%",
          width: "1em",
          height: 1,
          backgroundColor: s.color,
        },
      };
    },
    get bulbStyle(): CSSPartial {
      return {
        display: "inline-block",
        border: border(2, withOpacity(s.color, 0.3)),
        backgroundColor: p.activated ? s.color : withOpacity(s.color, 0.2),
        width: 9,
        height: 9,
        borderRadius: "50%",
      };
    },
    get textLabelStyle(): CSSPartial {
      return {
        fontSize: "1rem",
        textTransform: "uppercase",
        fontWeight: 700,
        whiteSpace: "nowrap",
        opacity: p.activated ? 1 : 0.5,
        width: "3.8em",
        color: s.color,
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <div
          className="BlanchetManualSelectButton"
          css={s.containerStyle}
          onClick={p.onClick}
        >
          <span css={s.bulbStyle}></span>
          <strong css={s.textLabelStyle}>{p.displayName}</strong>
        </div>
      )}
    />
  );
};

export default BlanchetManualSelectButton;
