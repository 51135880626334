import dayjs from "dayjs";
import { action, flow, observable, reaction, toJS } from "mobx";
import { Link } from "react-router-dom";
import LoginForm from "../base/components/LoginDialog";
import Spacing from "../base/components/Spacing";
import {
  AUTH_TIME_LAST_REFRESHED_TOKEN_KEY,
  AUTH_TOKEN_KEY,
  AUTH_USER_KEY,
} from "../base/constants/storageKeys.constants";
import { removeOneFromArray } from "../base/utils/array.utils";
import { assertTruthy } from "../base/utils/assert.utils";
import { reportError } from "../base/utils/errors.utils";
import { isStandardModel } from "../base/utils/models.utils";
import { getUrlParams } from "../base/utils/urlParams.utils";
import { ModelName } from "../constants/modelName.constants";
import { User, UserSnapshot } from "../models/User.model";
import { clearRequestMap } from "../utils/request.utils";
import { ApiController } from "./api.controller";
import { LocalDBController } from "./localDB.controller";
import { StorageController } from "./storage.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { WEBSITE_URL } from "../env";

const debug = false;

export type AuthController = ReturnType<typeof makeAuthController>;

export type LoginRequestReturnType = {
  accessToken: string;
  user: UserSnapshot;
};

export const makeAuthController = () => {
  const _ = observable({
    userSnapshotOnFetch: null as UserSnapshot | null,
  });
  const c = observable({
    ...makeControllerBase("AUTH"),
    user: null as User | null,
    get API(): ApiController | undefined {
      return c.ROOT?.API;
    },
    get STORAGE(): StorageController | undefined {
      return c.ROOT?.STORAGE;
    },
    get LOCALDB(): LocalDBController | undefined {
      return c.ROOT?.LOCALDB;
    },
    get isAuthenticated(): boolean {
      return Boolean(c.user?._id);
    },
    get isAdmin() {
      if (!process.env.REACT_APP_ADMIN_ROLE_ID) return false;
      return (
        _.userSnapshotOnFetch?.roles.includes(
          process.env.REACT_APP_ADMIN_ROLE_ID
        ) ?? false
      );
    },
    beforeInitChecksComplete: false,
    login: async (form: { usernameOrEmail: string; password: string }) => {
      if (!c.API) {
        throw Error("API Controller Not Ready");
      }
      const responseData = await c.API.postRaw<LoginRequestReturnType>(
        "/auth/login",
        {
          ...form,
          socketId: c.ROOT?.SYNC.socket.id ?? null,
        }
      );
      const { accessToken, user } = responseData || {};
      if (!user || !accessToken) {
        throw Error("Failed to log in...");
      }
      storeToken(accessToken);
      c.ROOT?.STORAGE.set(AUTH_TIME_LAST_REFRESHED_TOKEN_KEY, new Date());
      setUserInfo(user);
      return user;
    },
    getDataAfterAuthenticationSuccess: () => {
      c.ROOT?.COLLECTIONS.getAllOwn();
      c.ROOT?.ARTISTS.getAllOwn();
    },
    showQuickLoginDialog: (onSuccess?: () => void) => {
      if (/^\/auth\/(login|signup)/.exec(window.location.pathname)) return;
      const loginDialogName = "session-timed-out-login";
      c.ROOT?.DIALOGS.present({
        name: loginDialogName,
        Heading: c.isAuthenticated ? "Session timed out" : "Please log in",
        Body: (
          <>
            <p>
              Please sign in again, or{" "}
              <Link
                to={WEBSITE_URL}
                onClick={() => {
                  c.ROOT?.DIALOGS.dismiss(loginDialogName);
                }}
              >
                go back home
              </Link>
              .
            </p>
            <Spacing />
            <LoginForm
              includeSubmitButton
              onSuccess={() => {
                c.ROOT?.DIALOGS.dismiss(loginDialogName);
                onSuccess?.();
              }}
            />
          </>
        ),
        actions: [],
      });
    },

    logout: () => {
      c.API?.postRaw("/auth/logout", {
        socketId: c.ROOT?.SYNC.socket.id ?? null,
      });
      c.user = null;
      _.userSnapshotOnFetch = null;
      clearRequestMap();
      c.STORAGE?.remove(AUTH_TOKEN_KEY);
      c.STORAGE?.remove(AUTH_USER_KEY);
      c.STORAGE?.remove(AUTH_TIME_LAST_REFRESHED_TOKEN_KEY);
      c.API?.reset();
      c.ROOT?.NAVIGATOR.navigateTo("/");
    },

    onAuthenticated: (fn: () => void, once?: boolean) => {
      if (c.isAuthenticated) {
        fn();
        if (once) return;
      }
      runOnAuthenticated.unshift({ fn, once: once ?? false });
    },

    forgotPassword: async (form: { email: string }) => {},

    signUp: async (form: { username: string; password: string }) => {
      if (!process.env.REACT_APP_REGISTRATION_OPEN) {
        throw Error("Registration is unavailable");
      }
      if (!c.API) {
        throw Error("API Controller Not Ready");
      }
      await c.API.postRaw("/auth/signup", form);
    },

    saveCurrentUser: async () => {
      if (!c.API) {
        throw Error("API Controller Not Ready");
      }
      if (!c.user) {
        console.warn("No authenticated user to save.");
        return;
      }
      await c.API.patch<User>("/auth/user", ModelName.users, c.user);
    },

    updatePassword: async (form: { password: string; confirm: string }) => {
      if (form.password !== form.confirm) {
        throw Error("Passwords do not match.");
      }
      return await c.API!.postRaw<UserSnapshot>(`/auth/update-password`, form);
    },
  });

  const verifyToken = async (token: string) => {
    assertTruthy(
      c.ROOT,
      "ROOT Controller must be available before verifying token"
    );
    const timeLastRetrievedToken = c.ROOT.STORAGE.get<string>(
      AUTH_TIME_LAST_REFRESHED_TOKEN_KEY
    );
    if (timeLastRetrievedToken) {
      const tokenWillExpireSoon = dayjs(timeLastRetrievedToken)
        .add(5, "days")
        .isBefore(new Date());
      if (tokenWillExpireSoon) {
        if (debug)
          console.info(
            "Token will expire soon or have expired, attempting to retrieve a refreshed token..."
          );
        try {
          await refreshToken();
          if (debug) console.info("Token refresh success!");
          return true;
        } catch (e) {
          if (debug) console.info("Token refresh failed, logging out...");
          return false;
        }
      }
    }
    try {
      const url = "/auth/verify-token";
      const { data: user } = await c.ROOT.API.postRaw<{
        message: "string";
        data?: User;
      }>(url, ModelName.users);
      if (user) {
        setUserInfo(user);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      reportError(e);
    }
  };

  const refreshToken = async () => {
    try {
      const url = "/auth/refresh-token";
      const { user } = await c.ROOT!.API.postRaw<LoginRequestReturnType>(
        url,
        ModelName.users
      );
      if (user) {
        setUserInfo(user);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      reportError(e);
    }
  };

  const runOnAuthenticated = [] as { fn: () => void; once: boolean }[];

  const setUserInfo = action((u: User | UserSnapshot) => {
    if (!c.LOCALDB) {
      if (debug) console.warn("LOCALDB unavailable while setting user info");
    } else {
      const user = isStandardModel(u)
        ? u
        : c.LOCALDB.setOrMerge<User>(ModelName.users, u);
      _.userSnapshotOnFetch = toJS(user.$);
      c.user = user;
      c.STORAGE?.set(AUTH_USER_KEY, c.user.$);
    }
    c.beforeInitChecksComplete = true;
  });

  const setupAuthStateWatcher = () =>
    reaction(
      () => c.isAuthenticated,
      function (isAuthenticated) {
        const { NAVIGATOR } = c.ROOT!;
        const params = getUrlParams();
        const pathname = window.location.pathname;
        const isOnPublicRoute = /^\/play/.exec(pathname);
        if (isOnPublicRoute) return;
        const isOnLogoutPage = /^\/auth\/(logout)/.exec(pathname);
        const isOnLoginPage = /^\/auth\/(login)/.exec(pathname);
        const isOnSignUpPage = /^\/auth\/(signup)/.exec(pathname);
        if (isAuthenticated) {
          if (debug) console.info("reaction: authenticated, redirecting");
          for (const def of [...runOnAuthenticated].reverse()) {
            def.fn();
            if (def.once) removeOneFromArray(runOnAuthenticated, def);
          }
          const redirectedFrom =
            params["redirectedFrom"] !== "/auth/logout"
              ? params["redirectedFrom"]
              : undefined;
          if (isOnLoginPage || isOnSignUpPage) {
            NAVIGATOR.navigateTo(redirectedFrom || "/app");
          }
          c.getDataAfterAuthenticationSuccess();
        } else {
          if (debug)
            console.info(
              "reaction: not authenticated, redirecting to /auth/login"
            );
          if (isOnLoginPage || isOnSignUpPage) return;
          NAVIGATOR.navigateTo("/auth/login");
        }
      }
    );

  const getStoredToken = () => {
    return c.ROOT?.STORAGE.get<string>(AUTH_TOKEN_KEY) || null;
  };

  let tokenValueInLastRead = null as string | null;

  const checkExistingToken = flow(function* () {
    const token: string | null = getStoredToken();
    storeToken(token);
    if (token && token !== tokenValueInLastRead) {
      const tokenIsValid: boolean = yield verifyToken(token);
      if (!tokenIsValid) {
        if (!tokenValueInLastRead) {
          c.logout();
        } else {
          c.showQuickLoginDialog();
        }
      }
    } else if (tokenValueInLastRead && !token) {
      c.showQuickLoginDialog();
    }
    tokenValueInLastRead = token;
    c.beforeInitChecksComplete = true;
    return;
  });

  const storeToken = (token: string | null) => {
    const { STORAGE, API } = c.ROOT! || {};
    if (!token) {
      STORAGE.remove(AUTH_TOKEN_KEY);
      return;
    }
    API?.setLastToken(token);
    STORAGE.set(AUTH_TOKEN_KEY, token);
  };

  c.init = makeRootControllerChildInitFn(
    c,
    flow(function* () {
      setupAuthStateWatcher();
      yield checkExistingToken();
      window.addEventListener("focus", () => {
        checkExistingToken();
      });
      c.ready = true;
    })
  );

  return c;
};
