/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import {
  fg00,
  fg20,
  fg60,
} from "../../constants/cssCustomProperties.constants";
import { WorkspacePanelController } from "../../controllers/composer/workspace.controller";
import { CSSPartial } from "../@types/css.types";
import { border } from "../styles/helpers/shorthands.styleHelpers";
import {
  getClientXFromMouseOrTouchEvent,
  getClientYFromMouseOrTouchEvent,
} from "../utils/events.utils";
import { useProps, useStore } from "../utils/mobx.utils";

type PanelResizeControlSetProps = {
  controller?: WorkspacePanelController;
  onResize?: (delta: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) => void;
  onReset?: WorkspacePanelController["resetDimension"];
};

const style = {
  resizeHandleTop: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: 9,
    zIndex: 100,
    cursor: "ns-resize",
    backgroundColor: fg00,
    borderTop: border(1, "transparent"),
    "&:hover": {
      borderColor: fg20,
    },
  } as CSSPartial,
  resizeHandleBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 9,
    zIndex: 100,
    cursor: "ns-resize",
    backgroundColor: fg00,
    borderBottom: border(1, "transparent"),
    "&:hover": {
      borderColor: fg20,
    },
  } as CSSPartial,
  resizeHandleLeft: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    width: 9,
    zIndex: 100,
    cursor: "ew-resize",
    backgroundColor: fg00,
    borderLeft: border(1, "transparent"),
    "&:hover": {
      borderColor: fg20,
    },
  } as CSSPartial,
  resizeHandleRight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    width: 9,
    zIndex: 100,
    cursor: "ew-resize",
    backgroundColor: fg00,
    borderRight: border(1, "transparent"),
    "&:hover": {
      borderColor: fg20,
    },
  } as CSSPartial,
  resizeHandleTopRight: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 9,
    height: 9,
    zIndex: 100,
    cursor: "nesw-resize",
    backgroundColor: fg00,
    "&:hover": {
      borderColor: fg20,
      "&:before, &:after": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 0,
      },
      "&:before": {
        height: "3em",
        width: 1,
        backgroundImage: `linear-gradient(to bottom, ${fg60}, ${fg00})`,
      },
      "&:after": {
        bottom: 0,
        width: "3em",
        height: 1,
        backgroundImage: `linear-gradient(to left, ${fg60}, ${fg00})`,
      },
    },
  } as CSSPartial,
  resizeHandleBottomRight: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: 9,
    height: 9,
    zIndex: 100,
    cursor: "nwse-resize",
    backgroundColor: fg00,
    "&:hover": {
      borderColor: fg20,
      "&:before, &:after": {
        content: '""',
        display: "block",
        position: "absolute",
        bottom: 0,
        right: 0,
      },
      "&:before": {
        height: "3em",
        width: 1,
        backgroundImage: `linear-gradient(to top, ${fg60}, ${fg00})`,
      },
      "&:after": {
        bottom: 0,
        width: "3em",
        height: 1,
        backgroundImage: `linear-gradient(to left, ${fg60}, ${fg00})`,
      },
    },
  } as CSSPartial,
  resizeHandleTopLeft: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 9,
    height: 9,
    zIndex: 100,
    cursor: "nwse-resize",
    backgroundColor: fg00,
    "&:hover": {
      borderColor: fg20,
      "&:before, &:after": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
      },
      "&:before": {
        height: "3em",
        width: 1,
        backgroundImage: `linear-gradient(to bottom, ${fg60}, ${fg00})`,
      },
      "&:after": {
        bottom: 0,
        width: "3em",
        height: 1,
        backgroundImage: `linear-gradient(to right, ${fg60}, ${fg00})`,
      },
    },
  } as CSSPartial,
  resizeHandleBottomLeft: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: 9,
    height: 9,
    zIndex: 100,
    cursor: "nesw-resize",
    backgroundColor: fg00,
    "&:hover": {
      borderColor: fg20,
      "&:before, &:after": {
        content: '""',
        display: "block",
        position: "absolute",
        bottom: 0,
        left: 0,
      },
      "&:before": {
        height: "3em",
        width: 1,
        backgroundImage: `linear-gradient(to top, ${fg60}, ${fg00})`,
      },
      "&:after": {
        bottom: 0,
        width: "3em",
        height: 1,
        backgroundImage: `linear-gradient(to right, ${fg60}, ${fg00})`,
      },
    },
  } as CSSPartial,
};

const PanelResizeHandleSet: React.FC<PanelResizeControlSetProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    prevPointerPosition: {
      x: 0,
      y: 0,
    },
    handleMoveFromLeftEdge: (e: MouseEvent | TouchEvent) => {
      const deltaX =
        getClientXFromMouseOrTouchEvent(e) - s.prevPointerPosition.x;
      if (p.controller) {
        if (p.controller.params.width < 375 && deltaX < 0) return;
        p.controller.params.x += deltaX;
        p.controller.params.width -= deltaX;
      } else {
        p.onResize?.({
          x: deltaX,
          y: 0,
          width: -deltaX,
          height: 0,
        });
      }
      s.prevPointerPosition.x = getClientXFromMouseOrTouchEvent(e);
    },
    handleMoveFromRightEdge: (e: MouseEvent | TouchEvent) => {
      const deltaX =
        getClientXFromMouseOrTouchEvent(e) - s.prevPointerPosition.x;
      if (p.controller) {
        if (p.controller.params.width < 375 && deltaX < 0) return;
        p.controller.params.width += deltaX;
      } else {
        p.onResize?.({
          x: 0,
          y: 0,
          width: deltaX,
          height: 0,
        });
      }
      s.prevPointerPosition.x = getClientXFromMouseOrTouchEvent(e);
    },
    handleMoveFromTopEdge: (e: MouseEvent | TouchEvent) => {
      const deltaY =
        getClientYFromMouseOrTouchEvent(e) - s.prevPointerPosition.y;
      if (p.controller) {
        if (p.controller.params.height < 375 && deltaY > 0) return;
        p.controller.params.y += deltaY;
        p.controller.params.height -= deltaY;
      } else {
        p.onResize?.({
          x: 0,
          y: deltaY,
          width: 0,
          height: -deltaY,
        });
      }
      s.prevPointerPosition.y = getClientYFromMouseOrTouchEvent(e);
    },
    handleMoveFromBottomEdge: (e: MouseEvent | TouchEvent) => {
      const deltaY =
        getClientYFromMouseOrTouchEvent(e) - s.prevPointerPosition.y;
      if (p.controller) {
        if (p.controller.params.height < 375 && deltaY < 0) return;
        p.controller.params.height += deltaY;
      } else {
        p.onResize?.({
          x: 0,
          y: 0,
          width: 0,
          height: deltaY,
        });
      }
      s.prevPointerPosition.y = getClientYFromMouseOrTouchEvent(e);
    },
    stopWindowListeners: () => {
      window.removeEventListener("mousemove", s.handleMoveFromTopEdge);
      window.removeEventListener("mousemove", s.handleMoveFromLeftEdge);
      window.removeEventListener("mousemove", s.handleMoveFromRightEdge);
      window.removeEventListener("mousemove", s.handleMoveFromBottomEdge);
      window.removeEventListener("touchmove", s.handleMoveFromTopEdge);
      window.removeEventListener("touchmove", s.handleMoveFromLeftEdge);
      window.removeEventListener("touchmove", s.handleMoveFromRightEdge);
      window.removeEventListener("touchmove", s.handleMoveFromBottomEdge);
      window.removeEventListener("mouseup", s.stopWindowListeners);
      window.removeEventListener("touchend", s.stopWindowListeners);
      window.removeEventListener("blur", s.stopWindowListeners);
    },
    handleResizeFromTopEdgeStart: (e: React.MouseEvent | React.TouchEvent) => {
      if (p.controller?.params.height === 0) {
        p.controller.params.height = p.controller.query?.height ?? 375;
      }
      s.prevPointerPosition.y = getClientYFromMouseOrTouchEvent(e);
      window.addEventListener("mouseup", s.stopWindowListeners, { once: true });
      window.addEventListener("touchend", s.stopWindowListeners, {
        once: true,
      });
      window.addEventListener("blur", s.stopWindowListeners, { once: true });
      window.addEventListener("mousemove", s.handleMoveFromTopEdge);
      window.addEventListener("touchmove", s.handleMoveFromTopEdge);
    },
    handleResizeFromBottomEdgeStart: (
      e: React.MouseEvent | React.TouchEvent
    ) => {
      if (p.controller?.params.height === 0) {
        p.controller.params.height = p.controller.query?.height ?? 375;
      }
      s.prevPointerPosition.y = getClientYFromMouseOrTouchEvent(e);
      window.addEventListener("mouseup", s.stopWindowListeners, { once: true });
      window.addEventListener("touchend", s.stopWindowListeners, {
        once: true,
      });
      window.addEventListener("blur", s.stopWindowListeners, { once: true });
      window.addEventListener("mousemove", s.handleMoveFromBottomEdge);
      window.addEventListener("touchmove", s.handleMoveFromBottomEdge);
    },
    handleResizeFromLeftEdgeStart: (e: React.MouseEvent | React.TouchEvent) => {
      s.prevPointerPosition.x = getClientXFromMouseOrTouchEvent(e);
      window.addEventListener("mouseup", s.stopWindowListeners, { once: true });
      window.addEventListener("touchend", s.stopWindowListeners, {
        once: true,
      });
      window.addEventListener("blur", s.stopWindowListeners, { once: true });
      window.addEventListener("mousemove", s.handleMoveFromLeftEdge);
      window.addEventListener("touchmove", s.handleMoveFromLeftEdge);
    },
    handleResizeFromRightEdgeStart: (
      e: React.MouseEvent | React.TouchEvent
    ) => {
      s.prevPointerPosition.x = getClientXFromMouseOrTouchEvent(e);
      window.addEventListener("mouseup", s.stopWindowListeners, { once: true });
      window.addEventListener("touchend", s.stopWindowListeners, {
        once: true,
      });
      window.addEventListener("blur", s.stopWindowListeners, { once: true });
      window.addEventListener("mousemove", s.handleMoveFromRightEdge);
      window.addEventListener("touchmove", s.handleMoveFromRightEdge);
    },
    handleResizeFromTopRightStart: (e: React.MouseEvent | React.TouchEvent) => {
      s.prevPointerPosition.x = getClientXFromMouseOrTouchEvent(e);
      s.prevPointerPosition.y = getClientYFromMouseOrTouchEvent(e);
      window.addEventListener("mouseup", s.stopWindowListeners, { once: true });
      window.addEventListener("touchend", s.stopWindowListeners, {
        once: true,
      });
      window.addEventListener("blur", s.stopWindowListeners, { once: true });
      window.addEventListener("mousemove", s.handleMoveFromTopEdge);
      window.addEventListener("mousemove", s.handleMoveFromRightEdge);
      window.addEventListener("touchmove", s.handleMoveFromTopEdge);
      window.addEventListener("touchmove", s.handleMoveFromRightEdge);
    },
    handleResizeFromBottomRightStart: (
      e: React.MouseEvent | React.TouchEvent
    ) => {
      s.prevPointerPosition.x = getClientXFromMouseOrTouchEvent(e);
      s.prevPointerPosition.y = getClientYFromMouseOrTouchEvent(e);
      window.addEventListener("mouseup", s.stopWindowListeners, { once: true });
      window.addEventListener("touchend", s.stopWindowListeners, {
        once: true,
      });
      window.addEventListener("blur", s.stopWindowListeners, { once: true });
      window.addEventListener("mousemove", s.handleMoveFromBottomEdge);
      window.addEventListener("mousemove", s.handleMoveFromRightEdge);
      window.addEventListener("touchmove", s.handleMoveFromBottomEdge);
      window.addEventListener("touchmove", s.handleMoveFromRightEdge);
    },
    handleResizeFromTopLeftStart: (e: React.MouseEvent | React.TouchEvent) => {
      s.prevPointerPosition.x = getClientXFromMouseOrTouchEvent(e);
      s.prevPointerPosition.y = getClientYFromMouseOrTouchEvent(e);
      window.addEventListener("mouseup", s.stopWindowListeners, { once: true });
      window.addEventListener("touchend", s.stopWindowListeners, {
        once: true,
      });
      window.addEventListener("blur", s.stopWindowListeners, { once: true });
      window.addEventListener("mousemove", s.handleMoveFromTopEdge);
      window.addEventListener("mousemove", s.handleMoveFromLeftEdge);
      window.addEventListener("touchmove", s.handleMoveFromTopEdge);
      window.addEventListener("touchmove", s.handleMoveFromLeftEdge);
    },
    handleResizeFromBottomLeftStart: (
      e: React.MouseEvent | React.TouchEvent
    ) => {
      s.prevPointerPosition.x = getClientXFromMouseOrTouchEvent(e);
      s.prevPointerPosition.y = getClientYFromMouseOrTouchEvent(e);
      window.addEventListener("mouseup", s.stopWindowListeners, { once: true });
      window.addEventListener("touchend", s.stopWindowListeners, {
        once: true,
      });
      window.addEventListener("blur", s.stopWindowListeners, { once: true });
      window.addEventListener("mousemove", s.handleMoveFromBottomEdge);
      window.addEventListener("mousemove", s.handleMoveFromLeftEdge);
      window.addEventListener("touchmove", s.handleMoveFromBottomEdge);
      window.addEventListener("touchmove", s.handleMoveFromLeftEdge);
    },
    handleResizeHandleBottomDoubleClick: () => {
      p.controller?.resetDimension("height");
      p.onReset?.("height");
    },
    handleResizeHandleRightDoubleClick: () => {
      p.controller?.resetDimension("width");
      p.onReset?.("width");
    },
    handleResizeHandleBottomRightRightDoubleClick: () => {
      s.handleResizeHandleBottomDoubleClick();
      s.handleResizeHandleRightDoubleClick();
    },
  }));
  return (
    <Observer
      children={() =>
        p.controller || p.onResize ? (
          <>
            <div
              css={style.resizeHandleTop}
              onMouseDown={s.handleResizeFromTopEdgeStart}
            />
            <div
              css={style.resizeHandleBottom}
              onMouseDown={s.handleResizeFromBottomEdgeStart}
              onDoubleClick={s.handleResizeHandleBottomDoubleClick}
            />
            <div
              css={style.resizeHandleLeft}
              onMouseDown={s.handleResizeFromLeftEdgeStart}
            />
            <div
              css={style.resizeHandleRight}
              onMouseDown={s.handleResizeFromRightEdgeStart}
              onTouchStart={s.handleResizeFromRightEdgeStart}
              onDoubleClick={s.handleResizeHandleRightDoubleClick}
            />
            <div
              css={style.resizeHandleTopRight}
              onMouseDown={s.handleResizeFromTopRightStart}
            />
            <div
              css={style.resizeHandleBottomRight}
              onMouseDown={s.handleResizeFromBottomRightStart}
              onDoubleClick={s.handleResizeHandleBottomRightRightDoubleClick}
            />
            <div
              css={style.resizeHandleTopLeft}
              onMouseDown={s.handleResizeFromTopLeftStart}
            />
            <div
              css={style.resizeHandleBottomLeft}
              onMouseDown={s.handleResizeFromBottomLeftStart}
            />
          </>
        ) : null
      }
    />
  );
};

export default PanelResizeHandleSet;
