import { contrast } from "chroma-js";
import { action, observable, reaction, when } from "mobx";
import { SystemColorSchemeName } from "../base/@types";
import { clamp } from "../base/utils/math.utils";
import { recursiveMergeWithTypeCast } from "../base/utils/object.utils";
import { ComposerAppPrimaryUIMode } from "../components/composer/useCreateComposerInstance";
import { SettingsPanelTabName } from "../components/composer/workspace/WSPanelSettings";
import { KnownKeyframeControlPath } from "../constants/keyframe.constants";
import {
  UserPreferenceObject,
  defaultUserPreferences,
  makeUserPreferenceObject,
} from "../models/User.model";
import { AuthController } from "./auth.controller";
import type { MetronomeName } from "./composer/metronome.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { RecorderType } from "../tools/makeRecordTool";

export const powerEfficiencyMode = observable({
  on: false,
});

export const makeSettingsController = () => {
  const s = observable({
    ...makeControllerBase("SETTINGS"),
    get AUTH(): AuthController {
      return s.ROOT!.AUTH;
    },
    get synced(): UserPreferenceObject {
      return s.AUTH.user?.$.preferences ?? defaultUserPreferences;
    },
    computedValues: {
      get highContrastBorders() {
        const fgBgContrast = s.ROOT
          ? contrast(s.ROOT.THEME.fg, s.ROOT.THEME.bg)
          : 15;
        return fgBgContrast > 10 ? false : true;
      },
      get enableBlur() {
        return s.interface.uiBlurEffect;
      },
    },
    application: {
      maxHistoryItemCount: 500,
      enableExperimentalFeatures: false,
    },
    experimentalFeatures: {
      get patternSystem() {
        return true;
        // return (
        //   s.application.enableExperimentalFeatures ||
        //   !!s.ROOT?.COMPOSER.instance?.atomContext.patterns.length
        // );
      },
      get chords() {
        return true;
        // return (
        //   s.application.enableExperimentalFeatures ||
        //   !!s.ROOT?.COMPOSER.instance?.atomContext.chords.length
        // );
      },
      get engraver() {
        return s.application.enableExperimentalFeatures;
      },
    },
    playback: {
      loop: false,
      masterVolume: -12,
      autoScroll: true,
      timeScale: 1,
      pauseOnSelection: false,
    },
    theming: {
      colorScheme: "dark" as SystemColorSchemeName,
    },
    player: {
      primaryUI: "visualizer" as ComposerAppPrimaryUIMode,
    },
    interface: {
      uiBlurEffect: false,
      powerEfficiencyMode: false,
      panelDockBottom: true,
    },
    composer: {
      snapUnitXEnabled: true,
      snapUnitX: 0.25,
      writeNewNotesIntoOverlappingPattern: true,
      canvas: {
        desaturateOutOfScaleNotes: false,
        visualizeVelocityWithOpacity: false,
        visualizeVelocityWithNoteHeight: false,
        renderPitchGrid: true,
        renderBeatGrid: true,
        renderBarLines: true,
      },
      tools: {
        quill: {
          defaultVelocity: 0.5,
          defaultVelocityJitter: 0.05,
        },
        keyframe: {
          defaultControlPath: null as KnownKeyframeControlPath | null,
        },
      },
      recorder: {
        defaultRecorder: "midi" as RecorderType,
      },
      metronome: {
        playbackOn: false,
        recordOn: false,
        name: "electric" as MetronomeName,
      },
      instruments: {
        showRangeOnKeyboard: [] as string[],
        useVirtualKeyboardAsInput: false,
        useMidiControllersAsInput: false,
      },
      engraver: {
        noteColoration: "color" as "off" | "color",
        renderNoteWidth: "interpreted" as "off" | "authored" | "interpreted",
      },
      settingsPanel: {
        lastOpenedTab: "interface" as SettingsPanelTabName,
      },
      notices: {
        hasWarnedAboutSafari: false,
        doNotWarnAboutExternalInstruments: false,
      },
    },
    get snapUnitX(): number {
      if (!s.composer.snapUnitXEnabled) return 0;
      return clamp(s.composer.snapUnitX, 0.03125, 1);
    },
  });

  s.init = makeRootControllerChildInitFn(s, () => {
    reaction(
      () => s.AUTH.user?._id,
      () => {
        if (s.AUTH.user) {
          reaction(
            () => JSON.stringify(s.AUTH.user?.$.preferences),
            () => {
              if (s.AUTH.user) {
                s.AUTH.saveCurrentUser();
              }
            },
            {
              delay: 1000,
            }
          );
          if (!s.AUTH.user.$.preferences) {
            s.AUTH.user.$.preferences = makeUserPreferenceObject();
          }
        }
      },
      { fireImmediately: true }
    );
    reaction(
      () => s.interface.powerEfficiencyMode,
      on => {
        powerEfficiencyMode.on = on;
      },
      { fireImmediately: true }
    );
    when(
      () => !!s.ROOT,
      () => {
        s.ROOT?.STORAGE.sync(
          ["SETTINGS", "APPLICATION"],
          () => JSON.stringify(s.application),
          action(p => {
            if (p)
              recursiveMergeWithTypeCast(
                s.application,
                JSON.parse(p) as UnknownObject
              );
          })
        );
        s.ROOT?.STORAGE.sync(
          ["SETTINGS", "THEMING"],
          () => JSON.stringify(s.theming),
          action(p => {
            if (p)
              recursiveMergeWithTypeCast(
                s.theming,
                JSON.parse(p) as UnknownObject
              );
          })
        );
        s.ROOT?.STORAGE.sync(
          ["SETTINGS", "COMPOSER"],
          () => JSON.stringify(s.composer),
          action(p => {
            if (p)
              recursiveMergeWithTypeCast(
                s.composer,
                JSON.parse(p) as UnknownObject
              );
            if (!s.composer.snapUnitX) s.composer.snapUnitX = 0.125;
          })
        );
        s.ROOT?.STORAGE.sync(
          ["SETTINGS", "INTERFACE"],
          () => JSON.stringify(s.interface),
          action(p => {
            if (p)
              recursiveMergeWithTypeCast(
                s.interface,
                JSON.parse(p) as UnknownObject
              );
          })
        );
        s.ROOT?.STORAGE.sync(
          ["SETTINGS", "PLAYBACK"],
          () => JSON.stringify(s.playback),
          action(p => {
            if (p)
              recursiveMergeWithTypeCast(
                s.playback,
                JSON.parse(p) as UnknownObject
              );
          })
        );
        s.ROOT?.STORAGE.sync(
          ["SETTINGS", "PLAYER"],
          () => JSON.stringify(s.player),
          action(p => {
            if (p)
              recursiveMergeWithTypeCast(
                s.player,
                JSON.parse(p) as UnknownObject
              );
          })
        );
      }
    );
  });

  return s;
};

export type SettingsController = ReturnType<typeof makeSettingsController>;
