export const Logo10 = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="currentColor">
    <path d="M0 0H1V9H0V0Z" />
    <path d="M2 0H3V9H2V0Z" />
    <path d="M5 0H6V9H5V0Z" />
    <path d="M7 0H8V9H7V0Z" />
    <path d="M9 0H10V9H9V0Z" />
    <g opacity="0.5">
      <path d="M0 9H1V10H0V9Z" />
      <path d="M2 9H3V10H2V9Z" />
      <path d="M5 9H6V10H5V9Z" />
      <path d="M7 9H8V10H7V9Z" />
      <path d="M9 9H10V10H9V9Z" />
    </g>
  </svg>
);

export const Logo20 = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
    <path d="M0 0H2V18H0V0Z" />
    <path d="M4 0H6V18H4V0Z" />
    <path d="M10 0H12V18H10V0Z" />
    <path d="M14 0H16V18H14V0Z" />
    <path d="M18 0H20V18H18V0Z" />
    <g opacity="0.5">
      <path d="M0 18H2V20H0V18Z" />
      <path d="M4 18H6V20H4V18Z" />
      <path d="M10 18H12V20H10V18Z" />
      <path d="M14 18H16V20H14V18Z" />
      <path d="M18 18H20V20H18V18Z" />
    </g>
  </svg>
);
