/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom } from "../../@types";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useStore } from "../../base/utils/mobx.utils";
import { cond } from "../../base/utils/ramdaEquivalents.utils";
import { ComposerInstance } from "./useCreateComposerInstance";
import MultipleAtomPropertyPanel from "../atomPropertyPanels/MultipleAtomPropertyPanel";
import AtomPropertyPanelDispatcher from "../atomPropertyPanels/AtomPropertyPanelDispatcher";
import InterpretationMark from "../shared/InterpretationMark";
import { fg } from "../../constants/cssCustomProperties.constants";
import { varPanelPadding } from "../../base/components/Panel";
import AtomCommonControls from "../atomPropertyPanels/AtomCommonControls";
import Spacing from "../../base/components/Spacing";

type SelectionPropertiesPanelProps = {};

const SelectionPropertiesPanel: React.FC<SelectionPropertiesPanelProps> = p => {
  const { COMPOSER } = useControllers();

  const s = useStore(() => ({
    get I(): ComposerInstance | null {
      return COMPOSER.instance;
    },
    get selectedAtoms(): Atom[] {
      return s.I?.tools.select.atomSelection ?? [];
    },
    get lastSelectedAtom(): Atom | null {
      return s.I?.tools.select.lastSelectedAtom ?? null;
    },
    get panel(): React.ReactNode {
      return cond<React.ReactNode>([
        [
          () => s.selectedAtoms.length === 0 && !s.lastSelectedAtom,
          () => <p>No currently active selection.</p>,
        ],
        [
          () => s.selectedAtoms.length > 1,
          () => <MultipleAtomPropertyPanel atoms={s.selectedAtoms} />,
        ],
        [
          () => s.lastSelectedAtom,
          () => <AtomPropertyPanelDispatcher atom={s.lastSelectedAtom!} />,
        ],
      ])();
    },
    get containsInterpretationAtoms(): boolean {
      return s.selectedAtoms.some(a => !!a._itpId);
    },
    get allInterpretationAtoms(): boolean {
      return s.selectedAtoms.every(a => !!a._itpId);
    },
    get color(): string {
      return s.lastSelectedAtom
        ? s.selectedAtoms.every(
            a =>
              a.appearance?.colorInContext ===
              s.lastSelectedAtom!.appearance?.colorInContext
          )
          ? s.lastSelectedAtom.appearance?.colorInContext ?? fg
          : fg
        : fg;
    },
  }));

  return (
    <Observer
      children={() => (
        <div
          className="SelectionPropertiesPanel"
          css={{ padding: varPanelPadding() }}
        >
          {s.containsInterpretationAtoms && (
            <InterpretationMark
              color={s.color}
              outlined={!s.allInterpretationAtoms}
              size={7}
            />
          )}
          {s.panel}
          {s.I?.editable && s.selectedAtoms.length > 0 && (
            <>
              <Spacing size="1em" />
              <AtomCommonControls atoms={s.selectedAtoms} />
            </>
          )}
        </div>
      )}
    />
  );
};

export default SelectionPropertiesPanel;
