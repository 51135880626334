import { makeIconDef } from "./makeIconDef";

export const ClapperboardIconDef = {
  clapperboard: makeIconDef("clapperboard", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00024 8L7.23787 7.71675L3.98782 4.98963L3.73877 5.01142L4.00024 8ZM5 10.2502L7.25024 8H4.00024V10H4V11V20C4 21.1046 4.89543 22 6 22H19C20.1046 22 21 21.1046 21 20V11L21.0002 11V8H20.7502L17.7502 11L20 11V20C20 20.5523 19.5523 21 19 21H6C5.44772 21 5 20.5523 5 20V11V10.2502ZM6.4783 4.77174L9.72836 7.49886L12.7169 7.23739L9.46689 4.51027L6.4783 4.77174ZM11.9574 4.29238L14.946 4.03092L18.196 6.75803L15.2074 7.0195L11.9574 4.29238ZM20.6741 3.52977L17.4364 3.81303L20.6865 6.54014L20.9355 6.51835L20.6741 3.52977ZM6.75024 11L9.75024 8H12.7502L9.75024 11H6.75024ZM12.2502 11H15.2502L18.2502 8H15.2502L12.2502 11ZM10.0005 13L16.0005 16L10.0005 19V13Z"
        fill="currentColor"
      />
    ),
  }),
};
