import { sum } from "lodash-es";
import { NumericRange } from "../@types";
import { swapInPlace } from "./array.utils";
import { presumeSafeEval } from "./eval.utils";
import { isNil } from "./ramdaEquivalents.utils";

export const isOdd = (n: number) => n % 2 === 1;
export const isEven = (n: number) => n % 2 === 0;

export function padZero(value: string | number | null = 0, length = 2) {
  let valueString = value?.toString() || "0";
  while (valueString.length < length) {
    valueString = "0" + valueString;
  }
  return valueString;
}

export const inRangeInclusive = (
  n: number | null,
  a: number | null,
  b: number | null
) => {
  if (a === null && b === null) return n === null;
  if (n === null) return false;
  else if (a === null && b !== null) return n <= b;
  else if (a !== null && b === null) return n >= a;
  else if (a !== null && b !== null)
    return n >= Math.min(a, b) && n <= Math.max(a, b);
};

export const min = (a: number | null, b: number | null) => {
  if (a === null || b === null) return null;
  return Math.min(a, b);
};
export const max = (a: number | null, b: number | null) => {
  if (a === null || b === null) return null;
  return Math.max(a, b);
};

export const round = <T extends number | null>(a: T, precision = 2): T => {
  if (a === null) return null as T;
  const p = parseInt(`${precision}`);
  return (Math.round(a * 10 ** p) / 10 ** p) as T;
};
export const roundToString = (a: unknown, precision = 2): string => {
  if (a === null) return "";
  const number = round(parseFloat(`${a}`), precision);
  if (!`${number}`.includes(".")) return `${number}`;
  const dotIndex = `${number}`.indexOf(".");
  // const result = `${number}`
  //   .substring(0, dotIndex + precision + 1)
  //   .replace(/(\-[0-9]*)(\.[1-9]*)0+/, (match, $1, $2) => `${$1}${$2}`);
  // console.log(number, result);
  return `${number}`
    .substring(0, dotIndex + precision + 1)
    .replace(/(\-[0-9]+)(\.[1-9]*)0+/, (match, $1, $2) => `${$1}${$2}`);
};

export const add = (...args: (number | null)[]) => {
  if (args.some(a => a === null)) return args[0];
  return sum(args);
};
export const subtract = (a: number | null, b: number | null) => {
  if (a === null || b === null) return a;
  return a - b;
};
export const multiply = (a: number | null, b: number | null) => {
  if (a === null || b === null) return a;
  return a * b;
};

export const percentage = (number: number, precision = 2) =>
  `${round(number * 100, precision)}%`;

export const formatRange = (r: NumericRange | null) =>
  !r ? r : r[0] > r[1] ? swapInPlace(r, 0, 1) : r;

export const getRangesIntersection = (
  a: NumericRange | null,
  b: NumericRange | null
) => {
  if (!a || !b) return [];
  const A = formatRange(a)!;
  const B = formatRange(b)!;
  const min0 = Math.max(A[0], B[0]);
  const max1 = Math.min(A[1], B[1]);
  if (min0 < max1) return [min0, max1];
  return null;
};

export const rangesHasIntersect = (a: NumericRange, b: NumericRange) => {
  return Math.max(a[0], b[0]) < Math.min(a[1], b[1]);
};
export const rangesHasIntersection = (
  a: NumericRange | null,
  b: NumericRange | null
) => getRangesIntersection(a, b) !== null;

export function isNumber(input: unknown): input is number | string {
  return !isNaN(input as number);
}
export const isBasicMathOperation = (string: string) => {
  return Array.from(string).every(glyph => /[\s\d.+\-*/^%()]/.exec(glyph));
};

export const clampOptional = (value: number, min?: number, max?: number) => {
  if (isNil(min) && isNil(max)) return value;
  if (isNumber(min) && isNil(max)) return Math.max(value, min);
  if (isNumber(max) && isNil(min)) return Math.min(value, max);
  return Math.min(Math.max(value, min!), max!);
};
export const clamp = (value: number, min?: number, max?: number) => {
  return Math.min(Math.max(value, min ?? -Infinity), max ?? Infinity);
};

export const mapValue = (
  value: number,
  vMin: number,
  vMax: number,
  targetMin: number,
  targetMax: number
) => {
  const percentage = (value + vMin) / vMax;
  return targetMin + percentage * (targetMax - targetMin);
};

export const isInfinity = (n: number | null) =>
  n === Infinity || n === -Infinity;

export const getSign = (n: number): 0 | 1 | -1 => Math.sign(n) as 0 | 1 | -1;

export const approxEq = (v1: number, v2: number, epsilon = 0.001) => {
  return Math.abs(v1 - v2) < epsilon;
};

export const atLeast = (...value: number[]) => Math.max(...value);
export const atMost = (...value: number[]) => Math.min(...value);

export const applyFormula = (
  value: number | null,
  operation?: number | string | null,
  interpolations?: {
    [key: string]: number;
  }
) => {
  if (
    operation === "" ||
    operation === null ||
    operation === undefined ||
    value === null
  )
    return value;
  if (typeof operation === "number") return operation;
  // if (!isNaN(Number(operation))) return Number(operation);
  if (!operation.includes("$") && /[.+\-x*/^%()]/.test(operation))
    operation = `$ ${operation}`;
  let interpolated = operation
    .replace(/\s+/g, " ")
    .replace(/\$/g, `${value}`)
    .replace(/x/g, "*")
    .replace(/([0-9.]+)([a-z]+)/gi, (match, $0, $1) => `(${$0}*${$1})`);
  if (interpolations) {
    Object.entries(interpolations).forEach(([name, val]) => {
      interpolated = interpolated.replace(new RegExp(name, "g"), `${val}`);
    });
  }
  if (!isBasicMathOperation(interpolated)) return value;
  try {
    return parseFloat(`${presumeSafeEval(interpolated)}`);
  } catch (e) {
    return value;
  }
};

export const getBaseLog = (x: number, y: number) => {
  return Math.log(y) / Math.log(x);
};

export const jitter = (
  value: number,
  range: number,
  min?: number,
  max?: number,
  roundTo?: number
) => {
  return round(
    clampOptional(
      value + range * Math.random() * (isOdd(performance.now()) ? 1 : -1),
      min,
      max
    ),
    roundTo ?? 3
  );
};

export const rangesIntersect = (a: NumericRange, b: NumericRange) =>
  Math.max(a[0], b[0]) < Math.min(a[1], b[1]);
