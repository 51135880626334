/* eslint-disable import/no-named-as-default-member */
import Pluralize from "pluralize";
import { isArray } from "./typeChecks.utils";

export function stringLiterals<T extends string>(...args: T[]): T[] {
  return args;
}

export type StringTransformerInput = string | number | null;

export function isPlural(input?: StringTransformerInput) {
  return Pluralize.isPlural(`${input}`);
}
export function pluralize(input?: StringTransformerInput) {
  if (!input) return "";
  return Pluralize.plural(`${input}`);
}

export function isSingular(input: string) {
  return Pluralize.isSingular(input);
}

export function singularize(input: string) {
  if (!input) return "";
  return Pluralize.singular(input);
}

export function capitalize(input: string) {
  if (!input) return "";
  return `${input[0].toUpperCase()}${input.slice(1)}`;
}

export function autoPluralizeWithNumber(
  a: number | unknown[],
  singularString: string,
  zeroString?: string,
  pluralString?: string,
  doNotAutoDisplayNumber?: boolean
) {
  const amount = isArray(a) ? a.length : a;
  if (!amount && zeroString) return zeroString;
  if (amount === 1) {
    if (singularString.includes("%d")) {
      return singularString.replace(/%d/, "1");
    }
    if (doNotAutoDisplayNumber) return singularString;
    return "1 " + singularString;
  } else {
    const _pluralString = pluralString || pluralize(singularString);
    if (_pluralString) {
      if (_pluralString.includes("%d")) {
        return _pluralString.replace(/%d/, `${amount}`);
      }
      if (doNotAutoDisplayNumber) return _pluralString;
      return `${amount} ${_pluralString}`;
    }
  }
  return amount;
}

export function autoPluralize(
  a: number | unknown[],
  singularString: string,
  zeroString?: string,
  pluralString?: string
) {
  return autoPluralizeWithNumber(
    a,
    singularString,
    zeroString,
    pluralString,
    true
  );
}

export const isNumericString = (s: string) => /^\d+$/.test(s);

export function normalizeString(input?: string | number) {
  return (`${input}` || "").normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const compact = (input: string) => input.replace(/\s+/g, " ");

export function existsInList(originalString: string, target: string) {
  const escapedTarget = target.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const pattern = new RegExp("(^|,\\s?)" + escapedTarget + "(?=(,|$))", "g");
  return originalString.match(pattern);
}

export function replaceInList(
  originalString: string,
  target: string,
  replacement: string
) {
  // Escape special regex characters in the target string to ensure they are treated as literals
  const escapedTarget = target.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Create a regex pattern dynamically with the escaped target
  // It matches the target if it's at the start (^), followed by a comma,
  // has a comma or space before it, or at the end ($) of the string
  const pattern = new RegExp("(^|,\\s?)" + escapedTarget + "(?=(,|$))", "g");

  // Replace the target with the replacement in the original string
  return originalString.replace(pattern, "$1" + replacement);
}
