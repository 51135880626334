import { makeIconDef } from "./makeIconDef";

export const CropIconDef = {
  crop: makeIconDef("crop", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2.79289L7.85355 3.14645L9.85355 5.14645C10.0488 5.34171 10.0488 5.65829 9.85355 5.85355C9.65829 6.04881 9.34171 6.04881 9.14644 5.85355L8 4.70711V15.5C8 15.7761 8.22386 16 8.5 16H17H18H21V17H18V20.2929L19.1464 19.1464C19.3417 18.9512 19.6583 18.9512 19.8536 19.1464C20.0488 19.3417 20.0488 19.6583 19.8536 19.8536L17.8536 21.8536L17.5 22.2071L17.1464 21.8536L15.1464 19.8536C14.9512 19.6583 14.9512 19.3417 15.1464 19.1464C15.3417 18.9512 15.6583 18.9512 15.8536 19.1464L17 20.2929V17H8.5C7.67157 17 7 16.3284 7 15.5V9H4L4 8H7V4.70711L5.85355 5.85355C5.65829 6.04881 5.34171 6.04881 5.14644 5.85355C4.95118 5.65829 4.95118 5.34171 5.14644 5.14645L7.14644 3.14645L7.5 2.79289ZM9 9H16.5C16.7761 9 17 9.22386 17 9.5L17 15H18L18 9.5C18 8.67157 17.3284 8 16.5 8L9 8V9Z"
        fill="currentColor"
      />
    ),
  }),
};
