import { action } from "mobx";
import { Note } from "../@types";

export const resetOrnamentOverrideOfNote = action((note: Note) => {
  const ornament = note.interpreted.ornament;
  if (!ornament) {
    note.disableOrnamentReplicaSync = false;
    return;
  }
  const ac = ornament.context;
  if (!ac || !ornament.refAtom || !ornament.note) return;
  ac.removeAtoms(ornament.children);
  ornament.ornamentationType = ornament.refAtom.ornamentationType;
  ornament.note.disableOrnamentReplicaSync = false;
});
