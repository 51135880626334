/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import KeyboardShortcut from "../../base/components/KeyboardShortcut";
import cx from "../../base/utils/className.utils";
import { useProps } from "../../base/utils/mobx.utils";
import {
  varPrimary,
  varPrimary10,
  varPrimary30,
} from "../../constants/cssCustomProperties.constants";
import { PaletteCommand } from "../../controllers/commandPalette.controller";

type PaletteCommandEntryProps = {
  command: PaletteCommand;
  hasHighlight: boolean;
  onClick: (cmd: PaletteCommand) => void;
};

const style = {
  entry: {
    padding: ".5em .75em",
    appearance: "none",
    backgroundColor: "transparent",
    color: "inherit",
    border: 0,
    width: "100%",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "2.5em",
    fontWeight: 500,
    fontSize: "1.2rem",
    "&.hasHighlight": {
      backgroundColor: varPrimary10,
    },
    "&:hover": {
      backgroundColor: varPrimary30,
      color: varPrimary,
    },
  } as CSSPartial,
};

const PaletteCommandEntry: React.FC<PaletteCommandEntryProps> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <button
          css={style.entry}
          onClick={() => {
            p.onClick?.(p.command);
          }}
          className={cx(p.hasHighlight && "hasHighlight")}
          type="button"
        >
          <span>{p.command.label}</span>
          {p.command.shortcut && <KeyboardShortcut def={p.command.shortcut} />}
        </button>
      )}
    />
  );
};

export default PaletteCommandEntry;
