import { action } from "mobx";
import { Note, Pattern } from "../@types/atomsAndAtomContext.types";
import { ArrayElementType } from "../base/@types";
import { subtract } from "../base/utils/math.utils";
import { meanOfArray } from "../base/utils/ramdaEquivalents.utils";
import { MusicKey } from "../constants/musicKeys.constants";
import {
  getClosestTonicMidiNumber,
  getMidiNumberFromY,
} from "./musicKey.utils";

// export const getNoteElementSelector = (canvasId: string, noteId: string) =>
//   `[data-id="canvas#${canvasId}note#${noteId}"]`;
// export const getNoteElementsById = (canvasId: string, noteId: string) =>
//   document.querySelectorAll(getNoteElementSelector(canvasId, noteId));

export const isPlayableNote = (n: Note): boolean =>
  Boolean(n.width && n.width > 0 && !n.muted);

const CommonNoteSymbols = [
  // "",
  // "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
] as const;

export type CommonNoteSymbols = ArrayElementType<typeof CommonNoteSymbols>;

export const noteNotationSymbolMap = {
  [4]: ["", ""],
  [2]: ["", ""],
  [1]: ["", ""],
  [1 + 1 / 2]: ["", ""],
  [1 / 2]: ["", ""],
  [1 / 2 + 1 / 4]: ["", ""],
  [1 / 4]: ["", ""],
  [1 / 4 + 1 / 8]: ["", ""],
  [1 / 8]: ["", ""],
  [1 / 8 + 1 / 16]: ["", ""],
  [1 / 16]: ["", ""],
  [1 / 16 + 1 / 32]: ["", ""],
  [1 / 32]: ["", ""],
  [1 / 32 + 1 / 64]: ["", ""],
  [1 / 64]: ["", ""],
  [1 / 64 + 1 / 128]: ["", ""],
  [1 / 128]: ["", ""],
  [1 / 128 + 1 / 256]: ["", ""],
  [1 / 256]: ["", ""],
  [1 / 256 + 1.512]: ["", ""],
  [1 / 512]: ["", ""],
  [1 / 512 + 1 / 1024]: ["", ""],
  [1 / 1024]: ["", ""],
};

export const getNoteNotationSymbolByNotationValue = (
  value: number,
  downwards?: boolean
) => {
  return noteNotationSymbolMap[value]?.[downwards ? 1 : 0] ?? "";
};

export const getNumericNotePropertyInPattern = (
  property: "x" | "y" | "startX",
  note: Note,
  pattern: Pattern
) => {
  return subtract(note[property] ?? null, pattern[property] ?? null);
};

export const setNumericNotePropertyInPattern = action(
  (
    property: "x" | "y" | "startX",
    note: Note,
    pattern: Pattern,
    value: number | null
  ) => {
    if (value === null) note[property] = null;
    else note[property] = pattern[property] + value;
  }
);

export const getNoteSetClosestTonicMidiNumber = (
  notes: Note[],
  tonic: MusicKey = MusicKey.C
) => {
  const centerY = meanOfArray(
    notes.map(a => a.centerY).filter(y => y !== null)
  );
  return centerY
    ? getClosestTonicMidiNumber(getMidiNumberFromY(centerY) ?? 60, tonic)
    : 60;
};
