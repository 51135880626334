import { makeIconDef } from "./makeIconDef";

export const MarimbaIconDef = {
  marimba: makeIconDef("marimba", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.25C4 4.11193 4.11193 4 4.25 4H5.75C5.88807 4 6 4.11193 6 4.25V5H7V4.25C7 4.11193 7.11193 4 7.25 4H8.75C8.88807 4 9 4.11193 9 4.25V5H10V4.25C10 4.11193 10.1119 4 10.25 4H11.75C11.8881 4 12 4.11193 12 4.25V5H13V4.25C13 4.11193 13.1119 4 13.25 4H14.75C14.8881 4 15 4.11193 15 4.25V5H16V4.25C16 4.11193 16.1119 4 16.25 4H17.75C17.8881 4 18 4.11193 18 4.25V5H19V4.25C19 4.11193 19.1119 4 19.25 4H20.75C20.8881 4 21 4.11193 21 4.25V5H22.5C22.7761 5 23 5.22386 23 5.5C23 5.77614 22.7761 6 22.5 6H22V18H22.5V21H19.5V18H20V15H19H18V14H17H16V13H15H14V12H13H12V11H11H10V10H9H8V9H7H6V8H5V18H5.5V21H2.5V18H3V6H2.5C2.22386 6 2 5.77614 2 5.5C2 5.22386 2.22386 5 2.5 5H4V4.25ZM19 6H20V14H19V13V6ZM18 13V6H17V12V13H18ZM15 6H16V12H15V11V6ZM14 11V6H13V10V11H14ZM11 6H12V10H11V9V6ZM10 9V6H9V8V9H10ZM7 6H8V8H7V7V6ZM6 7V6H5V7H6Z"
        fill="currentColor"
      />
    ),
  }),
};
