import { StandardInstrumentOptions } from "../../@types";

export const makeDefaultInstrumentOptions = (): StandardInstrumentOptions => ({
  nickName: "",
  color: "",
  outputs: [],
  sendToLocal: true,
  shouldDisableIfExternalOutputsUnavailable: false,
  volumeAdjustment: 0,
});
