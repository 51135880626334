/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import { UNITS } from "../../base/constants/units.constant";
import {
  fg02,
  fg10,
  fg50,
  varFontSerif,
} from "../../constants/cssCustomProperties.constants";

type NoCompPlaceholderProps = {
  text?: string;
};

const style = {
  wrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2em 1em",
    borderRadius: ".3rem",
    backgroundColor: fg02,
    color: fg50,
    fontFamily: varFontSerif,
    fontSize: "2.4rem",
    fontWeight: 200,
    fontStyle: "italic",
    border: `${UNITS.lineWidth}px dashed ${fg10}`,
  } as CSSPartial,
};

const NoCompPlaceholder: React.FC<NoCompPlaceholderProps> = props => {
  return (
    <Observer
      children={() => (
        <div css={style.wrap}>
          <p>{props.text ?? "No compositions found."}</p>
        </div>
      )}
    />
  );
};

export default NoCompPlaceholder;
