/** @jsxImportSource @emotion/react */
import { round } from "lodash-es";
import { reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Chord } from "../../@types";
import FormLabel from "../../base/components/FormLabel";
import NumberArrayEditor from "../../base/components/NumberArrayEditor";
import TextInput from "../../base/components/TextInput";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { replaceContents } from "../../base/utils/array.utils";
import { approxEq } from "../../base/utils/math.utils";
import { usePropsFast, useStore } from "../../base/utils/mobx.utils";
import { first } from "../../base/utils/ramdaEquivalents.utils";
import { isArray } from "../../base/utils/typeChecks.utils";
import { RuleController } from "../../logic/interpreterRule.controller";
import { useComposer } from "./ComposerApp.context";

type ArpeggioEditorProps = {
  rule: RuleController;
  disabled?: boolean;
};

const ArpeggioEditor: React.FC<ArpeggioEditorProps> = props => {
  const p = usePropsFast(props);
  const I = useComposer();
  const s = useStore(() => ({
    advanced: false,
    get value() {
      return p.rule.properties.arpeggio;
    },
    get everyStepHasSameDiff() {
      return (
        s.value?.every((v, i, a) => {
          const curr = v;
          const prev = i === 0 ? null : a[i - 1];
          const prevPrev = i < 1 ? null : a[i - 2];
          if (!prev || !prevPrev) return true;
          return approxEq(curr - prev, prev - prevPrev, 0.0001);
        }) ?? true
      );
    },
    get firstDiff() {
      if (s.value && s.value.length >= 2) {
        return round(s.value[1] - s.value[0], 4);
      }
      return 0;
    },
    get step() {
      if (s.everyStepHasSameDiff) return s.firstDiff;
      return null;
    },
    set step(v) {
      I.runInHistory(
        "Adjust arpeggio chord",
        () => {
          if (!s.value) return;
          replaceContents(
            s.value,
            s.value.map((n, i) => i * (v ?? 0))
          );
        },
        {
          mergeableId: `adjust-arpeggio-chord-in-rule${p.rule._id}`,
        }
      );
    },
    get notes() {
      return (first(p.rule.atoms) as Chord | undefined)?.notesSorted ?? [];
    },
  }));
  useOnMount(() =>
    reaction(
      () => s.notes.length,
      () => {
        if (!isArray(s.value)) {
          Reflect.set(
            p.rule.properties,
            "arpeggio",
            Array(s.notes.length).fill(0)
          );
        } else {
          while (s.value.length < s.notes.length) {
            s.value.push(s.step ?? 0);
          }
        }
      },
      { fireImmediately: true }
    )
  );
  return (
    <Observer
      children={() => (
        <div>
          <FormLabel>Step</FormLabel>
          <TextInput
            type="number"
            form={s}
            field="step"
            step={0.0125}
            disabled={p.disabled}
          />
          {s.advanced && (
            <NumberArrayEditor
              form={p.rule.properties}
              field="arpeggio"
              fixedLength={s.notes.length}
              step={0.0125}
              disabled={p.disabled}
            />
          )}
        </div>
      )}
    />
  );
};

export default ArpeggioEditor;
