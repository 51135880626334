/* eslint-disable no-console */
import { observable, reaction, when } from "mobx";
import { Atom, AtomContext } from "../../@types";
import { makeDisposerController } from "../../base/utils/disposer.utils";
import { atMost, round } from "../../base/utils/math.utils";
import { getRandomNumericString } from "../../base/utils/random.utils";
import { isArray, isNumber } from "../../base/utils/typeChecks.utils";
import { makeFpsScheduler } from "../../base/utils/fps";
import {
  getAtomSetEndX,
  getAtomSetEndY,
  getAtomSetX,
  getAtomSetY,
  isAtomModel,
  isBarAtom,
} from "../../utils/atoms.utils";
import { EnsembleController } from "../ensemble.controller";
import { getAbstractXFromScaledTime } from "../../utils/valueAtXMap.utils";
import { DefaultPtPerX } from "../../constants/composer.constants";
import gsap from "gsap";
import { NoOp } from "../../base/utils/functions.utils";
import { isDevelopment } from "../../base/env";

const debug = false;

const fpsScheduler = makeFpsScheduler(120);

export const createPlaybackController = (
  ENSEMBLE: EnsembleController,
  atomContextGetter: () => AtomContext | null
) => {
  let playheadUpdaterDisposer = () => {};
  const { SETTINGS } = ENSEMBLE.ROOT!;

  const d = makeDisposerController();

  const __ = observable({
    get atomContext() {
      return atomContextGetter();
    },
    atomChunks: [] as Atom[][],
  });

  const _ = observable({
    id: getRandomNumericString(),
    timeline: gsap.timeline({
      paused: true,
      onComplete: () => {
        _.handleReachingTimelineEnd();
      },
    }),
    get midiRecorder() {
      return ENSEMBLE.composer?.recorders.midi;
    },
    initialPlayheadPosition: 0,
    endPlayheadPosition: 0,
    requiresReschedule: false,
    handleReachingTimelineEnd: async () => {
      if (debug) console.info("Reached the end of timeline.");
      if (s.options.loop) {
        if (debug) console.info("Loop is set to on. Restarting timeline...");
        _.timeline.seek(s.atomContext?.leadingBeatsInSeconds ?? 0, true);
        _.timeline.play();
      } else {
        _.timeline.pause();
        if (_.midiRecorder?.isRecording) {
          _.midiRecorder?.metronome?.startConstant();
          await when(() => !_.midiRecorder?.isRecording);
        }
        if (debug) console.info("Loop is set to off. Ending playback.");
        ENSEMBLE.executePlaybackEndHandlers();
        ENSEMBLE.updatePlayPauseState();
      }
    },
    get hasActiveTransform() {
      return (
        ENSEMBLE.composer?.focusedCanvas?.selectionTransform?.isActive ?? false
      );
    },
  });

  const s: PlaybackController = observable({
    get id() {
      return _.id;
    },
    get atomContext() {
      return __.atomContext;
    },
    neverPlayed: true,
    get atoms() {
      return __.atomContext?.atoms ?? [];
    },
    get timeline() {
      return _.timeline;
    },
    get userPreferredTimeScale() {
      return s.atomContext?.ROOT?.SETTINGS.playback.timeScale ?? 1;
    },
    rescheduleCounter: 0,
    get initialPlayheadPosition() {
      return _.initialPlayheadPosition;
    },
    get endPlayheadPosition() {
      return _.endPlayheadPosition;
    },
    playheadPositionPercentage: 0,
    get contentDuration() {
      // TODO calculate total duration when an atom context is not given
      if (!s.atomContext?.duration || s.atomContext.duration < 0) return 0;
      return s.atomContext.duration;
    },
    get totalDuration() {
      return s.atomContext?.durationWithLeadingAndTrailingSilence ?? 0;
    },
    get playheadPositionX() {
      if (!s.atoms) return 0;
      if (!s.atomContext)
        return s.playheadPositionPercentage * (s.width ?? 0) + (s.startX ?? 0);
      return getAbstractXFromScaledTime(
        s.playheadPositionInSeconds,
        s.atomContext.valueAtXMaps.scaledTime,
        s.atomContext
      );
    },
    get playheadPositionPt() {
      const ptPerX = s.atomContext?.canvas?.ptPerX ?? DefaultPtPerX;
      return round(s.playheadPositionX * ptPerX);
    },
    get playheadPositionInSeconds() {
      return s.totalDuration * s.playheadPositionPercentage;
    },
    get playheadPositionInSecondsFloored() {
      return Math.floor(s.playheadPositionInSeconds);
    },
    get startX() {
      if (s.atomContext) return s.atomContext.playbackStartX;
      if (!s.atoms) return null;
      if (isAtomModel(s.atoms)) return s.atoms.startX;
      else if (isArray(s.atoms)) return getAtomSetX(s.atoms);
      return null;
    },
    get endX() {
      if (s.atomContext) return s.atomContext.playbackEndX;
      if (!s.atoms) return null;
      if (isAtomModel(s.atoms)) return s.atoms.width;
      else if (isArray(s.atoms)) return getAtomSetEndX(s.atoms);
      return null;
    },
    get width() {
      if (s.atomContext) return s.endX ?? 0;
      if (s.endX === null || s.startX === null) return 0;
      return s.endX - s.startX;
    },
    get startY() {
      if (s.atomContext) return s.atomContext.height / -2;
      if (!s.atoms) return null;
      if (isAtomModel(s.atoms)) return s.atoms.startY;
      else if (isArray(s.atoms))
        return Math.min(
          ...s.atoms.map(t =>
            isBarAtom(t) ? getAtomSetY(t.atoms) ?? 0 : t.startY ?? 0
          )
        );
      return null;
    },
    get endY() {
      if (s.atomContext) return null;
      if (!s.atoms) return null;
      if (isAtomModel(s.atoms)) return s.atoms.endY;
      else if (isArray(s.atoms))
        return Math.max(
          ...s.atoms.map(t =>
            isBarAtom(t) ? getAtomSetEndY(t.atoms) ?? 0 : t.endY ?? 0
          )
        );
      return null;
    },
    get height() {
      if (s.endY === null || s.startY === null) return 0;
      return s.endY - s.startY;
    },
    seekTime: (time: number, suppressEvents?: boolean) => {
      ENSEMBLE.releaseAll();
      _.timeline.seek(time, suppressEvents);
      s.updatePlayheadPosition();
      return s;
    },
    seekAbstractX: (x: number, suppressEvents?: boolean) => {
      ENSEMBLE.releaseAll();
      _.timeline.seek(s.atomContext?.getScaledTime(x) ?? 0, suppressEvents);
      s.updatePlayheadPosition();
      return s;
    },
    seekProgress: (progress: number, suppressEvents?: boolean) => {
      ENSEMBLE.releaseAll();
      _.timeline.seek(progress * _.timeline.duration(), suppressEvents);
      s.updatePlayheadPosition();
      return s;
    },
    isPaused: () => _.timeline.paused() ?? false,
    isActive: () => _.timeline.isActive() ?? false,
    get offsetStartInSeconds(): number {
      return (s.atomContext?.leadingBeatsInSeconds ?? 0) * -1;
    },
    get offsetStartInPercentages(): number {
      return s.offsetStartInSeconds / s.contentDuration ?? 0;
    },
    progress: relativeTo => {
      const tlProgress = _.timeline.progress() ?? 0;
      if (relativeTo === "totalDuration") return tlProgress;
      else
        return atMost(
          (tlProgress * s.totalDuration + s.offsetStartInSeconds) /
            s.contentDuration,
          1
        );
    },
    pause: (atTime?: string | number, suppressEvents?: boolean) => {
      if (debug) console.info("pause");
      _.timeline.pause(atTime, suppressEvents);
      ENSEMBLE.releaseAll();
      ENSEMBLE.playPauseState = "paused";
      if (!ENSEMBLE.isRecordingMidi) ENSEMBLE.metronome?.stopScheduled();
      return s;
    },
    updatePlayheadPosition: () => {
      s.playheadPositionPercentage = s.progress("totalDuration") ?? 0;
    },
    play: (from?: number | string | null, suppressEvents?: boolean) => {
      s.neverPlayed = false;
      if (debug) console.info("▶️  play", from);
      const _from = isNumber(from) && from > s.contentDuration ? 0 : from;
      _.timeline.play(_from, suppressEvents);
      playheadUpdaterDisposer();
      playheadUpdaterDisposer = fpsScheduler.start(s.updatePlayheadPosition);
      ENSEMBLE.playPauseState = "playing";
      if (!ENSEMBLE.isRecordingMidi) ENSEMBLE.metronome?.startScheduled();
      return s;
    },
    getDuration: () => _.timeline.totalDuration(),
    options: observable({
      get loop() {
        return SETTINGS.playback.loop;
      },
      set loop(value: boolean) {
        SETTINGS.playback.loop = value;
      },
    }),
    dispose: () => {
      s.pause();
      _.timeline.kill();
      d.dispose();
    },
  });

  d.add(
    reaction(
      () => ENSEMBLE.isPaused,
      isPaused => {
        if (isPaused) {
          playheadUpdaterDisposer();
          playheadUpdaterDisposer = () => {};
        }
      }
    )
  );
  d.add(
    reaction(
      () => s.userPreferredTimeScale,
      timeScale => {
        _.timeline.timeScale(timeScale);
      },
      { fireImmediately: true }
    )
  );
  const endCallback = NoOp;
  d.add(
    reaction(
      () => s.atomContext?.durationWithLeadingAndTrailingSilence,
      duration => {
        // console.log(`timeline duration was ${_.timeline.duration()}s`);
        // console.log(`duration changed to ${duration}s`);
        _.timeline.remove(endCallback);
        if (duration !== undefined) _.timeline.add(endCallback, duration);
        // console.log(`timeline duration is now ${_.timeline.duration()}s`);
      },
      {
        fireImmediately: true,
      }
    )
  );

  if (isDevelopment) Reflect.set(window, "playback", s);

  return s;
};

export type PlaybackController = {
  id: string;
  atomContext: AtomContext | null;
  timeline: gsap.core.Timeline;
  atoms: Atom[];
  neverPlayed: boolean;
  rescheduleCounter: number;
  userPreferredTimeScale: number;
  offsetStartInSeconds: number;
  offsetStartInPercentages: number;
  initialPlayheadPosition: number;
  endPlayheadPosition: number;
  playheadPositionPercentage: number;
  playheadPositionX: number;
  playheadPositionPt: number;
  playheadPositionInSeconds: number;
  playheadPositionInSecondsFloored: number;
  contentDuration: number;
  totalDuration: number;
  updatePlayheadPosition: () => void;
  startX: number | null;
  endX: number | null;
  startY: number | null;
  endY: number | null;
  width: number;
  height: number;
  seekTime: (time: number, suppressEvents?: boolean) => PlaybackController;
  seekAbstractX: (time: number, suppressEvents?: boolean) => PlaybackController;
  seekProgress: (
    progress: number,
    suppressEvents?: boolean
  ) => PlaybackController;
  isPaused: () => boolean;
  isActive: () => boolean;
  progress: (relativeTo: "contentDuration" | "totalDuration") => number;
  getDuration: () => number;
  pause: (
    atTime?: string | number,
    suppressEvents?: boolean
  ) => PlaybackController;
  play: (
    from?: number | string | null,
    suppressEvents?: boolean
  ) => PlaybackController;
  options: {
    loop: boolean;
  };
  dispose: () => void;
};
