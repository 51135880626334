import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { isDevelopment } from "./base/env";

export function initSentry() {
  if (isDevelopment) return;
  Sentry.init({
    dsn: "https://4b1398a5b6e04e7bb790a31b77631798@o343513.ingest.sentry.io/4504703902679040",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
