const audioContext = new AudioContext();

// all the time values are expecting seconds
export async function extendSample(options: {
  baseUrl?: string;
  url: string;
  loopStart?: number;
  loopEnd?: number;
  minOutputDuration?: number;
  crossfadeDuration?: number;
}) {
  const {
    baseUrl,
    url: _url,
    loopStart = 0.75,
    loopEnd: _loopEnd,
    minOutputDuration = 30,
    crossfadeDuration = 0.75,
  } = options;

  const url = [baseUrl ?? "", _url].join("");
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  const loopEnd = _loopEnd ?? audioBuffer.duration - 2;
  const sampleRate = audioBuffer.sampleRate;
  const startSample = Math.floor(loopStart * sampleRate);
  const endSample = Math.floor(loopEnd * sampleRate);
  const crossfadeSamples = Math.floor(crossfadeDuration * sampleRate);
  const totalLengthSamples = Math.ceil(minOutputDuration * sampleRate);

  const extendedBuffer = audioContext.createBuffer(
    audioBuffer.numberOfChannels,
    totalLengthSamples,
    sampleRate
  );

  // Calculate the total required repetitions to fill the buffer
  const loopSectionLength = endSample - startSample;
  const totalLoopLengthRequired =
    totalLengthSamples - (loopSectionLength + crossfadeSamples); // Account for initial attack+loop and final release
  const repetitions = Math.ceil(
    totalLoopLengthRequired / (loopSectionLength - crossfadeSamples)
  );

  let currentPosition = 0;

  // Copy the attack and first loop with crossfade into the extendedBuffer
  copyAudioSegment({
    sourceBuffer: audioBuffer,
    targetBuffer: extendedBuffer,
    sourceStart: 0,
    sourceEnd: endSample, // Attack + first loop
    targetStart: currentPosition,
    crossfadeSamples,
    isFadeIn: false,
    isFadeOut: true, // Only fade out if there's more than one loop
  });

  currentPosition += loopSectionLength + crossfadeSamples;

  // Loop through and copy subsequent loops
  for (let i = 1; i < repetitions; i++) {
    copyAudioSegment({
      sourceBuffer: audioBuffer,
      targetBuffer: extendedBuffer,
      sourceStart: startSample, // Start from loopStart to skip attack
      sourceEnd: endSample,
      targetStart: currentPosition - crossfadeSamples, // Overlap for crossfade
      crossfadeSamples,
      isFadeIn: true,
      isFadeOut: true,
    });

    currentPosition += loopSectionLength - crossfadeSamples; // Adjust for overlap
  }

  // Append the release at the end
  if (audioBuffer.duration > loopEnd) {
    copyAudioSegment({
      sourceBuffer: audioBuffer,
      targetBuffer: extendedBuffer,
      sourceStart: endSample,
      sourceEnd: audioBuffer.length,
      targetStart: currentPosition - crossfadeSamples, // Overlap for crossfade into release
      crossfadeSamples,
      isFadeIn: true,
      isFadeOut: false,
    });
  }

  return extendedBuffer;
}

function copyAudioSegment({
  sourceBuffer,
  targetBuffer,
  sourceStart,
  sourceEnd,
  targetStart,
  crossfadeSamples,
  isFadeIn = false,
  isFadeOut = false,
}: {
  sourceBuffer: AudioBuffer;
  targetBuffer: AudioBuffer;
  sourceStart: number;
  sourceEnd: number;
  targetStart: number;
  crossfadeSamples: number;
  isFadeIn?: boolean;
  isFadeOut?: boolean;
}) {
  const numChannels = sourceBuffer.numberOfChannels;
  for (let channel = 0; channel < numChannels; ++channel) {
    const sourceData = sourceBuffer.getChannelData(channel);
    const targetData = targetBuffer.getChannelData(channel);

    for (
      let sourceIndex = sourceStart, targetIndex = targetStart, fadeCounter = 0;
      sourceIndex < sourceEnd && targetIndex < targetData.length;
      ++sourceIndex, ++targetIndex, ++fadeCounter
    ) {
      let fadeFactor = 1; // Assume no fading by default

      if (isFadeIn && fadeCounter < crossfadeSamples) {
        fadeFactor = fadeCounter / crossfadeSamples;
      }

      if (isFadeOut && sourceEnd - sourceIndex <= crossfadeSamples) {
        fadeFactor *= (sourceEnd - sourceIndex) / crossfadeSamples;
      }

      // Blend the current sample based on the fade factor
      targetData[targetIndex] =
        targetData[targetIndex] * (1 - fadeFactor) +
        sourceData[sourceIndex] * fadeFactor;
    }
  }
}

export const extendSampleSet = async (options: {
  baseUrl?: string;
  urls: Record<string, string>;
}) => {
  const resolved = await Promise.all(
    Object.entries(options.urls).map(
      ([label, url]) =>
        new Promise(async resolve => {
          resolve({
            [label]: await extendSample({ baseUrl: options.baseUrl, url }),
          });
        })
    )
  );
  const result = {} as Record<string, AudioBuffer>;
  resolved.forEach(a => Object.assign(result, a));
  return result;
};
