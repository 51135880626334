/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren, ReactNode } from "react";
import { CSSPartial } from "../../base/@types/css.types";
import LineSeparator from "../../base/components/LineSeparator";
import {
  mediaFromDesktop,
  mediaFromDesktopMd,
  mediaFromTablet,
  mediaFromTabletLg,
  mediaOnlyPhones,
} from "../../base/styles/helpers/mediaQueries.styling";
import {
  fg80,
  varFontSerif,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";

type WorkshopAreaPageHeaderProps = {
  EndSlot?: ReactNode;
};

const style = {
  header: {
    paddingBottom: "2em",
  } as CSSPartial,
  headerInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingTop: "1em",
    paddingBottom: "1em",
    ...mediaFromTablet({
      paddingTop: "0",
      paddingBottom: "2em",
    }),
    p: {
      fontSize: "1.4rem",
    },
    h1: {
      fontSize: "3.8rem",
      fontWeight: 200,
      fontFamily: varFontSerif,
      ...mediaFromTablet({
        fontWeight: 100,
        fontSize: "7.2rem",
      }),
      ...mediaFromDesktop({
        fontSize: "7.8rem",
      }),
      ...mediaFromDesktopMd({
        fontSize: "8.2rem",
      }),
    },
    h2: {
      opacity: 0.7,
      marginTop: ".25em",
      fontFamily: varFontSerif,
      fontWeight: 300,
      fontSize: "2.4rem",
      "&.serif": {
        fontWeight: 200,
        fontStyle: "italic",
      },
    },
  } as CSSPartial,
  headingGroup: {
    flex: "1 1 auto",
  } as CSSPartial,
  headerEnd: {
    flex: "0 1 auto",
    padding: "1em 0",
  } as CSSPartial,
  title: {
    fontSize: "2.8rem",
    fontFamily: varFontSerif,
    fontWeight: 700,
    ...mediaFromTablet({
      fontSize: "3.8rem",
    }),
    ...mediaFromTabletLg({
      fontSize: "4.8rem",
    }),
    ...mediaFromDesktop({
      fontSize: "5.4rem",
    }),
  } as CSSPartial,
  nav: {
    paddingTop: "1em",
    ...mediaFromTablet({
      paddingTop: "2em",
    }),
    a: {
      display: "inline-block",
      fontSize: "1.8rem",
      fontFamily: varFontSerif,
      fontWeight: 200,
      textDecoration: "none",
      color: fg80,
      ...mediaOnlyPhones({
        paddingTop: ".5em",
        paddingBottom: ".5em",
      }),
      "&.active": {
        color: varPrimary,
      },
      ...mediaFromDesktop({
        fontSize: "2.4rem",
        fontWeight: 200,
      }),
      "&:hover": {
        color: varPrimary,
        filter: "brightness(1.1)",
      },
    },
    span: {
      opacity: 0.5,
      marginLeft: ".5em",
      marginRight: ".5em",
      ...mediaFromTablet({
        marginLeft: "1em",
        marginRight: "1em",
      }),
    },
  } as CSSPartial,
};

const WorkshopAreaPageHeader: React.FC<
  PropsWithChildren<WorkshopAreaPageHeaderProps>
> = props => {
  return (
    <Observer
      children={() => (
        <header css={style.header}>
          <div css={style.headerInner}>
            <div css={style.headingGroup}>{props.children}</div>
            <div css={style.headerEnd}>{props.EndSlot}</div>
          </div>
          <LineSeparator />
        </header>
      )}
    />
  );
};

export default WorkshopAreaPageHeader;
