import { makeIconDef } from "./makeIconDef";

export const ComposerEditorIconDef = {
  "composer-editor": makeIconDef("composer-editor", {
    regular: () => (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6665 4C15.6665 3.44772 16.1142 3 16.6665 3H20.9998C21.5521 3 21.9998 3.44772 21.9998 4C21.9998 4.55228 21.5521 5 20.9998 5H16.6665C16.1142 5 15.6665 4.55228 15.6665 4ZM9.3335 6C9.3335 5.44772 9.78121 5 10.3335 5H14.6668C15.2191 5 15.6668 5.44772 15.6668 6C15.6668 6.55228 15.2191 7 14.6668 7H10.3335C9.78121 7 9.3335 6.55228 9.3335 6ZM3 8C3 7.44772 3.44772 7 4 7H8.33333C8.88562 7 9.33333 7.44772 9.33333 8C9.33333 8.55229 8.88562 9 8.33333 9H4C3.44772 9 3 8.55229 3 8ZM16.6665 7C16.1142 7 15.6665 7.44772 15.6665 8C15.6665 8.55229 16.1142 9 16.6665 9H20.9998C21.5521 9 21.9998 8.55229 21.9998 8C21.9998 7.44772 21.5521 7 20.9998 7H16.6665ZM4 19.5V13H6.61905V17.0441C6.61905 17.1822 6.73098 17.2941 6.86905 17.2941H7.52381V20H4.5C4.22386 20 4 19.7761 4 19.5ZM8.88095 17.0441V13H11.369V17.0441C11.369 17.1822 11.4809 17.2941 11.619 17.2941H12.0476V20H8.52381V17.2941H8.63095C8.76902 17.2941 8.88095 17.1822 8.88095 17.0441ZM13.6309 17.0441V13H16.119V17.0441C16.119 17.1822 16.2309 17.2941 16.369 17.2941H16.5714V20H13.0476V17.2941H13.3809C13.5189 17.2941 13.6309 17.1822 13.6309 17.0441ZM17.5714 17.2941V20H20.5C20.7761 20 21 19.7761 21 19.5V13H18.3809V17.0441C18.3809 17.1822 18.2689 17.2941 18.1309 17.2941H17.5714ZM12.0476 21H8.52381H7.52381L4.5 21C3.67157 21 3 20.3284 3 19.5V12.5C3 12.2239 3.22386 12 3.5 12H21.5C21.7761 12 22 12.2239 22 12.5V19.5C22 20.3284 21.3284 21 20.5 21L17.5714 21H16.5714H13.0476H12.0476Z"
        />
      </svg>
    ),
  }),
};
