function cryptoRandom() {
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0] / (0xffffffff + 1);
}

export function shuffleInPlace<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(cryptoRandom() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function shuffle<T>(array: T[]): T[] {
  return shuffleInPlace([...array]);
}

export const randomizeArray = shuffle;
export const randomizeArrayInPlace = shuffleInPlace;

export function getRandomInteger(floor = 0, ceil = 10) {
  return Math.round(cryptoRandom() * (ceil - floor) + floor);
}

export function getRandomItemFromArray<T>(array: T[]): T {
  return array[Math.floor(cryptoRandom() * array.length)];
}

export function getRandomNumericString(length = 12) {
  return `${cryptoRandom() * new Date().getTime()}`
    .replace(".", "")
    .substring(0, length);
}
