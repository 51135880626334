/** @jsxImportSource @emotion/react */
import { flow, when } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { ChangeEvent } from "react";
import { useNavigate } from "react-router";
import { CSSPartial } from "../../base/@types/css.types";
import Button from "../../base/components/Button";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useObservableRef } from "../../base/hooks/useObservableRef.hook";
import IconImport18 from "../../base/icons/18/Import.icon.18";
import { readFileAsArrayBuffer } from "../../base/utils/file.utils";
import { useStore, useStyle } from "../../base/utils/mobx.utils";
import { convertMidiFileToComposition } from "../../utils/midi/midiImport.utils";

type MidiImporterProps = {};

const MidiImporter: React.FC<MidiImporterProps> = props => {
  const { COMPOSITIONS, LOCALDB, DIALOGS } = useControllers();
  const navigate = useNavigate();
  const inputRef = useObservableRef<HTMLInputElement>();

  const s = useStore(() => ({
    importing: false,
    handleImportButtonClick: () => {
      inputRef.current?.click();
    },
    handleFileInputChange: async (e: ChangeEvent<HTMLInputElement>) =>
      await flow(function* () {
        s.importing = true;
        try {
          const file = e.target.files?.[0];
          if (!file) return;
          const arrayBuffer: ArrayBuffer | null = yield readFileAsArrayBuffer(
            file
          );
          yield s.readArrayBufferAsMidi(arrayBuffer, file.name);
        } catch (e) {
          console.error(e);
        } finally {
          s.importing = false;
        }
      })(),
    readArrayBufferAsMidi: async (
      arrayBuffer: ArrayBuffer | null,
      fileName: string
    ) => {
      if (!arrayBuffer) {
        DIALOGS.attention({ Heading: "Failed to import file." });
        return;
      }
      const comp = await convertMidiFileToComposition(
        LOCALDB,
        arrayBuffer,
        fileName
      );
      if (!comp) return;
      const importedComp = await COMPOSITIONS.save(comp);
      // TODO: refactor composition open mechanism
      navigate(`/compose/${importedComp._id}`);
      when(
        () => !!importedComp.atomContext?.ready,
        async () => {
          const { atomContext } = importedComp || {};
          if (!atomContext) return;
          const emptyVoices = atomContext.voices.filter(v => v.isEmpty) ?? [];
          emptyVoices.forEach(v => atomContext.deleteVoice(v));
          await when(
            () =>
              !!(
                atomContext.interpreter &&
                atomContext.interpreter.instrumentMap.size > 0
              )
          );
          const interpreter = atomContext.interpreter!;
          interpreter.interpretation.options.bpm = 60;
          const firstInstrument = interpreter.instruments[0];
          atomContext.voices.forEach(v => {
            interpreter.findOrCreateRuleForAtom(v, {
              properties: { instrumentIds: [firstInstrument._id] },
            });
          });
          if (emptyVoices.length) await COMPOSITIONS.save(importedComp);
        }
      );
    },
  }));
  const style = useStyle(() => ({
    get component(): CSSPartial {
      return {
        position: "relative",
        input: {
          opacity: 0,
          position: "absolute",
          width: 1,
          height: 1,
          overflow: ["hidden", "clip"],
        },
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <span css={style.component}>
          <input
            type="file"
            accept="audio/midi,audio/x-midi"
            onChange={s.handleFileInputChange}
            ref={inputRef}
          />
          <Button
            onClick={s.handleImportButtonClick}
            Icon={<IconImport18 />}
            disabled={s.importing}
            loading={s.importing}
          >
            Import MIDI…
          </Button>
        </span>
      )}
    />
  );
};

export default MidiImporter;
