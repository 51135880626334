/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../../base/@types/css.types";
import { useProps, useStore } from "../../../base/utils/mobx.utils";
import { Instrument } from "../../../@types";
import { SalamanderGrandPianoOptions } from "../SalamanderGrandPiano.instrument";
import SustainPenalStatusIndicator from "../../../components/composer/SustainPenalStatusIndicator";

interface P {
  instrument: Instrument<SalamanderGrandPianoOptions>;
}

const SalamanderGrandControlPanel: React.FC<P> = props => {
  const p = useProps(props);
  const styles = useStore(() => ({
    get container(): CSSPartial {
      return {
        padding: "0 1em 1em",
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="SalamanderGrandPianoUI" css={styles.container}>
          <SustainPenalStatusIndicator instrument={p.instrument} />
        </div>
      )}
    />
  );
};

export default SalamanderGrandControlPanel;
