/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../@types/css.types";
import { useControllers } from "../../hooks/rootContext.hooks";
import { useGetColorFromString } from "../../hooks/theme.hooks";
import { flex } from "../../styles/helpers/flex.styleHelper";
import { renderRenderable } from "../../utils/components.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import SymbolIcon from "../SymbolIcon";
import { SelectorOptionRendererProps } from "./Selector";

const SelectDefaultOptionRenderer = <T extends UnknownObject = UnknownObject>(
  props: React.PropsWithChildren<SelectorOptionRendererProps<T>>
) => {
  const p = useProps(props);

  const { THEME } = useControllers();

  const c = useGetColorFromString(() => p.option.color);

  const s = useStore(() => ({
    handleClick: () => {
      p.onClick && p.onClick(p.option);
    },
    get style(): CSSPartial {
      return {
        color: p.isSelected
          ? THEME.increaseContrastFromBackground(c.color, 0.38)
          : "inherit",
        cursor: "default",
        ...flex({ align: "center", spacing: ".19em" }),
        textAlign: "center",
        "&:hover": {
          filter: "brightness(1.1)",
        },
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <span
          className="SelectDefaultOptionRenderer"
          onClick={s.handleClick}
          css={s.style}
        >
          {p.option.icon && <SymbolIcon icon={p.option.icon} />}
          <span>{renderRenderable(p.option.Label ?? p.option.value)}</span>
        </span>
      )}
    />
  );
};

export default SelectDefaultOptionRenderer;
