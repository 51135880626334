/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { fg20 } from "../../constants/cssCustomProperties.constants";
import { ColorPalette } from "../../theming/colorPalette";
import { CSSPartial } from "../@types/css.types";

type IndicatorLightProps = {
  on?: boolean;
  color?: string;
};

const IndicatorLight: React.FC<IndicatorLightProps> = p => {
  const style: CSSPartial = {
    height: 9,
    width: 9,
    display: "inline-block",
    borderRadius: "100%",
    backgroundColor: p.on ? p.color ?? ColorPalette.green : fg20,
  };
  return (
    <Observer
      children={() => <span className="IndicatorLight" css={style} />}
    />
  );
};

export default IndicatorLight;
