/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { flow } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import Button from "../../base/components/Button";
import InfoBox from "../../base/components/InfoBox";
import Spacing from "../../base/components/Spacing";
import TextInput from "../../base/components/TextInput";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { addRootClass, removeRootClass } from "../../base/utils/dom.utils";
import { readAxiosErrorMessage } from "../../base/utils/errors.utils";
import { useStore } from "../../base/utils/mobx.utils";
import { sanitizeStringForUsername } from "../../base/utils/username.utils";
import { MusicKey } from "../../constants/musicKeys.constants";
import { MusicScaleName } from "../../constants/musicScales.constants";
import { makeTimeSignature44 } from "../../constants/timeSignatures.constants";
import {
  Composition,
  makeCompositionOptions,
} from "../../models/Composition.model";
import { generateUsername } from "../../utils/generators.utils";
import AuthForm from "./components/AuthForm";
import AuthPage from "./components/AuthPage";
import { paths } from "../../base/constants/paths.constants";
import { externalLinkAttrs } from "../../base/utils/links.utils";
import { ExternalLinkIcon } from "../../base/components/ExternalLinkIcon";

interface P {}

const UserNameFieldSet = styled.div`
  display: grid;
  grid-template-columns: auto 2.5em;
  grid-gap: 0.5em;
  align-items: end;
`;

const SignUpPage: React.FC<P> = observer(props => {
  const { AUTH, COMPOSITIONS, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    form: {
      username: "",
      email: "",
      givenName: "",
      surname: "",
      password: "",
      passwordConfirm: "",
    },
    get style() {
      return {
        padding: "1em",
      };
    },
    generateUsername: () => {
      s.form.username = generateUsername();
    },
    error: null as string | null,
    signUp: async () => {
      if (!s.form.username) {
        s.error = "Please provide a username.";
        return;
      }
      if (!s.form.email) {
        s.error = "Please provide an email.";
        return;
      }
      if (!s.form.password) {
        s.error = "You must provide a password.";
        return;
      }
      if (s.form.password !== s.form.passwordConfirm) {
        s.error = "Passwords do not match.";
        return;
      }
      await flow(function* () {
        try {
          s.error = null;
          yield AUTH.signUp(s.form);
          yield AUTH.login({
            usernameOrEmail: s.form.username || s.form.email,
            password: s.form.password,
          });
          addRootClass("hide");
          try {
            const comp: Composition = yield COMPOSITIONS.create({
              title: "Untitled",
              options: {
                ...makeCompositionOptions(),
                defaultInstrumentName: "flemish-harpsichord",
                musicKey: MusicKey.C,
                musicScaleName: MusicScaleName.Ionian,
                timeSignature: makeTimeSignature44(),
              },
            });
            NAVIGATOR.navigateTo(`/compose/${comp._id}`);
          } catch (e) {
            reportError(e);
          } finally {
            removeRootClass("hide");
          }
        } catch (e) {
          s.error = readAxiosErrorMessage(e);
        }
      })();
    },
  }));

  useOnMount(() => {
    s.generateUsername();
    if (AUTH.isAuthenticated) AUTH.logout();
  });

  return (
    <AuthPage
      title="Create account"
      className="SignUpPage"
      css={s.style}
      HeaderContent={
        <div>
          <Spacing size="1.5em" />
          <p>
            By continuing you agree to our{" "}
            <a href={paths.terms} {...externalLinkAttrs}>
              Terms of Service <ExternalLinkIcon />
            </a>
            .
          </p>
        </div>
      }
    >
      <AuthForm>
        <UserNameFieldSet>
          <TextInput
            form={s.form}
            field="username"
            type="text"
            Label="Username *"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            selectOnFocus
            autoFocus
            transformer={sanitizeStringForUsername}
          />
          <Button icon="sparkles" onClick={s.generateUsername} iconSize={13} />
        </UserNameFieldSet>
        <TextInput
          form={s.form}
          field="email"
          type="email"
          Label="Email *"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
        />
        {/* <div>
          <FormLabel bold>Name (Optional)</FormLabel>
          <Spacing size="0.5em" />
          <div css={grid6col.grid}>
            <TextInput
              form={s.form}
              field="givenName"
              type="text"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              placeholder="First name"
              css={grid6col.half}
            />
            <TextInput
              form={s.form}
              field="surname"
              type="text"
              placeholder="Surname"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              css={grid6col.half}
            />
          </div>
        </div> */}
        <TextInput
          form={s.form}
          field="password"
          type="password"
          Label="Password *"
          autoComplete="new-password"
          autoCorrect="off"
          autoCapitalize="off"
        />
        <TextInput
          form={s.form}
          field="passwordConfirm"
          type="password"
          Label="Confirm Password *"
          autoComplete="new-password"
          autoCorrect="off"
          autoCapitalize="off"
          onEnter={s.signUp}
        />
        <Button onClick={s.signUp} fullWidth>
          Sign up
        </Button>

        {s.error && <InfoBox>{s.error}</InfoBox>}

        <p>
          Already have an account?{" "}
          <Link to="/auth/login" title="Login">
            Click here to log in
          </Link>
          .
        </p>
      </AuthForm>
    </AuthPage>
  );
});

export default SignUpPage;
