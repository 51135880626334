/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import { darken, lighten } from "polished";
import React from "react";
import FormLabel from "../../base/components/FormLabel";
import Selector from "../../base/components/Selector/Selector";
import SelectOptionVoice from "../../base/components/Selector/SelectOptionVoice";
import Spacing from "../../base/components/Spacing";
import {
  removeOneFromArray,
  replaceContents,
} from "../../base/utils/array.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { ColorPalette } from "../../theming/colorPalette";
import {
  RenaissanceOrganMeta,
  RenaissanceOrganSamplerName,
  RenaissanceOrganType,
} from "./RenaissanceOrgan.instrument";
import { useComposer } from "../../components/composer/ComposerApp.context";

type RenaissanceOrganControlPanelProps = {
  instrument: RenaissanceOrganType;
};

const RenaissanceOrganControlPanel: React.FC<
  RenaissanceOrganControlPanelProps
> = props => {
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    getActivatedSamplers: () => {
      return p.instrument.options.activatedSamplers;
    },
    setActivatedSamplers: (values: string[]) => {
      if (values.includes("full")) {
        if (!p.instrument.options.activatedSamplers.includes("full"))
          replaceContents(p.instrument.options.activatedSamplers, ["full"]);
        else
          replaceContents(
            p.instrument.options.activatedSamplers,
            removeOneFromArray(values, "full")
          );
      } else
        replaceContents(
          p.instrument.options.activatedSamplers,
          values as RenaissanceOrganSamplerName[]
        );
    },
    get color() {
      return (
        p.instrument.appearance.color ?? RenaissanceOrganMeta.defaultColor!
      );
    },
    get options() {
      return [
        {
          Label: "4'",
          value: "4'",
          color: lighten(0.1, s.color),
        },
        { Label: "8'", value: "8'", color: s.color },
        {
          Label: "Full",
          value: "full",
          color: darken(0.1, s.color),
        },
      ];
    },
  }));
  return (
    <Observer
      children={() => (
        <div css={{ padding: ".5em 1em 1em" }}>
          <FormLabel bold>Stops</FormLabel>
          <Spacing size=".5em" />
          <Selector
            getter={s.getActivatedSamplers}
            setter={s.setActivatedSamplers}
            options={s.options}
            optionRenderer={SelectOptionVoice}
            color={ColorPalette.orange}
            fullWidth
            taskName={`Change the stops of "${p.instrument.nickName}"`}
            mergeableId={`instrument-change-stops-${p.instrument._id}`}
            disabled={I.editDisabled}
          />
        </div>
      )}
    />
  );
};

export default RenaissanceOrganControlPanel;
