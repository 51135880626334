import { TimeSignature } from "../@types";
import { presumeSafeEval } from "../base/utils/eval.utils";
import { isBasicMathOperation } from "../base/utils/math.utils";
import { isNumberLike } from "../base/utils/typeChecks.utils";
import { CommonTimeSignatures } from "../constants/timeSignatures.constants";

export const parseBeatNumberFromShorthand = (shorthand: string) => {
  const secondSnippet = shorthand.split("'")[1];
  if (/^[0-9+\-*\/()]+$/.test(secondSnippet?.replace(/\s/g, "")))
    return +presumeSafeEval(secondSnippet);
  return +secondSnippet;
};

export const isValidBeatNumber = (v: string) => {
  if (isNumberLike(v)) return true;
  if (!isBasicMathOperation(v))
    return false; /* prevents dangerous javascript */
  const isANumber = !isNaN(+presumeSafeEval(v));
  return isANumber;
};

export const parseBeatNumber = (v?: string | number | null) => {
  if (isNumberLike(v)) return +v;
  if (!v) return null;
  const isValid = isValidBeatNumber(v);
  if (!isValid) return null;
  return +presumeSafeEval(v);
};

export const convertAbstractXToDurationInSeconds = <T = number | null>(
  xOrBeat: T,
  xpmOrBpm: number
): T => {
  if (xOrBeat === null) return xOrBeat;
  return (((xOrBeat as unknown as number) * 60) / xpmOrBpm) as unknown as T;
};

export const convertAbstractXToTimeFromStart =
  convertAbstractXToDurationInSeconds;

export const convertDurationInSecondsToAbstractX = (
  seconds: number,
  xpm: number
) => {
  return (seconds / 60) * xpm;
};

// 1 x always equals  = 1 / 4 whole note
export const getBpxFromTimeSignature = (timeSignature: TimeSignature) =>
  1 / ((1 / timeSignature[1]) * 4);

export const getDurationPerBeatFromBpm = (bpm = DefaultBpm) => {
  return 60 / bpm;
};

export const getXpmFromBpmBpx = (bpm: number, bpx: number) => bpm / bpx;

export const DefaultBpm = 60;
export const DefaultBpx = getBpxFromTimeSignature(CommonTimeSignatures["4/4"]);
export const DefaultXpm = getXpmFromBpmBpx(DefaultBpm, DefaultBpx);
