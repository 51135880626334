/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import Button from "../../base/components/Button";
import IconPlus18 from "../../base/icons/18/Plus.icon.18";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Collection } from "../../models/Collection.model";
import NewCollectionPanel from "../panels/NewCollectionPanel";

type NewCollectionButtonProps = {
  shouldNavigateToNewCollectionAfterCreation?: boolean;
  onShouldClose?: (newCollection?: Collection) => void;
};

const NewCollectionButton: React.FC<NewCollectionButtonProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    shouldShowPanel: false,
    showNewCollectionPanel: () => {
      s.shouldShowPanel = true;
    },
    closeNewCollectionPanel: (newCollection?: Collection) => {
      s.shouldShowPanel = false;
      p.onShouldClose?.(newCollection);
    },
  }));

  return (
    <Observer
      children={() => (
        <>
          <Button onClick={s.showNewCollectionPanel} Icon={<IconPlus18 />}>
            New collection
          </Button>
          {s.shouldShowPanel && (
            <NewCollectionPanel
              onShouldClose={s.closeNewCollectionPanel}
              shouldNavigateToNewCollectionAfterCreation={
                p.shouldNavigateToNewCollectionAfterCreation
              }
            />
          )}
        </>
      )}
    />
  );
};

export default NewCollectionButton;
