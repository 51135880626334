import { makeIconDef } from "./makeIconDef";

export const RainbowIconDef = {
  rainbow: makeIconDef("rainbow", {
    regular: () => (
      <>
        <path
          d="M7.5 17C7.5 16.3434 7.62933 15.6932 7.8806 15.0866C8.13188 14.48 8.50017 13.9288 8.96447 13.4645C9.42876 13.0002 9.97995 12.6319 10.5866 12.3806C11.1932 12.1293 11.8434 12 12.5 12C13.1566 12 13.8068 12.1293 14.4134 12.3806C15.02 12.6319 15.5712 13.0002 16.0355 13.4645C16.4998 13.9288 16.8681 14.48 17.1194 15.0866C17.3707 15.6932 17.5 16.3434 17.5 17"
          stroke="currentColor"
        />
        <path
          d="M5.5 17C5.5 16.0807 5.68106 15.1705 6.03284 14.3212C6.38463 13.4719 6.90024 12.7003 7.55025 12.0503C8.20026 11.4002 8.97194 10.8846 9.82122 10.5328C10.6705 10.1811 11.5807 10 12.5 10C13.4193 10 14.3295 10.1811 15.1788 10.5328C16.0281 10.8846 16.7997 11.4002 17.4497 12.0503C18.0998 12.7003 18.6154 13.4719 18.9672 14.3212C19.3189 15.1705 19.5 16.0807 19.5 17"
          stroke="currentColor"
        />
        <path
          d="M3.5 17C3.5 15.8181 3.73279 14.6478 4.18508 13.5558C4.63738 12.4639 5.30031 11.4718 6.13604 10.636C6.97177 9.80031 7.96392 9.13738 9.05585 8.68508C10.1478 8.23279 11.3181 8 12.5 8C13.6819 8 14.8522 8.23279 15.9442 8.68508C17.0361 9.13738 18.0282 9.80031 18.864 10.636C19.6997 11.4718 20.3626 12.4639 20.8149 13.5558C21.2672 14.6478 21.5 15.8181 21.5 17"
          stroke="currentColor"
        />
      </>
    ),
    colored: () => (
      <>
        <path
          d="M8.5 17C8.5 16.4747 8.60346 15.9546 8.80448 15.4693C9.0055 14.984 9.30014 14.543 9.67157 14.1716C10.043 13.8001 10.484 13.5055 10.9693 13.3045C11.4546 13.1035 11.9747 13 12.5 13C13.0253 13 13.5454 13.1035 14.0307 13.3045C14.516 13.5055 14.957 13.8001 15.3284 14.1716C15.6999 14.543 15.9945 14.984 16.1955 15.4693C16.3965 15.9546 16.5 16.4747 16.5 17"
          stroke="#6D65CC"
        />
        <path
          d="M7.5 17C7.5 16.3434 7.62933 15.6932 7.8806 15.0866C8.13188 14.48 8.50017 13.9288 8.96447 13.4645C9.42876 13.0002 9.97995 12.6319 10.5866 12.3806C11.1932 12.1293 11.8434 12 12.5 12C13.1566 12 13.8068 12.1293 14.4134 12.3806C15.02 12.6319 15.5712 13.0002 16.0355 13.4645C16.4998 13.9288 16.8681 14.48 17.1194 15.0866C17.3707 15.6932 17.5 16.3434 17.5 17"
          stroke="#408ACE"
        />
        <path
          d="M6.5 17C6.5 16.2121 6.65519 15.4319 6.95672 14.7039C7.25825 13.9759 7.70021 13.3145 8.25736 12.7574C8.81451 12.2002 9.47595 11.7583 10.2039 11.4567C10.9319 11.1552 11.7121 11 12.5 11C13.2879 11 14.0681 11.1552 14.7961 11.4567C15.5241 11.7583 16.1855 12.2002 16.7426 12.7574C17.2998 13.3145 17.7417 13.9759 18.0433 14.7039C18.3448 15.4319 18.5 16.2121 18.5 17"
          stroke="#33DB9E"
        />
        <path
          d="M5.5 17C5.5 16.0807 5.68106 15.1705 6.03284 14.3212C6.38463 13.4719 6.90024 12.7003 7.55025 12.0503C8.20026 11.4002 8.97194 10.8846 9.82122 10.5328C10.6705 10.1811 11.5807 10 12.5 10C13.4193 10 14.3295 10.1811 15.1788 10.5328C16.0281 10.8846 16.7997 11.4002 17.4497 12.0503C18.0998 12.7003 18.6154 13.4719 18.9672 14.3212C19.3189 15.1705 19.5 16.0807 19.5 17"
          stroke="#FFC71E"
        />
        <path
          d="M4.5 17C4.5 15.9494 4.70693 14.9091 5.10896 13.9385C5.511 12.9679 6.10028 12.086 6.84315 11.3431C7.58601 10.6003 8.46793 10.011 9.43853 9.60896C10.4091 9.20693 11.4494 9 12.5 9C13.5506 9 14.5909 9.20693 15.5615 9.60896C16.5321 10.011 17.414 10.6003 18.1569 11.3431C18.8997 12.086 19.489 12.9679 19.891 13.9385C20.2931 14.9091 20.5 15.9494 20.5 17"
          stroke="#EE9351"
        />
        <path
          d="M3.5 17C3.5 15.8181 3.73279 14.6478 4.18508 13.5558C4.63738 12.4639 5.30031 11.4718 6.13604 10.636C6.97177 9.80031 7.96392 9.13738 9.05585 8.68508C10.1478 8.23279 11.3181 8 12.5 8C13.6819 8 14.8522 8.23279 15.9442 8.68508C17.0361 9.13738 18.0282 9.80031 18.864 10.636C19.6997 11.4718 20.3626 12.4639 20.8149 13.5558C21.2672 14.6478 21.5 15.8181 21.5 17"
          stroke="#E14446"
        />
      </>
    ),
  }),
};
