import { makeIconDef } from "./makeIconDef";

export const ScissorsIconDef = {
  scissors: makeIconDef("scissors", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 10C8.88071 10 10 8.88071 10 7.5C10 6.11929 8.88071 5 7.5 5C6.11929 5 5 6.11929 5 7.5C5 8.88071 6.11929 10 7.5 10ZM7.5 11C8.08013 11 8.6273 10.8589 9.10903 10.609L11 12.5L9.10903 14.391C8.6273 14.1411 8.08013 14 7.5 14C5.567 14 4 15.567 4 17.5C4 19.433 5.567 21 7.5 21C9.433 21 11 19.433 11 17.5C11 16.9199 10.8589 16.3727 10.609 15.891L12.5 14L18.5 20H21V19.5L10.609 9.10903C10.8589 8.6273 11 8.08013 11 7.5C11 5.567 9.433 4 7.5 4C5.567 4 4 5.567 4 7.5C4 9.433 5.567 11 7.5 11ZM7.5 20C8.88071 20 10 18.8807 10 17.5C10 16.1193 8.88071 15 7.5 15C6.11929 15 5 16.1193 5 17.5C5 18.8807 6.11929 20 7.5 20ZM21 5.5V5H18.5L12.9583 10.5417L14.4583 12.0417L21 5.5ZM12.5 13C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12C12.2239 12 12 12.2239 12 12.5C12 12.7761 12.2239 13 12.5 13Z"
        fill="currentColor"
      />
    ),
  }),
};
