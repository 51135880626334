/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { CSSProperties } from "react";
import { CSSPartial } from "../../base/@types/css.types";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useObservableRef } from "../../base/hooks/useObservableRef.hook";
import {
  fromTablet,
  fromTabletLg,
} from "../../base/styles/helpers/mediaQueries.styling";
import { hideScrollbars } from "../../base/styles/partials/scrollable.stylePartials";
import cx from "../../base/utils/className.utils";
import { usePropsFast, useStore } from "../../base/utils/mobx.utils";
import { Composition } from "../../models/Composition.model";
import ImageFromFileRecord from "../../base/components/ImageFromFileRecord";

type CompositionThumbnailProps = {
  composition: Composition;
  height?: string | number;
  className?: string;
  alwaysFullWidth?: boolean;
};

const style = {
  figure: {
    margin: 0,
    padding: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "4em",
    boxSizing: "content-box",
    [fromTablet]: {
      height: "5em",
      overflowX: "auto",
      overflowY: "hidden",
      WebkitOverflowScrolling: "touch",
      ...hideScrollbars(),
    },
    img: {
      height: "var(--height, 4em)",
      maxWidth: "100%",
      pointerEvents: "none",
      imageRendering: "pixelated",
      [fromTablet]: {
        height: "var(--height, 5em)",
        maxHeight: "var(--height, 5em)",
        width: "auto",
      },
      [fromTabletLg]: {
        maxWidth: "100%",
      },
      opacity: 0,
      transition: ".1s opacity",
      "&.loaded": {
        opacity: 1,
      },
    },
    "&.alwaysFullWidth": {
      img: {
        width: "100%",
      },
    },
  } as CSSPartial,
};

const CompositionThumbnail: React.FC<CompositionThumbnailProps> = props => {
  const { THEME } = useControllers();
  const p = usePropsFast(props);
  const imageRef = useObservableRef<HTMLImageElement>();
  const s = useStore(() => ({
    loaded: false,
    naturalWidth: 0,
    naturalHeight: 0,
    onLoad: () => {
      s.naturalWidth = imageRef.current?.naturalWidth ?? 0;
      s.naturalHeight = imageRef.current?.naturalHeight ?? 0;
      s.loaded = true;
    },
    get thumbnailId() {
      return THEME.isDarkTheme
        ? p.composition.$.thumbnailDarkId ?? p.composition.$.thumbnailLightId
        : p.composition.$.thumbnailLightId ?? p.composition.$.thumbnailDarkId;
    },
  }));
  return (
    <Observer
      children={() => (
        <figure
          css={style.figure}
          className={cx(p.className, p.alwaysFullWidth && "alwaysFullWidth")}
          style={
            p.height
              ? ({
                  "--height": p.height,
                } as CSSProperties)
              : undefined
          }
        >
          {s.thumbnailId && (
            <ImageFromFileRecord
              className={cx(s.loaded && "loaded")}
              imageId={s.thumbnailId}
              onLoad={s.onLoad}
              innerRef={imageRef}
              fadeInOnLoad
              style={{
                maxHeight: s.naturalHeight * 3,
              }}
            />
          )}
        </figure>
      )}
    />
  );
};

export default CompositionThumbnail;
