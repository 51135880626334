/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import WithRequest from "../../base/components/ContainerWithRequest";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import NewCollectionButton from "../../components/controls/NewCollectionButton";
import CollectionIndex from "../../components/directory/CollectionIndex";
import WorkshopAreaPageTemplate from "./_WorkshopArea.pageTemplate";
import WorkshopAreaPageHeader from "./_WorkshopAreaPageHeader";

type WorkshopCollectionIndexPageProps = {};

const WorkshopCollectionIndexPage: React.FC<
  WorkshopCollectionIndexPageProps
> = props => {
  const { COLLECTIONS } = useControllers();

  return (
    <Observer
      children={() => (
        <WorkshopAreaPageTemplate title="Collections">
          <WorkshopAreaPageHeader
            EndSlot={
              <NewCollectionButton shouldNavigateToNewCollectionAfterCreation />
            }
          >
            <h1>Collections</h1>
          </WorkshopAreaPageHeader>
          <WithRequest
            id="WorkshopCollectionIndex"
            request={COLLECTIONS.getAllOwn}
            children={() => (
              <CollectionIndex linkTo="workshop" appearance="entry" />
            )}
          />
        </WorkshopAreaPageTemplate>
      )}
    />
  );
};

export default WorkshopCollectionIndexPage;
