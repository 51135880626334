import Helmet from "react-helmet";
import { APP_DISPLAY_NAME } from "../../constants/app.constants";

type Props = React.PropsWithChildren<{
  children: string;
}>;

const Title = ({ children }: Props) => {
  return (
    <Helmet>
      <title>
        {[
          children,
          !children?.match(new RegExp(`${APP_DISPLAY_NAME}$`)) &&
            APP_DISPLAY_NAME,
        ]
          .filter(i => i)
          .join(" • ")}
      </title>
    </Helmet>
  );
};

export default Title;
