/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import Button, { ButtonGroup } from "../../base/components/Button";
import FileUploader from "../../base/components/FileUploader";
import Spacing from "../../base/components/Spacing";
import Textarea from "../../base/components/Textarea";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { grid6col } from "../../base/styles/partials/grids.stylePartials";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { copyWithJSON } from "../../base/utils/object.utils";
import { Collection } from "../../models/Collection.model";
import { FileRecord } from "../../models/FileRecord.model";

type CollectionSetupEditorProps = {
  collection: Collection;
  onSave?: () => void;
  onCancel?: () => void;
  autoFocus?: boolean;
  includeSaveButton?: boolean;
};

const CollectionSetupEditor: React.FC<CollectionSetupEditorProps> = props => {
  const { COLLECTIONS, THEME } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    snapshot: p.includeSaveButton
      ? copyWithJSON(p.collection.$)
      : p.collection.$,
    handlePhotoUpload: (file: FileRecord) => {
      s.snapshot.coverImageId = file._id;
    },
    save: async () => {
      await COLLECTIONS.save(s.snapshot);
      p.onSave?.();
    },
  }));
  return (
    <Observer
      children={() => (
        <>
          <div css={grid6col.grid}>
            <TextInput
              css={grid6col.full}
              form={s.snapshot}
              field="name"
              Label="Name"
              placeholder="Enter a name"
              autoFocus={p.autoFocus}
              onEnter={s.save}
              autoComplete="off"
            />
            <TextInput
              css={grid6col.full}
              form={s.snapshot}
              field="subheading"
              Label="Subheading"
              placeholder="Subheading / Subtitle (Optional)"
            />
            <div css={grid6col.full}>
              <h4>Photo</h4>
              <Spacing size=".5em" />
              <FileUploader
                previewImage={p.collection.coverImage}
                acceptOnlyImages
                onUpload={s.handlePhotoUpload}
              />
            </div>
            <Textarea
              css={grid6col.full}
              form={s.snapshot}
              field="description"
              Label="Description (Optional)"
              rows={5}
            />
          </div>
          {p.includeSaveButton && (
            <>
              <Spacing />
              <ButtonGroup>
                <Button fullWidth onClick={p.onCancel}>
                  Discard changes
                </Button>
                <Button fullWidth onClick={s.save} color={THEME.primary}>
                  Save changes
                </Button>
              </ButtonGroup>
            </>
          )}
        </>
      )}
    />
  );
};

export default CollectionSetupEditor;
