/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Replica } from "../../@types";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import AtomThumbnailCanvas from "./AtomThumbnailCanvas";

type ReplicaEntryProps = {
  replica: Replica;
  selectReplicaOnClick?: boolean;
};

const replicaEntryStyle = css`
  &.interactable {
    cursor: pointer;
  }
  > * + * {
    margin-top: 0.5em;
  }
  p {
    font-weight: 600;
  }
`;

const ReplicaEntry: React.FC<ReplicaEntryProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    handleClick: () => {
      if (p.selectReplicaOnClick) p.replica.select();
    },
  }));
  return (
    <Observer
      children={() => (
        <div
          className={cx(p.selectReplicaOnClick && "interactable")}
          css={replicaEntryStyle}
          onClick={s.handleClick}
        >
          <AtomThumbnailCanvas target={p.replica} />
          <p>{p.replica.displayName}</p>
        </div>
      )}
    />
  );
};

export default ReplicaEntry;
