/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { MouseEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSSPartial } from "../../base/@types/css.types";
import cx from "../../base/utils/className.utils";
import { useProps } from "../../base/utils/mobx.utils";
import { makeSlug } from "../../base/utils/slug.utils";
import { varFontSerif } from "../../constants/cssCustomProperties.constants";
import { Artist } from "../../models/Artist.model";
import PersonIcon from "../shared/PersonIcon";

type ArtistEntryProps = {
  className?: string;
  artist?: Artist;
  asLink?: boolean;
  linkTo?: "discover" | "workshop" | "admin";
  appearance?: "prominent" | "entry" | "text";
  padding?: string | number;
  photoSize?: string | number;
  pseudoLink?: boolean;
};

const entryStyle: CSSPartial = {
  display: "grid",
  gridGap: ".5em",
  alignItems: "center",
  justifyContent: "start",
  gridTemplateColumns: "auto minmax(0, 1fr)",
  fontWeight: 400,
  "&:hover": {
    filter: "brightness(1.1)",
  },
  "&:active": {
    filter: "brightness(.9)",
  },
};
const prominentStyle: CSSPartial = {
  display: "grid",
  gridGap: "1em",
  alignItems: "center",
  justifyContent: "stretch",
  gridTemplateRows: "auto minmax(0, 1fr)",
  textAlign: "center",
  fontFamily: varFontSerif,
  // fontVariantNumeric: "oldstyle-nums",
  "&:hover": {
    filter: "brightness(1.1)",
  },
  "&:active": {
    filter: "brightness(.9)",
  },
};

const ArtistEntry: React.FC<ArtistEntryProps> = props => {
  const p = useProps(props);
  const navigate = useNavigate();
  return (
    <Observer
      children={() => {
        const className = cx("ArtistEntry", p.className);
        const inner = (
          <>
            {p.appearance !== "text" && (
              <PersonIcon
                size={
                  p.photoSize ?? (p.appearance === "entry" ? "3em" : undefined)
                }
                imageId={p.artist?.$.imageId}
                fadeInOnLoad
              />
            )}
            <span>{p.artist?.fullName ?? "No artists"}</span>
          </>
        );
        const link = `/${p.linkTo ?? "discover"}/artists/${
          p.artist?._id
        }?i=${makeSlug(p.artist?.fullName ?? "")}`;
        const style =
          p.appearance === "prominent" ? prominentStyle : entryStyle;
        const inlineStyle = {
          padding: p.padding,
        };
        const handleClick = (e: MouseEvent) => {
          if (p.pseudoLink) {
            e.preventDefault();
            e.stopPropagation();
            navigate(link);
          }
        };
        return p.asLink && !p.pseudoLink ? (
          <Link className={className} to={link} css={style} style={inlineStyle}>
            {inner}
          </Link>
        ) : (
          <div
            className={className}
            css={style}
            style={inlineStyle}
            onClick={handleClick}
          >
            {inner}
          </div>
        );
      }}
    />
  );
};

export default ArtistEntry;
