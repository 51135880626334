export function isNumber(v: unknown): v is number {
  return typeof v === "number";
}
export function isNumberLike(v: unknown): v is number | string {
  return v !== null && (typeof v === "number" || !isNaN(+`${v}`));
}
export function isString(v: unknown): v is string {
  return typeof v === "string";
}
export function isBoolean(v: unknown): v is boolean {
  return typeof v === "boolean";
}
export function isUndefined(v: unknown): v is undefined {
  return v === undefined;
}
export function isNull(v: unknown): v is null {
  return v === null;
}
export function isNotNull<T>(v: T): v is NonNullable<T> {
  return v !== null;
}

export function isFunction(v: unknown): v is (...args: unknown[]) => unknown {
  return typeof v === "function";
}
export function isArray<T>(v: unknown): v is T[] {
  return v instanceof Array;
}
/**
 * checks if given value is an object. `null` will return false, and arrays will return true;
 */
export function isObject<T extends AnyObject>(v: unknown): v is T {
  if (v === null) return false;
  return v instanceof Object;
}
export function isPromise<T>(v: unknown): v is Promise<T> {
  return v instanceof Promise;
}
