import { reaction } from "mobx";

export function addClass(el: Element | null, className: string) {
  if (!el) return;
  el.classList.add(className);
}
export function removeClass(el: Element | null, className: string) {
  if (!el) return;
  el.classList.remove(className);
}
export function hasClass(el: Element | null, className: string) {
  if (!el) return;
  return el.classList.contains(className);
}
export function bindClass(
  el: Element | null,
  condition: () => boolean,
  className: string
) {
  return reaction(
    condition,
    arg => {
      el?.classList.toggle(className, arg);
    },
    { fireImmediately: true }
  );
}

type getHeightOptions = {
  includePadding?: boolean;
};
export function getHeight(el: Element | null, options?: getHeightOptions) {
  if (!el) return 0;
  // TODO: include padding?
  return el.clientHeight;
}

export function element(el: string | "root" | "html" | "body" | Element) {
  let element: Element | null;
  if (el === "root" || el === "html") element = document.documentElement;
  else if (el === "body") element = document.body;
  else element = typeof el === "string" ? document.querySelector(el) : el;
  return {
    element,
    addClass: (className: string) => addClass(element, className),
    removeClass: (className: string) => removeClass(element, className),
    hasClass: (className: string) => hasClass(element, className),
    bindClass: (condition: () => boolean, className: string) =>
      bindClass(element, condition, className),
    getHeight: (options?: getHeightOptions) => getHeight(element, options),
  };
}

export const addRootClass = (value: string) =>
  value.includes(" ")
    ? value.split(" ").forEach(c => document.documentElement.classList.add(c))
    : document.documentElement.classList.add(value);
export const removeRootClass = (value: string) =>
  value.includes(" ")
    ? value
        .split(" ")
        .forEach(c => document.documentElement.classList.remove(c))
    : document.documentElement.classList.remove(value);
export const hasRootClass = (value: string) =>
  value.includes(" ")
    ? value.split(" ").some(c => document.documentElement.classList.contains(c))
    : document.documentElement.classList.contains(value);

export const isFocusedOnInputOrTextarea = () => {
  return (
    document.activeElement?.nodeName === "INPUT" ||
    document.activeElement?.nodeName === "TEXTAREA"
  );
};
export const isFocusedOnInputOrTextareaOrButtons = () => {
  return (
    document.activeElement?.nodeName === "INPUT" ||
    document.activeElement?.nodeName === "TEXTAREA" ||
    document.activeElement?.nodeName === "BUTTON"
  );
};

export const isDarkTheme = () =>
  document.documentElement.classList.contains("dark");
export const isLightTheme = () =>
  document.documentElement.classList.contains("light");

export const hasFocusedInputs = (el: HTMLElement | null) => {
  return !!el?.querySelector("input:focus, textarea:focus");
};
export const documentHasFocusedInputs = () => {
  return hasFocusedInputs(document.documentElement);
};

export const isHtmlTag = (
  element: Element,
  ...tags: (keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap)[]
) => {
  return tags.some(tag => element.tagName === tag);
};

export const elementIsTextInputLike = (
  element?: Element | EventTarget | null
) => {
  if (!element) return false;
  return (
    isHtmlTag(element as Element, "input", "textarea") ||
    (element as Element).classList?.contains("PatternAnchor") ||
    (element as Element).classList?.contains("ReplicaAnchor")
  );
};

export const disableWheel = (element: HTMLDivElement | null) => {
  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();
  };
  element?.addEventListener("wheel", handleWheel, {
    passive: false,
  });
  return () => {
    element?.removeEventListener("wheel", handleWheel);
  };
};
