import { flow, makeAutoObservable, runInAction } from "mobx";
import { DialogsController } from "../../controllers/dialogs.controller";
import { DialogAction, DialogConfig, DialogStatus, IDialog } from "../@types";
import { getRandomNumericString } from "../utils/random.utils";
import { isFunction } from "../utils/typeChecks.utils";
import resolveAfter from "../utils/waiters.utils";

export const makeDefaultDialogActions: () => DialogAction[] = () => [
  {
    name: "negative",
    label: "Cancel",
    buttonColorMode: "translucent",
    action: () => false,
    isActionForEscape: true,
  },
  {
    name: "positive",
    label: "OK",
    buttonColorMode: "solid",
    action: () => true,
    isActionForEnter: true,
  },
];

export default class Dialog<T> implements IDialog<T> {
  id = getRandomNumericString();
  config: DialogConfig;
  status: DialogStatus = "beforeOpen";
  controller: DialogsController;
  promise: Promise<T>;
  actions: (DialogAction & { id: string })[] = [];
  constructor(config: DialogConfig, controller: DialogsController) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ref = this;
    this.config = config;
    if (!this.config.name) this.config.name = this.id;
    this.controller = controller;
    this.status = "opened";
    this.promise = new Promise((resolve: (v: T) => void, reject) => {
      const defaultActions = makeDefaultDialogActions();
      const shouldAddDefaultActions: DialogAction[] = this.config.defaultActions
        ? (this.config.defaultActions
            .map(name => defaultActions.find(i => i.name === name))
            .filter(i => !!i) as DialogAction[])
        : this.config.actions
        ? []
        : defaultActions;
      const providedActions = [
        ...shouldAddDefaultActions,
        ...(this.config.actions || ([] as DialogAction[])),
      ];
      providedActions.forEach(a => {
        a.id = getRandomNumericString();
        a.waiting = false;
        const { action } = a;
        a.action = flow(function* () {
          let result: T;
          try {
            a.waiting = true;
            result = isFunction(action) ? yield action() : void 0;
            resolve(result);
          } catch (e) {
            reject(e);
          } finally {
            a.waiting = false;
            ref.close();
          }
        });
      });
      this.actions = providedActions as (DialogAction & { id: string })[];
    });
    makeAutoObservable(this);
  }
  get _closeFromUiStore() {
    return this.controller.dismiss;
  }
  close() {
    return new Promise<boolean | void>(async (resolve, reject) => {
      if (this.config.onBeforeClose) {
        const result = await this.config.onBeforeClose();
        if (!result) {
          resolve(false);
          return;
        }
      }
      this.status = "closing";
      await resolveAfter(380);
      runInAction(() => {
        this.status = "closed";
      });
      resolve();
      this.controller.dismiss(this);
    });
  }
}
