/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useControllers } from "../hooks/rootContext.hooks";
import { fillWithFixed } from "../styles/partials/fillWithFixed.stylePartials";

interface SplashScreenProps {}

const SplashScreenEnterKeyframes = keyframes`
  0% { opacity: 0; }
  75% { opacity: 0; }
  100% { opacity: 1; }
`;

const SplashScreenEnterStyle = {
  ...fillWithFixed,
  animation: `${SplashScreenEnterKeyframes} 1s`,
};

const SplashScreen: React.FC<SplashScreenProps> = observer(p => {
  const { COMMON } = useControllers();

  return (
    <div className="SplashScreen" css={SplashScreenEnterStyle}>
      {COMMON.app.name} {COMMON.app.version}
    </div>
  );
});

export default SplashScreen;
