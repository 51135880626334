import { makeUrl } from "../utils/url.utils";

const websiteUrl =
  process.env.REACT_APP_WEBSITE_URL || "https://www.clavierist.com";

export const paths = {
  website: websiteUrl,
  learn: makeUrl(websiteUrl, "learn", "introduction"),
  terms: makeUrl(websiteUrl, "terms"),
  examples: makeUrl(websiteUrl, "examples"),
  contactEmailLink: encodeURI(
    "mailto:yile.clavierist@gmail.com?subject=Clavierist app feedback"
  ),
  learnInterpretations: makeUrl(
    websiteUrl,
    "learn",
    "interpretations-and-keyframes"
  ),
};
