/** @jsxImportSource @emotion/react */
import { Observer, observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSSPartial } from "../../base/@types/css.types";
import {
  VAR_PanelBackdropFilter,
  VAR_PanelBackground,
  VAR_PanelBorder,
  VAR_SeparatorBorder,
  bg50,
  bg90,
  fg,
  fg70,
  varFontSerif,
  varPrimary,
  varVh,
} from "../../constants/cssCustomProperties.constants";
import LineSeparator from "../../base/components/LineSeparator";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import {
  fromTablet,
  onlyPhones,
} from "../../base/styles/helpers/mediaQueries.styling";
import { useStore } from "../../base/utils/mobx.utils";
import ColorSchemeSwitch from "../composer/ColorSchemeSwitch";
import { cssVarAppPageInset } from "../../utils/css.utils";
import PersonIcon from "../shared/PersonIcon";
import IconButton from "../../base/components/IconButton";
import cx from "../../base/utils/className.utils";
import { keyframes } from "@emotion/react";
import { cVar } from "../../base/utils/customProperties.utils";
import { Logo20 } from "../../base/components/Logo";
import { externalLinkAttrs } from "../../base/utils/links.utils";
import RegexLink from "../../base/components/RegexLink";
import { paths } from "../../base/constants/paths.constants";
import { ExternalLinkIcon } from "../../base/components/ExternalLinkIcon";

const backdropEnterKeyframes = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const style = {
  component: {
    boxSizing: "border-box",
    position: "sticky",
    top: 0,
    [fromTablet]: {
      display: "grid",
      gridTemplateRows: "minmax(auto, 1fr) auto",
      gridTemplateAreas: "'nav' 'header'",
      overflow: "auto",
      height: varVh,
      left: 0,
      padding: cssVarAppPageInset(),
    },
  } as CSSPartial,
  backdrop: {
    position: "fixed",
    top: "5.62rem",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: bg50,
    animation: `${backdropEnterKeyframes} 1s`,
  } as CSSPartial,
  nav: {
    gridArea: "nav",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontFamily: varFontSerif,
    [onlyPhones]: {
      position: "fixed",
      top: "5.62rem",
      left: 0,
      bottom: 0,
      minWidth: "25em",
      borderRight: cVar(VAR_PanelBorder),
      backgroundColor: cVar(VAR_PanelBackground),
      backdropFilter: cVar(VAR_PanelBackdropFilter),
      paddingLeft: "2em",
      paddingRight: "2em",
      transform: `translateX(-100%)`,
      transition: "transform .2s cubic-bezier(0.38,0,0,1)",
      ".menuOpen &": {
        transition: "transform .5s cubic-bezier(0.38,0,0,1)",
        transform: `translateX(0)`,
      },
    },
    a: {
      textDecoration: "none",
      whiteSpace: "nowrap",
      color: fg70,
    },
    h2: {
      display: "flex",
      flexDirection: "column",
      fontFamily: varFontSerif,
      textDecoration: "none",
      fontWeight: 300,
      span: {
        display: "block",
      },
      a: {
        color: fg,
        fontSize: "1.6rem",
        fontWeight: "inherit",
        textTransform: "lowercase",
        fontFeatureSettings: "'smcp' 1",
        "&.active": {
          pointerEvents: "none",
        },
      },
    },
    ul: {
      listStyle: "none",
      padding: 0,
      fontSize: "1.6rem",
    },
    li: {
      "a, span": {
        display: "block",
        padding: ".5em 0",
        cursor: "pointer",
        color: fg70,
        sup: {
          fontSize: "1rem",
          fontWeight: 500,
        },
        "&:hover": {
          color: varPrimary,
        },
        "&.active": {
          color: varPrimary,
        },
      },
    },
  } as CSSPartial,
  navInner: {
    flex: "1 0 auto",
  } as CSSPartial,
  major: {
    li: {
      a: {
        fontSize: "2.6rem",
        fontWeight: 200,
        padding: ".375em 0",
        sup: {
          fontSize: "1.4rem",
          fontWeight: 500,
        },
      },
    },
  } as CSSPartial,
  header: {
    [onlyPhones]: {
      display: "grid",
      gridTemplateColumns: "minmax(0, auto) minmax(auto, 1fr) minmax(0, auto)",
      gridTemplateAreas: "'menuButton center accountButton'",
      backgroundColor: bg90,
      backdropFilter: "blur(1em)",
      padding: "1em",
      borderBottom: cVar(VAR_SeparatorBorder),
    },
    [fromTablet]: {
      display: "none",
    },
  } as CSSPartial,
  logoContainer: {
    gridArea: "center",
    textAlign: "center",
    alignSelf: "center",
  } as CSSPartial,
  logoContainerDesktop: {
    display: "inline-block",
    [onlyPhones]: {
      display: "none",
    },
    "&:hover": {
      color: varPrimary,
    },
  } as CSSPartial,
  logo: {
    textDecoration: "none",
    fontWeight: 700,
    fontSize: "2.2rem",
    lineHeight: 1,
    fontFamily: varFontSerif,
  } as CSSPartial,
  headerMenuButton: {
    gridArea: "menuButton",
  } as CSSPartial,
  headerAccountButton: {
    gridArea: "accountButton",
  } as CSSPartial,
  footer: {
    gridArea: "footer",
    flex: "0 0 auto",
    [onlyPhones]: {
      display: "none",
    },
    [fromTablet]: {
      display: "flex",
      marginLeft: "-0.5em",
      marginBottom: "-0.5em",
    },
    "> * + *": {
      marginLeft: "1.5em",
    },
  } as CSSPartial,
};

const AppNavigation: React.FC<{
  className?: string;
}> = observer(props => {
  const { DIALOGS, AUTH, UI } = useControllers();

  const navigate = useNavigate();

  const s = useStore(() => ({
    menuOpen: false,
    toggleMenu: () => {
      s.menuOpen = !s.menuOpen;
    },
    logout: async () => {
      const confirm = await DIALOGS.attention({
        Heading: "Are you sure you want to log out?",
      });
      if (confirm) navigate("/logout");
    },
    get accountButton() {
      return (
        <Link
          css={style.headerAccountButton}
          to={AUTH.user ? "/account" : "/auth/login"}
        >
          <PersonIcon
            imageId={AUTH.user?.$.profileImageId}
            size="2.5em"
            eager
          />
        </Link>
      );
    },
  }));

  return (
    <Observer
      children={() => (
        <div
          css={style.component}
          className={cx("AppNavigation", s.menuOpen && "menuOpen")}
        >
          <header css={style.header}>
            <div css={style.logoContainer}>
              <Link to="/" css={style.logo}>
                <Logo20 />
              </Link>
            </div>
            <IconButton
              css={style.headerMenuButton}
              icon={s.menuOpen ? "close" : "menu"}
              onClick={s.toggleMenu}
            />
            {s.accountButton}
          </header>
          {s.menuOpen && <div css={style.backdrop} onClick={s.toggleMenu} />}
          <nav css={style.nav}>
            <div css={style.navInner}>
              <div css={style.major}>
                <Link to="/" css={style.logoContainerDesktop}>
                  <Logo20 />
                </Link>
                <ul>
                  {AUTH.isAuthenticated && (
                    <>
                      <li>
                        <RegexLink
                          to="/workshop"
                          regexNot={
                            /^\/workshop\/(archived|collections|artists)\/?/
                          }
                        >
                          Workshop
                        </RegexLink>
                      </li>
                      <li>
                        <RegexLink to="/workshop/artists">Artists</RegexLink>
                      </li>
                      <li>
                        <RegexLink to="/workshop/collections">
                          Collections
                        </RegexLink>
                      </li>
                    </>
                  )}
                  <li>
                    <a href={paths.learn} {...externalLinkAttrs}>
                      Learn
                      <ExternalLinkIcon />
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <LineSeparator margin="1.5em 0" />
                <ul>
                  <li>
                    <a href={paths.website} {...externalLinkAttrs}>
                      Visit website
                      <ExternalLinkIcon />
                    </a>
                  </li>
                  {AUTH.isAuthenticated && AUTH.isAdmin && (
                    <li>
                      <RegexLink to="/admin/">Administration</RegexLink>
                    </li>
                  )}
                  {AUTH.isAuthenticated ? (
                    <>
                      <li>
                        <RegexLink to="/workshop/archived">Archived</RegexLink>
                      </li>
                      <li>
                        <RegexLink to="/account/">Account settings</RegexLink>
                      </li>
                      <li>
                        <a href={paths.contactEmailLink}>
                          Submit feedback
                          <ExternalLinkIcon />
                        </a>
                      </li>
                      <li>
                        <span onClick={s.logout}>Log out</span>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <a href={paths.contactEmailLink}>Submit feedback</a>
                      </li>
                      <li>
                        <RegexLink to="/auth/login/">Log in</RegexLink>
                      </li>
                      <li>
                        <RegexLink to="/auth/signup/">Sign up</RegexLink>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <footer css={style.footer}>
              {s.accountButton}
              {!UI.isPhone && <ColorSchemeSwitch />}
            </footer>
          </nav>
        </div>
      )}
    />
  );
});

export default AppNavigation;
