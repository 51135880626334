/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { useCompleteProgressBar } from "../../base/utils/globalProgressBar.utils";
import PageAccountHome from "./PageAccountHome";

type P = {};

const AccountIndex: React.FC<P> = props => {
  useCompleteProgressBar();
  return (
    <Observer
      children={() => (
        <Routes>
          <Route index element={<PageAccountHome />} />
          <Route path="*" element={<Navigate to="/workshop" />} />
        </Routes>
      )}
    />
  );
};

export default AccountIndex;
