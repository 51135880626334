/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import Panel, { PanelHeader, PanelInner } from "../../base/components/Panel";
import Serif from "../../base/components/Serif";
import Spacing from "../../base/components/Spacing";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { Artist } from "../../models/Artist.model";
import ArtistSetupEditor from "../shared/ArtistSetupEditor";

type ArtistEditorPanelProps = {
  artist: Artist;
  onShouldClose?: (newArtist?: Artist) => void;
};

const ArtistEditorPanel: React.FC<ArtistEditorPanelProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    closeSelf: () => {
      p.onShouldClose?.();
    },
  }));
  return (
    <Observer
      children={() => (
        <Panel
          id="new-artist"
          title="Edit Artist"
          fullscreen
          canEscape
          width={640}
        >
          <PanelHeader>
            <h2>
              <Serif>Edit Artist</Serif>
            </h2>
            <Spacing />
            <p>
              Set up your new artist. You can always change those settings
              later.
            </p>
          </PanelHeader>
          <PanelInner>
            <ArtistSetupEditor
              artist={p.artist}
              includeSubmitButton
              onSave={s.closeSelf}
              onCancel={s.closeSelf}
              autoFocus
            />
          </PanelInner>
        </Panel>
      )}
    />
  );
};

export default ArtistEditorPanel;
