/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import { sort } from "fast-sort";
import React from "react";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useStore } from "../../base/utils/mobx.utils";
import WorkshopAreaPageTemplate from "./_WorkshopArea.pageTemplate";
import CompositionList from "../../components/directory/CompositionList";
import WithRequest from "../../base/components/ContainerWithRequest";

type WorkshopCompositionIndexPageProps = {};

const WorkshopCompositionIndexPage: React.FC<
  WorkshopCompositionIndexPageProps
> = props => {
  const { COMPOSITIONS } = useControllers();
  const s = useStore(() => ({
    get allNonArchivedCompsSortedByLatestFirst() {
      return sort(COMPOSITIONS.allNonArchived).desc(c => c.$.timeUpdated);
    },
  }));
  return (
    <Observer
      children={() => (
        <WorkshopAreaPageTemplate title="Compositions">
          <WithRequest
            id="WorkshopCompositionIndex"
            request={COMPOSITIONS.getAllOwnMeta}
            children={() => (
              <CompositionList
                compositions={s.allNonArchivedCompsSortedByLatestFirst}
                mode="editor"
                enableContextMenu
              />
            )}
          />
        </WorkshopAreaPageTemplate>
      )}
    />
  );
};

export default WorkshopCompositionIndexPage;
