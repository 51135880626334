import { computed, makeObservable } from "mobx";
import { joinTruthyWithSpace } from "../base/utils/array.utils";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { hasHumanName } from "../traits/hasHumanName";
import { has_id } from "../traits/hasId.trait";
import { hasOwnerAndAuthors } from "../traits/hasOwnerAndAuthors.trait";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import {
  StandardModel,
  addChangeDetectionToModel,
  appendMissingKeys,
} from "./StandardModel";
import { User } from "./User.model";

export type ArtistSnapshot = ReturnType<typeof ArtistSnapshotFactory>;
export const MakeCollectionDescriptor = (
  collectionId: string,
  catalogueNumber?: string
) => ({
  collectionId,
  catalogueNumber,
});
export type CollectionDescriptor = ReturnType<typeof MakeCollectionDescriptor>;

export const ArtistSnapshotFactory = () => ({
  ...has_id(),
  ...hasHumanName(),
  ...hasOwnerAndAuthors(),
  title: "Artist",
  dateOfBirth: null as string | null,
  description: "" as string | null,
  catalogueIdentifier: null as string | null,
  collectionDescriptors: [] as CollectionDescriptor[],
  imageId: null as string | null,
  compositionIds: [] as string[],
  ...hasTimestamps(),
});

const defaultSnapshot = ArtistSnapshotFactory();

const changeDetectionSnapshotMaker = ($: ArtistSnapshot) => {
  const {
    _id,
    title,
    dateOfBirth,
    description,
    catalogueIdentifier,
    collectionDescriptors,
    imageId,
    compositionIds,
  } = $;
  return JSON.stringify({
    _id,
    title,
    dateOfBirth,
    description,
    catalogueIdentifier,
    collectionDescriptors,
    imageId,
    compositionIds,
  });
};

export class Artist extends StandardModel<ModelName.artists, ArtistSnapshot> {
  constructor(LOCALDB: LocalDBController, $ = ArtistSnapshotFactory()) {
    appendMissingKeys($, defaultSnapshot);
    super(ModelName.artists, LOCALDB, $);
    makeObservable(this, {
      fullName: computed,
      title: computed,
      compositions: computed,
      owner: computed,
      authors: computed,
      image: computed,
      isArchived: computed,
    });
    addChangeDetectionToModel(
      this,
      LOCALDB,
      changeDetectionSnapshotMaker,
      this.d
    );
  }
  get fullName() {
    return (
      joinTruthyWithSpace(
        this.$.givenName,
        this.$.middleName,
        this.$.surname
      ) || "[No name]"
    );
  }
  get title() {
    return this.$.title ?? "Artist";
  }
  set title(v) {
    this.$.title = v;
  }
  get owner() {
    return this.LOCALDB.get<User>(ModelName.users, this.$.ownerId);
  }
  get authors() {
    return this.LOCALDB.getMany<User>(ModelName.users, this.$.authorIds);
  }
  get compositions() {
    return (
      this.ROOT?.COMPOSITIONS.allNonArchived.filter(c =>
        c.artists.includes(this)
      ) ?? []
    );
  }
  get image() {
    return this.LOCALDB.getFileRecord(this.$.imageId);
  }
  get isArchived() {
    return !!this.$.timeArchived;
  }
}

export const makeArtistSnapshot = (partial: Partial<ArtistSnapshot>) =>
  makeSnapshot(ArtistSnapshotFactory, partial);
