/** @jsxImportSource @emotion/react */
import { Global } from "@emotion/react";
import { darken, lighten, saturate, shade, transparentize } from "polished";
import { reaction, toJS } from "mobx";
import { Observer } from "mobx-react-lite";
import { VAR_InstrumentUIBorder } from "../../components/shared/InstrumentUI";
import {
  VAR_PianoKeyColor,
  VAR_PianoKeyHalfStepBorder,
  VAR_PianoKeyHalfStepColor,
  VAR_PianoKeyHoverFilter,
  VAR_PianoKeyboardBackground,
  VAR_PianoKeyboardHalfStepKeyShadow,
  VAR_PianoKeyboardTopShaderOpacity,
} from "../../components/shared/PianoKeyboard";
import { CSSPartial } from "../@types/css.types";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { provideFontFallback } from "../styles/helpers/fontFamilyFallbacks.styleHelper";
import { border } from "../styles/helpers/shorthands.styleHelpers";
import { makeDisposerController } from "../utils/disposer.utils";
import { useStore } from "../utils/mobx.utils";
import {
  VAR_DialogBackdrop,
  VAR_DialogBackground,
  VAR_DialogShadow,
} from "./Dialog/Dialog";
import { useControllers } from "../hooks/rootContext.hooks";
import { supportsBackdropBlur } from "../styles/helpers/supports.cssHelpers";
import {
  VAR_Accent,
  VAR_Bg,
  VAR_Bg00,
  VAR_Bg02,
  VAR_Bg05,
  VAR_Bg10,
  VAR_Bg20,
  VAR_Bg30,
  VAR_Bg40,
  VAR_Bg50,
  VAR_Bg60,
  VAR_Bg70,
  VAR_Bg80,
  VAR_Bg90,
  VAR_BlurBackdropFilter,
  VAR_BorderForeground10,
  VAR_DarkerBackgroundWhenDark,
  VAR_FONT_MONO,
  VAR_FONT_MONO_OPEN_SOURCE,
  VAR_FONT_NOTATION,
  VAR_FONT_NOTATION_TEXT,
  VAR_FONT_SANS,
  VAR_FONT_SANS_OPEN_SOURCE,
  VAR_FONT_SERIF,
  VAR_FONT_SERIF_SCORE,
  VAR_Fg,
  VAR_Fg00,
  VAR_Fg02,
  VAR_Fg03,
  VAR_Fg05,
  VAR_Fg07,
  VAR_Fg10,
  VAR_Fg15,
  VAR_Fg20,
  VAR_Fg30,
  VAR_Fg40,
  VAR_Fg50,
  VAR_Fg60,
  VAR_Fg70,
  VAR_Fg80,
  VAR_Fg90,
  VAR_ForegroundDarken,
  VAR_InputBackground,
  VAR_InputBackgroundHover,
  VAR_InputBorder,
  VAR_InputFocusOutline,
  VAR_InputForeground,
  VAR_InputHighlight,
  VAR_LineWidth,
  VAR_OctaveGridHalfStepHintColor,
  VAR_PanelBackdropFilter,
  VAR_PanelBackground,
  VAR_PanelBorder,
  VAR_PanelBorderlessHeaderBackground,
  VAR_PanelDockBottomHeight,
  VAR_Primary,
  VAR_Primary05,
  VAR_Primary10,
  VAR_Primary20,
  VAR_Primary30,
  VAR_Primary40,
  VAR_Primary50,
  VAR_Primary80,
  VAR_PrimaryContrast,
  VAR_PrimaryContrast10,
  VAR_PrimaryContrast20,
  VAR_SeparatorBorder,
  VAR_ShadowMedium,
  VAR_TitleBarHeight,
  VAR_ToolWheelBackgroundFill,
  VAR_ToolWheelBorderOuter,
  VAR_ToolWheelOverflow,
  VAR_ToolWheelSvgCircleStrokeColor,
  bg80,
  fg02,
  fg05,
  fg10,
  fg15,
  fg20,
} from "../../constants/cssCustomProperties.constants";
import { withOpacity } from "../utils/colors.utils";
import { cVar } from "../utils/customProperties.utils";
import chroma from "chroma-js";
import { UNITS } from "../constants/units.constant";
import { ColorPalette } from "../../theming/colorPalette";

declare global {
  interface Window {
    CSS_COLOR_DEFINITIONS: Record<string, string>;
    CSS_CUSTOM_PROPERTIES: Record<string, string>;
  }
}

export const CSSCustomPropertiesManager = () => {
  const { THEME, SETTINGS, UI } = useControllers();

  const s = useStore(() => ({
    get contextColors() {
      return THEME.contextColorsWithContrastColors;
    },
    get colorPalette() {
      return THEME.themeVariant.colors;
    },

    get colorDefinitions(): CSSPartial {
      const css: CSSPartial = {};
      Object.entries(THEME.themeVariant).forEach(
        e => (css[`--c-${e[0]}`] = e[1])
      );
      return {
        ":root": css,
      };
    },
    get primaryColor(): string {
      return THEME.primary;
    },
    get bg(): string {
      return THEME.bg;
    },
    get fg(): string {
      return THEME.fg;
    },
    get enableBlur() {
      return SETTINGS.computedValues.enableBlur;
    },
    get customProperties(): CSSPartial {
      const isDark = THEME.isDarkTheme;
      const pianoKeyColor =
        THEME.getColor("keyboardA") ??
        (isDark
          ? withOpacity(chroma(THEME.bg).brighten(0.3).hex(), 0.5)
          : THEME.bg);
      const pianoKeyHalfStepColor = isDark
        ? THEME.getColor("keyboardB") === THEME.getColor("background")
          ? THEME.blendWithBackgroundColor(
              THEME.getColor("keyboardB") ?? THEME.fg,
              0.175
            )
          : THEME.getColor("keyboardB")
        : THEME.getColor("keyboardB") === THEME.getColor("foreground")
        ? THEME.blendWithBackgroundColor(
            THEME.getColor("keyboardB") ?? THEME.fg,
            0.1
          )
        : THEME.getColor("keyboardB");
      const pianoKeyLuminosity = chroma(pianoKeyColor).luminance();
      const pianoHalfStepKeyLuminosity = chroma(
        pianoKeyHalfStepColor
      ).luminance();
      const isDarkKeyboard =
        pianoHalfStepKeyLuminosity > pianoKeyLuminosity &&
        pianoKeyLuminosity < 0.4;
      const needsShadowForPianoHalfStepKeys =
        isDarkKeyboard ||
        pianoKeyLuminosity - pianoHalfStepKeyLuminosity < 0.01;
      const pianoKeyboardBackground = isDarkKeyboard
        ? darken(0.3, pianoKeyColor)
        : withOpacity(s.fg, 0.1);
      return {
        ":root": {
          [VAR_FONT_SERIF]: provideFontFallback("serif", "signifier-web"),
          [VAR_FONT_SANS]: provideFontFallback("sans", "token7-sans"),
          [VAR_FONT_MONO]: provideFontFallback("monospace", "token7-mono"),
          [VAR_FONT_SANS_OPEN_SOURCE]: provideFontFallback(
            "sans",
            "geist-vf-web"
          ),
          [VAR_FONT_MONO_OPEN_SOURCE]: provideFontFallback(
            "monospace",
            "geist-mono-vf-web"
          ),
          [VAR_FONT_NOTATION]: "leland-web",
          [VAR_FONT_NOTATION_TEXT]: "leland-text-web",
          [VAR_FONT_SERIF_SCORE]: "edwin-web",

          [VAR_Primary]: s.primaryColor,
          [VAR_Primary80]: withOpacity(s.primaryColor, 0.8),
          [VAR_Primary50]: withOpacity(s.primaryColor, 0.5),
          [VAR_Primary40]: withOpacity(s.primaryColor, 0.4),
          [VAR_Primary30]: withOpacity(s.primaryColor, 0.3),
          [VAR_Primary20]: withOpacity(s.primaryColor, 0.2),
          [VAR_Primary10]: withOpacity(s.primaryColor, 0.1),
          [VAR_Primary05]: withOpacity(s.primaryColor, 0.05),

          [VAR_PrimaryContrast]: THEME.contrastContextColors.primary,
          [VAR_PrimaryContrast10]: withOpacity(
            THEME.contrastContextColors.primary,
            0.1
          ),
          [VAR_PrimaryContrast20]: withOpacity(
            THEME.contrastContextColors.primary,
            0.2
          ),

          [VAR_Accent]: s.primaryColor,

          [VAR_Fg]: s.fg,
          [VAR_Fg90]: withOpacity(s.fg, 0.9),
          [VAR_Fg80]: withOpacity(s.fg, 0.8),
          [VAR_Fg70]: withOpacity(s.fg, 0.7),
          [VAR_Fg60]: withOpacity(s.fg, 0.6),
          [VAR_Fg50]: withOpacity(s.fg, 0.5),
          [VAR_Fg40]: withOpacity(s.fg, 0.4),
          [VAR_Fg30]: withOpacity(s.fg, 0.3),
          [VAR_Fg20]: withOpacity(s.fg, 0.2),
          [VAR_Fg15]: withOpacity(s.fg, 0.15),
          [VAR_Fg10]: withOpacity(s.fg, 0.1),
          [VAR_Fg07]: withOpacity(s.fg, 0.07),
          [VAR_Fg05]: withOpacity(s.fg, 0.05),
          [VAR_Fg03]: withOpacity(s.fg, 0.03),
          [VAR_Fg02]: withOpacity(s.fg, 0.02),
          [VAR_Fg00]: withOpacity(s.fg, 0),

          [VAR_Bg]: s.bg,
          [VAR_Bg90]: withOpacity(s.bg, 0.9),
          [VAR_Bg80]: withOpacity(s.bg, 0.8),
          [VAR_Bg70]: withOpacity(s.bg, 0.7),
          [VAR_Bg60]: withOpacity(s.bg, 0.6),
          [VAR_Bg50]: withOpacity(s.bg, 0.5),
          [VAR_Bg40]: withOpacity(s.bg, 0.4),
          [VAR_Bg30]: withOpacity(s.bg, 0.3),
          [VAR_Bg20]: withOpacity(s.bg, 0.2),
          [VAR_Bg10]: withOpacity(s.bg, 0.1),
          [VAR_Bg05]: withOpacity(s.bg, 0.05),
          [VAR_Bg02]: withOpacity(s.bg, 0.02),
          [VAR_Bg00]: withOpacity(s.bg, 0),

          [VAR_DialogBackdrop]: withOpacity(s.bg, 0.6),
          [VAR_DialogBackground]: cVar(VAR_PanelBackground),
          [VAR_DialogShadow]: `0 1.5em 3em ${withOpacity(THEME.shade, 0.2)}`,

          [VAR_SeparatorBorder]: `${UNITS.lineWidth}px solid ${withOpacity(
            s.fg,
            0.15 * (SETTINGS.computedValues.highContrastBorders ? 2 : 1)
          )}`,

          [VAR_PianoKeyboardBackground]: pianoKeyboardBackground,
          [VAR_PianoKeyboardTopShaderOpacity]: isDarkKeyboard ? 1 : 0,
          [VAR_PianoKeyboardHalfStepKeyShadow]: isDarkKeyboard
            ? `0 1px 2px 2px ${withOpacity(
                ColorPalette.black,
                0.2 * (1 - pianoKeyLuminosity)
              )}`
            : needsShadowForPianoHalfStepKeys
            ? `0 1px 1px 1px ${withOpacity(pianoKeyboardBackground, 0.1)}`
            : "none",
          [VAR_PianoKeyHoverFilter]: `brightness(${
            isDarkKeyboard ? 1.19 : 1.05
          })`,
          [VAR_PianoKeyColor]: pianoKeyColor,
          [VAR_PianoKeyHalfStepColor]: pianoKeyHalfStepColor,
          [VAR_PianoKeyHalfStepBorder]: THEME.isDarkTheme
            ? "0"
            : `${UNITS.lineWidth}px solid ${withOpacity(pianoKeyColor, 0.4)}`,

          [VAR_InstrumentUIBorder]: isDark
            ? border(UNITS.lineWidth, withOpacity(lighten(0.5, s.bg), 0.1))
            : border(UNITS.lineWidth, fg20),

          [VAR_PanelBorder]: border(
            UNITS.lineWidth,
            withOpacity(
              s.fg,
              (isDark ? 0.15 : 0.3) *
                (SETTINGS.computedValues.highContrastBorders ? 2 : 1) *
                (UNITS.lineWidth > 0.5 ? 0.7 : 1)
            )
          ),
          [VAR_PanelBackground]: s.bg,
          [VAR_PanelBorderlessHeaderBackground]: s.bg,
          [VAR_PanelBackdropFilter]: "",
          [VAR_BlurBackdropFilter]: "",
          [supportsBackdropBlur]: {
            [VAR_PanelBackground]: s.enableBlur ? bg80 : s.bg,
            [VAR_PanelBorderlessHeaderBackground]: s.enableBlur
              ? isDark
                ? `linear-gradient(to bottom, ${THEME.bg} 80%, ${transparentize(
                    1,
                    THEME.bg
                  )} 100%)`
                : bg80
              : isDark
              ? s.bg
              : s.bg,
            [VAR_PanelBackdropFilter]: s.enableBlur
              ? isDark
                ? "blur(1.5em) saturate(1.25)"
                : "blur(1.5em)"
              : "",
            [VAR_BlurBackdropFilter]: s.enableBlur ? "blur(1.38em)" : "",
          },

          [VAR_ToolWheelOverflow]: isDark ? "hidden" : undefined,
          [VAR_ToolWheelBorderOuter]: `${UNITS.lineWidth}px ${
            UNITS.lineWidth > 0.5 ? fg05 : fg10
          } solid`,
          [VAR_ToolWheelSvgCircleStrokeColor]: `${
            UNITS.lineWidth > 0.5 ? fg15 : fg10
          }`,
          [VAR_ToolWheelBackgroundFill]: isDark ? "transparent" : s.bg,

          [VAR_OctaveGridHalfStepHintColor]: fg05,

          [VAR_ShadowMedium]: [
            0,
            isDark ? "1em" : ".5em",
            isDark ? "2em" : "1em",
            withOpacity(
              THEME.blackPoint,
              isDark ? (s.enableBlur ? 0.3 : 0.05) : 0.05
            ),
          ].join(" "),

          [VAR_DarkerBackgroundWhenDark]: isDark ? shade(0.2, s.bg) : s.bg,

          [VAR_BorderForeground10]: border(UNITS.lineWidth, fg20),

          [VAR_ForegroundDarken]: THEME.blendWithBackgroundColor(
            saturate(0.2, s.fg),
            0.3
          ),

          [VAR_LineWidth]: `${UNITS.lineWidth}px`,
          [VAR_InputBackground]: isDark ? fg02 : "transparent",
          [VAR_InputBackgroundHover]: isDark ? fg05 : "transparent",
          [VAR_InputForeground]: s.fg,
          [VAR_InputBorder]: border(
            UNITS.lineWidth,
            withOpacity(
              THEME.fg,
              (isDark ? 0.2 : 0.3) *
                (SETTINGS.computedValues.highContrastBorders ? 1.5 : 1) *
                (UNITS.lineWidth > 0.5 ? 0.7 : 1)
            )
          ),
          [VAR_InputHighlight]: s.primaryColor,
          [VAR_InputFocusOutline]: border(UNITS.lineWidth, s.primaryColor),
        },
      };
    },
    get measurements(): CSSPartial {
      return {
        ":root": {
          [VAR_PanelDockBottomHeight]: `${
            UI.componentRegistry.instrument.query?.height ?? 0
          }px`,
          [VAR_TitleBarHeight]: `${
            UI.componentRegistry.titleBar.query?.height ?? 0
          }px`,
        },
      };
    },
  }));

  useOnMount(() => {
    const d = makeDisposerController();
    d.add(
      reaction(
        () => s.colorDefinitions,
        () =>
          (window.CSS_COLOR_DEFINITIONS = toJS(s.colorDefinitions) as Record<
            string,
            string
          >)
      )
    );
    d.add(
      reaction(
        () => s.customProperties,
        () =>
          (window.CSS_CUSTOM_PROPERTIES = toJS(s.customProperties) as Record<
            string,
            string
          >)
      )
    );
    document.documentElement.style.setProperty(
      "transition",
      "background-color .5s"
    );
    return d.dispose;
  });

  return (
    <Observer
      children={() => (
        <>
          <Global styles={s.colorDefinitions} />
          <Global styles={s.customProperties} />
          <Global styles={s.measurements} />
        </>
      )}
    />
  );
};
