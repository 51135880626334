/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, Note } from "../../@types";
import Spacing from "../../base/components/Spacing";
import PillTag from "../../base/components/PillTag";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import AtomBarRangeDisplay from "../composer/AtomBarRangeDisplay";
import AtomRulesetEditor from "../composer/AtomRulesetEditor";
import AtomVoiceSelector from "../composer/AtomVoiceSelector";
import { useMixedValueFormState } from "../../base/utils/form.utils";
import {
  autoPluralize,
  autoPluralizeWithNumber,
} from "../../base/utils/string.utils";
import { first } from "../../base/utils/ramdaEquivalents.utils";
import NotePropertyFormRelativeToPattern from "./NotePropertyFormRelativeToPattern";
import DraggableNumberInput from "../../base/components/DraggableNumberInput";
import ReferrerCounter from "../composer/ReferrerCounter";
import { useComposer } from "../composer/ComposerApp.context";
import OrnamentEditor from "../composer/OrnamentEditor";
import Button from "../../base/components/Button";
import { ResetIcon } from "../../base/components/icons/Reset.icon";
import styled from "@emotion/styled";
import { action } from "mobx";

interface NotePropertyPanelProps {
  atom?: Atom;
  atoms?: Atom[];
}

const Grid = styled.div`
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 1.2rem;
  justify-content: stretch;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

const HeaderInner = styled.div`
  padding-right: 1em;
`;

const HeaderEnd = styled.div`
  text-align: right;
`;

const Title = styled.h3`
  font-size: 1.6rem;
`;

const NotePropertyPanel: React.FC<NotePropertyPanelProps> = props => {
  const { ENSEMBLE, SETTINGS, KEYBOARD } = useControllers();
  const I = useComposer();
  const p = useProps(props);
  const _ = useStore(() => ({
    get notes(): Note[] {
      return [p.atom, ...(p.atoms ?? [])].filter(i => i) as Note[];
    },
  }));

  const form = useMixedValueFormState(
    () => _.notes,
    [
      "startX",
      "endX",
      "midiNumber",
      "width",
      "octave",
      "velocity",
      "frequency",
      "_isHidden",
      "z",
    ]
  );

  const s = useStore(() => ({
    get ids() {
      return _.notes.map(n => n._id).join(",");
    },
    get firstNote() {
      return first(_.notes) as Note;
    },
    get hasExactlyOneNote() {
      return _.notes.length === 1;
    },
    get allNotesAreTheSamePitch() {
      return _.notes.every(n => n.keyName === s.firstNote.keyName);
    },
    get title(): string {
      return _.notes.length > 1
        ? `${autoPluralizeWithNumber(_.notes, "note")}${
            s.allNotesAreTheSamePitch ? `, ${s.firstNote.displayKeyName}` : ""
          }`
        : s.firstNote.displayName;
    },
    playNoteOnChange: () => {
      ENSEMBLE.releaseAll();
      ENSEMBLE.playNote(s.firstNote, {
        overrides: {
          durationInSeconds: 0.5,
        },
      });
    },
    get isInReplica() {
      return _.notes.every(note => !!note.refAtom);
    },
    get canReset() {
      return (
        s.canResetX ||
        s.canResetY ||
        s.canResetWidth ||
        s.canResetVelocity ||
        s.canResetVoice
      );
    },
    get canResetX() {
      return s.isInReplica && _.notes.some(n => n.$.x !== null);
    },
    get canResetY() {
      return s.isInReplica && _.notes.some(n => n.$.y !== null);
    },
    get canResetWidth() {
      return s.isInReplica && _.notes.some(n => n.$.width !== null);
    },
    get canResetVelocity() {
      return s.isInReplica && _.notes.some(n => n.$.velocity !== null);
    },
    get canResetVoice() {
      return s.isInReplica && _.notes.some(n => n.$.voiceId !== null);
    },
    get canResetOctave() {
      return s.canResetY && _.notes.some(n => n.y !== n.refAtom?.octave);
    },
    get snapX() {
      return SETTINGS.composer.snapUnitXEnabled ? I.units.snapX : 0.001;
    },
    resetAll: () => {
      I.runInHistory(
        `Reset override values`,
        action(() => {
          _.notes.forEach(n => {
            n.$.x = n.$.y = n.$.width = n.$.velocity = n.$.voiceId = null;
          });
        })
      );
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="NotePropertyPanel">
          <Header>
            <HeaderInner>
              <Title>{s.title}</Title>
              {s.hasExactlyOneNote && (
                <p>
                  <strong>
                    <AtomBarRangeDisplay atom={s.firstNote} />
                    {s.firstNote.referrers.length > 0 && (
                      <>
                        , <ReferrerCounter atom={s.firstNote} />
                      </>
                    )}
                  </strong>
                </p>
              )}
            </HeaderInner>
            {s.hasExactlyOneNote && (
              <HeaderEnd>
                <div>
                  <PillTag color={s.firstNote.appearance?.colorInContext}>
                    #{s.firstNote._id}
                  </PillTag>
                </div>
                {s.canReset && (
                  <div>
                    <Button appearance="text" onClick={s.resetAll}>
                      <ResetIcon /> Reset all
                    </Button>
                  </div>
                )}
              </HeaderEnd>
            )}
          </Header>
          <Spacing />
          <Grid>
            <DraggableNumberInput
              form={form}
              field="startX"
              Label="X"
              resettable={s.canResetX}
              step={s.snapX}
              minDeltaThreshold={5}
              deltaScalar={deltaX => Math.round(deltaX / 5) * s.snapX}
              precision={4}
              taskName={`Adjust ${autoPluralize(
                _.notes,
                "note X",
                undefined,
                "X value of notes"
              )}`}
              mergeableId={`adjust-note-x-${s.ids}`}
              fullWidth
              disabled={I.editDisabled}
            />
            <DraggableNumberInput
              form={form}
              field="endX"
              Label="End X"
              step={s.snapX}
              minDeltaThreshold={5}
              deltaScalar={deltaX => Math.round(deltaX / 5) * s.snapX}
              precision={4}
              min={form.startX as number}
              taskName={`Adjust ${autoPluralize(
                _.notes,
                "note end X",
                undefined,
                "end X value of notes"
              )}`}
              mergeableId={`adjust-note-end-x-${s.ids}`}
              fullWidth
              disabled={I.editDisabled}
            />
            <DraggableNumberInput
              form={form}
              field="width"
              Label="Width"
              resettable={s.canResetWidth}
              step={s.snapX}
              minDeltaThreshold={5}
              deltaScalar={deltaX => Math.round(deltaX / 5) * s.snapX}
              precision={4}
              taskName={`Adjust ${autoPluralize(
                _.notes,
                "note width",
                undefined,
                "width of notes"
              )}`}
              mergeableId={`adjust-note-width-${s.ids}`}
              fullWidth
              disabled={I.editDisabled}
            />
            <DraggableNumberInput
              form={form}
              field="z"
              Label="Z"
              minDeltaThreshold={5}
              deltaScalar={deltaX => Math.round(deltaX / 5)}
              taskName={`Adjust ${autoPluralize(
                _.notes,
                "note Z",
                undefined,
                "Z value of notes"
              )}`}
              mergeableId={`adjust-note-z-${s.ids}`}
              fullWidth
              disabled={I.editDisabled}
            />
            <DraggableNumberInput
              form={form}
              field="midiNumber"
              Label="Midi No."
              resettable={s.canResetY}
              taskName={`Adjust ${autoPluralize(
                _.notes,
                "note midi number",
                undefined,
                "midi number of notes"
              )}`}
              mergeableId={`adjust-note-midi-number-${s.ids}`}
              onChange={s.playNoteOnChange}
              minDeltaThreshold={KEYBOARD.pressed.alt ? 0 : 5}
              deltaScalar={deltaX =>
                KEYBOARD.pressed.alt ? deltaX / 5 : Math.round(deltaX / 5)
              }
              fullWidth
              primaryDirection="y"
              disabled={I.editDisabled}
            />
            <DraggableNumberInput
              form={form}
              field="octave"
              Label="Octave"
              taskName={`Adjust ${autoPluralize(
                _.notes,
                "note octave",
                undefined,
                "octave of notes"
              )}`}
              mergeableId={`adjust-note-octave-${s.ids}`}
              onChange={s.playNoteOnChange}
              step={1}
              min={0}
              max={9}
              primaryDirection="y"
              deltaScalar={1 / 5}
              minDeltaThreshold={5}
              precision={0}
              resettable={s.canResetOctave}
              fullWidth
              disabled={I.editDisabled}
            />
            <DraggableNumberInput
              form={form}
              field="frequency"
              Label="Frequency"
              taskName={`Adjust ${autoPluralize(
                _.notes,
                "note frequency",
                undefined,
                "frequency of notes"
              )}`}
              mergeableId={`adjust-note-frequency-${s.ids}`}
              resettable={s.canResetY}
              onChange={s.playNoteOnChange}
              step={1}
              min={0}
              fullWidth
              primaryDirection="y"
              disabled={I.editDisabled}
            />
            <DraggableNumberInput
              form={form}
              field="velocity"
              Label="Velocity"
              resettable={s.canResetVelocity}
              taskName={`Adjust ${autoPluralize(
                _.notes,
                "note velocity",
                undefined,
                "velocity of notes"
              )}`}
              mergeableId={`adjust-note-velocity-${s.ids}`}
              onChange={s.playNoteOnChange}
              step={0.01}
              deltaScalar={0.01}
              min={0}
              max={1}
              fullWidth
              disabled={
                I.editDisabled ||
                _.notes.every(n => !n.interpreted.supportsVelocity)
              }
            />
          </Grid>
          {/* <Checkbox form={form} field="_isHidden" Label="Hidden" /> */}
          <Spacing />
          {((s.firstNote.context && s.firstNote.context.voices.length > 1) ||
            _.notes.some(n => !n.voice)) && (
            <>
              <AtomVoiceSelector
                atoms={_.notes}
                fullWidth
                disabled={I.editDisabled}
              />
              <Spacing />
            </>
          )}
          {s.hasExactlyOneNote && (
            <OrnamentEditor note={s.firstNote} disabled={I.editDisabled} />
          )}
          {s.hasExactlyOneNote && (
            <>
              <Spacing />
              {s.firstNote.patternParents.map(pattern => (
                <NotePropertyFormRelativeToPattern
                  key={pattern._id}
                  note={s.firstNote}
                  pattern={pattern}
                  disabled={I.editDisabled}
                />
              ))}
            </>
          )}
          <Spacing />
          <AtomRulesetEditor atoms={_.notes} />
          {/* {isLocalhost && (
            <>
              <Spacing />
              <h3>First note debug</h3>
              <p>
                Is in current scale:{" "}
                {s.firstNote.isInCurrentMusicScale ? "true" : "false"}
              </p>
            </>
          )} */}
        </div>
      )}
    />
  );
};

export default NotePropertyPanel;
