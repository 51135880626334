import { action, reaction } from "mobx";

export const resolveAfter = (msMin?: number, msMax?: number) => {
  const duration =
    msMin && msMax ? Math.random() * (msMax - msMin) + msMin : msMin;
  return new Promise(resolve => setTimeout(resolve, duration));
};
export default resolveAfter;

export const runAfter = async (fn: Function, wait?: number) => {
  let alive = true;
  await resolveAfter(wait);
  if (alive) fn();
  return () => {
    alive = false;
  };
};

export const runEvery = (
  fn: Function,
  interval: number,
  options?: {
    fireImmediately?: boolean;
    until?: () => boolean;
    while?: () => boolean;
  }
) => {
  const ref = setInterval(() => {
    if (options?.while && !options.while()) {
      cleanup();
      return;
    }
    fn();
  }, interval);
  if (options?.fireImmediately) fn();
  let disposer: AnyFunction;
  if (options?.until) {
    disposer = reaction(options.until, value => value && cleanup());
  }
  const cleanup = () => {
    clearInterval(ref);
    disposer?.();
  };
  return cleanup;
};

export const runOnEachTick = async (...actions: Function[]) => {
  for (const a of actions) {
    await resolveAfter();
    action(a)();
  }
};

export const runUntilTruthy = (
  fn: () => unknown | Promise<unknown>,
  options?: { interval?: number; timeout?: number }
) =>
  new Promise(async (resolve, reject) => {
    const { timeout = 10000, interval = 100 } = options || {};
    if (timeout) {
      runAfter(() => {
        reject(
          `runUntilTruthy has not resulted in a truthy resolve after ${
            timeout / 1000
          } seconds. Please increase the timeout if this is expected.`
        );
      }, timeout);
    }
    let result = null;
    do {
      await resolveAfter(interval);
      result = await fn();
    } while (!result);
    resolve(result);
  });
