import { isNumberLike } from "../base/utils/typeChecks.utils";
import { Accidental } from "../constants/accidentals.constants";
import { MusicKey } from "../constants/musicKeys.constants";
import { parseAccidentalsFromShorthand } from "./accidentals.utils";
import { parseBeatNumberFromShorthand } from "./beats.utils";
import {
  getIndexInOctaveFromCFromPitchClass,
  getMusicKeyIndex,
  getPitchClassFromBaseKeyAndAccidentals,
  getPitchClassFromIndexInOctaveFromC,
  getYFromMidiNumber,
  parseBaseMusicKeyFromShorthand,
} from "./musicKey.utils";
import { parseOctaveNumberFromShorthand } from "./octaves.utils";

export const shorthandIsIncrement = (shorthand: string) => {
  const startsWithIntegerOrPointFive = Boolean(
    /^[+\-\d]|(\.5)/.exec(shorthand)
  );
  const firstSnippetIsANumber = isNumberLike(shorthand.split("'")[0]);
  return startsWithIntegerOrPointFive && firstSnippetIsANumber;
};

export const parseIncrementFromShorthand = (shorthand: string) => {
  const firstSnippet = shorthand.split("'")[0];
  if (!firstSnippet) return;
  const match = /^(?:\+|-)?\d+|\.5/.exec(firstSnippet);
  return match ? Number(match[0]) : undefined;
};

export const parseShorthand = (
  shorthand: string | null
): AuthoredNoteProperties => {
  if (!shorthand) return {};
  const baseKey = shorthand
    ? parseBaseMusicKeyFromShorthand(shorthand)
    : undefined;
  const accidentals = shorthand
    ? parseAccidentalsFromShorthand(shorthand)
    : undefined;
  return {
    ...(shorthandIsIncrement(shorthand) && {
      increment: parseIncrementFromShorthand(shorthand),
    }),
    baseKey,
    accidentals,
    key: getPitchClassFromBaseKeyAndAccidentals(baseKey, accidentals),
    octave: parseOctaveNumberFromShorthand(shorthand),
    beatCount: parseBeatNumberFromShorthand(shorthand),
    x: 0,
  };
};

export const flattenAuthoredNoteProperties = (
  authored: AuthoredNoteProperties
) => {
  const result = {
    x: 0 as number | null,
    y: 0 as number | null,
    width: 0 as number,
    midiNumber: 0 as number,
  };
  const indexInOctave = authored.key ? getMusicKeyIndex(authored.key) : null;
  const octave = authored.octave || 4;
  const pitchClass = indexInOctave
    ? getPitchClassFromIndexInOctaveFromC(indexInOctave)
    : getPitchClassFromBaseKeyAndAccidentals(
        authored.baseKey,
        authored.accidentals
      );
  const midiNumber =
    (octave + 1) * 12 +
    (getIndexInOctaveFromCFromPitchClass(pitchClass) ?? indexInOctave ?? 0);
  result.midiNumber = midiNumber;
  result.y = getYFromMidiNumber(midiNumber);
  result.x = authored.x ?? null;
  result.width = parseFloat(`${authored.beatCount ?? 1}`);
  return result;
};

export type AuthoredNoteProperties = {
  increment?: number | null;
  baseKey?: MusicKey | null;
  accidentals?: Accidental[];
  key?: MusicKey | null;
  octave?: number | null;
  beatCount?: string | number | null;
  x?: number | null;
};
