import { makeIconDef } from "./makeIconDef";

export const KeyframeIconDef = {
  keyframe: makeIconDef("keyframe", {
    regular: () => (
      <path
        d="M4.51488 11.7106L11.8861 5.97751C12.2472 5.69665 12.7528 5.69665 13.1139 5.97751L20.4851 11.7106C20.9999 12.111 20.9999 12.889 20.4851 13.2894L13.1139 19.0225C12.7528 19.3034 12.2472 19.3034 11.8861 19.0225L4.51488 13.2894C4.00014 12.889 4.00013 12.111 4.51488 11.7106Z"
        stroke="currentColor"
      />
    ),
  }),
};
