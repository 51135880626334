import { makeIconDef } from "./makeIconDef";

export const MoreIconDef = {
  more: makeIconDef("more", {
    regular: () => (
      <>
        <circle cx="5.5" cy="12.5" r="1.5" fill="currentColor" />
        <circle cx="12.5" cy="12.5" r="1.5" fill="currentColor" />
        <circle cx="19.5" cy="12.5" r="1.5" fill="currentColor" />
      </>
    ),
  }),
};
