/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { FormControlProps } from "../../base/@types";
import Dropdown from "../../base/components/Dropdown";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { MusicKey } from "../../constants/musicKeys.constants";
import { getMusicKeyDisplayName } from "../../utils/musicKey.utils";

export type MusicKeySelectorProps<T extends UnknownObject = UnknownObject> =
  FormControlProps<T> & {
    Label?: ReactNode;
  };

const MusicKeySelector = <T extends UnknownObject = UnknownObject>(
  props: React.PropsWithChildren<MusicKeySelectorProps<T>>
) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get options() {
      return Object.entries(MusicKey).map(k => ({
        label: getMusicKeyDisplayName(k[1]),
        value: k[1],
      }));
    },
  }));
  return (
    <Observer
      children={() => (
        <div className={cx("MusicKeySelector", p.className)}>
          <Dropdown
            form={p.form}
            Label={p.Label}
            field={p.field}
            options={s.options}
            taskName={p.taskName}
            mergeableId={p.mergeableId}
            mergeableTaskName={p.mergeableTaskName}
            disabled={p.disabled}
          />
        </div>
      )}
    />
  );
};

export default MusicKeySelector;
