import memoizee from "memoizee";
import { toJS } from "mobx";
import { Atom, Note } from "../@types";
import { last } from "../base/utils/ramdaEquivalents.utils";
import {
  MusicKey,
  MusicKeyEquivalenceMap,
} from "../constants/musicKeys.constants";
import {
  MusicScaleDef,
  MusicScaleDefMap,
  MusicScaleName,
} from "../constants/musicScales.constants";
import {
  getIndexInOctaveFromCFromMidiNumber,
  getMidiNumberFromY,
  getMusicKeyNumber,
} from "./musicKey.utils";

export const isKnownMusicScaleName = (
  scaleName: string
): scaleName is MusicScaleName => scaleName in MusicScaleDefMap;

export const getMusicScaleDef = (scale: MusicScaleName) =>
  MusicScaleDefMap[scale] ?? MusicScaleDefMap[MusicScaleName.Ionian];

export const getMusicScaleDisplayName = (scale: MusicScaleName) =>
  getMusicScaleDef(scale).displayName;

export const getMusicScaleMap = (scale: MusicScaleName, mode?: number) => {
  const mapAtFirstMode = getMusicScaleDef(scale).map;
  if (!mode || mode === 1) return mapAtFirstMode;
  return [...mapAtFirstMode.slice(mode), ...mapAtFirstMode.slice(0, mode)];
};

export const getMusicScalePitchMap = memoizee(
  (scale: MusicScaleName, key: MusicKey, mode?: number) => {
    const map = getMusicScaleMap(scale, mode);
    const root = getMusicKeyNumber(key);
    return map.map(i => {
      let index = root + i;
      if (index >= 12) index -= 12;
      if (index < 0) index += 12;
      return MusicKeyEquivalenceMap[
        index as keyof typeof MusicKeyEquivalenceMap
      ][0];
    });
  }
);

export const getIntervalOfStepRelativeToCurrentStepInScale = (
  currentStep: number,
  stepDelta: number,
  scaleDef: MusicScaleDef
) => {
  let i = currentStep - stepDelta;
  if (i <= 0) i += scaleDef.intervals.length;
  if (i > 0) i -= scaleDef.intervals.length;
  return scaleDef.intervals[i];
};

export const getIndexInMusicScale = (
  y: number,
  key: MusicKey,
  scale: MusicScaleName
) => {
  const musicScaleDef = MusicScaleDefMap[scale];
  const indexInOctaveFromRoot = getIndexInOctaveFromRoot(y, key);
  let stepCeil = musicScaleDef.map.find(step => indexInOctaveFromRoot <= step);
  const stepCeilIndex =
    stepCeil !== undefined
      ? musicScaleDef.map.indexOf(stepCeil)
      : musicScaleDef.map.length;
  stepCeil = stepCeil !== undefined ? stepCeil : last(musicScaleDef.map)! + 1;
  if (stepCeil === indexInOctaveFromRoot) return stepCeilIndex;
  const stepFloorIndex = stepCeilIndex - 1;
  const stepFloor = musicScaleDef.map[stepFloorIndex];
  if (stepFloor === indexInOctaveFromRoot) return stepFloorIndex;
  const stepSize = stepCeil - stepFloor;
  const diffFromFloor = indexInOctaveFromRoot - stepFloor;
  return diffFromFloor / stepSize + stepFloorIndex;
};

export const getAtomIndexInMusicScale = (atom: Atom) => {
  if (atom.indexInOctaveFromRoot === null || atom.y === null) return null;
  return getIndexInMusicScale(atom.y, atom.musicKey, atom.musicScaleName);
};

export const getIndexInOctaveFromRoot = (y: number, musicKey: MusicKey) => {
  const indexInOctaveFromC = getIndexInOctaveFromCFromMidiNumber(
    getMidiNumberFromY(y)
  );
  let v = indexInOctaveFromC - getMusicKeyNumber(musicKey);
  if (v >= 12) v -= 12;
  if (v < 0) v += 12;
  return v;
};

export const getScaleStepDiffFromYDiff = (
  yDiff: number,
  startingIndex: number,
  scale: MusicScaleName
) => {
  if (yDiff === 0) return 0;
  let yDiffInv = yDiff * -1; // y's direction is in reverse of scales. The higher the note, the smaller the Y
  const intervalMap = MusicScaleDefMap[scale].intervals;
  let stepDiff = 0;
  // start from the given index.
  if (yDiffInv >= 0) {
    // console.info("going up the scale by", yDiff);
    // If it's not an integer, process the partial step first
    let i = Math.ceil(startingIndex); // 2
    const fullStepOffset = i - startingIndex; // 0.5
    let offsetStepIndex = i - 1;
    if (offsetStepIndex < 0) offsetStepIndex += intervalMap.length;
    let interval = intervalMap[offsetStepIndex];
    stepDiff += fullStepOffset;
    yDiffInv -= fullStepOffset * interval;
    interval = intervalMap[i];
    while (yDiffInv >= interval) {
      yDiffInv -= interval;
      stepDiff += 1;
      i += 1;
      if (i >= intervalMap.length) i -= intervalMap.length;
      interval = intervalMap[i];
    }
    stepDiff += yDiffInv / interval;
  } else {
    // console.info("going down the scale by", yDiff);
    // If it's not an integer, process the partial step first
    let i = Math.floor(startingIndex);
    const fullStepOffset = i - startingIndex;
    i = i - 1;
    if (i < 0) i += intervalMap.length;
    const offsetStepIndex = i;
    let interval = intervalMap[offsetStepIndex] * -1;
    stepDiff -= fullStepOffset;
    yDiffInv -= fullStepOffset * interval;
    interval = intervalMap[i] * -1;
    // console.log({
    //   i,
    //   startingIndex,
    //   offsetStepIndex,
    //   stepDiff,
    //   yDiff,
    //   interval,
    // });
    while (yDiffInv <= interval) {
      yDiffInv -= interval;
      stepDiff -= 1;
      i -= 1;
      if (i < 0) i += intervalMap.length;
      interval = intervalMap[i] * -1;
    }
    stepDiff -= yDiffInv / interval;
  }
  return stepDiff;
};

export const getYDiffFromScaleStepDiff = (
  stepDiff: number,
  startingStepIndex: number,
  scale: MusicScaleName
) => {
  let stepsToProcess = Math.abs(stepDiff);

  const transposeDirectionInScale = Math.sign(stepDiff);
  const ySign = transposeDirectionInScale * -1;

  const targetScaleDef = getMusicScaleDef(scale);
  const targetIntervalMap = targetScaleDef.intervals;

  let yDiff = 0;

  let intervalIndex = Math.floor(startingStepIndex) - (ySign < 0 ? 1 : 0);
  if (intervalIndex >= targetIntervalMap.length)
    intervalIndex -= targetIntervalMap.length;
  if (intervalIndex < 0) intervalIndex += targetIntervalMap.length;
  let interval = targetIntervalMap[intervalIndex];

  const applyStep = (step: number) => {
    yDiff += ySign * step * interval;
    intervalIndex += 1 * transposeDirectionInScale;
    if (intervalIndex >= targetIntervalMap.length)
      intervalIndex -= targetIntervalMap.length;
    if (intervalIndex < 0) intervalIndex += targetIntervalMap.length;
    interval = targetIntervalMap[intervalIndex];
    stepsToProcess -= step;
  };

  // deal with any sub-integer values first to get to the first full interval
  const firstStep = Math.abs(Math.round(startingStepIndex) - startingStepIndex);
  applyStep(firstStep);

  // iterate through the integer steps until we are left with a remainder
  while (stepsToProcess > 0) {
    const step = stepsToProcess >= 1 ? 1 : stepsToProcess;
    applyStep(step);
  }

  // console.table({
  //   stepDiff,
  //   startingStepIndex,
  //   scale,
  //   transposeDirectionInScale: ySign < 0 ? "up" : "down",
  //   firstStep,
  //   yDiff,
  // });

  return yDiff;
};

export const getNoteSnapshotAfterAddingOrSubtractingStepsInScale = (
  note: Note,
  steps: number
) => {
  const $ = toJS(note.$);
  if (note.y === null || steps === 0 || note.indexInMusicScale === null)
    return $;
  $.y = note.y;
  const intervalMap = (
    MusicScaleDefMap[note.musicScaleName] ??
    MusicScaleDefMap[MusicScaleName.Ionian]
  ).intervals;
  const startingFrom = note.indexInMusicScale;
  const dir = Math.sign(steps);
  const absSteps = Math.abs(steps);
  for (let i = 0; i < absSteps; i++) {
    // if ascending, use interval at this index. otherwise use the previous interval to do subtraction.
    const index = startingFrom + i + (dir < 0 ? -1 : 0);
    const diffIndexFloat =
      (index >= 0 ? index : index + intervalMap.length) % intervalMap.length;
    const diff = intervalMap[Math.floor(diffIndexFloat)] * dir;
    // the higher the note, the smaller the y
    $.y -= diff;
  }
  return $;
};
