/** @jsxImportSource @emotion/react */
import { Global } from "@emotion/react";
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren, ReactNode } from "react";
import { CSSPartial } from "../../../base/@types/css.types";
import {
  fg,
  fg00,
  fg05,
  fg30,
  fg70,
  varFontSerif,
  varPrimary,
  varPrimary50,
} from "../../../constants/cssCustomProperties.constants";
import LineSeparator from "../../../base/components/LineSeparator";
import {
  mediaFromDesktopLg,
  mediaFromTablet,
} from "../../../base/styles/helpers/mediaQueries.styling";
import cx from "../../../base/utils/className.utils";
import { useProps } from "../../../base/utils/mobx.utils";
import Title from "../../../components/app/Title";
import { APP_DISPLAY_NAME } from "../../../constants/app.constants";
import { Logo20 } from "../../../base/components/Logo";
import { paths } from "../../../base/constants/paths.constants";
import { externalLinkAttrs } from "../../../base/utils/links.utils";

type AuthPageProps = {
  className?: string;
  title: string;
  displayTitle?: string;
  HeaderContent?: ReactNode;
};

const style = {
  page: {
    display: "grid",
    gridTemplateRows: "auto minmax(auto, 1fr) auto",
    gridTemplateAreas: "'header' 'content' 'footer'",
    maxWidth: 375,
    minHeight: "100vh",
    margin: "0 auto",
    padding: "4em 1em",
    fontSize: "1.4rem",
    boxSizing: "border-box",
    ...mediaFromTablet({
      padding: "6em 2em",
    }),
    ...mediaFromDesktopLg({
      padding: "8em 2em",
    }),
    p: {
      lineHeight: 1.5,
      color: fg70,
      a: {
        color: fg,
        fontWeight: 700,
        textDecorationColor: fg30,
        "&:hover": {
          filter: "brightness(1.1)",
          color: varPrimary,
          textDecorationColor: varPrimary50,
        },
      },
    },
  } as CSSPartial,
  header: {
    gridArea: "header",
  } as CSSPartial,
  logo: {
    display: "inline-block",
    fontFamily: varFontSerif,
    fontWeight: 500,
    fontSize: "2rem",
    marginTop: "1em",
    marginBottom: "1em",
    opacity: 0.8,
    textDecoration: "none",
    "&:hover": {
      opacity: 1,
    },
  } as CSSPartial,
  title: {
    fontSize: "4.2rem",
    fontFamily: varFontSerif,
    fontWeight: 200,
    lineHeight: 1,
  } as CSSPartial,
  content: {
    gridArea: "content",
  } as CSSPartial,
  footer: {
    gridArea: "footer",
    textAlign: "center",
    padding: "3em 0",
    a: {
      "&:hover": {
        color: varPrimary,
      },
    },
  } as CSSPartial,
};

const AuthPage: React.FC<PropsWithChildren<AuthPageProps>> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <div className={cx("AuthPage", p.className)} css={style.page}>
          <Global
            styles={{
              backgroundImage: `radial-gradient(100% 55% at 70% 100%, ${fg05} 0%, ${fg00} 100%);`,
            }}
          />
          <Title>{p.title}</Title>
          <header css={style.header}>
            <a href={paths.website} css={style.logo} {...externalLinkAttrs}>
              {APP_DISPLAY_NAME}
            </a>
            <h1 css={style.title}>{p.displayTitle ?? p.title}</h1>
            {p.HeaderContent}
            <LineSeparator margin="2em 0" />
          </header>
          <div css={style.content}>{p.children}</div>
          <footer css={style.footer}>
            <a href={paths.website} {...externalLinkAttrs}>
              <Logo20 />
            </a>
          </footer>
        </div>
      )}
    />
  );
};

export default AuthPage;
