import { action } from "mobx";
import { useContext } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { KeyboardShortcutName } from "../../constants/keyboardShortcuts.constants";
import { RootContext } from "../../RootContext";

export const useRootController = action(() => useContext(RootContext));
export const useControllers = action(() => useRootController());
export const useUIController = action(() => useRootController().UI);
export const useDialogController = action(() => useRootController().DIALOGS);
export const usePortalController = action(() => useRootController().PORTALS);
export const useAppConfig = action(
  () => useRootController().SETTINGS.application
);
export const useShortcut = action(
  (
    name: KeyboardShortcutName,
    a: Parameters<typeof useHotkeys>[1],
    b?: Parameters<typeof useHotkeys>[2]
  ) => {
    const keys = useRootController().KEYBOARD.shortcuts[name];
    useHotkeys(keys, a, b);
  }
);

export type AppConfig = ReturnType<typeof useAppConfig>;
