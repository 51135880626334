export const T = () => true;
export const F = () => false;

export const always =
  <T>(a: T) =>
  () =>
    a;

export const cond =
  <T>(
    items: [(...args: unknown[]) => unknown, (...args: unknown[]) => unknown][]
  ) =>
  (...args: unknown[]) => {
    for (const i of items) {
      if (i[0](...args)) return i[1](...args) as T;
    }
  };

export const and = (a: unknown, b: unknown) => () => Boolean(a && b);
export const or = (a: unknown, b: unknown) => () => Boolean(a || b);

export const eq = (a: unknown, b: unknown) => () => a === b;

export const eqBy = <A, B>(
  fn: (v: A | B) => unknown = (v: A | B) => v,
  a: A,
  b: B
) => fn(a) === fn(b);

export const eqByFn =
  (fn: (v: unknown) => unknown) => (a: unknown, b: unknown) =>
    eqBy(fn, a, b);

export const first = <T>(arr?: T[]) =>
  arr && arr.length > 0 ? arr[0] : undefined;
export const second = <T>(arr?: T[]) =>
  arr && arr.length > 1 ? arr[1] : undefined;
export const last = <T>(arr?: T[]) =>
  arr && arr.length > 0 ? arr[arr.length - 1] : undefined;
export const lastInString = (arr?: string) =>
  arr ? arr[arr.length - 1] : undefined;

export const take = <T>(num: number, arr: T[]) => arr.slice(0, num);

export const sumOfArray = (numbers: (number | null)[]) =>
  numbers.reduce((a, b) => (a ?? 0) + (b ?? 0), 0) as number;
export const sumOfElements = (...numbers: (number | null)[]) =>
  sumOfArray(numbers);
export const meanOfArray = (numbers: (number | null)[]) =>
  sumOfArray(numbers) / numbers.length;
export const meanOfElements = (...numbers: (number | null)[]) =>
  sumOfArray(numbers) / numbers.length;

export const medianOfArray = (arr: number[]) => {
  const mid = Math.floor(arr.length / 2),
    numbers = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0
    ? numbers[mid]
    : (numbers[mid - 1] + numbers[mid]) / 2;
};

export const isNil = (v: unknown): v is null | undefined =>
  v === null || v === undefined;
export const isNotNil = <T>(v: T): v is NonNullable<T> =>
  v !== null && v !== undefined;

export const both = (a: () => unknown, b: () => unknown) => a() && b();

export const prop = <T extends UnknownObject>(key: keyof T, object: T) =>
  object[key];
export const propFn =
  <T extends UnknownObject>(key: keyof T) =>
  (object: T) =>
    prop(key, object);

export const groupBy = <T extends UnknownObject>(
  fn: (obj: T) => unknown,
  array: T[]
) => {
  const result = {} as Record<string, T[]>;
  array.forEach(o => {
    const key = `${fn(o)}`;
    if (!result[key]) result[key] = [];
    result[key].push(o);
  });
  return result;
};

export const allPass = (...fns: Function[]) => fns.every(f => f());

export const uniq = <T>(array: T[]) => Array.from(new Set(array));

export const findLast = <T>(
  fn: (x: T, i: number, a: T[]) => unknown,
  array: T[]
) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (fn(array[i], i, array)) return array[i];
  }
  return undefined;
};
export const findLastIndex = <T>(
  fn: (x: T, i: number, a: T[]) => unknown,
  array: T[]
) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (fn(array[i], i, array)) return i;
  }
  return -1;
};

export const pick = <T extends UnknownObject>(keys: (keyof T)[], object: T) => {
  const result: UnknownObject = {};
  const keysConst = [...keys] as const;
  keysConst.forEach(k => (result[k as string] = object[k]));
  return result as Pick<T, (typeof keysConst)[number]>;
};

export const merge = <A extends UnknownObject, B extends UnknownObject>(
  a: A,
  b: B
) => {
  return { ...a, ...b };
};
export const mergeLeft = <A extends UnknownObject, B extends UnknownObject>(
  a: A,
  b: B
) => {
  return { ...b, ...a };
};
export const mergeRight = merge;

export const mapObjectByKey = <T extends UnknownObject, K extends keyof T, R>(
  transformer: (k: K) => R,
  object: T
) => {
  const result = {} as UnknownObject;
  Object.entries(object).forEach(e => (result[e[0]] = transformer(e[0] as K)));
  return result as Record<K, R>;
};

export const mapObjectByValue = <T extends UnknownObject, K extends string, R>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformer: (a: any) => R,
  object: T
) => {
  const result = {} as UnknownObject;
  Object.entries(object).forEach(e => (result[e[0]] = transformer(e[1])));
  return result as Record<K, R>;
};

export const intersection = <A, B>(arrA: A[], arrB: B[]) =>
  arrA.filter(a => arrB.includes(a as unknown as B));
export const difference = <A, B>(arrA: A[], arrB: B[]) =>
  arrA.filter(a => !arrB.includes(a as unknown as B));
export const symmetricDifference = <A, B>(arrA: A[], arrB: B[]) => [
  ...difference(arrA, arrB),
  ...difference(arrB, arrA),
];

export const mathMod = (n: number, m: number) => {
  return ((n % m) + m) % m;
};
