/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Observer } from "mobx-react-lite";
import React from "react";
import Button, { ButtonGroup } from "../../base/components/Button";
import Checkbox from "../../base/components/Checkbox";
import LineSeparator from "../../base/components/LineSeparator";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import AppPage, {
  AppPageBody,
  AppPageHeader,
} from "../../components/layout/AppPage";
import UserEditor from "../../components/shared/UserEditor";
import UserPasswordSelfUpdater from "../../components/shared/UserPasswordSelfUpdater";

type P = {};

const formContent = css`
  max-width: 72rem;
`;

const PageAccountHome: React.FC<P> = props => {
  const { AUTH, SETTINGS } = useControllers();
  return (
    <Observer
      children={() => (
        <AppPage title="Account settings">
          <AppPageHeader
            End={
              <ButtonGroup>
                <Button onClick={AUTH.saveCurrentUser}>Save changes</Button>
              </ButtonGroup>
            }
          >
            <h1>Account settings</h1>
          </AppPageHeader>
          <LineSeparator />
          <AppPageBody>
            {AUTH.user && (
              <div css={formContent}>
                <h2>Account details</h2>
                <UserEditor user={AUTH.user} />
                <LineSeparator dashed margin="2em 0" />
                <h2>Update password</h2>
                <UserPasswordSelfUpdater />
                <LineSeparator dashed margin="2em 0" />
                <h2>Settings</h2>
                <Checkbox
                  form={SETTINGS.application}
                  field="enableExperimentalFeatures"
                >
                  Enable experimental features
                </Checkbox>
              </div>
            )}
          </AppPageBody>
        </AppPage>
      )}
    />
  );
};

export default PageAccountHome;
