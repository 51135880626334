/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, Note, Ornament } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import Spacing from "../../base/components/Spacing";
import PillTag from "../../base/components/PillTag";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import AtomBarRangeDisplay from "../composer/AtomBarRangeDisplay";
import AtomRulesetEditor from "../composer/AtomRulesetEditor";
import AtomVoiceSelector from "../composer/AtomVoiceSelector";
import { useMixedValueFormState } from "../../base/utils/form.utils";
import { autoPluralizeWithNumber } from "../../base/utils/string.utils";
import { first } from "../../base/utils/ramdaEquivalents.utils";
import { OrnamentationType } from "../../constants/ornaments.constants";
import Selector from "../../base/components/Selector/Selector";
import SelectOptionRect from "../../base/components/Selector/SelectOptionRect";
import FormLabel from "../../base/components/FormLabel";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import ReferrerCounter from "../composer/ReferrerCounter";
import OrnamentEditor from "../composer/OrnamentEditor";
import { useComposer } from "../composer/ComposerApp.context";

interface OrnamentPropertyPanelProps {
  atom?: Atom;
  atoms?: Atom[];
}

const style = {
  grid: {
    display: "grid",
    gridGap: ".5em",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    fontSize: "1.2rem",
  } as CSSPartial,
  header: {
    display: "flex",
    justifyContent: "space-between",
  } as CSSPartial,
  headerInner: {
    paddingRight: "1em",
  } as CSSPartial,
  title: {
    fontSize: "1.6rem",
  } as CSSPartial,
};

const OrnamentPropertyPanel: React.FC<OrnamentPropertyPanelProps> = props => {
  const { THEME } = useControllers();
  const p = useProps(props);
  const _ = useStore(() => ({
    get ornaments(): Ornament[] {
      return [p.atom, ...(p.atoms ?? [])].filter(i => i) as Ornament[];
    },
  }));
  const I = useComposer();
  const form = useMixedValueFormState(() => _.ornaments, ["ornamentationType"]);

  const s = useStore(() => ({
    get firstOrnament() {
      return first(_.ornaments) as Ornament;
    },
    get firstOrnamentNote(): Note | null {
      return s.firstOrnament.note;
    },
    get firstOrnamentType(): OrnamentationType {
      return s.firstOrnament.ornamentationType;
    },
    get notes() {
      return _.ornaments.map(o => o.note).filter(i => i) as Note[];
    },
    get hasExactlyOneOrnament(): boolean {
      return _.ornaments.length === 1;
    },
    get allOrnamentsAreTheSamePitch(): boolean {
      return !!(
        s.firstOrnament.note &&
        _.ornaments.every(
          o => o.note?.midiNumber === s.firstOrnament.note!.midiNumber
        )
      );
    },
    get title(): string {
      return _.ornaments.length > 1
        ? `${autoPluralizeWithNumber(_.ornaments, "ornament")}`
        : s.firstOrnament.displayName;
    },
    updateForm: {
      ornamentNoteWidth: 0.125,
      startsWith: "",
      endsWith: "",
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="OrnamentPropertyPanel">
          <header css={style.header}>
            <div css={style.headerInner}>
              <h3 css={style.title}>{s.title}</h3>
              {s.hasExactlyOneOrnament && (
                <p>
                  <strong>
                    <AtomBarRangeDisplay atom={s.firstOrnament} />
                    {s.firstOrnament.referrers.length > 0 && (
                      <>
                        , <ReferrerCounter atom={s.firstOrnament} />
                      </>
                    )}
                  </strong>
                </p>
              )}
            </div>
            {s.hasExactlyOneOrnament && (
              <div>
                <PillTag
                  color={s.firstOrnament.voice?.appearance?.colorInContext}
                >
                  #{s.firstOrnament._id}
                </PillTag>
              </div>
            )}
          </header>
          <Spacing />
          {s.hasExactlyOneOrnament && s.firstOrnamentNote && (
            <OrnamentEditor
              note={s.firstOrnamentNote}
              disabled={I.editDisabled}
            />
          )}
          {s.firstOrnamentType === "trill" && (
            <>
              <Spacing />
              <FormLabel bold>Ornament note width</FormLabel>
              <TextInput
                form={s.updateForm}
                type="number"
                field="ornamentNoteWidth"
                min={0.0625}
                step={0.0625}
                disabled={I.editDisabled}
              />
              <Spacing />
              <FormLabel bold>Starts with turn</FormLabel>
              <Selector
                form={s.updateForm}
                field="startsWith"
                color={
                  s.firstOrnamentNote?.appearance?.colorInContext ??
                  THEME.primary
                }
                options={[
                  { Label: "No", value: "" },
                  { Label: "From higher", value: "turn-from-higher" },
                  { Label: "From lower", value: "turn-from-lower" },
                ]}
                optionRenderer={SelectOptionRect}
                fullWidth
                disabled={I.editDisabled}
              />
              <Spacing />
              <FormLabel bold>Ends with turn</FormLabel>
              <Selector
                form={s.updateForm}
                field="endsWith"
                color={
                  s.firstOrnamentNote?.appearance?.colorInContext ??
                  THEME.primary
                }
                options={[
                  { Label: "No", value: "" },
                  { Label: "From higher", value: "turn-from-higher" },
                  { Label: "From lower", value: "turn-from-lower" },
                ]}
                optionRenderer={SelectOptionRect}
                fullWidth
                disabled={I.editDisabled}
              />
            </>
          )}
          {/* <div css={style.grid}>
             <TextInput
              form={form}
              type="number"
              field="startX"
              Label="X"
              step={SETTINGS.composer.snapUnitX}
            /> 
          </div> */}
          {/* {s.hasExactlyOneOrnament && (
            <>
              <Spacing />
              <GroupLikeAtomChildrenSpacingEditor atom={s.firstOrnament} />
            </>
          )} */}
          <Spacing />
          <AtomVoiceSelector
            atoms={s.notes}
            fullWidth
            disabled={I.editDisabled}
          />
          <Spacing />
          {s.hasExactlyOneOrnament && <></>}
          <Spacing />
          <AtomRulesetEditor atoms={_.ornaments} />
        </div>
      )}
    />
  );
};

export default OrnamentPropertyPanel;
