import { makeIconDef } from "./makeIconDef";

export const NoteIconDefs = {
  note8: makeIconDef("note8", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.5L12 3L12 5V6V8V13H12C11.9997 13.4807 11.9975 13.7293 11.9767 13.8107C11.8618 14.2613 11.7426 14.3576 11.2778 14.3748C11.1838 14.3783 10.7385 14.2822 9.84781 14.09C9.82004 14.084 9.79217 14.0783 9.76418 14.0729C9.51682 14.0251 9.26134 14 9 14C6.79086 14 5 15.7909 5 18C5 20.2091 6.79086 22 9 22C11.0398 22 12.723 20.4732 12.9691 18.5L12.9739 18.4593C12.9911 18.3086 13 18.1553 13 18V17V13V10C13 8.89568 13.895 8.0004 14.9993 8H15L20 8V6V5C16.5 5 14.5 4.5 12.5 2.5H12Z"
        fill="currentColor"
      />
    ),
  }),
};
