import { createContext } from "react";
import { makeRootController } from "./controllers/_root.controller";
import { API_HOST } from "./env";

export const RootControllerInstance = makeRootController({
  api: {
    baseURL: API_HOST,
  },
  storage: {
    separator: "%",
  },
});
RootControllerInstance.init();

export const RootContext = createContext(RootControllerInstance);
