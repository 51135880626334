import { Observer } from "mobx-react-lite";
import * as React from "react";
import AppContainer from "./AppContainer";
import { CSSCustomPropertiesManager } from "./base/components/CSSCustomPropertiesManager";
import CSSGlobals from "./base/components/CSSGlobals";
import ErrorBoundary from "./base/components/ErrorBoundary/ErrorBoundary";
import SplashScreen from "./base/components/SplashScreen.component";
import AppSymbols from "./base/components/Symbols/AppSymbols";
import IconSymbols from "./base/components/Symbols/IconSymbols";
import { useRootController, useShortcut } from "./base/hooks/rootContext.hooks";

const App: React.FC = p => {
  const ROOT = useRootController();
  useShortcut("toggleColorTheme", e => {
    e.preventDefault();
    ROOT.THEME.switchColorScheme();
  });
  return (
    <Observer
      children={() => (
        <div className="App">
          <CSSGlobals />
          <CSSCustomPropertiesManager />
          <AppSymbols />
          <IconSymbols />
          {ROOT.ready ? (
            <ErrorBoundary>
              <AppContainer />
            </ErrorBoundary>
          ) : (
            <SplashScreen />
          )}
        </div>
      )}
    />
  );
};

export default App;
