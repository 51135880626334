/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { Fragment, RefObject } from "react";
import { Atom } from "../../@types";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import {
  DefaultPtPerX,
  DefaultPtPerY,
} from "../../constants/composer.constants";
import {
  getAtomSetHeight,
  getAtomSetStartX,
  getAtomSetStartY,
  getAtomSetWidth,
  isGroupLikeAtom,
  isNoteAtom,
} from "../../utils/atoms.utils";
import { useAtomContext } from "./ComposerApp.context";

// remember to update the API counterpart
export const makeImageExportOptionsBag = () => ({
  useInterpretedTiming: true,
  renderNotesAs: "rect" as "rect" | "line",
  includeBackgroundColor: true,
  scale: 1,
});

export type ImageExportOptionsBag = ReturnType<
  typeof makeImageExportOptionsBag
>;

type AtomMapSvgProps = {
  target?: Atom;
  options?: ImageExportOptionsBag;
  innerRef?: RefObject<SVGSVGElement>;
};

const AtomMapSvg: React.FC<AtomMapSvgProps> = props => {
  const { THEME } = useControllers();
  const p = useProps(props);
  const ac = useAtomContext();
  const s = useStore(() => ({
    get notes() {
      if (!p.target) return ac.notes;
      if (isNoteAtom(p.target)) return [p.target];
      if (isGroupLikeAtom(p.target)) return p.target.descendantNotes;
      return [];
    },
    get padding() {
      return { top: 5, left: 0.5, right: 0.5, bottom: 5 };
    },
    get ptPerX() {
      return ac.interpretation?.options.ptPerX ?? DefaultPtPerX;
    },
    get ptPerY() {
      return ac.interpretation?.options.ptPerY ?? DefaultPtPerY;
    },
    get width() {
      return getAtomSetWidth(s.notes);
    },
    get widthPt() {
      return (s.width + s.padding.left + s.padding.right) * s.ptPerX;
    },
    get height() {
      return getAtomSetHeight(s.notes);
    },
    get heightPt() {
      return (s.height + s.padding.top + s.padding.bottom) * s.ptPerY;
    },
    get startX() {
      return getAtomSetStartX(s.notes) ?? 0;
    },
    get startY() {
      return getAtomSetStartY(s.notes) ?? 0;
    },
    get useInterpretedTiming() {
      return p.options?.useInterpretedTiming ?? true;
    },
    get includeBackgroundColorRect() {
      return p.options?.includeBackgroundColor ?? true;
    },
    get renderNotesAsLines() {
      return p.options?.renderNotesAs === "line";
    },
  }));
  return (
    <Observer
      children={() => (
        <svg
          style={{
            aspectRatio: `${s.widthPt}/${s.heightPt}`,
          }}
          width={s.widthPt}
          height={s.heightPt}
          viewBox={`0 0 ${s.widthPt} ${s.heightPt}`}
          xmlns="http://www.w3.org/2000/svg"
          ref={p.innerRef}
        >
          {s.includeBackgroundColorRect && (
            <rect
              x={0}
              y={0}
              width={s.widthPt}
              height={s.heightPt}
              fill={THEME.bg}
            />
          )}
          {s.notes.map(n => {
            const x = s.useInterpretedTiming ? n.interpreted.startX : n.startX;
            const y = n.y;
            const w = s.useInterpretedTiming ? n.interpreted.width : n.width;
            if (x === null || y === null || w === null)
              return <Fragment key={n._id} />;
            const xPt = (x - s.startX + s.padding.left) * s.ptPerX;
            const yPt = (y - s.startY + s.padding.top) * s.ptPerY;
            const wPt = w * s.ptPerX;
            const hPt = (n.appearance.noteYScalar || 1) * s.ptPerY;
            const color = n.appearance.colorInContext ?? THEME.fg;
            return s.renderNotesAsLines ? (
              <line
                key={n._id}
                x1={xPt}
                y1={yPt}
                x2={xPt + wPt}
                y2={yPt}
                stroke={color}
                strokeWidth={hPt}
              />
            ) : (
              <rect
                key={n._id}
                x={xPt}
                y={yPt - hPt / 2}
                width={wPt}
                height={hPt}
                fill={color}
                rx={n.appearance.noteRoundedness ?? 0}
              />
            );
          })}
        </svg>
      )}
    />
  );
};

export default AtomMapSvg;
