import { observable } from "mobx";
import { isMultiFingerTouchEvent } from "./touch.utils";

export const createDraggableHandler = (options: {
  onStart?: (params: { e: PointerEvent; x: number; y: number }) => void;
  onMove?: (params: {
    e: PointerEvent;
    deltaX: number;
    deltaY: number;
    totalDeltaX: number;
    totalDeltaY: number;
    hasMoved: boolean;
  }) => void;
  onEnd?: (params: {
    e: PointerEvent | null;
    x: number | null;
    y: number | null;
  }) => void;
}) => {
  const _ = observable({
    attachedToElement: null as Element | null,
    prevPointerEvent: null as PointerEvent | null,
    prevTouchEvent: null as TouchEvent | null,
    get isTouch() {
      return !!_.prevTouchEvent;
    },
    get isMultiFingerTouch() {
      return isMultiFingerTouchEvent(_.prevTouchEvent);
    },
    handlePointerDown: (e: PointerEvent | React.PointerEvent) => {
      _.totalDeltaX = 0;
      _.totalDeltaY = 0;
      _.hasMoved = false;
      _.prevPointerEvent = "nativeEvent" in e ? e.nativeEvent : e;
      options.onStart?.({
        e: _.prevPointerEvent,
        x: e.clientX,
        y: e.clientY,
      });
      window.addEventListener("pointermove", _.handlePointerMove);
      window.addEventListener("pointerup", _.handlePointerEnd, {
        capture: true,
      });
      window.addEventListener("pointercancel", _.handlePointerEnd, {
        capture: true,
      });
      window.addEventListener("blur", _.handlePointerEnd);
    },
    totalDeltaX: 0,
    totalDeltaY: 0,
    hasMoved: false,
    handlePointerMove: (e: PointerEvent) => {
      if (!_.prevPointerEvent) return;
      const deltaX = e.clientX - _.prevPointerEvent.clientX;
      const deltaY = e.clientY - _.prevPointerEvent.clientY;
      _.totalDeltaX += deltaX;
      _.totalDeltaY += deltaY;
      if (!_.hasMoved)
        _.hasMoved =
          Math.abs(_.totalDeltaX) >= 5 || Math.abs(_.totalDeltaY) >= 5;
      options.onMove?.({
        e,
        deltaX,
        deltaY,
        totalDeltaX: _.totalDeltaX,
        totalDeltaY: _.totalDeltaY,
        hasMoved: _.hasMoved,
      });
      _.prevPointerEvent = e;
    },
    handlePointerEnd: (e?: PointerEvent | UIEvent) => {
      const pointerEvent = e && "clientX" in e ? e : null;
      if (_.hasMoved) {
        e?.preventDefault();
        e?.stopImmediatePropagation();
      }
      options.onEnd?.({
        e: pointerEvent,
        x: (pointerEvent ?? _.prevPointerEvent)?.clientX ?? null,
        y: (pointerEvent ?? _.prevPointerEvent)?.clientY ?? null,
      });
      window.removeEventListener("pointermove", _.handlePointerMove);
      window.removeEventListener("pointerup", _.handlePointerEnd, {
        capture: true,
      });
      window.removeEventListener("pointercancel", _.handlePointerEnd, {
        capture: true,
      });
      window.removeEventListener("blur", _.handlePointerEnd);
      _.prevPointerEvent = null;
      _.totalDeltaX = 0;
      _.totalDeltaY = 0;
      _.hasMoved = false;
    },
    handleTouchStart: (e: TouchEvent | React.TouchEvent) => {
      _.prevTouchEvent = "nativeEvent" in e ? e.nativeEvent : e;
      window.addEventListener("touchmove", _.handleTouchMove);
      window.addEventListener("touchend", _.handleTouchEnd);
      window.addEventListener("touchcancel", _.handleTouchEnd);
      window.addEventListener("blur", _.handlePointerEnd);
    },
    handleTouchMove: (e: TouchEvent) => {
      _.prevTouchEvent = e;
    },
    handleTouchEnd: () => {
      window.removeEventListener("touchmove", _.handleTouchMove);
      window.removeEventListener("touchend", _.handleTouchEnd);
      window.removeEventListener("touchcancel", _.handleTouchEnd);
      window.removeEventListener("blur", _.handleTouchEnd);
      _.prevTouchEvent = null;
    },
    cleanUpListeners: (el?: HTMLElement | null) => {
      el?.removeEventListener("pointerdown", _.handlePointerDown);
      _.handlePointerEnd();
    },
  });
  return observable({
    get handlePointerDown() {
      return _.handlePointerDown;
    },
    /** attach "handleTouchStart" to "onTouchStart" to register touch events */
    get handleTouchStart() {
      return _.handleTouchStart;
    },
    get isTouch() {
      return _.isTouch;
    },
    get isMultiFingerTouch() {
      return _.isMultiFingerTouch;
    },
    get totalDeltaX() {
      return _.totalDeltaX;
    },
    get totalDeltaY() {
      return _.totalDeltaY;
    },
    get hasMoved() {
      return _.hasMoved;
    },
    dispose: () => {
      _.handlePointerEnd();
      _.handleTouchEnd();
    },
  });
};
