/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import { lighten } from "polished";
import * as React from "react";
import { CSSPartial } from "../../@types/css.types";
import { UNITS } from "../../constants/units.constant";
import { useControllers } from "../../hooks/rootContext.hooks";
import cx from "../../utils/className.utils";
import { withOpacity } from "../../utils/colors.utils";
import { renderRenderable } from "../../utils/components.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import SymbolIcon from "../SymbolIcon";
import { SelectorOptionRendererProps } from "./Selector";

const SelectOptionRect = <T extends UnknownObject = UnknownObject>(
  props: React.PropsWithChildren<SelectorOptionRendererProps<T>>
) => {
  const p = useProps(props);

  const { THEME } = useControllers();

  const s = useStore(() => ({
    handleClick: () => {
      p.onClick && p.onClick(p.option);
    },
    get color() {
      return p.option.color || p.color || THEME.fg;
    },
    get contrastColor(): string {
      return THEME.getContrastColor(s.color);
    },
    get style(): CSSPartial {
      return {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: p.fullWidth ? "1 0 auto" : undefined,
        padding: ".5em 1em",
        minHeight: "2.5em",
        borderRadius: 0,
        border: `${UNITS.lineWidth}px solid ${withOpacity(THEME.bg, 0.5)}`,
        backgroundColor: withOpacity(p.option.color ?? THEME.fg, 0.1),
        color: "inherit",
        cursor: "default",
        textAlign: "center",
        "&:hover": {
          filter: "brightness(1.1)",
        },
        "&.selected": {
          border: `${UNITS.lineWidth * 2}px solid ${lighten(0.1, s.color)}`,
          backgroundColor: s.color,
          color: s.contrastColor,
        },
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <span
          className={cx("SelectOptionRect", p.isSelected && "selected")}
          onClick={s.handleClick}
          css={s.style}
          data-value={p.option.value}
        >
          {p.option.icon && <SymbolIcon icon={p.option.icon} />}
          <span>{renderRenderable(p.option.Label ?? p.option.value)}</span>
        </span>
      )}
    />
  );
};

export default SelectOptionRect;
