import { makeIconDef } from "./makeIconDef";

export const VoicesIconDef = {
  voices: makeIconDef("voices", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.446 6.5L13.4612 6.5C13.0056 6.5 12.5746 6.70713 12.2899 7.06296L11.4909 8.06174C11.0165 8.65478 10.2982 9 9.53875 9H7.57843C6.91539 9 6.2795 8.73661 5.81066 8.26777L4.64645 7.10355C4.45118 6.90829 4.45118 6.59171 4.64645 6.39645C4.84171 6.20118 5.15829 6.20118 5.35355 6.39645L6.51777 7.56066C6.79907 7.84196 7.1806 8 7.57843 8H9.53875C9.99443 8 10.4254 7.79287 10.7101 7.43704L11.5091 6.43826C11.9835 5.84522 12.7018 5.5 13.4612 5.5H15.446C15.8991 5.5 16.3437 5.62314 16.7323 5.85627L18.2822 6.78624C18.5153 6.92611 18.7821 7 19.054 7H20C20.2761 7 20.5 7.22386 20.5 7.5C20.5 7.77614 20.2761 8 20 8H19.054C18.6009 8 18.1563 7.87686 17.7677 7.64373L16.2178 6.71376C15.9847 6.57389 15.7179 6.5 15.446 6.5ZM15.8284 10.5H17.6716C18.0694 10.5 18.4509 10.658 18.7322 10.9393L19.6464 11.8536C19.8417 12.0488 20.1583 12.0488 20.3536 11.8536C20.5488 11.6583 20.5488 11.3417 20.3536 11.1464L19.4393 10.2322C18.9705 9.76339 18.3346 9.5 17.6716 9.5H15.8284C15.1654 9.5 14.5295 9.76339 14.0607 10.2322L10.3536 13.9393C10.1583 14.1346 9.84171 14.1346 9.64645 13.9393L7.43934 11.7322C6.9705 11.2634 6.33461 11 5.67157 11H5C4.72386 11 4.5 11.2239 4.5 11.5C4.5 11.7761 4.72386 12 5 12H5.67157C6.0694 12 6.45093 12.158 6.73223 12.4393L8.93934 14.6464C9.52513 15.2322 10.4749 15.2322 11.0607 14.6464L14.7678 10.9393C15.0491 10.658 15.4306 10.5 15.8284 10.5ZM16.3284 15H18.5H20C20.2761 15 20.5 14.7761 20.5 14.5C20.5 14.2239 20.2761 14 20 14H18.5H16.3284C15.6654 14 15.0295 14.2634 14.5607 14.7322L11.7322 17.5607C11.4509 17.842 11.0694 18 10.6716 18H5C4.72386 18 4.5 18.2239 4.5 18.5C4.5 18.7761 4.72386 19 5 19H10.6716C11.3346 19 11.9705 18.7366 12.4393 18.2678L15.2678 15.4393C15.5491 15.158 15.9306 15 16.3284 15ZM16.5 18C16.2239 18 16 18.2239 16 18.5C16 18.7761 16.2239 19 16.5 19H20C20.2761 19 20.5 18.7761 20.5 18.5C20.5 18.2239 20.2761 18 20 18H16.5Z"
        fill="currentColor"
      />
    ),
  }),
};
