/** @jsxImportSource @emotion/react */
import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import Button, { ButtonGroup } from "../../base/components/Button";
import Panel, {
  PanelCloseButton,
  PanelHeader,
  PanelInner,
} from "../../base/components/Panel";
import Serif from "../../base/components/Serif";
import Spacing from "../../base/components/Spacing";
import Textarea from "../../base/components/Textarea";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { makeSnapshot } from "../../base/utils/snapshot.utils";
import { Artist, ArtistSnapshotFactory } from "../../models/Artist.model";
import ArtistTitleSelector from "../composer/ArtistTitleSelector";

type NewArtistPanelProps = {
  onShouldClose?: (newArtist?: Artist) => void;
  shouldNavigateToNewArtistAfterCreation?: boolean;
};

const NewArtistPanel: React.FC<NewArtistPanelProps> = props => {
  const { ARTISTS, THEME, PORTALS } = useControllers();
  const navigate = useNavigate();
  const p = useProps(props);
  const s = useStore(() => ({
    snapshot: makeSnapshot(ArtistSnapshotFactory, {}),
    cancel: () => {
      p.onShouldClose?.();
    },
    createArtist: async () =>
      await flow(function* () {
        try {
          const newArtist: Artist = yield ARTISTS.create(s.snapshot);
          p.onShouldClose?.(newArtist);
          if (p.shouldNavigateToNewArtistAfterCreation)
            navigate(`/workshop/artists/${newArtist._id}`);
        } catch (e) {
          console.error(e);
        }
      })(),
  }));
  return (
    <Observer
      children={() =>
        PORTALS.render(
          <Panel id="new-artist" title="New artist" fullscreen canEscape>
            <PanelHeader End={<PanelCloseButton onClick={s.cancel} />}>
              <h2>
                <Serif>New artist</Serif>
              </h2>
            </PanelHeader>
            <PanelInner>
              <div
                css={{
                  display: "grid",
                  gridGap: ".5em",
                  gridTemplateColumns: "repeat(6, 1fr)",
                }}
              >
                <TextInput
                  form={s.snapshot}
                  field="givenName"
                  Label="Given Name"
                  placeholder=""
                  css={{ gridColumnEnd: "span 2" }}
                  autoFocus
                  onEnter={s.createArtist}
                />
                <TextInput
                  form={s.snapshot}
                  field="middleName"
                  Label="Middle Name"
                  placeholder=""
                  css={{ gridColumnEnd: "span 2" }}
                  onEnter={s.createArtist}
                />
                <TextInput
                  form={s.snapshot}
                  field="surname"
                  Label="Surname"
                  placeholder=""
                  css={{ gridColumnEnd: "span 2" }}
                  onEnter={s.createArtist}
                />
                <TextInput
                  form={s.snapshot}
                  field="dateOfBirth"
                  Label="Date of birth"
                  type="date"
                  css={{ gridColumnEnd: "span 3" }}
                  placeholder=""
                  onEnter={s.createArtist}
                />
                <TextInput
                  form={s.snapshot}
                  field="catalogueIdentifier"
                  Label="Composition Catalogue Name"
                  css={{ gridColumnEnd: "span 3" }}
                  placeholder=""
                  onEnter={s.createArtist}
                />
                <ArtistTitleSelector
                  css={{ gridColumnEnd: "span 3" }}
                  artist={s.snapshot}
                />
                <Textarea
                  form={s.snapshot}
                  field="description"
                  Label="Description (Optional)"
                  css={{ gridColumnEnd: "span 6" }}
                  rows={3}
                />
              </div>
              <Spacing />
              <ButtonGroup>
                <Button fullWidth onClick={s.cancel}>
                  Cancel
                </Button>
                <Button
                  fullWidth
                  onClick={s.createArtist}
                  color={THEME.primary}
                >
                  Confirm
                </Button>
              </ButtonGroup>
            </PanelInner>
          </Panel>
        )
      }
    />
  );
};

export default NewArtistPanel;
