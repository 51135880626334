import { action, makeObservable, observable } from "mobx";
import WebMidi from "webmidi";
import type {
  Input,
  InputEventControlchange,
  InputEventNoteoff,
  InputEventNoteon,
  Output,
} from "webmidi";
import { Instrument, Note } from "../@types";
import * as Tone from "tone";
import {
  knownMidiInputDevices,
  knownMidiOutputDevices,
} from "../constants/midiDevices.constants";
import {
  changeMusicKeyDOMElBrightness,
  getYFromMidiNumber,
  resetKeyDOMElBrightness,
} from "../utils/musicKey.utils";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { isDevelopment } from "../base/env";
import { MidiRecorder } from "./composer/midiRecorder.controller";
import { ComposerInstance } from "../components/composer/useCreateComposerInstance";
import { SpeedMapRecorder } from "./composer/speedMapRecorder.controller";

export const makeMidiController = () => {
  const s = makeObservable(
    {
      ...makeControllerBase("MIDI"),
      inputs: [] as Input[],
      outputs: [] as Output[],
      get composer(): ComposerInstance | null {
        return s.ROOT?.COMPOSER.instance ?? null;
      },
      get midiRecorder(): MidiRecorder | null {
        return s.composer?.recorders.midi || null;
      },
      get speedMapRecorder(): SpeedMapRecorder | null {
        return s.composer?.recorders.speedMap || null;
      },
      getDeviceCustomName: (device: Input | Output) => {
        const knownDevices =
          device.type === "input"
            ? knownMidiInputDevices
            : knownMidiOutputDevices;
        return (
          knownDevices.find(
            d =>
              d._name === device.name && d._manufacturer === device.manufacturer
          ) ?? {
            _name: device.name,
            _manufacturer: device.manufacturer,
            displayName: device.name,
            displayManufacturer: device.manufacturer,
          }
        );
      },
      get instruments(): Instrument[] {
        return s.ROOT?.ENSEMBLE.focusedInstruments ?? [];
      },
      get useMidiControllersAsInput(): boolean {
        return (
          s.ROOT?.SETTINGS.composer.instruments.useMidiControllersAsInput ??
          false
        );
      },
      lastEnteredNote: null as null | Note,
      handleNoteOn: (e: InputEventNoteon) => {
        if (s.speedMapRecorder?.isRecording) {
          s.speedMapRecorder.processMidiEvent(e);
        } else {
          if (!Tone || !s.instruments || !s.ROOT) return;
          const { ENSEMBLE, KEYBOARD, SETTINGS } = s.ROOT;
          if (s.midiRecorder?.isRecording) {
            s.midiRecorder.processMidiEvent(e);
          } else if (SETTINGS.composer.instruments.useMidiControllersAsInput) {
            if (s.composer?.atomContext) {
              s.composer.runInHistory(
                "Enter note",
                action(() => {
                  const I = s.composer!;
                  const x = I.focusedCanvas?.primaryCursor?.x ?? 0;
                  const note = I.atomContext.createNote({
                    x: ENSEMBLE.isPlaying
                      ? x
                      : KEYBOARD.pressed.shift
                      ? s.lastEnteredNote?.startX ?? x
                      : x,
                    y: getYFromMidiNumber(e.note.number),
                    velocity: e.velocity,
                    width:
                      s.lastEnteredNote?.width ??
                      SETTINGS.composer.snapUnitX ??
                      0.25,
                  });
                  s.lastEnteredNote = note;
                  note?.select();
                })
              );
            }
          }
          s.instruments.forEach(ins =>
            ins.attack(`${e.note.name}${e.note.octave}`, Tone.immediate(), {
              velocity: e.velocity,
            })
          );
          changeMusicKeyDOMElBrightness(e.note.number);
        }
      },
      handleNoteOff: (e: InputEventNoteoff) => {
        if (s.speedMapRecorder?.isRecording) {
          return;
        } else {
          if (!Tone || !s.instruments || !s.midiRecorder) return;
          s.midiRecorder.processMidiEvent(e);
          s.instruments.forEach(ins =>
            ins.release(`${e.note.name}${e.note.octave}`, Tone.immediate())
          );
          resetKeyDOMElBrightness(e.note.number);
        }
      },
      handleSustainPedalDown: () => {
        s.instruments.forEach(ins => ins.sustainPedalDown?.());
      },
      handleSustainPedalUp: () => {
        s.instruments.forEach(ins => ins.sustainPedalUp?.());
      },
      handleControlChange: (e: InputEventControlchange) => {
        // console.info(e);
        if (!s.instruments || !s.midiRecorder) return;
        if (s.speedMapRecorder?.isRecording) {
          return;
        } else {
          s.midiRecorder.processMidiEvent(e);
          switch (e.controller.name) {
            case "holdpedal":
              if (e.value === 0) s.handleSustainPedalUp();
              else s.handleSustainPedalDown();
              break;
            default:
              if (isDevelopment)
                console.info(
                  `Unsupported controlchange event received in MIDI`,
                  e
                );
          }
        }
      },
      addInput: (...inputs: Input[]) => {
        const inputsWithCustomNames = inputs.filter(i =>
          s.getDeviceCustomName(i)
        );
        s.inputs.push(...inputsWithCustomNames);
        inputsWithCustomNames.forEach(input => {
          input.addListener("noteon", "all", s.handleNoteOn);
          input.addListener("noteoff", "all", s.handleNoteOff);
          input.addListener("controlchange", "all", s.handleControlChange);
        });
      },
      addOutput: (...outputs: Output[]) => {
        const outputsWithCustomNames = outputs.filter(o =>
          s.getDeviceCustomName(o)
        );
        for (const o of outputsWithCustomNames) {
          if (s.outputs.includes(o)) return;
          s.outputs.push(o);
        }
      },
      removeInput: (input: Input) => {
        // the 'removeListener' method doesn't actually exist
        // try {
        //   input.removeListener("noteon", "all", s.handleNoteOn);
        //   input.removeListener("noteoff", "all", s.handleNoteOff);
        //   input.removeListener("controlchange", "all", s.handleControlChange);
        // } catch (e) {
        //   console.warn(e);
        // }
        s.inputs.splice(
          s.inputs.findIndex(p => input.id === p.id),
          1
        );
      },
      removeOutput: (output: Output) => {
        s.outputs.splice(
          s.outputs.findIndex(p => output.id === p.id),
          1
        );
      },
      stopAllOutputs: () => {
        s.outputs.forEach(o => o.stopNote("all"));
      },
    },
    {
      inputs: observable.shallow,
      outputs: observable.shallow,
    }
  );

  s.init = makeRootControllerChildInitFn(
    s,
    () =>
      new Promise<void>(resolve => {
        WebMidi.enable(
          action(err => {
            if (err) {
              console.warn("WebMidi could not be enabled.", err);
              resolve();
            } else {
              // console.info("WebMidi enabled!");
              // console.info("INPUTS", WebMidi.inputs);
              // console.info("OUTPUTS", WebMidi.outputs);
              if (isDevelopment) Reflect.set(window, "webmidi", WebMidi);
              WebMidi.addListener(
                "connected",
                action(e => {
                  // console.info(e);
                  if (e.port.type === "input") s.addInput(e.port);
                  if (e.port.type === "output") s.addOutput(e.port);
                })
              );
              WebMidi.addListener(
                "disconnected",
                action(e => {
                  // console.info(e);
                  if (e.port.type === "input") s.removeInput(e.port as Input);
                  if (e.port.type === "output")
                    s.removeOutput(e.port as Output);
                })
              );
              resolve();
            }
          })
        );
        window.addEventListener("beforeunload", s.stopAllOutputs);
      })
  );

  return s;
};

export type MidiController = ReturnType<typeof makeMidiController>;
