import { action, observable } from "mobx";
import { AtomType } from "../@types";
import { KeyboardShortcutDef } from "../base/components/KeyboardShortcut";
import { first } from "../base/utils/ramdaEquivalents.utils";
import { formatAsSearchString } from "../base/utils/search.utils";
import type { ComposerInstance } from "../components/composer/useCreateComposerInstance";
import { KEYBOARD_SHORTCUTS } from "../constants/keyboardShortcuts.constants";
import { Collection } from "../models/Collection.model";
import { Composition } from "../models/Composition.model";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { IS_EMBEDDED } from "../env";
import {
  ClavieristWindowEvent,
  postMessageToParentWindow,
} from "../utils/messages.utils";
import { makeSlug } from "../base/utils/slug.utils";

export type PaletteCommand = {
  label: string;
  _s: string;
  action: () => void;
  shortcut?: KeyboardShortcutDef;
};

export const makeCommandPaletteController = () => {
  const s = observable({
    ...makeControllerBase("COMMAND_PALETTE"),
    _shouldRender: false,
    get shouldRender() {
      return s._shouldRender && s.commands.length > 0;
    },
    set shouldRender(v: boolean) {
      s._shouldRender = v;
    },
    toggle: () => (s.shouldRender = !s.shouldRender),
    show: () => (s.shouldRender = true),
    hide: () => (s.shouldRender = false),
    query: "",
    get composer(): ComposerInstance | null {
      return s.ROOT?.COMPOSER.instance ?? null;
    },
    get withPlayerUI(): boolean {
      return !!(s.comp && s.composer?.withPlayerUI);
    },
    get withEditorUI(): boolean {
      return !!(s.comp && s.composer?.withEditorUI);
    },
    get comp(): Composition | null {
      return s.composer?.composition ?? null;
    },
    get firstCollection(): Collection | null {
      return first(s.comp?.collections) ?? null;
    },
    get editable(): boolean {
      return s.composer?.editable ?? false;
    },
    get isAuthenticated(): boolean {
      return !!s.ROOT?.AUTH.isAuthenticated;
    },
    get commands(): PaletteCommand[] {
      return [
        ...(s.withEditorUI
          ? [
              // {
              //   label: "Organize element stacking order",
              //   action: () => {
              //     s.composer.atomContext.applyAutoSort();
              //   },
              // },
              {
                label: "Unlock all",
                action: () => {
                  s.comp?.atomContext?.unsortedAtoms
                    .filter(a => a._isLocked)
                    .forEach(a => (a._isLocked = false));
                },
              },
              ...(s.composer?.isOwnerOrAdmin
                ? [
                    {
                      label: s.composer?.package
                        ? `Export version “${s.composer.package.displayName}” as JSON`
                        : "Export as JSON",
                      action: async () => {
                        if (s.composer?.package) {
                          await s.ROOT?.PACKAGES.export(s.composer.package);
                        } else {
                          await s.ROOT?.COMPOSITIONS.export(s.comp!);
                        }
                      },
                    },
                    {
                      label: "Export as MIDI...",
                      action: () => {
                        s.composer?.togglePanel("midiExporter", true);
                      },
                    },
                  ]
                : []),
              {
                label: "Export as image (PNG)...",
                action: () => {
                  s.composer?.togglePanel("pngExporter", true);
                },
              },
              {
                label: "Export as vector image (SVG)...",
                action: () => {
                  s.composer?.togglePanel("svgExporter", true);
                },
              },
              ...(s.composer?.isOwnerOrAdmin &&
              !!s.composer?.package &&
              IS_EMBEDDED
                ? [
                    {
                      label: `Open version “${s.composer.package.displayName}” in new tab`,
                      action: () => {
                        if (s.composer?.package)
                          window.open(`/c/${s.composer.package._id}/`);
                      },
                    },
                    ...(s.editable
                      ? [
                          {
                            label: `Delete version “${s.composer.package.displayName}” permanently`,
                            action: () => {
                              if (s.composer?.package) {
                                // if (IS_EMBEDDED) {
                                postMessageToParentWindow(
                                  ClavieristWindowEvent.DeleteMessage,
                                  {
                                    packageId: s.composer.package._id,
                                  },
                                  true
                                );
                                // } else {
                                //   s.ROOT?.NAVIGATOR.navigateTo(
                                //     `/compose/${s.composer.package.$.compositionId}`
                                //   );
                                //   await s.ROOT?.PACKAGES.delete(s.composer.package);
                                //   s.ROOT?.DIALOGS.present({
                                //     Heading: `Delete success`,
                                //     Body: `Version snapshot “${s.composer.package.displayName}” was deleted permanently.`,
                                //     defaultActions: ["positive"],
                                //   });
                                // }
                              }
                            },
                          },
                        ]
                      : []),
                  ]
                : []),
              {
                label: "Open in play mode",
                action: () => {
                  window.location.href = `${window.location.origin}/play/${
                    s.comp!._id
                  }?i=${makeSlug(s.comp!.$.title)}`;
                },
              },
              {
                label: "Select all notes",
                action: () => {
                  s.composer?.atomContext.selectAll(AtomType.note);
                },
              },
              ...(s.ROOT?.SETTINGS.experimentalFeatures.chords
                ? [
                    {
                      label: "Select all chords",
                      action: () => {
                        s.composer?.atomContext.selectAll(AtomType.chord);
                      },
                    },
                  ]
                : []),
              {
                label: "Select all groups",
                action: () => {
                  s.composer?.atomContext.selectAll(AtomType.group);
                },
              },
              {
                label: "Select all ornaments",
                action: () => {
                  s.composer?.atomContext.selectAll(AtomType.ornament);
                },
              },
              ...(s.ROOT?.SETTINGS.experimentalFeatures.patternSystem
                ? [
                    {
                      label: "Select all patterns",
                      action: () => {
                        s.composer?.atomContext.selectAll(AtomType.pattern);
                      },
                    },
                    {
                      label: "Select all replicas",
                      action: () => {
                        s.composer?.atomContext.selectAll(AtomType.replica);
                      },
                    },
                  ]
                : []),
              {
                label: "Select all keyframes",
                action: () => {
                  s.composer?.atomContext.selectAll(AtomType.keyframe);
                },
              },
              {
                label: "Select all keyframes not in the interpretation",
                action: () => {
                  s.composer?.atomContext.composer?.tools.select.updateSelection(
                    {
                      debugCaller: `selectAllKeyframesInComp`,
                      atoms: s.composer.atomContext.keyframes.filter(
                        k => !k._itpId
                      ),
                    }
                  );
                },
              },
              {
                label: "Select all bars",
                action: () => {
                  s.composer?.atomContext.selectAll(AtomType.bar);
                },
              },
              ...(s.editable
                ? [
                    {
                      label: "Delete all empty bars",
                      action: () => {
                        s.composer?.atomContext.removeAtoms(
                          s.composer.atomContext.bars.filter(
                            b => b.atoms.length === 0
                          )
                        );
                      },
                    },
                    {
                      label: "Remove unused interpretation rules",
                      action: () => {
                        s.composer?.atomContext.interpreter?.cleanup.unusedRules();
                      },
                    },
                    {
                      label: "Sync changes",
                      action: () => {
                        s.ROOT?.SYNC.sync("Command palette manual sync");
                      },
                      shortcut: KEYBOARD_SHORTCUTS.syncChanges,
                    },
                  ]
                : []),
              {
                label: "Toggle dark/light mode",
                action: () => {
                  s.ROOT?.THEME.switchColorScheme();
                },
                shortcut: KEYBOARD_SHORTCUTS.toggleColorTheme,
              },
              {
                label: "Keyboard shortcuts",
                action: () => {
                  if (!s.composer) return;
                  s.composer.displayKeyboardShortcutCheatsheet = true;
                },
                shortcut: KEYBOARD_SHORTCUTS.toggleColorTheme,
              },
              // {
              //   label: "Make a copy of this composition in your Workshop",
              //   action: () => {
              //     s.ROOT?.THEME.switchColorScheme();
              //   },
              // },
            ]
          : []),
        ...(s.withPlayerUI
          ? [
              ...(s.isAuthenticated && s.comp?.owner === s.ROOT?.AUTH.user
                ? [
                    {
                      label: "Open in edit mode",
                      action: () => {
                        window.location.href = `${
                          window.location.origin
                        }/compose/${s.comp!._id}`;
                      },
                    },
                  ]
                : []),
              // {
              //   label: s.composer?.render.video
              //     ? "Pause rendering graphics"
              //     : "Continue rendering graphics",
              //   action: action(() => {
              //     if (s.composer)
              //       s.composer.render.video = !s.composer.render.video;
              //   }),
              // },
            ]
          : []),
        ...(s.composer
          ? [
              {
                label: s.composer?.hideUI ? "Show UI" : "Hide UI",
                action: action(() => {
                  if (s.composer) s.composer.hideUI = !s.composer.hideUI;
                }),
                shortcut: KEYBOARD_SHORTCUTS.hideUI,
              },
              ...(s.firstCollection
                ? [
                    {
                      label: `View collection${
                        s.firstCollection
                          ? ` "${s.firstCollection.$.name}"`
                          : ""
                      }`,
                      action: () => {
                        if (s.firstCollection) {
                          s.ROOT?.NAVIGATOR.navigateTo(
                            `/${
                              s.withEditorUI ? "workshop" : "discover"
                            }/collections/${s.firstCollection._id}`
                          );
                        }
                      },
                    },
                  ]
                : []),
            ]
          : []),
      ]
        .map(c => ({
          ...c,
          _s: formatAsSearchString(c.label),
        }))
        .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
    },
    get formattedQuery(): string {
      return formatAsSearchString(s.query);
    },
    get matchedCommands(): PaletteCommand[] {
      if (!s.formattedQuery) return s.commands;
      const queryWords = s.formattedQuery.split(/\s+/);
      const scoredEntries = s.commands
        .map(cmd => {
          let score = 0;
          if (queryWords.every(w => cmd._s.includes(w))) score += 1;
          const nameInWords = cmd.label.toLowerCase().split(" ");
          if (
            queryWords.every(
              w => nameInWords.filter(nw => nw.startsWith(w)).length > 0
            )
          )
            score += 1;
          return { score, cmd };
        })
        .filter(e => e.score > 0);
      return scoredEntries.sort((a, b) => b.score - a.score).map(e => e.cmd);
    },
    execute: (command: PaletteCommand) => {
      s.hide();
      command.action();
    },
  });
  s.init = makeRootControllerChildInitFn(s, () => {});
  return s;
};

export type CommandPaletteController = ReturnType<
  typeof makeCommandPaletteController
>;
