import { action, observable, toJS } from "mobx";
import {
  Atom,
  AtomBaseSnapshot,
  AtomContext,
  InstrumentSnapshot,
  VoiceSnapshot,
} from "../@types";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { replaceContents } from "../base/utils/array.utils";
import { removeAtoms } from "../operations/removeAtoms.operation";
import { duplicateAtoms } from "../operations/duplicateAtoms.operation";
import { CCanvasCursorController } from "../components/composerCanvas/controllers/makeCCanvasCursor.controller";
import type { ComposerInstance } from "../components/composer/useCreateComposerInstance";
import { uniq } from "../base/utils/ramdaEquivalents.utils";
import { isObject } from "../base/utils/typeChecks.utils";
import { RuleSnapshot } from "../@types/interpretations.types";
import { isMusicalAtom, isNonVoiceGroupLikeAtom } from "../utils/atoms.utils";
import {
  getMinimizedAtomSnapshot,
  minimizeRuleSnapshot,
} from "../base/utils/snapshot.utils";
import { assertTruthy } from "../base/utils/assert.utils";
import { reportError } from "../base/utils/errors.utils";
import { isEqual, last } from "lodash-es";
import { createObservable } from "../base/utils/mobx.utils";

export type ClipboardRecord<T extends AtomBaseSnapshot = AtomBaseSnapshot> = {
  app: "clavierist";
  type: "cut" | "copy";
  authorId: string;
  compositionId: string;
  interpretationId: string;
  atoms: Partial<AtomBaseSnapshot>[];
  rules: Partial<RuleSnapshot>[];
  voices: Partial<VoiceSnapshot>[];
  instruments: InstrumentSnapshot[];
  timeCopied: number;
};

export const makeClipboardController = () => {
  const c = createObservable(
    {
      ...makeControllerBase("CLIPBOARD"),
      history: [] as ClipboardRecord[],
      get lastRecord(): ClipboardRecord | null {
        return last(c.history) ?? null;
      },
      /** @deprecated */
      atoms: [] as Atom[],
      atomsAddedFromActionType: null as "copy" | "cut" | null,
      get composer(): ComposerInstance | null {
        return c.ROOT!.COMPOSER.instance ?? null;
      },
      get targetAtomContext(): AtomContext | null {
        return c.composer?.atomContext ?? null;
      },
      get currentCursor(): CCanvasCursorController | null {
        return c.composer?.focusedCanvas?.primaryCursor ?? null;
      },
      writeToSystemClipboard: (record: ClipboardRecord) => {
        navigator.clipboard.writeText(JSON.stringify(record));
      },
      copy: (atoms: Atom[]) => {
        try {
          c.atomsAddedFromActionType = "copy";
          c.createClipboardRecord(atoms, "copy");
          replaceContents(c.atoms, atoms);
        } catch (e) {
          reportError(e);
          c.ROOT?.STATUS.displayMessage("Failed to copy");
        }
      },
      cut: (atoms: Atom[]) => {
        try {
          if (atoms.length === 0) return;
          if (!c.targetAtomContext) return;
          c.atomsAddedFromActionType = "cut";
          c.targetAtomContext.composer?.runInHistory("Cut atoms", () => {
            c.createClipboardRecord(atoms, "cut");
            replaceContents(c.atoms, atoms);
            removeAtoms(atoms, "cutAtoms");
            c.composer?.tools.select.clearSelection({
              debugHandle: "clipboardCutAtomsInSelection",
            });
          });
        } catch (e) {
          reportError(e);
          c.ROOT?.STATUS.displayMessage("Failed to cut");
        }
      },
      paste: () => {
        try {
          if (c.atoms.length === 0) return;
          if (!c.composer) return;
          const x =
            c.currentCursor?.x ??
            c.composer.composition.atomContext?.lastNoteByEndX?.endX ??
            0;
          c.composer.runInHistory("Paste atoms", () => {
            duplicateAtoms({
              atoms: c.atoms,
              atPosition: x,
            });
          });
        } catch (e) {
          c.ROOT?.STATUS.displayMessage("Failed to paste");
        }
      },
      pastAtomsFromClipboard: async () => {
        const contentFromClipboard = await c.readFromSystemClipboard();
        if (!contentFromClipboard) return;
        // const currentLargestId =
        //   c.composer?.atomContext.getNextNewAtomId();
      },
      createClipboardRecord: action((atoms: Atom[], type: "cut" | "copy") => {
        const composition = c.composer?.composition;
        const interpretation = c.composer?.selectedInterpretation;
        assertTruthy(
          composition,
          "Composition unavailable when attempting to copy"
        );
        assertTruthy(
          interpretation,
          "Interpretation unavailable when attempting to copy"
        );
        const record: ClipboardRecord = toJS({
          app: "clavierist",
          type,
          authorId: c.ROOT?.AUTH.user?._id ?? composition.$.ownerId,
          compositionId: composition._id,
          interpretationId: interpretation._id,
          atoms: uniq(
            atoms.map(a => {
              if (isNonVoiceGroupLikeAtom(a))
                return a.descendants.map(d => d.$);
              return a.$;
            })
          )
            .flat()
            .map($ => getMinimizedAtomSnapshot($)),
          rules: uniq(atoms.map(a => a.rules.map(r => r.$)).flat()).map($ =>
            minimizeRuleSnapshot($)
          ),
          voices: uniq(
            atoms.map(a => a.voice?.$).filter(i => i) as VoiceSnapshot[]
          ).map($ => getMinimizedAtomSnapshot($)),
          instruments: uniq(
            atoms
              .map(a =>
                isMusicalAtom(a) ? a.interpreted.instruments.map(i => i.$) : []
              )
              .flat()
          ).map($ => toJS($)),
          timeCopied: new Date().getTime(),
        });
        if (c.lastRecord) {
          const { timeCopied: lastTimeCopied, ...lastRecord } = c.lastRecord;
          const { timeCopied: newTimeCopied, ...newRecord } = record;
          if (isEqual(lastRecord, newRecord)) return c.lastRecord;
        }
        c.history.push(record);
        c.writeToSystemClipboard(record);
        // console.info("Created clipboard record:", record);
        return record;
      }),
      readFromSystemClipboard: async () => {
        const content = JSON.parse(await navigator.clipboard.readText());
        if (
          isObject(content) &&
          "app" in content &&
          content.app === "clavierist"
        ) {
          return content as ClipboardRecord;
        }
        return null;
      },
    },
    { history: observable.shallow }
  );

  c.init = makeRootControllerChildInitFn(c, () => {});

  return c;
};

export type ClipboardController = ReturnType<typeof makeClipboardController>;
