/** @jsxImportSource @emotion/react */
import { sort } from "fast-sort";
import { action, reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Atom, Voice } from "../../@types";
import { DropdownOptionDefSet } from "../../base/components/Dropdown";
import Selector from "../../base/components/Selector/Selector";
import SelectOptionVoice from "../../base/components/Selector/SelectOptionVoice";
import Spacing from "../../base/components/Spacing";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { keepTruthy } from "../../base/utils/array.utils";
import cx from "../../base/utils/className.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { first, uniq } from "../../base/utils/ramdaEquivalents.utils";
import { assignVoice } from "../../operations/assignVoice.operation";
import {
  isGroupAtom,
  isGroupLikeAtom,
  isNoteAtom,
} from "../../utils/atoms.utils";
import { useComposer } from "./ComposerApp.context";
import styled from "@emotion/styled";
import { ResetButtonButton } from "../../base/components/ResetButton";
import { ResetIcon } from "../../base/components/icons/Reset.icon";
import { varPrimary } from "../../constants/cssCustomProperties.constants";

type AtomVoiceSelectorProps = {
  className?: string;
  atom?: Atom;
  atoms?: Atom[];
  nullable?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
};

const MIXED = "MIXED";

const Label = styled.label`
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  > * {
    + * {
      margin-left: 1em;
    }
  }
`;

const AtomVoiceSelector: React.FC<AtomVoiceSelectorProps> = props => {
  const { THEME } = useControllers();
  const I = useComposer();
  const p = useProps(props);
  const s = useStore(() => ({
    get givenAtoms(): Atom[] {
      return keepTruthy([p.atom, ...(p.atoms ?? [])]) as Atom[];
    },
    get allDescendantsInGivenAtoms(): Atom[] {
      return s.givenAtoms
        .map(n => (isGroupLikeAtom(n) ? [n, ...n.descendants] : n))
        .flat();
    },
    get uniqueVoicesInAllDescendants(): Voice[] {
      return sort(
        uniq(
          keepTruthy(s.allDescendantsInGivenAtoms.map(n => n.voice))
        ) as Voice[]
      ).asc(v => v.displayName);
    },
    get voices(): Voice[] {
      return I.composition.atomContext?.voices.filter(v => v.isWritable) ?? [];
    },
    get voicesAsOptions(): DropdownOptionDefSet {
      return s.voices.map(voice => ({
        Label: voice.name ?? `Voice #${voice._id}`,
        value: voice._id,
        color: voice.appearance?.colorInContext ?? THEME.primary,
      }));
    },
    get mixedLabel(): string {
      return s.isMixed
        ? `* Currently mixed (${s.uniqueVoicesInAllDescendants
            .map(v => v.name)
            .join(", ")})`
        : "";
    },
    form: {
      voiceId: "",
    },
    get isMixed(): boolean {
      return s.uniqueVoicesInAllDescendants.length > 1;
    },
    get outerValue() {
      return s.isMixed ? MIXED : s.uniqueVoicesInAllDescendants[0]?._id ?? "";
    },
    get labelName() {
      if (isGroupAtom(p.atom)) return `this group and all the notes in it`;
      if (isNoteAtom(p.atom)) return "this note";
      return "";
    },
    get canReset() {
      return s.allDescendantsInGivenAtoms.some(
        a => !!a.refAtom && a.refAtom.voiceId !== a.voiceId
      );
    },
    reset: () => {
      I.runInHistory(
        "Reset voice",
        action(() => {
          s.allDescendantsInGivenAtoms.forEach(a => {
            if (a.refAtom) {
              a.$.voiceId = null;
            }
          });
        })
      );
    },
    handleAtomVoiceChange: (newValue?: string) => {
      I.runInHistory(`Assign voice`, () => {
        const atomsUpdated = assignVoice({
          context: I.atomContext,
          newVoiceId: newValue,
          atoms: s.givenAtoms,
        });
        const v = first(atomsUpdated)?.voice;
        if (v) I.setWriteToVoiceAs(v, "AtomVoiceChange");
      });
    },
  }));
  useOnMount(() => {
    return reaction(
      () => s.outerValue && s.outerValue !== s.form.voiceId,
      () => {
        s.form.voiceId = s.outerValue;
      },
      { fireImmediately: true }
    );
  });
  return (
    <Observer
      children={() => (
        <div className={cx("AtomVoiceSelector", p.className)}>
          <Label style={{ color: s.canReset ? varPrimary : "currentColor" }}>
            <span>
              {["Assign", s.labelName, "to a voice"].filter(i => i).join(" ")}
            </span>
            {s.canReset && (
              <ResetButtonButton
                type="button"
                onClick={s.reset}
                disabled={p.disabled}
              >
                <ResetIcon /> Reset
              </ResetButtonButton>
            )}
          </Label>
          {s.isMixed && <p css={{ opacity: 0.5 }}>{s.mixedLabel}</p>}
          <Spacing size=".5em" />
          <Selector
            form={s.form}
            field="voiceId"
            options={s.voicesAsOptions}
            onChange={s.handleAtomVoiceChange}
            optionRenderer={SelectOptionVoice}
            nullable={p.nullable}
            fullWidth={p.fullWidth}
            wrap
            disabled={p.disabled}
          />
        </div>
      )}
    />
  );
};

export default AtomVoiceSelector;
