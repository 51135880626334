import { observable } from "mobx";
import { ColorTheme, OctaveColorList } from "../../base/@types";
import { ColorPalette } from "../colorPalette";

const makeDefaultOctaveColorList = () =>
  [
    "#FACC56", // 1
    "#BCE762", // 1#
    "#61D8E9", // 2
    "#4985E0", // 2#
    "#FD8B39", // 3
    "#3DB766", // 4
    "#39DBA1", // 4#
    "#FD7070", // 5
    "#FF284F", // 5#
    "#63428D", // 6
    "#9440FF", // 6#
    "#CC83EF", // 7
  ] as OctaveColorList;

export const makeClavieristColorTheme = (): ColorTheme =>
  observable({
    name: "Clavierist",
    default: "dark",
    variants: {
      dark: {
        name: "Dark",
        colors: {
          foreground: ColorPalette.light,
          background: ColorPalette.shade,
          primary: ColorPalette.pistachio,
          keyboardA: ColorPalette.shade,
          keyboardB: ColorPalette.ivory,
        },
        octave: makeDefaultOctaveColorList(),
      },
      light: {
        name: "Light",
        colors: {
          foreground: ColorPalette.shade,
          background: ColorPalette.light,
          primary: ColorPalette.grass,
          keyboardA: ColorPalette.light,
          keyboardB: ColorPalette.shade,
        },
        octave: makeDefaultOctaveColorList(),
      },
    },
    options: {
      colorNotesByPitch: false,
    },
  });

export const ClavieristColorTheme = makeClavieristColorTheme();
