import { observable, runInAction, when } from "mobx";
import { ReactNode } from "react";
import { removeOneFromArray } from "../base/utils/array.utils";
import { makeDisposerController } from "../base/utils/disposer.utils";
import { createObservable } from "../base/utils/mobx.utils";
import { isString } from "../base/utils/typeChecks.utils";
import { runAfter } from "../base/utils/waiters.utils";
import { makeControllerBase } from "./_root.controller";

type ProgressMessageFactory = string | ((progress: number) => ReactNode);

const makeStatusMessage = (
  Message: ReactNode,
  options?: {
    duration?: number;
    color?: string;
  }
) => {
  const s = createObservable({
    get message() {
      return Message;
    },
    dismiss: () => Promise.resolve(),
  });
  const duration = options?.duration ?? 5000;
  if (duration) {
    runAfter(() => {
      s.dismiss();
    }, duration);
  }
  return s;
};

type StatusMessage = ReturnType<typeof makeStatusMessage>;
type StatusMessageOptions = Parameters<typeof makeStatusMessage>["1"];

const makeProgressIndicator = (
  message: ProgressMessageFactory,
  resolveWhen?: () => boolean
) => {
  const onCompleteHandlers = [] as Function[];
  const d = makeDisposerController();
  const _ = observable({
    message,
  });
  const s = observable({
    progress: 0,
    get message() {
      return isString(_.message) ? _.message : _.message(s.progress);
    },
    updateMessage: (m: string) => {
      _.message = m;
    },
    complete: () => {
      s.progress = 1;
      return s;
    },
    get isComplete() {
      return s.progress >= 1;
    },
    waitForComplete: async () =>
      await new Promise<void>(resolve => {
        d.add(
          when(resolveWhen ?? (() => s.isComplete), () => {
            if (!s.isComplete) s.complete();
            resolve();
          })
        );
      }),
    onComplete: (fn: () => void) => {
      if (s.isComplete) fn();
      else onCompleteHandlers.push(fn);
    },
    dispose: d.dispose,
  });
  d.add(
    when(
      () => s.isComplete,
      () => {
        onCompleteHandlers.forEach(fn => {
          fn();
        });
        s.dispose();
      }
    )
  );
  return s;
};

type ProgressIndicator = ReturnType<typeof makeProgressIndicator>;

export const makeStatusController = () => {
  const s = observable({
    ...makeControllerBase("STATUS"),
    messages: [] as StatusMessage[],
    progresses: [] as ProgressIndicator[],
    displayMessage: (message: ReactNode, options?: StatusMessageOptions) => {
      const m = makeStatusMessage(message, options);
      s.messages.push(m);
      m.dismiss = async () => {
        await when(() => !s.progresses.length);
        runInAction(() => {
          removeOneFromArray(s.messages, m);
        });
      };
      return m;
    },
    registerProgress: (
      message: ProgressMessageFactory,
      resolveWhen?: () => boolean
    ) => {
      const progress = makeProgressIndicator(message, resolveWhen);
      s.progresses.push(progress);
      progress.onComplete(() => {
        removeOneFromArray(s.progresses, progress);
      });
      return progress;
    },
  });
  return s;
};

export type StatusController = ReturnType<typeof makeStatusController>;
