import { isEmpty, isEqual } from "lodash-es";
import { toJS } from "mobx";
import { AtomBaseSnapshot } from "../../@types";
import { RuleSnapshot } from "../../@types/interpretations.types";
import { BaselineAtomSnapshotSet } from "../../models/atoms/_baselineAtomSnapshotSet";
import { CompositionSnapshot } from "../../models/Composition.model";
import { InterpretationSnapshot } from "../../models/Interpretation.model";
import { isStandardModel } from "./models.utils";
import {
  copyWithJSON,
  isEmptyObjectOrUndefined,
  mergeIntoObjectByDescriptors,
} from "./object.utils";

export const getSnapshot = <T extends AnyObject = AnyObject>(
  object: AnyObject
) => {
  if (isStandardModel(object)) return toJS(object.$) as unknown as T;
  if ("$" in object && typeof object.$ === "object") return toJS(object.$) as T;
  return toJS(object) as T;
};

export const makeSnapshot = <T extends {}>(
  factory: () => T,
  ...partials: Partial<T>[]
) => {
  const snapshot = factory();
  partials.forEach(part => Object.assign(snapshot, part));
  return snapshot;
};

/**
 * Automatically generates getters and setters for an object. This does not make the object observable.
 * @param object an object that has not been applied with getters and setters of the attributes in the snapshot
 * @param snapshotBase an empty snapshot as a blueprint
 * @param overrides the overriding methods, applied after the standard getter/setters have been created
 */
export const setupAccessorsToObservableSnapshot = <T extends UnknownObject>(
  object: UnknownObject,
  observableSnapshot: Partial<T>,
  snapshotBase: Partial<T>,
  overrides?: Partial<T>
): T => {
  Object.keys(snapshotBase).forEach(key => {
    Object.defineProperty(object, key, {
      get() {
        return observableSnapshot[key];
      },
      set(newValue: unknown) {
        (observableSnapshot as UnknownObject)[key] = newValue;
      },
      configurable: true,
    });
  });
  if (overrides) mergeIntoObjectByDescriptors(object, overrides);
  return object as T;
};

export const replaceIds = <T>(snapshot: T, map: Map<string, string>) => {
  let str = JSON.stringify(snapshot);
  Array.from(map.entries()).forEach(([oldId, newId]) => {
    str = str.replaceAll(oldId, newId);
  });
  return JSON.parse(str) as T;
};

export const getMinimizedAtomSnapshot = <T extends AtomBaseSnapshot>($: T) => {
  const { baseline, properties } = BaselineAtomSnapshotSet[$.type];
  const result = {
    _id: $._id,
    type: $.type,
  } as Partial<T>;
  properties.forEach(prop => {
    if (
      isEmptyObjectOrUndefined((baseline as unknown as T)[prop as keyof T]) &&
      isEmptyObjectOrUndefined($[prop as keyof T])
    )
      return;
    else if (
      !isEqual((baseline as unknown as T)[prop as keyof T], $[prop as keyof T])
    ) {
      result[prop as keyof T] = $[prop as keyof T];
    }
  });
  return copyWithJSON(result);
};

export const getMinimizedCompositionSnapshot = ($: CompositionSnapshot) => {
  const { atomSnapshots, ...rest } = toJS($);
  return {
    ...rest,
    atomSnapshots: atomSnapshots.map(a => getMinimizedAtomSnapshot(a)),
  };
};

export const minimizeRuleSnapshot = ($: RuleSnapshot) => {
  const { properties, ...rest } = $;
  if (isEmpty(properties)) return copyWithJSON(rest);
  return copyWithJSON($);
};

export const minimizeInterpretationSnapshot = ($: InterpretationSnapshot) => {
  const { atomSnapshots, ruleSnapshots, ...rest } = toJS($);
  return {
    ...rest,
    atomSnapshots: atomSnapshots.map(a => getMinimizedAtomSnapshot(a)),
    ruleSnapshots: ruleSnapshots.map(r => minimizeRuleSnapshot(r)),
  };
};

export const isEquivalentAtomSnapshot = <T extends AtomBaseSnapshot>(
  a: T,
  b: T
) => {
  const { _id: aId, ...aMin } = getMinimizedAtomSnapshot(a);
  const { _id: bId, ...bMin } = getMinimizedAtomSnapshot(b);
  return isEqual(aMin, bMin);
};

export const isIdenticalAtomSnapshot = <T extends AtomBaseSnapshot>(
  a: T,
  b: T
) => {
  const aMin = getMinimizedAtomSnapshot(a);
  const bMin = getMinimizedAtomSnapshot(b);
  return isEqual(aMin, bMin);
};
