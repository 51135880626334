import { normalizeString } from "./string.utils";

export const sanitizeStringForUsername = (string: string) => {
  return normalizeString(string)
    .toLowerCase()
    .replace(/(\s)/g, "-")
    .replace(/&/g, "-")
    .replace(/[^\w\-]+/g, "-")
    .replace(/^\-/g, "");
};
