import { makeIconDef } from "./makeIconDef";

export const ScoreIconDef = {
  score: makeIconDef("score", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4H3.5H5H6H17.5V5H6V8H12V9H6V12H11V13H6V16H8.05001C8.01722 16.1616 8 16.3288 8 16.5C8 16.6712 8.01722 16.8384 8.05001 17H6V20H16.4998C16.9559 20.6072 17.6821 21 18.5 21H6H5H3.5H3V20.5V4.5V4ZM5 20V17H4V20H5ZM5 16V13H4V16H5ZM5 12V9H4V12H5ZM5 8V5H4V8H5ZM22 10.999V9L13 6V7.5V9V14.4998C12.5822 14.186 12.0628 14 11.5 14C10.1193 14 9 15.1193 9 16.5C9 17.8807 10.1193 19 11.5 19C12.8807 19 14 17.8807 14 16.5V9.33333L21 11.6667V16.4998C20.5822 16.186 20.0628 16 19.5 16C18.1193 16 17 17.1193 17 18.5C17 19.8807 18.1193 21 19.5 21C20.8807 21 22 19.8807 22 18.5V12V11V10.999Z"
        fill="currentColor"
      />
    ),
  }),
};
