import * as React from "react";
import { Suspense } from "react";
import { Renderable } from "../@types/ui.types";
import LoadingIndicatorBlock from "../components/LoadingIndicatorBlock";
import { isFunction, isObject } from "./typeChecks.utils";

export function isReactComponentClass(
  component: unknown
): component is React.ComponentClass {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    typeof component === "function" && !!component?.prototype?.isReactComponent
  );
}

/**
 * native arrows don't have prototypes; class components have special prototype property 'isReactComponent'
 */
export function isReactFunctionalComponent(
  component: unknown
): component is React.FC {
  if (!component) return false;
  return (
    typeof component === "function" && // can be various things
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (!component?.prototype || !component?.prototype?.isReactComponent)
  );
}

export function isReactComponent(
  component: unknown
): component is React.ComponentClass | React.FC<React.PropsWithChildren> {
  return (
    isReactComponentClass(component) || isReactFunctionalComponent(component)
  );
}

export function isLazyComponent<
  T extends React.FunctionComponent | React.ComponentClass
>(component: unknown): component is React.LazyExoticComponent<T> {
  return (
    isObject(component) &&
    "$$typeof" in component &&
    ((component.$$typeof as UnknownObject).toString as () => string)() ===
      "Symbol(react.lazy)"
  );
}

export function isMemoizedComponent(
  component: unknown
): component is React.NamedExoticComponent {
  return (
    isObject(component) &&
    "$$typeof" in component &&
    ((component.$$typeof as UnknownObject).toString as () => string)() ===
      "Symbol(react.memo)"
  );
}

export const renderRenderable = <P extends {} = {}>(
  R: Renderable<P>,
  props?: P,
  Fallback?: Renderable
) => {
  if (isMemoizedComponent(R)) return <R {...props} />;
  if (isLazyComponent(R))
    return (
      <Suspense
        fallback={
          (renderRenderable(Fallback) ?? (
            <LoadingIndicatorBlock />
          )) as React.ReactNode
        }
        children={<R {...props} />}
      />
    );
  if (isReactComponent(R)) return <R {...props} />;
  if (isFunction(R)) return R(props as P) as React.ReactNode;
  return R as React.ReactNode;
};
