/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import Button from "../../base/components/Button";
import Panel, {
  PanelCloseButton,
  PanelFooter,
  PanelHeader,
  PanelInner,
} from "../../base/components/Panel";
import SelectableItem from "../../base/components/SelectableItem";
import Serif from "../../base/components/Serif";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { formatAsSearchString } from "../../base/utils/search.utils";
import { Artist } from "../../models/Artist.model";
import NewArtistButton from "../controls/NewArtistButton";
import ArtistEntry from "./ArtistEntry";

type ArtistFinderProps = {
  onShouldClose: () => void;
  onSelect: (artists: Artist[]) => void;
  title?: string;
  initialSelection?: Artist[];
};

const EmptyWarning = styled.div`
  margin-top: 0.5em;
  opacity: 0.6;
  font-size: 1.8rem;
  font-weight: 200;
`;
const SearchAndAddNewButton = styled.div`
  display: grid;
  align-items: stretch;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-gap: 0.5em;
`;
const Scrollable = styled.div`
  overflow: auto;
  height: 400px;
  margin: 0.5em -0.5em;
  > * + * {
    margin-top: 0.5em;
  }
`;

const ArtistFinder: React.FC<ArtistFinderProps> = props => {
  const { ARTISTS } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    selectionSet: new Set<Artist>(p.initialSelection),
    selectionToggler: (artist: Artist) =>
      action(() => {
        if (s.selectionSet.has(artist)) s.selectionSet.delete(artist);
        else s.selectionSet.add(artist);
      }),
    confirmSelection: () => {
      p.onSelect(Array.from(s.selectionSet));
      p.onShouldClose();
    },
    query: "",
    get queryFormatted() {
      return formatAsSearchString(s.query);
    },
    get artists() {
      if (!s.queryFormatted) return ARTISTS.ownNonArchived;
      return ARTISTS.ownNonArchived.filter(c =>
        formatAsSearchString(c.fullName).includes(s.queryFormatted)
      );
    },
    handlePanelClose: (newArtist?: Artist) => {
      if (newArtist) s.selectionToggler(newArtist)();
    },
  }));
  return (
    <Observer
      children={() => (
        <Panel
          id="artist-finder"
          className="ArtistFinder"
          title={p.title ?? "Artists"}
          canEscape
          onShouldClose={p.onShouldClose}
          width={500}
          moveable
          fullscreen
        >
          <PanelHeader End={<PanelCloseButton onClick={p.onShouldClose} />}>
            <h2>
              <Serif>{p.title ?? "Artists"}</Serif>
            </h2>
          </PanelHeader>
          <PanelInner>
            <SearchAndAddNewButton>
              <TextInput
                form={s}
                field="query"
                placeholder="Search..."
                icon="search"
                autoComplete="off"
                type="search"
                autoFocus
              />
              <NewArtistButton onShouldClose={s.handlePanelClose} />
            </SearchAndAddNewButton>
            {!s.artists.length && (
              <EmptyWarning>No artists found.</EmptyWarning>
            )}
            <Scrollable>
              {s.artists.map(a => (
                <SelectableItem
                  key={a._id}
                  selectStateChecker={() => s.selectionSet.has(a)}
                  onClick={s.selectionToggler(a)}
                  padding=".5em"
                >
                  <ArtistEntry artist={a} appearance="entry" />
                </SelectableItem>
              ))}
            </Scrollable>
          </PanelInner>
          <PanelFooter sticky>
            <Button onClick={s.confirmSelection} fullWidth>
              Confirm
            </Button>
          </PanelFooter>
        </Panel>
      )}
    />
  );
};

export default ArtistFinder;
