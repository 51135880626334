/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Note } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import DropdownLikeUI from "../../base/components/DropdownLikeUI";
import { UNITS } from "../../base/constants/units.constant";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import cx from "../../base/utils/className.utils";
import { withOpacity } from "../../base/utils/colors.utils";
import { cVar } from "../../base/utils/customProperties.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { runAfter } from "../../base/utils/waiters.utils";
import {
  VAR_PanelBorder,
  bg,
  fg,
  varFontNotation,
  varPrimary,
  varPrimary10,
  varPrimary50,
  varPrimaryContrast,
  varPrimaryContrast20,
} from "../../constants/cssCustomProperties.constants";
import {
  OrnamentationDef,
  OrnamentationType,
} from "../../constants/ornaments.constants";
import { RuleController } from "../../logic/interpreterRule.controller";
import {
  getOrnamentDefByIdentifier,
  ornamentationDefs,
} from "../../logic/ornaments/index.ornamentDefs";
import { Ornamentation_NullDef } from "../../logic/ornaments/null.ornamentDef";
import { changeOrnamentType } from "../../operations/changeOrnamentType.operation";
import InterpretationMark from "../shared/InterpretationMark";
import { useComposer } from "./ComposerApp.context";

type OrnamentSelectorProps = {
  note: Note;
  rule?: RuleController;
  disabled?: boolean;
};

const ornamentSelectorHeaderStyle = css`
  position: relative;
`;

const OrnamentTypeSelector: React.FC<OrnamentSelectorProps> = props => {
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    get context() {
      return p.note.context;
    },
    get value(): OrnamentationType | string {
      return s.ornament?.ornamentationType ?? "";
    },
    set value(v) {
      if (!v || v === Ornamentation_NullDef.identifier) {
        I.runInHistory(
          "Remove ornament",
          action(() => {
            if (!s.ornament) return;
            s.context?.removeAtom(s.ornament);
          })
        );
      } else if (s.ornament && s.value) {
        I.runInHistory(
          "Update ornament",
          action(() => {
            changeOrnamentType(s.ornament!, v as OrnamentationType);
          })
        );
      } else {
        I.runInHistory(
          "Create ornament",
          action(() => {
            const newOrnament = s.context?.createOrnament({
              forNote: p.note,
              rule: p.rule,
              def: getOrnamentDefByIdentifier(v),
            });
            runAfter(() => {
              newOrnament?.select();
            });
          })
        );
      }
    },
    get selectedOrnamentDef(): OrnamentationDef | null {
      if (!s.value) return Ornamentation_NullDef;
      return getOrnamentDefByIdentifier(s.value);
    },
    get ornament() {
      return p.note.ornamentInComp;
    },
    selectOrnamentDef: (def?: OrnamentationDef | null) => {
      if (!def) s.value = "";
      else s.value = def.identifier;
    },
    get optionsAsElements() {
      return ornamentationDefs.map(def => (
        <OrnamentSelectorOption
          key={def.identifier}
          def={def}
          onClick={s.selectOrnamentDef}
          selected={def.identifier === s.value}
          color={p.note.appearance?.colorInContext}
        />
      ));
    },
  }));

  return (
    <Observer
      children={() => (
        <DropdownLikeUI
          Header={
            <div css={ornamentSelectorHeaderStyle} data-disabled={p.disabled}>
              {p.rule && (
                <InterpretationMark color={p.note.appearance?.colorInContext} />
              )}
              <OrnamentSelectorOption
                def={s.selectedOrnamentDef}
                color={p.note.appearance?.colorInContext}
                presentational
              />
            </div>
          }
          children={() => <div>{s.optionsAsElements}</div>}
          disabled={p.disabled}
        />
      )}
    />
  );
};

const optionSymbolContainerStyle: CSSPartial = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRight: "inherit",
  width: "35px",
  height: "30px",
  padding: "10px",
  overflow: "hidden",
  span: {
    fontSize: "2.4rem",
    fontFamily: varFontNotation,
    transform: "translateY(.15em)",
  },
};

const optionLabelContainerStyle: CSSPartial = {
  display: "flex",
  alignItems: "center",
  flex: "1 1 auto",
  padding: ".5em",
};

const OrnamentSelectorOption: React.FC<{
  def: OrnamentationDef | null;
  onClick?: (def?: OrnamentationDef | null) => void;
  selected?: boolean;
  color?: string | null;
  presentational?: boolean;
}> = props => {
  const { THEME } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    handleClick: () => {
      p.onClick?.(p.def);
    },
    get contrastColor() {
      return p.color ? THEME.getContrastColor(p.color) : varPrimaryContrast;
    },
    get contrastColor20() {
      return p.color
        ? withOpacity(THEME.getContrastColor(p.color), 0.2)
        : varPrimaryContrast20;
    },
    get optionStyle(): CSSPartial {
      return {
        display: "flex",
        alignItems: "stretch",
        appearance: "none",
        backgroundColor: bg,
        color: fg,
        width: "100%",
        padding: 0,
        border: cVar(VAR_PanelBorder),
        borderRadius: 3,
        fontSize: "1.2rem",
        ".open &, &:hover": {
          borderColor: p.color ? withOpacity(p.color, 0.5) : varPrimary50,
          backgroundColor: p.color ? withOpacity(p.color, 0.1) : varPrimary10,
        },
        "&.selected": {
          backgroundColor: p.color ?? varPrimary,
          color: s.contrastColor,
          borderColor: s.contrastColor20,
        },
      };
    },
    get inner() {
      return (
        <>
          <div css={optionSymbolContainerStyle}>
            {p.def?.defaultSymbol ? (
              <span>{p.def.defaultSymbol}</span>
            ) : (
              <svg width="18" height="18" viewBox="0 0 18 18">
                <path
                  d="M4 8.5H14"
                  stroke="currentColor"
                  strokeWidth={UNITS.lineWidth}
                />
              </svg>
            )}
          </div>
          <div css={optionLabelContainerStyle}>
            {p.def?.displayName ?? "Unknown"}
          </div>
        </>
      );
    },
  }));
  return (
    <Observer
      children={() =>
        p.presentational ? (
          <div className={cx(p.selected && "selected")} css={s.optionStyle}>
            {s.inner}
          </div>
        ) : (
          <button
            className={cx(p.selected && "selected")}
            css={s.optionStyle}
            onClick={s.handleClick}
            type="button"
          >
            {s.inner}
          </button>
        )
      }
    />
  );
};

export default OrnamentTypeSelector;
