/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { flow, observable, reaction } from "mobx";
import { useOnMount } from "../base/hooks/lifecycle.hooks";
import { getRandomNumericString } from "../base/utils/random.utils";

const debug = false;

// TODO: automatic invalidate after certain duration

export const REQUEST_MAP = new Map<string, RequestManager>();
export const clearRequestMap = () => {
  REQUEST_MAP.forEach(r => r.dispose());
  REQUEST_MAP.clear();
};

export type RequestId =
  | string
  | number
  | (string | number | null | undefined)[];

export type RequestManager<T = any> = {
  readonly id: RequestId;
  data: null | T;
  readonly fetch: () => Promise<T | null>;
  loaded: boolean;
  loading: boolean;
  error: unknown | null;
  numberOfFetchesCompleted: number;
  reset: () => void;
  dispose: () => void;
};

type RequestManagerOptions<T> = {
  shouldInvalidate?: (data?: T | null) => boolean;
  onFirstFetchComplete?: (data?: T | null) => void;
  onComplete?: (data?: T | null) => void;
};

export const makeRequest = <T = any>(
  id: RequestId,
  request: () => Promise<T>,
  options?: RequestManagerOptions<T>
) => {
  const _id = `${id instanceof Array ? id.join("_") : id}`;
  const existingManager = REQUEST_MAP.get(_id);
  if (existingManager) return existingManager as RequestManager<T>;
  let invalidationKey = getRandomNumericString();
  const s: RequestManager<T> = observable({
    get id() {
      return id;
    },
    data: null,
    numberOfFetchesCompleted: 0,
    fetch: async () =>
      await flow(function* () {
        if (s.data || s.loaded) {
          if (debug)
            console.log(
              `Request "${id}" was already fetched; returning existing data`
            );
          return s.data;
        }
        if (debug) console.log(`Fetching request "${id}"...`);
        s.loading = true;
        const invalidationKeyOnStart = invalidationKey;
        try {
          const data = yield request();
          if (invalidationKeyOnStart !== invalidationKey) {
            return null;
          }
          s.data = data;
          s.loaded = true;
          s.numberOfFetchesCompleted++;
          options?.onComplete?.(s.data);
          if (s.numberOfFetchesCompleted === 1) {
            options?.onFirstFetchComplete?.(s.data);
          }
        } catch (e) {
          s.error = e;
          console.error(e);
        } finally {
          s.loading = false;
        }
        return s.data;
      })(),
    loaded: false,
    loading: false,
    error: null,
    reset: () => {
      s.data = null;
      s.loading = false;
      s.error = null;
      invalidationKey = getRandomNumericString();
    },
    dispose: () => {},
  });
  if (options?.shouldInvalidate) {
    s.dispose = reaction(() => options.shouldInvalidate!(s.data), s.reset);
  }
  REQUEST_MAP.set(_id, s);
  return s;
};

export type UseRequestOptions<T> = RequestManagerOptions<T> & {
  fetchOnMount?: boolean;
};
export const useRequest = <T>(
  id: RequestId,
  request: () => Promise<T>,
  options?: UseRequestOptions<T>
) => {
  const s = makeRequest<T>(id, request, options);
  useOnMount(() => {
    if (options?.fetchOnMount !== false) s.fetch();
  });
  return s;
};
