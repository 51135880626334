/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Note, Pattern } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import TextInput from "../../base/components/TextInput";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { cVar } from "../../base/utils/customProperties.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { VAR_PanelBorder } from "../../constants/cssCustomProperties.constants";
import {
  getNumericNotePropertyInPattern,
  setNumericNotePropertyInPattern,
} from "../../utils/note.utils";

type NotePropertyFormRelativeToFrameProps = {
  note: Note;
  pattern: Pattern;
  disabled?: boolean;
};

const style = {
  container: {
    "+ .NotePropertyFormRelativeToPattern": {
      marginTop: ".5em",
      borderTop: cVar(VAR_PanelBorder),
    },
  } as CSSPartial,
  grid: {
    display: "grid",
    gridGap: ".5em",
    gridTemplateColumns: "1fr 1fr 1fr",
    fontSize: "1.2rem",
  } as CSSPartial,
  header: {
    paddingBottom: ".5em",
  } as CSSPartial,
};

const NotePropertyFormRelativeToPattern: React.FC<
  NotePropertyFormRelativeToFrameProps
> = props => {
  const { SETTINGS, ENSEMBLE } = useControllers();
  const p = useProps(props);
  const form = useStore(() => ({
    get startX() {
      return getNumericNotePropertyInPattern("startX", p.note, p.pattern);
    },
    set startX(v) {
      setNumericNotePropertyInPattern("startX", p.note, p.pattern, v);
    },
    get y() {
      return getNumericNotePropertyInPattern("y", p.note, p.pattern);
    },
    set y(v) {
      setNumericNotePropertyInPattern("y", p.note, p.pattern, v);
    },
  }));
  const s = useStore(() => ({
    playNoteOnChange: () => {
      ENSEMBLE.releaseAll();
      ENSEMBLE.playNote(p.note);
    },
  }));
  return (
    <Observer
      children={() => (
        <div
          className="NotePropertyFormRelativeToPattern"
          css={style.container}
        >
          <header css={style.header}>
            <strong>
              {p.note.displayName} in pattern "{p.pattern.displayName}"
            </strong>
          </header>
          <div css={style.grid}>
            <TextInput
              form={form}
              type="number"
              field="startX"
              Label={`X in pattern`}
              step={SETTINGS.composer.snapUnitX}
              disabled={p.disabled}
            />
            <TextInput
              form={form}
              type="number"
              field="y"
              Label={`Y in pattern`}
              onChange={s.playNoteOnChange}
              step="1"
              disabled={p.disabled}
            />
          </div>
        </div>
      )}
    />
  );
};

export default NotePropertyFormRelativeToPattern;
