/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Atom } from "../../@types";
import { useProps } from "../../base/utils/mobx.utils";
import { autoPluralize } from "../../base/utils/string.utils";
import { isPatternAtom } from "../../utils/atoms.utils";

type ReferrerCounterProps = {
  atom: Atom;
};

const ReferrerCounter: React.FC<ReferrerCounterProps> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <>
          replicated{" "}
          {autoPluralize(
            (isPatternAtom(p.atom) ? p.atom.replicas : p.atom.referrers).length,
            "once",
            "0 times",
            "%d times"
          )}
        </>
      )}
    />
  );
};

export default ReferrerCounter;
