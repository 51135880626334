/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { useControllers } from "../../../base/hooks/rootContext.hooks";
import { useProps, useStore } from "../../../base/utils/mobx.utils";
import { ModelName } from "../../../constants/modelName.constants";
import { User } from "../../../models/User.model";

type OwnerLinkProps = {
  userId: string;
};

const OwnerLink: React.FC<OwnerLinkProps> = props => {
  const { LOCALDB } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get user() {
      return LOCALDB.get<User>(ModelName.users, p.userId);
    },
  }));
  return (
    <Observer
      children={() => (
        <Link to={`/admin/users/${p.userId}`}>
          {s.user?.fullName ?? `User ${p.userId}}`}
        </Link>
      )}
    />
  );
};

export default OwnerLink;
