/** @jsxImportSource @emotion/react */
import { observable } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { fg02 } from "../../constants/cssCustomProperties.constants";
import AccessDenied from "../../illustrations/AccessDenied";
import BrokenRecord from "../../illustrations/BrokenRecord";
import {
  isAccessDeniedError,
  isNetworkError,
  isUnauthorizedError,
} from "../utils/errors.utils";
import { useCompleteProgressBar } from "../utils/globalProgressBar.utils";
import { useProps, useStore } from "../utils/mobx.utils";
import Button from "./Button";
import CenteredMessageBox from "./CenteredMessageBox";
import Spacing from "./Spacing";

type ErrorScreenProps = {
  error?: unknown;
  className?: string;
  actionSuggestions?: ReactNode;
  addBackdrop?: boolean;
};

const ErrorScreen: React.FC<ErrorScreenProps> = props => {
  const p = useProps(props, {
    error: observable.ref,
  });
  const s = useStore(() => ({
    get isNetworkError() {
      return isNetworkError(p.error);
    },
    get unauthorized() {
      return isUnauthorizedError(p.error);
    },
    get accessDenied() {
      return isAccessDeniedError(p.error);
    },
    get message() {
      if (s.isNetworkError) {
        return {
          heading: "We are having trouble connecting to the server :(",
          body: "Please check back later, or contact us for support.",
          defaultActionSuggestion: <>{/* todo: retry button */}</>,
        };
      }
      if (s.accessDenied || s.unauthorized) {
        return {
          icon: <AccessDenied />,
          heading: "Restricted access",
          body: "If you are the owner of this composition, please sign in with your account.",
        };
      }
      return {
        heading: "Something went wrong",
        body: "Please check back later, or contact us for support.",
        defaultActionSuggestion: (
          <>
            {/* TODO: retry button */}
            <Button to="/" type="button">
              Go home
            </Button>
          </>
        ),
      };
    },
  }));
  useCompleteProgressBar();
  return (
    <Observer
      children={() => (
        <CenteredMessageBox
          className={p.className}
          css={
            p.addBackdrop
              ? {
                  backgroundColor: fg02,
                  padding: "3em 1.5em",
                  borderRadius: "0.5em",
                }
              : undefined
          }
        >
          {s.message.icon ?? <BrokenRecord />}
          <Spacing size="2em" />
          <h2>{s.message.heading}</h2>
          <Spacing />
          <p>{s.message.body}</p>
          <Spacing size="3em" />
          {p.actionSuggestions ?? s.message.defaultActionSuggestion}
        </CenteredMessageBox>
      )}
    />
  );
};

export default ErrorScreen;
