import { makeIconDef } from "./makeIconDef";

export const InterpretationsIconDef = {
  interpretations: makeIconDef("interpretations", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11.2254V6H11.2254L6 11.2254ZM12.4325 5H5.5C5.22386 5 5 5.22386 5 5.5V12.4325C5 12.878 5.53857 13.1011 5.85355 12.7861L12.7861 5.85355C13.1011 5.53857 12.878 5 12.4325 5ZM14.5007 10.0183V6.5H18.019L14.5007 10.0183ZM14.5007 12.1396L20.1403 6.5L20.1729 6.46742L20.4332 6.20711L20.7868 5.85355C21.1018 5.53857 20.8787 5 20.4332 5H19.9332H19.5651H19.519H13.5007C13.2246 5 13.0007 5.22386 13.0007 5.5V11.5183V11.5644V11.9325V12.4325C13.0007 12.878 13.5393 13.1011 13.8543 12.7861L14.2078 12.4325L14.4681 12.1722L14.5007 12.1396ZM7 15.0007V16.8119L8.81122 15.0007H7ZM10.8112 13.0007H5.5C5.22386 13.0007 5 13.2246 5 13.5007V18.8119V19.0142V19.9332V20.4332C5 20.8787 5.53857 21.1018 5.85355 20.7868L6.20711 20.4332L6.85696 19.7834L7 19.6403L11.6396 15.0007L11.7827 14.8577L12.4325 14.2078L12.7861 13.8543C13.1011 13.5393 12.878 13.0007 12.4325 13.0007H11.9325H11.0135H10.8112ZM13.5007 13.0007H20.4332C20.8787 13.0007 21.1018 13.5393 20.7868 13.8543L13.8543 20.7868C13.5393 21.1018 13.0007 20.8787 13.0007 20.4332V13.5007C13.0007 13.2246 13.2246 13.0007 13.5007 13.0007Z"
        fill="currentColor"
      />
    ),
  }),
};
