import { makeIconDef } from "./makeIconDef";

export const CopyIconDef = {
  copy: makeIconDef("copy", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5H6V16H9V17H6C5.44772 17 5 16.5523 5 16V5C5 4.44772 5.44772 4 6 4H14C14.5523 4 15 4.44772 15 5V7H14V5ZM11 20V9H19V20H11ZM10 9C10 8.44772 10.4477 8 11 8H19C19.5523 8 20 8.44772 20 9V20C20 20.5523 19.5523 21 19 21H11C10.4477 21 10 20.5523 10 20V9Z"
        fill="currentColor"
      />
    ),
  }),
};
