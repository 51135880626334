/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../../base/@types/css.types";
import {
  VAR_DarkerBackgroundWhenDark,
  fg,
  fg10,
} from "../../../constants/cssCustomProperties.constants";
import { flex } from "../../../base/styles/helpers/flex.styleHelper";
import { spaceChildren } from "../../../base/styles/partials/spaceChildren.stylePartials";
import cx from "../../../base/utils/className.utils";
import { useProps, useStore } from "../../../base/utils/mobx.utils";
import BlanchetManualSelectButton from "./BlanchetManualSelectButton";
import { cVar } from "../../../base/utils/customProperties.utils";
import {
  addOneToArrayIfNew,
  removeOneFromArray,
} from "../../../base/utils/array.utils";
import {
  BlanchetHarpsichordSamplerName,
  BlanchetHarpsichordType,
} from "../BlanchetHarpsichord.instrument";

interface BlanchetControlPanelProps {
  className?: string;
  instrument: BlanchetHarpsichordType;
}

export const BlanchetColors = {
  red: "#C9311B",
  // black: "#261E1F",
  // gold: "#E7C4AB",
};

const BlanchetControlPanel: React.FC<BlanchetControlPanelProps> = observer(
  props => {
    const p = useProps(props);
    const s = useStore(() => ({
      get instrument(): BlanchetHarpsichordType {
        return p.instrument;
      },
      toggleManual: (m: BlanchetHarpsichordSamplerName) => {
        p.instrument.releaseAll();
        if (s.activatedSamplers.includes(m))
          removeOneFromArray(s.activatedSamplers, m);
        else addOneToArrayIfNew(s.activatedSamplers, m);
      },
      get activatedSamplers(): BlanchetHarpsichordSamplerName[] {
        return s.instrument.options.activatedSamplers;
      },
      toggleJeu1: () => {
        p.instrument.releaseAll();
        removeOneFromArray(s.activatedSamplers, "jeu2");
        removeOneFromArray(s.activatedSamplers, "lute");
        s.toggleManual("jeu1");
        if (s.activatedSamplers.length === 0) s.toggleJeu2();
      },
      toggleJeu2: () => {
        p.instrument.releaseAll();
        removeOneFromArray(s.activatedSamplers, "jeu1");
        removeOneFromArray(s.activatedSamplers, "lute");
        s.toggleManual("jeu2");
        if (s.activatedSamplers.length === 0) s.toggleJeu1();
      },
      toggleLute: () => {
        p.instrument.releaseAll();
        removeOneFromArray(s.activatedSamplers, "jeu1");
        removeOneFromArray(s.activatedSamplers, "jeu2");
        s.toggleManual("lute");
        if (s.activatedSamplers.length === 0) s.toggleJeu1();
      },
      get bothJeuSelected(): boolean {
        return (
          s.activatedSamplers.includes("jeu1") &&
          s.activatedSamplers.includes("jeu2")
        );
      },
      toggleBothJeus: () => {
        p.instrument.releaseAll();
        if (s.bothJeuSelected) {
          removeOneFromArray(s.activatedSamplers, "jeu1");
          removeOneFromArray(s.activatedSamplers, "jeu2");
          addOneToArrayIfNew(s.activatedSamplers, "lute");
        } else {
          removeOneFromArray(s.activatedSamplers, "lute");
          if (!s.activatedSamplers.includes("jeu1"))
            addOneToArrayIfNew(s.activatedSamplers, "jeu1");
          if (!s.activatedSamplers.includes("jeu2"))
            addOneToArrayIfNew(s.activatedSamplers, "jeu2");
        }
      },
    }));

    const style = useStore(() => ({
      get panel(): CSSPartial {
        return {
          position: "relative",
          ...flex({ direction: "column", align: "start", justify: "start" }),
          padding: "1em .5em 1em 1em",
          ...spaceChildren(".38em"),
          backgroundColor: cVar(VAR_DarkerBackgroundWhenDark),
          transition: "background-color .5s",
          borderRight: `.5px solid ${fg10}`,
          overflow: ["hidden", "clip"],
        };
      },
      get manualButtonSet(): CSSPartial {
        return {
          marginLeft: "-.5em",
        };
      },
      get title(): CSSPartial {
        return {
          fontSize: "1rem",
          fontWeight: 700,
          textTransform: "uppercase",
          textAlign: "left",
          marginBottom: "1em",
          strong: {
            color: BlanchetColors.red,
            fontWeight: "inherit",
          },
        };
      },
      get jeuGroup(): CSSPartial {
        return {
          ...flex({ align: "stretch" }),
          color: s.bothJeuSelected ? BlanchetColors.red : fg,
        };
      },
      get jeuGroupInner(): CSSPartial {
        return {
          ...flex({ direction: "column", align: "stretch" }),
        };
      },
      get jeuLinkButton(): CSSPartial {
        return {
          appearance: "none",
          ...flex({ align: "center" }),
          backgroundColor: "transparent",
          border: "none",
          padding: 0,
          color: "inherit",
          opacity: s.bothJeuSelected ? 1 : 0.5,
          alignSelf: "stretch",
          "&:focus": {
            outline: "none",
          },
        };
      },
      get jeuLinkButtonBracket(): CSSPartial {
        return {
          display: "block",
          width: ".5em",
          borderWidth: "2px 2px 2px 0",
          borderColor: "currentColor",
          borderStyle: "solid",
          height: "calc(100% - 1em)",
        };
      },
      get jeuLinkButtonHorizontalLine(): CSSPartial {
        return {
          display: "block",
          width: ".5em",
          height: 2,
          backgroundColor: "currentColor",
        };
      },
      get jeuLinkButtonKnob(): CSSPartial {
        return {
          display: "block",
          width: ".5em",
          height: "1.38em",
          borderRadius: "1em",
          backgroundColor: "currentColor",
        };
      },
    }));

    return (
      <section
        className={cx("BlanchetControlPanel", p.className)}
        css={style.panel}
      >
        {/* <PlayStateHighlighter gradientOpacityMap={[0.05, 0]} top="50% " /> */}
        <div css={{ position: "relative", zIndex: 1 }}>
          <p css={style.title}>
            Soni Musicae <br />
            <strong>Blanchet 1720</strong>
          </p>

          <div css={style.manualButtonSet}>
            <div css={style.jeuGroup}>
              <div css={style.jeuGroupInner}>
                <BlanchetManualSelectButton
                  manual="jeu1"
                  displayName="Jeu 1"
                  onClick={s.toggleJeu1}
                  activated={s.activatedSamplers.includes("jeu1")}
                />
                <BlanchetManualSelectButton
                  manual="jeu2"
                  displayName="Jeu 2"
                  onClick={s.toggleJeu2}
                  activated={s.activatedSamplers.includes("jeu2")}
                />
              </div>
              <button
                css={style.jeuLinkButton}
                onClick={s.toggleBothJeus}
                type="button"
              >
                <span css={style.jeuLinkButtonBracket}></span>
                <span css={style.jeuLinkButtonHorizontalLine}></span>
                <span css={style.jeuLinkButtonKnob}></span>
              </button>
            </div>
            <BlanchetManualSelectButton
              manual="lute"
              displayName="Lute"
              onClick={s.toggleLute}
              activated={s.activatedSamplers.includes("lute")}
            />
          </div>
        </div>
      </section>
    );
  }
);

export default BlanchetControlPanel;
