import { Voice } from "../@types";
import resolveAfter, { runUntilTruthy } from "../base/utils/waiters.utils";
import type { WorkspaceController } from "../controllers/composer/workspace.controller";
import { IS_EMBEDDED } from "../env";

export const navigateToVoiceManager = async (
  workspace: WorkspaceController,
  voiceId: string
) => {
  workspace.switchTo("voices");
  const getVoiceManagerHeader = () =>
    document.querySelector<HTMLDivElement>(
      `[data-voice-id="${voiceId}"] header`
    );
  await runUntilTruthy(() => !!getVoiceManagerHeader(), { timeout: 1000 });
  getVoiceManagerHeader()?.click();
  await resolveAfter();
  if (IS_EMBEDDED) return;
  const manager = document.querySelector<HTMLDivElement>(
    `[data-voice-id="${voiceId}"]`
  );
  manager?.scrollIntoView();
};

export const deleteVoice = (voice: Voice) => {
  const { context } = voice;
  const I = context?.composer;
  if (!context?.ROOT || !I) return;
  I.runInHistory("Delete voice", () => {
    I.atomContext.deleteVoice(voice);
  });
};
