import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { Artist } from "./Artist.model";
import { Collection } from "./Collection.model";
import { Composition } from "./Composition.model";
import { Interpretation } from "./Interpretation.model";
import { User } from "./User.model";
import { StandardModel } from "./StandardModel";
import { Score } from "./Score.model";
import { FileRecord } from "./FileRecord.model";
import { Package } from "./Package.model";
import { RenderJob } from "./RenderJob.model";
import { LibraryEntry } from "./LibraryEntry.model";

export type StandardModelConstructor<T extends StandardModel> = new (
  LOCALDB: LocalDBController,
  $: T["$"]
) => T;

export type AccessType = "private" | "public";

export const modelConstructorMap = {
  [ModelName.artists]: Artist,
  [ModelName.collections]: Collection,
  [ModelName.compositions]: Composition,
  [ModelName.interpretations]: Interpretation,
  [ModelName.scores]: Score,
  [ModelName.users]: User,
  [ModelName.fileRecords]: FileRecord,
  [ModelName.packages]: Package,
  [ModelName.renderJobs]: RenderJob,
  [ModelName.libraryEntries]: LibraryEntry,
} as const;
