import { when } from "mobx";
import {
  ComponentRegistryEntry,
  RegisterableComponentIdentifiers,
} from "../../controllers/ui.controller";
import { makeDisposerController } from "../utils/disposer.utils";
import getScrollParent from "../utils/scroll.utils";
import { useOnBeforeUnmount, useOnMount } from "./lifecycle.hooks";
import { useControllers } from "./rootContext.hooks";
import { ObservableRef, useObservableRef } from "./useObservableRef.hook";
import { useResizeQuery } from "./useResizeQuery";

export const useCreateResizeQueryWithRef = <
  T extends HTMLElement | SVGElement = HTMLDivElement
>(options?: {
  identifier?: RegisterableComponentIdentifiers;
  defaultWidth?: number;
  delay?: number;
  ref?: ObservableRef<T>;
}) => {
  const { UI } = useControllers();
  const { identifier, defaultWidth, delay } = options || {};
  const ownRef = useObservableRef<T>();
  const ref = options?.ref ?? ownRef;
  const query = useResizeQuery(ref, { defaultWidth, delay });
  const result = { identifier, ref, query };
  if (result.identifier) UI.registerComponent(result as ComponentRegistryEntry);
  useOnMount(() => {
    const d = makeDisposerController();
    d.add(
      when(
        () => !!ref.current,
        () => {
          const scrollParent = getScrollParent(ref.current!);
          if (scrollParent) {
            scrollParent.addEventListener("scroll", query.recalc);
            d.add(() =>
              scrollParent.removeEventListener("scroll", query.recalc)
            );
          }
        }
      )
    );
  });
  useOnBeforeUnmount(() => {
    if (identifier) UI.deregisterComponent(identifier);
  });
  return result;
};
