export enum ClavieristWindowEvent {
  ThemeChange = "THEME_CHANGE",
  PlaybackStarted = "PLAYBACK_STARTED",
  PlaybackPaused = "PLAYBACK_PAUSED",
  VisualizerReady = "VISUALIZER_READY",
  DeleteMessage = "DELETE_PACKAGE",
  Play = "PLAY",
  Pause = "PAUSE",
}

export const postMessageToParentWindow = (
  event: ClavieristWindowEvent,
  payload: Record<string, string | null> = {},
  allowAnyOrigin?: boolean
) => {
  if (window.parent !== window) {
    window.parent.postMessage(
      {
        event,
        payload,
      },
      allowAnyOrigin ? "*" : window.origin
    );
  }
};
