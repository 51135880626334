import { clearArray } from "./array.utils";

export const makeDisposerController = () => {
  const disposers: VoidFunction[] = [];
  const s = {
    disposed: false,
    add: (fn: VoidFunction) => {
      if (s.disposed) fn();
      else disposers.push(fn);
    },
    addMany: (...fns: VoidFunction[]) => fns.forEach(s.add),
    dispose: () => {
      s.disposed = true;
      disposers.forEach(d => d());
      clearArray(disposers);
    },
    reset: () => {
      clearArray(disposers);
      s.disposed = false;
    },
    get contents() {
      return disposers;
    },
  };
  return s;
};

export type DisposerController = ReturnType<typeof makeDisposerController>;
