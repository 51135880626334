import { action } from "mobx";
import { Atom, RemoveAtomsOptions } from "../@types";
import { removeFromArrayById } from "../base/utils/array.utils";
import {
  isBarAtom,
  isGroupLikeAtom,
  isNoteAtom,
  isOrnamentAtom,
  isPatternAtom,
} from "../utils/atoms.utils";
import { detachOrnamentFromOriginalNote } from "../utils/ornament.utils";

const debug = false;

export const removeAtoms = action(
  (atoms: Atom[], debugCaller: string, options?: RemoveAtomsOptions) => {
    if (debug)
      console.info(
        `[${debugCaller}] removing atoms ${atoms.map(a => a._id).join(" ")}`
      );
    if (atoms.length === 0) return;
    const context = atoms[0].context;
    if (!context) return;
    atoms.forEach(
      action(atom => {
        if (
          atom.refAtom &&
          !atom.replica?._isDeleted &&
          !(isNoteAtom(atom) && atom.isInOrnament)
        ) {
          const rule = atom.interpreter?.findOrCreateRuleForAtom(atom);
          if (rule) rule.properties.disabled = true;
        } else {
          atom.markAsDeleted();
          context.interpreter?.deleteRules(atom.ownRules);
        }
        if (isOrnamentAtom(atom)) {
          detachOrnamentFromOriginalNote(atom);
          removeAtomsAndDescendants([atom]);
        } else {
          if (atom._itpId) {
            removeFromArrayById(context.interpretationAtomSnapshots, [atom]);
          } else {
            removeFromArrayById(context.compositionAtomSnapshots, [atom]);
          }
        }
      })
    );
    context.composer?.tools.select.updateSelection({
      debugCaller: "removeAtoms",
      mode: "subtract",
      atoms: atoms,
    });
  }
);

const collectAtomsForDeletion = (atoms: Atom[]) => {
  const toDelete = new Set<Atom>();
  atoms.forEach(atom => {
    toDelete.add(atom);
    if (isGroupLikeAtom(atom)) {
      collectAtomsForDeletion(atom.children).forEach(a => toDelete.add(a));
    }
  });
  return toDelete;
};

export const removeAtomsAndDescendants = action(
  (atoms: Atom[], options?: RemoveAtomsOptions, debugCaller?: string) => {
    if (debug)
      console.info(
        `[${debugCaller}] removing atoms and its descendants ${atoms
          .map(a => a._id)
          .join(" ")}`
      );
    if (atoms.length === 0) return;
    const context = atoms[0].context;
    if (!context) return;
    const toDelete = collectAtomsForDeletion(atoms);
    // console.log("removeAtomsAndDescendants.toDelete", toDelete);
    // TODO: bars need to be dealt with differently
    toDelete.forEach(
      action(atom => {
        if (isBarAtom(atom)) {
          context.deleteBar(atom, options);
          return;
        }
        if (
          atom.replica &&
          !toDelete.has(atom.replica) &&
          atom.refAtom &&
          !toDelete.has(atom.refAtom)
        ) {
          const rule = atom.interpreter?.findOrCreateRuleForAtom(atom);
          if (rule) rule.properties.disabled = true;
          return;
        }
        if (isPatternAtom(atom)) {
          atom.replicas
            .filter(r => !toDelete.has(r))
            .forEach(context.convertToGroup);
        }
        context.interpreter?.deleteRules(atom.ownRules);
        if (isOrnamentAtom(atom)) {
          detachOrnamentFromOriginalNote(atom);
        }
        if (atom._itpId) {
          removeFromArrayById(context.interpretationAtomSnapshots, [atom]);
        } else {
          removeFromArrayById(context.compositionAtomSnapshots, [atom]);
        }
        atom.markAsDeleted();
      })
    );
    context.composer?.tools.select.updateSelection({
      debugCaller: "removeAtomsAndDescendants",
      mode: "subtract",
      atoms: atoms,
    });
  }
);
