import { makeIconDef } from "./makeIconDef";

export const RedoIconDef = {
  redo: makeIconDef("redo", {
    regular: () => (
      <path
        d="M17.4038 8.59619L11.6545 9.1244L14.0717 3.88127L17.4038 8.59619ZM8.56498 17.435C10.9081 19.7782 14.7071 19.7782 17.0503 17.435L17.7574 18.1421C15.0237 20.8758 10.5915 20.8758 7.85787 18.1421L8.56498 17.435ZM13.2785 7.21077C11.589 7.07864 9.85625 7.65847 8.56498 8.94975L7.85787 8.24264C9.36455 6.73597 11.3879 6.05986 13.3564 6.21381L13.2785 7.21077ZM8.56498 8.94975C6.22183 11.2929 6.22183 15.0919 8.56498 17.435L7.85787 18.1421C5.1242 15.4085 5.1242 10.9763 7.85787 8.24264L8.56498 8.94975Z"
        fill="currentColor"
      />
    ),
  }),
};
