/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Note } from "../../@types";
import { ResetIcon } from "../../base/components/icons/Reset.icon";
import { cVar } from "../../base/utils/customProperties.utils";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import {
  VAR_PanelBorder,
  varPrimary,
  varPrimary10,
} from "../../constants/cssCustomProperties.constants";
import { RuleController } from "../../logic/interpreterRule.controller";
import { resetOrnamentOverrideOfNote } from "../../operations/resetOrnamentOverrideOfNote.operation";
import { useComposer } from "./ComposerApp.context";
import OrnamentTypeSelector from "./OrnamentTypeSelector";

type OrnamentEditorProps = {
  note: Note;
  rule?: RuleController;
  disabled?: boolean;
};

const Wrap = styled.div`
  display: flex;
  align-items: stretch;
  > *:first-of-type {
    flex: 1 1 100%;
  }
`;

const ResetButton = styled.button`
  appearance: none;
  background-color: transparent;
  color: inherit;
  padding: 0 1em;
  border: ${cVar(VAR_PanelBorder)};
  margin-left: 0.5em;
  flex: 0 0 auto;
  border-radius: 3px;
  &:hover {
    background-color: ${varPrimary10};
    color: ${varPrimary};
  }
`;

const OrnamentEditor: React.FC<OrnamentEditorProps> = props => {
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    reset: () => {
      I.runInHistory("Reset ornament override", () => {
        resetOrnamentOverrideOfNote(p.note);
      });
    },
  }));
  return (
    <Observer
      children={() => (
        <Wrap>
          <OrnamentTypeSelector
            note={p.note}
            rule={p.rule}
            disabled={p.disabled}
          />
          {p.note.refAtom && p.note.disableOrnamentReplicaSync && (
            <ResetButton onClick={s.reset} disabled={p.disabled}>
              <ResetIcon />
            </ResetButton>
          )}
        </Wrap>
      )}
    />
  );
};

export default OrnamentEditor;
