import { flow, observable, reaction, runInAction, when } from "mobx";
import { Atom, Note } from "../../@types";
import {
  AttackReleaseParams,
  CompositeSamplerConfig,
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  InstrumentSnapshot,
  InstrumentStatus,
  StandardInstrumentOptions,
  ValueGetterSet,
} from "../../@types/instruments.types";
import { makeDisposerController } from "../../base/utils/disposer.utils";
import { getRandomNumericString } from "../../base/utils/random.utils";
import { isNumber } from "../../base/utils/typeChecks.utils";
import { EnsembleController } from "../../controllers/ensemble.controller";
import { noteIsInRange } from "../../utils/instrument.utils";
import {
  InstrumentPrivateStore,
  makeInstrumentPrivateStore,
} from "./instrumentPrivateStore";
import { removeOneFromArray } from "../../base/utils/array.utils";
import { observeChangesToArray } from "../../base/utils/observeChanges.util";
import { round } from "lodash-es";
import { getNoteAttackParams } from "../../utils/playback.utils";
import resolveAfter, { runAfter } from "../../base/utils/waiters.utils";

interface SamplerInstrumentFactoryOptions<
  O extends StandardInstrumentOptions = StandardInstrumentOptions
> {
  defaultOptionsBagBuilder: () => O;
  instrumentMeta: InstrumentMeta<O>;
  compositeSamplerConfigArray: CompositeSamplerConfig[];
  getters?: ValueGetterSet<O>;
}

/**
 * CompositeSamplerInstrumentFactory
 * */

export function createCompositeSamplerInstrumentFactory<
  O extends StandardInstrumentOptions = StandardInstrumentOptions
>({
  defaultOptionsBagBuilder,
  instrumentMeta,
  compositeSamplerConfigArray,
  getters,
}: SamplerInstrumentFactoryOptions<O>) {
  const compositeSamplerConfigMap = new Map(
    compositeSamplerConfigArray.map(c => [c.name, c])
  );
  return (
    ENSEMBLE: EnsembleController,
    snapshot: InstrumentSnapshot<O>
  ): Instrument<O> => {
    runInAction(() => {
      snapshot.options = Object.assign(
        { ...defaultOptionsBagBuilder() },
        snapshot.options
      );
      snapshot.options.activatedSamplers =
        snapshot.options.activatedSamplers?.filter(samplerName =>
          compositeSamplerConfigArray.find(
            config => config.name === samplerName
          )
        );
    });
    const d = makeDisposerController();
    const _ = makeInstrumentPrivateStore(
      ENSEMBLE,
      instrumentMeta,
      snapshot,
      compositeSamplerConfigMap,
      getters
    );
    const s = observable({
      get _id() {
        return snapshot._id ?? getRandomNumericString();
      },
      get isLoading(): boolean {
        return _.samplers.array.some(s => s.isLoading);
      },
      get loadPercentage(): number {
        return round(
          (_.samplers.array.filter(s => s.isLoaded).length /
            _.samplers.map.size) *
            100
        );
      },
      get initiated() {
        return _.samplers.array.every(s => s.isLoaded);
      },
      status: InstrumentStatus.disconnected,
      disposed: false,
      meta: instrumentMeta,
      get $() {
        return snapshot;
      },
      $patch: ($: InstrumentSnapshot<O>) => {
        Object.assign(s.$, $);
        return s;
      },
      get options() {
        return _.options;
      },
      get nickName(): string {
        return s.options.nickName || s.meta.displayName || s.meta.name;
      },
      appearance: {
        get color(): string {
          return s.options.color || s.meta.defaultColor || "";
        },
        set color(value: string) {
          s.options.color = value;
        },
      },
      get range(): InstrumentRange {
        return s.meta.range;
      },
      attack: (
        which: string | number,
        after: number | undefined,
        options?: AttackReleaseParams
      ) => {
        try {
          _.attackAllOutputs(which, after, options);
          if (!_.shouldSendToLocal) return;
          const frequency = _.getFrequency(which, options?.inputType);
          if (!frequency) return;
          const when = after ?? _.now();
          _.activeNotes.push(frequency);
          const velocity =
            options?.velocity ??
            (isNumber(_.getters.velocity.attack)
              ? _.getters.velocity.attack
              : _.getters.velocity.attack(options?.note, s.options));
          (_.samplers.hasMultipleSampledVelocities
            ? _.getSamplersByVelocity(velocity)
            : _.getSamplersForNote(options?.note)
          ).forEach(cs => {
            cs.attack({
              frequency,
              when,
              velocity: _.samplers.hasMultipleSampledVelocities ? 1 : velocity,
            });
          });
        } catch (e) {
          console.error(e);
        }
      },
      release: async (
        which: string | number,
        after?: number,
        options?: AttackReleaseParams
      ) => {
        const frequency = _.getFrequency(which, options?.inputType);
        if (!frequency) return;
        removeOneFromArray(_.activeNotes, frequency);
        await when(() => s.sustainPedalIsDown === false);
        runInAction(() => {
          const at = after ?? _.now();
          _.releaseAllOutputs(which, after, options);
          if (!_.shouldSendToLocal) return;
          const velocity =
            options?.velocity ??
            (isNumber(_.getters.velocity?.release)
              ? _.getters.velocity.release
              : _.getters.velocity?.release?.(options?.note, s.options));
          (_.samplers.hasMultipleSampledVelocities
            ? _.samplers.array
            : _.getSamplersForNote(options?.note)
          ).forEach(cs => {
            cs.release({
              frequency,
              when: at,
              velocity,
            });
          });
        });
      },
      releaseAll: () => {
        _.samplers.map.forEach(cs => {
          cs.samplers.sustain?.releaseAll();
          cs.samplers.release?.releaseAll();
        });
        _.activeNotes.length = 0;
        ENSEMBLE.notesOn.forEach(n => {
          if (n.midiNumber) _.releaseAllOutputs(n.midiNumber);
        });
        _.shouldSustain = false;
      },

      getNoteAttackParams: (n: Note) =>
        getNoteAttackParams(n, _ as unknown as InstrumentPrivateStore),

      attackNote: (n: Note, options?: AttackReleaseParams) => {
        const params = s.getNoteAttackParams(n);
        if (!params) return;
        const { midiNumber, frequency, beatCount, velocity } = params;
        const shouldPlay =
          n.interpreted.notesStartingAtTheSameTimeWithSamePitchAndInstrument
            .length === 0 ||
          n.interpreted
            .notesStartingAtTheSameTimeWithSamePitchAndInstrumentIncludingSelf[0]
            ._id === n._id;
        if (!midiNumber || !beatCount || beatCount <= 0) return false;
        if (!frequency) return false;
        const at = options?.time ?? _.now();
        if (shouldPlay) {
          if (_.shouldSendToLocal) {
            const samplers = _.getSamplersForNote(n);
            samplers.forEach(cs => {
              cs.attack({ frequency, when: at, velocity });
            });
          }
          // console.log(`${n._id} velocity`, velocity);
          _.attackAllOutputs(midiNumber, at, {
            velocity,
          });
          runAfter(() => {
            _.activeNotes.push(frequency);
          }, at);
          return {
            frequency,
            midiNumber,
          };
        } else {
          return false;
        }
      },

      playNote: flow(function* (n: Note, options?: AttackReleaseParams) {
        s.attackNote(n, options);
        yield resolveAfter(n.interpreted.adjustedDuration * 1000);
        s.releaseNote(n);
      }),

      releaseNote: async (n: Note) => {
        const params = s.getNoteAttackParams(n);
        if (!params) return;
        const { midiNumber, frequency } = params;
        const at = _.now();
        const velocity = isNumber(_.getters.velocity.release)
          ? _.getters.velocity.release
          : _.getters.velocity.release(n);
        if (s.sustainPedalIsDown) await when(() => !s.sustainPedalIsDown);
        if (_.shouldSendToLocal) {
          _.getSamplersForNote(n).forEach(
            flow(function* (cs) {
              cs?.release({
                frequency,
                when: at,
                velocity,
              });
            })
          );
        }
        _.releaseAllOutputs(midiNumber, at, {
          velocity,
        });
      },

      sustainPedalDown: () => {
        _.shouldSustain = true;
      },
      sustainPedalUp: () => {
        _.shouldSustain = false;
      },
      get sustainPedalIsDown() {
        return !!(instrumentMeta.supportsSustainPedal && _.shouldSustain);
      },

      midiNumberIsInRange: (n: number) => {
        return noteIsInRange(n, s.range);
      },

      canPlay: (n: Atom | string | number) => {
        return true;
      },
      get supportsMasterTuning() {
        return true;
      },
      get supportsVelocity() {
        return (
          s.meta.hasSampledVelocity ??
          typeof getters?.velocity?.attack === "function"
        );
      },

      init: async () => {
        if (s.isLoading || s.initiated) return;
        const progress = ENSEMBLE.ROOT?.STATUS.registerProgress(
          `Loading ${s.meta.displayName}...`
        );
        d.add(
          reaction(
            () => s.loadPercentage,
            () => {
              progress?.updateMessage(
                `Loading ${s.meta.displayName} (${s.loadPercentage}%)...`
              );
            }
          )
        );
        d.add(
          when(
            () => s.hasErrors,
            () => progress?.complete()
          )
        );
        try {
          if (s.options.activatedSamplers) {
            d.add(
              reaction(
                () => s.options.activatedSamplers!.join(","),
                () => {
                  s.options.activatedSamplers!.forEach(async name => {
                    const sampler = _.samplers.get(name);
                    if (!sampler) return;
                    if (!sampler.isLoaded && !sampler.isLoading) {
                      await sampler.load();
                    }
                  });
                },
                { fireImmediately: true }
              )
            );
            await when(
              () =>
                !!s.options.activatedSamplers
                  ?.map(_.samplers.get)
                  .every(s => s && !s.isLoading && s.isLoaded)
            );
            d.add(
              observeChangesToArray(s.options.activatedSamplers, {
                splice: (added, removed) => {
                  const actuallyRemoved = removed.filter(
                    r => !added.includes(r)
                  );
                  if (actuallyRemoved.length > 0) s.releaseAll();
                },
              })
            );
          } else {
            await Promise.all(_.samplers.array.map(s => s.load()));
            await when(() =>
              _.samplers.array.every(s => s && !s.isLoading && s.isLoaded)
            );
          }
          d.add(
            reaction(
              () => s.options.volumeAdjustment,
              () => {
                _.samplers.map.forEach(cs => {
                  cs.adjustVolume(s.options.volumeAdjustment);
                });
              },
              { fireImmediately: true }
            )
          );
          if (s.disposed) return;
          runInAction(() => {
            s.status = InstrumentStatus.connected;
          });
        } catch (e) {
          throw e;
        } finally {
          progress?.complete();
        }
      },

      disconnect: () => {
        s.releaseAll();
      },

      get errors() {
        return _.samplers.array.map(s => s.errors).flat();
      },
      get hasErrors() {
        return s.errors.length > 0;
      },
      dispose: () => {
        s.disposed = true;
        s.disconnect();
        _.dispose();
        d.dispose();
      },
    });

    return s;
  };
}
