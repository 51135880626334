import { Point, Rect, ValidRect } from "../../base/@types/geometry.types";
import {
  formatRect,
  isPoint,
  isValidRect,
} from "../../base/utils/geometry.utils";
import { round } from "../../base/utils/math.utils";
import { makePoint } from "./makePoint.model";

export const makeValidRectWithCoords = (
  a: number,
  b: number,
  c: number,
  d: number
) => {
  return [
    { x: a, y: b },
    { x: c, y: d },
  ] as ValidRect;
};
export const makeRect = (
  a: Point | number | null,
  b: Point | number | null,
  c?: number | null,
  d?: number | null
): Rect => {
  let rect: Rect;
  const error = Error(
    `Failed to construct Rectangle from given params: ${[a, b, c, d].join(
      ", "
    )}`
  );
  if (isPoint(a)) {
    if (isPoint(b)) rect = [a, b];
    else throw error;
  } else {
    if (isPoint(b)) throw error;
    else rect = [makePoint(a, b), makePoint(c, d)] as Rect;
  }
  if (isValidRect(rect)) return formatRect(rect);
  return rect;
};

export const printRect = (r: Rect) =>
  `[${[r[0].x, r[0].y, r[1].x, r[1].y].map(i => round(i, 3)).join(" ")}]`;
