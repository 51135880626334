import { makeIconDef } from "./makeIconDef";

export const SparklesIconDef = {
  sparkles: makeIconDef("sparkles", {
    regular: () => (
      <>
        <path
          d="M15.5 3C15.5 8.2381 14.9091 11.5 9 11.5C14.9091 11.5 15.5 16.8095 15.5 23C15.5 16.8095 16.0909 11.5 22 11.5C16.0909 11.5 15.5 8.2381 15.5 3Z"
          stroke="currentColor"
        />
        <path
          d="M6 3C6 5.35714 5.72727 7.07143 3 7.07143C5.72727 7.07143 6 9.21429 6 12C6 9.21429 6.27273 7.07143 9 7.07143C6.27273 7.07143 6 5.35714 6 3Z"
          fill="currentColor"
        />
        <path
          d="M8.5 14C8.5 16.0952 8.18182 17.619 5 17.619C8.18182 17.619 8.5 19.5238 8.5 22C8.5 19.5238 8.81818 17.619 12 17.619C8.81818 17.619 8.5 16.0952 8.5 14Z"
          fill="currentColor"
        />
      </>
    ),
    // filled: () => (
    //   <>
    //     <path
    //       d="M15.5 3C15.5 8.2381 14.9091 11.5 9 11.5C14.9091 11.5 15.5 16.8095 15.5 23C15.5 16.8095 16.0909 11.5 22 11.5C16.0909 11.5 15.5 8.2381 15.5 3Z"
    //       stroke="currentColor"
    //       fill="currentColor"
    //     />
    //     <path
    //       d="M6 3C6 5.09524 5.72727 6.61905 3 6.61905C5.72727 6.61905 6 8.52381 6 11C6 8.52381 6.27273 6.61905 9 6.61905C6.27273 6.61905 6 5.09524 6 3Z"
    //       stroke="currentColor"
    //       fill="currentColor"
    //     />
    //     <path
    //       d="M8.5 14C8.5 16.0952 8.18182 17.619 5 17.619C8.18182 17.619 8.5 19.5238 8.5 22C8.5 19.5238 8.81818 17.619 12 17.619C8.81818 17.619 8.5 16.0952 8.5 14Z"
    //       stroke="currentColor"
    //       fill="currentColor"
    //     />
    //   </>
    // ),
  }),
};
