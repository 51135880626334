import { makeIconDef } from "./makeIconDef";

export const SpeakerIconDefs = {
  speaker: makeIconDef("speaker", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0969 10H6V16H10.1463C10.604 16 11.0479 16.157 11.4038 16.4449L16 20.1616V5.09419L11.4839 9.44097C11.1112 9.79964 10.6141 10 10.0969 10ZM6 9H10.0969C10.3555 9 10.6041 8.89982 10.7904 8.72049L15.3065 4.3737C15.9418 3.76225 17 4.21246 17 5.09419V20.1616C17 21.0025 16.0251 21.4679 15.3712 20.9392L10.7751 17.2224C10.5971 17.0785 10.3751 17 10.1463 17H6C5.44772 17 5 16.5523 5 16V10C5 9.44772 5.44772 9 6 9Z"
        fill="currentColor"
      />
    ),
  }),
  "speaker-muted": makeIconDef("speaker-muted", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 14.5858L21.0607 18.6464C21.2559 18.8417 21.2559 19.1583 21.0607 19.3536C20.8654 19.5488 20.5488 19.5488 20.3536 19.3536L6.64645 5.64643C6.45118 5.45117 6.45118 5.13458 6.64645 4.93932C6.84171 4.74406 7.15829 4.74406 7.35355 4.93932L10.9658 8.55161L15.3065 4.37369C15.9418 3.76224 17 4.21245 17 5.09417V14.5858ZM16 13.5858V5.09417L11.6731 9.25885L16 13.5858ZM6 9.99999H9.69289L8.69289 8.99999H6C5.44772 8.99999 5 9.4477 5 9.99999V16C5 16.5523 5.44772 17 6 17H10.1463C10.3751 17 10.5971 17.0785 10.7751 17.2224L15.3712 20.9391C16.0251 21.4679 17 21.0025 17 20.1616V17.3071L16 16.3071V20.1616L11.4038 16.4448C11.0479 16.157 10.604 16 10.1463 16H6V9.99999Z"
        fill="currentColor"
      />
    ),
  }),
  "speaker-playing": makeIconDef("speaker-playing", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10H10.0969C10.6141 10 11.1112 9.79964 11.4839 9.44098L16 5.09419V20.1616L11.4038 16.4449C11.0479 16.157 10.604 16 10.1463 16H6V10ZM10.0969 9.00001H6C5.44772 9.00001 5 9.44772 5 10V16C5 16.5523 5.44772 17 6 17H10.1463C10.3751 17 10.5971 17.0785 10.7751 17.2224L15.3712 20.9392C16.0251 21.4679 17 21.0025 17 20.1616V5.09419C17 4.21247 15.9418 3.76226 15.3065 4.37371L10.7904 8.72049C10.6041 8.89983 10.3555 9.00001 10.0969 9.00001ZM20 8.99999C19.8047 8.80473 19.4882 8.80473 19.2929 9C19.0976 9.19526 19.0976 9.51184 19.2929 9.7071C19.7253 10.1395 20.0683 10.6529 20.3024 11.2179C20.5364 11.7829 20.6569 12.3885 20.6569 13C20.6569 13.6115 20.5364 14.2171 20.3024 14.7821C20.0683 15.3471 19.7253 15.8605 19.2929 16.2929C19.0976 16.4882 19.0976 16.8047 19.2929 17C19.4882 17.1953 19.8047 17.1953 20 17C20.5253 16.4747 20.942 15.8511 21.2263 15.1648C21.5105 14.4785 21.6569 13.7429 21.6569 13C21.6569 12.2571 21.5105 11.5215 21.2263 10.8352C20.942 10.1489 20.5253 9.52528 20 8.99999Z"
        fill="currentColor"
      />
    ),
  }),
};
