/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import {
  varPrimary,
  varPrimary05,
} from "../../constants/cssCustomProperties.constants";
import { CSSPartial } from "../@types/css.types";
import { useProps, useStore } from "../utils/mobx.utils";

type SelectableItemProps = {
  className?: string;
  selectStateChecker: () => boolean;
  onClick?: () => void;
  onDoubleClick?: () => void;
  borderRadius?: string | number;
  padding?: string | number;
};

const selectionBackdropStyle: CSSPartial = {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: varPrimary05,
};
const selectionBoxStyle: CSSPartial = {
  border: `2px solid ${varPrimary}`,
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

const SelectableItem: React.FC<
  PropsWithChildren<SelectableItemProps>
> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get isSelected() {
      return p.selectStateChecker();
    },
  }));
  return (
    <Observer
      children={() => (
        <div
          className={p.className}
          css={{
            position: "relative",
            padding: p.padding,
          }}
          onClick={p.onClick}
          onDoubleClick={p.onDoubleClick}
        >
          {s.isSelected && (
            <div
              css={selectionBackdropStyle}
              style={{ borderRadius: p.borderRadius }}
            ></div>
          )}
          {p.children}
          {s.isSelected && (
            <div
              css={selectionBoxStyle}
              style={{ borderRadius: p.borderRadius }}
            ></div>
          )}
        </div>
      )}
    />
  );
};

export default SelectableItem;
