import { computed, makeObservable } from "mobx";
import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { hasHumanName } from "../traits/hasHumanName";
import { has_id } from "../traits/hasId.trait";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { StandardModel, appendMissingKeys } from "./StandardModel";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { Composition } from "./Composition.model";
import { joinTruthyWithSpace } from "../base/utils/array.utils";

export type UserSnapshot = ReturnType<typeof UserSnapshotFactory>;

export const makeUserPreferenceObject = () => ({
  color: "" as string | undefined,
  automaticallyUseSupportedMidiOutputAsDefaultInstrument: false,
  doNotShowAutoSaveTip: false,
});

export const defaultUserPreferences = makeUserPreferenceObject();
export type UserPreferenceObject = ReturnType<typeof makeUserPreferenceObject>;

export const makeUserLegalStates = () => ({
  hasAcceptedContentGuidelines: false,
  hasAcceptedUserAgreement: false,
});
export type UserLegalStates = ReturnType<typeof makeUserLegalStates>;

export const UserSnapshotFactory = () => ({
  ...has_id(),
  username: "",
  email: "",
  ...hasHumanName(),
  preferences: makeUserPreferenceObject(),
  profileImageId: null as string | null,
  roles: [] as string[],
  lastKnownIP: null as string | null,
  legal: makeUserLegalStates() as Partial<UserLegalStates> | undefined,
  ...hasTimestamps(),
});

const defaultSnapshot = UserSnapshotFactory();

type Role = {
  _id: string;
  name: string;
  nameTitleCase: string;
};

const rolesMap: Record<string, Role> = {
  "60bb60e3391eca6109e99876": {
    _id: "60bb60e3391eca6109e99876",
    name: "user",
    nameTitleCase: "User",
  },
  "60bb60e3391eca6109e99877": {
    _id: "60bb60e3391eca6109e99877",
    name: "admin",
    nameTitleCase: "Admin",
  },
};

export class User extends StandardModel<ModelName.users, UserSnapshot> {
  constructor(LOCALDB: LocalDBController, $ = UserSnapshotFactory()) {
    appendMissingKeys($, defaultSnapshot);
    super(ModelName.users, LOCALDB, $);
    makeObservable(this, {
      fullName: computed,
      roles: computed,
      compositions: computed,
      profileImage: computed,
    });
  }
  get fullName() {
    return (
      joinTruthyWithSpace(
        this.$.givenName,
        this.$.middleName,
        this.$.surname
      ) || "[No name]"
    );
  }
  get roles() {
    return this.$.roles.map(r => rolesMap[r]).filter(i => i);
  }
  get compositions(): Composition[] {
    return (
      this.ROOT?.COMPOSITIONS.all.filter(comp => comp.$.ownerId === this._id) ??
      []
    );
  }
  get profileImage() {
    return this.LOCALDB.getFileRecord(this.$.profileImageId);
  }
}

export const makeUserSnapshot = (partial: Partial<UserSnapshot>) =>
  makeSnapshot(UserSnapshotFactory, partial);
