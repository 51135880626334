import { observable } from "mobx";
import {
  AtomExtendedPropertiesFactory,
  AtomType,
  Bar,
  BarSnapshot,
  TimeSignature,
} from "../../@types";
import { makeTimeSignature44 } from "../../constants/timeSignatures.constants";
import {
  createAtomFactory,
  makeAtomBaseSnapshot,
} from "../../logic/Atom.factory";
import {
  getInterpretedBpmOfAtom,
  getInterpretedBpxOfAtom,
  getInterpretedDurationInSecondsOfAtom,
  getInterpretedTimeEndInSecondsOfAtom,
  getInterpretedTimeStartInSecondsOfAtom,
  getInterpretedXpmOfAtom,
  isNoteAtom,
} from "../../utils/atoms.utils";
import { convertAbstractXToTimeFromStart } from "../../utils/beats.utils";
import { setupBarScheduler } from "../../utils/playback.utils";

export const BarSnapshotFactory = () => ({
  ...makeAtomBaseSnapshot(AtomType.bar),
  barIndex: 0,
  barNumber: null as null | number,
  timeSignature: null as null | TimeSignature,
});

export const makeBarExtendedProperties = (B: Bar, $: BarSnapshot) => ({
  // managed by reaction in each note model
  atoms: [],
  get displayName() {
    return `Bar No. ${B.barNumber}`;
  },
  set displayName(v) {
    B.name = `${v}`;
  },
  get beats() {
    return B.timeSignature?.[0] ?? 4;
  },
  set beats(v: number) {
    if (!B.$.timeSignature) {
      B.$.timeSignature = makeTimeSignature44();
    }
    B.timeSignature[0] = v;
  },
  get barNumber() {
    return (
      B.$.barNumber ??
      (B.isStartOfSection ? 1 : B.prevBar ? B.prevBar.barNumber + 1 : 1)
    );
  },
  set barNumber(v) {
    B.$.barNumber = v;
  },
  get prevBar() {
    return B.context?.bars.find(b => b.barIndex === $.barIndex - 1);
  },
  get nextBar() {
    return B.context?.bars.find(b => b.barIndex === $.barIndex + 1);
  },
  get x() {
    return B.prevBar?.endX ?? 0;
  },
  get endX() {
    return (B.startX || 0) + (B.width || 0);
  },
  get width() {
    return B.beats * B.beatWidth;
  },
  get timeSignature() {
    return (
      B.$.timeSignature ??
      ((B.prevBar?.timeSignature
        ? [...B.prevBar.timeSignature]
        : B.context?.timeSignature
        ? [...B.context?.timeSignature]
        : makeTimeSignature44()) as TimeSignature)
    );
  },
  set timeSignature(v) {
    B.$.timeSignature = v;
  },
  get beatWidth() {
    return 4 / B.timeSignature[1];
  },
  get alternatives() {
    return B.context?.bars.filter(b => b.barNumber === B.barNumber) ?? [];
  },
  get isStartOfSection() {
    return B.context?.sections.find(s => s.definedStartingBar === B) ?? null;
  },
  get y() {
    return -Infinity;
  },
  get y2() {
    return Infinity;
  },
  get height() {
    return Infinity;
  },
  get startY() {
    return -Infinity;
  },
  get endY() {
    return Infinity;
  },
  get beatCount() {
    return B.width || 0;
  },
  set beatCount(x: number) {
    B.width = x;
  },

  get notes() {
    return B.atoms.filter(isNoteAtom);
  },
  get atomsStartingInThisBar() {
    return B.x
      ? B.atoms.filter(n => n.x !== null && n.x >= B.x && n.x < B.endX)
      : [];
  },
  get topLevelAtoms() {
    return B.atoms.filter(a => !a.parents.length);
  },
  get topLevelAtomsStartingInThisBar() {
    return B.atomsStartingInThisBar.filter(a => !a.parents.length);
  },

  get bpm() {
    return B.context?.interpretation?.options.bpm;
  },
  get timeStartInSeconds() {
    return convertAbstractXToTimeFromStart(B.x, B.interpreted.bpm);
  },
  get timeEndInSeconds() {
    return convertAbstractXToTimeFromStart(B.endX, B.interpreted.bpm);
  },
  get durationInSeconds() {
    if (B.timeStartInSeconds === null || B.timeEndInSeconds === null) return 0;
    return B.timeEndInSeconds - B.timeStartInSeconds;
  },
  get parents() {
    return [];
  },
  get parentIds() {
    return [];
  },
  get bars() {
    return [B];
  },
  interpreted: observable({
    // interpretation rules never affects startX of bars
    get startX() {
      return B.startX;
    },
    // interpretation rules never affects endX of bars
    get endX() {
      return B.endX;
    },
    // interpretation rules never affects width of bars
    get width() {
      return B.width;
    },
    get timeStartInSeconds() {
      return getInterpretedTimeStartInSecondsOfAtom(B);
    },
    get timeEndInSeconds() {
      return getInterpretedTimeEndInSecondsOfAtom(B);
    },
    get durationInSeconds() {
      return getInterpretedDurationInSecondsOfAtom(B);
    },
    get bpm() {
      return getInterpretedBpmOfAtom(B);
    },
    get bpx() {
      return getInterpretedBpxOfAtom(B);
    },
    get xpm() {
      return getInterpretedXpmOfAtom(B);
    },
  }),
});

export const makeBar = createAtomFactory<Bar>({
  type: AtomType.bar,
  snapshotFactory: BarSnapshotFactory,
  extendedPropertiesFactories: [
    makeBarExtendedProperties as unknown as AtomExtendedPropertiesFactory<Bar>,
  ],
  init: bar => {
    return setupBarScheduler(bar);
  },
});
