/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import {
  VAR_InputBackground,
  VAR_InputBorder,
  VAR_InputFocusOutline,
  VAR_InputForeground,
  fg50,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useProps, useStore, useStyle } from "../../base/utils/mobx.utils";
import { Collection } from "../../models/Collection.model";
import { Composition } from "../../models/Composition.model";
import { cVar } from "../../base/utils/customProperties.utils";
import CollectionFinder from "../directory/CollectionFinder";
import {
  addOneToArrayIfNew,
  removeOneFromArray,
} from "../../base/utils/array.utils";

export type CompositionCollectionsSelectorProps = {
  composition: Composition;
  disabled?: boolean;
};

const CompositionCollectionsSelector = (
  props: React.PropsWithChildren<CompositionCollectionsSelectorProps>
) => {
  const p = useProps(props);
  const { COLLECTIONS, PORTALS } = useControllers();
  const s = useStore(() => ({
    get allCollections() {
      return COLLECTIONS.allNonArchived;
    },
    get selectedCollections() {
      return p.composition.collections;
    },
    get textLabel(): string {
      return s.selectedCollections.map(a => a.$.name).join(", ");
    },
    get placeholder(): string {
      return "Please select...";
    },
    shouldRenderSelector: false,
    toggleSelector: () => {
      s.shouldRenderSelector = !s.shouldRenderSelector;
    },
    handleSelectCollections: async (collections: Collection[]) => {
      const toRemove = p.composition.collections.filter(
        collection => !collections.includes(collection)
      );
      await Promise.all(
        toRemove.map(collection => {
          removeOneFromArray(collection.$.compositionIds, p.composition._id);
          return COLLECTIONS.save(collection);
        })
      );
      const toAdd = collections.filter(
        collection => !p.composition.collections.includes(collection)
      );
      await Promise.all(
        toAdd.map(collection => {
          addOneToArrayIfNew(collection.$.compositionIds, p.composition._id);
          return COLLECTIONS.save(collection);
        })
      );
    },
  }));
  const style = useStyle(() => ({
    get select(): CSSPartial {
      return {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        width: "100%",
        appearance: "none",
        paddingRight: "2em",
        fontFeatureSettings: "'tnum' 1",
        backgroundColor: cVar(VAR_InputBackground),
        color: cVar(VAR_InputForeground),
        border: cVar(VAR_InputBorder),
        minHeight: "2.5em",
        paddingLeft: ".5em",
        paddingTop: ".5em",
        paddingBottom: ".5em",
        lineHeight: "1.5em",
        borderRadius: 2,
        "&:hover": {
          filter: "brightness(1.1)",
        },
        "&:focus": {
          outline: cVar(VAR_InputFocusOutline),
          outlineColor: varPrimary,
        },
        "&[disabled]": {
          color: fg50,
        },
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="CompositionCollectionSelector">
          <button
            type="button"
            css={style.select}
            onClick={s.toggleSelector}
            disabled={p.disabled}
          >
            {s.textLabel || s.placeholder}
          </button>
          {s.shouldRenderSelector &&
            PORTALS.render(
              <CollectionFinder
                onShouldClose={s.toggleSelector}
                onSelect={s.handleSelectCollections}
                title="Select collections"
                initialSelection={p.composition.collections}
              />
            )}
        </div>
      )}
    />
  );
};

export default CompositionCollectionsSelector;
