import { flow, observable } from "mobx";
import resolveAfter from "../base/utils/waiters.utils";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";

export type NavigatorController = ReturnType<typeof makeNavigatorController>;

export const makeNavigatorController = () => {
  const c = observable({
    ...makeControllerBase("NAVIGATOR"),
    shouldNavigateTo: undefined as string | undefined,
    navigateTo: flow(function* (path: string) {
      if (/^\/compose\//.test(path)) {
        window.ProgressBar?.reset();
      }
      c.shouldNavigateTo = path;
      yield resolveAfter();
      c.shouldNavigateTo = undefined;
    }),
  });
  c.init = makeRootControllerChildInitFn(c, () => {});

  return c;
};
