import { useEffect } from "react";

export function useOnMount(fn: React.EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fn, []);
}

export function useOnBeforeUnmount(fn: ReturnType<React.EffectCallback>) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fn, []);
}
