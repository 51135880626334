import { clamp } from "lodash-es";
import { NumericRange } from "../@types/math.types";
import { rangesIntersect } from "./math.utils";

function getScrollParent(node?: HTMLElement | SVGElement): HTMLElement | null {
  if (!node) return null;
  const isElement = node instanceof HTMLElement;

  if (isElement) {
    const overflowY = window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== "visible" && overflowY !== "hidden";

    if (isScrollable && node.scrollHeight >= node.clientHeight) {
      return node;
    }
  }

  return node.parentNode
    ? getScrollParent(node.parentNode as HTMLElement) ?? document.body
    : document.body;
}

export const elementIsVisibleInScrollParent = (options: {
  el: HTMLElement;
  scrollParent?: HTMLElement | null;
  visibleHeightRangeOffsetTop?: number;
  visibleHeightRangeOffsetBottom?: number;
}) => {
  const { el } = options;
  const scrollParent = options.scrollParent ?? getScrollParent(el);
  if (!scrollParent) return false;
  const visibleHeightRange = [
    scrollParent.scrollTop + (options.visibleHeightRangeOffsetTop ?? 0),
    scrollParent.scrollTop +
      scrollParent.clientHeight +
      (options.visibleHeightRangeOffsetBottom ?? 0),
  ] as NumericRange;
  const childHeightRange = [
    el.offsetTop,
    el.offsetTop + el.clientHeight,
  ] as NumericRange;
  return rangesIntersect(visibleHeightRange, childHeightRange);
};

export const dispatchCustomScrollEvent = (el?: HTMLElement | null) => {
  el?.dispatchEvent(new CustomEvent("scroll"));
};

export const getAutoScrollDuration = (distance: number) => {
  return clamp(Math.abs(distance) / 3000, 0.2, 0.6);
};

export default getScrollParent;
