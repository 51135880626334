import { flow, observable } from "mobx";
import { convertRecordMapToArray } from "../../base/utils/map.utils";
import { getSnapshot } from "../../base/utils/snapshot.utils";
import { ModelName } from "../../constants/modelName.constants";
import { FileRecord, FileRecordSnapshot } from "../../models/FileRecord.model";
import { ApiController } from "../api.controller";
import { LocalDBController } from "../localDB.controller";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "../_root.controller";

export const makeFileRecordsController = () => {
  const s = observable({
    ...makeControllerBase("FILE_RECORDS"),

    get API(): ApiController {
      return s.ROOT!.API;
    },
    get LOCALDB(): LocalDBController {
      return s.ROOT!.LOCALDB;
    },
    get all(): FileRecord[] {
      return convertRecordMapToArray(s.LOCALDB.data.fileRecords);
    },

    get: async (id: string) => {
      try {
        return await s.API.get<FileRecord>(
          "/file-records/" + id,
          ModelName.fileRecords
        );
      } catch (e) {
        console.error(e);
        return null;
      }
    },

    update: (id: string, newFileBase64: string) => {},

    save: (
      record: FileRecord | Partial<FileRecordSnapshot>
    ): Promise<FileRecord> =>
      flow(function* () {
        const payload = getSnapshot<FileRecordSnapshot>(record);
        const saved: FileRecord = yield s.API.patch<FileRecord>(
          `/file-records/${payload._id}`,
          ModelName.fileRecords,
          payload
        );
        return saved;
      })(),

    reset: () => {
      // c.composerAppState = null;
    },
  });

  s.init = makeRootControllerChildInitFn(s, () => {});

  return s;
};

export type FileRecordsController = ReturnType<
  typeof makeFileRecordsController
>;
