export const appendUnit = (unit: string) => (value: number) =>
  value === 0 ? value : `${value}${unit}`;
export const px = appendUnit("px");
export const em = appendUnit("em");
export const rem = appendUnit("rem");

export const cssValue = (value?: number | string | null) =>
  value === null || value === undefined || typeof value === "string"
    ? value
    : `${value}px`;
