import { makeIconDef } from "./makeIconDef";

export const PeopleIconDef = {
  people: makeIconDef("people", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00009 9C10.1047 9 11.0001 8.10457 11.0001 7C11.0001 5.89543 10.1047 5 9.00009 5C7.89552 5 7.00009 5.89543 7.00009 7C7.00009 8.10457 7.89552 9 9.00009 9ZM9.00009 10C10.6569 10 12.0001 8.65685 12.0001 7C12.0001 5.34315 10.6569 4 9.00009 4C7.34323 4 6.00009 5.34315 6.00009 7C6.00009 8.65685 7.34323 10 9.00009 10ZM13.9599 20C13.8109 18.1509 13.2551 16.4574 12.4107 15.1907C11.4135 13.695 10.1653 13 9.00006 13C7.83479 13 6.5866 13.695 5.58947 15.1907C4.74504 16.4574 4.1892 18.1509 4.04019 20H13.9599ZM13.7467 15.4948C14.4194 16.7998 14.8406 18.3588 14.9629 20L20.9529 20C20.8033 18.4242 20.3065 16.988 19.5652 15.9097C18.6681 14.6048 17.547 14 16.5001 14C15.5713 14 14.5841 14.4761 13.7467 15.4948ZM13.2107 14.5884C12.0887 12.9304 10.5763 12 9.00007 12C7.40877 12 5.88264 12.9482 4.75742 14.636C3.79034 16.0867 3.18748 17.9815 3.03713 20.0008C2.99613 20.5516 3.44778 21 4.00006 21H13.0016H14.0001H21.0001C21.5524 21 22.0047 20.5514 21.9571 20.0012C21.8054 18.2478 21.2575 16.6061 20.3892 15.3431C19.3577 13.8429 17.9588 13 16.5001 13C15.3077 13 14.1552 13.5632 13.2107 14.5884ZM16.5 10C17.3284 10 18 9.32843 18 8.5C18 7.67157 17.3284 7 16.5 7C15.6716 7 15 7.67157 15 8.5C15 9.32843 15.6716 10 16.5 10ZM16.5 11C17.8807 11 19 9.88071 19 8.5C19 7.11929 17.8807 6 16.5 6C15.1193 6 14 7.11929 14 8.5C14 9.88071 15.1193 11 16.5 11Z"
        fill="currentColor"
      />
    ),
  }),
};
