import { computed, makeObservable } from "mobx";
import { ModelName } from "../constants/modelName.constants";
import { StandardModel, appendMissingKeys } from "./StandardModel";
import { AccessType } from "./_index.model";
import { LocalDBController } from "../controllers/localDB.controller";
import { User } from "./User.model";
import { Package } from "./Package.model";
import { Composition } from "./Composition.model";

export const makeDefaultVideoExportConfig = () => ({
  // width: 360,
  // height: 200,
  // fps: 24,
  // leadingSilenceInSeconds: 2,
  // trailingSilenceInSeconds: 2,
  width: 1920,
  height: 1080,
  fps: 48,
  leadingSilenceInSeconds: 10,
  trailingSilenceInSeconds: 10,
  compression: true,
});

export const defaultVideoExportConfig = makeDefaultVideoExportConfig();

export type VideoExportConfig = ReturnType<typeof makeDefaultVideoExportConfig>;

export const makeRenderJobSnapshot = () => ({
  _id: "",
  compositionId: "",
  packageId: "",
  options: makeDefaultVideoExportConfig(),
  size: 0,
  timeCreated: "",
  state: "pending",
  ownerId: "",
  videoFileRecordId: null as string | null,
  readAccess: "private" as AccessType,
});

const defaultSnapshot = makeRenderJobSnapshot();

export type RenderJobSnapshot = ReturnType<typeof makeRenderJobSnapshot>;

export class RenderJob extends StandardModel<
  ModelName.packages,
  RenderJobSnapshot
> {
  constructor(LOCALDB: LocalDBController, $ = makeRenderJobSnapshot()) {
    appendMissingKeys($, defaultSnapshot);
    super(ModelName.packages, LOCALDB, $);
    makeObservable(this, {
      aspectRatio: computed,
      options: computed,
      owner: computed,
      composition: computed,
      package: computed,
      isPublic: computed,
      isPrivate: computed,
    });
  }
  get aspectRatio() {
    return `${this.$.options.width}/${this.$.options.height}`;
  }
  get options() {
    return this.$.options;
  }
  get composition() {
    return this.LOCALDB.get<Composition>(
      ModelName.compositions,
      this.$.compositionId
    );
  }
  get package() {
    return this.LOCALDB.get<Package>(ModelName.packages, this.$.packageId);
  }
  get owner() {
    return this.LOCALDB.get<User>(ModelName.users, this.$.ownerId);
  }
  get isPublic() {
    return this.$.readAccess === "public";
  }
  get isPrivate() {
    return this.$.readAccess !== "public";
  }
}
