import {
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  Note,
  StandardInstrumentOptions,
} from "../../@types";
import { IconName } from "../../base/components/Symbols/iconDefs/_index.iconDefs";
import { STATIC_HOST } from "../../env";
import { ColorPalette } from "../../theming/colorPalette";
import { createCompositeSamplerInstrumentFactory } from "../_factory/compositeSamplerInstrumentFactory";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";

export type AltoRecorderOptions = StandardInstrumentOptions & {};

export const makeDefaultAltoRecorderOptions = (): AltoRecorderOptions => ({
  ...makeDefaultInstrumentOptions(),
});

export type AltoRecorderType = Instrument<AltoRecorderOptions>;

export const AltoRecorderName = "alto-recorder";

export const AltoRecorderMeta: InstrumentMeta<AltoRecorderOptions> = {
  name: AltoRecorderName,
  displayName: "Alto Recorder",
  source: "VS Chamber Orchestra: Community Edition",
  sourceWebsite: "https://github.com/sgossner/VSCO-2-CE",
  icon: "recorder" as IconName,
  range: [[65, 91]] as InstrumentRange,
  defaultColor: ColorPalette.eucalyptus,
  type: "woodwind",
};

export const makeAltoRecorder = createCompositeSamplerInstrumentFactory({
  defaultOptionsBagBuilder: makeDefaultAltoRecorderOptions,
  instrumentMeta: AltoRecorderMeta,
  compositeSamplerConfigArray: [
    {
      name: "sustain",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/alto-recorder/mp3/Sustain/`,
          volume: 30,
          urls: {
            "A#4": "AltRecorder_Sus_Asharp3_rr1_Main.mp3",
            "A#5": "AltRecorder_Sus_Asharp4_rr1_Main.mp3",
            C5: "AltRecorder_Sus_C4_rr1_Main.mp3",
            C6: "AltRecorder_Sus_C5_rr1_Main.mp3",
            D5: "AltRecorder_Sus_D4_rr1_Main.mp3",
            D6: "AltRecorder_Sus_D5_rr1_Main.mp3",
            E5: "AltRecorder_Sus_E4_rr1_Main.mp3",
            "F#5": "AltRecorder_Sus_Fsharp4_rr1_Main.mp3",
            F4: "AltRecorder_Sus_F3_rr1_Main.mp3",
            "G#4": "AltRecorder_Sus_Gsharp3_rr1_Main.mp3",
            "G#5": "AltRecorder_Sus_Gsharp4_rr1_Main.mp3",
          },
        },
      },
    },
    {
      name: "vibrato",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/alto-recorder/mp3/SusVib/`,
          volume: 30,
          urls: {
            "A#4": "AltRecorder_SusVib_Asharp3_rr1_Main.mp3",
            "A#5": "AltRecorder_SusVib_Asharp4_rr1_Main.mp3",
            C4: "AltRecorder_SusVib_C4_rr1_Main.mp3",
            C6: "AltRecorder_SusVib_C5_rr1_Main.mp3",
            D5: "AltRecorder_SusVib_D4_rr1_Main.mp3",
            D6: "AltRecorder_SusVib_D5_rr1_Main.mp3",
            E5: "AltRecorder_SusVib_E4_rr1_Main.mp3",
            E6: "AltRecorder_SusVib_E5_rr1_Main.mp3",
            "F#5": "AltRecorder_SusVib_Fsharp4_rr1_Main.mp3",
            F4: "AltRecorder_SusVib_F3_rr1_Main.mp3",
            "G#4": "AltRecorder_SusVib_Gsharp3_rr1_Main.mp3",
            "G#5": "AltRecorder_SusVib_Gsharp4_rr1_Main.mp3",
          },
        },
      },
    },
  ],
  getters: {
    samplers: (n?: Note) => {
      const duration =
        n?.interpreted?.durationInSeconds ?? n?.durationInSeconds ?? 1;
      return [duration > 3 ? "vibrato" : "sustain"];
    },
  },
});
