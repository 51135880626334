/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { CSSPartial } from "../../base/@types/css.types";
import Checkbox from "../../base/components/Checkbox";
import ResizeQueryContainer from "../../base/components/ResizeQueryContainer";
import Spacing from "../../base/components/Spacing";
import SymbolIcon from "../../base/components/SymbolIcon";
import {
  useControllers,
  useRootController,
} from "../../base/hooks/rootContext.hooks";
import { useCreateResizeQueryWithRef } from "../../base/hooks/useCreateResizeQueryWithRef.hook";
import { mediaFromDesktopLg } from "../../base/styles/helpers/mediaQueries.styling";
import cx from "../../base/utils/className.utils";
import { cVar } from "../../base/utils/customProperties.utils";
import { useStore } from "../../base/utils/mobx.utils";
import {
  VAR_PanelBackdropFilter,
  VAR_PanelBackground,
  VAR_PanelBorder,
  bg,
} from "../../constants/cssCustomProperties.constants";
import { useComposer } from "../composer/ComposerApp.context";
import SustainPenalStatusIndicator from "../composer/SustainPenalStatusIndicator";
import PianoKeyboard from "./PianoKeyboard";

interface InstrumentUIProps {
  className?: string;
}

export const VAR_InstrumentUIBorder = "--InstrumentUIBorder";

const style = {
  component: {
    backgroundColor: cVar(VAR_PanelBackground),
    backdropFilter: cVar(VAR_PanelBackdropFilter),
    borderTop: cVar(VAR_InstrumentUIBorder),
    transition: "background-color .5s",
    height: 162,
    ...mediaFromDesktopLg({
      height: 200,
    }),
  } as CSSPartial,
  innerWrap: {
    display: "grid",
    "&.hasSidePanel": {
      gridTemplateColumns: `auto minmax(0, 1fr)`,
    },
    alignItems: "stretch",
    height: "100%",
  } as CSSPartial,
  sidePanel: {
    backgroundColor: bg,
    borderRight: cVar(VAR_PanelBorder),
    transition: "background-color .3s",
    width: "15rem",
    display: "grid",
    gridTemplateRows: "auto minmax(0,1fr) auto",
    fontSize: "1.2rem",
    "> header": {
      display: "flex",
      "> * + *": {
        display: "grid",
        gridGap: "1em",
        gridTemplateColumns: "auto minmax(0, 1fr)",
        flex: "1 1 100%",
        padding: ".5em .75em .5em .5em",
        "&:before": {
          content: "'+'",
          opacity: 0.5,
          fontSize: "1.8rem",
        },
      },
    },
  } as CSSPartial,
  sidePanelInstrumentDisplay: {
    flex: "0 0 auto",
    "&.singleInstrument": {
      display: "grid",
      gridGap: ".5em",
      flex: "1 1 100%",
      gridTemplateColumns: "auto minmax(0, 1fr)",
    },
    alignItems: "center",
    padding: ".5em .75em .5em",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 10,
    lineHeight: 1,
    borderBottom: cVar(VAR_PanelBorder),
  } as CSSPartial,
  sidePanelInner: {
    padding: "1em",
  } as CSSPartial,
  sidePanelBottom: {
    padding: "1em",
  } as CSSPartial,
};

const InstrumentUI: React.FC<InstrumentUIProps> = p => {
  const { ENSEMBLE } = useControllers();
  const I = useComposer();
  const { ref, query } = useCreateResizeQueryWithRef<HTMLDivElement>({
    identifier: "instrument",
    defaultWidth: window.innerWidth,
  });

  return (
    <Observer
      children={() => (
        <ResizeQueryContainer
          className={cx(
            "InstrumentUI",
            p.className,
            ENSEMBLE.isPlaying && "isPlaying",
            I.tools.quill.isActivated && "quillIsActive"
          )}
          containerRef={ref}
          query={query}
          css={style.component}
          data-disable-pointer-when-interacting
        >
          <div
            css={style.innerWrap}
            className={cx(I.withEditorUI && "hasSidePanel")}
          >
            {I.withEditorUI && <InstrumentUISidePanel />}
            <PianoKeyboard instruments={ENSEMBLE._instrumentArray} />
          </div>
        </ResizeQueryContainer>
      )}
    />
  );
};

const InstrumentUISidePanel = (props: {}) => {
  const { ENSEMBLE, SETTINGS, MIDI } = useRootController();
  const s = useStore(() => ({
    get focusedInstruments() {
      return ENSEMBLE.focusedInstruments;
    },
    get hasJustOneInstrument() {
      return s.focusedInstruments.length === 1;
    },
  }));
  return (
    <Observer
      children={() => (
        <div css={style.sidePanel}>
          <header>
            {s.focusedInstruments.map(i => (
              <div
                css={style.sidePanelInstrumentDisplay}
                className={cx(s.hasJustOneInstrument && "singleInstrument")}
                key={i._id}
              >
                {i.meta.icon !== "blank" && <SymbolIcon icon={i.meta.icon} />}
                {s.hasJustOneInstrument && (
                  <span> {i.nickName || i.meta.name}</span>
                )}
              </div>
            ))}
          </header>
          <div css={style.sidePanelInner}>
            <strong>Enter notes with</strong>
            <Spacing size="1em" />
            <Checkbox
              form={SETTINGS.composer.instruments}
              field="useVirtualKeyboardAsInput"
            >
              Virtual keyboard
            </Checkbox>
            <Spacing size=".5em" />
            <Checkbox
              form={SETTINGS.composer.instruments}
              field="useMidiControllersAsInput"
              disabled={MIDI.inputs.length === 0}
            >
              MIDI controller
            </Checkbox>
          </div>
          <div css={style.sidePanelBottom}>
            <SustainPenalStatusIndicator />
          </div>
        </div>
      )}
    />
  );
};

export default InstrumentUI;
