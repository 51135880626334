import styled from "@emotion/styled";
import { Observer } from "mobx-react-lite";
import { useProps, useStore } from "../utils/mobx.utils";
import { Has_Id } from "../../traits/hasId.trait";
import { CSSProperties, ReactElement } from "react";
import { createDraggableHandler } from "../utils/draggable.utils";
import { action } from "mobx";
import cx from "../utils/className.utils";
import { bg50, fg05 } from "../../constants/cssCustomProperties.constants";

export type DraggableListDragEndHandler = (options: {
  itemIndex: number;
  destIndex: number;
}) => void;

type Props<T extends Has_Id> = {
  className?: string;
  items: T[];
  itemDragOverBoundingBoxOffset?: string;
  renderItem: (item: T, i: number, arr: T[]) => ReactElement;
  onDragEnd: DraggableListDragEndHandler;
};

const List = styled.div`
  &.dragStarted {
    .itemInner {
      pointer-events: none;
    }
    > *:not(.beingDragged) {
      .itemInner {
        transition: transform 0.2s;
        pointer-events: none;
      }
    }
  }
`;
const Item = styled.div`
  position: relative;
  .itemInner {
    position: relative;
    > * {
      position: relative;
    }
    &:before {
      content: "";
      position: absolute;
      top: var(--itemDragOverBoundingBoxOffset);
      left: var(--itemDragOverBoundingBoxOffset);
      bottom: var(--itemDragOverBoundingBoxOffset);
      right: var(--itemDragOverBoundingBoxOffset);
      outline: 1px solid transparent;
    }
  }
  &.dragStarted {
    z-index: 1;
    .itemInner {
      pointer-events: none;
      &:before {
        background-color: ${bg50};
        outline-color: ${fg05};
        backdrop-filter: blur(1em);
      }
    }
  }
`;

const DraggableList = <T extends Has_Id>(props: Props<T>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    deltaX: 0,
    deltaY: 0,
    itemIndex: null as null | number,
    destIndex: null as null | number,
    handler: createDraggableHandler({
      onMove: ({ e, totalDeltaX, totalDeltaY }) => {
        e.preventDefault();
        s.deltaX = totalDeltaX;
        s.deltaY = totalDeltaY;
      },
      onEnd: ({ e }) => {
        if (
          s.itemIndex !== null &&
          s.destIndex !== null &&
          s.itemIndex !== s.destIndex
        )
          p.onDragEnd({ itemIndex: s.itemIndex, destIndex: s.destIndex });

        setTimeout(
          action(() => {
            s.deltaX = s.deltaY = 0;
            s.itemIndex = s.destIndex = null;
          })
        );
      },
    }),
    handlePointerDownOnItem: (i: number) =>
      action((e: React.PointerEvent) => {
        e.preventDefault();
        s.itemIndex = s.destIndex = i;
        s.handler.handlePointerDown(e);
      }),
    handlePointerEnterItem: (i: number) =>
      action(() => {
        if (s.itemIndex !== null) s.destIndex = i;
      }),
    get hasDraggedUpwards() {
      return (
        s.destIndex !== null &&
        s.itemIndex !== null &&
        s.destIndex < s.itemIndex
      );
    },
    get hasDraggedDownwards() {
      return (
        s.destIndex !== null &&
        s.itemIndex !== null &&
        s.destIndex > s.itemIndex
      );
    },
  }));
  return (
    <Observer
      children={() => (
        <List
          className={cx(p.className, s.handler.hasMoved && "dragStarted")}
          data-target-index={s.itemIndex}
          data-dest-index={s.destIndex}
          data-has-dragged-downwards={s.hasDraggedDownwards}
          data-has-dragged-upwards={s.hasDraggedUpwards}
          data-delta-x={s.handler.totalDeltaX}
          data-has-moved={s.handler.hasMoved}
          style={
            {
              "--itemDragOverBoundingBoxOffset":
                p.itemDragOverBoundingBoxOffset,
            } as CSSProperties
          }
        >
          {s.handler.hasMoved && (
            <style children={`* { cursor: grabbing; } `} />
          )}
          {p.items.map((item, i) => {
            const beingDragged = s.itemIndex === i;
            const dragStarted = beingDragged && s.handler.hasMoved;
            const isBeforeDestIndex = s.hasDraggedUpwards && i < s.destIndex!;
            const isAfterDestIndex = s.hasDraggedDownwards && i >= s.destIndex!;
            const shouldTransformDown =
              s.hasDraggedUpwards && i >= s.destIndex! && i < s.itemIndex!;
            const shouldTransformUp =
              s.hasDraggedDownwards && i <= s.destIndex! && i > s.itemIndex!;
            return (
              <Item
                key={item._id}
                data-index={i}
                onPointerEnter={s.handlePointerEnterItem(i)}
                onPointerDown={s.handlePointerDownOnItem(i)}
                className={cx(
                  beingDragged && "beingDragged",
                  dragStarted && "dragStarted"
                )}
                data-is-before-dest-index={isBeforeDestIndex}
                data-is-after-dest-index={isAfterDestIndex}
                children={
                  <div
                    className="itemInner"
                    style={
                      s.handler.hasMoved
                        ? {
                            transform: beingDragged
                              ? `translate(${s.deltaX}px, ${s.deltaY}px)`
                              : shouldTransformDown
                              ? `translateY(100%)`
                              : shouldTransformUp
                              ? `translateY(-100%)`
                              : undefined,
                          }
                        : undefined
                    }
                  >
                    <Observer children={() => p.renderItem(item, i, p.items)} />
                  </div>
                }
              />
            );
          })}
        </List>
      )}
    />
  );
};

export default DraggableList;
