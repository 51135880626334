import { makeIconDef } from "./makeIconDef";

export const CloseIconDef = {
  close: makeIconDef("close", {
    regular: () => (
      <path
        d="M7.8125 7.8125L21.1875 21.1875M21.1875 7.8125L7.8125 21.1875"
        stroke="currentColor"
      />
    ),
  }),
};
