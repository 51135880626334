import { AtomContext, Section, SectionSnapshot } from "../@types";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { insertAtomInOrder } from "../logic/atomFactoryMethods";
import { SectionSnapshotFactory } from "../models/atoms/Section.model";

export const createSection = (
  context: AtomContext,
  template: Section | Partial<SectionSnapshot>
) => {
  const snapshot = makeSnapshot<SectionSnapshot>(
    SectionSnapshotFactory,
    template ?? {}
  );
  if (!snapshot._id || context.getAtomById(snapshot._id))
    snapshot._id = context.getNextNewAtomId();
  context.addAtomSnapshots([snapshot]);
  const newSection = context.getAtomById<Section>(snapshot._id)!;
  insertAtomInOrder(context.sections, newSection);
  return newSection;
};
