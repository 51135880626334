import { observable, runInAction, when } from "mobx";
import { Sampler } from "tone";
import { CompositeSamplerConfig } from "../../@types";
import { EnsembleController } from "../../controllers/ensemble.controller";
import { loadSampler } from "./loadSampler";
import * as Tone from "tone";
import resolveAfter from "../../base/utils/waiters.utils";
import { makeDisposerController } from "../../base/utils/disposer.utils";

export const createCompositeSampler = ({
  name,
  samplerConfigs,
  releaseDuration,
  ENSEMBLE,
}: CompositeSamplerConfig & {
  ENSEMBLE: EnsembleController;
}) => {
  const d = makeDisposerController();
  const s = observable(
    {
      name,
      samplers: observable({
        sustain: null as Sampler | null,
        release: null as Sampler | null,
      }),
      isLoading: false,
      isLoaded: false,
      errors: [] as Error[],
      load: async () => {
        try {
          if (s.isLoading || s.isLoaded) return;
          runInAction(() => {
            s.isLoading = true;
          });
          const sustain = await loadSampler({
            ...samplerConfigs.sustain,
            name: samplerConfigs.sustain.name ?? `${name}-main`,
            ENSEMBLE,
          });
          runInAction(() => {
            s.samplers.sustain = sustain;
          });
          if (samplerConfigs.release) {
            (async () => {
              try {
                const release = await loadSampler({
                  ...samplerConfigs.release!,
                  name: samplerConfigs.release!.name ?? `${name}-release`,
                  ENSEMBLE,
                });
                runInAction(() => {
                  s.samplers.release = release;
                });
              } catch (e) {
                s.errors.push(e as Error);
              }
            })();
          }
        } catch (e) {
          s.errors.push(e as Error);
        } finally {
          // console.info(`[${s.name}] Composite Sampler load complete`);
          runInAction(() => {
            s.isLoading = false;
            s.isLoaded = true;
          });
        }
      },
      adjustVolume: (adjustment: number) => {
        d.add(
          when(
            () => s.isLoaded && !!s.samplers.sustain,
            () => {
              s.samplers.sustain!.volume.value =
                (samplerConfigs.sustain.volume ?? 0) + adjustment;
            }
          )
        );
        if (samplerConfigs.release) {
          d.add(
            when(
              () => s.isLoaded && !!s.samplers.release,
              () => {
                s.samplers.release!.volume.value =
                  (samplerConfigs.release!.volume ?? 0) + adjustment;
              }
            )
          );
        }
      },
      frequenciesOn: new Set<number>(),
      attack: async ({
        frequency,
        when,
        velocity,
      }: {
        frequency: number;
        when: number;
        velocity?: number;
      }) => {
        try {
          if (s.frequenciesOn.has(frequency)) {
            s.release({ frequency, when: Tone.immediate(), velocity });
            await resolveAfter(30);
          }
          s.samplers.sustain?.triggerAttack(frequency, when, velocity);
          s.frequenciesOn.add(frequency);
        } catch (e) {
          console.warn(e);
        }
      },
      release: ({
        frequency,
        when,
        velocity,
      }: {
        frequency: number;
        when: number;
        velocity?: number;
      }) => {
        try {
          s.samplers.sustain?.triggerRelease(frequency, when);
          s.samplers.release?.triggerAttackRelease(
            frequency,
            releaseDuration ?? 3,
            when,
            velocity
          );
          s.frequenciesOn.delete(frequency);
        } catch (e) {
          console.warn(e);
        }
      },
      triggerAttackRelease: (
        frequency: number,
        duration: number,
        when: number,
        attackVelocity?: number,
        releaseVelocity?: number
      ) => {
        if (!s.isLoaded) return;
        try {
          s.samplers.sustain?.triggerAttackRelease(
            frequency,
            duration,
            when,
            attackVelocity
          );
          s.samplers.release?.triggerAttackRelease(
            frequency,
            duration,
            when + duration,
            releaseVelocity
          );
        } catch (e) {
          console.warn(e);
        }
      },
      dispose: () => {
        d.dispose();
        s.samplers.sustain?.dispose();
        s.samplers.release?.dispose();
      },
    },
    {
      samplers: false,
    }
  );
  return s;
};

export type CompositeSampler = ReturnType<typeof createCompositeSampler>;
