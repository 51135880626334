import chroma from "chroma-js";
import { action } from "mobx";
import { tint } from "polished";
import { Atom, AtomContext, Voice } from "../@types";
import { addOneToArrayIfNew } from "../base/utils/array.utils";
import { getRandomVibrantColor } from "../base/utils/colors.utils";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { VoiceSnapshotFactory } from "../models/atoms/Voice.model";
import { first } from "lodash-es";

export type CreateVoiceOptions = {
  name?: string;
  atoms?: Atom[];
  color?: string;
  parentVoice?: Voice;
  childVoices?: Voice[];
};

const getRandomColor = (c: AtomContext) => {
  let newColor = getRandomVibrantColor();
  let retry = 0;
  const existingColors = c.voices.map(v => v.appearance?.colorInContext);
  while (retry < 10 && existingColors.includes(newColor)) {
    newColor = getRandomVibrantColor();
    retry++;
  }
  if (c.voices.find(v => v.appearance?.colorInContext === newColor)) {
    newColor = tint(0.2, newColor);
  }
  return newColor;
};

export const createVoice = action(
  (c: AtomContext, options?: CreateVoiceOptions) => {
    const nextId = c.getNextNewAtomId();
    let index = c.voices.length + 1;
    const existingNames = c.voices.map(v => v.name);
    if (!options?.name) while (existingNames.includes(`${index}`)) index++;
    const color = options?.color || getRandomColor(c);
    const lightModeColor =
      chroma(color).luminance() > 0.7 ? chroma(color).darken(0.5).hex() : color;
    const v = makeSnapshot(VoiceSnapshotFactory, {
      _id: nextId,
      name: options?.name || `${index}`,
      parentIds: [],
      parentVoiceId: options?.parentVoice?._id,
    });
    (options?.atoms || []).forEach(action(a => (a.voiceId = nextId)));
    c.addAtomSnapshots([v]);
    const newVoice = c.getAtomById<Voice>(v._id)!;
    newVoice.appearance.color = {
      dark: color,
      light: lightModeColor,
    };
    if (options?.childVoices) {
      options.childVoices.forEach(action(v => (v.parentVoice = newVoice)));
    }
    if (c.interpreter) {
      const r = c.interpreter.findOrCreateRuleForAtom(newVoice);
      const firstInstrument = first(c.interpreter.instruments);
      if (r?.$.properties && firstInstrument)
        r.$.properties.instrumentIds = [firstInstrument._id];
    }
    addOneToArrayIfNew(c.voices, newVoice);
    c.composer?.setWriteToVoiceAs(newVoice, "setVoiceUponVoiceCreation");
    return newVoice;
  }
);
