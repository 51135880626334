import { makeIconDef } from "./makeIconDef";

export const KeyboardIconDef = {
  keyboard: makeIconDef("keyboard", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8H22V18H3L3 8ZM2 8C2 7.44772 2.44772 7 3 7H22C22.5523 7 23 7.44772 23 8V18C23 18.5523 22.5523 19 22 19H3C2.44772 19 2 18.5523 2 18V8ZM6 9H4V11H6V9ZM7 9H9V11H7V9ZM12 9H10V11H12V9ZM13 9H15V11H13V9ZM18 9H16V11H18V9ZM19 9H21V11H19V9ZM6 12H4V14H6V12ZM7 12H9V14H7V12ZM12 12H10V14H12V12ZM13 12H15V14H13V12ZM18 12H16V14H18V12ZM19 12H21V14H19V12ZM6 15H4V17H6V15ZM7 15H18V17H7V15ZM21 15H19V17H21V15Z"
        fill="currentColor"
      />
    ),
  }),
};
