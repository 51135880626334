import { transparentize } from "polished";
import { vibrantColors } from "../../theming/colorPalette";
import { ArrayElementType, ContextColorName } from "../@types";
import { padZero } from "./math.utils";
import { getRandomItemFromArray } from "./random.utils";

export const COLOR_HEX_CODE_REGEX = /^#([A-F0-9]{6}|[A-F0-9]{3})$/i;
export const COLOR_HEX_CODE_WITH_ALPHA_REGEX = /^#([A-F0-9]{8}|[A-F0-9]{4})$/i;

export function isColorHexCode(input?: string | null): input is string {
  if (!input) return false;
  return COLOR_HEX_CODE_REGEX.test(input + "");
}

export const CSSColorKeywords = [
  "inherit",
  "transparent",
  "currentColor",
] as const;
export type CSSColorKeyword = ArrayElementType<typeof CSSColorKeywords>;

export const isCSSColorKeyword = (s?: string | null): s is CSSColorKeyword => {
  if (!s) return false;
  return CSSColorKeywords.includes(s as CSSColorKeyword);
};

export const isHex = (s: unknown): s is string => `${s}`[0] === "#";

export const ContextColorNames = [
  "background",
  "foreground",
  "primary",
  "keyboardA",
  "keyboardB",
] as const;
export const isContextColorName = (i: string): i is ContextColorName => {
  if (!i || i[0] === "#") return false;
  return ContextColorNames.includes(i as ContextColorName);
};

export const withOpacity = <T extends string | undefined | null>(
  c?: T,
  opacity?: number
): T => {
  if (!c) return undefined as T;
  if (opacity === 1 || opacity === undefined) return c;
  if (isCSSColorKeyword(c)) return c;
  return `${
    c.length === 7 ? c : `#${c[1]}${c[1]}${c[2]}${c[2]}${c[3]}${c[3]}`
  }${padZero(Math.round(opacity * 255).toString(16))}` as T;
};

export const withOpacityRgba = <T extends string | undefined | null>(
  c?: T,
  opacity?: number
): T => {
  if (!c) return undefined as T;
  if (opacity === 1 || opacity === undefined) return c;
  if (isCSSColorKeyword(c)) return c;
  return transparentize(1 - (opacity ?? 0), c) as T;
};

export const withOpacityFn =
  (opacity: number) =>
  <T extends string | undefined | null>(color?: T): T =>
    withOpacity(color, opacity);

export const withOpacity0 = withOpacityFn(0);
export const withOpacity1 = withOpacityFn(0.1);
export const withOpacity2 = withOpacityFn(0.2);
export const withOpacity3 = withOpacityFn(0.3);
export const withOpacity4 = withOpacityFn(0.4);
export const withOpacity5 = withOpacityFn(0.5);
export const withOpacity6 = withOpacityFn(0.6);
export const withOpacity7 = withOpacityFn(0.7);
export const withOpacity8 = withOpacityFn(0.8);
export const withOpacity9 = withOpacityFn(0.9);

export const getRandomVibrantColor = () => {
  return getRandomItemFromArray(Object.values(vibrantColors));
};

export const hexToDec = (hex: string) => parseInt(`0x${hex.slice(1)}`, 16);
