import { ResponsiveCanvasState } from "../base/utils/canvas.utils";
import { withOpacity } from "../base/utils/colors.utils";
import { EnvelopeOptions } from "../instruments/PolySynth/PolySynth.instrument";

type EnvelopeCurveDrawFunctionParams = {
  c: ResponsiveCanvasState;
  envelope: EnvelopeOptions;
  color: string;
};

export function drawEnvelopeCurve({
  c,
  envelope,
  color,
}: EnvelopeCurveDrawFunctionParams) {
  const { width, height, context: ctx } = c;
  const { attack, decay, sustain, release } = envelope;

  const total = attack + decay + 0.5 + release;

  // Normalize values to the canvas dimensions
  const attackX = width * (attack / total);
  const decayX = width * ((attack + decay) / total);
  const sustainY = height * (1 - sustain);
  const releaseX = width * ((total - release) / total);

  // Clear the canvas
  ctx.clearRect(0, 0, width, height);

  // Draw the envelope curve
  ctx.beginPath();
  ctx.moveTo(0, height);
  ctx.lineTo(attackX, 0);
  ctx.lineTo(decayX, sustainY);
  ctx.lineTo(releaseX, sustainY);
  ctx.lineTo(width, height);
  ctx.closePath();

  // Set style and fill the curve
  ctx.fillStyle = withOpacity(color, 0.3);
  ctx.strokeStyle = withOpacity(color, 0.9);
  ctx.fill();
  ctx.stroke();

  ctx.strokeStyle = withOpacity(color, 0.3);

  // Attack line
  ctx.beginPath();
  ctx.moveTo(attackX, 0);
  ctx.lineTo(attackX, height);
  ctx.stroke();

  // Decay line
  ctx.beginPath();
  ctx.moveTo(decayX, 0);
  ctx.lineTo(decayX, height);
  ctx.stroke();

  // Release line
  ctx.beginPath();
  ctx.moveTo(releaseX, 0);
  ctx.lineTo(releaseX, height);
  ctx.stroke();
}
