import { computed, makeObservable } from "mobx";
import { getRandomNumericString } from "../base/utils/random.utils";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { has_id } from "../traits/hasId.trait";
import { hasOwnerAndAuthors } from "../traits/hasOwnerAndAuthors.trait";
import { StandardModel, appendMissingKeys } from "./StandardModel";

export const makeDefaultScoreParams = () => ({
  baseUnit: 10,
  lineWidth: 0.5,
  xScalar: 10,
  inset: {
    top: 5,
    right: 3,
    bottom: 5,
    left: 3,
  },
});

export const defaultScoreParams = makeDefaultScoreParams();

export type ScoreParams = ReturnType<typeof makeDefaultScoreParams>;

export type SupportedClefs = "g" | "f";

export type StaffAtomOverride = {
  atomId: string;
  staffId: string;
};

export const makeStaffSnapshot = () => ({
  _id: getRandomNumericString(),
  name: "",
  anchorY: 0,
  clef: "g" as SupportedClefs,
  voiceIds: [] as string[],
});

export type StaffSnapshot = ReturnType<typeof makeStaffSnapshot>;

export type ScoreSnapshot = ReturnType<typeof ScoreSnapshotFactory>;

export const ScoreSnapshotFactory = () => ({
  ...has_id(),
  name: "",
  params: makeDefaultScoreParams(),
  staffs: [] as StaffSnapshot[],
  atomOverrides: [] as StaffAtomOverride[],
  compositionId: "",
  ...hasOwnerAndAuthors(),
});

const defaultSnapshot = ScoreSnapshotFactory();

export class Score extends StandardModel<ModelName.scores, ScoreSnapshot> {
  constructor(LOCALDB: LocalDBController, $ = ScoreSnapshotFactory()) {
    appendMissingKeys($, defaultSnapshot);
    super(ModelName.scores, LOCALDB, $);
    makeObservable(this, {
      owner: computed,
      authors: computed,
      composition: computed,
    });
  }
  get owner() {
    return this.LOCALDB.get(ModelName.users, this.$.ownerId);
  }
  get authors() {
    return this.LOCALDB.getMany(ModelName.users, this.$.authorIds);
  }
  get composition() {
    return this.LOCALDB.get(ModelName.compositions, this.$.compositionId);
  }
}

export const makeScoreSnapshot = (partial: Partial<ScoreSnapshot>) =>
  makeSnapshot(ScoreSnapshotFactory, partial);
