/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from "react";
import { varFontSerif } from "../../constants/cssCustomProperties.constants";

const Serif: React.FC<PropsWithChildren> = props => {
  return (
    <span css={{ fontFamily: varFontSerif, fontWeight: 400 }}>
      {props.children}
    </span>
  );
};

export default Serif;
