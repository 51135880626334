/** @jsxImportSource @emotion/react */
import { action, flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import Spacing from "../../base/components/Spacing";
import LoadingIndicatorBlock from "../../base/components/LoadingIndicatorBlock";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import cx from "../../base/utils/className.utils";
import { useProps, useStore, useStyle } from "../../base/utils/mobx.utils";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import TextInput from "../../base/components/TextInput";
import { Composition } from "../../models/Composition.model";
import CompositionEntry from "./CompositionEntry";
import SelectableItem from "../../base/components/SelectableItem";
import { formatAsSearchString } from "../../base/utils/search.utils";

type CompositionFinderProps = {
  className?: string;
  onConfirm?: (comps: Composition[]) => void | Promise<void>;
  selectionSet: Set<Composition>;
};

const CompositionFinder: React.FC<CompositionFinderProps> = props => {
  const p = useProps(props);

  const { COMPOSITIONS } = useControllers();

  const s = useStore(() => ({
    query: "",
    get formattedQuery(): string {
      return formatAsSearchString(s.query);
    },
    get all() {
      return COMPOSITIONS.allNonArchived;
    },
    get filtered(): Composition[] {
      return s.query
        ? s.all.filter(c =>
            formatAsSearchString(c.titlePlaintext).includes(s.formattedQuery)
          )
        : s.all;
    },
    loaded: false,
    markAsLoaded: () => (s.loaded = true),
    loadData: () =>
      flow(function* () {
        if (s.all.length > 0) s.loaded = true;
        yield COMPOSITIONS.getAllOwnMeta();
        s.loaded = true;
      })(),
    get selection() {
      return p.selectionSet;
    },
    compSelectionToggler: (comp: Composition) =>
      action(() => {
        if (s.selection.has(comp)) s.selection.delete(comp);
        else s.selection.add(comp);
      }),
  }));

  const style = useStyle(() => ({
    get component() {
      return {
        a: {
          textDecoration: "none",
        },
      };
    },
  }));

  useOnMount(() => {
    s.loadData();
  });

  return (
    <Observer
      children={() => (
        <div
          className={cx("CompositionFinder", p.className)}
          css={style.component}
        >
          <TextInput
            form={s}
            field="query"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            autoFocus
            placeholder="Type to search for a composition…"
          />
          <Spacing />
          {s.loaded ? (
            <div
              css={{
                display: "grid",
                gridGap: ".75em",
                gridTemplateColumns: "minmax(0, 1fr)",
              }}
            >
              {s.filtered.map(comp => (
                <SelectableItem
                  key={comp._id}
                  selectStateChecker={() => s.selection.has(comp)}
                  onClick={s.compSelectionToggler(comp)}
                >
                  <CompositionEntry
                    composition={comp}
                    mode="static"
                    height="8em"
                    thumbnailHeight="2.5em"
                    titleFontSize="inherit"
                  />
                </SelectableItem>
              ))}
            </div>
          ) : (
            <LoadingIndicatorBlock />
          )}
        </div>
      )}
    />
  );
};

export default CompositionFinder;
