/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { fg } from "../constants/cssCustomProperties.constants";

type AccessDeniedProps = {};

const AccessDenied: React.FC<AccessDeniedProps> = props => {
  return (
    <Observer
      children={() => (
        <svg width="167" height="167" viewBox="0 0 167 167" fill="none">
          <circle
            opacity="0.2"
            cx="83.5"
            cy="83.5"
            r="83.5"
            fill="url(#paint0_linear_631_10448)"
          />
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M84 49.75C91.732 49.75 98 56.018 98 63.75V74H102V63.75C102 53.8089 93.9411 45.75 84 45.75C74.0589 45.75 66 53.8089 66 63.75V74H70V63.75C70 56.018 76.268 49.75 84 49.75Z"
            fill={fg}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61 74C59.8954 74 59 74.8954 59 76V108C59 109.105 59.8954 110 61 110H107C108.105 110 109 109.105 109 108V76C109 74.8954 108.105 74 107 74H61ZM86 86H82V99H86V86Z"
            fill="url(#paint1_linear_631_10448)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_631_10448"
              x1="0"
              y1="0"
              x2="167"
              y2="167"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={fg} stopOpacity="0.1" />
              <stop offset="1" stopColor={fg} stopOpacity="0.62" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_631_10448"
              x1="89.2899"
              y1="116.324"
              x2="80.0367"
              y2="80.0292"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={fg} />
              <stop offset="1" stopColor={fg} stopOpacity="0.31" />
            </linearGradient>
          </defs>
        </svg>
      )}
    />
  );
};

export default AccessDenied;
