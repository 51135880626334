export const DefaultSansFontStack =
  '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif';
export const DefaultSerifFontStack =
  'Georgia,Cambria,"Times New Roman",Times,serif';
export const DefaultMonospaceFontStack =
  'SFMono-Regular,Consolas,"Liberation Mono",Menlo,monospace';

const fontFallbacks = {
  sans: DefaultSansFontStack,
  serif: DefaultSerifFontStack,
  monospace: DefaultMonospaceFontStack,
};

export function provideFontFallback(
  type: keyof typeof fontFallbacks,
  ...fontFamilies: string[]
) {
  if (fontFamilies.length === 0) return fontFallbacks[type];
  return [...fontFamilies.map(f => `"${f}"`), fontFallbacks[type]].join(",");
}
