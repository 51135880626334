/** @jsxImportSource @emotion/react */
import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { ChangeEvent } from "react";
import { CSSPartial } from "../../base/@types/css.types";
import Button from "../../base/components/Button";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { useObservableRef } from "../../base/hooks/useObservableRef.hook";
import IconImport18 from "../../base/icons/18/Import.icon.18";
import { readFileAsText } from "../../base/utils/file.utils";
import { useStore, useStyle } from "../../base/utils/mobx.utils";

type CompositionImporterProps = {};

const CompositionImporter: React.FC<CompositionImporterProps> = props => {
  const { COMPOSITIONS, NAVIGATOR } = useControllers();

  const inputRef = useObservableRef<HTMLInputElement>();

  const s = useStore(() => ({
    importing: false,
    handleImportButtonClick: () => {
      inputRef.current?.click();
    },
    handleFileInputChange: async (e: ChangeEvent<HTMLInputElement>) =>
      await flow(function* () {
        s.importing = true;
        try {
          const file = e.target.files?.[0];
          if (!file) return;
          const fileContent: string = yield readFileAsText(file);
          yield s.import(fileContent);
        } catch (e) {
          console.error(e);
        } finally {
          s.importing = false;
        }
      })(),
    import: async (json: string) => {
      const composition = await COMPOSITIONS.import(json);
      if (composition) {
        NAVIGATOR.navigateTo(`/compose/${composition._id}`);
      }
    },
  }));
  const style = useStyle(() => ({
    get component(): CSSPartial {
      return {
        position: "relative",
        input: {
          opacity: 0,
          position: "absolute",
          width: 1,
          height: 1,
          overflow: ["hidden", "clip"],
        },
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <span css={style.component}>
          <input
            type="file"
            accept="application/JSON"
            onChange={s.handleFileInputChange}
            ref={inputRef}
          />
          <Button
            onClick={s.handleImportButtonClick}
            Icon={<IconImport18 />}
            disabled={s.importing}
            loading={s.importing}
          >
            Import JSON…
          </Button>
        </span>
      )}
    />
  );
};

export default CompositionImporter;
