import { round } from "lodash-es";
import { action, observable } from "mobx";
import { isNumber } from "./typeChecks.utils";

export const makeFpsScheduler = (fps: (() => number) | number = 60) => {
  let id: number;
  let frameCountInLastSecond = 0;
  const s = observable({
    isRunning: true,
    _fps: fps,
    get fps(): number {
      return isNumber(s._fps) ? s._fps : s._fps();
    },
    setFps(v: (() => number | string) | number | string) {
      s._fps = parseInt(`${v}`);
    },
    now: window.performance.now(),
    elapsed: 0,
    get sinceStart(): number {
      return s.now - s.startTime;
    },
    get sinceStartInSeconds(): number {
      return round(s.sinceStart / 1000);
    },
    measuredFps: 0,
    get then(): number {
      return s.now - (s.elapsed % s.fpsInterval);
    },
    get fpsInterval(): number {
      return 1000 / s.fps;
    },
    get frameIntervalHadPassed(): boolean {
      return s.elapsed > s.fpsInterval;
    },
    startTime: window.performance.now(),
    stop: () => {},
    start: (fn: (time?: number) => void, debug?: boolean) => {
      s.isRunning = true;
      s.startTime = window.performance.now();
      frameCountInLastSecond = 0;
      const animate: FrameRequestCallback = action(newTime => {
        if (!s.isRunning) return;
        id = requestAnimationFrame(animate);
        s.elapsed = newTime - s.then;
        s.now = newTime;
        if (s.elapsed > s.fpsInterval) {
          fn(newTime);
          frameCountInLastSecond += 1;
        }
      });
      id = requestAnimationFrame(animate);
      const interval = setInterval(
        action(() => {
          s.measuredFps = round(frameCountInLastSecond);
          frameCountInLastSecond = 0;
          if (debug) {
            console.info(
              `Measured FPS: ${s.measuredFps}, throttled at ${s.fps}`
            );
          }
        }),
        1000
      );
      const dispose = action(() => {
        s.isRunning = false;
        clearInterval(interval);
        cancelAnimationFrame(id);
      });
      s.stop = dispose;
      return dispose;
    },
  });
  return s;
};

export type FpsScheduler = ReturnType<typeof makeFpsScheduler>;
