import { Timestamp } from "../base/utils/time.utils";

export const hasTimestamps = () => ({
  timeCreated: "" as Timestamp,
  timeArchived: "" as Timestamp | null,
  timeUpdated: "" as Timestamp | null,
  timeDeleted: "" as Timestamp | null,
});

export type HasTimestamps = ReturnType<typeof hasTimestamps>;
