import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
import { when } from "mobx";
import * as React from "react";
import { makeDisposerController } from "../utils/disposer.utils";
import { useOnMount } from "./lifecycle.hooks";

const ResizeObserver = window.ResizeObserver || Polyfill;

export const useResizeObserver = (
  handler: ResizeObserverCallback,
  ref: React.MutableRefObject<HTMLElement | SVGElement | null>
) => {
  const observer = new ResizeObserver(handler);
  useOnMount(() => {
    const d = makeDisposerController();
    d.add(
      when(
        () => Boolean(ref.current),
        () => observer.observe(ref.current!)
      )
    );
    d.add(() => {
      observer.disconnect();
    });
    return d.dispose;
  });
};
