/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { useCompleteProgressBar } from "../../base/utils/globalProgressBar.utils";
import TestPageMidiImporter from "../_testPages/TestPageMidiImporter";
import PageWorkshopArchived from "./PageWorkshopArchived";
import WorkshopArtistIndexPage from "./WorkshopArtistIndex.page";
import WorkshopArtistSinglePage from "./WorkshopArtistSingle.page";
import WorkshopCollectionIndexPage from "./WorkshopCollectionIndex.page";
import WorkshopCollectionSinglePage from "./WorkshopCollectionSingle.page";
import WorkshopCompositionIndexPage from "./WorkshopCompositionIndex.page";
import WorkshopHomePage from "./WorkshopHome.page";

type WorkshopIndexProps = {};

const WorkshopIndex: React.FC<WorkshopIndexProps> = props => {
  useCompleteProgressBar();
  return (
    <Observer
      children={() => (
        <Routes>
          <Route
            path="compositions"
            element={<WorkshopCompositionIndexPage />}
          />
          <Route
            path="collections/:id"
            element={<WorkshopCollectionSinglePage />}
          />
          <Route path="collections" element={<WorkshopCollectionIndexPage />} />
          <Route path="artists/:id" element={<WorkshopArtistSinglePage />} />
          <Route path="artists" element={<WorkshopArtistIndexPage />} />
          <Route path="archived" element={<PageWorkshopArchived />} />
          <Route path="test/midi-import" element={<TestPageMidiImporter />} />
          <Route index element={<WorkshopHomePage />} />
          <Route path="*" element={<Navigate to="/workshop" />} />
        </Routes>
      )}
    />
  );
};

export default WorkshopIndex;
