import { first, flattenDeep } from "lodash-es";
import {
  Atom,
  AtomBaseSnapshot,
  Group,
  GroupLikeAtom,
  Instrument,
  Note,
  SnapshotOfAtom,
  Voice,
} from "../@types";
import { NumericRange, ValidRect } from "../base/@types";
import {
  excludeItemsInArray,
  replaceContents,
} from "../base/utils/array.utils";
import { max, min } from "../base/utils/math.utils";
import { getValueOfKey } from "../base/utils/object.utils";
import { isNotNil, uniq } from "../base/utils/ramdaEquivalents.utils";
import { makeRect } from "../models/geometry/makeRect.model";
import { isVoiceAtom } from "../utils/atoms.utils";

export const getAtomX2 = (n: Atom) => {
  if (n.x === null) return null;
  if (n.width === null) return n.x;
  return n.x + n.width * (n.isXInverted ? -1 : 1);
};
export const getAtomY2 = (n: Atom) => {
  if (n.y === null) return null;
  if (n.height === null) return n.y;
  return n.y + n.height * (n.isYInverted ? -1 : 1);
};
export const getAtomStartX = (n: Atom) => min(n.x, n.x2);
export const getAtomStartY = (n: Atom) => min(n.y, n.y2);
export const getAtomEndX = (n: Atom) => max(n.x, n.x2);
export const getAtomEndY = (n: Atom) => max(n.y, n.y2);

export const getAtomBoundingBox = (a: Atom) => {
  if (
    a.startX === null ||
    a.startY === null ||
    a.endX === null ||
    a.endY === null
  )
    return null;
  return makeRect(a.startX, a.startY, a.endX, a.endY) as ValidRect;
};

export type AtomSorterFunction = (a: Atom, b: Atom) => number;
export const AtomSorter: AtomSorterFunction = (a, b) => {
  return (
    a.startX! - b.startX! ||
    b.endX! - a.endX! ||
    a.z! - b.z! ||
    a.startY! - b.startY! ||
    b.endY! - a.endY! ||
    0
  );
};
export const atomSorterByEndX: AtomSorterFunction = (a, b) => {
  return (
    a.endX! - b.endX! ||
    a.z! - b.z! ||
    a.startY! - b.startY! ||
    b.endY! - a.endY! ||
    0
  );
};

export function findInsertionIndexInAtomArray(
  atoms: Atom[],
  newAtom: Atom,
  sorter = AtomSorter
): number {
  let low = 0;
  let high = atoms.length;
  while (low < high) {
    const mid = Math.floor((low + high) / 2);
    const comparison = sorter(newAtom, atoms[mid]);

    if (comparison < 0) {
      high = mid;
    } else {
      low = mid + 1;
    }
  }
  return low; // The correct insertion index
}

export function insertAtomInOrder(
  atoms: Atom[],
  newAtom: Atom,
  sorter = AtomSorter
) {
  if (newAtom.x === null) atoms.unshift(newAtom);
  else {
    atoms.splice(
      findInsertionIndexInAtomArray(atoms, newAtom, sorter),
      0,
      newAtom
    );
  }
  return atoms;
}

export function moveAtomIntoSortedPosition(
  atoms: Atom[],
  updatedAtom: Atom,
  sorter = AtomSorter
): Atom[] {
  // Step 1: Remove the atom from the array
  const atomIndex = atoms.findIndex(atom => atom === updatedAtom);
  if (atomIndex !== -1) {
    atoms.splice(atomIndex, 1); // Remove the atom if it's found
  } else {
    return atoms; // Return the array unchanged if the atom is not found
  }
  // Step 2 & 3: Find the new insertion index and insert the atom at the new position
  // This is done by the existing insertAtomInOrder function
  return insertAtomInOrder(atoms, updatedAtom, sorter);
}

export const sortAtoms = <T extends Atom>(
  arr: T[],
  sorter?: AtomSorterFunction
) => {
  // console.time("sortAtoms");
  const result = [...arr].sort(sorter ?? AtomSorter);
  // console.timeEnd("sortAtoms");
  return result;
};
export const sortAtomsInPlace = <T extends Atom>(
  arr: T[],
  sorter?: AtomSorterFunction
) => {
  // console.time("sortAtomsInPlace");
  const result = arr.sort(sorter ?? AtomSorter);
  // console.timeEnd("sortAtomsInPlace");
  return result;
};

export const getAtomVoice = (n: Atom) => {
  switch (n.type) {
    case "voice":
      return n as Voice;
    case "group": {
      const descendantBelongsVoices = new Set(
        (n as Group).descendants.map(d => d.voice).filter(i => i) as Voice[]
      );
      // const voices = groupBy(v => v, descendantBelongsVoices);
      if (descendantBelongsVoices.size === 1) {
        return Array.from(descendantBelongsVoices)[0];
      }
      return null;
    }
    case "bar":
      return null;
    case "note":
      if (n.$.voiceId) {
        return n.context?.getAtomById<Voice>(n.$.voiceId) ?? null;
      } else {
        if (n.refAtom) return n.refAtom.voice;
        if ((n as Note).isInOrnament)
          return (n as Note).ornamentParent?.voice ?? null;
        return null;
      }
    default:
      return n.context?.getAtomById<Voice>(n.$.voiceId) ?? null;
  }
};

export const getAtomXRange = (a: Atom) =>
  a.startX === null
    ? null
    : ([a.startX, a.endX === null ? a.startX : a.endX] as NumericRange);

export const getNextAtomInArray = <T extends Atom>(
  atom: Atom,
  array: T[] = []
) => {
  return (
    array.find(
      sibling =>
        sibling !== atom &&
        sibling.x !== null &&
        atom.x !== null &&
        sibling.x > atom.x
    ) ?? null
  );
};

export const getPrevAtomInArray = <T extends Atom>(
  atom: Atom,
  array: T[] = []
) => {
  return (
    array.find(
      sibling =>
        sibling !== atom &&
        sibling.x !== null &&
        atom.x !== null &&
        sibling.x < atom.x
    ) ?? null
  );
};

export const recursiveGetPropOfAtomOrSnapshot = <T extends Atom, R = unknown>(
  name: keyof T & string,
  atom: T,
  $?: SnapshotOfAtom<T>,
  fallback?: R,
  _path?: Atom[]
): R => {
  const source = $ || atom;
  const ownValue = source
    ? getValueOfKey<UnknownObject>(`${name}`, source)
    : undefined;
  const atomHasValueDefined =
    isNotNil(ownValue) !== null && ownValue !== "inherit";
  const firstParent = first(atom.parents) ?? null;
  return ((atomHasValueDefined
    ? ownValue
    : firstParent
    ? recursiveGetPropOfAtomOrSnapshot<GroupLikeAtom>(
        name as keyof GroupLikeAtom,
        firstParent,
        undefined,
        undefined,
        [atom, ...(_path ?? [])]
      )
    : undefined) ?? fallback) as R;
};

export function getAtomPath(atom: Atom) {
  return atom.parents.map(p => [p, ...p.path]);
}

export function setAtomParents(
  atom: Atom,
  atomSource: Partial<AtomBaseSnapshot>,
  parents: GroupLikeAtom[] = [],
  mode: ArrayOperationMode = "add"
) {
  if (!atomSource.parentIds) atomSource.parentIds = [];
  if (parents.some(p => isVoiceAtom(p))) {
    console.warn(
      "Voice atoms are not valid as parents. A set of atoms are being assigned as parents, but some of them are voices. This is likely a bug. The new parent atoms being assigned & the atom receiving the parents:",
      parents,
      atom
    );
    return;
  }
  if (parents.includes(atom as GroupLikeAtom)) {
    console.warn(
      "Cannot set a atom as its own parent. At least one of the atoms being assigned as new parents are identical to the atom receiving the new parents. This is a bug and must be fixed."
    );
    return;
  }
  const prevParents = atom.parents;
  const newParentsAfterMerge = [] as GroupLikeAtom[];
  switch (mode) {
    case "add":
      newParentsAfterMerge.push(...prevParents, ...parents);
      break;
    case "replace":
      newParentsAfterMerge.push(...parents);
      break;
    case "subtract":
      newParentsAfterMerge.push(
        ...excludeItemsInArray(prevParents, ...parents)
      );
      break;
  }
  const atomsThatWillContainCircularDependency = newParentsAfterMerge.filter(
    parent => parent?.ancestors.includes(atom)
  );
  if (atomsThatWillContainCircularDependency.length > 0) {
    throw Error(
      `This operation will result in circular dependency. The atoms to be the new parents that will result in circular dependencies are [${atomsThatWillContainCircularDependency.map(
        n => n._id
      )}]`
    );
  }
  const newParentIds = newParentsAfterMerge.map(n => n._id);
  // console.log(`#${atom._id} new parent ids:`, newParentIds.join(", "));
  replaceContents(atomSource.parentIds, uniq(newParentIds));
}

export const getAncestorsOfAtom = (a: Atom) => flattenDeep(uniq(a.path));

export const getInterpretedInstrumentsOfAtom = (a: Atom) => {
  if (!a.interpreter) return [];
  const instrumentIds = [...(a.rulePropertiesFlattened.instrumentIds ?? [])];
  if (instrumentIds.length === 0) {
    if (isVoiceAtom(a)) {
      instrumentIds.push(
        ...(a.parentVoice?.interpreted.instruments.map(i => i._id) ?? [])
      );
    } else {
      instrumentIds.push(
        ...(a.voice?.interpreted.instruments.map(i => i._id) ?? [])
      );
    }
  }
  return uniq(
    instrumentIds
      .sort()
      .map(id => a.interpreter!.instrumentMap.get(id))
      .filter(i => i) as Instrument[]
  );
};
