/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import * as React from "react";

interface SpacingProps {
  size?: string | number;
  inline?: boolean;
}

const Spacing: React.FC<SpacingProps> = observer(p => {
  return (
    <span
      className="Spacing"
      css={{
        width: p.size ?? "1em",
        height: p.size ?? "1em",
        display: p.inline ? "inline-block" : "block",
      }}
    />
  );
});

export default Spacing;
