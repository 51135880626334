import { action, autorun, observable, untracked, when } from "mobx";
import { Atom, AtomContext } from "../@types";
import { ThemeController } from "../controllers/theme.controller";
import { makeDisposerController } from "../base/utils/disposer.utils";
import { ObservableRef } from "../base/hooks/useObservableRef.hook";
import { blobToBase64 } from "../base/utils/blob.utils";
import { isAtom, isBarAtom, isGroupLikeAtom, isNoteAtom } from "./atoms.utils";
import { ColorPalette } from "../theming/colorPalette";
import { isInfinity } from "../base/utils/math.utils";
import { SystemColorSchemeName } from "../base/@types";

export const createAtomOrAtomContextThumbnailRenderer = action(
  (options: {
    THEME?: ThemeController;
    canvasContainerRef?: ObservableRef;
    canvasContainerEl?: HTMLDivElement;
    params: {
      target?: AtomContext | Atom;
      scalarX?: number;
      scalarY?: number;
      noteHeight?: number;
      onRender?: (canvas: HTMLCanvasElement) => void;
      lazy?: boolean;
      theme?: SystemColorSchemeName;
    };
  }) => {
    const d = makeDisposerController();
    const { canvasContainerRef, canvasContainerEl, THEME } = options;
    const p = options.params;
    const canvas = document.createElement("canvas");
    const s = observable({
      get context() {
        return isAtom(p.target) ? p.target?.context : p.target;
      },
      get scalarX(): number {
        if (p.scalarX) return p.scalarX;
        const { ptPerX, ptPerY } =
          s.context?.interpreter?.interpretation.options ?? {};
        if (ptPerX !== undefined && ptPerY !== undefined) {
          return (ptPerX / ptPerY) * s.scalarY;
        }
        return 12;
      },
      get scalarY(): number {
        return p.scalarY ?? 2;
      },
      get abstractWidth() {
        return p.target?.width ?? 0;
      },
      get abstractHeight() {
        return (p.target?.height ?? 0) + s.noteHeightPt;
      },
      get abstractXStart() {
        return p.target?.startX ?? 0;
      },
      get abstractYStart() {
        return p.target?.startY ?? 0;
      },
      get noteHeightPt(): number {
        return p.noteHeight ?? s.scalarY;
      },
      get renderWidthPt(): number {
        return s.abstractWidth * s.scalarX;
      },
      get renderHeightPt(): number {
        return s.abstractHeight * s.scalarY;
      },
      get notes() {
        return (
          (isAtom(p.target)
            ? isGroupLikeAtom(p.target)
              ? p.target.descendantNotes
              : isBarAtom(p.target)
              ? p.target.notes
              : isNoteAtom(p.target)
              ? [p.target]
              : []
            : s.context?.notes) ?? []
        ).filter(n => !n.interpreted.ornament);
      },
    });

    const C = canvas.getContext("2d");

    const render = () => {
      if (!C) return;
      C.clearRect(0, 0, canvas.width, canvas.height);
      s.notes.forEach(note => {
        C.fillStyle =
          note.appearance.colorInContext ?? THEME?.primary ?? ColorPalette.gray;
        if (
          note.interpreted.startX !== null &&
          note.interpreted.width !== null &&
          note.y !== null
        ) {
          C.fillRect(
            (note.interpreted.startX - s.abstractXStart) * s.scalarX,
            (note.y - s.abstractYStart) * s.scalarY,
            note.interpreted.width * s.scalarX,
            s.noteHeightPt
          );
        }
      });
    };

    const setMeasurements = () => {
      if (!C) return;
      const UI = THEME?.ROOT?.UI;
      const pixelDensity = UI?.pixelDensity ?? window.devicePixelRatio;
      const pxRatio = pixelDensity;
      if (isInfinity(s.renderHeightPt)) return;
      canvas.setAttribute("width", `${s.renderWidthPt * pxRatio}`);
      canvas.setAttribute("height", `${s.renderHeightPt * pxRatio}`);
      canvas.style.setProperty("width", `${s.renderWidthPt}px`);
      canvas.style.setProperty("height", `${s.renderHeightPt}px`);
      canvas.style.setProperty(
        "aspect-ratio",
        `${s.renderWidthPt}/${s.renderHeightPt}`
      );
      C.scale(pixelDensity, pixelDensity);
      untracked(render);
    };

    setMeasurements();
    render();
    d.add(autorun(setMeasurements, { delay: 25 }));
    d.add(autorun(render, { delay: 25 }));

    if (canvasContainerRef) {
      d.add(
        when(
          () => !!canvasContainerRef.current,
          () => {
            canvasContainerRef.current?.appendChild(canvas);
          }
        )
      );
    } else if (canvasContainerEl) {
      canvasContainerEl.appendChild(canvas);
    } else {
      const container = document.createElement("div");
      container.style.setProperty("position", "fixed");
      container.style.setProperty("top", "-100px");
      container.style.setProperty("left", "-100px");
      container.style.setProperty("width", "100px");
      container.style.setProperty("height", "100px");
      container.style.setProperty("overflow", "hidden");
      container.style.setProperty("opacity", "0");
      container.appendChild(canvas);
    }

    d.add(() => {
      canvas.remove();
    });

    return {
      _state: s,
      getUpToDateRenderAsBase64: () =>
        new Promise<string>(resolve => {
          action(render)();
          const handleCanvasToBlob = async (blob: Blob | null) => {
            if (!blob) {
              resolve("");
              return;
            }
            const base64 = await blobToBase64(blob);
            resolve(base64);
          };
          canvas.toBlob(handleCanvasToBlob);
        }),
      dispose: d.dispose,
    };
  }
);

export type AtomOrAtomContextThumbnailRenderer = ReturnType<
  typeof createAtomOrAtomContextThumbnailRenderer
>;

export const getAtomContextThumbnail = async (
  context: AtomContext,
  theme: SystemColorSchemeName
) => {
  const renderer = createAtomOrAtomContextThumbnailRenderer({
    params: {
      target: context,
      noteHeight: 3,
      lazy: true,
      theme,
    },
  });
  const thumbnailBase64 = await renderer.getUpToDateRenderAsBase64();
  renderer.dispose();
  return thumbnailBase64;
};
