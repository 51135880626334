/** @jsxImportSource @emotion/react */
import React from "react";
import { bg, varPrimary } from "../../constants/cssCustomProperties.constants";

type DevStripesProps = {};

const DevStripes: React.FC<DevStripesProps> = props => {
  return (
    <svg width="25" height="10" viewBox="0 0 25 10">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0H0V10H10H25V0H10Z"
        fill={varPrimary}
      />
      <path d="M0 0H5L0 5V0Z" fill={bg} />
      <path d="M25 10H20L25 5V10Z" fill={bg} />
      <path d="M10 0H15L5 10H0L10 0Z" fill={bg} />
      <path d="M25 0H20L10 10H15L25 0Z" fill={bg} />
    </svg>
  );
};

export default DevStripes;
