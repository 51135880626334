/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { observable, toJS } from "mobx";
import { isLocalhost } from "../base/env";
import { RecordMap, makeRecordMap } from "../base/utils/map.utils";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { isString } from "../base/utils/typeChecks.utils";
import { ModelName } from "../constants/modelName.constants";
import { modelConstructorMap } from "../models/_index.model";
import { Artist } from "../models/Artist.model";
import { Collection } from "../models/Collection.model";
import { Composition } from "../models/Composition.model";
import { Interpretation } from "../models/Interpretation.model";
import { User } from "../models/User.model";
import { Has_Id } from "../traits/hasId.trait";
import { ControllerBase } from "./_controller.types";
import {
  makeControllerBase,
  makeRootControllerChildInitFn,
} from "./_root.controller";
import { StandardModel } from "../models/StandardModel";
import { recursiveMergeWithTypeCast } from "../base/utils/object.utils";
import { Score } from "../models/Score.model";
import { isMatch } from "lodash-es";
import { FileRecord } from "../models/FileRecord.model";
import { Package } from "../models/Package.model";
import { RenderJob } from "../models/RenderJob.model";
import { LibraryEntry } from "../models/LibraryEntry.model";

export type LocalDBDataset = {
  [ModelName.artists]: RecordMap<Artist>;
  [ModelName.collections]: RecordMap<Collection>;
  [ModelName.compositions]: RecordMap<Composition>;
  [ModelName.interpretations]: RecordMap<Interpretation>;
  [ModelName.scores]: RecordMap<Score>;
  [ModelName.users]: RecordMap<User>;
  [ModelName.fileRecords]: RecordMap<FileRecord>;
  [ModelName.packages]: RecordMap<Package>;
  [ModelName.renderJobs]: RecordMap<RenderJob>;
  [ModelName.libraryEntries]: RecordMap<LibraryEntry>;
};

const makeDataSet = (): LocalDBDataset => ({
  [ModelName.artists]: makeRecordMap<Artist>(),
  [ModelName.collections]: makeRecordMap<Collection>(),
  [ModelName.compositions]: makeRecordMap<Composition>(),
  [ModelName.scores]: makeRecordMap<Score>(),
  [ModelName.interpretations]: makeRecordMap<Interpretation>(),
  [ModelName.users]: makeRecordMap<User>(),
  [ModelName.fileRecords]: makeRecordMap<FileRecord>(),
  [ModelName.packages]: makeRecordMap<Package>(),
  [ModelName.renderJobs]: makeRecordMap<RenderJob>(),
  [ModelName.libraryEntries]: makeRecordMap<LibraryEntry>(),
});

type SnapshotServerSignature = {
  _id: string;
  __v: number;
  timeUpdated: string;
};

export const makeLocalDBController = () => {
  const LOCALDB = observable({
    ...makeControllerBase("LOCALDB"),
    data: makeDataSet(),

    getById: <T extends StandardModel>(id: string | null) => {
      if (!id) return null;
      for (const dataSet of Object.values(LOCALDB.data)) {
        const record = dataSet.get(id);
        if (record) return record as unknown as T;
      }
      return null;
    },
    get: <T extends StandardModel>(type: ModelName, id: string | null) => {
      if (!id) return null;
      const record = LOCALDB.data[type]?.get(id) ?? null;
      return record as T | null;
    },
    getMany: <T extends StandardModel>(type: ModelName, ids: string[]) => {
      try {
        const result = observable(
          ids.map(id => LOCALDB.get(type, id)).filter(i => i) as unknown as T[]
        );
        return result;
      } catch (e) {
        console.warn("LOCALDB.getMany failed with", type, ids);
        console.error(e);
        debugger;
        return [];
      }
    },

    getFileRecord: (id: string | null) => {
      if (!id) return null;
      LOCALDB.ROOT?.BUCKET.get(id);
      return LOCALDB.get<FileRecord>(ModelName.fileRecords, id);
    },

    setOrMerge: <T extends StandardModel>(
      type: T["_name"],
      obj: T | T["$"]
    ): T => {
      const snapshot = getSnapshot<Has_Id>(
        obj
      ) as unknown as SnapshotServerSignature;
      const { _id } = snapshot;
      const Constructor = modelConstructorMap[type];
      const existingRecord = LOCALDB.data[type]?.get(_id);
      if (existingRecord) {
        const existingSnapshot = (existingRecord as unknown as T)
          .$ as unknown as SnapshotServerSignature;
        const {
          __v: eV,
          timeUpdated: eTU,
          ...existingSnapshotForComparison
        } = toJS(existingSnapshot);
        const {
          __v: iV,
          timeUpdated: iTU,
          ...incomingSnapshotForComparison
        } = toJS(snapshot);
        if (
          !isMatch(existingSnapshotForComparison, incomingSnapshotForComparison)
        ) {
          recursiveMergeWithTypeCast(existingSnapshot, snapshot);
        }
        existingSnapshot.__v = snapshot.__v;
        existingSnapshot.timeUpdated = snapshot.timeUpdated;
        // else {
        //   console.info(
        //     `setOrMerge: ${type}#${obj._id}'s local and remote copies are the same`
        //   );
        // }
        existingRecord.$setPrev(snapshot);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const record = new Constructor(LOCALDB, snapshot as any);
        (LOCALDB.data[type] as unknown as RecordMap<T>)?.set(
          _id,
          record as unknown as T
        );
      }
      return LOCALDB.get<T>(type, _id)!;
    },

    setOrMergeMany: <T extends StandardModel>(
      type: ModelName,
      records: (T | T["$"])[]
    ): T[] => {
      records.forEach(record => LOCALDB.setOrMerge(type, record));
      return LOCALDB.getMany<T>(
        type,
        records.map(r => r._id)
      );
    },

    has: (type: ModelName, s: string | Has_Id) => {
      if (isString(s)) return LOCALDB.data[type].has(s);
      else return LOCALDB.data[type].has(s._id);
    },

    remove: <T extends StandardModel>(record: T) => {
      const type = record._name;
      LOCALDB.data[type]?.delete(record._id);
    },
    removeMany: <T extends StandardModel>(records: T[]) => {
      const type = records[0]?._name;
      if (!type) return;
      records.forEach(record => LOCALDB.remove(record));
    },

    reset: () => {
      Object.values(LOCALDB.data).forEach(v => v.clear());
    },
  });

  LOCALDB.init = makeRootControllerChildInitFn(LOCALDB, () => {
    if (isLocalhost) Reflect.set(window, "toJS", toJS);
  });

  return LOCALDB;
};

export type LocalDBController = ControllerBase & {
  data: LocalDBDataset;
  getById: <T extends StandardModel>(id: string | null) => T | null;
  get: <T extends StandardModel>(
    type: ModelName,
    id: string | null
  ) => T | null;
  getFileRecord: (id: string | null) => FileRecord | null;
  getMany: <T extends StandardModel>(type: ModelName, ids: string[]) => T[];
  setOrMerge: <T extends StandardModel>(
    type: ModelName,
    record: T | T["$"]
  ) => T;
  setOrMergeMany: <T extends StandardModel>(
    type: ModelName,
    records: (T | T["$"])[]
  ) => T[];
  has: (type: ModelName, s: string | StandardModel) => boolean;
  remove: <T extends StandardModel>(record: T) => void;
  removeMany: <T extends StandardModel>(records: T[]) => void;
  reset: () => void;
};

export const getFromLocalDBFn =
  <T extends StandardModel = StandardModel>(
    key: ModelName,
    localDB?: LocalDBController
  ) =>
  (id: string | null) => {
    return id ? localDB?.get<T>(key, id) : null;
  };
