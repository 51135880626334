import { makeIconDef } from "./makeIconDef";

export const EnterIconDef = {
  enter: makeIconDef("enter", {
    regular: () => (
      <path
        d="M3 16L8 18.8868V13.1132L3 16ZM18.5 6V14H19.5V6H18.5ZM17 15.5H7.5V16.5H17V15.5ZM18.5 14C18.5 14.8284 17.8284 15.5 17 15.5V16.5C18.3807 16.5 19.5 15.3807 19.5 14H18.5Z"
        fill="currentColor"
      />
    ),
  }),
};
