import { makeIconDef } from "./makeIconDef";

export const HistoryIconDefs = {
  history: makeIconDef("history", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4255 5.25139C12.6911 4.79064 10.8489 4.96711 9.2334 5.74875C7.61794 6.5304 6.33631 7.8654 5.62118 9.51139C4.90606 11.1574 4.80485 13.0052 5.33594 14.7195C5.86703 16.4337 6.99519 17.9007 8.51563 18.8541C10.0361 19.8075 11.848 20.1841 13.6224 19.9155C15.3968 19.647 17.0161 18.751 18.1863 17.3904C19.3565 16.0298 20 14.2946 20 12.5H21C21 14.5339 20.2707 16.5004 18.9445 18.0424C17.6182 19.5845 15.783 20.5999 13.772 20.9043C11.761 21.2086 9.70754 20.7818 7.98438 19.7013C6.26122 18.6208 4.98263 16.9582 4.38073 15.0154C3.77883 13.0726 3.89353 10.9784 4.70401 9.11291C5.51448 7.24745 6.967 5.73445 8.79785 4.84859C10.6287 3.96273 12.7165 3.76273 14.6823 4.28491C16.328 4.72209 17.7969 5.64121 18.9062 6.91337V5.0625H19.9062V8.25V8.75H19.4062H16.2187V7.75H18.3041C17.3094 6.53446 15.9539 5.65739 14.4255 5.25139ZM12 12.5V7.1875H13V12.2929L16.041 15.3339L15.3339 16.041L12.1464 12.8535L12 12.7071V12.5Z"
        fill="currentColor"
      />
    ),
  }),
};
