export const useDataAttributes = (props: AnyObject) => {
  return getDataAttributes(props);
};

export type DataAttrName<T extends string = string> = `data-${T}`;

export type DataAttrs<T extends AnyObject> = {
  [Property in keyof T as Property & DataAttrName]: T[Property];
};

const stringIsDataAttrKey = <T extends AnyObject>(
  k: string
): k is keyof DataAttrs<T> & string => !!/^data-/.exec(k);

export const getDataAttributes = <T extends AnyObject>(object: T) => {
  const dataAttributes = {} as Partial<DataAttrs<T>>;
  Object.keys(object).forEach(k => {
    if (stringIsDataAttrKey<T>(k)) {
      dataAttributes[k] = object[k];
    }
  });
  return dataAttributes as unknown as DataAttrs<T>;
};
