import { cVar } from "../base/utils/customProperties.utils";

export const VAR_VH = "--vh";
export const varVh = `var(${VAR_VH}, 100vh)`;

export const VAR_FONT_SANS = "--FontSans";
export const VAR_FONT_SERIF = "--FontSerif";
export const VAR_FONT_MONO = "--FontMonospace";
export const VAR_FONT_SANS_OPEN_SOURCE = "--FontSansOpenSource";
export const VAR_FONT_MONO_OPEN_SOURCE = "--FontMonospaceOpenSource";
export const VAR_FONT_NOTATION = "--FontNotation";
export const VAR_FONT_NOTATION_TEXT = "--FontNotationText";
export const VAR_FONT_SERIF_SCORE = "--FontSerifScore";

export const varFontSans = cVar(VAR_FONT_SANS);
export const varFontSerif = cVar(VAR_FONT_SERIF);
export const varFontSerifScore = cVar(VAR_FONT_SERIF_SCORE);
export const varFontMono = cVar(VAR_FONT_MONO);
export const varFontNotation = cVar(VAR_FONT_NOTATION);
export const varFontNotationText = cVar(VAR_FONT_NOTATION_TEXT);
export const varFontSansOpenSource = cVar(VAR_FONT_SANS_OPEN_SOURCE);
export const varFontMonoOpenSource = cVar(VAR_FONT_MONO_OPEN_SOURCE);

export const VAR_Accent = "--Accent";
export const varAccent = cVar(VAR_Accent);
export const VAR_Primary = "--Primary";
export const VAR_Primary80 = "--Primary80";
export const VAR_Primary50 = "--Primary50";
export const VAR_Primary30 = "--Primary30";
export const VAR_Primary40 = "--Primary40";
export const VAR_Primary20 = "--Primary20";
export const VAR_Primary10 = "--Primary10";
export const VAR_Primary05 = "--Primary05";
export const VAR_PrimaryContrast = "--PrimaryContrast";
export const VAR_PrimaryContrast10 = "--PrimaryContrast10";
export const VAR_PrimaryContrast20 = "--PrimaryContrast20";
export const varPrimary = cVar(VAR_Primary);
export const varPrimaryContrast = cVar(VAR_PrimaryContrast);
export const varPrimaryContrast10 = cVar(VAR_PrimaryContrast10);
export const varPrimaryContrast20 = cVar(VAR_PrimaryContrast20);
export const varPrimary05 = cVar(VAR_Primary05);
export const varPrimary10 = cVar(VAR_Primary10);
export const varPrimary20 = cVar(VAR_Primary20);
export const varPrimary30 = cVar(VAR_Primary30);
export const varPrimary40 = cVar(VAR_Primary40);
export const varPrimary50 = cVar(VAR_Primary50);
export const varPrimary80 = cVar(VAR_Primary80);

export const VAR_Fg = "--Fg";
export const VAR_Fg90 = "--Fg90";
export const VAR_Fg80 = "--Fg80";
export const VAR_Fg70 = "--Fg70";
export const VAR_Fg60 = "--Fg60";
export const VAR_Fg50 = "--Fg50";
export const VAR_Fg40 = "--Fg40";
export const VAR_Fg30 = "--Fg30";
export const VAR_Fg20 = "--Fg20";
export const VAR_Fg15 = "--Fg15";
export const VAR_Fg10 = "--Fg10";
export const VAR_Fg07 = "--Fg07";
export const VAR_Fg05 = "--Fg05";
export const VAR_Fg03 = "--Fg03";
export const VAR_Fg02 = "--Fg02";
export const VAR_Fg00 = "--Fg00";

export const fg = cVar(VAR_Fg);
export const fg90 = cVar(VAR_Fg90);
export const fg80 = cVar(VAR_Fg80);
export const fg70 = cVar(VAR_Fg70);
export const fg60 = cVar(VAR_Fg60);
export const fg50 = cVar(VAR_Fg50);
export const fg40 = cVar(VAR_Fg40);
export const fg30 = cVar(VAR_Fg30);
export const fg20 = cVar(VAR_Fg20);
export const fg15 = cVar(VAR_Fg15);
export const fg10 = cVar(VAR_Fg10);
export const fg07 = cVar(VAR_Fg07);
export const fg05 = cVar(VAR_Fg05);
export const fg03 = cVar(VAR_Fg03);
export const fg02 = cVar(VAR_Fg02);
export const fg00 = cVar(VAR_Fg00);

export const VAR_Bg = "--Bg";
export const VAR_Bg90 = "--Bg90";
export const VAR_Bg80 = "--Bg80";
export const VAR_Bg70 = "--Bg70";
export const VAR_Bg60 = "--Bg60";
export const VAR_Bg50 = "--Bg50";
export const VAR_Bg40 = "--Bg40";
export const VAR_Bg30 = "--Bg30";
export const VAR_Bg20 = "--Bg20";
export const VAR_Bg10 = "--Bg10";
export const VAR_Bg05 = "--Bg05";
export const VAR_Bg02 = "--Bg02";
export const VAR_Bg00 = "--Bg00";

export const bg = cVar(VAR_Bg);
export const bg90 = cVar(VAR_Bg90);
export const bg80 = cVar(VAR_Bg80);
export const bg70 = cVar(VAR_Bg70);
export const bg60 = cVar(VAR_Bg60);
export const bg50 = cVar(VAR_Bg50);
export const bg40 = cVar(VAR_Bg40);
export const bg30 = cVar(VAR_Bg30);
export const bg20 = cVar(VAR_Bg20);
export const bg10 = cVar(VAR_Bg10);
export const bg05 = cVar(VAR_Bg05);
export const bg02 = cVar(VAR_Bg02);
export const bg00 = cVar(VAR_Bg00);

export const VAR_LineWidth = "--LineWidth";
export const VAR_BorderForeground10 = "--BorderForeground10";
export const VAR_ForegroundDarken = "--ForegroundDarken";
export const VAR_InputBackground = "--InputBackground";
export const VAR_InputBackgroundHover = "--InputBackgroundHover";
export const VAR_InputForeground = "--InputForeground";
export const VAR_InputBorder = "--InputBorder";
export const VAR_InputHighlight = "--InputHighlight";
export const VAR_InputFocusOutline = "--InputFocusOutline";

export const VAR_PanelDockBottomHeight = "--PanelDockBottomHeight";
export const VAR_TitleBarHeight = "--TitleBarHeight";

export const VAR_AppPageInset = "--AppPageInset";

export const VAR_PanelBorder = "--PanelBorder";
export const VAR_PanelBackground = "--PanelBackground";
export const VAR_PanelBorderlessHeaderBackground =
  "--PanelBorderlessHeaderBackground";
export const VAR_PanelBackdropFilter = "--PanelBackdropFilter";
export const VAR_BlurBackdropFilter = "--BlurBackdropFilter";
export const VAR_DarkerBackgroundWhenDark = "--DarkerBackgroundWhenDark";
export const VAR_SeparatorBorder = "--SeparatorBorder";
export const VAR_ShadowMedium = "--ShadowMedium";

export const VAR_ToolWheelOverflow = "--ToolWheelOverflow";
export const VAR_ToolWheelBorderOuter = "--ToolWheelBorderOuter";
export const VAR_ToolWheelSvgCircleStrokeColor =
  "--ToolWheelSvgCircleStrokeColor";
export const VAR_ToolWheelBackgroundFill = "--ToolWheelBackgroundFill";

export const VAR_OctaveGridHalfStepHintColor = "--OctaveGridHalfStepHintColor";
