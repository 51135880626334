import {
  computed,
  extendObservable,
  flow,
  isAction,
  isComputedProp,
  isObservableProp,
  makeObservable,
  observable,
  reaction,
  runInAction,
  when,
} from "mobx";
import { useLocalObservable } from "mobx-react-lite";
import {
  Annotation,
  AnnotationsMap,
  Atom,
  IWhenOptions,
} from "mobx/dist/internal";
import { useEffect } from "react";
import { isDevelopment } from "../env";
import { makeDisposerController } from "./disposer.utils";
import { isFunction } from "./typeChecks.utils";
import resolveAfter from "./waiters.utils";

/**
 *
 * A helper function to convert (typically) props object into an MobX observable state object.
 * Every time the component re-renders, the writable fields in the new set of props will be updated.
 * All props will be made observable and writable, but functions and components will be made with `observable.ref`, while other values with `observable.deep`.
 *
 * To help the function determine which props should be deep observable and which ones should only observe ref changes,
 * strictly use the following naming conventions for props that could potentially contain any React components (class / functional / lazy / memoized):
 * - Capitalize first letter, such as `StartSlot`. (The regex to match this is `/^[A-Z]/`).
 * - Name it exactly `children`, and write JSX children as a function.
 *
 * [Important]: For any non-component props that expects a function,
 * you MUST provide a NoOp stub function on mount.
 *
 * @param current: the props object.
 * @param annotations: Provide explicitly defined MobX observable annotations to override the auto-detected annotations by this utility function.
 * @see [Mobx Documentation: Observable State](https://mobx.js.org/observable-state.html)
 *
 * Note that "useProps" is only necessary if you are using props in a local MobX store.
 * If props are only used in rendering function, you can just use the original props object unchanged and let React handle the re-rendering.
 * This helper is really created to make it easier to write code and more consistent,
 * and does not necessarily mean better performance.
 * Although in practice, no significant performance hit has been observed. (* to be systematically tested)
 *
 * Rationale: By default, functions will be converted into non-writable, non-observable actions in MobX,
 * as in MobX "logic" and "derivations" are by default considered not 'state', thus non-mutable and non-enumerable.
 * However, for the automatic props conversion, we want to keep functions in props as "things (state)" and not just "logic",
 * and keep them writable and observable, because they might well change during a rerender.
 * However, simply using default observable behavior on components will recursively make them observable,
 * creating a new proxied object and causing some type checks of React component type to fail.
 * So component class / functional component / lazy / memoized components should never be converted into observables,
 * But the *reference* to them in our props state object should.
 * It is not possible to tell apart from code itself whether a function was intended as a functional component,
 * hence strict naming conventions is required so this helper can recognize them and keep them intact.
 *
 */

export const useProps = <T extends {}>(
  current: T,
  annotations: AuthorableAnnotationMap<T> = {},
  options?: ObservableWrapperOptions
) => {
  const name = `useProps${options?.name ? `@${options?.name}` : ""}`;

  const s = useLocalObservable(() =>
    createObservable(current, annotations, {
      ...options,
      name,
      includeWritableKeys: true,
    })
  );

  useEffect(() => {
    runInAction(() => {
      if (options?.debug) console.info(s.__writableKeys);
      for (const key of s.__writableKeys!) {
        if ((s as T)[key] !== current[key]) (s as T)[key] = current[key];
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return s as T;
};

export const usePropsFast = <T extends {}>(current: T) => {
  const s = useLocalObservable(() => ({
    ...current,
  }));
  useEffect(() => {
    runInAction(() => Object.assign(s, current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  return s;
};

/**
 * An alias for useLocalObservable.
 */
export const useStore = useLocalObservable;

/**
 * An alias for useLocalObservable.
 * since the values of the keys are presumed to be CSSObject, it's safe to just use default mobx annotations.
 */
export const useStyle = useLocalObservable;

/**
 *
 * Performs similar function to `useProps` with automatic annotations,
 * but returns a standalone observable object instead of a state in a component.
 *
 * @param current: the props object.
 * @param annotations: Provide explicitly defined MobX observable annotations to override the auto-detected annotations by this utility function.
 *
 */
export const makeObservableStore = <T extends AnyObject = AnyObject>(
  object: T,
  annotations: AuthorableAnnotationMap<T> = {},
  options?: ObservableWrapperOptions
) => createObservable(object, annotations, options);

// ------------------------
// types & internal helpers
// ------------------------

export type AuthorableAnnotationValue = true | false | Annotation;
export type AuthorableAnnotationMap<T extends AnyObject = AnyObject> = Partial<
  Record<keyof T, AuthorableAnnotationValue>
>;
export type ObservableWrapperOptions = {
  name?: string;
  proxy?: boolean;
  autoBind?: boolean;
  includeWritableKeys?: boolean;
  inPlace?: boolean;
  debug?: boolean;
};

/**
 * An observable store where functions are treated as states in the store and thus writable and observable.
 * Two $ signs are used, because this is one level more abstract than the usual controllers.
 * For model factory and controller abstractions, a single $ sign is used for meta-level fields and methods.
 */
export type ObservableStore<T extends UnknownObject = UnknownObject> = T & {
  __writableKeys?: StringKeyList<T>;
  __debug?: () => void;
};

/**
 * Making an object observable with our custom annotations.
 */
export const createObservable = <T extends UnknownObject = UnknownObject>(
  source: T,
  annotations: AuthorableAnnotationMap<T> = {},
  options?: ObservableWrapperOptions
) => {
  const descriptors = Object.getOwnPropertyDescriptors(source);
  const _annotations = { ...annotations };

  Object.entries(descriptors).forEach(([key, desc]) => {
    if (key in _annotations) return;
    // must use get/set to filter out getter/setters first because they might refer to the constructed object,
    // and checking their values directly will result in error "cannot access x before initialization".
    if (desc.get) {
      _annotations[key as StringKeyOf<T>] = computed;
      return;
    }
    if (desc.set) {
      _annotations[key as StringKeyOf<T>] = false; // ignore lone setter
      return;
    }
    if (_presumePropIsReactComponentOrStyle(key) || isFunction(desc.value)) {
      _annotations[key as StringKeyOf<T>] = observable.ref;
      return;
    }
    if (key === "__writableKeys" || key === "__debug") {
      _annotations[key as StringKeyOf<T>] = false; // ignore our own inventions
    }
    // leave the rest to mobx to figure out
    _annotations[key as StringKeyOf<T>] = true;
  });

  if (options?.debug) {
    console.info(descriptors, Object.keys(source), _annotations);
    debugger;
  }

  const s = (options?.inPlace ? makeObservable : observable)(
    source,
    _annotations as AnnotationsMap<T, never>,
    options
  ) as ObservableStore<T>;

  if (options?.includeWritableKeys) {
    const writableKeys = [] as string[];
    extendObservable(s, {
      get __writableKeys() {
        if (writableKeys.length > 0) return writableKeys;
        writableKeys.push(
          ...Object.entries(source)
            .filter(e => (e[1] as PropertyDescriptor)?.writable !== false)
            .map(e => e[0])
        );
        return writableKeys;
      },
    });
  }

  if (isDevelopment) {
    extendObservable(s, {
      __inspect: () => {
        const keys = Object.keys(
          Object.getOwnPropertyDescriptors(source)
        ) as StringKeyList<T>;
        console.info(
          `%c*** [${options?.name ?? "observable"}] debug info  ***`,
          "color: green"
        );
        console.info(s);
        console.info("annotations:", _annotations);
        console.group("keys grouped by:");
        console.info(`      all keys : ${keys.join(" ")}`);
        console.info(
          `    observable : ${keys
            .filter(k => isObservableProp(s, k))
            .join(" ")}`
        );
        console.info(
          `non-observable : ${keys
            .filter(k => !isObservableProp(s, k))
            .join(" ")}`
        );
        console.info(
          `     computeds : ${keys.filter(k => isComputedProp(s, k)).join(" ")}`
        );
        console.info(
          `       actions : ${keys.filter(k => isAction(s[k])).join(" ")}`
        );
        console.info(
          `         flows : ${keys.filter(k => isFlow(s[k])).join(" ")}`
        );
        console.info(`      writable : ${s.__writableKeys?.join(" ")}`);
        console.info(
          `      readonly : ${keys
            .filter(
              k => Object.getOwnPropertyDescriptor(s, k)?.writable === false
            )
            .join(" ")}`
        );
        console.groupEnd();
      },
      __name: options?.name ?? "OBSERVABLE_STORE",
    });
  }

  return s;
};

const _presumePropIsReactComponentOrStyle = (p: string) =>
  p === "children" || p === "style" || /^[A-Z]/.test(p);

export const isFlow = (fn: unknown) =>
  (fn as UnknownObject)?.isMobXFlow === true;

/** @deprecated */
export const delayedComputed = <T>(getter: () => T, delay?: number) => {
  const s = observable({
    value: getter(),
    dispose: () => {},
  });
  runInAction(
    () =>
      (s.dispose = reaction(
        getter,
        flow(function* () {
          yield resolveAfter(delay);
          s.value = getter();
        })
      ))
  );
  return s;
};

export const debouncedComputed = <T>(
  getter: () => T,
  delay?: number,
  fireImmediately?: boolean,
  debugHandle?: string
) => {
  const initialValue = runInAction(getter);
  const s = observable({
    value: initialValue,
    dispose: () => {},
  });
  runInAction(
    () =>
      (s.dispose = reaction(
        getter,
        value => {
          if (debugHandle)
            // eslint-disable-next-line no-console
            console.log(`debouncedComputed [${debugHandle}] detected change`);
          s.value = value;
        },
        { delay, fireImmediately }
      ))
  );
  return s;
};

export const optionalWhen = (predicate: () => boolean, opts?: IWhenOptions) =>
  Promise.any([
    resolveAfter(opts?.timeout ?? 5000),
    async () => await when(predicate, opts),
  ]);

export const createStableReactiveTransformedArray = <T, R>(
  arrayGetter: () => T[],
  transform: (t: T) => R,
  reverseTransform?: (r: R) => T
) => {
  const d = makeDisposerController();
  const s = observable({
    get targets() {
      return arrayGetter();
    },
    map: new Map<T, R>(),
    get value() {
      return Array.from(s.map.values());
    },
    dispose: () => {
      d.dispose();
    },
  });
  d.add(
    reaction(
      () => s.targets,
      (curr = [], prev = []) => {
        const removed = prev.filter(t => !curr.includes(t));
        const added = curr.filter(t => !prev.includes(t));
        removed.forEach(t => {
          if (reverseTransform) {
            const r = s.map.get(t);
            if (r) reverseTransform(r);
          }
          s.map.delete(t);
        });
        added.forEach(t => {
          s.map.set(t, transform(t));
        });
      },
      { fireImmediately: true }
    )
  );
  return s;
};

export type StableReactiveTransformedArray<T, R> = {
  targets: T[];
  map: Map<T, R>;
  value: R[];
  dispose: () => void;
};

export type HasAtomArray = {
  atoms: Atom[];
};

export const watchObservableArrayChanges = <T>(
  arrayGetter: () => T[],
  onAdd: (t: T) => void,
  onRemove?: (t: T) => void
) => {
  return createStableReactiveTransformedArray(
    arrayGetter,
    t => {
      onAdd(t);
      return t;
    },
    onRemove
      ? t => {
          onRemove(t);
          return t;
        }
      : undefined
  ).dispose;
};
