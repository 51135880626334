export const getUrlParam = (key: string) => {
  return new URLSearchParams(window.location.search).get(key);
};

export function getUrlParams<
  T extends Record<string, string> = Record<string, string>
>() {
  const params = new URLSearchParams(window.location.search);
  const paramsObj: Record<string, string> = {};
  params.forEach((value, key) => {
    paramsObj[key] = value;
  });
  return paramsObj as T;
}

export const useGetUrlParams = <T extends StringObject = StringObject>() => {
  return getUrlParams<T>();
};

export function setUrlParams(
  paramsToSet: Record<string, string>,
  behavior: "merge" | "replace" = "merge"
) {
  const searchParams = new URLSearchParams(
    behavior === "merge" ? window.location.search : ""
  );
  Object.entries(paramsToSet).forEach(([key, value]) => {
    searchParams.set(key, value);
  });
  const newUrl = [window.location.pathname, searchParams.toString()]
    .filter(i => i)
    .join("?");

  window.history.replaceState(null, "", newUrl);
}

export function setUrlParam(a: string, b: string) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(a, b);
  const newUrl = [window.location.pathname, searchParams.toString()]
    .filter(i => i)
    .join("?");
  window.history.replaceState(null, "", newUrl);
}

export function removeUrlParam(name: string) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(name); // Remove the parameter if it exists
  const newUrl = [window.location.pathname, searchParams.toString()]
    .filter(i => i)
    .join("?");
  window.history.replaceState(null, "", newUrl);
}

export function removeUrlParams(p: string[]) {
  const searchParams = new URLSearchParams(window.location.search);
  p.forEach(param => searchParams.delete(param));
  const newUrl = [window.location.pathname, searchParams.toString()]
    .filter(i => i)
    .join("?");
  window.history.replaceState(null, "", newUrl); // Update the URL without reloading
}
