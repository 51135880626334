import { computed, makeObservable } from "mobx";
import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { has_id } from "../traits/hasId.trait";
import { StandardModel, appendMissingKeys } from "./StandardModel";
import { Package } from "./Package.model";

export type LibraryEntryInterpretationSummary = {
  _id: string;
  name: string;
  description: string;
  thumbnailDarkId: string;
  thumbnailLightId: string;
  authorIds: string[];
  artistIds: string[];
  arrangerIds: string[];
  _duration: number;
};
export type LibraryEntryScoreSummary = {
  _id: string;
  name: string;
  ownerId: string;
  authorIds: string[];
};
export type LibraryEntryCompositionSummary = {
  title: string;
  subheading: string;
  description: string;
  options: {
    renderTitleAsMarkdown: boolean;
  };
};
export const createLibraryEntrySummaryBase = () => ({
  collectionIds: [] as string[],
  artistIds: [] as string[],
  authorIds: [] as string[],
  arrangerIds: [] as string[],
  composition: {
    title: "",
    subheading: "",
    description: "",
    options: {
      renderTitleAsMarkdown: false,
    },
  } as LibraryEntryCompositionSummary,
  interpretations: [] as LibraryEntryInterpretationSummary[],
  scores: [] as LibraryEntryScoreSummary[],
});

export type LibraryEntrySummary = ReturnType<
  typeof createLibraryEntrySummaryBase
>;

export const LibraryEntrySnapshotFactory = () => ({
  ...has_id(),
  compositionId: "",
  packageId: "",
  summary: createLibraryEntrySummaryBase(),
  stats: {
    views: 0,
    imports: 0,
  },
  timeCreated: "",
  timeArchived: "",
});

const defaultSnapshot = LibraryEntrySnapshotFactory();

export type LibraryEntrySnapshot = ReturnType<
  typeof LibraryEntrySnapshotFactory
>;

export class LibraryEntry extends StandardModel<
  ModelName.libraryEntries,
  LibraryEntrySnapshot
> {
  constructor(LOCALDB: LocalDBController, $ = LibraryEntrySnapshotFactory()) {
    appendMissingKeys($, defaultSnapshot);
    super(ModelName.libraryEntries, LOCALDB, $);
    makeObservable(this, {
      package: computed,
      summary: computed,
      isArchived: computed,
    });
  }
  get package() {
    return this.LOCALDB.get<Package>(ModelName.packages, this.$.packageId);
  }
  get summary() {
    return this.$.summary;
  }
  get isArchived() {
    return !!this.$.timeArchived;
  }
}
