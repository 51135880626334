/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { Link, useLocation } from "react-router-dom";
import cx from "../utils/className.utils";

type RegexLinkProps = {
  className?: string;
  to: string;
  regex?: RegExp;
  regexNot?: RegExp;
  exact?: boolean;
};

const RegexLink: React.FC<PropsWithChildren<RegexLinkProps>> = props => {
  const location = useLocation();
  const regex =
    props.regex ??
    (props.exact
      ? new RegExp(`^${props.to.replace(/\/?$/, `/?`)}$`)
      : new RegExp(`${props.to.replace(/\/?$/, `/?`)}`));
  return (
    <Observer
      children={() => (
        <Link
          className={cx(
            props.className,
            regex.test(location.pathname) &&
              (props.regexNot
                ? !props.regexNot.test(location.pathname)
                : true) &&
              "active"
          )}
          to={props.to}
        >
          {props.children}
        </Link>
      )}
    />
  );
};

export default RegexLink;
