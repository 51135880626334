/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, Bar } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import Spacing from "../../base/components/Spacing";
import PillTag from "../../base/components/PillTag";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import AtomRulesetEditor from "../composer/AtomRulesetEditor";
import TimeSignatureEditor from "../composer/TimeSignatureEditor";
import BarNumberEditor from "../composer/BarNumberEditor";
import styled from "@emotion/styled";
import {
  varFontSerif,
  varPrimary,
} from "../../constants/cssCustomProperties.constants";
import { useComposer } from "../composer/ComposerApp.context";

type BarPropertyPanelProps = {
  atom: Atom;
};

const SectionName = styled.em`
  font-family: ${varFontSerif};
  cursor: pointer;
  &:hover {
    color: ${varPrimary};
  }
`;

const style = {
  grid: {
    display: "grid",
    gridGap: "1em",
    gridTemplateColumns: "1fr 1fr 1fr",
    fontSize: "1.2rem",
  } as CSSPartial,
  header: {
    display: "flex",
    justifyContent: "space-between",
  } as CSSPartial,
  headerInner: {
    paddingRight: "1em",
  } as CSSPartial,
  title: {
    fontSize: "1.6rem",
  } as CSSPartial,
  form: {
    display: "grid",
    gridGap: "1em",
    gridTemplateColumns: "1fr 1fr",
    fontSize: "1.2rem",
  } as CSSPartial,
};

const BarPropertyPanel: React.FC<BarPropertyPanelProps> = props => {
  const p = useProps(props);
  const I = useComposer();
  const s = useStore(() => ({
    get bar() {
      return p.atom as Bar;
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="BarPropertyPanel">
          <header css={style.header}>
            <div css={style.headerInner}>
              <h3 css={style.title}>Bar {s.bar.barNumber}</h3>
              {s.bar.section && (
                <p>
                  Section:{" "}
                  <SectionName
                    onClick={() => {
                      s.bar.section?.select();
                    }}
                  >
                    {s.bar.section.name}
                  </SectionName>
                </p>
              )}
            </div>
            <div>
              <PillTag color={s.bar.voice?.appearance?.colorInContext}>
                #{s.bar._id}
              </PillTag>
            </div>
          </header>
          <Spacing />
          <div css={style.form}>
            <TimeSignatureEditor
              form={s.bar}
              field="timeSignature"
              Label="Time signature"
              disabled={I.editDisabled}
            />
            <BarNumberEditor
              bar={s.bar}
              Label="Bar number"
              disabled={I.editDisabled}
            />
          </div>
          <Spacing />
          <AtomRulesetEditor atom={s.bar} />
        </div>
      )}
    />
  );
};

export default BarPropertyPanel;
