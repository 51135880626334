import chroma from "chroma-js";
import { action, reaction, toJS, when } from "mobx";
import { lighten, setLightness } from "polished";
import { Atom, AtomContext, Bar, Keyframe, Note } from "../@types";
import { makeDisposerController } from "../base/utils/disposer.utils";
import { Composition } from "../models/Composition.model";
import { Interpretation } from "../models/Interpretation.model";
import { ColorPalette } from "../theming/colorPalette";
import { getFrequencyFromMidiNumber } from "./frequency.utils";
import { isNumber } from "../base/utils/typeChecks.utils";
import { InstrumentPrivateStore } from "../instruments/_factory/instrumentPrivateStore";
import { last } from "lodash-es";

function assertAtomContextHasCompAndItp(
  ac: AtomContext
): asserts ac is AtomContext & {
  composition: Composition;
  interpretation: Interpretation;
} {
  if (ac.composition === null)
    throw Error(
      "AtomContext does not have a composition loaded, cannot generate snapshot for visualizer"
    );
  if (ac.interpretation === null)
    throw Error(
      "AtomContext does not have an interpretation loaded, cannot generate snapshot for visualizer"
    );
}

export const getPlaybackNoteSnapshot = (n: Note, defaultColor: string) => {
  const color = n.appearance?.colorInContext ?? defaultColor;
  const lightness = chroma(color).luminance();
  return {
    id: n._id,
    x: n.interpreted.startX!,
    y: n.y!,
    z: n.z || n.voice?.z || 0,
    endX: n.interpreted.endX!,
    width: n.interpreted.width,
    height: n.appearance?.noteYScalar ?? 1,
    color,
    colorCeiledLightness: lightness > 0.95 ? setLightness(0.95, color) : color,
    colorBrighter: lighten(0.4, color),
    velocity: n.interpreted.velocity,
    midiNumber: n.midiNumber!,
    frequency: n.frequency!,
    instruments: toJS(n.interpreted.instruments),
    timeStart: n.interpreted.timeStartInSeconds!,
    timeEnd: n.interpreted.timeEndInSeconds!,
    duration: n.interpreted.durationInSeconds,
    muted: n.muted ?? false,
  };
};

export const generatePlaybackSnapshotNotes = (ac: AtomContext) => {
  return ac.notes
    .filter(
      n =>
        !n.interpreted.ornament &&
        n.interpreted.startX !== null &&
        n.interpreted.width !== null &&
        n.y !== null &&
        n.z !== null
    )
    .map(n => getPlaybackNoteSnapshot(n, ColorPalette.gray))
    .sort((a, b) => a.z - b.z);
};

export const generatePlaybackSnapshotSlim = action((ac: AtomContext) => {
  assertAtomContextHasCompAndItp(ac);
  const { atomSnapshots: ca, ...restOfComp } = ac.composition.$;
  const { atomSnapshots: ci, ...restOfItp } = ac.interpretation.$;
  return {
    composition: restOfComp,
    interpretation: restOfItp,
    startX: ac.startX,
    centerY: ac.centerY,
    duration: ac.durationWithLeading,
    scaledTimeMap: toJS(ac.valueAtXMaps.scaledTime),
  };
});

export const generatePlaybackSnapshotFull = (ac: AtomContext) => {
  return {
    ...generatePlaybackSnapshotSlim(ac),
    notes: generatePlaybackSnapshotNotes(ac),
  };
};

export type NotePlaybackSnapshot = ReturnType<typeof getPlaybackNoteSnapshot>;
export type PlaybackSnapshotSlim = ReturnType<
  typeof generatePlaybackSnapshotSlim
>;
export type PlaybackSnapshotNotesArray = ReturnType<
  typeof generatePlaybackSnapshotNotes
>;
export type PlaybackSnapshotFull = ReturnType<
  typeof generatePlaybackSnapshotFull
>;

const getPlaybackTimeline = (a: Atom) =>
  a.context?.ROOT?.ENSEMBLE.playback?.timeline;

export const setupNoteScheduler = (n: Note) => {
  let attackFn: gsap.core.TimelineChild;
  let releaseAudioFn: gsap.core.TimelineChild;
  let releaseVisualFn: gsap.core.TimelineChild;
  const d = makeDisposerController();
  const scheduleNote = () => {
    const timeline = getPlaybackTimeline(n);
    const ENSEMBLE = n.context?.ROOT?.ENSEMBLE;
    if (!ENSEMBLE || !timeline) return;
    if (attackFn) timeline?.remove(attackFn);
    if (releaseAudioFn) timeline?.remove(releaseAudioFn);
    if (releaseVisualFn) timeline?.remove(releaseVisualFn);
    const position = n.interpreted.timeStartInSeconds!;
    let markNoteOff = () => {};

    attackFn = action(() => {
      if (
        n.interpreted.timeStartInSeconds !== null &&
        n.interpreted.timeStartInSeconds <
          timeline.progress() * timeline.duration() - 0.3
      )
        return;
      const attackResult = ENSEMBLE.attackNote(n);
      if (attackResult) markNoteOff = attackResult.markNoteOff;
    });

    timeline.addLabel(n._id, position);
    timeline.add(attackFn, position);

    releaseVisualFn = action(() => {
      ENSEMBLE.releaseNoteVisual(n, { markNoteOff });
    });
    timeline.add(releaseVisualFn, position + n.interpreted.durationInSeconds);

    releaseAudioFn = action(() => {
      ENSEMBLE.releaseNoteAudio(n);
    });
    timeline.add(releaseAudioFn, position + n.interpreted.adjustedDuration);
  };
  const dispose = () => {
    const timeline = getPlaybackTimeline(n);
    try {
      timeline?.removeLabel(n._id);
      if (attackFn) timeline?.remove(attackFn);
      if (releaseAudioFn) timeline?.remove(releaseAudioFn);
    } catch (e) {
      console.warn(e);
    }
  };
  d.add(dispose);
  d.add(reaction(() => n._isDeleted, dispose));
  d.add(
    reaction(
      () =>
        [
          n.interpreted.timeStartInSeconds,
          n.interpreted.durationInSeconds,
          n.y,
          n.interpreted.velocity,
          n.muted,
        ].join(","),
      scheduleNote,
      { delay: 25 }
    )
  );
  d.add(when(() => !!getPlaybackTimeline(n), scheduleNote));
  return d.dispose;
};

export const setupKeyframeScheduler = (k: Keyframe) => {
  let callback: gsap.core.TimelineChild;
  const d = makeDisposerController();
  const scheduleKeyframe = () => {
    const timeline = getPlaybackTimeline(k);
    const ENSEMBLE = k.context?.ROOT?.ENSEMBLE;
    if (!ENSEMBLE || !timeline) return;
    if (callback) timeline?.remove(callback);
    callback = action(() => {
      const selectTool = k.context?.composer?.tools.select;
      if (
        selectTool?.hasSelection &&
        !selectTool.allKeyframesInSelectionSortedByEndX.includes(k)
      )
        return;
      if (
        k.interpreted.timeStartInSeconds !== null &&
        k.interpreted.timeStartInSeconds <
          timeline.progress() * timeline.duration() - 0.3
      )
        return;
      ENSEMBLE.applyKeyframe(k);
    });
    timeline.add(callback, k.interpreted.timeStartInSeconds);
    timeline.addLabel(k._id, k.interpreted.timeStartInSeconds);
  };
  const dispose = () => {
    const timeline = getPlaybackTimeline(k);
    timeline?.removeLabel(k._id);
    timeline?.remove(callback);
  };
  d.add(reaction(() => k._isDeleted, dispose));
  d.add(dispose);
  d.add(
    reaction(
      () =>
        [
          k.interpreted.timeStartInSeconds,
          k.interpreted.timeEndInSeconds,
          k.controlPath,
          k.value,
        ].join(","),
      scheduleKeyframe,
      { delay: 25 }
    )
  );
  d.add(when(() => !!getPlaybackTimeline(k), scheduleKeyframe));
  return d.dispose;
};

export const setupBarScheduler = (bar: Bar) => {
  let callbacks: [gsap.core.TimelineChild, number][];
  const d = makeDisposerController();
  const scheduleBar = () => {
    const timeline = getPlaybackTimeline(bar);
    const context = bar.context;
    const ENSEMBLE = context?.ROOT?.ENSEMBLE;
    if (!context || !ENSEMBLE || !timeline) return;
    callbacks?.forEach(([c]) => timeline.remove(c));
    timeline.addLabel(bar._id, bar.interpreted.timeStartInSeconds);
    callbacks = Array(bar.beats)
      .fill(0)
      .map((b, i) => {
        const x = bar.startX + bar.beatWidth * i;
        const time = context.getScaledTime(x);
        return [
          () => {
            if (time < timeline.progress() * timeline.duration() - 0.3) return;
            ENSEMBLE.runOnBeat(i, bar);
          },
          time,
        ];
      });
    callbacks.forEach(([callback, time]) => {
      timeline.add(callback, time);
    });
  };
  const dispose = () => {
    const timeline = getPlaybackTimeline(bar);
    timeline?.removeLabel(bar._id);
    callbacks.forEach(([callback]) => timeline?.remove(callback));
  };
  d.add(reaction(() => bar._isDeleted, dispose));
  d.add(dispose);
  d.add(
    reaction(
      () =>
        [
          bar.interpreted.timeStartInSeconds,
          bar.interpreted.timeEndInSeconds,
        ].join(","),
      scheduleBar,
      { delay: 25 }
    )
  );
  d.add(when(() => !!getPlaybackTimeline(bar), scheduleBar));
  return d.dispose;
};

const getOverlappedNoteDuration = (n: Note) => {
  const start = n.interpreted.timeStartInSeconds;
  if (start === null) return 0;
  const noteStarts = (
    [n, ...n.interpreted.overlappingNotesWithSameInstrument]
      .map(n => n.interpreted.timeStartInSeconds)
      .filter(i => i !== null) as number[]
  ).sort((a, b) => a - b);
  const noteEnds = (
    [n, ...n.interpreted.overlappingNotesWithSameInstrument]
      .map(n => n.interpreted.timeEndInSeconds)
      .filter(i => i !== null) as number[]
  ).sort((a, b) => a - b);
  const end = noteStarts.find(e => e > start) ?? last(noteEnds) ?? 0;
  if (end === undefined) return 0;
  const duration = Math.max(
    0,
    (end === last(noteEnds) ? end : end - 0.01) - start
  );
  return duration;
};

export const getNoteAutoAdjustedDuration = (n: Note) =>
  n.interpreted.notesStartingAtTheSameTimeWithSamePitchAndInstrument.length > 0
    ? Math.max(
        ...n.interpreted.notesStartingAtTheSameTimeWithSamePitchAndInstrumentIncludingSelf.map(
          n => n.interpreted.durationInSeconds
        )
      )
    : n.interpreted.overlappingNotesWithSameInstrument.length > 0
    ? getOverlappedNoteDuration(n)
    : n.interpreted.durationInSeconds;

export const getNoteAttackParams = (n: Note, _: InstrumentPrivateStore) => {
  const { midiNumber, beatCount } = n;
  if (!midiNumber || !beatCount || beatCount < 0) return false;
  const frequency = getFrequencyFromMidiNumber(midiNumber, _.a4);
  const velocity = isNumber(_.getters.velocity.attack)
    ? _.getters.velocity.attack
    : _.getters.velocity.attack(n);
  return {
    frequency,
    midiNumber,
    beatCount,
    velocity,
  };
};
