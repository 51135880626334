/* eslint-disable @typescript-eslint/no-unsafe-return */
/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import { CSSPartial } from "../../@types/css.types";
import { IconDefs } from "./iconDefs/_index.iconDefs";

const cssStyle = {
  position: "fixed",
  top: -25,
  left: -25,
  width: 25,
  height: 25,
  overflow: ["hidden", "clip"],
  opacity: 0,
} as CSSPartial;

const IconSymbols: React.FC = observer(() => {
  return (
    <div className="IconSymbols" css={cssStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
      >
        <defs>
          {Object.values(IconDefs).map(def =>
            Object.values(def).map(d => d?.())
          )}
        </defs>
      </svg>
    </div>
  );
});

export default IconSymbols;
