import { action } from "mobx";
import { Atom, AtomContext, GroupLikeAtom, Note, Pattern } from "../@types";
import {
  addOneToArrayIfNew,
  getMostCommonElementInArray,
  keepTruthy,
} from "../base/utils/array.utils";
import { subtractPoints } from "../base/utils/geometry.utils";
import { first } from "../base/utils/ramdaEquivalents.utils";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import { isArray } from "../base/utils/typeChecks.utils";
import { PatternSnapshotFactory } from "../models/atoms/Pattern.model";
import {
  getAtomSetBoundingBox,
  isGroupAtom,
  isGroupLikeAtom,
  isNoteAtom,
  isPatternAtom,
} from "./atoms.utils";
import { getYFromMidiNumber } from "./musicKey.utils";
import { getNoteSetClosestTonicMidiNumber } from "./note.utils";

export type PatternCreationSource =
  | GroupLikeAtom
  | Partial<GroupLikeAtom>
  | null
  | Atom[];

export const createPattern = action(
  (ac: AtomContext, source?: PatternCreationSource) => {
    if (isPatternAtom(source)) return source;
    const nextId = ac.getNextNewAtomId();
    const children: Atom[] = [];
    if (isGroupAtom(source)) {
      children.push(...source.children);
    } else if (isArray(source)) {
      children.push(...source);
    }
    if (children.length < 1) return null;
    const firstChild = children[0];
    const parentIds = children.every(n => n.parents === firstChild.parents)
      ? firstChild.parentIds
      : [];
    const voiceId = children.every(n => n.voice === firstChild.voice)
      ? firstChild.voiceId
      : null;
    const boundingBox = getAtomSetBoundingBox(children);
    const descendantNotes = keepTruthy(
      children
        .map(c => {
          if (isNoteAtom(c)) return c;
          if (isGroupLikeAtom(c)) return c.descendantNotes;
          return null;
        })
        .flat()
    ) as Note[];
    const defaultAnchor = getDefaultAnchorOfNoteSet(descendantNotes) ?? {
      x: boundingBox[0].x,
      y: boundingBox[1].y,
    };
    const newPatternSnapshot = makeSnapshot(PatternSnapshotFactory, {
      _id: nextId,
      name: isGroupAtom(source) ? source.name : undefined,
      x: defaultAnchor.x,
      y: defaultAnchor.y,
      parentIds: isGroupAtom(source) ? source.parentIds : parentIds,
      voiceId: isGroupAtom(source) ? source.voiceId : voiceId,
      boundingBoxRelativeToAnchor: [
        subtractPoints(boundingBox[0], defaultAnchor),
        subtractPoints(boundingBox[1], defaultAnchor),
      ],
    });
    ac.writeContentToArray.push(newPatternSnapshot);
    if (isGroupAtom(source)) ac.removeAtoms([source]);
    const newPattern = ac.getAtomById<Pattern>(newPatternSnapshot._id)!;
    children.forEach(n => n.addParents(newPattern));
    addOneToArrayIfNew(ac.patterns, newPattern);
    return newPattern;
  }
);

export const getDefaultAnchorOfNoteSet = (notes: Note[]) => {
  if (notes.length === 0) return null;
  const closestTonicMidiNumber = getNoteSetClosestTonicMidiNumber(
    notes,
    first(notes)?.tonic
  );
  if (!closestTonicMidiNumber) return null;
  const tonicY = getYFromMidiNumber(closestTonicMidiNumber);
  const notesCenterYArray = notes
    .map(n => n.centerY)
    .filter(y => y !== null) as number[];
  const yMin = Math.min(...notesCenterYArray);
  const yMax = Math.max(...notesCenterYArray);
  const xMin = Math.min(
    ...(notes.map(n => n.startX).filter(x => x !== null) as number[])
  );
  let y = tonicY;
  if (y < yMin) y += 12;
  else if (y > yMax) y -= 12;
  if (y < yMin || y > yMax) {
    // if after shifting an octave, y is still outside of the Y range of this pattern... use the most common note in the pattern as the Y
    y =
      getMostCommonElementInArray([
        first(notes)?.centerY,
        ...notes.map(n => n.centerY),
      ]) ?? 0;
  }
  return {
    x: xMin,
    y,
  };
};
