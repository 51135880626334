/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { useMaybeComposer } from "../../../components/composer/ComposerApp.context";
import {
  FormControlProps,
  HexOrContextColorName,
  Renderable,
} from "../../@types";
import { CSSPartial } from "../../@types/css.types";
import { flex } from "../../styles/helpers/flex.styleHelper";
import {
  addOneToArrayIfNew,
  clearArray,
  removeOneFromArray,
  replaceContents,
} from "../../utils/array.utils";
import cx from "../../utils/className.utils";
import { renderRenderable } from "../../utils/components.utils";
import { equalByString } from "../../utils/equality.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import { setValueOfKey } from "../../utils/object.utils";
import { isNotNil } from "../../utils/ramdaEquivalents.utils";
import { getRandomNumericString } from "../../utils/random.utils";
import { isArray, isObject } from "../../utils/typeChecks.utils";
import { IconName, IconVariant } from "../Symbols/iconDefs/_index.iconDefs";
import SelectDefaultOptionRenderer from "./SelectDefaultOptionRenderer";
import { action } from "mobx";

export type SelectorValueComparator = <T>(a: T, b: T) => boolean;

export type SelectorOptionDescriptor<T extends UnknownObject = UnknownObject> =
  {
    Label?: Renderable;
    icon?: IconName;
    iconVariant?: IconVariant;
    color?: HexOrContextColorName | null;
    value: string;
    disabled?: boolean;
    data?: T;
  };

export type SelectorOptionRendererProps<
  T extends UnknownObject = UnknownObject
> = React.PropsWithChildren<{
  option: SelectorOptionDescriptor<T>;
  isSelected?: boolean;
  onClick?: (option: SelectorOptionDescriptor<T>) => void;
  fullWidth?: boolean;
  color?: string | null;
}>;

export type SelectorOptionRenderer<T extends UnknownObject = UnknownObject> =
  Renderable<SelectorOptionRendererProps<T>>;

export type SelectorProps<
  V extends string | string[] = string,
  T extends UnknownObject = UnknownObject
> = FormControlProps<T, V> & {
  className?: string;
  id?: string;
  options: (SelectorOptionDescriptor<T> | string)[];
  optionRenderer?: SelectorOptionRenderer<T>;
  valueComparator?: SelectorValueComparator;
  direction?: "row" | "column";
  Separator?: Renderable;
  wrap?: boolean;
  nullable?: boolean;
  fullWidth?: boolean;
  singleChoice?: boolean;
};

const Selector = <
  V extends string | string[],
  T extends UnknownObject = UnknownObject
>(
  props: React.PropsWithChildren<SelectorProps<V, T>>
) => {
  const p = useProps(props);
  const I = useMaybeComposer();
  const s = useStore(() => ({
    _id: getRandomNumericString(),
    get id(): string {
      return String(p.id || p.field || s._id);
    },
    get value() {
      if (p.getter) return p.getter();
      if (p.form && isNotNil(p.field))
        return p.form[p.field] as string | string[];
      return "";
    },
    set value(v: string | string[]) {
      if (p.setter) p.setter(v as V);
      else if (p.form && isNotNil(p.field)) setValueOfKey(p.form, p.field, v);
    },
    get isMultiple(): boolean {
      return isArray(s.value);
    },
    get isSingle(): boolean {
      return !isArray(s.value);
    },
    get defaultOptionRenderer(): SelectorOptionRenderer<T> {
      return SelectDefaultOptionRenderer;
    },
    get optionRenderer(): SelectorOptionRenderer<T> {
      return p.optionRenderer ?? s.defaultOptionRenderer;
    },
    get options() {
      return isObject(p.options[0])
        ? (p.options as SelectorOptionDescriptor<T>[])
        : (p.options.map(o => ({ value: o })) as SelectorOptionDescriptor<T>[]);
    },
    get valueComparator() {
      return p.valueComparator || equalByString;
    },
    select: (option: SelectorOptionDescriptor<T>) => {
      const fn = action(() => {
        const prev = s.value;
        if (s.isSingle) {
          if (p.nullable && s.value === option.value) {
            s.value = "";
          } else {
            s.value = option.value;
          }
        } else if (s.isMultiple) {
          const newValueList = [...s.value];
          if (s.value.includes(option.value)) {
            removeOneFromArray(newValueList, option.value);
          } else {
            if (p.singleChoice) clearArray(newValueList);
            addOneToArrayIfNew(newValueList, option.value);
          }
          if (p.setter) p.setter(newValueList as V);
          else replaceContents(s.value as unknown[], newValueList as unknown[]);
        }
        p.onChange?.(s.value as V, prev as V);
      });
      const taskName = p.mergeableTaskName ?? p.taskName;
      if (taskName && I)
        I.runInHistory(taskName, fn, {
          mergeableId: p.mergeableTaskName ?? p.mergeableId,
        });
      else fn();
    },
  }));

  const style = useStore(() => ({
    get component(): CSSPartial {
      return {
        ...flex({
          inline: true,
          direction: p.direction,
          wrap: p.wrap ? "wrap" : "nowrap",
        }),
        listStyleType: "none",
        width: p.fullWidth ? "100%" : undefined,
        "&.disabled": {
          opacity: 0.5,
          pointerEvents: "none",
        },
      };
    },
    get item(): CSSPartial {
      return {
        ...flex({ inline: p.fullWidth ? false : true, direction: p.direction }),
        flex: p.fullWidth ? "1 0 auto" : undefined,
        margin: 0,
        padding: 0,
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <div
          className={cx("Selector", p.className, p.disabled && "disabled")}
          css={style.component}
        >
          {s.options.map((option, i) => (
            <div key={option.value} css={style.item}>
              {renderRenderable(s.optionRenderer, {
                option,
                isSelected: isArray(s.value)
                  ? !!s.value.find(v => s.valueComparator(option.value, v))
                  : s.valueComparator(option.value, s.value),
                onClick: s.select,
                fullWidth: p.fullWidth,
                color: option.color || p.color,
              })}
              {p.Separator &&
                i !== s.options.length - 1 &&
                renderRenderable(p.Separator)}
            </div>
          ))}
        </div>
      )}
    />
  );
};

export default Selector;
