import { makeIconDef } from "./makeIconDef";

export const FocusIconDef = {
  focus: makeIconDef("focus", {
    regular: () => (
      <>
        <circle cx="12.5" cy="12.5" r="6" stroke="currentColor" />
        <path
          d="M12.5 3.5V6.5M6.5 12.5H3.5M12.5 18.5V21.5M18.5 12.5H21.5"
          stroke="currentColor"
        />
      </>
    ),
  }),
};
