/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React from "react";
import IconButton from "../../base/components/IconButton";
import { useControllers } from "../../base/hooks/rootContext.hooks";

type ColorSchemeSwitchProps = {};

const ColorSchemeSwitch: React.FC<ColorSchemeSwitchProps> = props => {
  const { THEME } = useControllers();
  return (
    <Observer
      children={() => (
        <IconButton
          className="LightDarkThemeFlipperButton"
          icon={THEME.isDarkTheme ? "moon" : "sun"}
          onPointerDown={THEME.switchColorScheme}
          css={{
            "&:hover": {
              filter: "brightness(1.1)",
            },
          }}
        />
      )}
    />
  );
};

export default ColorSchemeSwitch;
