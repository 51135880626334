/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { observe } from "mobx";

export type ObjectDidChange<T = AnyObject, V = FIXME> = {
  name: keyof T;
  object: T;
  type: "add" | "update" | "remove";
  oldValue: V;
  newValue: V;
};

export type ObserverHandlers<T extends AnyObject, U = unknown> = {
  update?: (oldValue: U, newValue: U, key: keyof T) => void;
  add?: (newValue: U, key: keyof T) => void;
  remove?: (oldValue: U, key: keyof T) => void;
  splice?: (added: U[], removed: U[], array: U[], key: keyof T) => void;
};

export const applyObserverHandlers = <T extends AnyObject, V extends FIXME>(
  change: ObjectDidChange<T, V>,
  handlers: ObserverHandlers<T, V>
) => {
  const { name, oldValue, newValue, type } = change;
  switch (type) {
    case "update":
      handlers.update && handlers.update(oldValue, newValue, name);
      break;
    case "add":
      handlers.add && handlers.add(newValue, name);
      break;
    case "remove":
      handlers.remove && handlers.remove(oldValue, name);
      break;
    // @ts-ignore
    case "splice":
      handlers.splice &&
        // @ts-ignore
        handlers.splice(change.added, change.removed, change.object, name);
      break;
    default:
      break;
  }
};

export const observeChangesToArray = <T extends {}, V extends T>(
  array: T[],
  handlers: ObserverHandlers<T, V>
) =>
  observe(array, change => {
    // console.info("Change detected on object", change);
    applyObserverHandlers(change as unknown as ObjectDidChange<T, V>, handlers);
  });

export const observeChangesToKey = <T extends {}, V extends FIXME>(
  key: keyof T,
  object: T,
  handlers: ObserverHandlers<T, V>
) =>
  observe(object, change => {
    if (change.name !== key) return;
    // console.info(`Change detected on key ${key} of object`, object, change);
    applyObserverHandlers(change as unknown as ObjectDidChange<T, V>, handlers);
  });
