import {
  Instrument,
  InstrumentMeta,
  InstrumentRange,
  StandardInstrumentOptions,
} from "../../@types";
import { STATIC_HOST } from "../../env";
import { ColorPalette } from "../../theming/colorPalette";
import { createCompositeSamplerInstrumentFactory } from "../_factory/compositeSamplerInstrumentFactory";
import { makeDefaultInstrumentOptions } from "../_factory/defaultInstrumentOptions";

export type MarimbaOptions = StandardInstrumentOptions;

export const makeDefaultMarimbaOptions = (): MarimbaOptions => ({
  ...makeDefaultInstrumentOptions(),
});

export type MarimbaType = Instrument<MarimbaOptions>;

export const MarimbaName = "marimba";

export const MarimbaMeta: InstrumentMeta<MarimbaOptions> = {
  name: MarimbaName,
  displayName: "Marimba",
  source: "VS Chamber Orchestra: Community Edition",
  sourceWebsite: "https://github.com/sgossner/VSCO-2-CE",
  icon: "marimba",
  range: [[31, 89]] as InstrumentRange,
  defaultColor: ColorPalette.violet,
  type: "keyboards",
};

export const makeMarimba = createCompositeSamplerInstrumentFactory({
  defaultOptionsBagBuilder: makeDefaultMarimbaOptions,
  instrumentMeta: MarimbaMeta,
  getters: {
    samplers: n => {
      const velocity = n?.interpreted?.velocity ?? n?.velocity ?? 0.7;
      if (velocity > 0.6667) return ["loud"];
      if (velocity > 0.3333) return ["med"];
      return ["soft"];
    },
  },
  compositeSamplerConfigArray: [
    {
      name: "loud",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/marimba/hit/`,
          urls: {
            B2: "Marimba_hit_Outrigger_B2_loud_01.mp3",
            B4: "Marimba_hit_Outrigger_B4_loud_01.mp3",
            C2: "Marimba_hit_Outrigger_C2_loud_01.mp3",
            C4: "Marimba_hit_Outrigger_C4_loud_01.mp3",
            C6: "Marimba_hit_Outrigger_C6_loud_01.mp3",
            F1: "Marimba_hit_Outrigger_F1_loud_01.mp3",
            F3: "Marimba_hit_Outrigger_F3_loud_01.mp3",
            F5: "Marimba_hit_Outrigger_F5_loud_01.mp3",
            G2: "Marimba_hit_Outrigger_G2_loud_01.mp3",
            G4: "Marimba_hit_Outrigger_G4_loud_01.mp3",
          },
          volume: 15,
          octaveOffset: 1,
        },
      },
    },
    {
      name: "med",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/marimba/hit/`,
          urls: {
            B2: "Marimba_hit_Outrigger_B2_med_01.mp3",
            B4: "Marimba_hit_Outrigger_B4_med_01.mp3",
            C2: "Marimba_hit_Outrigger_C2_med_01.mp3",
            C4: "Marimba_hit_Outrigger_C4_med_01.mp3",
            C6: "Marimba_hit_Outrigger_C6_med_01.mp3",
            F1: "Marimba_hit_Outrigger_F1_med_01.mp3",
            F3: "Marimba_hit_Outrigger_F3_med_01.mp3",
            F5: "Marimba_hit_Outrigger_F5_med_01.mp3",
            G2: "Marimba_hit_Outrigger_G2_med_01.mp3",
            G4: "Marimba_hit_Outrigger_G4_med_01.mp3",
          },
          volume: 20,
          octaveOffset: 1,
        },
      },
    },
    {
      name: "soft",
      samplerConfigs: {
        sustain: {
          baseUrl: `${STATIC_HOST}/instruments/marimba/hit/`,
          urls: {
            B2: "Marimba_hit_Outrigger_B2_soft_01.mp3",
            B4: "Marimba_hit_Outrigger_B4_soft_01.mp3",
            C2: "Marimba_hit_Outrigger_C2_soft_01.mp3",
            C4: "Marimba_hit_Outrigger_C4_soft_01.mp3",
            C6: "Marimba_hit_Outrigger_C6_soft_01.mp3",
            F1: "Marimba_hit_Outrigger_F1_soft_01.mp3",
            F3: "Marimba_hit_Outrigger_F3_soft_01.mp3",
            F5: "Marimba_hit_Outrigger_F5_soft_01.mp3",
            G2: "Marimba_hit_Outrigger_G2_soft_01.mp3",
            G4: "Marimba_hit_Outrigger_G4_soft_01.mp3",
          },
          volume: 25,
          octaveOffset: 1,
        },
      },
    },
  ],
});
