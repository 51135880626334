/** @jsxImportSource @emotion/react */
import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Keyframe } from "../../@types";
import { FormControlProps } from "../../base/@types";
import Dropdown, { DropdownOptionDefSet } from "../../base/components/Dropdown";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { isNumber } from "../../base/utils/typeChecks.utils";
import { KnownKeyframeControlPath } from "../../constants/keyframe.constants";
import { MusicKey } from "../../constants/musicKeys.constants";
import { MusicScaleName } from "../../constants/musicScales.constants";
import { DefaultBpm } from "../../utils/beats.utils";
import { isValidMusicKey } from "../../utils/musicKey.utils";
import { isKnownMusicScaleName } from "../../utils/musicScale.utils";
import { getValueAtXFromMap } from "../../utils/valueAtXMap.utils";
import { useAtomContext, useComposer } from "./ComposerApp.context";

type KeyframePathEditorProps<T extends UnknownObject = UnknownObject> =
  FormControlProps<T, string> & {
    keyframe: Keyframe;
    form: Record<keyof Pick<Keyframe, "controlPath" | "value">, unknown>;
    onChange?: (v?: KnownKeyframeControlPath | string) => void;
    disabled?: boolean;
  };

const options: DropdownOptionDefSet = [
  {
    label: "Sustain Pedal",
    value: KnownKeyframeControlPath["sustainPedal"],
  },
  {
    label: "Speed Scalar",
    value: KnownKeyframeControlPath["speedScalar"],
  },
  {
    label: "BPM Change",
    value: KnownKeyframeControlPath["bpmChange"],
  },
  {
    label: "Key Change",
    value: KnownKeyframeControlPath["musicKeyChange"],
  },
  {
    label: "Scale Change",
    value: KnownKeyframeControlPath["musicScaleChange"],
  },
];

const KeyframePathEditor: React.FC<KeyframePathEditorProps> = props => {
  const p = useProps(props);
  const ac = useAtomContext();
  const I = useComposer();
  const s = useStore(() => ({
    get x() {
      return p.keyframe.x;
    },
    handlePathChange: (path?: KnownKeyframeControlPath) => {
      const fn = action(() => {
        switch (path) {
          case KnownKeyframeControlPath.musicKeyChange: {
            if (!isValidMusicKey(`${p.form.value}`)) {
              p.form.value = ac.composition?.options.musicKey ?? MusicKey.C;
            }
            break;
          }
          case KnownKeyframeControlPath.musicScaleChange: {
            if (!isKnownMusicScaleName(`${p.form.value}`)) {
              p.form.value =
                ac.composition?.options.musicScaleName ?? MusicScaleName.Ionian;
            }
            break;
          }
          case KnownKeyframeControlPath.bpmChange: {
            if (!isNumber(p.form.value)) {
              p.form.value =
                s.x !== null
                  ? getValueAtXFromMap(s.x, ac.valueAtXMaps.bpm) || DefaultBpm
                  : DefaultBpm;
            }
            break;
          }
          case KnownKeyframeControlPath.speedScalar: {
            if (
              !isNumber(p.form.value) ||
              (isNumber(p.form.value) && p.form.value < 0.001)
            )
              p.form.value = 1;
            break;
          }
          case KnownKeyframeControlPath.sustainPedal: {
            if (!isNumber(p.form.value)) p.form.value = 1;
          }
        }
        const prev = p.form.controlPath;
        p.form.controlPath = path ?? "";
        p.onChange?.(path, prev as string);
      });
      const taskName = p.mergeableTaskName ?? p.taskName;
      if (taskName && I)
        I.runInHistory(taskName, fn, {
          mergeableId: p.mergeableTaskName ?? p.mergeableId,
        });
      else fn();
    },
  }));
  return (
    <Observer
      children={() => (
        <div>
          <Dropdown<KnownKeyframeControlPath>
            form={p.form}
            field="controlPath"
            allowEmpty
            options={options}
            onChange={s.handlePathChange}
            taskName={p.taskName}
            mergeableId={p.mergeableId}
            mergeableTaskName={p.mergeableTaskName}
            disabled={p.disabled}
          />
        </div>
      )}
    />
  );
};

export default KeyframePathEditor;
