import { round } from "../base/utils/math.utils";
import { isString } from "../base/utils/typeChecks.utils";
import { getMidiNumberFromMusicKeyName } from "./musicKey.utils";

const notes = ["A", "A#", "B", "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#"];

export const getFrequencyFromNoteName = (noteName: string, A4Hz = 440) => {
  const octave = parseInt(noteName.charAt(noteName.length - 1));
  let midi = notes.indexOf(noteName.slice(0, -1));
  if (midi < 3) {
    midi = midi + 12 + (octave - 1) * 12 + 1;
  } else {
    midi = midi + (octave - 1) * 12 + 1;
  }
  return getFrequencyFromMidiNumber(midi, A4Hz);
};

/** 69 = A4 */
export const getFrequencyFromMidiNumber = (midi: number, a4 = 440) => {
  return round(a4 * Math.pow(2, (midi - 69) / 12));
};

export const getMidiNumberFromFrequency = (f: number, a4 = 440) => {
  return round((Math.log(f / a4) / Math.log(2)) * 12 + 69);
};

export const getMidiNumberFromStringOrNumber = (which: string | number) => {
  if (isString(which)) return getMidiNumberFromMusicKeyName(which);
  return which;
};

export const getFrequencyFromStringOrNumber = (options: {
  which: string | number;
  inputType?: "midi" | "frequency";
  a4?: number;
}) => {
  const { which, inputType, a4 } = options;
  if (inputType === "frequency") return +which;
  if (inputType === "midi") return getFrequencyFromMidiNumber(+which, a4);
  if (isString(which)) {
    const midiNumber = getMidiNumberFromMusicKeyName(which);
    if (midiNumber) return getFrequencyFromMidiNumber(midiNumber, a4);
    return null;
  }
  return null;
};
