import { computed, makeObservable } from "mobx";
import { ModelName } from "../constants/modelName.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { has_id } from "../traits/hasId.trait";
import { Composition } from "./Composition.model";
import { makeSnapshot } from "../base/utils/snapshot.utils";
import {
  StandardModel,
  addChangeDetectionToModel,
  appendMissingKeys,
} from "./StandardModel";
import { hasOwnerAndAuthors } from "../traits/hasOwnerAndAuthors.trait";
import { uniq } from "../base/utils/ramdaEquivalents.utils";
import { hasTimestamps } from "../traits/hasTimestamps.trait";

export type CollectionSnapshot = ReturnType<typeof CollectionSnapshotFactory>;

export const CollectionSnapshotFactory = () => ({
  ...has_id(),
  name: "",
  subheading: "",
  description: "",
  color: "",
  coverImageId: null as string | null,
  compositionIds: [] as string[],
  ...hasOwnerAndAuthors(),
  ...hasTimestamps(),
});

const defaultSnapshot = CollectionSnapshotFactory();

const changeDetectionSnapshotMaker = ($: CollectionSnapshot) => {
  const {
    _id,
    name,
    subheading,
    description,
    color,
    coverImageId,
    compositionIds,
  } = $;
  return JSON.stringify({
    _id,
    name,
    subheading,
    description,
    color,
    coverImageId,
    compositionIds,
  });
};

export type CollectionItem = {
  _id: "";
  composition: Composition | null;
};

export class Collection extends StandardModel<
  ModelName.collections,
  CollectionSnapshot
> {
  constructor(LOCALDB: LocalDBController, $ = CollectionSnapshotFactory()) {
    appendMissingKeys($, defaultSnapshot);
    super(ModelName.collections, LOCALDB, $);
    makeObservable(this, {
      artists: computed,
      owner: computed,
      authors: computed,
      coverImage: computed,
      compositions: computed,
      isArchived: computed,
    });
    addChangeDetectionToModel(
      this,
      LOCALDB,
      changeDetectionSnapshotMaker,
      this.d
    );
  }
  get owner() {
    return this.LOCALDB.get(ModelName.users, this.$.ownerId);
  }
  get authors() {
    return this.LOCALDB.getMany(ModelName.users, this.$.authorIds);
  }
  get artists() {
    return uniq(
      this.compositions
        .map(c => c.artists)
        .flat()
        .filter(i => i)
    );
  }
  get compositions() {
    return this.LOCALDB.getMany<Composition>(
      ModelName.compositions,
      this.$.compositionIds
    ).filter(c => !c.isArchived);
  }
  get isArchived() {
    return !!this.$.timeArchived;
  }
  get coverImage() {
    return this.LOCALDB.getFileRecord(this.$.coverImageId);
  }
}

export const makeCollectionSnapshot = (partial: Partial<CollectionSnapshot>) =>
  makeSnapshot(CollectionSnapshotFactory, partial);
