import {
  getRandomItemFromArray,
  getRandomNumericString,
} from "../base/utils/random.utils";

const adjectives = [
  "Acidic",
  "Aged",
  "Aloof",
  "Atomic",
  "Basic",
  "Boozy",
  "Boring",
  "Bronze",
  "Clumsy",
  "Cosmic",
  "Crunchy",
  "Dapper",
  "Dappled",
  "Dashy",
  "Dissonant",
  "Edgy",
  "Edible",
  "Ethereal",
  "Express",
  "Extra",
  "Fabulous",
  "Fast",
  "Feisty",
  "Fluffy",
  "Frisky",
  "Fugal",
  "Giant",
  "Gooey",
  "Gritty",
  "Groovy",
  "Hapless",
  "Haunted",
  "Huge",
  "Inky",
  "Instant",
  "International",
  "Irresistible",
  "Jammy",
  "Jazzy",
  "Jumpy",
  "Kinetic",
  "Knotty",
  "Lazy",
  "Little",
  "Lofty",
  "Lucid",
  "Majestic",
  "Massive",
  "Microtonal",
  "Minty",
  "Narrow",
  "Nasty",
  "Nautical",
  "Negative",
  "Norwegian",
  "Nostalgic",
  "Oceanic",
  "Orbital",
  "Orchestral",
  "Organic",
  "Overcast",
  "Pink",
  "Plucky",
  "Quaint",
  "Qualified",
  "Rough",
  "Rugged",
  "Rustic",
  "Salty",
  "Sassy",
  "Scientific",
  "Shiny",
  "Silver",
  "Slow",
  "Snarky",
  "Southern",
  "Spicy",
  "Tantalizing",
  "Ticklish",
  "Unapologetic",
  "Undercover",
  "Vast",
  "Venetian",
  "Vintage",
  "Volatile",
  "Wholesome",
  "Winter",
  "Wise",
  "Woody",
  "Yawning",
  "Zesty",
];

const nouns = [
  "Acorn",
  "Anthem",
  "Ape",
  "Badger",
  "Banana",
  "Bass",
  "Bonfire",
  "Broccoli",
  "Bus",
  "Cactus",
  "Candle",
  "Cicada",
  "Comet",
  "Cucumber",
  "Dinner",
  "Dinosaur",
  "Donut",
  "Duck",
  "Echo",
  "Emoji",
  "Fern",
  "Ferret",
  "Fig",
  "Forest",
  "Fork",
  "Gaze",
  "Goose",
  "Hammer",
  "Harpsichord",
  "Hibiscus",
  "House",
  "Human",
  "Iceberg",
  "Illusion",
  "Jogger",
  "Jug",
  "Jungle",
  "Kale",
  "King",
  "Kitty",
  "Kiwi",
  "Lagoon",
  "Lemur",
  "Mango",
  "Monster",
  "Mountain",
  "Mushroom",
  "Neptune",
  "Nomad",
  "Noodle",
  "Octopus",
  "Orbit",
  "Ox",
  "Oxygen",
  "Painter",
  "Paprika",
  "Photon",
  "Pitcher",
  "Pony",
  "Potato",
  "Quasar",
  "Quaver",
  "Queen",
  "Raven",
  "Reflection",
  "Riff",
  "River",
  "Robot",
  "Rope",
  "Sage",
  "Saturn",
  "Snapper",
  "Sonata",
  "Spaceship",
  "Tesseract",
  "Toccata",
  "Toothbrush",
  "Umbrella",
  "Uranus",
  "Vegetable",
  "Volleyball",
  "Walnut",
  "Whiskey",
  "Windmill",
  "Wrath",
  "Xylophone",
  "Yarn",
  "Yolk",
  "Zenith",
  "Zipper",
];

export const generateUsername = () => {
  return [
    getRandomItemFromArray(adjectives),
    getRandomItemFromArray(nouns),
    getRandomNumericString(4),
  ]
    .join("-")
    .toLowerCase();
};
