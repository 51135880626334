/** @jsxImportSource @emotion/react */
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, Pattern } from "../../@types";
import { CSSPartial } from "../../base/@types/css.types";
import Button from "../../base/components/Button";
import SpaceChildren from "../../base/components/SpaceChildren";
import Spacing from "../../base/components/Spacing";
import TextInput from "../../base/components/TextInput";
import { useOnMount } from "../../base/hooks/lifecycle.hooks";
import { grid6col } from "../../base/styles/partials/grids.stylePartials";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { fg15 } from "../../constants/cssCustomProperties.constants";
import AtomBarRangeDisplay from "../composer/AtomBarRangeDisplay";
import AtomVoiceSelector from "../composer/AtomVoiceSelector";
import { useComposer } from "../composer/ComposerApp.context";
import PatternReplicaManager from "../composer/PatternReplicaManager";
import AtomThumbnailCanvas from "../composer/AtomThumbnailCanvas";
import PillTag from "../../base/components/PillTag";
import { UNITS } from "../../base/constants/units.constant";
import ReferrerCounter from "../composer/ReferrerCounter";
import Checkbox from "../../base/components/Checkbox";

interface PatternPropertyPanelProps {
  atom: Atom;
}

const style = {
  header: {
    display: "flex",
    justifyContent: "space-between",
  } as CSSPartial,
  headerInner: {
    paddingRight: "1em",
  } as CSSPartial,
  title: {
    fontSize: "1.6rem",
  } as CSSPartial,
  patternThumbnailWrap: {
    padding: "1em",
    border: `${UNITS.lineWidth}px solid ${fg15}`,
    canvas: {
      maxWidth: "100%",
    },
  } as CSSPartial,
};

const PatternPropertyPanel: React.FC<PatternPropertyPanelProps> = observer(
  props => {
    const p = useProps(props);
    const I = useComposer();
    const s = useStore(() => ({
      get pattern() {
        return p.atom as Pattern;
      },
      resetBoundingBox: () => {
        I.runInHistory(
          `Reset "${p.atom.displayName}" bounding box`,
          s.pattern.resetBoundingBox
        );
      },
      resetAnchor: () => {
        I.runInHistory(
          `Reset "${p.atom.displayName}" anchor`,
          s.pattern.resetAnchor
        );
      },
      insertReplica: () => {
        I.atomContext.duplicateAtoms({
          atoms: [s.pattern],
          inVoice: I.focusedCanvas?.writeToVoice,
          atPosition: I.focusedCanvas?.primaryCursor?.x,
        });
      },
      setAnchorX: (v: unknown) => {
        s.pattern.setAnchorX(parseFloat(`${v}`));
      },
      setAnchorY: (v: unknown) => {
        s.pattern.setAnchorY(parseFloat(`${v}`));
      },
    }));
    useOnMount(action(() => {}));
    return (
      <div className="PatternPropertyPanel">
        <header css={style.header}>
          <div css={style.headerInner}>
            <h3 css={style.title}>{s.pattern.displayName}</h3>
            <p>
              <strong>
                <AtomBarRangeDisplay atom={s.pattern} />
                {s.pattern.replicas.length > 0 && (
                  <>
                    , <ReferrerCounter atom={s.pattern} />
                  </>
                )}
              </strong>
            </p>
          </div>
          <div>
            <PillTag color={s.pattern.appearance?.colorInContext}>
              #{s.pattern._id}
            </PillTag>
          </div>
        </header>
        <Spacing size="0.5em" />
        <SpaceChildren size="0.5em">
          <div css={style.patternThumbnailWrap}>
            <AtomThumbnailCanvas target={s.pattern} />
          </div>

          <h4>Pattern name</h4>

          <TextInput
            form={s.pattern.$}
            field="name"
            taskName={`Update pattern name`}
            mergeableId={`update-pattern-${s.pattern._id}-name`}
            autoComplete="off"
            disabled={I.editDisabled}
          />

          <h4>Pattern anchor</h4>

          <div css={grid6col.grid}>
            <TextInput
              getter={() => `${s.pattern.$.x ?? ""}`}
              setter={s.setAnchorX}
              type="number"
              css={grid6col.third}
              taskName={`Changing "${p.atom.displayName}" anchor X`}
              mergeableId={`update-pattern-${s.pattern._id}-anchor-x`}
              disabled={I.editDisabled}
            />
            <TextInput
              getter={() => `${s.pattern.$.y ?? ""}`}
              setter={s.setAnchorY}
              type="number"
              css={grid6col.third}
              taskName={`Changing "${p.atom.displayName}" anchor Y`}
              mergeableId={`update-pattern-${s.pattern._id}-anchor-y`}
              disabled={I.editDisabled}
            />
            <Button
              onClick={s.resetAnchor}
              fullWidth
              css={grid6col.third}
              disabled={I.editDisabled}
            >
              Reset
            </Button>
          </div>

          <Spacing size=".25em" />

          {/* <GroupLikeAtomChildrenSpacingEditor atom={s.pattern} />

          <Spacing size=".25em" /> */}

          <AtomVoiceSelector
            css={grid6col.full}
            atom={s.pattern}
            fullWidth
            disabled={I.editDisabled}
          />

          <Spacing size=".5em" />

          {I.editable && (
            <>
              <h4>Options</h4>

              <Spacing size=".5em" />

              <Checkbox
                form={s.pattern}
                field="useClickThroughBoundingBox"
                disabled={I.editDisabled}
              >
                Select only with ❖ label
              </Checkbox>

              <Spacing size=".5em" />

              <h4>Actions</h4>

              <div css={grid6col.grid}>
                <Button
                  onClick={s.resetBoundingBox}
                  fullWidth
                  css={grid6col.half}
                >
                  Reset bounding box
                </Button>
                <Button onClick={s.insertReplica} fullWidth css={grid6col.half}>
                  Insert a replica
                </Button>
              </div>
            </>
          )}

          <PatternReplicaManager pattern={s.pattern} />
        </SpaceChildren>
      </div>
    );
  }
);

export default PatternPropertyPanel;
