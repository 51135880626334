/** @jsxImportSource @emotion/react */
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren, ReactNode } from "react";
import cx from "../../base/utils/className.utils";
import { useProps } from "../../base/utils/mobx.utils";
import AppNavigation from "./AppNavigation";
import {
  fromTablet,
  mediaFromDesktop,
  mediaFromDesktopMd,
  mediaFromPhoneLg,
  mediaFromTablet,
  mediaFromTabletLg,
} from "../../base/styles/helpers/mediaQueries.styling";
import Title from "../app/Title";
import {
  VAR_AppPageInset,
  fg50,
  varFontSerif,
  varPrimary,
  varPrimary50,
  varVh,
} from "../../constants/cssCustomProperties.constants";
import { CSSPartial } from "../../base/@types/css.types";
import { cssVarAppPageInset } from "../../utils/css.utils";

type AppPageProps = {
  className?: string;
  title: string;
};

const style = {
  page: {
    [VAR_AppPageInset]: "1.5em",
    ...mediaFromPhoneLg({ [VAR_AppPageInset]: "2em" } as CSSPartial),
    ...mediaFromTablet({ [VAR_AppPageInset]: "2.5em" } as CSSPartial),
    ...mediaFromTabletLg({
      [VAR_AppPageInset]: "3.5em",
    } as CSSPartial),
    ...mediaFromDesktop({ [VAR_AppPageInset]: "3em" } as CSSPartial),
    ...mediaFromTablet({
      display: "grid",
      gridTemplateColumns: "27.5rem minmax(0, 1fr)",
      gridTemplateAreas: "'nav content'",
    }),
    minHeight: varVh,
    // backgroundImage: `radial-gradient(100% 38% at 62% 100%, ${fg40} 0%, ${fg00} 62%);`,
    // backgroundAttachment: "fixed",
  } as CSSPartial,
  nav: {
    gridArea: "nav",
  } as CSSPartial,
  content: {
    gridArea: "content",
    padding: cssVarAppPageInset(),
    ...mediaFromTablet({ paddingLeft: 0 }),
    ...mediaFromDesktopMd({ paddingLeft: cssVarAppPageInset() }),
  } as CSSPartial,
  header: {
    display: "flex",
    justifyContent: "space-between",
    h1: {
      fontSize: "4.2rem",
      fontFamily: varFontSerif,
      fontWeight: 200,
    },
    "&.largerHeading": {
      h1: {
        fontSize: "3.8rem",
        fontWeight: 200,
        fontFamily: varFontSerif,
        ...mediaFromTablet({
          fontWeight: 100,
          fontSize: "7.2rem",
        }),
        ...mediaFromDesktop({
          fontSize: "7.8rem",
        }),
      },
    },
    "+ *": {
      marginTop: "1em",
    },
  } as CSSPartial,
  headerPreHeading: {
    fontFamily: varFontSerif,
    fontSize: "2.4rem",
    fontWeight: 300,
    color: fg50,
    lineHeight: "1.2",
    a: {
      textDecoration: "none",
      "&:hover": {
        color: varPrimary,
      },
    },
  } as CSSPartial,
  headerStart: {
    paddingRight: "2em",
    paddingBottom: "1em",
    "> * + *": {
      marginTop: "1.4rem",
    },
    "> * + h1": {
      marginTop: "0",
    },
  } as CSSPartial,
  headerEnd: {
    alignSelf: "center",
  } as CSSPartial,
  body: {
    h2: {
      fontSize: "150%",
      fontFamily: varFontSerif,
      fontWeight: 300,
      marginTop: "1.5em",
      [fromTablet]: {
        fontSize: "175%",
      },
      "+ *": {
        marginTop: "1.5em",
      },
    },
  } as CSSPartial,
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "2em",
    "+ *": {
      marginTop: "2em",
    },
  } as CSSPartial,
  sectionHeaderStart: {
    flex: "1 1 auto",
    paddingRight: "2em",
    h2: {
      marginTop: 0,
    },
  } as CSSPartial,
  sectionHeaderEnd: {
    a: {
      color: varPrimary,
      textDecoration: "none",
      fontWeight: 600,
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: varPrimary50,
      },
    },
  } as CSSPartial,
};

const AppPage: React.FC<PropsWithChildren<AppPageProps>> = props => {
  const p = useProps(props);

  return (
    <Observer
      children={() => (
        <div className={cx("AppPage", p.className)} css={style.page}>
          <Title>{p.title}</Title>
          <AppNavigation css={style.nav} />
          <div css={style.content}>{props.children}</div>
        </div>
      )}
    />
  );
};

export const AppPageHeader = (
  props: PropsWithChildren<{
    End?: ReactNode;
    largerHeading?: boolean;
  }>
) => {
  return (
    <header
      css={style.header}
      className={cx(props.largerHeading && "largerHeading")}
    >
      <div css={style.headerStart}>{props.children}</div>
      {props.End && <div css={style.headerEnd}>{props.End}</div>}
    </header>
  );
};

export const AppPageHeaderPreHeading = (props: PropsWithChildren<{}>) => (
  <p css={style.headerPreHeading}>{props.children}</p>
);

export const AppPageBody = (props: PropsWithChildren<{}>) => {
  return <div css={style.body}>{props.children}</div>;
};

export const AppPageSectionHeader = (
  props: PropsWithChildren<{
    End?: ReactNode;
  }>
) => {
  return (
    <header css={style.sectionHeader}>
      <div css={style.sectionHeaderStart}>{props.children}</div>
      {props.End && <div css={style.sectionHeaderEnd}>{props.End}</div>}
    </header>
  );
};

export default AppPage;
