import { isEqual, uniq } from "lodash-es";
import { Has_Id } from "../../traits/hasId.trait";
import { isArray, isObject } from "./typeChecks.utils";

export const diff = <T extends {}>(
  a: T,
  b: T
): {
  changed: Partial<T>;
  removed: Partial<T>;
} => {
  const changed: Partial<T> = {};
  const removed: Partial<T> = {};
  const keys = uniq([...Object.keys(a), ...Object.keys(b)]) as (keyof T)[];
  keys.forEach(key => {
    const aValue = a[key];
    const bValue = b[key];
    if (bValue === undefined && aValue !== undefined) {
      removed[key] = bValue;
    } else if (isArray(aValue) || isArray(bValue)) {
      if (aValue === undefined || aValue === null) {
        changed[key] = bValue;
      } else {
        const arrayToUseInCheck = (
          (aValue as unknown[] | undefined)?.length
            ? aValue
            : (bValue as unknown[] | undefined)?.length
            ? bValue
            : []
        ) as unknown[];
        const firstElement = isObject(arrayToUseInCheck[0])
          ? arrayToUseInCheck[0]
          : null;
        if (firstElement && "_id" in firstElement) {
          const arrDiff = diffArrayOfObjectsWithId(
            aValue as Has_Id[],
            bValue as Has_Id[]
          );
          if (arrDiff.changed.length > 0)
            changed[key] = arrDiff.changed as T[keyof T];
          if (arrDiff.removed.length > 0)
            removed[key] = arrDiff.removed as T[keyof T];
        } else {
          if (!isEqual(aValue, bValue)) {
            changed[key] = bValue;
          }
        }
      }
    } else if (isObject(aValue)) {
      if (!isEqual(aValue, bValue)) {
        changed[key] = bValue;
      }
    } else {
      if (aValue !== bValue) {
        changed[key] = bValue;
      }
    }
  });
  const result = { changed, removed };
  return result;
};

export const diffArrayOfObjectsWithId = <T extends Has_Id>(
  A: T[],
  B: T[]
): {
  changed: T[];
  removed: T[];
} => {
  const pairs = new Map<string, { a?: T; b?: T }>(A.map(a => [a._id, { a }]));
  B.forEach(b => pairs.set(b._id, { ...pairs.get(b._id), b }));
  const changed: T[] = [];
  const removed: T[] = [];
  pairs.forEach(({ a, b }) => {
    if (a && !b) removed.push(a);
    else if (!a && b) changed.push(b);
    else if (!isEqual(a, b)) {
      changed.push(b!);
    }
  });
  return {
    changed,
    removed,
  };
};
