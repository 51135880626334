import chroma from "chroma-js";

const defaultLight = "#f6e9df";
const defaultShade = "#091010";

export const ColorPalette = {
  white: "#FFFFFF",
  black: "#000000",
  light: defaultLight,
  shade: defaultShade,
  lightGray: chroma.mix(defaultLight, defaultShade, 0.38).hex(),
  gray: chroma.mix(defaultLight, defaultShade, 0.5).hex(),
  darkGray: chroma.mix(defaultLight, defaultShade, 0.62).hex(),
  ivory: "#F6D0B3",
  red: "#E14446",
  orange: "#EE9351",
  yellow: "#FBB752",
  apricot: "#DA7C0E",
  eucalyptus: "#86B082",
  pistachio: "#D5DE73",
  grass: "#669339",
  green: "#018F48",
  lime: "#7DA50D",
  moss: "#5A6820",
  neonGreen: "#33DB9E",
  blue: "#0968F6",
  violet: "#B33DFC",
  purple: "#72629F",
  teal: "#4BA6AC",
  flamingo: "#FF6F6F",
  summer: "#3A9AB9",
  cobalt: "#1954AD",
  orchid: "#8444A3",
  crocodile: "#375E3F",
};

const {
  light: lightColor,
  shade: shadeColor,
  white,
  black,
  lightGray,
  gray,
  darkGray,
  ivory,
  ...rest
} = ColorPalette;

export const vibrantColors = rest;
