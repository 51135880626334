import { makeIconDef } from "./makeIconDef";

export const OrganIconDef = {
  organ: makeIconDef("organ", {
    regular: () => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 5V6.5V20H14V6.5V5H11ZM15 6V4.5C15 4.22386 14.7761 4 14.5 4H10.5C10.2239 4 10 4.22386 10 4.5V6H7.5C7.22386 6 7 6.22386 7 6.5V9H5.5C5.22386 9 5 9.22386 5 9.5V11H4.5C4.22386 11 4 11.2239 4 11.5V20.5C4 20.7761 4.22386 21 4.5 21H5.5H7.5H10.5H14.5H17.5H19.5H20.5C20.7761 21 21 20.7761 21 20.5V11.5C21 11.2239 20.7761 11 20.5 11H20V9.5C20 9.22386 19.7761 9 19.5 9H18V6.5C18 6.22386 17.7761 6 17.5 6H15ZM19 11.5V10H18V20H19V11.5ZM6 20V11.5V10H7V20H6ZM17 9.5V7H15V20H17V9.5ZM8 20V9.5V7H10V20H8Z"
          fill="currentColor"
        />
        <rect
          opacity="0.5"
          x="4"
          y="17"
          width="17"
          height="1"
          fill="currentColor"
        />
      </>
    ),
  }),
};
