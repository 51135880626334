import { ArrayElementType } from "../@types";
import { useStore } from "./mobx.utils";
import { first } from "./ramdaEquivalents.utils";

export const getMixedValueOfField = <T extends {}>(
  array: T[],
  field: keyof T
) => {
  const f = first(array);
  if (!f) return null;
  return array.every(i => i[field] === f[field]) ? f[field] : "Mixed";
};
export const setMixedValueOfField = <T extends {}>(
  array: T[],
  field: keyof T,
  value: unknown
) => {
  array.forEach(i => Reflect.set(i, field, value));
};

export const makeMixedValueForm = <T extends {}>(
  arrayGetter: () => T[],
  fields: (keyof T)[]
) => {
  const form = {} as Record<ArrayElementType<typeof fields>, unknown>;
  fields.forEach(field => {
    Reflect.defineProperty(form, field, {
      get: function () {
        return getMixedValueOfField(arrayGetter(), field);
      },
      set: function (v) {
        setMixedValueOfField(arrayGetter(), field, v);
      },
    });
  });
  return form;
};

export const useMixedValueFormState = <T extends {}>(
  arrayGetter: () => T[],
  fields: (keyof T)[]
) => useStore(() => makeMixedValueForm(arrayGetter, fields));
