import { NumericRange, Point, ValidPoint, ValidRect } from "../base/@types";
import { ComposerInstance } from "../components/composer/useCreateComposerInstance";
import { CCanvasState } from "../components/composerCanvas/useMakeComposerCanvasState";
import { MusicKey, MusicKeyNumber } from "../constants/musicKeys.constants";
import { RootControllerChildren } from "../controllers/_controller.types";
import { makeAtomBaseSnapshot } from "../logic/Atom.factory";
import type { makeAtomContextOptions } from "../logic/AtomContext.maker";
import { Composition } from "../models/Composition.model";
import { Interpretation } from "../models/Interpretation.model";
import { BarSnapshotFactory } from "../models/atoms/Bar.model";
import { GroupSnapshotFactory } from "../models/atoms/Group.model";
import { NoteSnapshotFactory } from "../models/atoms/Note.model";
import { VoiceSnapshotFactory } from "../models/atoms/Voice.model";
import {
  ScaleTransformStep,
  TransformController,
  TransformStep,
} from "../transformers/transform.controller";
import { Instrument } from "./instruments.types";
import { Interpreter } from "../logic/interpreter.controller";
import { RuleController } from "../logic/interpreterRule.controller";
import { RuleProperties } from "./interpretations.types";
import { LocalDBController } from "../controllers/localDB.controller";
import { PatternSnapshotFactory } from "../models/atoms/Pattern.model";
import { ChordSnapshotFactory } from "../models/atoms/Chord.models";
import { KnownKeyframeControlPath } from "../constants/keyframe.constants";
import { DuplicateAtomsOptions } from "../operations/duplicateAtoms.operation";
import { OrnamentSnapshotFactory } from "../models/atoms/Ornament.model";
import { OrnamentationDef } from "../constants/ornaments.constants";
import { MusicScaleName } from "../constants/musicScales.constants";
import {
  BpmMap,
  MusicKeyMap,
  MusicScaleMap,
  ScaledTimeMap,
  ScaledXMap,
  SpeedScalarMap,
  TimeMap,
  TimeSignatureMap,
  XpmMap,
} from "../utils/valueAtXMap.utils";
import { TextNodeSnapshotFactory } from "../models/atoms/TextNode.model";
import { CreateVoiceOptions } from "../operations/createVoice.operation";
import { PatternCreationSource } from "../utils/patterns.utils";
import { ReplicaSnapshotFactory } from "../models/atoms/Replica.model";
import { AtomAppearanceController } from "../logic/makeAtomAppearanceController.factory";
import { SectionSnapshotFactory } from "../models/atoms/Section.model";
import { AppearanceSnapshot } from "../traits/hasAppearance.trait";
import { VisualizerState } from "../components/visualizer/visualizer";

export enum AtomType {
  "note" = "note",
  "keyframe" = "keyframe",
  "chord" = "chord",
  "ornament" = "ornament",
  "textNode" = "textNode",
  "group" = "group",
  "pattern" = "pattern",
  "replica" = "replica",
  "bar" = "bar",
  "section" = "section",
  "voice" = "voice",
  // 'image' = 'image',
  // 'audio' = 'audio',
  // 'video' = 'video',
  // 'comment' = 'comment',
  // 'shape' = 'shape',
}

export type AtomPath = (Atom | AtomPath)[];

export type MusicalGroupAtom =
  | Group
  | Voice
  | Chord
  | Pattern
  | Replica
  | Ornament;
export type MusicalAtom = Note | MusicalGroupAtom;
export type TimeSignature = [number, number];
export type SelectableAtom =
  | Note
  | Group
  | Pattern
  | Replica
  | Chord
  | Keyframe
  | Ornament
  | TextNode;

export type AtomWithValidXValues<T extends Atom = Atom> = T & {
  startX: number;
  endX: number;
  x: number;
  x2: number;
  width: number;
};

export type AtomExtendedPropertiesFactory<A extends Atom<AtomType>> = (
  m: A,
  $: SnapshotOfAtom<A>,
  localDB?: LocalDBController
) => Partial<Nullable<ExtendedPropertiesOfAtom<A>>>;

export type Atom<
  AtomType extends string = string,
  Snapshot extends AtomBaseSnapshot = AtomBaseSnapshot,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ExtendedProperties extends {} = {}
> = Snapshot &
  AtomBaseExtendedMembers &
  ExtendedProperties & {
    // HasAtomArray &
    readonly type: AtomType;
    /** the observable source object */
    $: Snapshot;
    _updateId: () => Atom<AtomType, Snapshot, ExtendedProperties>;
    setParents: (parents: GroupLikeAtom[], mode: ArrayOperationMode) => void;
    addParents(...newParents: GroupLikeAtom[]): void;
    subtractParents(...parentsToSubtract: GroupLikeAtom[]): void;
    clearParents(): void;
    readonly path: AtomPath;
    /** The context might be empty, e.g. ephemeral atoms only during execution of certain functions */
    context?: AtomContext;
    interpreter?: Interpreter;
    interpretation: Interpretation | null;
    select(): void;
    readonly isAtom: true;
    appearance: AtomAppearanceController;
    setAppearance: ($: AppearanceSnapshot) => void;
    readonly transforms: TransformController[];
    readonly allTransforms: TransformController[];
    readonly allTransformSteps: TransformStep[];
    readonly hasActiveTransforms: boolean;
    readonly isXInverted: boolean;
    readonly isYInverted: boolean;
    rules: RuleController[];
    ruleParents: (GroupLikeAtom | Bar | Section)[];
    ownRules: RuleController[];
    lastOwnRule: RuleController | null;
    rulesRecursive: RuleController[];
    rulePropertiesFlattened: Partial<RuleProperties>;
    dispose(): void;
  };

export type AtomBaseSnapshot = ReturnType<typeof makeAtomBaseSnapshot>;

export type AtomBaseExtendedMembers = {
  _isLocked: boolean;
  _isHidden: boolean;
  /**
   * managed by @see setAtomElementSelectionState
   */
  _isSelected: boolean;
  _isDeleted: boolean;
  markAsDeleted(): void;
  displayName: string;
  /** TODO: we need localX and globalX, corresponding to x in an immediate frame (pattern) v.s. in the composition */
  /** x2 is the second x value that demarcates the time-wise edge of a note */
  readonly x2: number | null;
  /** y2 is the second y value that demarcates the pitch-wise edge of a note */
  readonly y2: number | null;
  readonly startX: number | null;
  readonly startY: number | null;
  readonly centerY: number | null;
  /**
   * endX is the end-most (right-most in an rtl context) X value out of x and x2.
   * e.g. If a note is inverted on the X axis, the endX is always x.
   * */
  readonly endX: number | null;
  readonly endY: number | null;
  timeSignature: TimeSignature;
  bpm: number;
  bpx: number;
  xpm: number;
  beatCount: number;
  readonly boundingBox: ValidRect | null;
  readonly hitBox: ValidRect | null;
  section: Section | null;
  parents: GroupLikeAtom[];
  patternParents: Pattern[];
  patternAncestors: Pattern[];
  primaryPatternAncestor: Pattern | null;
  replica: Replica | null;
  anchor: ValidPoint;
  voice: Voice | null;
  readonly xRange: NumericRange | null;
  readonly ancestors: Atom[];
  readonly bars: Bar[];
  readonly startsInBar: Bar | null;
  readonly endsInBar: Bar | null;
  musicKey: MusicKey;
  tonic: MusicKey;
  closestTonicMidiNumber: number;
  musicScaleName: MusicScaleName;
  indexInOctaveFromRoot: number | null;
  indexInMusicScale: number | null;
  refAtom: Atom | null;
  referrers: Atom[];
  xyRelToAnchor: Point | null;
};

export type TypeOfAtom<T> = T extends Atom<infer NType> ? NType : string;
export type SnapshotOfAtom<T> = T extends Atom<string, infer SnapshotType>
  ? SnapshotType
  : AtomBaseSnapshot;
export type ExtendedPropertiesOfAtom<T> = T extends Atom<
  string,
  AtomBaseSnapshot,
  infer ExtendedMembersType
>
  ? ExtendedMembersType
  : UnknownObject;

export type AtomRelationshipKeyDescriptor<T extends Atom> = {
  modelName?: TypeOfAtom<T>;
  has?: "one" | "many";
  /**
   * the key name that contains the identifier(s) of the related model.
   */
  identifierKeyName?: keyof SnapshotOfAtom<T>;
  /**
   * the key name on the related model (the 'remote') that links back to this model.
   * when this key is set, the model will automatically sync the relationship changes between them.
   */
  selfIdentifierKeyNameOnRemote?: string;
  /**
   * whether the related model has one or many of this model.
   * when `selfIdentifierKeyNameOnRemote` is set, and this is not set, we presume it's the reverse of the 'has' value.
   * if both directions are has-many relationship, please specify explicitly.
   */
  remoteHasSelf?: "one" | "many";
};

export type AtomModelFactory<T extends Atom> = (
  source?: Partial<SnapshotOfAtom<T>>,
  context?: AtomContext
) => T;

export type ChordCreationAutoAlign = "none" | "start" | "both";
export type AtomContextWriteDestination = "composition" | "interpretation";

export type DeleteBarOptions = {
  deleteContents?: boolean;
};
export type RemoveAtomsOptions = DeleteBarOptions;

/**
 * AtomContext
 */
export type AtomContext = {
  __isAtomContext: boolean;
  id: string;
  ROOT: RootControllerChildren | undefined;
  ready: boolean;
  options: AtomContextOptions;
  composition: Composition | null;
  compositionAtomSnapshots: AtomBaseSnapshot[];
  interpretationAtomSnapshots: AtomBaseSnapshot[];
  writeContentToArray: AtomBaseSnapshot[];
  writeKeyframesToArray: AtomBaseSnapshot[];
  atomSnapshots: AtomBaseSnapshot[];
  composer: ComposerInstance | null;
  interpreter: Interpreter | null;
  interpretation: Interpretation | null;
  timeSignature: TimeSignature;
  bpm: number;
  bpx: number;
  xpm: number;
  xpb: number;
  xpsAfterEnd: number;
  leadingBeats: number;
  leadingBeatsWidth: number;
  leadingBeatsInSeconds: number;
  trailingBeats: number;
  trailingBeatsWidth: number;
  trailingBeatsInSeconds: number;
  unsortedAtoms: Atom[];
  atoms: Atom[];
  markAsShouldResortByX: () => void;
  markAsShouldResortByEndX: () => void;
  atomsSortedByX: Atom[];
  atomsSortedByEndX: Atom[];
  atomsReverseSortedByX: Atom[];
  atomsAtTopLevel: Atom[];
  leafAtoms: LeafAtom[];
  notesAndKeyframes: (Note | Keyframe)[];
  isLargeComposition: boolean;
  duration: number;
  durationWithLeading: number;
  durationWithLeadingAndTrailingSilence: number;
  allSelectableAtoms: SelectableAtom[];
  allSelectableAtomsAtRoot: SelectableAtom[];
  musicalAtoms: MusicalAtom[];
  musicalAtomsSortedByEndX: MusicalAtom[];
  getNextNewAtomId: () => string;
  createNote: (template: Note | Partial<NoteSnapshot>) => Note;
  createChord: (
    atoms?: Atom[],
    options?: {
      id?: string;
      name?: string;
      refAtomId?: string | null;
      parentIds?: string[];
      align?: ChordCreationAutoAlign;
    }
  ) => Chord;
  createGroup: (
    atoms?: Atom[],
    options?: {
      id?: string;
      name?: string;
      parentIds?: string[];
      refAtomId?: string | null;
    }
  ) => Group;
  ungroup: (...atoms: Atom[]) => Atom[];
  createVoice: (options?: CreateVoiceOptions) => Voice;
  createBars: (
    template?: Bar | Partial<BarSnapshot> | null,
    atIndex?: number,
    copies?: number
  ) => Bar | Bar[];
  createPattern: (source?: PatternCreationSource) => Pattern | null;
  createReplica: (
    source?: Partial<ReplicaSnapshot>,
    newId?: string
  ) => Replica | null;
  createKeyframe: (template: Keyframe | Partial<KeyframeSnapshot>) => Keyframe;
  createTextNode: (template: TextNode | Partial<TextNodeSnapshot>) => TextNode;
  createOrnament: (options: {
    forNote: Note;
    rule?: RuleController | null;
    template?: Ornament | Partial<OrnamentSnapshot>;
    def?: OrnamentationDef | null;
  }) => Ornament | null;
  createSection: (template: Section | Partial<SectionSnapshot>) => Section;
  deleteSection: (section: Section) => void;
  notes: Note[];
  notesUnsorted: Note[];
  notesReversed: Note[];
  notesSortedByEndX: Note[];
  playableNotes: Note[];
  playableNotesSortedByEndX: Note[];
  orphanNotes: Note[];
  chords: Chord[];
  chordsReversed: Chord[];
  groups: Group[];
  groupsReversed: Group[];
  ornaments: Ornament[];
  ornamentsReversed: Ornament[];
  patterns: Pattern[];
  patternsReversed: Pattern[];
  replicas: Replica[];
  replicasReversed: Replica[];
  groupsAndPatterns: (Group | Pattern)[];
  groupsAndPatternsAndReplicas: (Group | Pattern | Replica)[];
  voices: Voice[];
  voicesSortedByY: Voice[];
  voicesOrdered: Voice[];
  voicesReversed: Voice[];
  topLevelVoices: Voice[];
  topLevelVoicesOrdered: Voice[];
  writableVoices: Voice[];
  getVoiceByNumberOrName: (n: number | string) => Voice | null;
  firstVoice: Voice | null;
  bars: Bar[];
  barsReversed: Bar[];
  barsSortedByEndX: Bar[];
  keyframes: Keyframe[];
  keyframesReversed: Keyframe[];
  keyframesSortedByEndX: Keyframe[];
  keyframesCategorized: {
    sustainPedal: SustainPedalKeyframe[];
    speedScalar: SpeedScalarKeyframe[];
    bpmChange: BpmChangeKeyframe[];
    musicKeyChange: MusicKeyChangeKeyframe[];
    musicScaleChange: MusicScaleChangeKeyframe[];
  };
  textNodes: TextNode[];
  textNodesReversed: TextNode[];
  hasSections: boolean;
  hasMoreThanOneSection: boolean;
  sections: Section[];
  sectionsReversed: Section[];
  lastAtomByEndX: Atom | null;
  lastNoteByEndX: Note | null;
  lastKeyframeByEndX: Keyframe | null;
  lastNoteOrKeyframeByEndX: Note | Keyframe | null;
  lastBar: Bar | null;
  width: number;
  widthPt: number;
  height: number;
  heightPt: number;
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  centerY: number;
  playableRange: [number, number];
  playableRangePt: [number, number];
  playableRangeStartX: number;
  playableRangeEndX: number;
  playableWidth: number;
  playableWidthPt: number;
  playbackRange: [number, number];
  playbackStartX: number;
  playbackEndX: number;
  playbackWidth: number;
  getAtomById: <T extends Atom = Atom>(id: string | null) => T | null;
  getAtomsByIds: <T extends Atom = Atom>(ids?: string[]) => T[];
  constructAtom: ($: Partial<AtomBaseSnapshot>) => Atom;
  pushAtoms: <T extends Atom = Atom>(...atoms: T[]) => void;
  constructAtomsFromSnapshots: <
    T extends Partial<AtomBaseSnapshot> = Partial<AtomBaseSnapshot>
  >(
    snapshots: T[]
  ) => Atom[];
  addAtomSnapshots: <T extends AtomBaseSnapshot = AtomBaseSnapshot>(
    atomSnapshots: T[]
  ) => void;
  removeAtomSnapshots: <T extends AtomBaseSnapshot = AtomBaseSnapshot>(
    atomSnapshots: T[]
  ) => void;
  removeAtom: <T extends Atom = Atom>(
    atom: T,
    options?: RemoveAtomsOptions
  ) => void;
  removeAtoms: <T extends Atom = Atom>(
    atoms: T[],
    options?: RemoveAtomsOptions
  ) => void;
  removeAtomById: (id: string, options?: RemoveAtomsOptions) => void;
  removeAtomsByIds: (ids: string[], options?: RemoveAtomsOptions) => void;
  removeAtomsAndDescendants: <T extends Atom = Atom>(
    atoms: T[],
    options?: RemoveAtomsOptions
  ) => void;
  deleteBar: (bars: Bar, options?: DeleteBarOptions) => void;
  deleteBars: (bars: Bar[], options?: DeleteBarOptions) => void;
  deleteVoice: (voice: Voice) => void;
  deleteVoiceAndDescendants: (voice: Voice) => void;
  duplicateAtoms: (options: DuplicateAtomsOptions) => Atom[];
  lastEditedNote: Note | null;
  lastEditedKeyframe: Keyframe | null;
  lastEditedTextNode: TextNode | null;
  canvas: CCanvasState | null;
  setCanvas: (c: CCanvasState | null) => void;
  visualizers: VisualizerState[];
  moveAtomsToInterpretation: (
    atoms: Atom[],
    int: Interpretation,
    includeAllDescendants?: boolean
  ) => void;
  moveAtomsToComposition: (
    atoms: Atom[],
    includeAllDescendants?: boolean
  ) => void;
  selectAll: (type: AtomType) => Atom[];
  convertToGroup: (source: Pattern | Replica | Chord | Ornament) => Group;
  getOverlappingAtomsAtX: <T extends Atom = Atom>(
    x: number,
    atoms?: T[]
  ) => T[];
  getOverlappingInterpretedAtomsAtX: <T extends TimedAtom = TimedAtom>(
    x: number,
    atoms?: T[]
  ) => T[];
  getOverlappingAtomsInXSpan: <T extends Atom = Atom>(
    x1: number,
    x2: number,
    atoms?: T[]
  ) => T[];
  getTime: (x: number) => number;
  getScaledTime: (x: number) => number;
  valueAtXMaps: {
    timeSignature: TimeSignatureMap;
    bpm: BpmMap;
    bpx: XpmMap;
    xpm: XpmMap;
    speedScalar: SpeedScalarMap;
    time: TimeMap;
    /** X scaled with speed scalar, relative to the default state of the composition */
    scaledX: ScaledXMap;
    scaledTime: ScaledTimeMap;
    musicKey: MusicKeyMap;
    musicScaleName: MusicScaleMap;
  };
  applyAutoSort: () => void;
  cleanUp: {
    zeroWidthNotes: () => string[];
    emptyChords: () => string[];
    emptyPatterns: () => string[];
    emptyGroups: () => string[];
    emptyVoices: () => string[];
    trailingBars: () => string[];
    all: () => string[];
  };
  dispose: () => void;
};
export type AtomContextOptions = ReturnType<typeof makeAtomContextOptions>;

export type TimedAtom = Note | GroupLikeAtom | Bar | Keyframe;

export type TimedAtomInterpretedProperties = {
  instruments: Instrument[];
  startX: number | null;
  endX: number | null;
  width: number;
  timeStartInSeconds: number | null;
  timeEndInSeconds: number | null;
  durationInSeconds: number;
  bpm: number;
  bpx: number;
  xpm: number;
};

export type TimedAtomExtendedProperties = {
  timeStartInSeconds: number | null;
  timeEndInSeconds: number | null;
  durationInSeconds: number;
  bpm: number;
  muted?: boolean;
  interpreted: TimedAtomInterpretedProperties;
};

export type NonNullableTimedAtomExtendedProperties =
  TimedAtomExtendedProperties & {
    timeStartInSeconds: number;
    timeEndInSeconds: number;
    interpreted: TimedAtomInterpretedProperties & {
      startX: number;
      endX: number;
      timeStartInSeconds: number;
      timeEndInSeconds: number;
    };
  };

/**
 * Note
 */
export type Note = Atom<AtomType.note, NoteSnapshot, NoteExtendedProperties>;
export type NoteSnapshot = ReturnType<typeof NoteSnapshotFactory>;
export type NoteExtendedProperties = {
  /**
   * whether the note is currently being played or not.
   * imperatively controlled by ENSEMBLE.
   * */
  readonly _on: boolean;
  _markNoteOn: () => () => void;
  _hardMarkNoteOff: () => void;
  /** pre-transform X value */
  _x: number | null;
  /** pre-transform Y value */
  _y: number | null;
  _width: number | null;
  startX: number | null;
  _centerY: number | null;
  centerY: number | null;
  pitchClassNumber: MusicKeyNumber | null;
  pitchClass: MusicKey | null;
  /** pre-transform width value */
  keyName: string;
  displayKeyName: string;
  octave: number;
  indexInOctaveFromC: MusicKeyNumber | null;
  midiNumber: number | null;
  velocity: number;
  frequency: number | null;
  prevNote: Note | null;
  nextNote: Note | null;
  // notationValue: number;
  /** Each note can only belong to one chord */
  chord: Chord | null;
  indexInChord: number;
  isInOrnament: boolean;
  ornamentParent: Ornament | null;
  ornamentInComp: Ornament | null;
  refAtom: Note | null;
  referrers: Note[];
  readonly _anchor: ValidPoint;
} & TimedAtomExtendedProperties & {
    interpreted: NoteSpecificInterpretedProperties;
  };

type NoteSpecificInterpretedProperties = {
  supportsVelocity: boolean;
  velocity: number;
  arpeggioOffset: number;
  ornament: Ornament | null;
  ornamentId: string | null;
  nextImmediateNotesWithSameInstrument: Note[];
  overlappingNotesWithSameInstrument: Note[];
  someNextImmediateNoteIsSamePitch: boolean;
  notesStartingAtTheSameTimeWithSamePitchAndInstrument: Note[];
  notesStartingAtTheSameTimeWithSamePitchAndInstrumentIncludingSelf: Note[];
  adjustedDuration: number;
};
export type NoteInterpretedProperties = TimedAtomInterpretedProperties &
  NoteSpecificInterpretedProperties;

export type PlayableNotePartial = Partial<Omit<Note, "interpreted">> & {
  interpreted?: Partial<NoteInterpretedProperties>;
};

/**
 * Ghost Note: used for displaying a placeholder note when user is hovering on the canvas when the quill tool is active
 */
export type GhostNote = {
  x: number;
  y: number;
  width: number;
  midiNumber: number;
  voiceId: string | null;
  voice: Voice | null;
};

export type ShapeOfMelody = (number | null)[];
export type ShapeOfMelodyDirection = (0 | 1 | -1 | null)[];
export type ShapeOfRhythm = (number | null)[];
export type GroupShape = {
  melody: ShapeOfMelody;
  melodyDirection: ShapeOfMelodyDirection;
  rhythm: ShapeOfRhythm;
};

/**
 * Atoms that can have no children
 */
export type LeafAtom = Note | Keyframe | TextNode;

/**
 * Group
 */
export type Group = Atom<
  AtomType.group,
  GroupSnapshot,
  GroupExtendedProperties & GroupOwnExtendedProperties
>;
export type GroupSnapshot = ReturnType<typeof GroupSnapshotFactory>;
export type GroupExtendedProperties = {
  children: Atom[];
  childrenSorted: Atom[];
  childrenSpacing: number;
  pushAtom: (atom: Atom) => void;
  pushAtoms: (...atoms: Atom[]) => void;
  descendants: Atom[];
  descendantsReversed: Atom[];
  nonGroupDescendants: Atom[];
  descendantNotes: Note[];
  descendantNotesReversed: Note[];
  descendantNotesSortedByEndX: Note[];
  descendantNotesYMean: number | null;
  descendantKeyframes: Keyframe[];
  descendantNotesAndKeyframes: (Note | Keyframe)[];
  descendantNotesAndKeyframesSortedByEndX: (Note | Keyframe)[];
  shape: GroupShape;
  isEmpty: boolean;
} & TimedAtomExtendedProperties;

export type GroupOwnExtendedProperties = {
  refAtom: Group | null;
  referrers: Group[];
};

export type GroupLikeType =
  | AtomType.group
  | AtomType.voice
  | AtomType.chord
  | AtomType.ornament
  | AtomType.pattern
  | AtomType.replica;

export type GroupLikeAtom =
  | Group
  | Voice
  | Pattern
  | Replica
  | Chord
  | Ornament;

/**
 * Voice
 */
export type Voice = Atom<
  AtomType.voice,
  VoiceSnapshot,
  VoiceExtendedProperties
>;
export type VoiceSnapshot = ReturnType<typeof VoiceSnapshotFactory>;
export type VoiceExtendedProperties = GroupExtendedProperties & {
  voiceIndex: number;
  childVoices: Voice[];
  childVoicesOrdered: Voice[];
  parentVoice: Voice | null;
  ancestorVoices: Voice[];
  voicePath: Voice[];
  hasChildVoices: boolean;
  isWritable: boolean;
  order: number;
};

export type VoiceOrderType = "by-pitch" | "manual";

/**
 * Chord
 */
export type Chord = Atom<
  AtomType.chord,
  ChordSnapshot,
  ChordExtendedProperties
>;
export type ChordSnapshot = ReturnType<typeof ChordSnapshotFactory>;
export type ChordExtendedProperties = GroupExtendedProperties & {
  notesSorted: Note[];
  arpeggio: number[];
  refAtom: Chord | null;
  referrers: Chord[];
  defs: string[];
  matchedChords: ChordProperties[];
  firstMatchedChord: ChordProperties | null;
};

export type ChordProperties = {
  name: string;
  symbol: string;
  aliases: string[];
  quality: ChordQuality;
  tonic: string | null;
  type: string;
  root: string;
};

export type ChordQuality =
  | "Major"
  | "Minor"
  | "Augmented"
  | "Diminished"
  | "Unknown";

/**
 * Pattern
 */
export type Pattern = Atom<
  AtomType.pattern,
  PatternSnapshot,
  PatternExtendedProperties
>;
export type PatternSnapshot = ReturnType<typeof PatternSnapshotFactory>;
export type PatternExtendedProperties = GroupExtendedProperties & {
  _boundingBox: ValidRect;
  boundingBox: ValidRect;
  x: number;
  y: number;
  x2: number;
  y2: number;
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  width: number;
  height: number;
  replicas: Replica[];
  defaultAnchor: ValidPoint;
  _anchor: ValidPoint;
  anchor: ValidPoint;
  setAnchorX: (v: number) => void;
  setAnchorY: (v: number) => void;
  resetAnchor: () => void;
  resetBoundingBox: () => void;
  readonly xyRelToAnchor: ValidPoint;
} & TimedAtomExtendedProperties;

/**
 * Replica
 */
export type Replica = Atom<
  AtomType.replica,
  ReplicaSnapshot,
  ReplicaExtendedProperties
>;
export type ReplicaSnapshot = ReturnType<typeof ReplicaSnapshotFactory>;
export type ReplicaExtendedProperties = GroupExtendedProperties & {
  _boundingBox: ValidRect;
  boundingBox: ValidRect;
  _x: number;
  _y: number;
  x: number;
  y: number;
  x2: number;
  y2: number;
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  width: number;
  height: number;
  pattern: Pattern | null;
  refAtom: Pattern | null;
  referrers: Replica[];
  readonly _anchor: ValidPoint;
  readonly anchor: ValidPoint;
  readonly _anchorDiffFromSource: ValidPoint;
  readonly anchorDiffFromSource: ValidPoint;
  readonly scaleStepDiffFromSource: number;
  readonly xyRelToAnchor: ValidPoint;
  widthDiffFromSource: number;
  heightDiffFromSource: number;
  scaleTransformStep: null | ScaleTransformStep;
  transformOnSource: null | TransformController;
  useClickThroughBoundingBox: boolean;
};

/**
 * Ornament
 */
export type Ornament = Atom<
  AtomType.ornament,
  OrnamentSnapshot,
  OrnamentExtendedProperties
>;
export type OrnamentSnapshot = ReturnType<typeof OrnamentSnapshotFactory>;
export type OrnamentExtendedProperties = GroupExtendedProperties & {
  def: OrnamentationDef | null;
  symbol: string | null;
  note: Note | null;
  rule: RuleController | null;
  refAtom: Ornament | null;
  referrers: Ornament[];
} & TimedAtomExtendedProperties;

/**
 * Bar
 */
export type Bar = Atom<AtomType.bar, BarSnapshot, BarExtendedProperties>;
export type BarSnapshot = ReturnType<typeof BarSnapshotFactory>;
export type BarExtendedProperties = {
  x: number;
  y: number;
  x2: number;
  y2: number;
  barNumber: number;
  beats: number;
  timeSignature: TimeSignature;
  beatWidth: number;
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  width: number;
  prevBar: Bar | null;
  nextBar: Bar | null;
  alternatives: Bar[];
  isStartOfSection: Section | null;
  section: Section | null;
  atoms: Atom[];
  topLevelAtoms: Atom[];
  notes: Note[];
  atomsStartingInThisBar: Atom[];
  topLevelAtomsStartingInThisBar: Atom[];
} & NonNullableTimedAtomExtendedProperties;

/**
 * Ghost Keyframe: used for displaying a placeholder keyframe when user is hovering on the canvas when the keyframe tool is active
 */
export type GhostKeyframe = {
  x: number;
  y: number;
  width: number;
  controlPath: KnownKeyframeControlPath | null;
};

/**
 * Keyframe
 */
export type Keyframe<
  T extends unknown | null = unknown | null,
  P extends KnownKeyframeControlPath | string | null =
    | KnownKeyframeControlPath
    | string
    | null
> = Atom<AtomType.keyframe, KeyframeSnapshot<T>, KeyframeExtendedProperties>;
export type KeyframeSnapshot<
  T extends unknown | null = unknown | null,
  P extends KnownKeyframeControlPath | string | null =
    | KnownKeyframeControlPath
    | string
    | null
> = AtomBaseSnapshot & {
  controlPath: P;
  value: T;
};
export type KeyframeExtendedProperties<
  T extends unknown | null = unknown | null,
  P extends KnownKeyframeControlPath | string | null =
    | KnownKeyframeControlPath
    | string
    | null
> = KeyframeSnapshot<T, P> & {
  /** pre-transform X value */
  _x: number | null;
  /** pre-transform Y value */
  _y: number | null;
  _width: number | null;
  setSourceX: (newValue: number | null) => void;
  setSourceY: (newValue: number | null) => void;
  disabled: boolean;
  _on: boolean;
  refAtom: Keyframe<T, P> | null;
  referrers: Keyframe<T, P>[];
  _updateHitBox: (rect: ValidRect) => void;
} & NonNullableTimedAtomExtendedProperties;

export type SpeedScalarKeyframe = Keyframe<
  number,
  KnownKeyframeControlPath.speedScalar
>;
export type SustainPedalKeyframe = Keyframe<
  number,
  KnownKeyframeControlPath.sustainPedal
>;
export type BpmChangeKeyframe = Keyframe<
  number,
  KnownKeyframeControlPath.bpmChange
>;
export type MusicKeyChangeKeyframe = Keyframe<
  MusicKey,
  KnownKeyframeControlPath.musicKeyChange
>;
export type MusicScaleChangeKeyframe = Keyframe<
  MusicScaleName,
  KnownKeyframeControlPath.musicScaleChange
>;

export type NoteOrKeyframe = Note | Keyframe;

export type NK = NoteOrKeyframe;

/**
 * Ghost TextNode: used for displaying a placeholder textNode when user is hovering on the canvas when the text tool is active
 */
export type GhostTextNode = {
  x: number;
  y: number;
};

/**
 * TextNode
 */
export type TextNode = Atom<
  AtomType.textNode,
  TextNodeSnapshot,
  TextNodeExtendedProperties
>;
export type TextNodeSnapshot = ReturnType<typeof TextNodeSnapshotFactory>;
export type TextNodeExtendedProperties = {
  x: number;
  y: number;
  x2: number;
  y2: number;
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  width: number;
  height: number;
  setSourceX: (newValue: number) => void;
  setSourceY: (newValue: number) => void;
  refAtom: TextNode | null;
  referrers: TextNode[];
  hitBox: ValidRect;
  _updateHitBox: (rect: ValidRect) => void;
};

/**
 * Section
 */

export type Section = Atom<
  AtomType.section,
  SectionSnapshot,
  SectionExtendedProperties
>;
export type SectionSnapshot = ReturnType<typeof SectionSnapshotFactory>;
export type SectionExtendedProperties = {
  index: number;
  definedStartingBar: Bar | null;
  startX: number;
  endX: number;
  x2: number;
  y2: number;
  startY: number;
  endY: number;
  sectionNumber: number;
  startsWithPickupMeasure: boolean;
  nextSection: Section | null;
  atoms: Atom[];
};
