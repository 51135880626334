type Props = {
  strokeWidth?: number;
};

const IconPlus18 = (props: Props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M3 9L15 9M9 3L9 15"
        stroke="currentColor"
        strokeWidth={props.strokeWidth ?? 2}
      />
    </svg>
  );
};

export default IconPlus18;
