/** @jsxImportSource @emotion/react */
import { camelCase } from "lodash-es";
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { Atom, Voice } from "../../@types";
import Button from "../../base/components/Button";
import FormLabel from "../../base/components/FormLabel";
import Spacing from "../../base/components/Spacing";
import TextInput from "../../base/components/TextInput";
import { isDevelopment } from "../../base/env";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { ColorPalette } from "../../theming/colorPalette";
import { deleteVoice } from "../../utils/voice.utils";
import AppearanceColorPicker from "../composer/AppearanceColorPicker";
import AtomInstrumentSelector from "../composer/AtomInstrumentSelector";
import { useComposer } from "../composer/ComposerApp.context";
import { useControllers } from "../../base/hooks/rootContext.hooks";
import { RequiredAppearanceColorDefObject } from "../../traits/hasAppearance.trait";
import DraggableNumberInput from "../../base/components/DraggableNumberInput";

interface VoicePropertyPanelProps {
  atom: Atom;
}

const VoicePropertyPanel: React.FC<VoicePropertyPanelProps> = props => {
  const p = useProps(props);
  const { THEME } = useControllers();
  const I = useComposer();
  const s = useStore(() => ({
    get voice() {
      return p.atom as Voice;
    },
    logToConsole: () => {
      const key = camelCase(`voice ${s.voice.name}`);
      console.info({ [key]: s.voice });
      Reflect.set(window, `${key}`, s.voice);
    },
    selectContent: () => {
      I.tools.select.updateSelection(s.voice.children);
    },
    get rule() {
      if (!I.interpreter) return null;
      return I.interpreter?.findOrCreateRuleWithRuleSnapshot({
        selector: p.atom._id,
      });
    },
    deleteVoice: () => {
      deleteVoice(s.voice);
    },
    handleColorChange: (c: RequiredAppearanceColorDefObject) => {
      if (I.atomContext.voices.length === 1) {
        THEME.theme.variants.light.colors.primary = c.light;
        THEME.theme.variants.dark.colors.primary = c.dark;
      }
    },
  }));
  return (
    <Observer
      children={() => (
        <div className="VoicePropertyPanel">
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "repeat(6, 1fr)",
              gridGap: "1em .5em",
            }}
          >
            {s.voice.appearance && (
              <>
                <TextInput
                  type="number"
                  form={s.voice.appearance}
                  field="noteYScalar"
                  Label="Note Y scalar"
                  min={0.1}
                  step=".1"
                  taskName="Edit voice note Y scalar"
                  mergeableId={`voice-edit-note-y-scalar-${s.voice._id}`}
                  css={{ gridColumnEnd: "span 2" }}
                  disabled={I.editDisabled}
                />
                <TextInput
                  type="number"
                  form={s.voice.appearance}
                  field="noteRoundedness"
                  Label="Note roundedness"
                  min={0}
                  step="1"
                  taskName="Edit voice note roundness"
                  mergeableId={`voice-edit-note-roundness-${s.voice._id}`}
                  css={{ gridColumnEnd: "span 2" }}
                  disabled={I.editDisabled}
                />
              </>
            )}
            <DraggableNumberInput
              form={s.voice}
              field="z"
              Label="Z"
              min={-200}
              max={200}
              step={1}
              taskName="Edit voice Z value"
              mergeableId={`voice-edit-note-z-value-${s.voice._id}`}
              fullWidth
              css={{ gridColumnEnd: "span 2" }}
              disabled={I.editDisabled}
            />
            {s.voice.appearance && (
              <>
                <div css={{ gridColumnEnd: "span 3", paddingRight: ".25em" }}>
                  <AppearanceColorPicker
                    atom={s.voice}
                    Label="Color"
                    taskName={m => `Edit voice ${m} mode color`}
                    mergeableId={m => `voice-edit-color-${m}-${s.voice._id}`}
                    onChange={s.handleColorChange}
                  />
                </div>
                <div css={{ gridColumnEnd: "span 3", paddingLeft: ".25em" }}>
                  <AppearanceColorPicker
                    atom={s.voice}
                    Label="Highlight color"
                    type="highlight"
                    taskName={m => `Edit voice ${m} mode highlight color`}
                    mergeableId={m =>
                      `voice-edit-highlight-color-${m}-${s.voice._id}`
                    }
                  />
                </div>
              </>
            )}
            <div css={{ gridColumnEnd: "span 6" }}>
              <FormLabel bold>Instrument</FormLabel>
              <Spacing size="0.5em" />
              <AtomInstrumentSelector atom={s.voice} />
            </div>
          </div>
          <Spacing />
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: ".25em",
            }}
          >
            <Button onClick={s.selectContent}>Select content</Button>{" "}
            <Button
              onClick={s.deleteVoice}
              color={ColorPalette.red}
              disabled={I.editDisabled}
            >
              Delete
            </Button>
            {isDevelopment && (
              <Button css={{ gridColumn: "span 2" }} onClick={s.logToConsole}>
                Log to console
              </Button>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default VoicePropertyPanel;
