/* eslint-disable @typescript-eslint/unbound-method */
import { isMobileOS } from "./browsersAndPlatforms.utils";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
gsap.registerPlugin(ScrollToPlugin, CustomEase);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const createEase = (def: string): ((progress: number) => number) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  CustomEase.create("custom", def);

/** this function keeps GSAP running when the window is in the background. */
function tickGSAPWhileHidden(value: boolean) {
  let id = null as null | IntervalHandle;
  const onChange = () => {
    id ? clearInterval(id) : void 0;
    if (document.hidden) {
      gsap.ticker.lagSmoothing(0); // keep the time moving forward (don't adjust for lag)
      // tick gsap every 20ms. This allows a fairly smooth audio timing.
      // this can probably be made customizable as an option.
      id = setInterval(gsap.ticker.tick, 20);
    } else {
      gsap.ticker.lagSmoothing(1000, 33); // restore lag smoothing
    }
  };
  if (value === false) {
    document.removeEventListener("visibilitychange", onChange);
    return id ? clearInterval(id) : null;
  }
  document.addEventListener("visibilitychange", onChange);
  onChange(); // in case the document is currently hidden.
}

export const setupGsap = () => {
  if (!isMobileOS) tickGSAPWhileHidden(true);
  Reflect.set(window, "gsap", gsap);
};

export const clearAnimatedProps = (
  target: gsap.TweenTarget,
  props?: string | string[]
) => {
  gsap.set(target, {
    clearProps: (props instanceof Array ? props.join(",") : props) ?? "all",
  });
};
