import { makeIconDef } from "./makeIconDef";

export const CDIconDef = {
  cd: makeIconDef("cd", {
    regular: () => (
      <>
        <circle cx="12.5" cy="12.5" r="9" stroke="currentColor" />
        <path
          d="M12.5 5.5C13.4193 5.5 14.3295 5.68106 15.1788 6.03284C16.0281 6.38463 16.7997 6.90024 17.4497 7.55025C18.0998 8.20026 18.6154 8.97194 18.9672 9.82122C19.3189 10.6705 19.5 11.5807 19.5 12.5"
          stroke="currentColor"
          strokeOpacity=".5"
        />
        <path
          d="M12.5 7.5C13.1566 7.5 13.8068 7.62933 14.4134 7.8806C15.02 8.13188 15.5712 8.50017 16.0355 8.96447C16.4998 9.42876 16.8681 9.97995 17.1194 10.5866C17.3707 11.1932 17.5 11.8434 17.5 12.5"
          stroke="currentColor"
          strokeOpacity=".5"
        />
        <path
          d="M12.5 19.5C11.5807 19.5 10.6705 19.3189 9.82122 18.9672C8.97194 18.6154 8.20026 18.0998 7.55025 17.4497C6.90024 16.7997 6.38463 16.0281 6.03284 15.1788C5.68106 14.3295 5.5 13.4193 5.5 12.5"
          stroke="currentColor"
          strokeOpacity=".5"
        />
        <path
          d="M12.5 17.5C11.8434 17.5 11.1932 17.3707 10.5866 17.1194C9.97996 16.8681 9.42876 16.4998 8.96447 16.0355C8.50017 15.5712 8.13188 15.02 7.8806 14.4134C7.62933 13.8068 7.5 13.1566 7.5 12.5"
          stroke="currentColor"
          strokeOpacity=".5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 16C14.433 16 16 14.433 16 12.5C16 10.567 14.433 9 12.5 9C10.567 9 9 10.567 9 12.5C9 14.433 10.567 16 12.5 16ZM12.5 14C13.3284 14 14 13.3284 14 12.5C14 11.6716 13.3284 11 12.5 11C11.6716 11 11 11.6716 11 12.5C11 13.3284 11.6716 14 12.5 14Z"
          fill="currentColor"
        />
      </>
    ),
  }),
};
