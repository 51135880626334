import { action, reaction, toJS, when } from "mobx";
import {
  Atom,
  AtomBaseSnapshot,
  AtomContext,
  AtomPath,
  AtomType,
  Bar,
  Chord,
  Group,
  GroupLikeAtom,
  Keyframe,
  LeafAtom,
  MusicalAtom,
  Note,
  Ornament,
  Pattern,
  Replica,
  Section,
  SelectableAtom,
  SnapshotOfAtom,
  TextNode,
  TimedAtom,
  Voice,
} from "../@types";
import { ValidRect } from "../base/@types";
import {
  addOneToArrayIfNew,
  removeOneFromArray,
  removeOneFromArrayById,
  replaceItemInArray,
} from "../base/utils/array.utils";
import { makeDisposerController } from "../base/utils/disposer.utils";
import { add, applyFormula, rangesIntersect } from "../base/utils/math.utils";
import { first } from "../base/utils/ramdaEquivalents.utils";
import { SELECTION_GROUP_ID } from "../constants/selection.constants";
import { LocalDBController } from "../controllers/localDB.controller";
import { addAtomToRuleIfSelectedByTheRule } from "../logic/interpreterRule.controller";
import { makePoint } from "../models/geometry/makePoint.model";
import { Has_Id } from "../traits/hasId.trait";
import { DefaultBpm } from "./beats.utils";
import { getValueAtXFromMap } from "./valueAtXMap.utils";
import { replaceInList } from "../base/utils/string.utils";
import {
  copyWithJSON,
  recursiveMergeWithTypeCast,
} from "../base/utils/object.utils";

export function isAtomModel<Model extends Atom>(
  model: Model | unknown
): model is Model {
  return (model as UnknownObject)?.isAtom === true;
}
export const getAtomSnapshot = <T>(object: Atom | Partial<Has_Id>) => {
  if (isAtomModel(object)) return object.$ as unknown as Partial<T>;
  return object as unknown as Partial<T>;
};

export const isAtom = (n?: unknown): n is Atom => (n as Atom)?.isAtom;
export const isAtomSnapshot = (n?: unknown): n is AtomBaseSnapshot[] => {
  return !!(
    n &&
    "_id" in (n as AtomBaseSnapshot) &&
    "type" in (n as AtomBaseSnapshot) &&
    "voiceId" in (n as AtomBaseSnapshot)
  );
};
export const isAtomSnapshotArray = (a: unknown[]): a is AtomBaseSnapshot[] =>
  a.every(isAtomSnapshot) || a.length === 0;

/** patch the source of this atom. Note that this will not use the setters on a constructed atom instance. To use the setters, use the setProperties method. */
export const patchAtomSource = <T extends Atom>(
  A: T,
  src: Partial<SnapshotOfAtom<T>> | T
) => {
  const snapshot = toJS(isAtomModel(src) ? src.$ : src);
  recursiveMergeWithTypeCast(A.$, snapshot as Partial<SnapshotOfAtom<T>>);
  return A;
};

export const isGroupAtom = (a?: unknown): a is Group =>
  (a as Atom)?.type === AtomType.group;
export const isVoiceAtom = (a?: unknown): a is Voice =>
  (a as Atom)?.type === AtomType.voice;
export const isChordAtom = (a?: unknown): a is Chord =>
  (a as Atom)?.type === AtomType.chord;
export const isOrnamentAtom = (a?: unknown): a is Ornament =>
  (a as Atom)?.type === AtomType.ornament;
export const isPatternAtom = (a?: unknown): a is Pattern =>
  (a as Atom)?.type === AtomType.pattern;
export const isReplicaAtom = (a?: unknown): a is Replica =>
  (a as Atom)?.type === AtomType.replica;
export const isPatternOrReplicaAtom = (a?: unknown): a is Pattern | Replica =>
  (a as Atom)?.type === AtomType.pattern ||
  (a as Atom)?.type === AtomType.replica;
export const isGroupLikeAtom = (a?: unknown): a is GroupLikeAtom =>
  (a as Atom)?.type === AtomType.group ||
  (a as Atom)?.type === AtomType.pattern ||
  (a as Atom)?.type === AtomType.replica ||
  (a as Atom)?.type === AtomType.voice ||
  (a as Atom)?.type === AtomType.chord ||
  (a as Atom)?.type === AtomType.ornament;
export const isNonVoiceGroupLikeAtom = (a?: unknown): a is GroupLikeAtom =>
  (a as Atom)?.type === AtomType.group ||
  (a as Atom)?.type === AtomType.pattern ||
  (a as Atom)?.type === AtomType.replica ||
  (a as Atom)?.type === AtomType.chord ||
  (a as Atom)?.type === AtomType.ornament;
export const canBeConvertedToGroup = (
  a?: Atom
): a is Pattern | Replica | Chord | Ornament => {
  return !!(
    a &&
    (a.type === AtomType.chord ||
      a.type === AtomType.replica ||
      a.type === AtomType.ornament ||
      a.type === AtomType.pattern)
  );
};
export const isNoteAtom = (a?: unknown): a is Note =>
  (a as Atom)?.type === AtomType.note;
export const isNoteOrGroupAtom = (a?: unknown): a is Note | Group =>
  (a as Atom)?.type === AtomType.note || (a as Atom)?.type === AtomType.group;
export const isKeyframeAtom = (a?: unknown): a is Keyframe =>
  (a as Atom)?.type === AtomType.keyframe;
export const isNoteOrKeyframeAtom = (a?: unknown): a is Note | Keyframe =>
  (a as Atom)?.type === AtomType.note ||
  (a as Atom)?.type === AtomType.keyframe;

export const isTextNodeAtom = (a?: unknown): a is TextNode =>
  (a as Atom)?.type === AtomType.textNode;

export const isSectionAtom = (a?: unknown): a is Section =>
  (a as Atom)?.type === AtomType.section;

export const isLeafAtom = (a?: unknown): a is LeafAtom =>
  (a as Atom)?.type === AtomType.note ||
  (a as Atom)?.type === AtomType.keyframe ||
  (a as Atom)?.type === AtomType.textNode;

const leafAtomTypes = [AtomType.note, AtomType.keyframe, AtomType.textNode];
export const isLeafAtomType = (a?: AtomType) =>
  !!a && leafAtomTypes.includes(a);

export const isBarAtom = (a?: unknown): a is Bar =>
  (a as Atom)?.type === AtomType.bar;

export const isBarOrSectionAtom = (a?: unknown): a is Bar | Section =>
  (a as Atom)?.type === AtomType.bar || (a as Atom)?.type === AtomType.section;

export const isMusicalAtom = (a?: unknown): a is MusicalAtom =>
  isNoteAtom(a) || isGroupLikeAtom(a);

export const isSelectableAtom = (a?: unknown): a is SelectableAtom =>
  (a as Atom)?.type === AtomType.note ||
  (a as Atom)?.type === AtomType.group ||
  (a as Atom)?.type === AtomType.pattern ||
  (a as Atom)?.type === AtomType.replica ||
  (a as Atom)?.type === AtomType.chord ||
  (a as Atom)?.type === AtomType.ornament ||
  (a as Atom)?.type === AtomType.textNode ||
  (a as Atom)?.type === AtomType.keyframe;

export const reduceAtomArrayToIdString = (atoms: Atom[]) =>
  atoms.reduce((a, n) => `${a},${n._id}`, "");
export const reduceAtomArrayToStartXString = (atoms: Atom[]) =>
  atoms.reduce((a, n) => `${a},${n._id}_${n.startX}`, "");
export const reduceAtomArrayToEndXString = (atoms: Atom[]) =>
  atoms.reduce((a, n) => `${a},${n._id}_${n.endX}`, "");

/** TODO inverted atom set might be different here */
export const getAtomSetX = (a: Atom[]) =>
  a.length ? Math.min(...a.map(n => n.x || 0)) : null;
/** TODO inverted atom set might be different here */
export const getAtomSetY = (a: Atom[]) =>
  a.length ? Math.min(...a.map(n => n.y || 0)) : null;
/** TODO inverted atom set might be different here */
export const getAtomSetX2 = (atoms: Atom[]) =>
  atoms.length ? Math.max(...atoms.map(n => n.x2 || 0)) : null;
/** TODO inverted atom set might be different here */
export const getAtomSetY2 = (atoms: Atom[]) =>
  atoms.length ? Math.max(...atoms.map(n => n.y2 || 0)) : null;
export const getAtomSetStartX = (atoms: Atom[]) =>
  atoms.length ? Math.min(...atoms.map(n => n.startX || 0)) : null;
export const getAtomSetStartY = (atoms: Atom[]) =>
  atoms.length ? Math.min(...atoms.map(n => n.startY || 0)) : null;
export const getAtomSetEndX = (atoms: Atom[]) =>
  atoms.length ? Math.max(...atoms.map(n => n.endX || 0)) : null;
export const getAtomSetEndY = (atoms: Atom[]) =>
  atoms.length ? Math.max(...atoms.map(n => n.endY || 0)) : null;
export const getAtomSetWidth = (atoms: Atom[]) => {
  return getAtomSetEndX(atoms)! - getAtomSetX(atoms)!;
};
export const getAtomSetHeight = (atoms: Atom[]) => {
  return getAtomSetEndY(atoms)! - getAtomSetY(atoms)!;
};
export const getAtomSetBoundingBox = (atoms: Atom[]): ValidRect => {
  return [
    makePoint(getAtomSetStartX(atoms) ?? 0, getAtomSetStartY(atoms) ?? 0),
    makePoint(getAtomSetEndX(atoms) ?? 0, getAtomSetEndY(atoms) ?? 0),
  ] as ValidRect;
};

export const atomIsInCurrentMusicScale = (A: Atom) => {
  return (
    A.indexInMusicScale !== null &&
    Math.round(A.indexInMusicScale) === A.indexInMusicScale
  );
};

export const getInterpretedStartXOfNoteOrKeyframe = (atom: Note | Keyframe) => {
  return applyFormula(atom.startX, atom.rulePropertiesFlattened.start ?? "");
};
export const getInterpretedEndXOfNoteOrKeyframe = (atom: Note | Keyframe) => {
  return add(atom.interpreted.startX, atom.interpreted.width);
};
export const getInterpretedWidthOfAtom = (atom: Note | Keyframe) => {
  return applyFormula(atom.width, atom.rulePropertiesFlattened.width ?? "");
};

export const getTimeStartInSecondsOfAtom = (atom: TimedAtom) => {
  if (atom.startX === null) return null;
  const startX = atom.startX;
  if (!atom.context) return startX;
  return atom.context.getTime(atom.startX);
};

export const getInterpretedTimeStartInSecondsOfAtom = (atom: TimedAtom) => {
  if (atom.interpreted.startX === null) return null;
  const startX = atom.interpreted.startX;
  if (!atom.context) return startX;
  return atom.context.getScaledTime(atom.interpreted.startX);
};

export const getTimeEndInSecondsOfAtom = (atom: TimedAtom) => {
  if (atom.endX === null || atom.timeStartInSeconds === null) return null;
  const endX = atom.endX;
  if (!atom.context) return endX;
  const value = atom.context.getTime(atom.endX);
  return value;
};

export const getInterpretedTimeEndInSecondsOfAtom = (atom: TimedAtom) => {
  if (
    atom.interpreted.endX === null ||
    atom.interpreted.timeStartInSeconds === null
  )
    return null;
  const endX = atom.interpreted.endX;
  if (!atom.context) return endX;
  const value = atom.context.getScaledTime(atom.interpreted.endX);
  if (isNoteAtom(atom) && atom.interpreted.someNextImmediateNoteIsSamePitch) {
    return value - Math.min(value - atom.interpreted.timeStartInSeconds, 0.01);
  }
  return value;
};

export const getInterpretedDurationInSecondsOfAtom = (atom: TimedAtom) => {
  if (
    atom.interpreted.timeStartInSeconds === null ||
    atom.interpreted.timeEndInSeconds === null
  )
    return 0;
  return (
    atom.interpreted.timeEndInSeconds - atom.interpreted.timeStartInSeconds
  );
};

export const getInterpretedBpmOfAtom = (atom: TimedAtom) => {
  if (!atom.context) {
    if (isBarAtom(atom)) return DefaultBpm;
    return atom.startsInBar?.interpreted.bpm ?? DefaultBpm;
  }
  if (atom.interpreted.startX === null) return atom.bpm;
  return getValueAtXFromMap(
    atom.interpreted.startX,
    atom.context?.valueAtXMaps.bpm
  );
};

export const getInterpretedBpxOfAtom = (atom: TimedAtom) => {
  if (!atom.context) return atom.bpx;
  if (atom.interpreted.startX === null) return atom.bpx;
  return getValueAtXFromMap(
    atom.interpreted.startX,
    atom.context.valueAtXMaps.bpx
  );
};

export const getInterpretedXpmOfAtom = (atom: TimedAtom) => {
  return atom.interpreted.bpm / atom.interpreted.bpx;
};

export const findNearestInAtomPath = <T extends Atom>(
  path: AtomPath,
  comparator: (a: Atom) => boolean
): T | null => {
  for (const branch of path) {
    if (branch instanceof Array) {
      const result = findNearestInAtomPath<T>(branch, comparator);
      if (result !== null) return result;
    } else {
      if (comparator(branch)) return branch as T;
    }
  }
  return null;
};

export const findCommonParentsOfAtoms = (atoms: Atom[]) => {
  return (
    first(atoms)?.parents.filter(p =>
      atoms.every(a => a.parents.includes(p))
    ) ?? []
  );
};

export const createSnapshotCopyWithNewId = (atom: Atom) => {
  const id = atom.context!.getNextNewAtomId();
  const $ = toJS(atom.$);
  $._id = id;
  return {
    $,
    prevId: atom._id,
  };
};

export const atomIsDirectlySelected = (A: Atom) =>
  A.context?.composer?.tools.select.atomSelection.includes(A);

export const everyAtomsOverlapOnXAxis = (atoms: Atom[]) => {
  if (atoms.length === 1) return false;
  if (atoms.some(a => !a.width || a.startX === null || a.endX === null))
    return false;
  const shortest = atoms.sort((a, b) => a.width! - b.width!)[0];
  return atoms.every(a => rangesIntersect(a.xRange!, shortest.xRange!));
};

export const setupAtomReactions = <T extends Atom<AtomType>>({
  A,
  init,
  context,
  localDB,
}: {
  A: T;
  init?: (m: T, context?: AtomContext, localDB?: LocalDBController) => Disposer;
  context?: AtomContext;
  localDB?: LocalDBController;
}) => {
  const d = makeDisposerController();

  if (init) {
    d.add(init(A, context, localDB));
  }

  if (A._id !== SELECTION_GROUP_ID && A.type !== AtomType.bar) {
    if (!isGroupAtom(A)) {
      d.add(
        reaction(
          () => A.startX,
          () => {
            if (!A.context?.ready) return;
            A.context.markAsShouldResortByX();
          },
          { fireImmediately: true }
        )
      );
      d.add(
        reaction(
          () => A.endX,
          () => {
            if (!A.context?.ready) return;
            A.context.markAsShouldResortByEndX();
          },
          { fireImmediately: true }
        )
      );
    }

    d.add(
      reaction(
        () => A.voice,
        (curr, prev) => {
          if (curr) addOneToArrayIfNew(curr?.children, A);
          if (prev) removeOneFromArrayById(prev?.children, A);
        },
        { fireImmediately: true }
      )
    );
    d.add(
      reaction(
        () => A.refAtom,
        (curr, prev) => {
          if (curr) addOneToArrayIfNew(curr?.referrers, A);
          if (prev) removeOneFromArrayById(prev?.referrers, A);
        },
        { fireImmediately: true }
      )
    );
    d.add(
      reaction(
        () => A.parents,
        (curr, prev) => {
          if (prev) prev.forEach(p => removeOneFromArrayById(p.children, A));
          if (curr) curr.forEach(p => addOneToArrayIfNew(p.children, A));
        },
        { fireImmediately: true }
      )
    );
    if (!isVoiceAtom(A) && !isSectionAtom(A)) {
      d.add(
        reaction(
          () => A.bars,
          (curr, prev) => {
            prev?.forEach(b => removeOneFromArray(b.atoms, A));
            curr?.forEach(b => addOneToArrayIfNew(b.atoms, A));
          },
          { fireImmediately: true }
        )
      );
    }
    d.add(
      reaction(
        () => A.section,
        (curr, prev) => {
          if (curr) addOneToArrayIfNew(curr.atoms, A);
          if (prev) removeOneFromArray(prev.atoms, A);
        }
      )
    );
    d.add(() => {
      A.context?.bars.forEach(bar => {
        removeOneFromArrayById(bar.atoms, A);
      });
      A.parents.forEach(p => removeOneFromArray(p.children, A));
      if (A.voice) removeOneFromArray(A.voice.children, A);
      A.rules.forEach(r => {
        removeOneFromArray(r.atoms, A);
      });
      A.context?.markAsShouldResortByX();
      A.context?.markAsShouldResortByEndX();
      A.context = undefined;
    });
  }

  if (isNoteAtom(A)) {
    d.add(
      reaction(
        () =>
          [
            A.midiNumber,
            A.z,
            A.interpreted.startX,
            A.interpreted.endX,
            A.interpreted.velocity,
            A.appearance.noteYScalar,
            A.appearance.noteRoundedness,
            A.appearance.colorInContext,
            A.appearance.highlightColorInContext,
          ].join(","),
        () => {
          context?.visualizers.forEach(v => v.notifyNoteUpdate(A._id));
        }
      )
    );
    d.add(() => {
      context?.visualizers.forEach(v => v.notifyNoteRemoval(A._id));
    });
  }

  d.add(
    reaction(
      () => JSON.stringify(A.$),
      (curr, prev) => {
        A.context?.composer?.queue.recordAtomChangeBuffer(
          "changed",
          JSON.parse(curr) as AtomBaseSnapshot,
          JSON.parse(prev) as AtomBaseSnapshot
        );
      }
    )
  );

  if (A._id !== SELECTION_GROUP_ID) {
    d.add(
      when(
        () => !!A.context?.interpreter?.rules.length,
        () => {
          A.context?.interpreter?.rules.forEach(r => {
            addAtomToRuleIfSelectedByTheRule(A, r);
          });
        }
      )
    );
  }

  return d.dispose;
};

export const getAtomsAtRoot = <T extends Atom>(A: T[]) =>
  A.filter(
    a =>
      a.parents.length === 0 ||
      a.ancestors.every(
        a => isPatternOrReplicaAtom(a) && a.useClickThroughBoundingBox
      )
  );

export const updateAtomId = action(<T extends Atom>(A: T) => {
  const { context } = A;
  if (
    !context ||
    !context.interpreter ||
    (A._itpId && A._itpId !== context.interpreter.id)
  )
    return null;
  const oldId = A._id;
  const newId = context.getNextNewAtomId();
  const parents = [...A.parents];
  const children: Atom[] = [];
  const newSnapshot = copyWithJSON(A.$);
  newSnapshot._id = newId;
  context.addAtomSnapshots([newSnapshot]);
  const newAtom = context.getAtomById<T>(newId)!;
  A.clearParents();
  newAtom.addParents(...parents);
  // [TODO] one day keyframes will support rigging atom properties. will need to process them then too
  if (isGroupLikeAtom(A)) {
    children.push(...A.children);
    children.forEach(c => c.subtractParents(A));
    if (isGroupLikeAtom(newAtom))
      children.forEach(child => child.addParents(newAtom));
  }
  if (isNoteAtom(A)) {
    if (A.ornamentInComp) A.ornamentInComp._id = newId;
  }
  if (isOrnamentAtom(A)) {
    if (A.note) {
      if (A.note.ornamentId === oldId) A.note.ornamentId = newId;
      A.note.rules.forEach(r => {
        if (r.properties.ornamentId === oldId) r.properties.ornamentId = newId;
      });
    }
  }
  if (isBarAtom(A)) {
    if (A.isStartOfSection) A.isStartOfSection.definedStartingBarId = newId;
  }
  if (isVoiceAtom(A)) {
    if (A.parentVoice)
      replaceItemInArray(A.parentVoice.childrenIds, oldId, newId);
  }
  A.referrers.forEach(referrer => {
    referrer.refAtomId = newId;
  });
  A.rules.forEach(r => {
    r.selector = replaceInList(r.selector ?? "", oldId, newId);
  });
  context.removeAtom(A);
  // console.info(`Updated ${newAtom.type}'s ID from ${oldId} to ${newId}`);
  return newAtom;
});
